import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: string;
  Date: string;
  DateTime: string;
  HexColorCode: string;
  UnsignedFloat: number;
};

export type AwsBillingData = {
  __typename?: "AWSBillingData";
  /** `accountId` is how AWS account is identified within cloud provider, e.g. `123123123123` */
  accountId: Scalars["String"];
  /** `bucketId` is unique identifier of AWS bucket where the cost and usage reports are stored */
  bucketId: Scalars["String"];
  /** `displayName` is a user-defined account name */
  displayName?: Maybe<Scalars["String"]>;
  /** `isActive` is a status of billing data connection */
  isActive: Scalars["Boolean"];
  /** `isOnboarding` is a status of billing data onboarding */
  isOnboarding: Scalars["Boolean"];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid?: Maybe<Scalars["String"]>;
  /** `region` is geographical region where AWS resources and services are provisioned and utilized */
  region: Scalars["String"];
  /** `reportName` is the name assigned to a specific AWS Cost and Usage Report */
  reportName: Scalars["String"];
  /** `reportPathPrefix` is the prefix for storing AWS Cost and Usage Reports */
  reportPathPrefix: Scalars["String"];
};

export type Access = {
  __typename?: "Access";
  /** This field is only defined if the role of the user/access key is `TEAM_LEAD` */
  applications?: Maybe<Array<Scalars["String"]>>;
  /** This field is only defined if the role of the user/access key is `TEAM_LEAD` */
  applicationsWithNames?: Maybe<Array<ApplicationName>>;
  role: Role;
};

export type AccessInput = {
  applications?: InputMaybe<Array<Scalars["String"]>>;
  description?: InputMaybe<Scalars["String"]>;
  expirationDate?: InputMaybe<Scalars["String"]>;
  role: Role;
};

export type AccessKeyPermissions = {
  __typename?: "AccessKeyPermissions";
  access: Access;
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  expirationDate?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isExpired?: Maybe<Scalars["Boolean"]>;
};

export type AccessKeyPermissionsWithSecret = {
  __typename?: "AccessKeyPermissionsWithSecret";
  access: Access;
  id: Scalars["ID"];
  secret: Scalars["String"];
};

export type AccessKeysPermissionsResponse = {
  __typename?: "AccessKeysPermissionsResponse";
  count?: Maybe<Scalars["Int"]>;
  keyPermissions?: Maybe<Array<AccessKeyPermissions>>;
  pages?: Maybe<Scalars["Int"]>;
};

export type Account = {
  __typename?: "Account";
  /**
   * Accounts that have no credentials are not `activated`, since they
   * were added automatically
   */
  activated: Scalars["Boolean"];
  /** Returns costs by charge type ("usage", "tax", "support"...) */
  chargeTypeCosts?: Maybe<Array<ChargeTypeCost>>;
  /** Cloud status is used for all resources */
  cloudStatusV2?: Maybe<CloudAccountStatus>;
  /** `cloudWasteAggregate` describes aggregated information about Cloud Waste */
  cloudWasteAggregate?: Maybe<CloudWasteAggregate>;
  contacts?: Maybe<Array<Maybe<Person>>>;
  description?: Maybe<Scalars["String"]>;
  /** `displayName` is a user-defined friendly name */
  displayName?: Maybe<Scalars["String"]>;
  /** @deprecated use global query `estateV2` with `account` filter instead */
  estate?: Maybe<ResourceResponse>;
  /** @deprecated id and nid have been unified, and are going to be the same field, use `nid` instead */
  id: Scalars["String"];
  /** Metadata stores information for k8s scanner */
  metadata?: Maybe<Scalars["String"]>;
  /** `name` is usually `alias` when the account is from AWS or similar in others */
  name: Scalars["String"];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars["String"];
  owner?: Maybe<Person>;
  /**
   * `provider` is the cloud provider that the account is from, e.g.
   * `AWS`, `GCP`, `AZURE`, `VMWARE`, `IBMCLOUD`,
   */
  provider: Provider;
  /**
   * `providerId` is how the account is identified within cloud provider, e.g.
   * `123123123123` for AWS, subscription UUID for Azure, organization id for GCP,
   * for VMWARE provider this field contains nid as in VMWARE there is no provider id concept.
   */
  providerId: Scalars["String"];
  /**
   * `providerType` is the cloud provider type, e.g.
   * `KUBERNETES`, `OPENSHIFT`, `VMWARE`, `CLOUD`
   */
  providerType?: Maybe<CloudProviderType>;
  /** Account status is used for discovered resources */
  status?: Maybe<Scalars["String"]>;
};

export type AccountEstateArgs = {
  limit: Scalars["Int"];
  page: Scalars["Int"];
};

export type AccountCostComparisonRecord = {
  __typename?: "AccountCostComparisonRecord";
  cloudResourceCost: Scalars["String"];
  day: Scalars["String"];
  totalCost: Scalars["String"];
};

export type AccountProviderStatusSummary = {
  __typename?: "AccountProviderStatusSummary";
  /** Number of account marked as active - with access to the account currently available */
  active: Scalars["Int"];
  /** Number of accounts marked as discovered - with the account being found, but access never set up */
  discovered: Scalars["Int"];
  /** Number of accounts marked as inactive - with access to the account currently impossible */
  inactive: Scalars["Int"];
  /** Providers */
  provider: Provider;
};

export type AccountSpends = {
  __typename?: "AccountSpends";
  /** `currentMonthCo2e` is value of account co2 emissions in current calendar month */
  currentMonthCo2e: Scalars["Float"];
  /** `currentMonthForecastValue` is value of forecasted spends for application in current calendar month */
  currentMonthForecastValue: Scalars["Float"];
  /** `currentMonthValue` is value of spends for application in current calendar month */
  currentMonthValue: Scalars["Float"];
  /** `previousMonthCo2e` is value of account co2 emissions in previous calendar month */
  previousMonthCo2e: Scalars["Float"];
  /** `previousMonthValue` is value of spends for application in previous calendar month */
  previousMonthValue: Scalars["Float"];
};

export type AccountsFilter = {
  /** Returns only accounts with specified cloud provider id */
  cloudProviderIds?: InputMaybe<Array<Scalars["String"]>>;
  /** Returns only accounts with specified provider type */
  providerTypes?: InputMaybe<Array<CloudProviderType>>;
  /** Returns only accounts with specified provider */
  providers?: InputMaybe<Array<Provider>>;
};

export type AccountsResponse = {
  __typename?: "AccountsResponse";
  accounts?: Maybe<Array<Account>>;
  /** @deprecated no longer supported, will be removed in the future */
  costs?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
};

export type AccumulatedCostInput = {
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
};

export type AccumulatedCostResponse = {
  __typename?: "AccumulatedCostResponse";
  timePoints: Array<TimePoint>;
  total: Scalars["String"];
};

/** `ActiveReservationsFilters` used to filter from list of active reservations */
export type ActiveReservationsFilters = {
  __typename?: "ActiveReservationsFilters";
  platforms?: Maybe<Array<Scalars["String"]>>;
  resourceTypes?: Maybe<Array<Scalars["String"]>>;
};

export type AddAccount = {
  contactIds?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * Credentials that are saved, and then assumed to perform scanning operations
   * credentials are passed as string encoded JSON, with following format for each provider
   * (based on the `provider` field):
   *
   * for `AZURE` provider:
   * {
   *   "password": "asdf2389-fwan",
   *   "appId": "63894e1a-1b3b-4a89-9574-3b1660ac4c10",
   *   "tenantId": "bd84a79f-f391-4e9f-b358-c18d4b8b078b"
   * }
   * for `GCP` provider:
   * {
   *   "type": "service_account",
   *   "project_id": "test-sa",
   *   "private_key_id": "8f3ce9b574486fafa80c1a6052775409efd76642",
   *   "private_key": "-----BEGIN PRIVATE KEY-----...",
   *   "client_email": "test@test-sa.iam.gserviceaccount.com",
   *   "client_id": "494254016998140918876",
   *   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
   *   "token_uri": "https://oauth2.googleapis.com/token",
   *   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
   *   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/test%40test-sa.iam.gserviceaccount.com"
   * }
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * alternatively just role name could be passed
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "roleName": "CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * additionally, credentials for AWS china can include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "credentials: {
   *     "AWS_ACCESS_KEY_ID": "12312dawf23wefq3urhfwliueq",
   *     "AWS_SECRET_ACCESS_KEY": "adsfbhjkauv23089purhjfqwlcanpiuqweh"
   *   },
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ],
   *   "region": "cn-northwest-1"
   * }
   * for `IBMCLOUD` provider:
   * {
   *   "apikey": "dbsdX_rzgNj8LT05y255JUoPOmvwHvEcKHEJ4B4Ylidf"
   * }
   */
  creds: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerId: Scalars["String"];
  provider: Provider;
  providerId: Scalars["String"];
};

export type AddAccountV2 = {
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  /**
   * Credentials that are saved, and then assumed to perform scanning operations
   * credentials are passed as string encoded JSON, with following format for each provider
   * (based on the `provider` field):
   *
   * for `AZURE` provider:
   * {
   *   "password": "asdf2389-fwan",
   *   "appId": "63894e1a-1b3b-4a89-9574-3b1660ac4c10",
   *   "tenantId": "bd84a79f-f391-4e9f-b358-c18d4b8b078b"
   * }
   * for `GCP` provider:
   * {
   *   "type": "service_account",
   *   "project_id": "test-sa",
   *   "private_key_id": "8f3ce9b574486fafa80c1a6052775409efd76642",
   *   "private_key": "-----BEGIN PRIVATE KEY-----...",
   *   "client_email": "test@test-sa.iam.gserviceaccount.com",
   *   "client_id": "494254016998140918876",
   *   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
   *   "token_uri": "https://oauth2.googleapis.com/token",
   *   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
   *   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/test%40test-sa.iam.gserviceaccount.com"
   * }
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * alternatively just role name could be passed
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "roleName": "CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * additionally, credentials for AWS china can include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   *   "credentials: {
   *     "AWS_ACCESS_KEY_ID": "12312dawf23wefq3urhfwliueq",
   *     "AWS_SECRET_ACCESS_KEY": "adsfbhjkauv23089purhjfqwlcanpiuqweh"
   *   },
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   *       }
   *   ],
   *   "region": "cn-northwest-1"
   * }
   * for `IBMCLOUD` provider:
   * {
   *   "apikey": "dbsdX_rzgNj8LT05y255JUoPOmvwHvEcKHEJ4B4Ylidf"
   * }
   */
  creds?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerId: Scalars["ID"];
  provider: Provider;
  providerId?: InputMaybe<Scalars["ID"]>;
  providerType?: InputMaybe<CloudProviderType>;
  status?: InputMaybe<Scalars["String"]>;
};

export type AddAccountV3 = {
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  /**
   * Credentials that are saved, and then assumed to perform scanning operations
   * credentials are passed as string encoded JSON, with following format for each provider
   * (based on the `provider` field):
   *
   * for `AZURE` provider:
   * {
   * "password": "asdf2389-fwan",
   * "appId": "63894e1a-1b3b-4a89-9574-3b1660ac4c10",
   * "tenantId": "bd84a79f-f391-4e9f-b358-c18d4b8b078b"
   * }
   * for `GCP` provider:
   * {
   * "type": "service_account",
   * "project_id": "test-sa",
   * "private_key_id": "8f3ce9b574486fafa80c1a6052775409efd76642",
   * "private_key": "-----BEGIN PRIVATE KEY-----...",
   * "client_email": "test@test-sa.iam.gserviceaccount.com",
   * "client_id": "494254016998140918876",
   * "auth_uri": "https://accounts.google.com/o/oauth2/auth",
   * "token_uri": "https://oauth2.googleapis.com/token",
   * "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
   * "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/test%40test-sa.iam.gserviceaccount.com"
   * }
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   * "roles": [
   * {
   * "externalId": "awd87JKLdsan.jk",
   * "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   * }
   * ]
   * }
   * alternatively just role name could be passed
   * {
   *   "roles": [
   *       {
   *           "externalId": "awd87JKLdsan.jk",
   *           "roleName": "CMPScannerServiceRole"
   *       }
   *   ]
   * }
   * additionally, credentials for AWS china can include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account:
   * {
   * "credentials: {
   * "AWS_ACCESS_KEY_ID": "12312dawf23wefq3urhfwliueq",
   * "AWS_SECRET_ACCESS_KEY": "adsfbhjkauv23089purhjfqwlcanpiuqweh"
   * },
   * "roles": [
   * {
   * "externalId": "awd87JKLdsan.jk",
   * "arn": "arn:aws:iam::123123123123:role/CMPScannerServiceRole"
   * }
   * ],
   * "region": "cn-northwest-1"
   * }
   * for `IBMCLOUD` provider:
   * {
   * "apikey": "dbsdX_rzgNj8LT05y255JUoPOmvwHvEcKHEJ4B4Ylidf"
   * }
   */
  creds?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerId: Scalars["ID"];
  provider: Provider;
  providerId?: InputMaybe<Scalars["ID"]>;
  providerType?: InputMaybe<CloudProviderType>;
  status?: InputMaybe<Scalars["String"]>;
};

export type AddApplication = {
  contactIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  /**
   * `displayName` is a string up to 256 characters long that helps to identify the application
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerId: Scalars["String"];
};

export type AddApplicationV2 = {
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  description?: InputMaybe<Scalars["String"]>;
  /** `displayName` is a string up to 256 characters long that helps to identify the application */
  displayName: Scalars["String"];
  name: Scalars["String"];
  ownerId: Scalars["ID"];
};

export type AddAwsBillingConfig = {
  /** `accountId` is how AWS account is identified within cloud provider, e.g. `123123123123` */
  accountId: Scalars["String"];
  /** `bucketId` is unique identifier of AWS bucket where the cost and usage reports are stored */
  bucketId: Scalars["String"];
  /** `region` is geographical region where AWS resources and services are provisioned and utilized */
  region: Scalars["String"];
  /** `reportName` is the name assigned to a specific AWS Cost and Usage Report */
  reportName: Scalars["String"];
  /** `reportPathPrefix` is the prefix for storing AWS Cost and Usage Reports */
  reportPathPrefix: Scalars["String"];
};

export type AddAzureBillingConfig = {
  /** `enrollmentAccessKey` is a JWT token used to authenticate account in Azure. */
  enrollmentAccessKey: Scalars["String"];
  /** `enrollmentNumber` is how the account is identified by Azure. */
  enrollmentNumber: Scalars["String"];
};

export type AddBusinessContext = {
  /** `color` is a string in the form of a hex, eg #666666 */
  color: Scalars["HexColorCode"];
  /** `description` is an optional text that describes what the context represents, and it's purpose */
  description?: InputMaybe<Scalars["String"]>;
  /** `name` is a string up to 256 characters long that helps to identify the context */
  name: Scalars["String"];
};

export type AddCostCategoryMapping = {
  algorithm: CostMappingAlgorithm;
  provider: Provider;
  settings: CostSettingsInput;
  type: Scalars["String"];
};

export type AddDiscoveryRule = {
  environmentId: Scalars["String"];
  exclusive?: InputMaybe<Scalars["Boolean"]>;
  ibmResourceGroup?: InputMaybe<Scalars["Boolean"]>;
  match: DiscoveryRuleMatchInput;
  name: Scalars["String"];
  tagsOperator?: InputMaybe<RuleOperator>;
};

export type AddEnvironment = {
  /**
   * The NID of the application this new environment will be assigned to
   * each environment must be assigned to an application
   */
  applicationId: Scalars["String"];
  /** A list of contacts attached for this environment */
  contactIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: InputMaybe<Scalars["String"]>;
  /**
   * `displayNam`e is a string up to 256 characters long that helps to identify the environment
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: InputMaybe<Scalars["String"]>;
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars["String"];
  /** The id of the person that is the owner of the environment */
  ownerId: Scalars["String"];
  /** The type of the environment */
  type: EnvironmentType;
};

export type AddEnvironmentV2 = {
  /**
   * The NID of the application this new environment will be assigned to
   * each environment must be assigned to an application
   */
  applicationNid: Scalars["ID"];
  /** A list of contacts attached for this environment */
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: InputMaybe<Scalars["String"]>;
  /** `displayName` is a string up to 256 characters long that helps to identify the environment */
  displayName: Scalars["String"];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars["String"];
  /** The id of the person that is the owner of the environment */
  ownerId: Scalars["ID"];
  /** The type of the environment */
  type: EnvironmentType;
};

export type AddEvent = {
  content: Scalars["String"];
  nid: Scalars["String"];
  title: Scalars["String"];
  type: Scalars["String"];
};

export type AddKpiTarget = {
  businessContextId: Scalars["ID"];
  contactPersonId: Scalars["ID"];
  endDate: Scalars["DateTime"];
  goal: Scalars["Float"];
  name: Scalars["String"];
  startDate: Scalars["DateTime"];
  type: KpiTypes;
};

export type AddMappingRule = {
  cloudAccount?: InputMaybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  name: Scalars["String"];
  projectId?: InputMaybe<Scalars["String"]>;
  resourceGroup?: InputMaybe<Scalars["String"]>;
  resourceType?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<KeyValueInput>>;
};

export type AddOrgUnitTypeV2 = {
  /** `description` is an optional text that helps identify what the type represents, and it's purpose */
  description?: InputMaybe<Scalars["String"]>;
  /** `name` is a string up to 256 characters long helps identify the type */
  name: Scalars["String"];
};

export type AddOrgUnitV2 = {
  /** `contactId` is optional id of the contact person that should be assigned to the OrgUnit */
  contactId?: InputMaybe<Scalars["ID"]>;
  /** `context` is the ID of the business context this org unit is in */
  contextId: Scalars["ID"];
  /** `description` is an optional text that helps identify what the unit represents, and it's purpose */
  description?: InputMaybe<Scalars["String"]>;
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name: Scalars["String"];
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: InputMaybe<Scalars["ID"]>;
  /** `typeId` is optional id of the OrgUnitTypeV2 that can be assigned to the OrgUnit */
  typeId?: InputMaybe<Scalars["ID"]>;
};

export type AddPerson = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type AddSandbox = {
  userEmail: Scalars["String"];
};

export type AddSearchPresetInput = {
  filter?: InputMaybe<EstateV2Filter>;
  name: Scalars["String"];
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SearchSort>;
};

export type AddSearchPresetResponse = {
  __typename?: "AddSearchPresetResponse";
  preset: SearchPreset;
};

export type AddSearchPresetV2Input = {
  filter?: InputMaybe<SearchV2Filter>;
  name: Scalars["String"];
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SearchV2Sort>;
};

export type AddSearchPresetV2Response = {
  __typename?: "AddSearchPresetV2Response";
  preset: SearchPresetV2;
};

export type AddSyntheticEstateRecord = {
  cloudAccount: Scalars["String"];
  provider: Provider;
  providerId: Scalars["String"];
  resourceId: Scalars["String"];
  subtype: Scalars["String"];
};

/** Input */
export type AddTurbonomicIntegrationPluginInput = {
  contactPersonIds: Array<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  integrationType: IntegrationPluginType;
  name: Scalars["String"];
  password: Scalars["String"];
  url: Scalars["String"];
  username: Scalars["String"];
};

/** Input */
export type AddWorkflowInput = {
  active: Scalars["Boolean"];
  contactPersonIds: Array<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  externalIntegrations: Array<WorkflowExternalIntegrationsInput>;
  name: Scalars["String"];
  selectData: WorkflowSelectDataInput;
  triggers: Array<WorkflowTriggerInput>;
};

export type AllReservationsRecommendationsResponse = {
  __typename?: "AllReservationsRecommendationsResponse";
  data?: Maybe<ReservationsAndCommitmentsRecommendationsResponse>;
  payment: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
};

export type AnomaliesQueryInput = {
  /** `applicationId` is UUID of Nordcloud application */
  applicationId: Scalars["String"];
};

export type Anomaly = {
  __typename?: "Anomaly";
  /** `anomaly` describes whether cost on a particular day is anomaly or not */
  anomaly: Scalars["Boolean"];
  /** `anomalyCost` is a difference between cost and closest upper/lower estimate on a particular day */
  anomalyCost?: Maybe<Scalars["Float"]>;
  /** `cost` is real cost of application/customer/service type on a particular day */
  cost: Scalars["Float"];
  /** `date` is date on which event occured */
  date: Scalars["String"];
  /** `forecastedCost` is forecast of cost on a particular day */
  forecastedCost?: Maybe<Scalars["Float"]>;
  /** `lowerCostEstimate` is forecast on a particular day with subtracted half of threshold value */
  lowerCostEstimate?: Maybe<Scalars["Float"]>;
  /** `upperCostEstimate` is forecast on a particular day with added half of threshold value */
  upperCostEstimate?: Maybe<Scalars["Float"]>;
};

export type AnomalyDetailsResponse = {
  __typename?: "AnomalyDetailsResponse";
  count: Scalars["Int"];
  pages: Scalars["Int"];
  resources: Array<AnomalyService>;
};

export type AnomalyEstateRecord = {
  __typename?: "AnomalyEstateRecord";
  /** `cost` is a cost of estate record on a particular day */
  cost?: Maybe<Scalars["Float"]>;
  /** `name` is a cloud provider id of estate record */
  name?: Maybe<Scalars["String"]>;
  /** `nid` is Nordcloud ID of estate record */
  nid?: Maybe<Scalars["String"]>;
};

export type AnomalyService = {
  __typename?: "AnomalyService";
  /** `anomaly` describes whether cost on a particular day is anomaly or not */
  anomaly: Scalars["Boolean"];
  /** `anomalyCost` is a difference between cost and closest upper/lower estimate on a particular day */
  anomalyCost?: Maybe<Scalars["Float"]>;
  /** `cost` is real cost of resource type on a particular day */
  cost?: Maybe<Scalars["Float"]>;
  /** `costDifference` is difference in cost between previous day and current one */
  costDifference?: Maybe<Scalars["Float"]>;
  /** `expectedValue` is a cost that is expected by the model on a particular day */
  expectedValue?: Maybe<Scalars["Float"]>;
  /** @deprecated Deprecated manual counting in favor of costDifference */
  forecastedCost?: Maybe<Scalars["Float"]>;
  maxDailyCost?: Maybe<Scalars["Float"]>;
  minDailyCost?: Maybe<Scalars["Float"]>;
  /** `provider` is a cloud provider that the resource is connected to */
  provider?: Maybe<Provider>;
  /**
   * `subRows` is parametrized field containing subtypes. You need to provide the same `applicationId` and `date`
   * as in the root object. `type` is optional and can be inferred from root object.
   */
  subRows?: Maybe<Array<AnomalyServiceSubtype>>;
  /** `type` is type of resource that caused anomaly - e.g. - `rds` */
  type: Scalars["String"];
};

export type AnomalyServiceSubRowsArgs = {
  applicationId: Scalars["String"];
  date: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
};

export type AnomalyServiceSubtype = {
  __typename?: "AnomalyServiceSubtype";
  /** `cost` is a cloud provider that the resource is connected to */
  cost: Scalars["Float"];
  /** `subRows` is a list of estate records in particular subtype */
  subRows: Array<AnomalyEstateRecord>;
  /** `subType` is further division of `type` field, e.g. in `ec2/instance` - `instance` is a subtype */
  subType: Scalars["String"];
};

export type AppAndEnvIds = {
  __typename?: "AppAndEnvIds";
  applicationNids: Array<Maybe<Scalars["String"]>>;
  environmentNids: Array<Maybe<Scalars["String"]>>;
};

export type Application = {
  __typename?: "Application";
  /** Budget that can be set for the application. */
  budgetYearly: BudgetYearly;
  /** `cloudWasteAggregate` describes aggregated information about Cloud Waste */
  cloudWasteAggregate?: Maybe<CloudWasteAggregate>;
  /**
   * `co2e` is the total carbon emission of all resources assigned to the environments of the application,
   * `timePeriod` is the current/previous month for carbon emission,
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  co2e?: Maybe<Scalars["String"]>;
  /** A list of contacts attached for this environment */
  contacts?: Maybe<Array<Maybe<Person>>>;
  /**
   * `cost` is the total cost of all resources assigned to the environments of the application
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  cost?: Maybe<Cost>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars["String"]>;
  /** `displayName` is a string up to 256 characters long that helps to identify the application */
  displayName: Scalars["String"];
  /**
   * The environment field currently uses a lambda to buffer all results from
   * a paginated API, so it would be nice to stop using it and remove it
   * @deprecated use `environmentsV2` instead
   */
  environments?: Maybe<Array<Maybe<Environment>>>;
  /** A list of resources for this environment, paginated */
  environmentsV2?: Maybe<EnvironmentResponse>;
  finOpsOptimisation: FinOpsOptimisation;
  forecastSpendYearly: ForecastSpendYearly;
  /** `hasAnomalies` is information if cost anomalies were found in application */
  hasAnomalies?: Maybe<Scalars["Boolean"]>;
  /**
   * `id` is the id from CustomerDB
   * @deprecated id and nid have been unified, and are going to be the same field, use `nid` instead
   */
  id: Scalars["String"];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars["String"];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars["String"];
  /** Optionally the person that is the owner of the environment */
  owner?: Maybe<Person>;
  /** `resourceCount` is the number of resources that belong to all environments in this application */
  resourceCount: Scalars["Int"];
  /**
   * `spend` is the total cost of all resources assigned to the environments of the application,
   * for current/previous month depending on `timePeriod`
   * `providers` is an optional filter to find costs only for certain set of providers
   * @deprecated use `cost`
   */
  spend?: Maybe<Scalars["String"]>;
  spendByDateRange: SpendByDateRange;
  spendYearly: SpendYearly;
};

export type ApplicationBudgetYearlyArgs = {
  year: Scalars["String"];
};

export type ApplicationCo2eArgs = {
  providers?: InputMaybe<Array<Provider>>;
  timePeriod: TimePeriodType;
};

export type ApplicationCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type ApplicationEnvironmentsV2Args = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type ApplicationForecastSpendYearlyArgs = {
  year: Scalars["String"];
};

export type ApplicationSpendArgs = {
  providers?: InputMaybe<Array<Provider>>;
  timePeriod: TimePeriodType;
};

export type ApplicationSpendByDateRangeArgs = {
  endDate: Scalars["String"];
  startDate: Scalars["String"];
};

export type ApplicationSpendYearlyArgs = {
  year: Scalars["String"];
};

export type ApplicationAccumulatedCostInput = {
  applicationId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
};

export type ApplicationAccumulatedCostResponse = {
  __typename?: "ApplicationAccumulatedCostResponse";
  timePoints?: Maybe<Array<Maybe<TimePoint>>>;
  total: Scalars["String"];
};

export type ApplicationName = {
  __typename?: "ApplicationName";
  displayName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nid: Scalars["ID"];
};

export type ApplicationOtherCategoriesCostInput = {
  applicationId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

export type ApplicationOtherCategoriesCostResponse = {
  __typename?: "ApplicationOtherCategoriesCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

export type ApplicationTopCategoriesCostInput = {
  applicationId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

export type ApplicationTopCategoriesCostResponse = {
  __typename?: "ApplicationTopCategoriesCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

export type ApplicationTopEnvironmentsCostInput = {
  applicationId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

export type ApplicationTopEnvironmentsCostResponse = {
  __typename?: "ApplicationTopEnvironmentsCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

export type ApplicationsPaginatedResponse = {
  __typename?: "ApplicationsPaginatedResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Application>>;
};

export type Assignments = {
  __typename?: "Assignments";
  appCount: Scalars["Int"];
  envCount: Scalars["Int"];
};

export type AwsAdditionalCredentials = {
  /**
   * Additionalcredentials for AWS china may include static credentials in addition to assumed role chain
   * for `AWS` provider, the credentials are a role chain that is assumed from a proxy account
   */
  credentials: AwsCredentials;
  /** Aws region */
  region: Scalars["String"];
};

export type AwsCredentials = {
  /** Part of Access key, used to sign programmatic requests that you make to AWS */
  awsAccessKeyId: Scalars["ID"];
  /** Part of Access key, used to sign programmatic requests that you make to AWS */
  awsSecretAccessKey?: InputMaybe<Scalars["String"]>;
};

export type AwsRole = {
  /** External Id is used to give 3rd party access to your AWS resources */
  externalId: Scalars["ID"];
  /** AWS Role Name */
  roleName?: InputMaybe<Scalars["String"]>;
};

export type AzureBillingData = {
  __typename?: "AzureBillingData";
  /** `capacitorCustomerId` is Capacitor Customer ID */
  capacitorCustomerId: Scalars["String"];
  /** `enrollmentNumber` is how the account is identified by Azure */
  enrollmentNumber: Scalars["String"];
  /** `isActive` is a status of billing data connection */
  isActive: Scalars["Boolean"];
  /** `isOnboarding` is a status of billing data onboarding */
  isOnboarding: Scalars["Boolean"];
};

export type BatchRemoveAccountsInput = {
  /** Array of accounts nids to be deleted */
  nids: Array<Scalars["ID"]>;
  /** Cloud accounts query variables to be refetched after deletion */
  responseParameters: CloudAccountsInput;
};

export enum BillingPeriod {
  Current = "CURRENT",
  Previous = "PREVIOUS",
}

/**
 * Yearly budget for an entity (OrgUnit|Environment|Application)
 * Budgets are represented as a list of 12 monthly budgets (from Jan to Dec, if available, otherwise 0) and as a yearly sum of budgets.
 */
export type BudgetYearly = {
  __typename?: "BudgetYearly";
  budgetByMonth: Array<Scalars["String"]>;
  /** The `id` field is a combination of all identifiers for a single budget for a single entity. It's used only for caching purposes */
  id: Scalars["ID"];
  yearlySum: Scalars["String"];
};

/**
 * BudgetYearlyInput represents a yearly budget that can be set for an entity (OrgUnit|Environment|Application),
 * as a list of 12 values for 12 months, and a year for which the budget must be set (YYYY format).
 */
export type BudgetYearlyInput = {
  /** budgetByMonth represent budgets - 12 values with 2 point decimal precision, one for each month from Jan to Dec */
  budgetByMonth: Array<Scalars["Float"]>;
  /**
   * The currency in which the budget will be saved. If not provided, base currency set in customerConfig will be used.
   *
   * Currencies provided here will only be valid if they are handled by the `kantor` (https://kantor.misc.nordcloudapp.com/docs)
   * service.
   */
  currency?: InputMaybe<Scalars["String"]>;
  /** year for which the budget will be set - YYYY format */
  year: Scalars["String"];
};

export type BulkResourceCostSplitRuleInput = {
  algorithm: CostMappingAlgorithm;
  resources: Array<ResourceProps>;
  settings: ResourceCostSplitSettings;
};

export type BulkResourceCostSplitRules = {
  __typename?: "BulkResourceCostSplitRules";
  algorithm: CostMappingAlgorithm;
  resources: Array<ResourcePropsType>;
  settings: CostSplitRuleSettings;
};

export type BusinessContext = {
  __typename?: "BusinessContext";
  /** `color` is a string in the form of a 6 character hex, eg #666666 */
  color?: Maybe<Scalars["String"]>;
  costAllocation: CostAllocation;
  /** `description` is an optional text describing what the context represents, and it's purpose */
  description?: Maybe<Scalars["String"]>;
  /**
   * Business contexts have id, not nid, to identify them since they are not searchable
   * entities within Klarity
   */
  id: Scalars["ID"];
  isPrimary?: Maybe<Scalars["Boolean"]>;
  /**
   * Get KPIs for given Business Context
   * `bcId` is uuid of businessContext
   */
  kpis: BusinessContextKpis;
  /** `name` is a string up to 256 characters long that helps to identify the context */
  name: Scalars["String"];
  /**
   * `orgUnits` are listing ALL the organizational units that are retrieved from the backend,
   * since they are a list containing a tree like structure
   * they are returned as a 'non paginated response' but contain the total count
   */
  orgUnits?: Maybe<OrgUnitsV2NonPaginatedResponse>;
};

export type BusinessContextCostAllocationArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type BusinessContextKpi = {
  details: KpiDetails;
  targets?: Maybe<Array<KpiTarget>>;
};

export type BusinessContextKpiDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type BusinessContextKpiTargetsArgs = {
  selection: KpiTargetSelection;
};

export type BusinessContextKpis = {
  __typename?: "BusinessContextKpis";
  costAllocationCoverage: KpiCostAllocationCoverage;
  nonProdHours: KpiNonProdHoursBusinessContext;
  overallWaste: KpiOverallWasteBusinessContext;
};

export type BusinessContextResponse = {
  __typename?: "BusinessContextResponse";
  businessContexts?: Maybe<Array<BusinessContext>>;
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type Category = {
  __typename?: "Category";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ChargeType = {
  __typename?: "ChargeType";
  name: Scalars["String"];
};

export type ChargeTypeCost = {
  __typename?: "ChargeTypeCost";
  chargeType: Scalars["String"];
  cost: Scalars["String"];
};

export type ChargeTypesResponse = {
  __typename?: "ChargeTypesResponse";
  chargeTypes?: Maybe<Array<ChargeType>>;
};

export type ChartGroupCost = {
  __typename?: "ChartGroupCost";
  id: Scalars["String"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ChartGroupTimePoint = {
  __typename?: "ChartGroupTimePoint";
  date: Scalars["String"];
  groups?: Maybe<Array<Maybe<ChartGroupCost>>>;
  value: Scalars["String"];
};

export type ChartJobData = {
  __typename?: "ChartJobData";
  count: Scalars["Int"];
  extraDetails?: Maybe<Array<Maybe<ChartJobExtraDetails>>>;
  timePoints?: Maybe<Array<Maybe<ChartGroupTimePoint>>>;
  total: Scalars["String"];
};

export type ChartJobExtraDetails = {
  __typename?: "ChartJobExtraDetails";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type ChartJobStatusResponse = {
  __typename?: "ChartJobStatusResponse";
  data?: Maybe<ChartJobData>;
  status: SearchJobStatus;
};

/** The data of the async chart job. */
export type ChartJobTableData = {
  __typename?: "ChartJobTableData";
  /** Count of all entries. */
  count: Scalars["Int"];
  /** Number of pages available. */
  pages: Scalars["Int"];
  /** The results of the async chart job. */
  results?: Maybe<Array<Maybe<ChartJobTableTimePoint>>>;
};

/** Entry of the async job result. */
export type ChartJobTableGroup = {
  __typename?: "ChartJobTableGroup";
  /** The cost of the group. */
  cost: Scalars["String"];
  /** Key of the group */
  key: Scalars["String"];
  /** Name of the key */
  name: Scalars["String"];
};

/** The response of the chart table job with data when job is completed. */
export type ChartJobTableResponse = {
  __typename?: "ChartJobTableResponse";
  /** The data after the job is completed. */
  data?: Maybe<ChartJobTableData>;
  /** Status of the job. */
  status: SearchJobStatus;
};

/** Entry of the async job time point. */
export type ChartJobTableTimePoint = {
  __typename?: "ChartJobTableTimePoint";
  /** Date */
  date: Scalars["String"];
  /** Groups per timepoint. */
  groups?: Maybe<Array<ChartJobTableGroup>>;
  /** Total cost of the date */
  total: Scalars["String"];
};

/** Input for chart job sorting. */
export type ChartTableSort = {
  field: ChartTableSortField;
  order: ChartTableSortOrder;
};

/** Chart job sorting fields denominates the field by which result data will be ordered. */
export enum ChartTableSortField {
  /** Sort by cost */
  Cost = "COST",
  /** Sort by name */
  Name = "NAME",
}

/** Chart sorting order is used to differentiate between ascending 'ASC' and descending 'DSC' orders. */
export enum ChartTableSortOrder {
  Asc = "ASC",
  Dsc = "DSC",
}

export enum ChartType {
  Accumulated = "ACCUMULATED",
  Application = "APPLICATION",
  Category = "CATEGORY",
  CloudAccount = "CLOUD_ACCOUNT",
  EnvironmentType = "ENVIRONMENT_TYPE",
  OrgUnit = "ORG_UNIT",
  Provider = "PROVIDER",
  Region = "REGION",
  Service = "SERVICE",
  Tags = "TAGS",
}

export type CloneWorkflowInput = {
  active: Scalars["Boolean"];
  contactPersonIds?: InputMaybe<Array<Scalars["String"]>>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type CloudAccountCostByChargeType = {
  __typename?: "CloudAccountCostByChargeType";
  chargeType: Scalars["String"];
  cost: Scalars["String"];
};

export type CloudAccountCostByProvider = {
  __typename?: "CloudAccountCostByProvider";
  cost: Scalars["String"];
  count: Scalars["Int"];
  hasWarning: Scalars["Boolean"];
  provider: Scalars["String"];
};

export type CloudAccountCostsByChargeTypeResponse = {
  __typename?: "CloudAccountCostsByChargeTypeResponse";
  costsByChargeType?: Maybe<Array<CloudAccountCostByChargeType>>;
};

export type CloudAccountCostsByProviderResponse = {
  __typename?: "CloudAccountCostsByProviderResponse";
  costsByProvider?: Maybe<Array<CloudAccountCostByProvider>>;
};

export type CloudAccountNidItem = {
  __typename?: "CloudAccountNidItem";
  nid: Scalars["String"];
};

export type CloudAccountNidsResponse = {
  __typename?: "CloudAccountNidsResponse";
  accounts?: Maybe<Array<CloudAccountNidItem>>;
  count: Scalars["Int"];
};

export type CloudAccountSavingCosts = {
  __typename?: "CloudAccountSavingCosts";
  co2e?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["String"]>;
};

/** Represents status for all types of accounts (CLOUD and other) */
export enum CloudAccountStatus {
  ApiOnly = "API_ONLY",
  BillingOnly = "BILLING_ONLY",
  FullAccess = "FULL_ACCESS",
  NoAccess = "NO_ACCESS",
  NoData = "NO_DATA",
}

export type CloudAccountsFilter = {
  /** Return resources matching specified cloud provider ID(s) */
  cloudProviderId?: InputMaybe<Array<Scalars["String"]>>;
  /** Status filter */
  cloudStatusV2?: InputMaybe<Array<CloudAccountStatus>>;
  /** When true, returns accounts that do not match `provider` field */
  isProviderExcluded?: InputMaybe<Scalars["Boolean"]>;
  /** When true, returns accounts that do not match `cloudStatusV2` field */
  isStatusExcluded?: InputMaybe<Scalars["Boolean"]>;
  /** Return cloud account by klarity nid */
  nid?: InputMaybe<Scalars["ID"]>;
  provider?: InputMaybe<Array<Provider>>;
  /** Return resources which belong to given provider type */
  providerType?: InputMaybe<Array<Scalars["String"]>>;
};

export type CloudAccountsInput = {
  /** Cloud accounts records filter */
  filter?: InputMaybe<CloudAccountsFilter>;
  /** Limit the amount of records returned */
  limit: Scalars["Int"];
  /** Cloud accounts records ordering */
  order?: InputMaybe<CloudAccountsSort>;
  /** Used to return records of certain page */
  page?: InputMaybe<Scalars["Int"]>;
  /** Cloud accounts records filter by query */
  query?: InputMaybe<Scalars["String"]>;
};

export type CloudAccountsResponse = {
  __typename?: "CloudAccountsResponse";
  accounts?: Maybe<Array<Account>>;
  count: Scalars["Int"];
  pages: Scalars["Int"];
};

export type CloudAccountsSort = {
  /** Return resources of matching charge type or with no charge type */
  chargeType?: InputMaybe<Scalars["String"]>;
  /** Sort resources by field */
  field: CloudAccountsSortField;
  /** Sort resources by ascending or descending order */
  order: SearchSortOrder;
};

export enum CloudAccountsSortField {
  ChargeType = "CHARGE_TYPE",
  CloudStatus = "CLOUD_STATUS",
  Name = "NAME",
  TotalCost = "TOTAL_COST",
}

export enum CloudProviderType {
  Cloud = "CLOUD",
  Kubernetes = "KUBERNETES",
  Openshift = "OPENSHIFT",
  Vmware = "VMWARE",
}

export type CloudResource = {
  __typename?: "CloudResource";
  /** `assignments contain counts of applications and environments returned from search */
  assignments?: Maybe<Assignments>;
  /** `category` is estate record category based on `type` and `subtype` of the resource. */
  category?: Maybe<Scalars["String"]>;
  /** `cloudAccount` contains a CloudAccount object from Klarity if it's available */
  cloudAccount?: Maybe<Account>;
  /**
   * ID of the cloud account, e.g. `123123123123` for AWS or subscriptionId for Azure
   * in some cases cloudAccoundId can be empty, e.g. for S3 buckets scanned from capacity
   */
  cloudAccountId?: Maybe<Scalars["String"]>;
  /** `cloudWaste` describes information about Cloud Waste */
  cloudWaste?: Maybe<CloudWaste>;
  /** `co2e` is the total carbon emission of this resource */
  co2e?: Maybe<Co2e>;
  /** `cost` is the total cost of this resource */
  cost?: Maybe<Cost>;
  /** `costSavings` is the total CostSavings of this resource */
  costSavings?: Maybe<Array<CostSavings>>;
  /** `createdAt` is the creation date of the resource */
  createdAt?: Maybe<Scalars["String"]>;
  /**
   * `drifted` is true, when drifter found a new assignment for this resource, when drifted
   * is false, `pendingAssignment` will be null
   */
  drifted?: Maybe<Scalars["Boolean"]>;
  /**
   * Instance Comparator for EC2
   *
   * This query will suggest comparable instances (by VCPU and RAM)
   * and show first 5 cheapest options along the information about the
   * instance used as an argument
   */
  ec2InstanceComparator?: Maybe<Array<Ec2Instance>>;
  /**
   * Usage Metrics for EC2 Estate Records
   *
   * If volumeId (EBS) is not provided, metrics for Instance Store are returned
   * (if there is an Instance Store associated with the EC2 instance)
   */
  ec2UsageMetrics?: Maybe<Ec2UsageMetrics>;
  /** `enrichments` contains enriching data from external integrations */
  enrichments?: Maybe<Array<Enrichment>>;
  /**
   * `environment` shows what environment has this resource been attached to
   * @deprecated use environmentsPaginated instead
   */
  environment?: Maybe<Environment>;
  /** `environmentsPaginated` returns attached environments with pagination capabilities */
  environmentsPaginated: EnvironmentAndSpendsPaginated;
  /**
   * EOLMetadata represents a set of specific metadata related to an instance system's EOL. This is only provided for a
   * specific subset of type/subtype combinations for cloud resources, and will be undefined otherwise.
   */
  eolMetadata?: Maybe<EolMetadata>;
  /** ID the same as nid in Cloud Estate */
  id: Scalars["String"];
  /**
   * `links` is an estate mapping array, the link is created from a source estate record and a target estate record
   * For example, a VM estate record should be linked to a Kubernetes Node estate record.
   * This array not only includes the links that current record is the source of, but also the target.
   */
  links?: Maybe<Array<Link>>;
  /**
   * `metadataAndTags` contains both `metadata` and `tags` since they are both resolved
   * from the same source of data
   */
  metadataAndTags?: Maybe<MetadataAndTags>;
  /**
   * `name` is what the resource is named within the provider,
   * e.g. `auto-backup-scanner-lambda-function-X678Y`
   */
  name: Scalars["String"];
  /**
   * `pendingAssignment` is the environment that mapper wants to assign this resource to, or in other
   * words the environment which this resource has a drift to
   * when the `updateDriftStatuses` mutation is used for this resource, the environment from `pendingAssignment`
   * will be in the `environment` field, meaning that the resource has been drifted
   */
  pendingAssignment?: Maybe<Environment>;
  provider: Provider;
  /** `providerId` is what the resource is identified by it's provider e.g. `arn` in AWS */
  providerId: Scalars["String"];
  /**
   * `providerType` is type/subtype of the resource concatenated with `/`
   * e.g. for an AWS Lambda function it will be `lambda/function`
   * for some resources subtype is not relevant, there providerType
   * will be just main type, e.g. `s3`
   */
  providerType?: Maybe<Scalars["String"]>;
  /**
   * `region` is taken straight from provider data, for some resources
   * the region field may be `global`, it may also be null for some resources
   */
  region?: Maybe<Scalars["String"]>;
  /**
   * `reservation` describes the time period in which the resource is reserved
   * Can be non-null only if the CloudResource comes from the reservableResources query
   */
  reservation?: Maybe<Reservation>;
  /** `resourceConsumption` is the monthly consumption of this resource */
  resourceConsumption?: Maybe<ResourceConsumptionResponse>;
  /** `savingsAmount` describes amount of savings we can get by rightsizing our VMs */
  savingsAmount?: Maybe<SavingsAmount>;
  /** `searchCost` is the filtered cost of the resource returned from search */
  searchCost?: Maybe<Scalars["String"]>;
  /**
   * `spend` the total cost of this resource for the current/previous month depending on `timePeriod`
   * @deprecated use `cost`
   */
  spend?: Maybe<Scalars["String"]>;
  /** `validThrough` is the last period in which the estate record is visible */
  validThrough?: Maybe<Scalars["String"]>;
};

export type CloudResourceCo2eArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type CloudResourceCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type CloudResourceEc2InstanceComparatorArgs = {
  instanceFamily: Ec2InstanceFamily;
  instancesFromSavingsSuggestions?: InputMaybe<Array<Scalars["String"]>>;
  region: Scalars["String"];
  tenancy?: InputMaybe<Ec2Tenancy>;
  type: Scalars["String"];
  usageOperation: Scalars["String"];
};

export type CloudResourceEc2UsageMetricsArgs = {
  endDate: Scalars["String"];
  granulation: Ec2MetricGranulation;
  startDate: Scalars["String"];
  volumeId?: InputMaybe<Scalars["String"]>;
};

export type CloudResourceEnvironmentsPaginatedArgs = {
  envNid?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type CloudResourceLinksArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
};

export type CloudResourceSavingsAmountArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type CloudResourceSpendArgs = {
  providers?: InputMaybe<Array<Provider>>;
  timePeriod: TimePeriodType;
};

export type CloudResourcesResponse = {
  __typename?: "CloudResourcesResponse";
  count: Scalars["Int"];
  pages: Scalars["Int"];
  resources?: Maybe<Array<CloudResource>>;
};

export type CloudWaste = {
  __typename?: "CloudWaste";
  /** `isWaste` describes whether the resource was marked as a waste. */
  isWaste: Scalars["Boolean"];
  /** `reason` describes reason why the resource was marked as a waste. */
  reason?: Maybe<Scalars["String"]>;
};

export type CloudWasteAggregate = {
  __typename?: "CloudWasteAggregate";
  /** `co2e` describes the total sum of carbon emission that are marked as cloud co2e waste inside entity */
  co2e?: Maybe<Scalars["String"]>;
  /** `cost` describes the total sum of spends that are marked as cloud waste inside entity */
  cost?: Maybe<Scalars["String"]>;
  /** `count` describes the number of cloud waste records inside entity */
  count?: Maybe<Scalars["Int"]>;
  /** `hasWaste` describes whether entity contains cloud waste records */
  hasWaste?: Maybe<Scalars["Boolean"]>;
};

export enum CloudWasteCategoryKey {
  Idle = "IDLE",
  Orphaned = "ORPHANED",
  OverProvisioned = "OVER_PROVISIONED",
  Turbonomic = "TURBONOMIC",
}

export type CloudWastePoliciesCategorised = {
  __typename?: "CloudWastePoliciesCategorised";
  category: CloudWasteCategoryKey;
  categoryName: Scalars["String"];
  policies: Array<CloudWastePolicy>;
};

export type CloudWastePolicy = {
  __typename?: "CloudWastePolicy";
  id: CloudWasteSettingKey;
  name: Scalars["String"];
};

export type CloudWastePolicySummary = {
  __typename?: "CloudWastePolicySummary";
  category?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<CloudWasteSettingKey>;
  title?: Maybe<Scalars["String"]>;
};

export type CloudWastePolicySummaryCategorised = {
  __typename?: "CloudWastePolicySummaryCategorised";
  category?: Maybe<CloudWasteCategoryKey>;
  co2e?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["String"]>;
  costYearly?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  policies?: Maybe<Array<Maybe<CloudWasteSettingKey>>>;
};

export type CloudWasteSetting = {
  __typename?: "CloudWasteSetting";
  description: Scalars["String"];
  id: CloudWasteSettingKey;
  name: Scalars["String"];
  parameters: Array<CloudWasteSettingParameter>;
  status: CloudWasteSettingStatus;
};

export type CloudWasteSettingCategorised = {
  __typename?: "CloudWasteSettingCategorised";
  category: CloudWasteCategoryKey;
  settings: Array<CloudWasteSetting>;
};

export enum CloudWasteSettingKey {
  IdleDatabase = "IDLE_DATABASE",
  IdleVmGcp = "IDLE_VM_GCP",
  KubeNodeOverProvisionedCpu = "KUBE_NODE_OVER_PROVISIONED_CPU",
  KubeNodeOverProvisionedMemory = "KUBE_NODE_OVER_PROVISIONED_MEMORY",
  KubePodOverProvisionedCpu = "KUBE_POD_OVER_PROVISIONED_CPU",
  KubePodOverProvisionedMemory = "KUBE_POD_OVER_PROVISIONED_MEMORY",
  OscpNodeOverProvisionedCpu = "OSCP_NODE_OVER_PROVISIONED_CPU",
  OscpNodeOverProvisionedMemory = "OSCP_NODE_OVER_PROVISIONED_MEMORY",
  OscpPodOverProvisionedCpu = "OSCP_POD_OVER_PROVISIONED_CPU",
  OscpPodOverProvisionedMemory = "OSCP_POD_OVER_PROVISIONED_MEMORY",
  OutdatedSnapshot = "OUTDATED_SNAPSHOT",
  OverProvisionedDb = "OVER_PROVISIONED_DB",
  OverProvisionedVm = "OVER_PROVISIONED_VM",
  StoppedVm = "STOPPED_VM",
  TurbonomicAction = "TURBONOMIC_ACTION",
  UnassignedIp = "UNASSIGNED_IP",
  /** @deprecated security groups are not supported anymore` */
  UnassignedSecurityGroup = "UNASSIGNED_SECURITY_GROUP",
  UnattachedVolume = "UNATTACHED_VOLUME",
}

export type CloudWasteSettingParameter = CloudWasteSettingParameterInt;

export enum CloudWasteSettingParameterId {
  Days = "DAYS",
  Percentage = "PERCENTAGE",
}

export type CloudWasteSettingParameterInt = {
  __typename?: "CloudWasteSettingParameterInt";
  description: Scalars["String"];
  id: CloudWasteSettingParameterId;
  max: Scalars["Int"];
  min: Scalars["Int"];
  value: Scalars["Int"];
};

export enum CloudWasteSettingStatus {
  Disabled = "DISABLED",
  Enabled = "ENABLED",
}

export type CloudWasteSummaryCategorisedResponse = {
  __typename?: "CloudWasteSummaryCategorisedResponse";
  /** `categories` describes the basic information and cost of cloud waste policy categories */
  categories?: Maybe<Array<Maybe<CloudWastePolicySummaryCategorised>>>;
  /** `totalCo2e` describes sum of cloud waste co2 emission of all queried policies for whole year */
  totalCo2e?: Maybe<Scalars["String"]>;
  /** `totalCost` describes summarized cloud waste cost of all queried policies */
  totalCost?: Maybe<Scalars["String"]>;
  /** `totalCostYearly` describes summarized cloud waste cost of all queried policies for whole year */
  totalCostYearly?: Maybe<Scalars["String"]>;
};

export type CloudWasteSummaryResponse = {
  __typename?: "CloudWasteSummaryResponse";
  /** `policies` describes the basic information and cost of cloud waste policies */
  policies?: Maybe<Array<Maybe<CloudWastePolicySummary>>>;
  /** `totalCost` describes summarized cloud waste cost of all queried policies */
  totalCost?: Maybe<Scalars["String"]>;
};

export type ClusterConsumedResource = {
  __typename?: "ClusterConsumedResource";
  date?: Maybe<Scalars["String"]>;
  used?: Maybe<Scalars["String"]>;
};

export type ClusterResourceConsumptionResponse = {
  __typename?: "ClusterResourceConsumptionResponse";
  accountId: Scalars["String"];
  cpu?: Maybe<Array<ClusterConsumedResource>>;
  memory?: Maybe<Array<ClusterConsumedResource>>;
  storageRw?: Maybe<Array<StorageReadWriteResource>>;
};

export type Co2e = {
  __typename?: "Co2e";
  currentMonth: Scalars["String"];
  currentMonthDaily?: Maybe<Array<Maybe<Scalars["String"]>>>;
  forecast?: Maybe<Scalars["String"]>;
  previousMonth: Scalars["String"];
  /**
   * `previousMonthDayToDay` represents cost in previous month up to current day.
   * For example, if today is 20th then it will be the cost in previous month from 1st to 20th.
   */
  previousMonthDayToDay?: Maybe<Scalars["String"]>;
};

export type CommitmentsCoverageDetailsResponse = {
  __typename?: "CommitmentsCoverageDetailsResponse";
  averageCoverage?: Maybe<Scalars["String"]>;
  coverageSavedCost?: Maybe<Scalars["String"]>;
  measurementUnit?: Maybe<Scalars["String"]>;
  onDemandCostPercent?: Maybe<Scalars["String"]>;
  platform?: Maybe<Scalars["String"]>;
  potentialSavings?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  resource?: Maybe<Scalars["String"]>;
  resourceTypesCount?: Maybe<Scalars["Int"]>;
  riCostPercent?: Maybe<Scalars["String"]>;
  spCostPercent?: Maybe<Scalars["String"]>;
  totalCoveredHours?: Maybe<Scalars["String"]>;
  totalOnDemandCost?: Maybe<Scalars["String"]>;
  totalOnDemandHours?: Maybe<Scalars["String"]>;
};

export enum ConnectionStatus {
  Connected = "CONNECTED",
  Failure = "FAILURE",
}

export type ConsumedResource = {
  __typename?: "ConsumedResource";
  date?: Maybe<Scalars["String"]>;
  requested?: Maybe<Scalars["String"]>;
  used?: Maybe<Scalars["String"]>;
};

export type Cost = {
  __typename?: "Cost";
  /**
   * `count` represents the quantity of internal resources that are in the resource. Example:
   * in GCP case, project type records have services, so to get price of project type record
   *  we sum up services prices.
   */
  count?: Maybe<Scalars["Int"]>;
  currentMonth: Scalars["String"];
  currentMonthDaily?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** `currentMonthLastSpendDate` is used to check when resource was last seen */
  currentMonthLastSpendDate?: Maybe<Scalars["String"]>;
  forecast?: Maybe<Scalars["String"]>;
  lastThreeMonthsDaily?: Maybe<Array<Maybe<MonthDaily>>>;
  /**
   * `originalCost` represents original cost of resource after cost reallocation. Example:
   * in KUBERNETES case original cost will be shown in VM after cost was reallocated to nodes.
   */
  originalCost?: Maybe<OriginalCost>;
  previousMonth: Scalars["String"];
  /**
   * `previousMonthDayToDay` represents cost in previous month up to current day.
   * For example, if today is 20th then it will be the cost in previous month from 1st to 20th.
   */
  previousMonthDayToDay?: Maybe<Scalars["String"]>;
};

export type CostAllocation = {
  __typename?: "CostAllocation";
  allocated: Scalars["String"];
  unallocated: Scalars["String"];
};

export type CostAllocationTimeSeries = {
  __typename?: "CostAllocationTimeSeries";
  allocated: Scalars["String"];
  date: Scalars["String"];
  isEstimated: Scalars["Boolean"];
  unallocated: Scalars["String"];
  value: Scalars["String"];
};

export type CostCategory = {
  __typename?: "CostCategory";
  label?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type CostCategoryMapping = {
  __typename?: "CostCategoryMapping";
  algorithm?: Maybe<CostMappingAlgorithm>;
  id?: Maybe<Scalars["String"]>;
  provider?: Maybe<Provider>;
  settings?: Maybe<CostSettings>;
  type?: Maybe<Scalars["String"]>;
};

export enum CostMappingAlgorithm {
  Equal = "EQUAL",
  Manual = "MANUAL",
  Proportional = "PROPORTIONAL",
}

export type CostResource = {
  __typename?: "CostResource";
  /** `assignments contain counts of applications and environments returned from search */
  assignments?: Maybe<Assignments>;
  /** `co2e` is the total carbon emission of the resource before it was split between environments */
  co2e?: Maybe<Co2e>;
  /** `cost` is the total cost of the resource, before it was split between environments */
  cost?: Maybe<Cost>;
  /**
   * `environments` are coupled with spend for each of the environment, this allows to see how
   * the cost of this resource has been split between environments according to splitting rules
   * @deprecated use environmentsPaginated instead
   */
  environments?: Maybe<Array<EnvironmentAndSpends>>;
  /**
   * `environmentsPaginated` same as `environments` but with pagination capabilities
   * You can use argument `envNid` to filter output result to only a selected environment.
   */
  environmentsPaginated: EnvironmentAndSpendsPaginated;
  /** ID the same as nid in Cloud Estate */
  id: Scalars["String"];
  /**
   * Costs are not attached to cloudAccounts, therefore there is no `cloudAccountId` field
   *
   * MetadataAndTags contains both `metadata` and `tags` since they are both resolved
   * from the same source of data
   */
  metadataAndTags?: Maybe<MetadataAndTags>;
  provider: Provider;
  /** `providerType` for cost is the type of the cost, e.g. `Support` */
  providerType?: Maybe<Scalars["String"]>;
  /**
   * Costs don't have name, therefore there is no `name` field
   *
   * `region` for cost is always `global`
   */
  region?: Maybe<Scalars["String"]>;
  /** `searchCost` is the filtered cost of the resource returned from search */
  searchCost?: Maybe<Scalars["String"]>;
  /**
   * `spendOverall` is the total cost of the resource, before it was split between environments,
   * for current/previous month depending on `timePeriod`
   * @deprecated use `cost`
   */
  spendOverall?: Maybe<Scalars["String"]>;
};

export type CostResourceCo2eArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type CostResourceCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type CostResourceEnvironmentsPaginatedArgs = {
  envNid?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type CostResourceSpendOverallArgs = {
  providers?: InputMaybe<Array<Provider>>;
  timePeriod: TimePeriodType;
};

export type CostSavings = {
  __typename?: "CostSavings";
  action: Scalars["String"];
  /** `annualCo2e` is the carbon emissions savings amount of this resource for the next 12 month */
  annualCo2e: Scalars["String"];
  annualCost: Scalars["String"];
  cloudUrl?: Maybe<Scalars["String"]>;
  /** `co2e` is the carbon emissions savings amount of this resource */
  co2e: Scalars["String"];
  cost: Scalars["String"];
  detectionId?: Maybe<Scalars["String"]>;
  /** @deprecated this field is no longer used */
  impact?: Maybe<Scalars["String"]>;
  metadata?: Maybe<CostSavingsMetadata>;
  policyId: CloudWasteSettingKey;
  problem: Scalars["String"];
};

export type CostSavingsMetadata = {
  __typename?: "CostSavingsMetadata";
  /**
   * Current instance type used to make this recommendation
   *
   * Returned for overprovisioned AWS EC2 instances only
   */
  currentInstanceType?: Maybe<Scalars["String"]>;
  /**
   * Instance type to switch to, according to this recommendation
   *
   * Returned for overprovisioned AWS EC2 instances only
   */
  recommendedInstanceType?: Maybe<Scalars["String"]>;
};

export type CostSettings = {
  __typename?: "CostSettings";
  allEnvironments?: Maybe<Scalars["Boolean"]>;
  environments?: Maybe<Array<EnvCostShare>>;
};

export type CostSettingsInput = {
  allEnvironments?: InputMaybe<Scalars["Boolean"]>;
  environments?: InputMaybe<Array<EnvCostShareInput>>;
};

export type CostSplitRuleSettings = {
  __typename?: "CostSplitRuleSettings";
  allEnvironments?: Maybe<Scalars["Boolean"]>;
  environments?: Maybe<Array<EnvCostSplits>>;
};

export type CostWithPeriod = {
  __typename?: "CostWithPeriod";
  cost: Cost;
  period: Scalars["String"];
};

export type CreateAccessInput = {
  applications?: InputMaybe<Array<Scalars["String"]>>;
  description: Scalars["String"];
  expirationDate?: InputMaybe<Scalars["String"]>;
  role: Role;
};

/** Requests a specific page of customer's application cost over the given date range. */
export type CustomerApplicationsCostInput = {
  /** end of date range */
  endDate: Scalars["String"];
  /** returned cost granularity */
  granularity?: InputMaybe<Granularity>;
  /** amount of applications to retrieve per time point */
  limit: Scalars["Int"];
  /** page number */
  page?: InputMaybe<Scalars["Int"]>;
  /** start of date range */
  startDate: Scalars["String"];
};

export type CustomerApplicationsCostResponse = {
  __typename?: "CustomerApplicationsCostResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  timePoints: Array<CustomerGroupTimePoint>;
};

/** Returns customer costs at the desired granularity as category-specific TimePoints, with granularity-specific totals */
export type CustomerCategoriesCostResponse = {
  __typename?: "CustomerCategoriesCostResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  timePoints?: Maybe<Array<Maybe<CustomerGroupTimePoint>>>;
  total: Scalars["String"];
};

/** Requests a specific page of categorized customer costs over the given period. */
export type CustomerCategorizedCostInput = {
  /** a predefined set of categories to calculate costs for */
  categoryId?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** end of date range */
  endDate: Scalars["String"];
  /** returned cost granularity */
  granularity?: InputMaybe<Granularity>;
  /** amount of categories to retrieve (per page) */
  limit: Scalars["Int"];
  /** page number */
  page?: InputMaybe<Scalars["Int"]>;
  /** start of date range */
  startDate: Scalars["String"];
};

export type CustomerConfig = {
  __typename?: "CustomerConfig";
  /** Experimental features enabled for customer */
  allowExperimental?: Maybe<Scalars["Boolean"]>;
  /** Represents customer's threshold for marking cost as anomaly in percentage */
  anomalyThreshold?: Maybe<Scalars["Float"]>;
  /** Indicates whether Resource Groups tags, and Subscriptions tags will be passed to resources contained in them for Azure */
  azureTagPropagation?: Maybe<Scalars["Boolean"]>;
  /** Default currency for the customer */
  defaultCurrency?: Maybe<Scalars["String"]>;
  /** Determines whether estate record drifts are disabled */
  driftsDisabled?: Maybe<Scalars["Boolean"]>;
  /** Features enabled for the customer */
  enabledFeatures?: Maybe<Array<Scalars["String"]>>;
  /** Indicates whether Resource Groups tags, and Subscriptions tags will be passed to resources contained in them for GCP */
  gcpTagPropagation?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  /** RI coverage target for the customer */
  riCoverageTarget?: Maybe<Scalars["Float"]>;
  /** RI enabled represents reserved instances feature */
  riEnabled?: Maybe<Scalars["Boolean"]>;
  /** Indicates maximum possible number of active workflows for the customer */
  workflowsMaxActiveNum?: Maybe<Scalars["Int"]>;
};

/** Requests a correction cost over given date range. */
export type CustomerCorrectionCostInput = {
  /** end of date range */
  endDate: Scalars["String"];
  /** returned cost granularity */
  granularity?: InputMaybe<Granularity>;
  /** start of date range */
  startDate: Scalars["String"];
};

/** Returns customer correction cost response. */
export type CustomerCorrectionResponse = {
  __typename?: "CustomerCorrectionResponse";
  timePoints?: Maybe<Array<Maybe<CustomerCorrectionTimePoint>>>;
};

/** Returns customer correction cost at the desired granularity by date. */
export type CustomerCorrectionTimePoint = {
  __typename?: "CustomerCorrectionTimePoint";
  date: Scalars["String"];
  groups?: Maybe<Array<Maybe<Group>>>;
  value: Scalars["String"];
};

export type CustomerGroupTimePoint = {
  __typename?: "CustomerGroupTimePoint";
  date: Scalars["String"];
  groups?: Maybe<Array<Maybe<EnvGroup>>>;
  totalCost: Scalars["String"];
  value: Scalars["String"];
};

export type CustomerServiceGroup = {
  __typename?: "CustomerServiceGroup";
  id: Scalars["ID"];
  name: Scalars["String"];
  types?: Maybe<Array<Maybe<Scalars["String"]>>>;
  value: Scalars["String"];
};

export type CustomerServiceGroupTimePoint = {
  __typename?: "CustomerServiceGroupTimePoint";
  date: Scalars["String"];
  groups?: Maybe<Array<Maybe<CustomerServiceGroup>>>;
  totalCost: Scalars["String"];
  value: Scalars["String"];
};

/** Requests a specific page of customer services costs over the given period. */
export type CustomerServicesCostInput = {
  /** end of date range */
  endDate: Scalars["String"];
  /** returned cost granularity */
  granularity?: InputMaybe<Granularity>;
  /**
   * amount of services to retrieve (per page);
   * this value is not allowed to exceed 50
   */
  limit: Scalars["Int"];
  /** page number */
  page?: InputMaybe<Scalars["Int"]>;
  /** start of date range */
  startDate: Scalars["String"];
};

/** Returns customer costs at the desired granularity as service-specific TimePoints, with granularity-specific totals */
export type CustomerServicesCostResponse = {
  __typename?: "CustomerServicesCostResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  timePoints?: Maybe<Array<Maybe<CustomerGroupTimePoint>>>;
  total: Scalars["String"];
};

/**
 * Returns customer costs at the desired granularity as service-specific TimePoints, with granularity-specific totals
 * and associated type+subtype combinations
 */
export type CustomerServicesCostResponseV2 = {
  __typename?: "CustomerServicesCostResponseV2";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  timePoints?: Maybe<Array<Maybe<CustomerServiceGroupTimePoint>>>;
  total: Scalars["String"];
};

export type DashboardApplicationCost = {
  __typename?: "DashboardApplicationCost";
  currentMonth: Scalars["String"];
  forecast: Scalars["String"];
  name: Scalars["String"];
  nid: Scalars["String"];
  previousMonth: Scalars["String"];
  /**
   * `previousMonthDayToDay` represents cost in previous month up to current day.
   * For example, if today is 20th then it will be the cost in previous month from 1st to 20th.
   */
  previousMonthDayToDay: Scalars["String"];
};

export type DashboardApplicationCostTotal = {
  __typename?: "DashboardApplicationCostTotal";
  currentMonth: Scalars["String"];
};

export type Dirtiness = {
  __typename?: "Dirtiness";
  isDirty: Scalars["Boolean"];
};

export type DiscoveryRule = {
  __typename?: "DiscoveryRule";
  applicationId: Scalars["String"];
  environmentId: Scalars["String"];
  exclusive: Scalars["Boolean"];
  ibmResourceGroup?: Maybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  match: DiscoveryRuleMatch;
  name: Scalars["String"];
  tagsOperator: RuleOperator;
};

export type DiscoveryRuleMatch = {
  __typename?: "DiscoveryRuleMatch";
  cloudAccount?: Maybe<Array<Scalars["String"]>>;
  customerId?: Maybe<Array<Scalars["String"]>>;
  nid?: Maybe<Array<Scalars["String"]>>;
  projectId?: Maybe<Array<Scalars["String"]>>;
  provider?: Maybe<Array<Scalars["String"]>>;
  providerId?: Maybe<Array<Scalars["String"]>>;
  region?: Maybe<Array<Scalars["String"]>>;
  resourceGroup?: Maybe<Array<Scalars["String"]>>;
  resourceId?: Maybe<Array<Scalars["String"]>>;
  securityGroupIds?: Maybe<Array<Scalars["String"]>>;
  subnetIds?: Maybe<Array<Scalars["String"]>>;
  subtype?: Maybe<Array<Scalars["String"]>>;
  tags?: Maybe<Array<KeyValue>>;
  type?: Maybe<Array<Scalars["String"]>>;
};

export type DiscoveryRuleMatchInput = {
  cloudAccount?: InputMaybe<Array<Scalars["String"]>>;
  customerId?: InputMaybe<Array<Scalars["String"]>>;
  nid?: InputMaybe<Array<Scalars["String"]>>;
  projectId?: InputMaybe<Array<Scalars["String"]>>;
  provider?: InputMaybe<Array<Scalars["String"]>>;
  providerId?: InputMaybe<Array<Scalars["String"]>>;
  region?: InputMaybe<Array<Scalars["String"]>>;
  resourceGroup?: InputMaybe<Array<Scalars["String"]>>;
  resourceId?: InputMaybe<Array<Scalars["String"]>>;
  securityGroupIds?: InputMaybe<Array<Scalars["String"]>>;
  subnetIds?: InputMaybe<Array<Scalars["String"]>>;
  subtype?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<KeyValueInput>>;
  type?: InputMaybe<Array<Scalars["String"]>>;
};

export type DownloadExportResponse = {
  __typename?: "DownloadExportResponse";
  fileUrl: Scalars["String"];
};

export enum DriftResolveStatus {
  Approve = "APPROVE",
  Reject = "REJECT",
}

/** Special type that does not use separate resolvers for fields due to performance reasons */
export type DriftedCloudResource = {
  __typename?: "DriftedCloudResource";
  drifted?: Maybe<Scalars["Boolean"]>;
  environment?: Maybe<DriftedCloudResourceEnvironment>;
  id: Scalars["String"];
  name: Scalars["String"];
  pendingAssignment?: Maybe<DriftedCloudResourceEnvironment>;
  provider: Provider;
  providerId: Scalars["String"];
  providerType?: Maybe<Scalars["String"]>;
};

export type DriftedCloudResourceEnvironment = {
  __typename?: "DriftedCloudResourceEnvironment";
  application: DriftedCloudResourceEnvironmentApplication;
  id: Scalars["String"];
  name: Scalars["String"];
};

export type DriftedCloudResourceEnvironmentApplication = {
  __typename?: "DriftedCloudResourceEnvironmentApplication";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type DriftedResourcesResponse = {
  __typename?: "DriftedResourcesResponse";
  count: Scalars["Int"];
  pages: Scalars["Int"];
  resources?: Maybe<Array<DriftedCloudResource>>;
};

export type DriftsFilters = {
  excludeSourceEnvironments?: InputMaybe<Array<Scalars["ID"]>>;
  excludeTargetEnvironments?: InputMaybe<Array<Scalars["ID"]>>;
  includeSourceEnvironments?: InputMaybe<Array<Scalars["ID"]>>;
  includeTargetEnvironments?: InputMaybe<Array<Scalars["ID"]>>;
};

export type Ec2DiskUtilization = {
  __typename?: "EC2DiskUtilization";
  readBytes: Array<TimePointWithUnit>;
  readOps: Array<TimePointWithUnit>;
  writeBytes: Array<TimePointWithUnit>;
  writeOps: Array<TimePointWithUnit>;
};

export type Ec2Instance = {
  __typename?: "EC2Instance";
  /** InstanceFamily is the AWS instance family name eg. "General purpose" */
  instanceFamily: Scalars["String"];
  /** InstanceType is the AWS instance type eg. "t3.micro" */
  instanceType: Scalars["String"];
  /** True if this is the instance that was used as a basis of the comparison */
  isInstanceComparedTo: Scalars["Boolean"];
  /** True if this is the instance type was provided to instancesFromSavingsSuggestions query input */
  isInstanceFromSavingsSuggestions: Scalars["Boolean"];
  /** Memory is the RAM size with GiB suffix eg. "8 GiB" */
  memory: Scalars["String"];
  /** NetworkPerformance describes network performance of the instance, eg. "Moderate" or "Up to 5 Mbps" */
  networkPerformance: Scalars["String"];
  /** OperatingSystem of the instance */
  operatingSystem: Scalars["String"];
  priceDaily: Scalars["String"];
  /** Hourly price is taken from the AWS API, daily price assumes 24 hours in a day, and monthly assumes 730 hours in a month */
  priceHourly: Scalars["String"];
  priceMonthly: Scalars["String"];
  /** Region used for the pricing (same as query argument}, eg. "eu-west-1" */
  region: Scalars["String"];
  /** Percentage difference between the cost of the base instance and this instance type */
  savingsPercent: Scalars["String"];
  /** Storage describes the storage options for the instance */
  storage: Scalars["String"];
  /** VCPU is the vCPU count, eg. 4 */
  vCPU: Scalars["Int"];
};

export enum Ec2InstanceFamily {
  Burstable = "BURSTABLE",
  ComputeOptimized = "COMPUTE_OPTIMIZED",
  GeneralPurpose = "GENERAL_PURPOSE",
  MemoryOptimized = "MEMORY_OPTIMIZED",
  NetworkThroughput = "NETWORK_THROUGHPUT",
  StorageOptimized = "STORAGE_OPTIMIZED",
}

export type Ec2MemoryUtilization = {
  __typename?: "EC2MemoryUtilization";
  utilization: Array<TimePointWithUnit>;
};

export enum Ec2MetricGranulation {
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
}

export type Ec2NetworkUtilization = {
  __typename?: "EC2NetworkUtilization";
  inBytes: Array<TimePointWithUnit>;
  outBytes: Array<TimePointWithUnit>;
};

export type Ec2ProcessorUtilization = {
  __typename?: "EC2ProcessorUtilization";
  utilization: Array<TimePointWithUnit>;
};

export enum Ec2Tenancy {
  Dedicated = "DEDICATED",
  Default = "DEFAULT",
  Host = "HOST",
  Shared = "SHARED",
}

export type Ec2UsageMetrics = {
  __typename?: "EC2UsageMetrics";
  disk?: Maybe<Ec2DiskUtilization>;
  memory?: Maybe<Ec2MemoryUtilization>;
  network?: Maybe<Ec2NetworkUtilization>;
  processor?: Maybe<Ec2ProcessorUtilization>;
};

export type EolMetadata = {
  __typename?: "EOLMetadata";
  /**
   * Identifier of the cluster the resource belongs to, if the resource is a part of a cluster, e.g. ASG group name for EC2
   * running within an ASG, cluster name for clustered database types, availability set for instances running within Azure
   * ASGs. Undefined if the resource does not belong to any cluster.
   */
  clusterIdentifier?: Maybe<Scalars["String"]>;
  /** The id of the resource, this is the same value as `CloudResource.id`. */
  id: Scalars["String"];
  /**
   * Will be true if the related cloud resource is run throught an auto-scaling process, e.g. an EC2 instance within an ASG
   * or an Azure virtual machine running within an availabilitySet. Undefined if the resource cannot be autoscaled.
   */
  isAutoscaled?: Maybe<Scalars["Boolean"]>;
  lastMetadataUpdate: Scalars["String"];
  /**
   * The human readable name of the resource, if available. If the nameis not available in the metadata, this is equal to
   * `CloudResource.name`.
   */
  name: Scalars["String"];
  /**
   * The type of the os, e.g. Windows, Linux, Linux/UNIX, etc. depending on how cloud provider represents the operating
   * system's data.
   */
  os: Scalars["String"];
  /**
   * Detailed information about the Operating Systems name. The format will depend on information available in the cloud
   * resource's metadata provided by the operating system's provider. Undefined if that data cannot be parsed.
   */
  osName?: Maybe<Scalars["String"]>;
  /** The exact version of the operating system or database engine, if available. */
  osVersion?: Maybe<Scalars["String"]>;
  /** The id of the resource by cloud provider, this is the same value as `CloudResource.providerId` */
  providerId: Scalars["String"];
};

export type EnhancedTimePoint = {
  __typename?: "EnhancedTimePoint";
  /** This includes fields like 'hours', 'number of vms' */
  additionalFields?: Maybe<Array<KeyValue>>;
  date: Scalars["String"];
  value: Scalars["String"];
};

export type Enrichment = {
  __typename?: "Enrichment";
  data?: Maybe<Array<KeyValueSource>>;
  integration: Integration;
};

export type EnvCostShare = {
  __typename?: "EnvCostShare";
  id: Scalars["String"];
  isExcluded?: Maybe<Scalars["Boolean"]>;
  /** Float from 0 to 1 */
  splitValue?: Maybe<Scalars["String"]>;
};

export type EnvCostShareInput = {
  id: Scalars["String"];
  isExcluded?: InputMaybe<Scalars["Boolean"]>;
  /** Float from 0 to 1 */
  splitValue?: InputMaybe<Scalars["String"]>;
};

export type EnvCostSplitInput = {
  id?: InputMaybe<Scalars["String"]>;
  splitValue?: InputMaybe<Scalars["String"]>;
};

export type EnvCostSplits = {
  __typename?: "EnvCostSplits";
  id?: Maybe<Scalars["String"]>;
  splitValue?: Maybe<Scalars["String"]>;
};

export type EnvGroup = {
  __typename?: "EnvGroup";
  id: Scalars["ID"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type Environment = {
  __typename?: "Environment";
  /** The application this environment is a part of */
  application: Application;
  /** Budget that can be set for the environment. */
  budgetYearly: BudgetYearly;
  /** `cloudWasteAggregate` describes aggregated information about Cloud Waste */
  cloudWasteAggregate?: Maybe<CloudWasteAggregate>;
  /**
   * `co2e` is the total carbon emissions of all resources assigned to the environment, for current/previous
   * month depending on `timePeriod`, `providers` is an optional filter to find co2e only for certain set of providers
   */
  co2e?: Maybe<Scalars["String"]>;
  /** A list of contacts attached for this environment */
  contacts?: Maybe<Array<Maybe<Person>>>;
  /**
   * `cost` of all resources assigned to the environment, `providers` is an optional filter to find costs only for certain
   * set of providers
   */
  cost?: Maybe<Cost>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: Maybe<Scalars["String"]>;
  /** `displayName` is a string up to 256 characters long that helps to identify the environment */
  displayName: Scalars["String"];
  /** Type of the environment, according to the enum */
  environmentType: EnvironmentType;
  /**
   * `estate` is a list of Cloud and Cost resources that belong to this environment, paginated
   * @deprecated use global query `estateV2` with `environment` filter instead
   */
  estate?: Maybe<ResourceResponse>;
  forecastSpendYearly: ForecastSpendYearly;
  /** @deprecated id and nid have been unified, and are going to be the same field, use `nid` instead */
  id: Scalars["String"];
  /** @deprecated replaced by kpis field */
  kpiDetails: EnvironmentKpiDetails;
  kpis?: Maybe<EnvironmentKpis>;
  /** @deprecated Does not work */
  metadataAndTags?: Maybe<MetadataAndTags>;
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars["String"];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars["String"];
  /** `orgUnitsV2` is a paginated list of all organizational units, one organizational unit per business context */
  orgUnitsV2?: Maybe<OrgUnitsV2Response>;
  /** The person that is the owner of the environment */
  owner?: Maybe<Person>;
  /** `resourceCount` is the number of resources that belong to this environment */
  resourceCount: Scalars["Int"];
  /**
   * `spend` is the total cost of all resources assigned to the environment, for current/previous
   * month depending on `timePeriod`, `providers` is an optional filter to find costs only for certain set of providers
   * @deprecated use `cost`
   */
  spend?: Maybe<Scalars["String"]>;
  spendByDateRange: SpendByDateRange;
  spendYearly: SpendYearly;
};

export type EnvironmentBudgetYearlyArgs = {
  year: Scalars["String"];
};

export type EnvironmentCo2eArgs = {
  providers?: InputMaybe<Array<Provider>>;
  timePeriod: TimePeriodType;
};

export type EnvironmentCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type EnvironmentEstateArgs = {
  filter?: InputMaybe<ResourceFilter>;
  limit: Scalars["Int"];
  page: Scalars["Int"];
  query?: InputMaybe<Scalars["String"]>;
};

export type EnvironmentForecastSpendYearlyArgs = {
  year: Scalars["String"];
};

export type EnvironmentKpisArgs = {
  bcId: Scalars["ID"];
};

export type EnvironmentOrgUnitsV2Args = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type EnvironmentSpendArgs = {
  providers?: InputMaybe<Array<Provider>>;
  timePeriod: TimePeriodType;
};

export type EnvironmentSpendByDateRangeArgs = {
  endDate: Scalars["String"];
  startDate: Scalars["String"];
};

export type EnvironmentSpendYearlyArgs = {
  year: Scalars["String"];
};

export type EnvironmentAccumulatedCostInput = {
  endDate: Scalars["String"];
  environmentId: Scalars["ID"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
};

export type EnvironmentAccumulatedCostResponse = {
  __typename?: "EnvironmentAccumulatedCostResponse";
  timePoints?: Maybe<Array<Maybe<TimePoint>>>;
  total: Scalars["String"];
};

export type EnvironmentAndSpends = {
  __typename?: "EnvironmentAndSpends";
  cost?: Maybe<Cost>;
  environment?: Maybe<Environment>;
  /** @deprecated use `cost` */
  spend?: Maybe<Scalars["String"]>;
};

export type EnvironmentAndSpendsCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type EnvironmentAndSpendsSpendArgs = {
  providers?: InputMaybe<Array<Provider>>;
  timePeriod: TimePeriodType;
};

export type EnvironmentAndSpendsPaginated = {
  __typename?: "EnvironmentAndSpendsPaginated";
  count?: Maybe<Scalars["Int"]>;
  environments?: Maybe<Array<EnvironmentAndSpends>>;
  pages?: Maybe<Scalars["Int"]>;
};

export type EnvironmentKpi = {
  details: KpiDetails;
};

export type EnvironmentKpiDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type EnvironmentKpiDetails = {
  __typename?: "EnvironmentKpiDetails";
  nonProdHours: KpiDetails;
};

export type EnvironmentKpiDetailsNonProdHoursArgs = {
  bcId?: InputMaybe<Scalars["ID"]>;
  orgUnitId?: InputMaybe<Scalars["ID"]>;
  timePeriod: KpiTimePeriod;
};

export type EnvironmentKpis = {
  __typename?: "EnvironmentKpis";
  nonProdHours: KpiNonProdHoursForEnvironment;
  overallWaste: KpiOverallWasteForEnvironment;
};

/** Specifies the amount of most expensive categories for the given Environment over the given period to be excluded, all other categories to be returned. */
export type EnvironmentOtherCategoriesCostInput = {
  endDate: Scalars["String"];
  environmentId: Scalars["ID"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

/** Returns other environment costs at the desired granularity as category-specific TimePoints */
export type EnvironmentOtherCategoriesCostResponse = {
  __typename?: "EnvironmentOtherCategoriesCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

export type EnvironmentResponse = {
  __typename?: "EnvironmentResponse";
  count?: Maybe<Scalars["Int"]>;
  environments?: Maybe<Array<Environment>>;
  pages?: Maybe<Scalars["Int"]>;
};

/** Specifies the amount of most expensive categories for the given Environment over the given period to be returned. */
export type EnvironmentTopCategoriesCostInput = {
  endDate: Scalars["String"];
  environmentId: Scalars["ID"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

/** Returns top environment costs at the desired granularity as category-specific TimePoints */
export type EnvironmentTopCategoriesCostResponse = {
  __typename?: "EnvironmentTopCategoriesCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

export enum EnvironmentType {
  Development = "DEVELOPMENT",
  Other = "OTHER",
  Production = "PRODUCTION",
  Staging = "STAGING",
}

export type EstateAllocationDriftRecord = {
  __typename?: "EstateAllocationDriftRecord";
  /** Name of the category the record belongs to */
  categoryName: Scalars["String"];
  /** The environment the record is currently assigned to, along with the application containing the environment */
  currentEnvironment?: Maybe<EstateAllocationDriftRecordEnvironment>;
  /** Record name */
  name: Scalars["String"];
  /** Nordcloud ID of the estate record */
  nid: Scalars["String"];
  /** Provider of the record */
  provider: Provider;
  /** ID of the record within it's provider */
  providerId: Scalars["String"];
  /** Type/subtype of the record */
  providerType?: Maybe<Scalars["String"]>;
  /** The region the record is in */
  region: Scalars["String"];
  /** The environment the record's allocation drift points to, along with the application containing the environment */
  targetEnvironment?: Maybe<EstateAllocationDriftRecordEnvironment>;
};

export type EstateAllocationDriftRecordApplication = {
  __typename?: "EstateAllocationDriftRecordApplication";
  name: Scalars["String"];
  nid: Scalars["String"];
};

export type EstateAllocationDriftRecordEnvironment = {
  __typename?: "EstateAllocationDriftRecordEnvironment";
  application: EstateAllocationDriftRecordApplication;
  name: Scalars["String"];
  nid: Scalars["String"];
};

export type EstateAllocationDriftsInput = {
  filters?: InputMaybe<DriftsFilters>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type EstateAllocationDriftsResponse = {
  __typename?: "EstateAllocationDriftsResponse";
  count: Scalars["Int"];
  pages: Scalars["Int"];
  records?: Maybe<Array<EstateAllocationDriftRecord>>;
};

export type EstateRecordsExportInput = {
  /** The same field as in `Query.estate()` */
  filter?: InputMaybe<ResourceFilter>;
  /** Email address that will be used to notify about export completion */
  notificationEmail: Scalars["String"];
  /** The same field as in `Query.estate()` */
  query?: InputMaybe<Scalars["String"]>;
  /**
   * Url of the frontend view for which the export has been requested
   * it should include all the filters
   */
  viewUrl: Scalars["String"];
  /** Filter records to the ones which are marked as cloud waste */
  waste?: InputMaybe<Scalars["Boolean"]>;
};

export type EstateResourceFilter = {
  /** Return all resources belonging to an account */
  cloudAccount?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources belonging to a GCP project ID */
  projectId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources belonging to a Azure resource group */
  resourceGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources with specified subtype */
  subtype?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources belonging to a specific tags */
  tags?: InputMaybe<Array<KeyValueInput>>;
  /** Return all resources with specified type */
  type?: InputMaybe<Array<Scalars["String"]>>;
};

export type EstateResourceFilterV2 = {
  /** Return all resources belonging to an account */
  cloudAccount?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources belonging to a GCP project ID */
  projectId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources belonging to a Azure resource group */
  resourceGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources with specified AWS Security Group */
  securityGroupIds?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources with specified subtype */
  subtype?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources belonging to a specific tags */
  tags?: InputMaybe<Array<KeyValuesInput>>;
  /** Return all resources with specified type */
  type?: InputMaybe<Array<Scalars["String"]>>;
};

export enum EstateV2ExportExtendedColumn {
  Application = "APPLICATION",
  Co2E = "CO2E",
  Cost = "COST",
  CostSavings = "COST_SAVINGS",
  Enrichments = "ENRICHMENTS",
  Environment = "ENVIRONMENT",
  Metadata = "METADATA",
  Reservation = "RESERVATION",
  ResourceConsumption = "RESOURCE_CONSUMPTION",
  SavingsAmount = "SAVINGS_AMOUNT",
  Tags = "TAGS",
}

export type EstateV2ExportFilters = {
  filter?: InputMaybe<EstateV2Filter>;
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SearchSort>;
};

/** Input for filtering data. */
export type EstateV2Filter = {
  /** Return all resources belonging to an account */
  account?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources assigned to environments that belong to given applications list */
  application?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources that had assignment updated from */
  assignmentUpdatedFrom?: InputMaybe<Scalars["String"]>;
  /** Return all resources that had assignment updated to */
  assignmentUpdatedTo?: InputMaybe<Scalars["String"]>;
  /** Return all resources which belong to given category (UUID) */
  category?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources which belong to given category (NAME) */
  categoryName?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources which are marked as co2e waste */
  co2eWaste?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources above given cost */
  costFrom?: InputMaybe<Scalars["UnsignedFloat"]>;
  /** Return all resources below given cost */
  costTo?: InputMaybe<Scalars["UnsignedFloat"]>;
  /** Return all resources which are enriched by external integration */
  enrichment?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources enriched by a specific external integration */
  enrichmentIntegrationId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources assigned to given environments list */
  environment?: InputMaybe<Array<Scalars["String"]>>;
  /** Return resources not matching specified provider ID(s) */
  exceptProviderId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources created for a specific external integration */
  externalIntegration?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources that have been first seen at no earlier than this timestamp */
  firstSeenAtFrom?: InputMaybe<Scalars["String"]>;
  /** Return all resources that have been first seen at no later than this timestamp */
  firstSeenAtTo?: InputMaybe<Scalars["String"]>;
  /** Return all resources that have been last seen at no earlier than this timestamp */
  lastSeenAtFrom?: InputMaybe<Scalars["String"]>;
  /** Return all resources that have been last seen at no later than this timestamp */
  lastSeenAtTo?: InputMaybe<Scalars["String"]>;
  /** Returns all resources excluding lifeSpanFrom and/or lifeSpanTo */
  lifeSpanExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all resources that has life span from this number of days */
  lifeSpanFrom?: InputMaybe<Scalars["Int"]>;
  /** Returns all resources that has life span to this number of days */
  lifeSpanTo?: InputMaybe<Scalars["Int"]>;
  /** Return all resources with the specific metadata key value pairs */
  metadata?: InputMaybe<Array<KeyValuesInput>>;
  /** Return only related resources for a resource specified in query */
  onlyRelated?: InputMaybe<Scalars["Boolean"]>;
  /** [GCP-specific] Return all resources that belong to any of the specified GCP projects */
  projectId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources from a particular provider */
  provider?: InputMaybe<Array<Provider>>;
  /** Return all resources matching specified provider ID(s) */
  providerId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources from a given regions list */
  region?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources with specified resourceGroup. This includes filtering by external integration */
  resourceGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources with any of the specified AWS Security Group ID(s) */
  securityGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources with specified subtype */
  subtype?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources belonging to a specific tags */
  tags?: InputMaybe<Array<KeyValuesInput>>;
  /** Return all resources with specified type */
  type?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources with specified type/subtype combination */
  typeSubtype?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * Return all resources assigned to application `default` and environment `default`.
   * `unassigned` can not be used with `application` or `environment` filters
   */
  unassigned?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources which are marked as cloud waste */
  waste?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources which belong to given waste policy */
  wastePolicy?: InputMaybe<Array<Scalars["String"]>>;
};

export enum EstateV3ExportExtendedColumn {
  Application = "APPLICATION",
  Co2E = "CO2E",
  Cost = "COST",
  CostSavings = "COST_SAVINGS",
  Enrichments = "ENRICHMENTS",
  Environment = "ENVIRONMENT",
  Metadata = "METADATA",
  Reservation = "RESERVATION",
  ResourceConsumption = "RESOURCE_CONSUMPTION",
  SavingsAmount = "SAVINGS_AMOUNT",
  Tags = "TAGS",
}

/** Export filters designed to work with async search jobs. */
export type EstateV3ExportFilters = {
  filter?: InputMaybe<SearchV2Filter>;
  query?: InputMaybe<Scalars["String"]>;
  sort: SearchV2Sort;
};

export type Event = {
  __typename?: "Event";
  content: Scalars["String"];
  createdAt: Scalars["AWSDateTime"];
  id: Scalars["String"];
  nid: Scalars["String"];
  title: Scalars["String"];
  type: Scalars["String"];
};

export type ExternalIntegration = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerId: Scalars["String"];
};

export type ExternalIntegrationConfig = {
  __typename?: "ExternalIntegrationConfig";
  createdAt: Scalars["String"];
  id: Scalars["String"];
  lastSync?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ExternalIntegrationDetailsResponse = {
  __typename?: "ExternalIntegrationDetailsResponse";
  authId?: Maybe<Scalars["String"]>;
  authName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  customerId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastSync?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  ownerId?: Maybe<Scalars["String"]>;
};

export type ExternalIntegrationResponse = {
  __typename?: "ExternalIntegrationResponse";
  apiId: Scalars["String"];
  apiSecret: Scalars["String"];
  /** @deprecated use apiId instead */
  id: Scalars["String"];
  nid: Scalars["String"];
  /** @deprecated use apiSecret instead */
  secret: Scalars["String"];
};

export type ExternalIntegrationsResponse = {
  __typename?: "ExternalIntegrationsResponse";
  integrations?: Maybe<Array<Maybe<ExternalIntegrationConfig>>>;
};

export type FinOpsOptimisation = {
  __typename?: "FinOpsOptimisation";
  budgetAdded: Scalars["Boolean"];
  contactPersonAdded: Scalars["Boolean"];
  environmentsAttachedToOUs: Scalars["Boolean"];
  environmentsTypesDefined: Scalars["Boolean"];
  optimisationLevel: OptimisationLevel;
  ownerAdded: Scalars["Boolean"];
  savingSuggestions: SavingSuggestionsOptimisation;
};

/**
 * Forecasted spend for an entity (OrgUnit|Environment|Application)
 * Akin to budgets, spends are also represented as a list of 12 monthly budgets from Jan to Dec, if available.
 */
export type ForecastSpendYearly = {
  __typename?: "ForecastSpendYearly";
  spends: Array<Scalars["String"]>;
};

export type GcpBillingData = {
  __typename?: "GCPBillingData";
  /** `bigQueryDatasetName` is the name of a BigQuery dataset. */
  bigQueryDatasetName: Scalars["String"];
  /** `bigQueryTable` is the name of a table within a BigQuery dataset. */
  bigQueryTableName: Scalars["String"];
  /** `isActive` is a status of billing data connection */
  isActive: Scalars["Boolean"];
  /** `isOnboarding` is a status of billing data onboarding */
  isOnboarding: Scalars["Boolean"];
  /** `onboardedOrgId` is unique ID with which GCP account was onboarded */
  onboardedOrgId: Scalars["String"];
  /** `organizationId` is a unique identifier for a GCP organization */
  organizationId: Scalars["String"];
  /** `projectId` is a unique identifier for a GCP project */
  projectId: Scalars["String"];
  /** `useDetailedBillingData` is a parameter that determines whether detailed billing data is enabled or disabled for a project. */
  useDetailedBillingData: Scalars["Boolean"];
};

export enum GcpAccountType {
  Business = "BUSINESS",
  Personal = "PERSONAL",
}

export type GcpBillingConfig = {
  /** `bigQueryDatasetName` is the name of a BigQuery dataset. */
  bigQueryDatasetName: Scalars["String"];
  /** `bigQueryTableName` is the name of a table within a BigQuery dataset. */
  bigQueryTableName: Scalars["String"];
  /** `organizationId` is a unique identifier for a GCP organization. */
  organizationId: Scalars["String"];
  /** `projectId` is a unique identifier for a GCP project */
  projectId: Scalars["String"];
  /**
   * `serviceAccountKey` is a JSON file that contains the credentials for
   * authenticating and authorizing access to GCP services on behalf of a service account.
   */
  serviceAccountKey: Scalars["String"];
  /** `useDetailedBillingData`  is a parameter that determines whether detailed billing data is enabled or disabled for a project. */
  useDetailedBillingData: Scalars["Boolean"];
};

export type GcpProjectsResponse = {
  __typename?: "GcpProjectsResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  projects?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type GlobalCostAllocationResponse = {
  __typename?: "GlobalCostAllocationResponse";
  allocated: Scalars["String"];
  timeSeries?: Maybe<Array<CostAllocationTimeSeries>>;
  unallocated: Scalars["String"];
};

export type GlobalMetricsResponse = {
  __typename?: "GlobalMetricsResponse";
  costAllocation?: Maybe<GlobalCostAllocationResponse>;
};

export type GlobalMetricsResponseCostAllocationArgs = {
  endDate: Scalars["String"];
  startDate: Scalars["String"];
};

export type GlobalTagKeys = {
  __typename?: "GlobalTagKeys";
  application: Array<Maybe<Scalars["String"]>>;
  environment: Array<Maybe<Scalars["String"]>>;
};

export enum Granularity {
  Daily = "DAILY",
  Monthly = "MONTHLY",
}

export enum GranularityUsageType {
  Daily = "DAILY",
  Monthly = "MONTHLY",
}

export type Group = {
  __typename?: "Group";
  name: Scalars["String"];
  value: Scalars["String"];
};

export type GroupTimePoint = {
  __typename?: "GroupTimePoint";
  date: Scalars["String"];
  groups?: Maybe<Array<Maybe<EnvGroup>>>;
  value: Scalars["String"];
};

export type GroupedResources = {
  __typename?: "GroupedResources";
  /** `platform` represents platforms of compute and database(e.g. `LINUX` or `WINDOWS` or `AWS LAMBDA` or `RDS - Aurora MySQL` or `Elasticache`) */
  platform?: Maybe<Scalars["String"]>;
  /** `resources` represents list of ReservationCoveredAndCommitmentType resources grouped by respective platform */
  resources?: Maybe<Array<ReservationCoveredAndCommitmentType>>;
};

export type Hook = {
  __typename?: "Hook";
  createdAt: Scalars["String"];
  lastInvokedAt?: Maybe<Scalars["String"]>;
  type: HookType;
  updatedAt: Scalars["String"];
  url: Scalars["String"];
};

export enum HookType {
  MappingFinalized = "MAPPING_FINALIZED",
}

export type InputCostSavings = {
  comment: Scalars["String"];
  detectionId: Scalars["String"];
  state: RecommendationState;
};

export type Integration = {
  __typename?: "Integration";
  id: Scalars["String"];
  name: Scalars["String"];
};

/** Types */
export type IntegrationPlugin = {
  __typename?: "IntegrationPlugin";
  contactPersons: Array<Person>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  integrationType: IntegrationPluginType;
  lastSyncStatus?: Maybe<IntegrationPluginSyncStatus>;
  lastSyncTime: Scalars["String"];
  name: Scalars["String"];
};

export enum IntegrationPluginSyncStatus {
  Completed = "COMPLETED",
  Failure = "FAILURE",
}

/** ENUM */
export enum IntegrationPluginType {
  Turbonomic = "TURBONOMIC",
}

export type IntegrationPluginsInput = {
  integrationType?: InputMaybe<IntegrationPluginType>;
};

/**
 * KeyValue is used anywhere we would use a map, but since there is no
 * map/object type for GraphQL we return a list of key/value instead.
 */
export type KeyValue = {
  __typename?: "KeyValue";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type KeyValueInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

/**
 * KeyValueSource is used anywhere we return a list of key/value similarly to KeyValue.
 * KeyValueSource is used where we return metadata provider by different services
 * e.g. tags with the same name can be scanned from scanner and from capacity data
 * the `source` field differentiates which source the metadata comes from.
 */
export type KeyValueSource = {
  __typename?: "KeyValueSource";
  key: Scalars["String"];
  source: Scalars["String"];
  value: Scalars["String"];
};

/** KeyValues represents map of strings to list of strings. */
export type KeyValues = {
  __typename?: "KeyValues";
  key: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export type KeyValuesInput = {
  key: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export type KpiCostAllocationCoverage = BusinessContextKpi & {
  __typename?: "KpiCostAllocationCoverage";
  details: KpiDetails;
  targets?: Maybe<Array<KpiTarget>>;
  /** `totalAllocatedCost` is sum of costs of all resources that are allocated to some environment */
  totalAllocatedCost: Scalars["String"];
};

export type KpiCostAllocationCoverageDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type KpiCostAllocationCoverageTargetsArgs = {
  selection: KpiTargetSelection;
};

export type KpiDetails = {
  __typename?: "KpiDetails";
  metrics?: Maybe<KpiMetric>;
  targets?: Maybe<KpiTargets>;
};

export type KpiMetric = {
  __typename?: "KpiMetric";
  /** @deprecated `latestTimePoint` can be used instead */
  currentValue?: Maybe<Scalars["String"]>;
  latestTimePoint?: Maybe<EnhancedTimePoint>;
  values: Array<EnhancedTimePoint>;
};

export type KpiNonProdHoursBusinessContext = BusinessContextKpi & {
  __typename?: "KpiNonProdHoursBusinessContext";
  details: KpiDetails;
  targets?: Maybe<Array<KpiTarget>>;
  /** Total shutdown time in hours for the machines in this BusinessContext */
  totalHours: Scalars["String"];
  /** List of up to 5 worst performing OrgUnits for this KPI */
  worst?: Maybe<KpiNonProdHoursOrgUnits>;
};

export type KpiNonProdHoursBusinessContextDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type KpiNonProdHoursBusinessContextTargetsArgs = {
  selection: KpiTargetSelection;
};

export type KpiNonProdHoursForEnvironment = EnvironmentKpi & {
  __typename?: "KpiNonProdHoursForEnvironment";
  details: KpiDetails;
};

export type KpiNonProdHoursForEnvironmentDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type KpiNonProdHoursForOrgUnit = OrgUnitKpi & {
  __typename?: "KpiNonProdHoursForOrgUnit";
  details: KpiDetails;
  /** List of up to 5 worst performing child OrgUnits for this OrgUnit for NonProdHours KPI */
  worst: KpiNonProdHoursOrgUnits;
};

export type KpiNonProdHoursForOrgUnitDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type KpiNonProdHoursOrgUnit = {
  __typename?: "KpiNonProdHoursOrgUnit";
  hours: Scalars["String"];
  kpiValue: Scalars["String"];
  orgUnit: OrgUnitV2;
};

export type KpiNonProdHoursOrgUnits = {
  __typename?: "KpiNonProdHoursOrgUnits";
  /** Total running hours for the machines in the `orgUnits` field */
  hours?: Maybe<Scalars["String"]>;
  orgUnits?: Maybe<Array<KpiNonProdHoursOrgUnit>>;
};

export type KpiOverallWasteBusinessContext = BusinessContextKpi & {
  __typename?: "KpiOverallWasteBusinessContext";
  details: KpiDetails;
  targets?: Maybe<Array<KpiTarget>>;
  /** Total waste for this BusinessContext */
  totalWaste: Scalars["String"];
  /** List of up to 5 worst performing OrgUnits for this KPI */
  worst?: Maybe<KpiOverallWasteOrgUnits>;
};

export type KpiOverallWasteBusinessContextDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type KpiOverallWasteBusinessContextTargetsArgs = {
  selection: KpiTargetSelection;
};

export type KpiOverallWasteForEnvironment = EnvironmentKpi & {
  __typename?: "KpiOverallWasteForEnvironment";
  details: KpiDetails;
};

export type KpiOverallWasteForEnvironmentDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type KpiOverallWasteForOrgUnit = OrgUnitKpi & {
  __typename?: "KpiOverallWasteForOrgUnit";
  details: KpiDetails;
  /** List of up to 5 worst performing child OrgUnits for this OrgUnit for OverallWaste KPI */
  worst: KpiOverallWasteOrgUnits;
};

export type KpiOverallWasteForOrgUnitDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type KpiOverallWasteOrgUnit = {
  __typename?: "KpiOverallWasteOrgUnit";
  currentWaste: Scalars["String"];
  kpiValue: Scalars["String"];
  orgUnit: OrgUnitV2;
};

export type KpiOverallWasteOrgUnits = {
  __typename?: "KpiOverallWasteOrgUnits";
  orgUnits?: Maybe<Array<KpiOverallWasteOrgUnit>>;
  /** Sum of OverallWaste for orgUnits in the `orgUnits` field */
  waste?: Maybe<Scalars["String"]>;
};

export type KpiTarget = {
  __typename?: "KpiTarget";
  businessContextId: Scalars["ID"];
  contactPerson?: Maybe<Person>;
  endDate: Scalars["Date"];
  goal: Scalars["Float"];
  id: Scalars["ID"];
  name: Scalars["String"];
  startDate: Scalars["Date"];
  type: KpiTypes;
};

export enum KpiTargetSelection {
  Archived = "ARCHIVED",
  Past = "PAST",
  Upcoming = "UPCOMING",
}

export type KpiTargets = {
  __typename?: "KpiTargets";
  /** Target which is currently active */
  currentTarget?: Maybe<KpiTarget>;
  /** A list of all targets available for this KPI */
  targets: Array<KpiTarget>;
};

export enum KpiTimePeriod {
  OneMonth = "ONE_MONTH",
  ThreeMonths = "THREE_MONTHS",
  TwelveMonths = "TWELVE_MONTHS",
}

export enum KpiTypes {
  CostAllocationCoverage = "COST_ALLOCATION_COVERAGE",
  NonProdHours = "NON_PROD_HOURS",
  OverallWaste = "OVERALL_WASTE",
}

export enum KubernetesType {
  Node = "NODE",
  Pod = "POD",
}

export type Link = {
  __typename?: "Link";
  effectiveAt: Scalars["String"];
  sourceRecord: Resource;
  targetRecord: Resource;
};

export type LinkInput = {
  sourceNid: Scalars["String"];
  targetNid: Scalars["String"];
};

export type ListCategoriesResponse = {
  __typename?: "ListCategoriesResponse";
  categories?: Maybe<Array<Category>>;
};

export type ListCommitmentsCoverageDetailsResponse = {
  __typename?: "ListCommitmentsCoverageDetailsResponse";
  resources?: Maybe<Array<CommitmentsCoverageDetailsResponse>>;
};

export type ListUniqueTagKeysResponse = {
  __typename?: "ListUniqueTagKeysResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  tagKeys?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type MappedRecord = {
  fromNid: Scalars["String"];
  toNid: Scalars["String"];
};

export type MappingRule = {
  __typename?: "MappingRule";
  applicationId: Scalars["String"];
  cloudAccount?: Maybe<Scalars["String"]>;
  environmentId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  projectId?: Maybe<Scalars["String"]>;
  resourceGroup?: Maybe<Scalars["String"]>;
  resourceType?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<KeyValue>>;
  type?: Maybe<MappingRuleType>;
};

export enum MappingRuleType {
  Account = "ACCOUNT",
  Projectid = "PROJECTID",
  Resourcegroup = "RESOURCEGROUP",
  Resourcetype = "RESOURCETYPE",
  Tag = "TAG",
}

export type MarkReservedInstance = {
  from: Scalars["AWSDateTime"];
  nid: Scalars["String"];
  to: Scalars["AWSDateTime"];
};

export type MetadataAndTags = {
  __typename?: "MetadataAndTags";
  metadata?: Maybe<Array<KeyValueSource>>;
  tags?: Maybe<Array<KeyValueSource>>;
};

export type MonthDaily = {
  __typename?: "MonthDaily";
  month: Scalars["String"];
  values: Array<Scalars["String"]>;
};

export type MonthlyReportInput = {
  businessContextId?: InputMaybe<Scalars["String"]>;
  /** Report period in YYYY-MM format. Repors are available for N-x months where x >= 2 */
  period: Scalars["String"];
  /** If report tyle is MONTHLY_REPORT_BC, businessContextId field should be provided */
  reportType: ReportType;
};

export type MonthlyReportResponse = {
  __typename?: "MonthlyReportResponse";
  error?: Maybe<Scalars["String"]>;
  fileUrl?: Maybe<Scalars["String"]>;
  jobId: Scalars["String"];
  reportType: ReportType;
  /**
   * If report status is SCHEDULED or IN_PROGRESS - report was not yet prepared
   * If report status is FINISHED `fileUrl` field contains presigned URL to download report
   * If report status is ERROR `error` field contains error message
   */
  status: ReportStatus;
};

export type MultipleResourceFilter = {
  /** Return all resources belonging to an account */
  account?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources assigned to environments that belong to given application */
  application?: InputMaybe<Scalars["String"]>;
  /** Return all resources assigned to given environment */
  environment?: InputMaybe<Scalars["String"]>;
  /** Return all resources from a particular provider */
  provider?: InputMaybe<Array<Provider>>;
  /** Return all resources from a given region */
  region?: InputMaybe<Array<Scalars["String"]>>;
  /** Return only resources with specified subtype */
  subtype?: InputMaybe<Array<Scalars["String"]>>;
  /** Return only resources with specified type */
  type?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * The filters for `unassigned`, `environment`, `application` are exclusive,
   * and they are resolved in order unassigned > environment > application
   * so if all are specified, only one is going to work along with that ordering
   *
   *
   * Return all resources assigned to application `default` or environment `default`
   */
  unassigned?: InputMaybe<Scalars["Boolean"]>;
};

/** Mutations */
export type Mutation = {
  __typename?: "Mutation";
  /** Activate or Deactivate a workflow with userErrors included in response */
  activateWorkflowV2?: Maybe<WorkflowOutput>;
  /** Creates AWS billing config */
  addAWSBillingConfigV2: AwsBillingData;
  /** @deprecated use `addAccountV3` instead */
  addAccount?: Maybe<MutationResponse>;
  /** @deprecated use `addAccountV3` instead */
  addAccountV2?: Maybe<MutationResponse>;
  addAccountV3?: Maybe<Account>;
  /** @deprecated use `addApplicationV2` instead */
  addApplication?: Maybe<MutationResponse>;
  addApplicationV2?: Maybe<MutationResponse>;
  /** Creates Azure billing config */
  addAzureBillingConfigV2: AzureBillingData;
  /** @deprecated use `addBusinessContextV2` instead */
  addBusinessContext?: Maybe<MutationResponse>;
  addBusinessContextV2: BusinessContext;
  addCostCategoryMapping?: Maybe<MutationResponse>;
  /** @deprecated use `addDiscoveryRuleV2` instead */
  addDiscoveryRule?: Maybe<MutationResponse>;
  addDiscoveryRuleV2: DiscoveryRule;
  /**
   * `addEnvToOrgUnitV2` attaches an Environment to a OrgUnitV2
   * each environment can be attached to one org unit within a specific BusinessContext, but can be attached to
   * many different OrgUnits that belong to different BusinessContexts
   * `env` is the id of the Environment that is to be attached
   * `orgUnitId` is the id of the OrgUnit that the environment should be attached to
   */
  addEnvToOrgUnitV2?: Maybe<Scalars["Boolean"]>;
  /**
   * A user with the `TEAM_LEAD` role assigned to him can only add environments to applications that he has access to
   * @deprecated use `addEnvironmentV2` instead
   */
  addEnvironment?: Maybe<MutationResponse>;
  /**
   * A user with the `TEAM_LEAD` role assigned to him can only add environments to applications that he has access to
   * @deprecated use `addEnvironmentV3` instead
   */
  addEnvironmentV2?: Maybe<MutationResponse>;
  /** A user with the `TEAM_LEAD` role assigned to him can only add environments to applications that he has access to */
  addEnvironmentV3: Environment;
  /**
   * `addEnvironmentsToOrgUnit` attaches multiple Environments to a OrgUnit
   * each environment can be attached to one OrgUnit within a specific BusinessContext, but can be attached to
   * many different OrgUnits that belong to different BusinessContexts
   * `input` is the UpdateOrgUnitEnvironmentsBodyInput type of object containing environments need to be added to orgUnit
   */
  addEnvironmentsToOrgUnit?: Maybe<Scalars["Boolean"]>;
  /** Add event for given nid in evenciarz */
  addEvent?: Maybe<Scalars["Boolean"]>;
  addExternalIntegration?: Maybe<ExternalIntegrationResponse>;
  /** Add configuration related to billing in Google Cloud platform */
  addGCPBillingConfigV2: GcpBillingData;
  /** Add new KPI details return entity ID */
  addKPITarget: KpiTarget;
  /** @deprecated use `addDiscoveryRule` instead */
  addMappingRule?: Maybe<MutationResponse>;
  /** @deprecated use `addOrgUnitTypeV3` instead */
  addOrgUnitTypeV2?: Maybe<MutationResponse>;
  addOrgUnitTypeV3: OrgUnitTypeV2;
  /** @deprecated use `addOrgUnitV3` instead */
  addOrgUnitV2?: Maybe<MutationResponse>;
  addOrgUnitV3: OrgUnitV2;
  /** @deprecated use `addPersonV2` instead */
  addPerson?: Maybe<MutationResponse>;
  addPersonV2: Person;
  addResourceCostSplitRule: ResourceCostSplitRule;
  /** Add sandbox for current customer */
  addSandbox?: Maybe<MutationResponse>;
  /** @deprecated use addSearchPresetV2 */
  addSearchPreset?: Maybe<AddSearchPresetResponse>;
  /** Updated version of addSearchPreset mutation with support for new filters and sorting. */
  addSearchPresetV2?: Maybe<AddSearchPresetV2Response>;
  /** Creates a synthetic estate record */
  addSyntheticEstateRecord?: Maybe<MutationResponse>;
  /** Add threshold `input` */
  addThreshold: Threshold;
  /** Create a new turbonomic plugin */
  addTurbonomicIntegrationPluginV2?: Maybe<IntegrationPlugin>;
  /** Add a new workflow */
  addWorkflowV2?: Maybe<WorkflowOutput>;
  /** Archive KPI details return entity ID */
  archiveKPITarget: KpiTarget;
  /**
   * Permanently attach a resource to an environment
   * Required fields are: resource id or nid (from the `id` field of CloudResource) of resource that is being attached
   * and nid of the environment (from the `nid` field of Environment)
   */
  attachResource?: Maybe<Scalars["Boolean"]>;
  attachResourceCostSplitRules: BulkResourceCostSplitRules;
  /** Clone workflow with input name, description and contact person */
  cloneWorkflow?: Maybe<Workflow>;
  /**
   * Create an access key with access devised similarly to `setUserPermissions`
   * This mutation returns the permissions assigned to the key, along with instances
   * Identifier and secret that is needed to use the API
   *
   * The secret is only available within this operation, and can't be retrieved later
   */
  createAccessKey: AccessKeyPermissionsWithSecret;
  /** Create a new link between two estate records */
  createLink?: Maybe<Link>;
  /** Delete/close a link between two estate records */
  deleteLink?: Maybe<Scalars["Boolean"]>;
  executeWorkflow?: Maybe<Scalars["Boolean"]>;
  /** Creates and removes links between estate records */
  linkedRecords?: Maybe<Scalars["Boolean"]>;
  /**
   * Map and unmap estate records to other estate records
   * Note that there is bulk request limit in Cloud Estate
   */
  mappedRecords?: Maybe<Scalars["Boolean"]>;
  /** A user with the `TEAM_LEAD` role assigned to him can only mark for deletion environments in applications that he has access to */
  markEnvironmentsForDeletion?: Maybe<Scalars["Boolean"]>;
  markNotificationsAsRead?: Maybe<Scalars["Boolean"]>;
  /**
   * Regenerate (rotate) access key secret
   *
   * The secret is only available within this operation and cannot be retrieved later
   */
  regenerateAccessKeySecret?: Maybe<AccessKeyPermissionsWithSecret>;
  regenerateExternalIntegrationSecret?: Maybe<ExternalIntegrationResponse>;
  registerExternalIntegration?: Maybe<ExternalIntegrationResponse>;
  /** Remove an access key */
  removeAccessKey?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `removeAccounts` instead */
  removeAccount?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `removeAccounts` instead */
  removeAccountV2?: Maybe<Scalars["Boolean"]>;
  /** Removes accounts in batches by given ids */
  removeAccounts?: Maybe<CloudAccountsResponse>;
  /** @deprecated use `removeApplicationV2` instead */
  removeApplication?: Maybe<Scalars["Boolean"]>;
  removeApplicationV2?: Maybe<Scalars["Boolean"]>;
  /** Removes AWS billing config for given account id */
  removeAwsBillingConfigV2: MutationResponse;
  /** Removes Azure billing config */
  removeAzureBillingConfigV2: MutationResponse;
  removeBusinessContext?: Maybe<Scalars["Boolean"]>;
  /** @deprecated support for removing cost splitting rules has been dropped */
  removeCostCategoryMapping?: Maybe<Scalars["Boolean"]>;
  removeDiscoveryRule?: Maybe<Scalars["Boolean"]>;
  /**
   * `removeEnvFromOrgUnitV2` removes the attachment that was created via `addEnvToOrgUnitV2`
   * After the removal the environment can still be attached to other OrgUnits
   * `env` is the id of the Environment that is to be removed
   * `orgUnit` is the nid of the OrgUnit that the environment should be removed from
   */
  removeEnvFromOrgUnitV2?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `removeEnvironmentV2` instead */
  removeEnvironment?: Maybe<Scalars["Boolean"]>;
  removeEnvironmentV2?: Maybe<Scalars["Boolean"]>;
  removeExternalIntegration?: Maybe<MutationResponse>;
  /** Removes GCP billing config for given onboarded organization ID */
  removeGcpBillingConfigV2: MutationResponse;
  removeHook?: Maybe<Scalars["Boolean"]>;
  /** Remove plugin */
  removeIntegrationPlugin?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `removeDiscoveryRule` instead */
  removeMappingRule?: Maybe<Scalars["Boolean"]>;
  removeOrgUnitTypeV2?: Maybe<Scalars["Boolean"]>;
  removeOrgUnitV2?: Maybe<Scalars["Boolean"]>;
  removePerson?: Maybe<Scalars["Boolean"]>;
  removeResourceCostSplitRule?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use removeSearchPresetV2 */
  removeSearchPreset?: Maybe<RemoveSearchPresetResponse>;
  /** Removes search preset by id */
  removeSearchPresetV2?: Maybe<RemoveSearchPresetV2Response>;
  /** Remove threshold by `id` */
  removeThreshold: MutationResponse;
  /** Revoke any permissions that user had previously assigned */
  removeUserPermissions?: Maybe<Scalars["Boolean"]>;
  removeWorkflow?: Maybe<Scalars["Boolean"]>;
  /** Request an asynchronous export of the estate records to csv file */
  requestEstateRecordsExport?: Maybe<RequestExportResponse>;
  /**
   * Request an asynchronous export of the estate records to csv file
   * @deprecated
   */
  requestEstateV2Export?: Maybe<RequestEstateV2ExportResponse>;
  /** Request an asynchronous export of the estate records to csv file */
  requestEstateV2ExportAsync?: Maybe<RequestEstateV2ExportResponse>;
  /** Request an asynchronous export of the estate records to csv file (V3) */
  requestEstateV3ExportAsync?: Maybe<RequestEstateV3ExportResponse>;
  /** Request export of estate records grouped by given field */
  requestGroupedRecordsExport?: Maybe<RequestGroupedRecordsExportResponse>;
  /**
   * Mark and unmark resources as reserved instances in Cloud Estate
   * Note that there is bulk request limit in Cloud Estate
   */
  reservedInstances?: Maybe<Scalars["Boolean"]>;
  /**
   * Resolve ALL assignments for a given set of filters, determined by the `filter` field of the input. The resolution will be
   * performed according to the `status` field of the input.
   */
  resolveAllDrifts: ResolveDriftsResponse;
  /**
   * Resolve pending assignments for a set of resources, identified by the `nids` field of the input. The resolution will be performed
   * according to the `status` field of the input.
   */
  resolveDrifts: EstateAllocationDriftsResponse;
  /** Set a budget for an Application with a given `nid` for the entire year */
  setApplicationYearlyBudget: BudgetYearly;
  /** Set a budget for an Environment with a given `nid` for the entire year */
  setEnvironmentYearlyBudget: BudgetYearly;
  setHook?: Maybe<Scalars["Boolean"]>;
  /** Set a budget for an OrgUnit with a given `nid` for the entire year */
  setOrgUnitBudget: BudgetYearly;
  setPrimaryBusinessContext?: Maybe<Scalars["Boolean"]>;
  /**
   * Set permissions for a user
   * If the ROLE is `ADMIN` applications list is obsolete
   * If the ROLE is  `TEAM_LEAD`, the `applications` field controls to which applications and
   * entities assigned the user has access
   */
  setUserPermissions?: Maybe<Scalars["Boolean"]>;
  /** Submit a chart job to be processed asynchronously. */
  submitChartJob?: Maybe<SubmitSearchJobResponse>;
  /** Submit a chart job to be processed asynchronously. */
  submitChartJobV2?: Maybe<SubmitSearchJobResponse>;
  /** Submit a chart job to be processed asynchronously. */
  submitOrganisationUnitsChartJob?: Maybe<SubmitSearchJobResponse>;
  /** Submit a search job to be processed asynchronously. */
  submitSearchJob?: Maybe<SubmitSearchJobResponse>;
  /** Submit a search V2 job to be processed asynchronously. */
  submitSearchJobV2?: Maybe<SubmitSearchJobResponse>;
  /** Submit a chart job to be processed asynchronously. */
  submitTagsChartJob?: Maybe<SubmitSearchJobResponse>;
  triggerRecalculation?: Maybe<Scalars["Boolean"]>;
  /**
   * Trigger the scan process for the entire customer
   * a subset of all the accounts can be selected, to skip scan for unwanted accounts
   * @deprecated use `triggerScanV2`
   */
  triggerScan?: Maybe<Scalars["Boolean"]>;
  /**
   * Trigger the scan process for the entire customer
   * a subset of all the accounts can be selected, to skip scan fo unwanted accounts.
   * Returns a list of account nids for which scan wasn't started because of invalid account data.
   */
  triggerScanV2?: Maybe<TriggerScanV2Output>;
  /** Update a key with different permissions */
  updateAccessKeyPermissions?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateAccountV3` instead */
  updateAccount?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateAccountV3` instead */
  updateAccountV2?: Maybe<Scalars["Boolean"]>;
  updateAccountV3?: Maybe<Account>;
  updateAnomalyThreshold?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateApplicationV2` instead */
  updateApplication?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateApplicationV3` instead */
  updateApplicationV2?: Maybe<Scalars["Boolean"]>;
  updateApplicationV3: Application;
  /**
   * Updates AWS account credentials
   * @deprecated use `updateAwsAccountCredentialsBatch` with single nid instead
   */
  updateAwsAccountCredentials?: Maybe<Scalars["Boolean"]>;
  /** Updates AWS account credentials in batch */
  updateAwsAccountCredentialsBatch?: Maybe<Scalars["Boolean"]>;
  /** Updates AWS billing config for given account id */
  updateAwsBillingConfigV2: AwsBillingData;
  /**
   * Updates Azure account credentials
   * @deprecated use `updateAzureAccountCredentialsBatch` with single nid instead
   */
  updateAzureAccountCredentials?: Maybe<Scalars["Boolean"]>;
  /** Updates Azure account credentials in batch */
  updateAzureAccountCredentialsBatch?: Maybe<Scalars["Boolean"]>;
  /** Updates Azure billing config */
  updateAzureBillingConfigV2: AzureBillingData;
  updateAzureTagPropagation?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateBusinessContextV2` instead */
  updateBusinessContext?: Maybe<Scalars["Boolean"]>;
  updateBusinessContextV2: BusinessContext;
  /** Update status or expiration date of existing cloud waste setting rule that classifies resources as cloud wastes */
  updateCloudWasteSetting?: Maybe<Scalars["Boolean"]>;
  updateCostCategoryMapping?: Maybe<Scalars["Boolean"]>;
  updateCostSavings?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateDiscoveryRuleV2` instead */
  updateDiscoveryRule?: Maybe<Scalars["Boolean"]>;
  updateDiscoveryRuleV2: DiscoveryRule;
  /**
   * Accept (when `accept` is equal to true) or reject (when `accept` is equal to false)
   * pending drifts for a list of resources given by the `ids` array
   * ids in the `ids` array are resource identifiers (`id` field from CloudResource)
   * if there are no drifts for one of resources in the list, an error will be returned.
   *
   * This mutation is semantically identical to the `resolveDrifts` mutation, but with different syntax for improved consistency.
   * @deprecated use `resolveDrifts` instead
   */
  updateDriftStatuses?: Maybe<Scalars["Boolean"]>;
  updateDriftsDisabled?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateEnvironmentV2` instead */
  updateEnvironment?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateEnvironmentV3` instead */
  updateEnvironmentV2?: Maybe<Scalars["Boolean"]>;
  updateEnvironmentV3: Environment;
  updateGCPTagPropagation?: Maybe<Scalars["Boolean"]>;
  /**
   * Updates Google Cloud account credentials
   * @deprecated use `updateGcpAccountCredentialsBatch` with single nid instead
   */
  updateGcpAccountCredentials?: Maybe<Scalars["Boolean"]>;
  /** Updates Google Cloud account credentials in batch */
  updateGcpAccountCredentialsBatch?: Maybe<Scalars["Boolean"]>;
  /** Updates GCP billing config for given onboarded organization ID */
  updateGcpBillingConfigV2: GcpBillingData;
  updateGlobalTagKeys?: Maybe<Scalars["Boolean"]>;
  /**
   * Updates Ibm Cloud account credentials
   * @deprecated use `updateIbmAccountCredentialsBatch` with single nid instead
   */
  updateIbmAccountCredentials?: Maybe<Scalars["Boolean"]>;
  /** Updates Ibm Cloud account credentials in batch */
  updateIbmAccountCredentialsBatch?: Maybe<Scalars["Boolean"]>;
  /** Update KPI */
  updateKPITarget: KpiTarget;
  /** Update a link between two estate records */
  updateLink?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateDiscoveryRule` instead */
  updateMappingRule?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateOrgUnitTypeV3` instead */
  updateOrgUnitTypeV2?: Maybe<Scalars["Boolean"]>;
  updateOrgUnitTypeV3: OrgUnitTypeV2;
  /** @deprecated use `updateOrgUnitV3` instead */
  updateOrgUnitV2?: Maybe<Scalars["Boolean"]>;
  /** @deprecated use `updateOrgUnitV4` instead */
  updateOrgUnitV3: OrgUnitV2;
  updateOrgUnitV4: UpdateOrgUnitV4Response;
  /** @deprecated use `updatePersonV2` instead */
  updatePerson?: Maybe<Scalars["Boolean"]>;
  updatePersonV2: Person;
  updateRICoverageTarget?: Maybe<Scalars["Boolean"]>;
  updateResourceCostSplitRule: ResourceCostSplitRule;
  /** Update threshold by `id` with `input` */
  updateThreshold: Threshold;
  /** Update plugin */
  updateTurbonomicIntegrationPlugin?: Maybe<IntegrationPlugin>;
  /** Update the last login date for a user */
  updateUserLastLoginDate?: Maybe<UserPermissions>;
  updateWorkflowConfig?: Maybe<Workflow>;
  updateWorkflowDetail?: Maybe<Workflow>;
};

/** Mutations */
export type MutationActivateWorkflowV2Args = {
  active: Scalars["Boolean"];
  id: Scalars["ID"];
};

/** Mutations */
export type MutationAddAwsBillingConfigV2Args = {
  input: AddAwsBillingConfig;
};

/** Mutations */
export type MutationAddAccountArgs = {
  input: AddAccount;
};

/** Mutations */
export type MutationAddAccountV2Args = {
  input: AddAccountV2;
};

/** Mutations */
export type MutationAddAccountV3Args = {
  input: AddAccountV3;
};

/** Mutations */
export type MutationAddApplicationArgs = {
  input: AddApplication;
};

/** Mutations */
export type MutationAddApplicationV2Args = {
  input: AddApplicationV2;
};

/** Mutations */
export type MutationAddAzureBillingConfigV2Args = {
  input: AddAzureBillingConfig;
};

/** Mutations */
export type MutationAddBusinessContextArgs = {
  input: AddBusinessContext;
};

/** Mutations */
export type MutationAddBusinessContextV2Args = {
  input: AddBusinessContext;
};

/** Mutations */
export type MutationAddCostCategoryMappingArgs = {
  input?: InputMaybe<AddCostCategoryMapping>;
};

/** Mutations */
export type MutationAddDiscoveryRuleArgs = {
  input: AddDiscoveryRule;
};

/** Mutations */
export type MutationAddDiscoveryRuleV2Args = {
  input: AddDiscoveryRule;
};

/** Mutations */
export type MutationAddEnvToOrgUnitV2Args = {
  envId: Scalars["ID"];
  orgUnitId: Scalars["ID"];
};

/** Mutations */
export type MutationAddEnvironmentArgs = {
  input: AddEnvironment;
};

/** Mutations */
export type MutationAddEnvironmentV2Args = {
  input: AddEnvironmentV2;
};

/** Mutations */
export type MutationAddEnvironmentV3Args = {
  input: AddEnvironmentV2;
};

/** Mutations */
export type MutationAddEnvironmentsToOrgUnitArgs = {
  input: UpdateOrgUnitEnvironmentsBodyInput;
};

/** Mutations */
export type MutationAddEventArgs = {
  input?: InputMaybe<AddEvent>;
};

/** Mutations */
export type MutationAddExternalIntegrationArgs = {
  integration: ExternalIntegration;
};

/** Mutations */
export type MutationAddGcpBillingConfigV2Args = {
  input: GcpBillingConfig;
};

/** Mutations */
export type MutationAddKpiTargetArgs = {
  input: AddKpiTarget;
};

/** Mutations */
export type MutationAddMappingRuleArgs = {
  input: AddMappingRule;
};

/** Mutations */
export type MutationAddOrgUnitTypeV2Args = {
  input: AddOrgUnitTypeV2;
};

/** Mutations */
export type MutationAddOrgUnitTypeV3Args = {
  input: AddOrgUnitTypeV2;
};

/** Mutations */
export type MutationAddOrgUnitV2Args = {
  input: AddOrgUnitV2;
};

/** Mutations */
export type MutationAddOrgUnitV3Args = {
  input: AddOrgUnitV2;
};

/** Mutations */
export type MutationAddPersonArgs = {
  input?: InputMaybe<AddPerson>;
};

/** Mutations */
export type MutationAddPersonV2Args = {
  input: AddPerson;
};

/** Mutations */
export type MutationAddResourceCostSplitRuleArgs = {
  input: ResourceCostSplitRuleInput;
};

/** Mutations */
export type MutationAddSandboxArgs = {
  input: AddSandbox;
};

/** Mutations */
export type MutationAddSearchPresetArgs = {
  input: AddSearchPresetInput;
};

/** Mutations */
export type MutationAddSearchPresetV2Args = {
  input: AddSearchPresetV2Input;
};

/** Mutations */
export type MutationAddSyntheticEstateRecordArgs = {
  input: AddSyntheticEstateRecord;
};

/** Mutations */
export type MutationAddThresholdArgs = {
  input: ThresholdAddInput;
};

/** Mutations */
export type MutationAddTurbonomicIntegrationPluginV2Args = {
  input: AddTurbonomicIntegrationPluginInput;
};

/** Mutations */
export type MutationAddWorkflowV2Args = {
  input: AddWorkflowInput;
};

/** Mutations */
export type MutationArchiveKpiTargetArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationAttachResourceArgs = {
  environmentNid: Scalars["String"];
  resourceNid: Scalars["String"];
};

/** Mutations */
export type MutationAttachResourceCostSplitRulesArgs = {
  input: BulkResourceCostSplitRuleInput;
};

/** Mutations */
export type MutationCloneWorkflowArgs = {
  id: Scalars["ID"];
  input: CloneWorkflowInput;
};

/** Mutations */
export type MutationCreateAccessKeyArgs = {
  input: CreateAccessInput;
};

/** Mutations */
export type MutationCreateLinkArgs = {
  link: LinkInput;
};

/** Mutations */
export type MutationDeleteLinkArgs = {
  link: LinkInput;
};

/** Mutations */
export type MutationExecuteWorkflowArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationLinkedRecordsArgs = {
  link?: InputMaybe<Array<LinkInput>>;
  unlink?: InputMaybe<Array<LinkInput>>;
};

/** Mutations */
export type MutationMappedRecordsArgs = {
  map?: InputMaybe<Array<MappedRecord>>;
  unmap?: InputMaybe<Array<MappedRecord>>;
};

/** Mutations */
export type MutationMarkEnvironmentsForDeletionArgs = {
  nids: Array<Scalars["ID"]>;
};

/** Mutations */
export type MutationMarkNotificationsAsReadArgs = {
  ids: Array<Scalars["String"]>;
};

/** Mutations */
export type MutationRegenerateAccessKeySecretArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationRegenerateExternalIntegrationSecretArgs = {
  integrationId: Scalars["String"];
};

/** Mutations */
export type MutationRegisterExternalIntegrationArgs = {
  integration: ExternalIntegration;
};

/** Mutations */
export type MutationRemoveAccessKeyArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveAccountArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveAccountV2Args = {
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveAccountsArgs = {
  input: BatchRemoveAccountsInput;
};

/** Mutations */
export type MutationRemoveApplicationArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveApplicationV2Args = {
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveAwsBillingConfigV2Args = {
  accountId: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveAzureBillingConfigV2Args = {
  capacitorCustomerId: Scalars["String"];
};

/** Mutations */
export type MutationRemoveBusinessContextArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveCostCategoryMappingArgs = {
  id?: InputMaybe<Scalars["String"]>;
};

/** Mutations */
export type MutationRemoveDiscoveryRuleArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveEnvFromOrgUnitV2Args = {
  envId: Scalars["ID"];
  orgUnitId: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveEnvironmentArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveEnvironmentV2Args = {
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveExternalIntegrationArgs = {
  integrationId: Scalars["String"];
};

/** Mutations */
export type MutationRemoveGcpBillingConfigV2Args = {
  onboardedOrgId: Scalars["String"];
};

/** Mutations */
export type MutationRemoveHookArgs = {
  type: HookType;
};

/** Mutations */
export type MutationRemoveIntegrationPluginArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveMappingRuleArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveOrgUnitTypeV2Args = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveOrgUnitV2Args = {
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationRemovePersonArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveResourceCostSplitRuleArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveSearchPresetArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveSearchPresetV2Args = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationRemoveThresholdArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationRemoveUserPermissionsArgs = {
  email: Scalars["String"];
};

/** Mutations */
export type MutationRemoveWorkflowArgs = {
  id: Scalars["ID"];
};

/** Mutations */
export type MutationRequestEstateRecordsExportArgs = {
  input: EstateRecordsExportInput;
};

/** Mutations */
export type MutationRequestEstateV2ExportArgs = {
  input: RequestEstateV2ExportInput;
};

/** Mutations */
export type MutationRequestEstateV2ExportAsyncArgs = {
  input: RequestEstateV2ExportInput;
};

/** Mutations */
export type MutationRequestEstateV3ExportAsyncArgs = {
  input: RequestEstateV3ExportInput;
};

/** Mutations */
export type MutationRequestGroupedRecordsExportArgs = {
  input: RequestGroupedRecordsExportInput;
};

/** Mutations */
export type MutationReservedInstancesArgs = {
  mark?: InputMaybe<Array<MarkReservedInstance>>;
  unmark?: InputMaybe<Array<UnmarkReservedInstance>>;
};

/** Mutations */
export type MutationResolveAllDriftsArgs = {
  input: ResolveAllDriftsInput;
};

/** Mutations */
export type MutationResolveDriftsArgs = {
  input: ResolveDriftsInput;
};

/** Mutations */
export type MutationSetApplicationYearlyBudgetArgs = {
  budget: BudgetYearlyInput;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationSetEnvironmentYearlyBudgetArgs = {
  budget: BudgetYearlyInput;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationSetHookArgs = {
  type: HookType;
  url: Scalars["String"];
};

/** Mutations */
export type MutationSetOrgUnitBudgetArgs = {
  budget: BudgetYearlyInput;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationSetPrimaryBusinessContextArgs = {
  id: Scalars["String"];
};

/** Mutations */
export type MutationSetUserPermissionsArgs = {
  email: Scalars["String"];
  input: AccessInput;
};

/** Mutations */
export type MutationSubmitChartJobArgs = {
  chartType?: InputMaybe<ChartType>;
  endDate: Scalars["String"];
  filter?: InputMaybe<EstateV2Filter>;
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
};

/** Mutations */
export type MutationSubmitChartJobV2Args = {
  chartType?: InputMaybe<ChartType>;
  endDate: Scalars["String"];
  filter?: InputMaybe<SearchV2Filter>;
  startDate: Scalars["String"];
};

/** Mutations */
export type MutationSubmitOrganisationUnitsChartJobArgs = {
  businessContextNID: Scalars["String"];
  endDate: Scalars["String"];
  filter?: InputMaybe<SearchV2Filter>;
  startDate: Scalars["String"];
};

/** Mutations */
export type MutationSubmitSearchJobArgs = {
  filter?: InputMaybe<EstateV2Filter>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SearchSort>;
};

/** Mutations */
export type MutationSubmitSearchJobV2Args = {
  filter?: InputMaybe<SearchV2Filter>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SearchV2Sort>;
};

/** Mutations */
export type MutationSubmitTagsChartJobArgs = {
  endDate: Scalars["String"];
  filter?: InputMaybe<SearchV2Filter>;
  startDate: Scalars["String"];
  tagKey: Scalars["String"];
};

/** Mutations */
export type MutationTriggerScanArgs = {
  accountNids?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Mutations */
export type MutationTriggerScanV2Args = {
  accountNids?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Mutations */
export type MutationUpdateAccessKeyPermissionsArgs = {
  id: Scalars["ID"];
  input: AccessInput;
};

/** Mutations */
export type MutationUpdateAccountArgs = {
  input: UpdateAccount;
};

/** Mutations */
export type MutationUpdateAccountV2Args = {
  input: UpdateAccountV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateAccountV3Args = {
  input: UpdateAccountV3;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateAnomalyThresholdArgs = {
  input: UpdateAnomalyThreshold;
};

/** Mutations */
export type MutationUpdateApplicationArgs = {
  input: UpdateApplication;
};

/** Mutations */
export type MutationUpdateApplicationV2Args = {
  input: UpdateApplicationV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateApplicationV3Args = {
  input: UpdateApplicationV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateAwsAccountCredentialsArgs = {
  input: UpdateAwsAccountCredentialsInput;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateAwsAccountCredentialsBatchArgs = {
  input: UpdateAwsAccountCredentialsInput;
  nids: Array<Scalars["ID"]>;
};

/** Mutations */
export type MutationUpdateAwsBillingConfigV2Args = {
  input: UpdateAwsBillingConfig;
};

/** Mutations */
export type MutationUpdateAzureAccountCredentialsArgs = {
  input: UpdateAzureAccountCredentialsInput;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateAzureAccountCredentialsBatchArgs = {
  input: UpdateAzureAccountCredentialsInput;
  nids: Array<Scalars["ID"]>;
};

/** Mutations */
export type MutationUpdateAzureBillingConfigV2Args = {
  input: UpdateAzureBillingConfig;
};

/** Mutations */
export type MutationUpdateAzureTagPropagationArgs = {
  input: UpdateAzureTagPropagation;
};

/** Mutations */
export type MutationUpdateBusinessContextArgs = {
  id: Scalars["String"];
  input: UpdateBusinessContext;
};

/** Mutations */
export type MutationUpdateBusinessContextV2Args = {
  id: Scalars["String"];
  input: UpdateBusinessContext;
};

/** Mutations */
export type MutationUpdateCloudWasteSettingArgs = {
  input: UpdateCloudWasteSetting;
};

/** Mutations */
export type MutationUpdateCostCategoryMappingArgs = {
  input?: InputMaybe<UpdateCostCategoryMapping>;
};

/** Mutations */
export type MutationUpdateCostSavingsArgs = {
  input: InputCostSavings;
};

/** Mutations */
export type MutationUpdateDiscoveryRuleArgs = {
  input: UpdateDiscoveryRule;
};

/** Mutations */
export type MutationUpdateDiscoveryRuleV2Args = {
  input: UpdateDiscoveryRule;
};

/** Mutations */
export type MutationUpdateDriftStatusesArgs = {
  accept?: InputMaybe<Scalars["Boolean"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Mutations */
export type MutationUpdateDriftsDisabledArgs = {
  input?: InputMaybe<UpdateDriftsDisabled>;
};

/** Mutations */
export type MutationUpdateEnvironmentArgs = {
  input: UpdateEnvironment;
};

/** Mutations */
export type MutationUpdateEnvironmentV2Args = {
  input: UpdateEnvironmentV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateEnvironmentV3Args = {
  input: UpdateEnvironmentV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateGcpTagPropagationArgs = {
  input: UpdateGcpTagPropagation;
};

/** Mutations */
export type MutationUpdateGcpAccountCredentialsArgs = {
  input: UpdateGcpAccountCredentialsInput;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateGcpAccountCredentialsBatchArgs = {
  input: UpdateGcpAccountCredentialsInput;
  nids: Array<Scalars["ID"]>;
};

/** Mutations */
export type MutationUpdateGcpBillingConfigV2Args = {
  input: UpdateGcpBillingConfig;
};

/** Mutations */
export type MutationUpdateGlobalTagKeysArgs = {
  input?: InputMaybe<UpdateGlobalTagKeys>;
};

/** Mutations */
export type MutationUpdateIbmAccountCredentialsArgs = {
  input: UpdateIbmAccountCredentialsInput;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateIbmAccountCredentialsBatchArgs = {
  input: UpdateIbmAccountCredentialsInput;
  nids: Array<Scalars["ID"]>;
};

/** Mutations */
export type MutationUpdateKpiTargetArgs = {
  id: Scalars["ID"];
  input: UpdateKpiTarget;
};

/** Mutations */
export type MutationUpdateLinkArgs = {
  newLink: LinkInput;
  oldLink: LinkInput;
};

/** Mutations */
export type MutationUpdateMappingRuleArgs = {
  input: UpdateMappingRule;
};

/** Mutations */
export type MutationUpdateOrgUnitTypeV2Args = {
  id: Scalars["ID"];
  input: UpdateOrgUnitTypeV2;
};

/** Mutations */
export type MutationUpdateOrgUnitTypeV3Args = {
  id: Scalars["ID"];
  input: UpdateOrgUnitTypeV2;
};

/** Mutations */
export type MutationUpdateOrgUnitV2Args = {
  input: UpdateOrgUnitV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateOrgUnitV3Args = {
  input: UpdateOrgUnitV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdateOrgUnitV4Args = {
  input: UpdateOrgUnitV2;
  nid: Scalars["ID"];
};

/** Mutations */
export type MutationUpdatePersonArgs = {
  input?: InputMaybe<UpdatePerson>;
};

/** Mutations */
export type MutationUpdatePersonV2Args = {
  input: UpdatePerson;
};

/** Mutations */
export type MutationUpdateRiCoverageTargetArgs = {
  target?: InputMaybe<Scalars["Float"]>;
};

/** Mutations */
export type MutationUpdateResourceCostSplitRuleArgs = {
  input: ResourceCostSplitRuleUpdateInput;
};

/** Mutations */
export type MutationUpdateThresholdArgs = {
  id: Scalars["ID"];
  input: ThresholdUpdateInput;
};

/** Mutations */
export type MutationUpdateTurbonomicIntegrationPluginArgs = {
  id: Scalars["ID"];
  input: UpdateTurbonomicIntegrationPluginInput;
};

/** Mutations */
export type MutationUpdateUserLastLoginDateArgs = {
  email: Scalars["String"];
};

/** Mutations */
export type MutationUpdateWorkflowConfigArgs = {
  id: Scalars["ID"];
  input: UpdateWorkflowConfigInput;
};

/** Mutations */
export type MutationUpdateWorkflowDetailArgs = {
  id: Scalars["ID"];
  input: UpdateWorkflowInput;
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  id?: Maybe<Scalars["String"]>;
};

export type Notification = {
  __typename?: "Notification";
  createdAt: Scalars["AWSDateTime"];
  details: Scalars["String"];
  id: Scalars["String"];
  read: Scalars["Boolean"];
  targetView?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  type: NotificationType;
  updatedAt: Scalars["AWSDateTime"];
};

export enum NotificationType {
  Error = "ERROR",
  Info = "INFO",
  Warning = "WARNING",
}

export type NotificationsFilter = {
  unread?: InputMaybe<Scalars["Boolean"]>;
};

export type NotificationsResponse = {
  __typename?: "NotificationsResponse";
  cursor?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
};

export enum OptimisationLevel {
  FullyOptimised = "FULLY_OPTIMISED",
  ModerateOptimisation = "MODERATE_OPTIMISATION",
  NeedsOptimisation = "NEEDS_OPTIMISATION",
  PartiallyOptimised = "PARTIALLY_OPTIMISED",
}

export type OrgUnitAccumulatedCostInput = {
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  orgUnitId: Scalars["ID"];
  startDate: Scalars["String"];
};

export type OrgUnitAccumulatedCostResponse = {
  __typename?: "OrgUnitAccumulatedCostResponse";
  timePoints?: Maybe<Array<Maybe<TimePoint>>>;
  total: Scalars["String"];
};

export type OrgUnitApplication = {
  __typename?: "OrgUnitApplication";
  /** Budget that can be set for the application. */
  budgetYearly: BudgetYearly;
  /**
   * `cost` is the total cost of all resources assigned to the environments of the application
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  cost?: Maybe<Cost>;
  /** `displayName` is a string up to 256 characters long that helps to identify the application */
  displayName: Scalars["String"];
  environments: Array<OrgUnitApplicationEnvironment>;
  /** `id` is the id from CustomerDB */
  id: Scalars["String"];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars["String"];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars["String"];
};

export type OrgUnitApplicationBudgetYearlyArgs = {
  year: Scalars["String"];
};

export type OrgUnitApplicationCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type OrgUnitApplicationEnvironment = {
  __typename?: "OrgUnitApplicationEnvironment";
  /** Budget that can be set for the environment. */
  budgetYearly: BudgetYearly;
  /** `cloudWasteAggregate` describes aggregated information about Cloud Waste */
  cloudWasteAggregate?: Maybe<CloudWasteAggregate>;
  /**
   * `cost` of all resources assigned to the environment, `providers` is an optional filter to find costs only for certain
   * set of providers
   */
  cost?: Maybe<Cost>;
  /** `displayName` is a string up to 256 characters long that helps to identify the environment */
  displayName: Scalars["String"];
  /** `id` is the id from CustomerDB */
  id: Scalars["String"];
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name: Scalars["String"];
  /** `nid` is NordcloudID, or the id taken from Cloud Estate */
  nid: Scalars["String"];
  /** `resourceCount` is the number of resources that belong to this environment */
  resourceCount: Scalars["Int"];
};

export type OrgUnitApplicationEnvironmentBudgetYearlyArgs = {
  year: Scalars["String"];
};

export type OrgUnitApplicationEnvironmentCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type OrgUnitApplicationsResponse = {
  __typename?: "OrgUnitApplicationsResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<OrgUnitApplication>>;
};

export type OrgUnitKpi = {
  details: KpiDetails;
};

export type OrgUnitKpiDetailsArgs = {
  timePeriod: KpiTimePeriod;
};

export type OrgUnitKpiDetails = {
  __typename?: "OrgUnitKpiDetails";
  nonProdHours: KpiDetails;
  overallWaste: KpiDetails;
};

export type OrgUnitKpiDetailsNonProdHoursArgs = {
  timePeriod: KpiTimePeriod;
};

export type OrgUnitKpiDetailsOverallWasteArgs = {
  timePeriod: KpiTimePeriod;
};

export type OrgUnitKpis = {
  __typename?: "OrgUnitKpis";
  nonProdHours: KpiNonProdHoursForOrgUnit;
  overallWaste: KpiOverallWasteForOrgUnit;
};

export type OrgUnitOtherCategoriesCostInput = {
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  orgUnitId: Scalars["ID"];
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

export type OrgUnitOtherCategoriesCostResponse = {
  __typename?: "OrgUnitOtherCategoriesCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

/** Specifies the amount of most expensive applications for the given Org Unit over the given period to be returned. */
export type OrgUnitTopApplicationsCostInput = {
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  orgUnitId: Scalars["ID"];
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

/** Returns costs at the desired granularity as application-specific TimePoints */
export type OrgUnitTopApplicationsCostResponse = {
  __typename?: "OrgUnitTopApplicationsCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

export type OrgUnitTopCategoriesCostInput = {
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  orgUnitId: Scalars["ID"];
  startDate: Scalars["String"];
  top: Scalars["Int"];
};

export type OrgUnitTopCategoriesCostResponse = {
  __typename?: "OrgUnitTopCategoriesCostResponse";
  timePoints?: Maybe<Array<Maybe<GroupTimePoint>>>;
  total: Scalars["String"];
};

export type OrgUnitTypeV2 = {
  __typename?: "OrgUnitTypeV2";
  /** `description` is an optional text that helps identify what the type represents */
  description?: Maybe<Scalars["String"]>;
  /** Org unit types have id, not nid since they are not searchable entities */
  id: Scalars["ID"];
  /** `name` is a string up to 256 characters long that describes the type */
  name: Scalars["String"];
};

export type OrgUnitTypesV2Response = {
  __typename?: "OrgUnitTypesV2Response";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  types?: Maybe<Array<OrgUnitTypeV2>>;
};

export type OrgUnitUserError = {
  __typename?: "OrgUnitUserError";
  /** User error message code */
  code: OrgUnitUserErrorCode;
  /** Error message, suited to be user facing */
  message: Scalars["String"];
};

export enum OrgUnitUserErrorCode {
  DuplicateOrgUnitName = "DUPLICATE_ORG_UNIT_NAME",
}

/** Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved. */
export type OrgUnitV2 = {
  __typename?: "OrgUnitV2";
  budgetYearly: BudgetYearly;
  /** The context that the OrgUnit belongs to */
  businessContext: BusinessContext;
  /** The contact person for this org unit */
  contact?: Maybe<Person>;
  /**
   * `cost` is the total cost of all resources assigned to the environments of the org unit,
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  cost?: Maybe<Cost>;
  /** `description` is an optional text that helps identify what the unit represents */
  description?: Maybe<Scalars["String"]>;
  /** A paginated list of environments assigned to this org unit */
  environments?: Maybe<EnvironmentResponse>;
  forecastSpendYearly: ForecastSpendYearly;
  id: Scalars["ID"];
  /** @deprecated replaced by kpis field */
  kpiDetails: OrgUnitKpiDetails;
  kpis: OrgUnitKpis;
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name: Scalars["String"];
  /**
   * Org units are differentiated by Nordcloud ID, a unique identifier which is present for
   * every searchable entity in Klarity
   */
  nid: Scalars["ID"];
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: Maybe<Scalars["ID"]>;
  spendByDateRange: SpendByDateRange;
  spendYearly: SpendYearly;
  /** Each org unit can have a type assigned, which works as a label for the org unit */
  type?: Maybe<OrgUnitTypeV2>;
};

/** Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved. */
export type OrgUnitV2BudgetYearlyArgs = {
  year: Scalars["String"];
};

/** Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved. */
export type OrgUnitV2CostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

/** Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved. */
export type OrgUnitV2EnvironmentsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

/** Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved. */
export type OrgUnitV2ForecastSpendYearlyArgs = {
  year: Scalars["String"];
};

/** Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved. */
export type OrgUnitV2SpendByDateRangeArgs = {
  endDate: Scalars["String"];
  startDate: Scalars["String"];
};

/** Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved. */
export type OrgUnitV2SpendYearlyArgs = {
  year: Scalars["String"];
};

export type OrgUnitWithChildren = {
  __typename?: "OrgUnitWithChildren";
  budgetYearly: BudgetYearly;
  /** The context that the OrgUnit belongs to */
  businessContext: BusinessContext;
  /** All child org units */
  children?: Maybe<Array<OrgUnitWithChildren>>;
  /** `cloudWasteAggregate` describes aggregated information about Cloud Waste */
  cloudWasteAggregate?: Maybe<CloudWasteAggregate>;
  /**
   * `co2e` is the total co2e of all resources assigned to the environments of the org unit,
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  co2e?: Maybe<Co2e>;
  /** The contact person for this org unit */
  contact?: Maybe<Person>;
  /**
   * `cost` is the total cost of all resources assigned to the environments of the org unit,
   * `providers` is an optional filter to find costs only for certain set of providers
   */
  cost?: Maybe<Cost>;
  /** `description` is an optional text that helps identify what the unit represents */
  description?: Maybe<Scalars["String"]>;
  /** A paginated list of environments assigned to this org unit */
  environments?: Maybe<EnvironmentResponse>;
  id: Scalars["ID"];
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name: Scalars["String"];
  /**
   * Org units are differentiated by Nordcloud ID, a unique identifier which is present for
   * every searchable entity in Klarity
   */
  nid: Scalars["ID"];
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: Maybe<Scalars["ID"]>;
  /** Each org unit can have a type assigned, which works as a label for the org unit */
  type?: Maybe<OrgUnitTypeV2>;
};

export type OrgUnitWithChildrenBudgetYearlyArgs = {
  year: Scalars["String"];
};

export type OrgUnitWithChildrenCo2eArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type OrgUnitWithChildrenCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type OrgUnitWithChildrenEnvironmentsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type OrgUnitsV2NonPaginatedResponse = {
  __typename?: "OrgUnitsV2NonPaginatedResponse";
  count?: Maybe<Scalars["Int"]>;
  orgUnits?: Maybe<Array<OrgUnitV2>>;
};

export type OrgUnitsV2Response = {
  __typename?: "OrgUnitsV2Response";
  count?: Maybe<Scalars["Int"]>;
  orgUnits?: Maybe<Array<OrgUnitV2>>;
  pages?: Maybe<Scalars["Int"]>;
};

export type Organization = {
  __typename?: "Organization";
  cost?: Maybe<Cost>;
  costYearly: Array<CostWithPeriod>;
  createdAt: Scalars["AWSDateTime"];
  id: Scalars["String"];
  updatedAt: Scalars["AWSDateTime"];
};

export type OrganizationCostArgs = {
  providers?: InputMaybe<Array<Provider>>;
};

export type OrganizationCostYearlyArgs = {
  providers?: InputMaybe<Array<Provider>>;
  year: Scalars["String"];
};

export type OriginalCost = {
  __typename?: "OriginalCost";
  currentMonth?: Maybe<Scalars["String"]>;
  previousMonth?: Maybe<Scalars["String"]>;
};

export type Person = {
  __typename?: "Person";
  email: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum Provider {
  Aws = "AWS",
  Azure = "AZURE",
  External = "EXTERNAL",
  Gcp = "GCP",
  Hybrid = "HYBRID",
  Ibmcloud = "IBMCLOUD",
  Kubernetes = "KUBERNETES",
  Nordcloud = "NORDCLOUD",
  Openshift = "OPENSHIFT",
  Services = "SERVICES",
  Vmware = "VMWARE",
}

/** Queries */
export type Query = {
  __typename?: "Query";
  /** Get permissions of an active access key, without secret */
  accessKeyPermissions?: Maybe<AccessKeyPermissions>;
  /** List all active access keys, without secret */
  accessKeysPermissions?: Maybe<AccessKeysPermissionsResponse>;
  /** @deprecated use `accountV2` instead */
  account?: Maybe<Account>;
  /**
   * Get daily breakdown of account total cost and cloud resource cost
   * `accountProviderId` is how the account is identified within cloud provider, e.g.
   * `123123123123` for AWS, subscription UUID for Azure, organization id for GCP,
   * for VMWARE provider this field contains nid as in VMWARE there is no provider id concept.
   * 'period' is relative period from current day, possible values (CURRENT | PREVIOUS)
   */
  accountCostComparison: Array<AccountCostComparisonRecord>;
  /**
   * Get spends for account with `accountProviderId`
   *
   * Note: the accountProviderId is the cloud-side ID, not Klarity-side ID (except for Kubernetes and Openshift)
   * AWS - account number
   * AZURE - subscription ID
   * GCP - account IDs
   * IBMCLOUD - account ID
   * KUBERNETES - NID of the account (Klarity-side ID)
   * OPENSHIFT - NID of the account (Klarity-side ID)
   */
  accountSpends?: Maybe<AccountSpends>;
  /** Get statuses divided by providers */
  accountStatuses: Array<AccountProviderStatusSummary>;
  accountV2?: Maybe<Account>;
  /** @deprecated use `accountsV2` instead */
  accounts?: Maybe<Array<Maybe<Account>>>;
  accountsV2?: Maybe<AccountsResponse>;
  /**
   * `accumulatedCost` returns accumulated cost for customer's tenant.
   * `input` is a input used to specify start date, end date and granularity of data.
   */
  accumulatedCost: AccumulatedCostResponse;
  allReservationsRecommendations: Array<Maybe<AllReservationsRecommendationsResponse>>;
  /** Get all costs with anomaly info attached for application */
  anomalies: Array<Anomaly>;
  /** @deprecated Query doesn't support pagination, use anomalyV2 */
  anomaly: Array<AnomalyService>;
  anomalySubtypes: Array<AnomalyServiceSubtype>;
  anomalyV2: AnomalyDetailsResponse;
  application?: Maybe<Application>;
  applicationAccumulatedCost: ApplicationAccumulatedCostResponse;
  /** `applicationOtherCategoriesCost` returns remaining categories, which are not a part of `applicationTopCategoriesCost` */
  applicationOtherCategoriesCost: ApplicationOtherCategoriesCostResponse;
  applicationTopCategoriesCost: ApplicationTopCategoriesCostResponse;
  applicationTopEnvironmentsCost: ApplicationTopEnvironmentsCostResponse;
  /** @deprecated use `applicationsPaginated` instead */
  applications?: Maybe<Array<Maybe<Application>>>;
  applicationsByIds?: Maybe<Array<Maybe<Application>>>;
  /**
   * 'estateRecordNid' filter returns applications list for specified estate record.
   * If no filter presented 'applicationsPaginated' returns all applications for customer
   */
  applicationsPaginated?: Maybe<ApplicationsPaginatedResponse>;
  awsBillingData: Array<Maybe<AwsBillingData>>;
  azureBillingData: Array<Maybe<AzureBillingData>>;
  businessContext?: Maybe<BusinessContext>;
  businessContexts?: Maybe<BusinessContextResponse>;
  categoriesByIds: Array<Category>;
  chargeTypes?: Maybe<ChargeTypesResponse>;
  /**
   * Get the status of chart job and chart(executed asynchronously) results, when job is COMPLETED.
   * If granularity is not defined, MONTHLY is used
   */
  chartJob?: Maybe<ChartJobStatusResponse>;
  /** Get the status of the chart job and chart paginated results, when job is COMPLETED. */
  chartJobTable?: Maybe<ChartJobTableResponse>;
  /** Get cloud account costs grouped by charge type */
  cloudAccountCostsByChargeType?: Maybe<CloudAccountCostsByChargeTypeResponse>;
  /** Get cloud account costs grouped by provider */
  cloudAccountCostsByProvider?: Maybe<CloudAccountCostsByProviderResponse>;
  /** Returns a list of cloud account nids without pagination */
  cloudAccountNids?: Maybe<CloudAccountNidsResponse>;
  /** `cloudAccountSavingCosts` returns waste and co2e costs of account by providerId */
  cloudAccountSavingCosts: CloudAccountSavingCosts;
  cloudAccounts?: Maybe<CloudAccountsResponse>;
  /** `cloudWastePoliciesCategorised` returns categorised rules that classify resources as cloud wastes */
  cloudWastePoliciesCategorised: Array<CloudWastePoliciesCategorised>;
  /** `cloudWasteSettings` returns defined rules that classify resources as cloud wastes */
  cloudWasteSettings: Array<CloudWasteSetting>;
  /** `cloudWasteSettingsCategorised` returns categorised rules that classify resources as cloud wastes */
  cloudWasteSettingsCategorised: Array<CloudWasteSettingCategorised>;
  /**
   * `cloudWasteSummary` describes summarized cost of cloud waste per policy.
   * It takes optional `period` parameter - string in format YYYY-MM, empty value represents current billing period
   */
  cloudWasteSummary?: Maybe<CloudWasteSummaryResponse>;
  /**
   * `cloudWasteSummaryCategorised` describes summarized cost of cloud waste per policy category.
   * It takes optional `period` parameter - string in format YYYY-MM, empty value represents current billing period
   */
  cloudWasteSummaryCategorised?: Maybe<CloudWasteSummaryCategorisedResponse>;
  /**
   * `cloudWasteSummaryTotalPotentialSavings` describes sum of maximum potential monthly savings of the saving suggestions
   * enabled resources for given period and previous period of the given billing period.
   */
  cloudWasteSummaryTotalPotentialSavings?: Maybe<TotalPotentialMonthlySavings>;
  /** `clusterResourceConsumption` returns lists of cluster resources (cpu & memory) consumed by Kubernetes */
  clusterResourceConsumption?: Maybe<ClusterResourceConsumptionResponse>;
  costCategories?: Maybe<Array<Maybe<CostCategory>>>;
  costMappings?: Maybe<Array<Maybe<CostCategoryMapping>>>;
  /** `customerApplicationsCost` returns customer's applications cost for specified date range and granularity. */
  customerApplicationsCost: CustomerApplicationsCostResponse;
  /** Returns customer-wide categorized costs over the requested date range */
  customerCategoriesCost: CustomerCategoriesCostResponse;
  customerConfig?: Maybe<CustomerConfig>;
  /** `customerCorrectionCost` returns customer's correction cost for specified date range and granularity. */
  customerCorrectionCost: CustomerCorrectionResponse;
  customerDirtiness: Dirtiness;
  /**
   * Returns customer-wide services costs over the requested date range.
   * Up to 50 services can be retrieved per invocation.
   * @deprecated Use customerServicesCostV2
   */
  customerServicesCost: CustomerServicesCostResponse;
  /**
   * Returns customer-wide services costs over the requested date range.
   * Up to 50 services can be retrieved per invocation.
   */
  customerServicesCostV2: CustomerServicesCostResponseV2;
  dashboardApplicationCost?: Maybe<Array<DashboardApplicationCost>>;
  /** `defaultApplication` returns default application */
  defaultApplication?: Maybe<Application>;
  /** `defaultEnvironment` returns default environment */
  defaultEnvironment?: Maybe<Environment>;
  discoveryRules?: Maybe<Array<Maybe<DiscoveryRule>>>;
  /** `downloadExport` returns URL of a report previously generated by `requestEstateRecordsExport` */
  downloadExport?: Maybe<DownloadExportResponse>;
  /**
   * Return resources that have an active/unresolved drift assignment
   * @deprecated use `estateAllocationDrifts` instead
   */
  driftedResources?: Maybe<DriftedResourcesResponse>;
  environment?: Maybe<Environment>;
  environmentAccumulatedCost: EnvironmentAccumulatedCostResponse;
  environmentOtherCategoriesCost: EnvironmentOtherCategoriesCostResponse;
  environmentTopCategoriesCost: EnvironmentTopCategoriesCostResponse;
  /**
   * A list of environments, filtered with the `ids` arugments, the list consists of all existing environments that match
   * `nids` of given inputs.
   */
  environmentsByIds: Array<Environment>;
  /**
   * Returns all discovered estate records
   * @deprecated use `estateV2` instead
   */
  estate?: Maybe<ResourceResponse>;
  /** Return estate records that have an active/unresolved drift assignment */
  estateAllocationDrifts: EstateAllocationDriftsResponse;
  /**
   * Returns count of all discovered estate records, supports providing multiple filter values
   * e.g. account: ["id1", "id2"]
   * @deprecated use `estateResourceCount` instead
   */
  estateCount?: Maybe<Scalars["Int"]>;
  /** Returns single estate record */
  estateRecord?: Maybe<Resource>;
  /**
   * Returns count of all discovered estate records, supports providing multiple filter values
   * e.g. cloudAccount: ["id1", "id2"]
   * @deprecated use `estateResourceCountV2` instead
   */
  estateResourceCount?: Maybe<Scalars["Int"]>;
  /**
   * Returns count of all discovered estate records, supports providing multiple filter values
   * e.g. cloudAccount: ["id1", "id2"]
   */
  estateResourceCountV2?: Maybe<Scalars["Int"]>;
  /**
   * This is the new version of the `estate` or `estateRecords` query, which is for now in an 'experimental' stage.
   * This query is missing parameters like search phrase, etc. since we will add them later, and we are not sure
   * on how they should be defined.
   *
   * The query supports single level sorting, via the `sorting` field. It is not required, if no sorting is required,
   * the default sorting will be used - sorting by `COST` `DSC` - or by monthly cost from highest to lowest.
   *
   * This query supports filtering by given filters via the optional `filter` field.
   *
   * This query supports searching by given query via the optional `query` field.
   *
   * This query is naturally using pagination. If the `page` parameter is missing, the first page will be returned.
   * @deprecated use `submitSearchJob` & `searchJob` asynchronous queries to retrieve estate records
   */
  estateV2?: Maybe<ResourceResponse>;
  /** Get events for given nid in date range from evenciarz */
  events?: Maybe<Array<Event>>;
  externalIntegrationDetails?: Maybe<ExternalIntegrationDetailsResponse>;
  externalIntegrations?: Maybe<ExternalIntegrationsResponse>;
  gcpBillingData: Array<Maybe<GcpBillingData>>;
  /** Get unique projects for GCP provider */
  gcpProjects?: Maybe<GcpProjectsResponse>;
  /** `getReservationsAndCommitmentsRecommendations` returns Reservation and Commitment Recommendation of an instance family */
  getReservationsAndCommitmentsRecommendations?: Maybe<ReservationsAndCommitmentsRecommendationsResponse>;
  /** Get global metrics for Applications */
  globalMetrics?: Maybe<GlobalMetricsResponse>;
  globalTagKeys?: Maybe<GlobalTagKeys>;
  historyEvents?: Maybe<WorkflowHistoryListOutput>;
  hooks: Array<Hook>;
  /** Get plugin list */
  integrationPlugins: Array<IntegrationPlugin>;
  listCategories: ListCategoriesResponse;
  listReservationCoveredInstances?: Maybe<ReservationCoveredInstancesPaginated>;
  listReservationsAndCommitmentsCoverage?: Maybe<ReservationsAndCommitmentsCoveragePaginated>;
  listReservationsAndCommitmentsCoverageDetails?: Maybe<ListCommitmentsCoverageDetailsResponse>;
  /** Get tag keys for specified period interval */
  listTagKeys?: Maybe<ListUniqueTagKeysResponse>;
  /** @deprecated use `discoveryRules` instead */
  mappingRules?: Maybe<Array<Maybe<MappingRule>>>;
  monthlyReport?: Maybe<MonthlyReportResponse>;
  notifications?: Maybe<NotificationsResponse>;
  orgUnitAccumulatedCost: OrgUnitAccumulatedCostResponse;
  orgUnitApplications?: Maybe<OrgUnitApplicationsResponse>;
  /** `orgUnitOtherCategoriesCost` returns remaining categories, which are not a part of `orgUnitTopCategoriesCost` */
  orgUnitOtherCategoriesCost: OrgUnitOtherCategoriesCostResponse;
  orgUnitTopApplicationsCost: OrgUnitTopApplicationsCostResponse;
  orgUnitTopCategoriesCost: OrgUnitTopCategoriesCostResponse;
  orgUnitTypeV2?: Maybe<OrgUnitTypeV2>;
  orgUnitTypesV2?: Maybe<OrgUnitTypesV2Response>;
  orgUnitV2?: Maybe<OrgUnitV2>;
  orgUnitWithChildren?: Maybe<OrgUnitWithChildren>;
  orgUnitsV2?: Maybe<OrgUnitsV2Response>;
  /** Information about organization */
  organization?: Maybe<Organization>;
  people?: Maybe<Array<Maybe<Person>>>;
  person?: Maybe<Person>;
  primaryBusinessContext?: Maybe<BusinessContext>;
  recalculationTime: RecalculationTime;
  recalculations: Array<Maybe<Recalculation>>;
  /**
   * `reservableResources` lists all resources, assigned to a given environment, that are reservable. This means either
   * resources that are reserved all that can be reserved
   */
  reservableResources?: Maybe<CloudResourcesResponse>;
  reservationDetails?: Maybe<Reservation>;
  /** `reservationUtilization` returns  Estate record detail utilization graph with utilization, benefit and forecast values */
  reservationUtilization?: Maybe<ReservationUtilizationDetail>;
  /** `reservationUtilizationV2` returns  Estate record detail utilization graph with utilization, reservationIDs, benefit and forecast values */
  reservationUtilizationV2?: Maybe<ReservationUtilizationDetail>;
  /** `reservationsActiveTabs` returns lists for serviceUsageType with it's active state */
  reservationsActiveTabs?: Maybe<ReservationsActiveServicesResponse>;
  reservationsAndCommitmentUsageCoverage?: Maybe<ReservationsAndCommitmentsUsageChartCoverage>;
  reservationsAndCommitmentsSavings?: Maybe<ReservationsAndCommitmentsSavingsData>;
  /** `reservationsAndMetadata` returns the reserved instances list with its metadata */
  reservationsAndMetadata?: Maybe<ReservationsPaginatedItem>;
  /** `reservationsBenefitsEstateRecords` returns the benefit details for the given reservations Id */
  reservationsBenefitsEstateRecords?: Maybe<ReservationsPaginatedItem>;
  /** `reservationsFilters` returns Reservation and Commitment Recommendation list of present resources, regions and platforms for a customer in coverage. */
  reservationsFilters?: Maybe<ReservationsFiltersResponse>;
  reservationsRecommendationsSummary: ReservationsRecommendationsSummary;
  /** `resourceConsumption` returns lists for resources (cpu & memory) consumed by Kubernetes */
  resourceConsumption?: Maybe<ResourceConsumptionResponse>;
  resourceCostSplitRules: ResourceCostSplitRulesResponse;
  /** `resourceTypes` returns Cloud Estate resource types optionally filtered by providers or category ids */
  resourceTypes: Array<Scalars["String"]>;
  search?: Maybe<SearchResponse>;
  /** Get the status of a search job and search(executed asynchronously) results, when job is COMPLETED. */
  searchJob?: Maybe<SearchJobStatusResponse>;
  /** @deprecated use `searchPresetsV2` query instead */
  searchPresets?: Maybe<SearchPresetsResponse>;
  /** Updated searchPresets query with support for new filters and sorting. */
  searchPresetsV2?: Maybe<SearchPresetsV2Response>;
  /**
   * Search through tag keys by providing key prefix with minimum length of two.
   * After is an optional cursor for pagination.
   */
  searchTagKeys?: Maybe<SearchTagKeysResponse>;
  /**
   * Search through tag values by providing exact key and value prefix with minimum length of two.
   * After is an optional cursor for pagination.
   */
  searchTagValues?: Maybe<SearchTagValuesResponse>;
  selectAllAppAndEnvIds?: Maybe<AppAndEnvIds>;
  /** Get services used by customer in period */
  services: Array<Service>;
  /** Resolve service names by ids */
  servicesByIds?: Maybe<Array<Maybe<Service>>>;
  /**
   * SKU is the resource used by particular service in GCP.
   * `skuCosts` returns list of SKUs with costs which belongs to particular service (based on nid)
   */
  skuCosts?: Maybe<SkuCostsResponse>;
  /** Get threshold by `id` */
  threshold?: Maybe<Threshold>;
  /** Get list of thresholds with `sorting` options */
  thresholds: Array<Threshold>;
  /** `topSpendingApplications` returns applications with the highest `currentMonth` costs */
  topSpendingApplications: TopSpendingApplicationsResponse;
  /** Check turbonomic connection */
  turbonomicConnectionStatus: ConnectionStatus;
  /** Get turbonomic plugin */
  turbonomicIntegrationPlugin?: Maybe<TurbonomicIntegrationPlugin>;
  turbonomicPluginConnectionStatus: ConnectionStatus;
  /** Get permissions of a user */
  userPermissions?: Maybe<UserPermissions>;
  /** List permissions for all users */
  usersPermissions?: Maybe<UsersPermissionsResponse>;
  /** `utilisationData` returns the utilization of the reserved instances and savings plans */
  utilisationData?: Maybe<UtilisationResponse>;
  /** List of AWS VPCs with their subnets */
  vpcs?: Maybe<VpcsResponse>;
  workflow?: Maybe<Workflow>;
  workflowHistoryEvent?: Maybe<WorkflowEvent>;
  workflowHistoryEvents?: Maybe<WorkflowHistoryListOutput>;
  workflows: Array<WorkflowListItem>;
};

/** Queries */
export type QueryAccessKeyPermissionsArgs = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryAccessKeysPermissionsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  searchByValue?: InputMaybe<Scalars["String"]>;
  sortByOrder?: InputMaybe<SortByOrder>;
  sortByValue?: InputMaybe<SortByValue>;
};

/** Queries */
export type QueryAccountArgs = {
  id: Scalars["String"];
};

/** Queries */
export type QueryAccountCostComparisonArgs = {
  accountProviderId: Scalars["String"];
  period: RelativePeriod;
};

/** Queries */
export type QueryAccountSpendsArgs = {
  accountProviderId: Scalars["String"];
};

/** Queries */
export type QueryAccountV2Args = {
  id: Scalars["String"];
};

/** Queries */
export type QueryAccountsV2Args = {
  filter?: InputMaybe<AccountsFilter>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryAccumulatedCostArgs = {
  input: AccumulatedCostInput;
};

/** Queries */
export type QueryAllReservationsRecommendationsArgs = {
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  payments: Array<ReservationsRecommendationPaymentOption>;
  platform?: InputMaybe<Scalars["String"]>;
  provider: Scalars["String"];
  region: Scalars["String"];
  resourceType: Scalars["String"];
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  terms: Array<ReservationsRecommendationTerm>;
};

/** Queries */
export type QueryAnomaliesArgs = {
  input: AnomaliesQueryInput;
};

/** Queries */
export type QueryAnomalyArgs = {
  applicationId: Scalars["String"];
  date: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryAnomalySubtypesArgs = {
  applicationId: Scalars["String"];
  date: Scalars["String"];
  type: Scalars["String"];
};

/** Queries */
export type QueryAnomalyV2Args = {
  applicationId: Scalars["String"];
  date: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryApplicationArgs = {
  id: Scalars["String"];
};

/** Queries */
export type QueryApplicationAccumulatedCostArgs = {
  input: ApplicationAccumulatedCostInput;
};

/** Queries */
export type QueryApplicationOtherCategoriesCostArgs = {
  input: ApplicationOtherCategoriesCostInput;
};

/** Queries */
export type QueryApplicationTopCategoriesCostArgs = {
  input: ApplicationTopCategoriesCostInput;
};

/** Queries */
export type QueryApplicationTopEnvironmentsCostArgs = {
  input: ApplicationTopEnvironmentsCostInput;
};

/** Queries */
export type QueryApplicationsByIdsArgs = {
  ids: Array<Scalars["String"]>;
};

/** Queries */
export type QueryApplicationsPaginatedArgs = {
  applicationName?: InputMaybe<Scalars["String"]>;
  estateRecordNid?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryBusinessContextArgs = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryBusinessContextsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryCategoriesByIdsArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
};

/** Queries */
export type QueryChargeTypesArgs = {
  provider: Scalars["String"];
};

/** Queries */
export type QueryChartJobArgs = {
  granularity?: InputMaybe<Granularity>;
  jobId: Scalars["ID"];
  top?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryChartJobTableArgs = {
  jobId: Scalars["ID"];
  limit: Scalars["Int"];
  order?: InputMaybe<ChartTableSort>;
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryCloudAccountCostsByChargeTypeArgs = {
  filter?: InputMaybe<CloudAccountsFilter>;
  query?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryCloudAccountNidsArgs = {
  filter?: InputMaybe<CloudAccountsFilter>;
  order?: InputMaybe<CloudAccountsSort>;
  query?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryCloudAccountSavingCostsArgs = {
  cloudProviderId: Scalars["String"];
};

/** Queries */
export type QueryCloudAccountsArgs = {
  filter?: InputMaybe<CloudAccountsFilter>;
  limit: Scalars["Int"];
  order?: InputMaybe<CloudAccountsSort>;
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryClusterResourceConsumptionArgs = {
  accountId: Scalars["String"];
  granularity?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryCustomerApplicationsCostArgs = {
  input: CustomerApplicationsCostInput;
};

/** Queries */
export type QueryCustomerCategoriesCostArgs = {
  input: CustomerCategorizedCostInput;
};

/** Queries */
export type QueryCustomerCorrectionCostArgs = {
  input: CustomerCorrectionCostInput;
};

/** Queries */
export type QueryCustomerServicesCostArgs = {
  input: CustomerServicesCostInput;
};

/** Queries */
export type QueryCustomerServicesCostV2Args = {
  input: CustomerServicesCostInput;
};

/** Queries */
export type QueryDiscoveryRulesArgs = {
  environmentId: Scalars["String"];
};

/** Queries */
export type QueryDownloadExportArgs = {
  exportJobId: Scalars["ID"];
};

/** Queries */
export type QueryDriftedResourcesArgs = {
  limit: Scalars["Int"];
  page: Scalars["Int"];
};

/** Queries */
export type QueryEnvironmentArgs = {
  id: Scalars["String"];
};

/** Queries */
export type QueryEnvironmentAccumulatedCostArgs = {
  input: EnvironmentAccumulatedCostInput;
};

/** Queries */
export type QueryEnvironmentOtherCategoriesCostArgs = {
  input: EnvironmentOtherCategoriesCostInput;
};

/** Queries */
export type QueryEnvironmentTopCategoriesCostArgs = {
  input: EnvironmentTopCategoriesCostInput;
};

/** Queries */
export type QueryEnvironmentsByIdsArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
};

/** Queries */
export type QueryEstateArgs = {
  filter?: InputMaybe<ResourceFilter>;
  limit: Scalars["Int"];
  page: Scalars["Int"];
  query?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryEstateAllocationDriftsArgs = {
  input: EstateAllocationDriftsInput;
};

/** Queries */
export type QueryEstateCountArgs = {
  filter?: InputMaybe<MultipleResourceFilter>;
};

/** Queries */
export type QueryEstateRecordArgs = {
  nid: Scalars["String"];
};

/** Queries */
export type QueryEstateResourceCountArgs = {
  filter?: InputMaybe<EstateResourceFilter>;
};

/** Queries */
export type QueryEstateResourceCountV2Args = {
  filter?: InputMaybe<EstateResourceFilterV2>;
};

/** Queries */
export type QueryEstateV2Args = {
  filter?: InputMaybe<EstateV2Filter>;
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SearchSort>;
};

/** Queries */
export type QueryEventsArgs = {
  from: Scalars["AWSDateTime"];
  nid: Scalars["String"];
  to: Scalars["AWSDateTime"];
};

/** Queries */
export type QueryExternalIntegrationDetailsArgs = {
  integrationId: Scalars["String"];
};

/** Queries */
export type QueryGcpProjectsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryGetReservationsAndCommitmentsRecommendationsArgs = {
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  paymentOption: ReservationsRecommendationPaymentOption;
  platform?: InputMaybe<Scalars["String"]>;
  provider: Scalars["String"];
  region: Scalars["String"];
  resourceType: Scalars["String"];
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  term: ReservationsRecommendationTerm;
};

/** Queries */
export type QueryHistoryEventsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  field?: InputMaybe<WorkflowEventInListSortingFields>;
  limit: Scalars["Int"];
  order?: InputMaybe<WorkflowSortingOrder>;
};

/** Queries */
export type QueryIntegrationPluginsArgs = {
  filter?: InputMaybe<IntegrationPluginsInput>;
};

/** Queries */
export type QueryListReservationCoveredInstancesArgs = {
  limit: Scalars["Int"];
  page: Scalars["Int"];
  reservationId: Scalars["String"];
};

/** Queries */
export type QueryListReservationsAndCommitmentsCoverageArgs = {
  end?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ReservationsAndCommitmentsFilters>;
  isGrouped?: InputMaybe<Scalars["Boolean"]>;
  limit: Scalars["Int"];
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  page: Scalars["Int"];
  paymentOption?: InputMaybe<ReservationsRecommendationPaymentOption>;
  provider?: InputMaybe<ReservationsProvider>;
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
  start?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<ReservationsRecommendationTerm>;
};

/** Queries */
export type QueryListReservationsAndCommitmentsCoverageDetailsArgs = {
  end?: InputMaybe<Scalars["String"]>;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  platform: Scalars["String"];
  provider?: InputMaybe<ReservationsProvider>;
  region: Scalars["String"];
  resourceType: Scalars["String"];
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
  start?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryListTagKeysArgs = {
  endDate: Scalars["String"];
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  startDate: Scalars["String"];
};

/** Queries */
export type QueryMappingRulesArgs = {
  environmentId: Scalars["String"];
};

/** Queries */
export type QueryMonthlyReportArgs = {
  input: MonthlyReportInput;
};

/** Queries */
export type QueryNotificationsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<NotificationsFilter>;
  limit: Scalars["Int"];
};

/** Queries */
export type QueryOrgUnitAccumulatedCostArgs = {
  input: OrgUnitAccumulatedCostInput;
};

/** Queries */
export type QueryOrgUnitApplicationsArgs = {
  limit: Scalars["Int"];
  orgUnitId: Scalars["ID"];
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryOrgUnitOtherCategoriesCostArgs = {
  input: OrgUnitOtherCategoriesCostInput;
};

/** Queries */
export type QueryOrgUnitTopApplicationsCostArgs = {
  input: OrgUnitTopApplicationsCostInput;
};

/** Queries */
export type QueryOrgUnitTopCategoriesCostArgs = {
  input: OrgUnitTopCategoriesCostInput;
};

/** Queries */
export type QueryOrgUnitTypeV2Args = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryOrgUnitTypesV2Args = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryOrgUnitV2Args = {
  nid: Scalars["ID"];
};

/** Queries */
export type QueryOrgUnitWithChildrenArgs = {
  nid: Scalars["ID"];
};

/** Queries */
export type QueryOrgUnitsV2Args = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QueryPersonArgs = {
  id: Scalars["String"];
};

/** Queries */
export type QueryReservableResourcesArgs = {
  environmentId: Scalars["String"];
  limit: Scalars["Int"];
  page: Scalars["Int"];
};

/** Queries */
export type QueryReservationDetailsArgs = {
  end?: InputMaybe<Scalars["String"]>;
  reservationId: Scalars["String"];
  start?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryReservationUtilizationArgs = {
  reservationId: Scalars["String"];
  timePeriod: ReservationUtilizationTimePeriod;
};

/** Queries */
export type QueryReservationUtilizationV2Args = {
  end: Scalars["String"];
  granularity?: InputMaybe<Scalars["String"]>;
  nid: Scalars["String"];
  reservationId?: InputMaybe<Scalars["String"]>;
  start: Scalars["String"];
};

/** Queries */
export type QueryReservationsActiveTabsArgs = {
  lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod;
  paymentOption: ReservationsRecommendationPaymentOption;
  provider: Scalars["String"];
  term: ReservationsRecommendationTerm;
};

/** Queries */
export type QueryReservationsAndCommitmentUsageCoverageArgs = {
  end?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<ReservationsAndCommitmentsFilters>;
  granularity?: InputMaybe<GranularityUsageType>;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  provider?: InputMaybe<ReservationsProvider>;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  start?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryReservationsAndCommitmentsSavingsArgs = {
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  paymentOption?: InputMaybe<ReservationsRecommendationPaymentOption>;
  term?: InputMaybe<ReservationsRecommendationTerm>;
};

/** Queries */
export type QueryReservationsAndMetadataArgs = {
  filters?: InputMaybe<ReservationAndMetadataFilters>;
  limit?: InputMaybe<Scalars["Int"]>;
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  onlyCount?: InputMaybe<Scalars["Boolean"]>;
  page?: InputMaybe<Scalars["Int"]>;
  provider?: InputMaybe<ReservationsProvider>;
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
};

/** Queries */
export type QueryReservationsBenefitsEstateRecordsArgs = {
  instanceId: Scalars["String"];
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  provider: Scalars["String"];
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
};

/** Queries */
export type QueryReservationsFiltersArgs = {
  instanceFamily?: InputMaybe<Scalars["String"]>;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  platform?: InputMaybe<Scalars["String"]>;
  provider: ReservationsProvider;
  region?: InputMaybe<Scalars["String"]>;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
};

/** Queries */
export type QueryReservationsRecommendationsSummaryArgs = {
  filters?: InputMaybe<ReservationsAndCommitmentsFilters>;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  paymentOption: ReservationsRecommendationPaymentOption;
  provider?: InputMaybe<ReservationsProvider>;
  serviceType?: InputMaybe<ReservationsRecommendationServiceType>;
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
  term: ReservationsRecommendationTerm;
};

/** Queries */
export type QueryResourceConsumptionArgs = {
  granularity?: InputMaybe<Scalars["String"]>;
  nid: Scalars["String"];
  type: KubernetesType;
};

/** Queries */
export type QueryResourceCostSplitRulesArgs = {
  limit: Scalars["Int"];
  nid: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  provider?: InputMaybe<Provider>;
};

/** Queries */
export type QueryResourceTypesArgs = {
  categoryIDs?: InputMaybe<Array<Scalars["ID"]>>;
  provider?: InputMaybe<Array<Provider>>;
};

/** Queries */
export type QuerySearchArgs = {
  filter?: InputMaybe<SearchFilter>;
  limit: Scalars["Int"];
  page: Scalars["Int"];
  phrase: Scalars["String"];
};

/** Queries */
export type QuerySearchJobArgs = {
  jobId: Scalars["ID"];
};

/** Queries */
export type QuerySearchPresetsArgs = {
  limit: Scalars["Int"];
  nameQuery?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QuerySearchPresetsV2Args = {
  limit: Scalars["Int"];
  nameQuery?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
};

/** Queries */
export type QuerySearchTagKeysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  keyPrefix: Scalars["String"];
  limit: Scalars["Int"];
};

/** Queries */
export type QuerySearchTagValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  key: Scalars["String"];
  limit: Scalars["Int"];
  valuePrefix: Scalars["String"];
};

/** Queries */
export type QuerySelectAllAppAndEnvIdsArgs = {
  applicationName?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryServicesByIdsArgs = {
  ids: Array<Scalars["String"]>;
};

/** Queries */
export type QuerySkuCostsArgs = {
  nid: Scalars["String"];
  providers?: InputMaybe<Array<InputMaybe<Provider>>>;
};

/** Queries */
export type QueryThresholdArgs = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryThresholdsArgs = {
  sorting?: InputMaybe<ThresholdSortInput>;
};

/** Queries */
export type QueryTopSpendingApplicationsArgs = {
  limit: Scalars["Int"];
};

/** Queries */
export type QueryTurbonomicConnectionStatusArgs = {
  connection: TurbonomicConnectionInput;
};

/** Queries */
export type QueryTurbonomicIntegrationPluginArgs = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryTurbonomicPluginConnectionStatusArgs = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryUserPermissionsArgs = {
  email: Scalars["String"];
};

/** Queries */
export type QueryUsersPermissionsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  searchByValue?: InputMaybe<Scalars["String"]>;
  sortByOrder?: InputMaybe<SortByOrder>;
  sortByValue?: InputMaybe<SortByValue>;
};

/** Queries */
export type QueryUtilisationDataArgs = {
  endPeriod: Scalars["String"];
  granularity: Scalars["String"];
  id: Scalars["String"];
  startPeriod: Scalars["String"];
};

/** Queries */
export type QueryVpcsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryWorkflowArgs = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryWorkflowHistoryEventArgs = {
  id: Scalars["ID"];
};

/** Queries */
export type QueryWorkflowHistoryEventsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
  status?: InputMaybe<WorkflowEventStatus>;
  workflowId?: InputMaybe<Scalars["String"]>;
};

/** Queries */
export type QueryWorkflowsArgs = {
  field?: InputMaybe<WorkflowSortingFields>;
  order?: InputMaybe<WorkflowSortingOrder>;
};

export enum Role {
  Admin = "ADMIN",
  AdminLite = "ADMIN_LITE",
  MspDashboard = "MSP_DASHBOARD",
  ReadOnly = "READ_ONLY",
  TeamLead = "TEAM_LEAD",
}

export type Recalculation = {
  __typename?: "Recalculation";
  status: RecalculationStatus;
};

export enum RecalculationStatus {
  Running = "RUNNING",
}

export type RecalculationTime = {
  __typename?: "RecalculationTime";
  /** This field can return empty string if no recalculation has finished successfully for period. */
  finishedAt: Scalars["String"];
};

export enum RecommendationState {
  Active = "ACTIVE",
  Dismissed = "DISMISSED",
}

export type RecommendationTimeSeriesResponse = {
  __typename?: "RecommendationTimeSeriesResponse";
  label?: Maybe<Scalars["String"]>;
  onDemandCost?: Maybe<Scalars["String"]>;
  riCost?: Maybe<Scalars["String"]>;
};

export enum RelativePeriod {
  Current = "CURRENT",
  Previous = "PREVIOUS",
}

export type RemoveSearchPresetResponse = {
  __typename?: "RemoveSearchPresetResponse";
  preset: SearchPreset;
};

export type RemoveSearchPresetV2Response = {
  __typename?: "RemoveSearchPresetV2Response";
  preset: SearchPresetV2;
};

export enum ReportStatus {
  Error = "ERROR",
  Finished = "FINISHED",
  InProgress = "IN_PROGRESS",
  Scheduled = "SCHEDULED",
}

export enum ReportType {
  MonthlyReportBc = "MONTHLY_REPORT_BC",
  /** @deprecated use MONTHLY_REPORT_BC report instead */
  MonthlyReportBcWithoutMetadata = "MONTHLY_REPORT_BC_WITHOUT_METADATA",
  MonthlyReportFull = "MONTHLY_REPORT_FULL",
  /** @deprecated use MONTHLY_REPORT_FULL report instead */
  MonthlyReportFullWithoutMetadata = "MONTHLY_REPORT_FULL_WITHOUT_METADATA",
  MonthlyReportStripped = "MONTHLY_REPORT_STRIPPED",
  /** @deprecated use MONTHLY_REPORT_STRIPPED report instead */
  MonthlyReportStrippedWithoutMetadata = "MONTHLY_REPORT_STRIPPED_WITHOUT_METADATA",
}

export type RequestEstateV2ExportInput = {
  /**
   * extendedRecordData can be used to customize the content of CSV report.
   * By default it contains following properties:   COST, CO2E, SAVINGS_AMOUNT, APPLICATION, ENVIRONMENT, TAGS, METADATA
   * To generate report with no additional columns set it to []
   */
  extendedRecordData?: InputMaybe<Array<EstateV2ExportExtendedColumn>>;
  /** The same filters used to query estateV2 */
  filters?: InputMaybe<EstateV2ExportFilters>;
  /** Email address that will be used to notify about export completion */
  notificationEmail: Scalars["String"];
  /**
   * Url of the frontend view for which the export has been requested
   * it should include all the filters
   */
  viewUrl: Scalars["String"];
};

export type RequestEstateV2ExportResponse = {
  __typename?: "RequestEstateV2ExportResponse";
  exportJobId: Scalars["ID"];
};

export type RequestEstateV3ExportInput = {
  /**
   * extendedRecordData can be used to customize the content of CSV report.
   * By default it contains following properties:   COST, CO2E, SAVINGS_AMOUNT, APPLICATION, ENVIRONMENT, TAGS, METADATA
   * To generate report with no additional columns set it to []
   */
  extendedRecordData?: InputMaybe<Array<EstateV3ExportExtendedColumn>>;
  /** The same filters used to submitSearchJobV2 */
  filters: EstateV3ExportFilters;
  /** Email address that will be used to notify about export completion */
  notificationEmail: Scalars["String"];
  /**
   * Url of the frontend view for which the export has been requested
   * it should include all the filters
   */
  viewUrl: Scalars["String"];
};

export type RequestEstateV3ExportResponse = {
  __typename?: "RequestEstateV3ExportResponse";
  exportJobId: Scalars["ID"];
};

export type RequestExportResponse = {
  __typename?: "RequestExportResponse";
  exportJobId: Scalars["ID"];
};

/** Input type for grouped records export */
export type RequestGroupedRecordsExportInput = {
  /** An ID of the search job that will be used to generate the export */
  chartJobId: Scalars["ID"];
  /** End date filter */
  endDate: Scalars["String"];
  /** Email address that will be used to notify about export completion */
  notificationEmail: Scalars["String"];
  /** Query by which results were filtered */
  query?: InputMaybe<Scalars["String"]>;
  /** Sort option used to order results */
  sort?: InputMaybe<ChartTableSort>;
  /** Start date filter */
  startDate: Scalars["String"];
  /**
   * Url of the frontend view for which the export has been requested
   * it should include all the filters
   */
  viewUrl: Scalars["String"];
};

/** Type for grouped records export response */
export type RequestGroupedRecordsExportResponse = {
  __typename?: "RequestGroupedRecordsExportResponse";
  exportJobId: Scalars["ID"];
};

export type Reservation = {
  __typename?: "Reservation";
  billingPlan?: Maybe<Scalars["String"]>;
  cloudAccount?: Maybe<Scalars["String"]>;
  dailyUtilization?: Maybe<ReservationUtilization>;
  from: Scalars["AWSDateTime"];
  instanceType?: Maybe<Scalars["String"]>;
  monthlyCost?: Maybe<Scalars["String"]>;
  monthlyUtilization?: Maybe<ReservationUtilization>;
  paymentOption?: Maybe<Scalars["String"]>;
  productDescription?: Maybe<Scalars["String"]>;
  provider?: Maybe<Provider>;
  purchaseOption?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  reservationId: Scalars["String"];
  skuName?: Maybe<Scalars["String"]>;
  to: Scalars["AWSDateTime"];
  upfrontCost?: Maybe<Scalars["String"]>;
  yearlyUtilization?: Maybe<ReservationUtilization>;
};

export type ReservationAndMetadataFilters = {
  paymentOption?: InputMaybe<ReservationsRecommendationPaymentOption>;
  platform?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  reservationNid?: InputMaybe<Scalars["String"]>;
  resource?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<ReservationsRecommendationTerm>;
};

export type ReservationAndMetadataItem = {
  __typename?: "ReservationAndMetadataItem";
  billingPlan?: Maybe<Scalars["String"]>;
  cloudAccount?: Maybe<Scalars["String"]>;
  commitment?: Maybe<Scalars["String"]>;
  ec2InstanceFamily?: Maybe<Scalars["String"]>;
  expirationTimeInDays?: Maybe<Scalars["Int"]>;
  expirationTimeInYears?: Maybe<Scalars["String"]>;
  from: Scalars["AWSDateTime"];
  instanceType?: Maybe<Scalars["String"]>;
  monthlyCost?: Maybe<Scalars["String"]>;
  offeringClass?: Maybe<Scalars["String"]>;
  originalQuantity?: Maybe<Scalars["Int"]>;
  paymentOption?: Maybe<Scalars["String"]>;
  productDescription?: Maybe<Scalars["String"]>;
  provider?: Maybe<Provider>;
  purchaseOption?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  reservationId: Scalars["String"];
  savingsPlanType?: Maybe<Scalars["String"]>;
  skuName?: Maybe<Scalars["String"]>;
  to: Scalars["AWSDateTime"];
  unitsCount?: Maybe<Scalars["Int"]>;
  upfrontCost?: Maybe<Scalars["String"]>;
  yearlyUtilization?: Maybe<UtilisationResponse>;
};

export type ReservationCommitmentsActiveServices = {
  __typename?: "ReservationCommitmentsActiveServices";
  isActive: Scalars["Boolean"];
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
};

export type ReservationCoveredAndCommitmentType = {
  __typename?: "ReservationCoveredAndCommitmentType";
  averageCoverage?: Maybe<Scalars["String"]>;
  coverageSavedCost?: Maybe<Scalars["String"]>;
  groupByParameter?: Maybe<Scalars["String"]>;
  hasCoverageResourceList?: Maybe<Scalars["Boolean"]>;
  hasRecommendations?: Maybe<Scalars["Boolean"]>;
  measurementUnit?: Maybe<Scalars["String"]>;
  offeringType?: Maybe<Scalars["String"]>;
  onDemandCostPercent?: Maybe<Scalars["String"]>;
  platform?: Maybe<Scalars["String"]>;
  potentialSavings?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  resource?: Maybe<Scalars["String"]>;
  resourceTypesCount?: Maybe<Scalars["Int"]>;
  riCostPercent?: Maybe<Scalars["String"]>;
  spCostPercent?: Maybe<Scalars["String"]>;
  totalCoveredHours?: Maybe<Scalars["String"]>;
  totalOnDemandCost?: Maybe<Scalars["String"]>;
  totalOnDemandHours?: Maybe<Scalars["String"]>;
};

export type ReservationCoveredInstance = {
  __typename?: "ReservationCoveredInstance";
  account?: Maybe<Scalars["String"]>;
  benefit?: Maybe<Scalars["String"]>;
  environment?: Maybe<Environment>;
  id?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nid: Scalars["String"];
  provider?: Maybe<Provider>;
  type?: Maybe<Scalars["String"]>;
};

export type ReservationCoveredInstancesPaginated = {
  __typename?: "ReservationCoveredInstancesPaginated";
  count?: Maybe<Scalars["Int"]>;
  instances?: Maybe<Array<ReservationCoveredInstance>>;
  pages?: Maybe<Scalars["Int"]>;
};

export type ReservationUsageChartTimeSeries = {
  __typename?: "ReservationUsageChartTimeSeries";
  date?: Maybe<Scalars["String"]>;
  onDemandCost?: Maybe<Scalars["String"]>;
  riCost?: Maybe<Scalars["String"]>;
  /** @deprecated this field was never used */
  riCoveredHours?: Maybe<Scalars["String"]>;
  spCost?: Maybe<Scalars["String"]>;
  /** @deprecated this field was never used */
  spCoveredHours?: Maybe<Scalars["String"]>;
  /** `totalCost` returns total ondemand cost */
  totalCost?: Maybe<Scalars["String"]>;
  /** @deprecated this field was never used */
  usedHours?: Maybe<Scalars["String"]>;
};

export type ReservationUtilization = {
  __typename?: "ReservationUtilization";
  benefit: Scalars["String"];
  forecast?: Maybe<Scalars["String"]>;
  reservationIDs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  timeSeries?: Maybe<Array<Maybe<TimeSeries>>>;
  utilization: Scalars["String"];
};

export type ReservationUtilizationDetail = {
  __typename?: "ReservationUtilizationDetail";
  monthlyUtilization?: Maybe<ReservationUtilization>;
  reservationId: Scalars["String"];
};

export enum ReservationUtilizationTimePeriod {
  OneMonth = "ONE_MONTH",
  ThreeMonths = "THREE_MONTHS",
  TwelveMonths = "TWELVE_MONTHS",
}

export type ReservationsActiveServicesResponse = {
  __typename?: "ReservationsActiveServicesResponse";
  provider?: Maybe<Scalars["String"]>;
  tabs?: Maybe<Array<ReservationCommitmentsActiveServices>>;
};

/** ReservationsAndCommitmentsCoverageOfferingType used to get Reserved Instances or Savings Plans or both */
export enum ReservationsAndCommitmentsCoverageOfferingType {
  All = "ALL",
  ReservedInstances = "RESERVED_INSTANCES",
  SavingsPlans = "SAVINGS_PLANS",
}

export type ReservationsAndCommitmentsCoveragePaginated = {
  __typename?: "ReservationsAndCommitmentsCoveragePaginated";
  count?: Maybe<Scalars["Int"]>;
  /** `groupedResources` returns the resources based on the platform(e.g. `LINUX` or `WINDOWS` or `AWS LAMBDA` or `RDS - Aurora MySQL` or `Elasticache`) in which it is running on. */
  groupedResources?: Maybe<Array<Maybe<GroupedResources>>>;
  pages?: Maybe<Scalars["Int"]>;
  resources?: Maybe<Array<ReservationCoveredAndCommitmentType>>;
};

/** Reservations And Commitments Coverage ServiceUsageType describes the services provided by cloud provider */
export enum ReservationsAndCommitmentsCoverageServiceUsageType {
  Analytics = "ANALYTICS",
  Compute = "COMPUTE",
  Database = "DATABASE",
  MachineLearning = "MACHINE_LEARNING",
  Storage = "STORAGE",
}

export type ReservationsAndCommitmentsFilters = {
  instanceFamily?: InputMaybe<Array<Scalars["String"]>>;
  platform?: InputMaybe<Array<Scalars["String"]>>;
  region?: InputMaybe<Array<Scalars["String"]>>;
};

/** Lookback period available and takes input as THIRTY_DAYS SIXTY_DAYS */
export enum ReservationsAndCommitmentsLookbackPeriod {
  SixtyDays = "SIXTY_DAYS",
  ThirtyDays = "THIRTY_DAYS",
}

export type ReservationsAndCommitmentsRecommendationsResponse = {
  __typename?: "ReservationsAndCommitmentsRecommendationsResponse";
  breakEvenPointXAxis?: Maybe<Scalars["String"]>;
  breakEvenPointYAxis?: Maybe<Scalars["String"]>;
  expectedUtilization?: Maybe<Scalars["String"]>;
  instanceType?: Maybe<Scalars["String"]>;
  monthlySavings?: Maybe<Scalars["String"]>;
  monthlySavingsPercentage?: Maybe<Scalars["String"]>;
  numberOfInstances?: Maybe<Scalars["Int"]>;
  recurringMonthlyCost?: Maybe<Scalars["String"]>;
  startSavings?: Maybe<Scalars["String"]>;
  timeSeries?: Maybe<Array<RecommendationTimeSeriesResponse>>;
  upfrontCost?: Maybe<Scalars["String"]>;
  yearlySavings?: Maybe<Scalars["String"]>;
};

export type ReservationsAndCommitmentsSavingsData = {
  __typename?: "ReservationsAndCommitmentsSavingsData";
  totalMonthlySavings?: Maybe<Scalars["String"]>;
  totalYearlySavings?: Maybe<Scalars["String"]>;
};

export type ReservationsAndCommitmentsUsageChartCoverage = {
  __typename?: "ReservationsAndCommitmentsUsageChartCoverage";
  averageCoverage?: Maybe<Scalars["String"]>;
  riAverageCoverage?: Maybe<Scalars["String"]>;
  savedCost?: Maybe<Scalars["String"]>;
  spAverageCoverage?: Maybe<Scalars["String"]>;
  timeSeries?: Maybe<Array<Maybe<ReservationUsageChartTimeSeries>>>;
  totalOnDemandCost?: Maybe<Scalars["String"]>;
};

export type ReservationsFiltersResponse = {
  __typename?: "ReservationsFiltersResponse";
  platformsList?: Maybe<Array<Maybe<Scalars["String"]>>>;
  regionsList?: Maybe<Array<Maybe<Scalars["String"]>>>;
  resourcesList?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ReservationsPaginatedItem = {
  __typename?: "ReservationsPaginatedItem";
  count?: Maybe<Scalars["Int"]>;
  filters?: Maybe<ActiveReservationsFilters>;
  pages?: Maybe<Scalars["Int"]>;
  reservations?: Maybe<Array<ReservationAndMetadataItem>>;
};

export enum ReservationsProvider {
  Aws = "AWS",
  Azure = "AZURE",
}

/** ReservationsRecommendationPaymentOption represents payment options for reserved-instances and savings-plan */
export enum ReservationsRecommendationPaymentOption {
  AllUpfront = "ALL_UPFRONT",
  Monthly = "MONTHLY",
  NoUpfront = "NO_UPFRONT",
  PartialUpfront = "PARTIAL_UPFRONT",
}

/** Reservations Recommendation Service Type denominates the field by which recommendations data will be filtered. */
export enum ReservationsRecommendationServiceType {
  ElasticComputeCloud = "ELASTIC_COMPUTE_CLOUD",
  ElasticSearchService = "ELASTIC_SEARCH_SERVICE",
  ElastiCache = "ELASTI_CACHE",
  Redshift = "REDSHIFT",
  RelationalDatabaseService = "RELATIONAL_DATABASE_SERVICE",
  ShowAll = "SHOW_ALL",
}

/** ReservationsRecommendationTerm represents the purchase term for Reserved-Instances or Savings-Plan */
export enum ReservationsRecommendationTerm {
  OneYear = "ONE_YEAR",
  ThreeYears = "THREE_YEARS",
}

export type ReservationsRecommendationsSummary = {
  __typename?: "ReservationsRecommendationsSummary";
  count?: Maybe<Scalars["Int"]>;
  monthlySavings: Scalars["String"];
  /** `onDemandCostPerHour` returns ondemand cost on hourly basis */
  onDemandCostPerHour?: Maybe<Scalars["String"]>;
  recurringMonthlyCost: Scalars["String"];
  /** `savingsPerHour` returns savings rate per hour */
  savingsPerHour?: Maybe<Scalars["String"]>;
  savingsPlansCommitments?: Maybe<Array<Maybe<SavingsPlansCommitment>>>;
  /** `savingsPlansHourlyCommitmentToPurchase` returns savings plan commitment to purchase */
  savingsPlansHourlyCommitmentToPurchase?: Maybe<Scalars["String"]>;
  savingsRate: Scalars["String"];
  upfrontCost: Scalars["String"];
  yearlySavings: Scalars["String"];
};

export type ResolveAllDriftsInput = {
  /** Additional filters allowing for listing drifts limited by source/target environment of the pending assignment. */
  filters?: InputMaybe<DriftsFilters>;
  /** Status decides wether the drifts will be accepted or rejected. */
  status: DriftResolveStatus;
};

export type ResolveDriftsInput = {
  /** A list of NIDs of estate records for which the drifts will be updated, according to status. */
  nids: Array<Scalars["ID"]>;
  /**
   * responseParameters is an additional input, which regulates which resources are returned from the Mutation. If this
   * field is empty, the response will be as if allocation drifts were queried with no filters and default parameters.
   */
  responseParameters?: InputMaybe<EstateAllocationDriftsInput>;
  /** Status decides wether the drifts will be accepted or rejected. */
  status: DriftResolveStatus;
};

export type ResolveDriftsResponse = {
  __typename?: "ResolveDriftsResponse";
  success?: Maybe<Scalars["Boolean"]>;
};

/** Types for estate resources */
export type Resource = CloudResource | CostResource;

export type ResourceConsumptionResponse = {
  __typename?: "ResourceConsumptionResponse";
  cpu?: Maybe<Array<ConsumedResource>>;
  memory?: Maybe<Array<ConsumedResource>>;
  nid?: Maybe<Scalars["String"]>;
  nodeNid?: Maybe<Scalars["String"]>;
  storageRw?: Maybe<Array<StorageReadWriteResource>>;
};

export type ResourceCostSplitRule = {
  __typename?: "ResourceCostSplitRule";
  algorithm: CostMappingAlgorithm;
  id?: Maybe<Scalars["ID"]>;
  provider: Provider;
  resourceNid: Scalars["String"];
  settings: CostSplitRuleSettings;
};

export type ResourceCostSplitRuleInput = {
  algorithm: CostMappingAlgorithm;
  id?: InputMaybe<Scalars["ID"]>;
  nid: Scalars["String"];
  provider: Provider;
  settings?: InputMaybe<ResourceCostSplitSettings>;
};

export type ResourceCostSplitRuleUpdateInput = {
  algorithm: CostMappingAlgorithm;
  id: Scalars["ID"];
  nid: Scalars["String"];
  provider: Provider;
  settings?: InputMaybe<ResourceCostSplitSettings>;
};

export type ResourceCostSplitRulesResponse = {
  __typename?: "ResourceCostSplitRulesResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  result: Array<ResourceCostSplitRule>;
};

export type ResourceCostSplitSettings = {
  allEnvironments?: InputMaybe<Scalars["Boolean"]>;
  environments?: InputMaybe<Array<EnvCostSplitInput>>;
};

export type ResourceFilter = {
  /** Return all resources belonging to an account */
  account?: InputMaybe<Scalars["String"]>;
  /** Return all resources assigned to environments that belong to given application */
  application?: InputMaybe<Scalars["String"]>;
  /** Return all resources that have an active/unresolved drift assignment */
  drifted?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources assigned to given environment */
  environment?: InputMaybe<Scalars["String"]>;
  /** Return all resources from a particular provider */
  provider?: InputMaybe<Provider>;
  /** Return all resources from a given region */
  region?: InputMaybe<Scalars["String"]>;
  /** Return only resources with specified type */
  type?: InputMaybe<Scalars["String"]>;
  /**
   * The filters for `unassigned`, `environment`, `application` are exclusive,
   * and they are resolved in order unassigned > environment > application
   * so if all are specified, only one is going to work along with that ordering
   *
   *
   * Return all resources assigned to application `default` or environment `default`
   */
  unassigned?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources which are marked as cloud waste */
  waste?: InputMaybe<Scalars["Boolean"]>;
};

export type ResourceProps = {
  nid: Scalars["String"];
  provider: Provider;
};

export type ResourcePropsType = {
  __typename?: "ResourcePropsType";
  nid: Scalars["String"];
  provider: Provider;
  ruleId: Scalars["String"];
};

export type ResourceResponse = {
  __typename?: "ResourceResponse";
  cost?: Maybe<ResourcesCost>;
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  resources?: Maybe<Array<Resource>>;
  savingsAmount?: Maybe<ResourceSavingsAmount>;
};

/** ResourceSavingsAmount represents a savings amount for a set of resources. */
export type ResourceSavingsAmount = {
  __typename?: "ResourceSavingsAmount";
  /** A sum of all savings amount for resources in the current period. */
  total?: Maybe<Scalars["String"]>;
};

/** ResourcesCost represents a cost for a set of resources. */
export type ResourcesCost = {
  __typename?: "ResourcesCost";
  /** A sum of all costs for resources in the current period. */
  currentMonthTotal: Scalars["String"];
  /** A sum of all forecast costs for resources in the current period. */
  monthlyForecast?: Maybe<Scalars["String"]>;
};

export enum RuleOperator {
  And = "AND",
  Or = "OR",
}

export type SkuCost = {
  __typename?: "SKUCost";
  endDate?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SkuCostsResponse = {
  __typename?: "SKUCostsResponse";
  currentMonth?: Maybe<Array<Maybe<SkuCost>>>;
  previousMonth?: Maybe<Array<Maybe<SkuCost>>>;
};

export type SavingSuggestionsOptimisation = {
  __typename?: "SavingSuggestionsOptimisation";
  savingSuggestionsUtilised: Scalars["Boolean"];
  savingSuggestionsValue: Scalars["String"];
};

export type SavingsAmount = {
  __typename?: "SavingsAmount";
  amount: Scalars["String"];
  /** `co2e` is the carbon emission savings amount of this resource */
  co2e: Scalars["String"];
  count: Scalars["Int"];
};

export type SavingsPlansCommitment = {
  __typename?: "SavingsPlansCommitment";
  account?: Maybe<Scalars["String"]>;
  onDemandCostPerHour?: Maybe<Scalars["String"]>;
  savingsPerHour?: Maybe<Scalars["String"]>;
  savingsPlansHourlyCommitmentToPurchase?: Maybe<Scalars["String"]>;
};

export type SearchFilter = {
  /**
   * The provider ID of the CloudAccount, which is the `providerId` field in the Account type,
   * e.g. `123123123123` for AWS accounts or `272a852e-3468-4372-b4fa-6ae68bce3bff` for AZURE subscriptions
   */
  account?: InputMaybe<Array<Scalars["String"]>>;
  /** The CustomerDB ID of the application, which is the `id` field in the Application type */
  application?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * `category` is the type of the resource, meaning `ec2` or `lambda`, etc.
   * this only works for cloud resources
   */
  category?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * the `environment` and `application` fields are exclusive, and they are resolved
   * in order: `environment` > `application`, so if both are specified, only the first
   * is going to have impact on the results
   *
   * The NID of the environment, which is the `nid` field in the Environment type
   */
  environment?: InputMaybe<Array<Scalars["String"]>>;
  /** `provider` is straightforward */
  provider?: InputMaybe<Array<Provider>>;
  /** `region` is straightforward, e.g. `eu-west-1` */
  region?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * `type` is the field that separates different entities in search, while category should
   * only be used to discriminate between different types of cloud resources
   * if no `type` is provided, search will be performed on all entities
   *
   * Disclaimer: those filters don't work when the query phrase is an empty string, since
   * there is a huge cutoff that allows to optimise the search
   */
  type?: InputMaybe<SearchType>;
  /** Filter records to the ones which are marked as cloud waste */
  waste?: InputMaybe<Scalars["Boolean"]>;
};

export enum SearchJobStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  Processing = "PROCESSING",
  Submitted = "SUBMITTED",
}

export type SearchJobStatusResponse = {
  __typename?: "SearchJobStatusResponse";
  data?: Maybe<ResourceResponse>;
  status: SearchJobStatus;
};

export type SearchPreset = {
  __typename?: "SearchPreset";
  createdAt: Scalars["String"];
  filter?: Maybe<SearchPresetFilter>;
  id: Scalars["String"];
  name: Scalars["String"];
  query?: Maybe<Scalars["String"]>;
  sort?: Maybe<SearchPresetSort>;
  updatedAt: Scalars["String"];
};

/** SearchPresetFilter is the same as EstateV2Filter input. */
export type SearchPresetFilter = {
  __typename?: "SearchPresetFilter";
  account?: Maybe<Array<Scalars["String"]>>;
  application?: Maybe<Array<Scalars["String"]>>;
  assignmentUpdatedFrom?: Maybe<Scalars["String"]>;
  assignmentUpdatedTo?: Maybe<Scalars["String"]>;
  category?: Maybe<Array<Scalars["String"]>>;
  co2eWaste?: Maybe<Scalars["Boolean"]>;
  costFrom?: Maybe<Scalars["UnsignedFloat"]>;
  costTo?: Maybe<Scalars["UnsignedFloat"]>;
  enrichment?: Maybe<Scalars["Boolean"]>;
  enrichmentIntegrationId?: Maybe<Array<Scalars["String"]>>;
  environment?: Maybe<Array<Scalars["String"]>>;
  externalIntegration?: Maybe<Array<Scalars["String"]>>;
  metadata?: Maybe<Array<KeyValues>>;
  provider?: Maybe<Array<Provider>>;
  region?: Maybe<Array<Scalars["String"]>>;
  resourceGroup?: Maybe<Array<Scalars["String"]>>;
  subtype?: Maybe<Array<Scalars["String"]>>;
  tags?: Maybe<Array<KeyValues>>;
  type?: Maybe<Array<Scalars["String"]>>;
  typeSubtype?: Maybe<Array<Scalars["String"]>>;
  unassigned?: Maybe<Scalars["Boolean"]>;
  waste?: Maybe<Scalars["Boolean"]>;
  wastePolicy?: Maybe<Array<Scalars["String"]>>;
};

/** SearchPresetFilterV2 is the same as SearchV2Filter input. */
export type SearchPresetFilterV2 = {
  __typename?: "SearchPresetFilterV2";
  /** Return all resources belonging to an account */
  account?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with an account specified in account filter */
  accountExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources assigned to environments that belong to given applications list */
  application?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with applications specified in application filter */
  applicationExclude?: Maybe<Scalars["Boolean"]>;
  /** Exclude resources with assignment updated values specified in assignmentUpdatedFrom/assignmentUpdatedTo filters */
  assignmentUpdateExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources that had assignment updated from */
  assignmentUpdatedFrom?: Maybe<Scalars["String"]>;
  /** Return all resources that had assignment updated to */
  assignmentUpdatedTo?: Maybe<Scalars["String"]>;
  /** Return all resources which which belong to given category */
  category?: Maybe<Array<Scalars["String"]>>;
  /** Excludes resources which which belong to given category in category filter */
  categoryExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources which are marked as co2e waste */
  co2eWaste?: Maybe<Scalars["Boolean"]>;
  /** Exclude resources with cost specified in costFrom/costTo filters */
  costExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources above given cost */
  costFrom?: Maybe<Scalars["UnsignedFloat"]>;
  /** Return all resources below given cost */
  costTo?: Maybe<Scalars["UnsignedFloat"]>;
  /** Return all resources which are enriched by external integration */
  enrichment?: Maybe<Scalars["Boolean"]>;
  /** Return all resources enriched by a specific external integration */
  enrichmentIntegrationId?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources enriched by a specific external integration specified in enrichmentIntegrationId filter */
  enrichmentIntegrationIdExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources assigned to given environments list */
  environment?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources assigned to given environments list specified in environment filter */
  environmentExclude?: Maybe<Scalars["Boolean"]>;
  /** Return resources not matching specified provider ID(s) */
  exceptProviderId?: Maybe<Array<Scalars["String"]>>;
  /** Return all resources created for a specific external integration */
  externalIntegration?: Maybe<Array<Scalars["String"]>>;
  /** Exclude all resources created for a specific external integration */
  externalIntegrationExclude?: Maybe<Scalars["Boolean"]>;
  /** Exclude resources with firstSeen values specified in firstSeenAtFrom/firstSeenAtTo filters */
  firstSeenAtExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources that have been first seen at no earlier than this timestamp */
  firstSeenAtFrom?: Maybe<Scalars["String"]>;
  /** Return all resources that have been first seen at no later than this timestamp */
  firstSeenAtTo?: Maybe<Scalars["String"]>;
  /** Excludes all resources with values from lastSeenAtFrom/lastSeenAtTo filter */
  lastSeenAtExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources that have been last seen at no earlier than this timestamp */
  lastSeenAtFrom?: Maybe<Scalars["String"]>;
  /** Return all resources that have been last seen at no later than this timestamp */
  lastSeenAtTo?: Maybe<Scalars["String"]>;
  /** Returns all resources excluding lifeSpanFrom and/or lifeSpanTo */
  lifeSpanExclude?: Maybe<Scalars["Boolean"]>;
  /** Returns all resources that has life span from this number of days */
  lifeSpanFrom?: Maybe<Scalars["Int"]>;
  /** Returns all resources that has life span to this number of days */
  lifeSpanTo?: Maybe<Scalars["Int"]>;
  /** Return all resources with the specific metadata key value pairs */
  metadata?: Maybe<Array<KeyValues>>;
  /** Excludes resources with the specific metadata key value pairs specified in metadata filter */
  metadataExclude?: Maybe<Scalars["Boolean"]>;
  /** Return only related resources for a resource specified in query */
  onlyRelated?: Maybe<Scalars["Boolean"]>;
  /** [GCP-specific] Return all resources that belong to any of the specified GCP projects */
  projectId?: Maybe<Array<Scalars["String"]>>;
  /** [GCP-specific] Exclude all resources that belong to any of the specified GCP projects */
  projectIdExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources from a particular provider */
  provider?: Maybe<Array<Provider>>;
  /** Exclude resources with providers specified in provider filter */
  providerExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources matching specified provider ID(s) */
  providerId?: Maybe<Array<Scalars["String"]>>;
  /** Return all resources from a given regions list */
  region?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with given regions specified in region filter */
  regionExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources with specified resourceGroup. This includes filtering by external integration */
  resourceGroup?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with resourceGroup specified in resource group filter. */
  resourceGroupExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources with any of the specified AWS Security Group ID(s) */
  securityGroup?: Maybe<Array<Scalars["String"]>>;
  /** Excludes resources with any of the specified AWS Security Group ID(s) in securityGroup filter */
  securityGroupExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources assigned to a service */
  service?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with a service specified in service filter */
  serviceExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources with specified subtype */
  subtype?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with subtype specified in subtype filter */
  subtypeExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources belonging to a specific tags */
  tags?: Maybe<Array<KeyValues>>;
  /** Exclude resources belonging to a specific tags specified in tags filter */
  tagsExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources with specified type */
  type?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with type specified in type filter */
  typeExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources with specified type/subtype combination */
  typeSubtype?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources with type/subtype combination specified in typeSubtype filter */
  typeSubtypeExclude?: Maybe<Scalars["Boolean"]>;
  /**
   * Return all resources assigned to application `default` and environment `default`.
   * `unassigned` can not be used with `application` or `environment` filters
   */
  unassigned?: Maybe<Scalars["Boolean"]>;
  /** Return all resources not assigned to application `default` and environment `default`. */
  unassignedExclude?: Maybe<Scalars["Boolean"]>;
  /** Return all resources which are marked as cloud waste */
  waste?: Maybe<Scalars["Boolean"]>;
  /** Return all resources which belong to given waste policy */
  wastePolicy?: Maybe<Array<Scalars["String"]>>;
  /** Exclude resources which belong to given waste policy specified in wastePolicy filter */
  wastePolicyExclude?: Maybe<Scalars["Boolean"]>;
};

/** SearchPresetSort is the same as SearchSort input. */
export type SearchPresetSort = {
  __typename?: "SearchPresetSort";
  field: SearchSortField;
  order: SearchSortOrder;
};

/** SearchPresetSortV2 is the same as SearchV2Sort input. */
export type SearchPresetSortV2 = {
  __typename?: "SearchPresetSortV2";
  /** Specifies which field should be used for sorting. */
  field: SearchV2SortField;
  /** Specifies the order of the sort. */
  order: SearchSortOrder;
};

/** SearchPresetV2 is updated SearchPreset type with support for new filters and sorting. */
export type SearchPresetV2 = {
  __typename?: "SearchPresetV2";
  createdAt: Scalars["String"];
  filter?: Maybe<SearchPresetFilterV2>;
  id: Scalars["String"];
  name: Scalars["String"];
  query?: Maybe<Scalars["String"]>;
  sort?: Maybe<SearchPresetSortV2>;
  updatedAt: Scalars["String"];
};

export type SearchPresetsResponse = {
  __typename?: "SearchPresetsResponse";
  count: Scalars["Int"];
  pages: Scalars["Int"];
  presets: Array<SearchPreset>;
};

export type SearchPresetsV2Response = {
  __typename?: "SearchPresetsV2Response";
  count: Scalars["Int"];
  pages: Scalars["Int"];
  presets: Array<SearchPresetV2>;
};

export type SearchResponse = {
  __typename?: "SearchResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<SearchResult>>;
};

export type SearchResult =
  | Account
  | Application
  | CloudResource
  | CostResource
  | Environment
  | OrgUnitV2;

/**
 * Input for sorting.
 *
 * Only one field/order combination can be selected at a time, and both must be selected (e.g. sort by TYPE/SUBTYPE
 * descending).
 */
export type SearchSort = {
  field: SearchSortField;
  order: SearchSortOrder;
};

/** Search sorting fields denominates the field by which result data will be ordered. */
export enum SearchSortField {
  /** Sort by monthly cost */
  Cost = "COST",
  /**
   * Sort by monthly cost forecast. This is currently identical to sorting by cost, since forecast is linear but might
   * change in the future.
   */
  Forecast = "FORECAST",
  /** Sort by Klarity ID or NID */
  Nid = "NID",
  /** Sort by the ID that providers give to resources. */
  ProviderId = "PROVIDER_ID",
  /** Sort by resource region, including resources that are in the 'global' region */
  Region = "REGION",
  /** Sort by savings amount */
  SavingsAmount = "SAVINGS_AMOUNT",
  /**
   * Sort by the type and subtype fields. The sorting is done as shown on frontend, so alphanumerically with type joined
   * with subtype by a '/', e.g. 'ec2/instance' is before 'ec2/volume' in ASC order.
   */
  Type = "TYPE",
}

/** Search sorting order is used to differentiate between ascending 'ASC' and descending 'DSC' orders. */
export enum SearchSortOrder {
  Asc = "ASC",
  Dsc = "DSC",
}

export type SearchTagKeysResponse = {
  __typename?: "SearchTagKeysResponse";
  keys: Array<Scalars["String"]>;
  /** Next is an optional cursor for pagination, that should be passed in `after` field. */
  next?: Maybe<Scalars["String"]>;
};

export type SearchTagValuesResponse = {
  __typename?: "SearchTagValuesResponse";
  /** Next is an optional cursor for pagination, that should be passed in `after` field. */
  next?: Maybe<Scalars["String"]>;
  values: Array<Scalars["String"]>;
};

export enum SearchType {
  Account = "ACCOUNT",
  Application = "APPLICATION",
  Cost = "COST",
  Environment = "ENVIRONMENT",
  OrgUnit = "ORG_UNIT",
  Resource = "RESOURCE",
}

/** Input for filtering data. */
export type SearchV2Filter = {
  /** Return all resources belonging to an account */
  account?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with an account specified in account filter */
  accountExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources assigned to environments that belong to given applications list */
  application?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with applications specified in application filter */
  applicationExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Exclude resources with assignment updated values specified in assignmentUpdatedFrom/assignmentUpdatedTo filters */
  assignmentUpdateExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources that had assignment updated from */
  assignmentUpdatedFrom?: InputMaybe<Scalars["String"]>;
  /** Return all resources that had assignment updated to */
  assignmentUpdatedTo?: InputMaybe<Scalars["String"]>;
  /** Return all resources for a specific billing period */
  billingPeriod?: InputMaybe<BillingPeriod>;
  /** Return all resources which which belong to given category */
  category?: InputMaybe<Array<Scalars["String"]>>;
  /** Excludes resources which which belong to given category in category filter */
  categoryExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources which are marked as co2e waste */
  co2eWaste?: InputMaybe<Scalars["Boolean"]>;
  /** Exclude resources with cost specified in costFrom/costTo filters */
  costExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources above given cost */
  costFrom?: InputMaybe<Scalars["UnsignedFloat"]>;
  /** Return all resources below given cost */
  costTo?: InputMaybe<Scalars["UnsignedFloat"]>;
  /** Return all resources which are enriched by external integration */
  enrichment?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources enriched by a specific external integration */
  enrichmentIntegrationId?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources enriched by a specific external integration specified in enrichmentIntegrationId filter */
  enrichmentIntegrationIdExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources assigned to given environments list */
  environment?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources assigned to given environments list specified in environment filter */
  environmentExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return resources not matching specified provider ID(s) */
  exceptProviderId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources created for a specific external integration */
  externalIntegration?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude all resources created for a specific external integration */
  externalIntegrationExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Exclude resources with firstSeen values specified in firstSeenAtFrom/firstSeenAtTo filters */
  firstSeenAtExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources that have been first seen at no earlier than this timestamp */
  firstSeenAtFrom?: InputMaybe<Scalars["String"]>;
  /** Return all resources that have been first seen at no later than this timestamp */
  firstSeenAtTo?: InputMaybe<Scalars["String"]>;
  /** Excludes all resources with values from lastSeenAtFrom/lastSeenAtTo filter */
  lastSeenAtExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources that have been last seen at no earlier than this timestamp */
  lastSeenAtFrom?: InputMaybe<Scalars["String"]>;
  /** Return all resources that have been last seen at no later than this timestamp */
  lastSeenAtTo?: InputMaybe<Scalars["String"]>;
  /** Returns all resources excluding lifeSpanFrom and/or lifeSpanTo */
  lifeSpanExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all resources that has life span from this number of days */
  lifeSpanFrom?: InputMaybe<Scalars["Int"]>;
  /** Returns all resources that has life span to this number of days */
  lifeSpanTo?: InputMaybe<Scalars["Int"]>;
  /** Return all resources with the specific metadata key value pairs */
  metadata?: InputMaybe<Array<KeyValuesInput>>;
  /** Excludes resources with the specific metadata key value pairs specified in metadata filter */
  metadataExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return only related resources for a resource specified in query */
  onlyRelated?: InputMaybe<Scalars["Boolean"]>;
  /** [GCP-specific] Return all resources that belong to any of the specified GCP projects */
  projectId?: InputMaybe<Array<Scalars["String"]>>;
  /** [GCP-specific] Exclude all resources that belong to any of the specified GCP projects */
  projectIdExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources from a particular provider */
  provider?: InputMaybe<Array<Provider>>;
  /** Exclude resources with providers specified in provider filter */
  providerExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources matching specified provider ID(s) */
  providerId?: InputMaybe<Array<Scalars["String"]>>;
  /** Return all resources from a given regions list */
  region?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with given regions specified in region filter */
  regionExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources with specified resourceGroup. This includes filtering by external integration */
  resourceGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with resourceGroup specified in resource group filter. */
  resourceGroupExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources with any of the specified AWS Security Group ID(s) */
  securityGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Excludes resources with any of the specified AWS Security Group ID(s) in securityGroup filter */
  securityGroupExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources assigned to a service */
  service?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with a service specified in service filter */
  serviceExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources with specified subtype */
  subtype?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with subtype specified in subtype filter */
  subtypeExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources belonging to a specific tags */
  tags?: InputMaybe<Array<KeyValuesInput>>;
  /** Exclude resources belonging to a specific tags specified in tags filter */
  tagsExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources with specified type */
  type?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with type specified in type filter */
  typeExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources with specified type/subtype combination */
  typeSubtype?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources with type/subtype combination specified in typeSubtype filter */
  typeSubtypeExclude?: InputMaybe<Scalars["Boolean"]>;
  /**
   * Return all resources assigned to application `default` and environment `default`.
   * `unassigned` can not be used with `application` or `environment` filters
   */
  unassigned?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources not assigned to application `default` and environment `default`. */
  unassignedExclude?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources which are marked as cloud waste */
  waste?: InputMaybe<Scalars["Boolean"]>;
  /** Return all resources which belong to given waste policy */
  wastePolicy?: InputMaybe<Array<Scalars["String"]>>;
  /** Exclude resources which belong to given waste policy specified in wastePolicy filter */
  wastePolicyExclude?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * Input for sorting V2.
 *
 * Only one field/order combination can be selected at a time, and both must be selected (e.g. sort by TYPE/SUBTYPE
 * descending).
 */
export type SearchV2Sort = {
  /** Specifies which field should be used for sorting. */
  field: SearchV2SortField;
  /** Specifies the order of the sort. */
  order: SearchSortOrder;
};

/** Search V2 sorting fields denominates the field by which result data will be ordered. */
export enum SearchV2SortField {
  /** Sort by monthly cost */
  Cost = "COST",
  /**
   * Sort by monthly cost forecast. This is currently identical to sorting by cost, since forecast is linear but might
   * change in the future.
   */
  Forecast = "FORECAST",
  /** Sort by Klarity ID or NID */
  Nid = "NID",
  /** Sort by the ID that providers give to resources. */
  ProviderId = "PROVIDER_ID",
  /** Sort by resource region, including resources that are in the 'global' region */
  Region = "REGION",
  /** Sort by savings amount */
  SavingsAmount = "SAVINGS_AMOUNT",
  /**
   * Sort by the type and subtype fields. The sorting is done as shown on frontend, so alphanumerically with type joined
   * with subtype by a '/', e.g. 'ec2/instance' is before 'ec2/volume' in ASC order.
   */
  Type = "TYPE",
}

export type Service = {
  __typename?: "Service";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum SortByOrder {
  Ascending = "ASCENDING",
  Descending = "DESCENDING",
}

export enum SortByValue {
  ApiKeyDescription = "API_KEY_DESCRIPTION",
  CreationDate = "CREATION_DATE",
  ExpirationDate = "EXPIRATION_DATE",
  LastLoginDate = "LAST_LOGIN_DATE",
  /** User's can be sorted by role */
  Role = "ROLE",
  UserEmailId = "USER_EMAIL_ID",
}

/**
 * Spend within date range, monthly, for an entity (OrgUnit|Environment|Application)
 * List of N monthly budgets from, if available.
 */
export type SpendByDateRange = {
  __typename?: "SpendByDateRange";
  spends: Array<Scalars["String"]>;
};

/**
 * Yearly spend for an entity (OrgUnit|Environment|Application)
 * Akin to budgets, spends are also represented as a list of 12 monthly budgets from Jan to Dec, if available.
 */
export type SpendYearly = {
  __typename?: "SpendYearly";
  spends: Array<Scalars["String"]>;
};

export type StorageReadWriteResource = {
  __typename?: "StorageReadWriteResource";
  date?: Maybe<Scalars["String"]>;
  read?: Maybe<Scalars["String"]>;
  write?: Maybe<Scalars["String"]>;
};

export type SubmitSearchJobResponse = {
  __typename?: "SubmitSearchJobResponse";
  jobId: Scalars["ID"];
};

export type Subnet = {
  __typename?: "Subnet";
  nid: Scalars["String"];
  subnetId: Scalars["String"];
};

export type Threshold = {
  __typename?: "Threshold";
  /** Threshold accounts */
  accounts: Array<Scalars["String"]>;
  /** Threshold created date */
  createdDate: Scalars["String"];
  /** Threshold external recipients */
  externalRecipients: Array<Scalars["String"]>;
  /** Threshold granularity */
  granularity: ThresholdGranularity;
  /** Threshold id */
  id: Scalars["ID"];
  /** Threshold name */
  name: Scalars["String"];
  /** Threshold providers */
  providers: Array<Scalars["String"]>;
  /** Threshold services */
  services: Array<Scalars["String"]>;
  /** Threshold cost amount */
  thresholdAmount: Scalars["Float"];
  /** Threshold updated date */
  updatedDate: Scalars["String"];
  /** Threshold user recipients */
  userRecipients: Array<Scalars["String"]>;
};

export type ThresholdAddInput = {
  /** Threshold accounts */
  accounts?: InputMaybe<Array<Scalars["String"]>>;
  /** Threshold external recipients */
  externalRecipients?: InputMaybe<Array<Scalars["String"]>>;
  /** Threshold granularity */
  granularity: ThresholdGranularity;
  /** Threshold name */
  name: Scalars["String"];
  /** Threshold providers */
  providers?: InputMaybe<Array<Scalars["String"]>>;
  /** Threshold services */
  services?: InputMaybe<Array<Scalars["String"]>>;
  /** Threshold cost amount */
  thresholdAmount: Scalars["Float"];
  /** Threshold user recipients */
  userRecipients?: InputMaybe<Array<Scalars["String"]>>;
};

export enum ThresholdGranularity {
  Daily = "DAILY",
  Monthly = "MONTHLY",
}

export enum ThresholdSortField {
  Amount = "AMOUNT",
  CreatedDate = "CREATED_DATE",
  Name = "NAME",
}

export type ThresholdSortInput = {
  /** Threshold field to sort by */
  field: ThresholdSortField;
  /** Thresholds sort order */
  order: ThresholdSortOrder;
};

export enum ThresholdSortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type ThresholdUpdateInput = {
  /** Threshold external recipients */
  externalRecipients?: InputMaybe<Array<Scalars["String"]>>;
  /** Threshold granularity */
  granularity?: InputMaybe<ThresholdGranularity>;
  /** Threshold name */
  name?: InputMaybe<Scalars["String"]>;
  /** Threshold resource selector */
  resourceSelector?: InputMaybe<ThresholdUpdateResourceSelector>;
  /** Threshold cost amount */
  thresholdAmount?: InputMaybe<Scalars["Float"]>;
  /** Threshold user recipients */
  userRecipients?: InputMaybe<Array<Scalars["String"]>>;
};

export type ThresholdUpdateResourceSelector = {
  /** Threshold accounts */
  accounts?: InputMaybe<Array<Scalars["String"]>>;
  /** Threshold providers */
  providers?: InputMaybe<Array<Scalars["String"]>>;
  /** Threshold services */
  services?: InputMaybe<Array<Scalars["String"]>>;
};

export enum TimePeriodType {
  Currentmonth = "CURRENTMONTH",
  Previousmonth = "PREVIOUSMONTH",
}

export type TimePoint = {
  __typename?: "TimePoint";
  date: Scalars["String"];
  value: Scalars["String"];
};

export type TimePointWithUnit = {
  __typename?: "TimePointWithUnit";
  date: Scalars["String"];
  unit: Scalars["String"];
  value: Scalars["String"];
};

export type TimeSeries = {
  __typename?: "TimeSeries";
  benefit: Scalars["String"];
  date: Scalars["String"];
  value: Scalars["String"];
};

export type TopSpendingApplicationsResponse = {
  __typename?: "TopSpendingApplicationsResponse";
  /** `defaultApplication` is the application with unallocated costs */
  defaultApplication?: Maybe<DashboardApplicationCost>;
  /** `other` is the application with summed costs of all applications excluding `topSpending` and `defaultApplication` */
  other: DashboardApplicationCost;
  /** `topSpending` is the list of applications with the highest `currentMonth` cost */
  topSpending?: Maybe<Array<DashboardApplicationCost>>;
  /** `total` is the summary of costs of all applications */
  total: DashboardApplicationCostTotal;
};

export type TotalPotentialMonthlySavings = {
  __typename?: "TotalPotentialMonthlySavings";
  /**
   * `currentMonthTotalSavings` describes the sum of maximum of potential savings amount
   * of all the savings suggestion policy enabled resouces for the given customer and period
   */
  currentMonthTotalSavings?: Maybe<Scalars["String"]>;
  /** `period` describes the given period for which the response is fetched */
  period?: Maybe<Scalars["String"]>;
  /**
   * `previousMonthTotalSavings` describes the sum of maximum of potential savings amount
   * of all the savings suggestion policy enabled resouces for the given customer and
   * previous period of the given period
   */
  previousMonthTotalSavings?: Maybe<Scalars["String"]>;
};

export type TriggerScanV2Output = {
  __typename?: "TriggerScanV2Output";
  notTriggeredAccounts?: Maybe<Array<Scalars["String"]>>;
  scanTriggered?: Maybe<Scalars["Boolean"]>;
};

export type TurbonomicConnectionInput = {
  password: Scalars["String"];
  url: Scalars["String"];
  username: Scalars["String"];
};

export type TurbonomicIntegrationPlugin = {
  __typename?: "TurbonomicIntegrationPlugin";
  contactPersons: Array<Person>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  integrationType: IntegrationPluginType;
  lastSyncStatus?: Maybe<IntegrationPluginSyncStatus>;
  lastSyncTime: Scalars["String"];
  name: Scalars["String"];
  url: Scalars["String"];
  username: Scalars["String"];
};

export type UnmarkReservedInstance = {
  nid: Scalars["String"];
};

export type UpdateAccount = {
  contactIds?: InputMaybe<Array<Scalars["String"]>>;
  description?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  orgUnitIds?: InputMaybe<Array<Scalars["String"]>>;
  ownerId?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Provider>;
  providerId?: InputMaybe<Scalars["String"]>;
};

export type UpdateAccountV2 = {
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  provider?: InputMaybe<Provider>;
  providerId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type UpdateAccountV3 = {
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  description?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  metadata?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  provider?: InputMaybe<Provider>;
  providerId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type UpdateAnomalyThreshold = {
  threshold: Scalars["Float"];
};

export type UpdateApplication = {
  contactIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  /**
   * `displayName` is a string up to 256 characters long that helps to identify the application
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["String"]>;
};

export type UpdateApplicationV2 = {
  /** A list of contacts attached to this application */
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: InputMaybe<Scalars["String"]>;
  /** `displayName` is a string up to 256 characters long that helps to identify the application */
  displayName?: InputMaybe<Scalars["String"]>;
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name?: InputMaybe<Scalars["String"]>;
  /** The id of the person that is the owner of the application */
  ownerId?: InputMaybe<Scalars["ID"]>;
};

export type UpdateAwsAccountCredentialsInput = {
  /** Additional credentials for Aws China */
  additionalCredentials?: InputMaybe<AwsAdditionalCredentials>;
  /** Roles are entities with specific permissions to allow trusted identities to perform actions in AWS */
  roles: Array<AwsRole>;
};

export type UpdateAwsBillingConfig = {
  accountId: Scalars["String"];
  bucketId: Scalars["String"];
  region?: InputMaybe<Scalars["String"]>;
  reportName?: InputMaybe<Scalars["String"]>;
  reportPathPrefix?: InputMaybe<Scalars["String"]>;
};

export type UpdateAzureAccountCredentialsInput = {
  /** Unique application ID of this application in your directory */
  appId: Scalars["ID"];
  /** Azure account password */
  password: Scalars["String"];
  /** Tenant Id is the Azure Active Directory’s Global unique identifier (GUID) */
  tenantId: Scalars["ID"];
};

export type UpdateAzureBillingConfig = {
  /** `capacitorCustomerId` is a Capacitor Customer ID. */
  capacitorCustomerId: Scalars["String"];
  /** `enrollmentAccessKey` is a JWT token used to authenticate account in Azure. */
  enrollmentAccessKey: Scalars["String"];
  /** `enrollmentNumber` is how the account is identified by Azure. */
  enrollmentNumber: Scalars["String"];
};

export type UpdateAzureTagPropagation = {
  enabled: Scalars["Boolean"];
};

export type UpdateBusinessContext = {
  /** `color` is a string in the form of a hex, eg #666666 */
  color?: InputMaybe<Scalars["HexColorCode"]>;
  /** `description` is an optional text that describes what the context represents, and it's purpose */
  description?: InputMaybe<Scalars["String"]>;
  /** `name` is a string up to 256 characters long that helps to identify the context */
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateCloudWasteSetting = {
  id: CloudWasteSettingKey;
  parameters?: InputMaybe<Array<UpdateCloudWasteSettingParameter>>;
  status: CloudWasteSettingStatus;
};

export type UpdateCloudWasteSettingParameter = {
  id: CloudWasteSettingParameterId;
  /**
   * `value` is typed as String to be universal for both number and text input,
   * union types for graphql input are not supported
   */
  value: Scalars["String"];
};

export type UpdateCostCategoryMapping = {
  algorithm?: InputMaybe<CostMappingAlgorithm>;
  id: Scalars["String"];
  provider?: InputMaybe<Provider>;
  settings?: InputMaybe<CostSettingsInput>;
  type?: InputMaybe<Scalars["String"]>;
};

export type UpdateDiscoveryRule = {
  exclusive?: InputMaybe<Scalars["Boolean"]>;
  ibmResourceGroup?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["String"];
  match?: InputMaybe<DiscoveryRuleMatchInput>;
  name?: InputMaybe<Scalars["String"]>;
  tagsOperator?: InputMaybe<RuleOperator>;
};

export type UpdateDriftsDisabled = {
  disabled: Scalars["Boolean"];
};

export type UpdateEnvironment = {
  applicationId?: InputMaybe<Scalars["String"]>;
  contactIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: InputMaybe<Scalars["String"]>;
  /**
   * `displayName` is a string up to 256 characters long that helps to identify the environment
   * `displayName` is not required for backwards compatibility reason, if it's not specified,
   * it's gonna be copied from `name`, but it should be always included if possible
   */
  displayName?: InputMaybe<Scalars["String"]>;
  /**
   * `id` is equal to `nid` in Environment right now
   * we want to use `nid` everywhere
   * for now they are kept as a backward compability issue, but `nid` should be used whenever possible
   */
  id: Scalars["String"];
  name: Scalars["String"];
  ownerId?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<EnvironmentType>;
};

export type UpdateEnvironmentV2 = {
  /**
   * The NID of the application this new environment will be assigned to
   * each environment must be assigned to an application
   */
  applicationNid?: InputMaybe<Scalars["ID"]>;
  /** Optionally a list of contacts can be attached to the environment */
  contactIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** `description` is an optional text that helps identify what the environment represents */
  description?: InputMaybe<Scalars["String"]>;
  /** `displayName` is a string up to 256 characters long that helps to identify the environment */
  displayName?: InputMaybe<Scalars["String"]>;
  /**
   * `name` is a string up to 256 characters long that is used when mapping resources,
   * `name` must be unique per customer, to avoid conflicts in mapping
   */
  name?: InputMaybe<Scalars["String"]>;
  /** The id of the person that is the owner of the environment */
  ownerId?: InputMaybe<Scalars["ID"]>;
  /** The type of the environment */
  type?: InputMaybe<EnvironmentType>;
};

export type UpdateGcpBillingConfig = {
  /** `bigQueryDataset` is the name of a BigQuery dataset. */
  bigQueryDataset: Scalars["String"];
  /** `bigQueryTable` is the name of a table within a BigQuery dataset. */
  bigQueryTable: Scalars["String"];
  /** `onboardedOrgId` is unique ID with which GCP account was onboarded */
  onboardedOrgId: Scalars["String"];
  /** `projectId` is a unique identifier for a GCP project */
  projectId: Scalars["String"];
  /**
   * `serviceAccountKey` is a JSON file that contains the credentials for
   * authenticating and authorizing access to GCP services on behalf of a service account.
   */
  serviceAccountKey: Scalars["String"];
  /** `useDetailedBillingData` is a parameter that determines whether detailed billing data is enabled or disabled for a project. */
  useDetailedBillingData: Scalars["Boolean"];
};

export type UpdateGcpTagPropagation = {
  enabled: Scalars["Boolean"];
};

export type UpdateGcpAccountCredentialsInput = {
  /** The X. 509 certificate is a safeguard against malicious network impersonators */
  authProviderX509CertUrl: Scalars["String"];
  /** Auth Uri is the enpoint where you can begin auth flow to obtain access token for Gcp resources */
  authUri: Scalars["String"];
  /** Gcp account client email */
  clientEmail: Scalars["String"];
  /** client ID is a unique identifier that is associated with a client application or service that needs to access GCP resources */
  clientId: Scalars["String"];
  /** The X. 509 certificate is a safeguard against malicious network impersonators */
  clientX509CertUrl: Scalars["String"];
  /** Private key is a file that contains a cryptographic key used for authentication and encryption purposes. */
  privateKey: Scalars["String"];
  /** The private key ID is a string value that is used to uniquely identify the private key when you use it to authenticate with GCP services */
  privateKeyId: Scalars["ID"];
  /** Id of Gcp project that is in use */
  projectId: Scalars["String"];
  /** Token Uri is used to get access to Gcp resources */
  tokenUri: Scalars["String"];
  /** Gcp account type: Personal or Business */
  type: GcpAccountType;
};

export type UpdateGlobalTagKeys = {
  application?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  environment?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UpdateIbmAccountCredentialsInput = {
  /** apikey is used to authenticate to IBM Cloud */
  apikey: Scalars["ID"];
};

export type UpdateKpiTarget = {
  contactPersonId?: InputMaybe<Scalars["ID"]>;
  goal?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateMappingRule = {
  cloudAccount?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  projectId?: InputMaybe<Scalars["String"]>;
  resourceGroup?: InputMaybe<Scalars["String"]>;
  resourceType?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<KeyValueInput>>;
};

export type UpdateOrgUnitEnvironmentsBodyInput = {
  /** `envIds` is an array of Environment id's to be attached to orgUnit */
  envIds: Array<Scalars["ID"]>;
  /** `orgUnitId` is a organization unit ID */
  orgUnitId: Scalars["ID"];
};

export type UpdateOrgUnitTypeV2 = {
  /** `description` is an optional text that helps identify what the type represents, and it's purpose */
  description?: InputMaybe<Scalars["String"]>;
  /** `name` is a string up to 256 characters long helps identify the type */
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrgUnitV2 = {
  /** `contactId` is optional id of the contact person that should be assigned to the OrgUnit */
  contactId?: InputMaybe<Scalars["ID"]>;
  /** `contextId` is the ID of the business context this org unit is in */
  contextId?: InputMaybe<Scalars["ID"]>;
  /** `description` is an optional text that helps identify what the unit represents, and it's purpose */
  description?: InputMaybe<Scalars["String"]>;
  /** `name` is a string up to 256 characters long that helps identify the unit */
  name?: InputMaybe<Scalars["String"]>;
  /**
   * The nid of the parent OrgUnit in a unit tree
   * parent can be null for top-level organizational units
   */
  parentNid?: InputMaybe<Scalars["ID"]>;
  /** `typeId` is optional id of the OrgUnitTypeV2 that can be assigned to the OrgUnit */
  typeId?: InputMaybe<Scalars["ID"]>;
};

export type UpdateOrgUnitV4Response = {
  __typename?: "UpdateOrgUnitV4Response";
  /** Updated Organizational Unit object, null when `userErrors` is defined */
  orgUnit?: Maybe<OrgUnitV2>;
  /** User errors, null when `orgUnit` is defined */
  userErrors?: Maybe<OrgUnitUserError>;
};

export type UpdatePerson = {
  email?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateTurbonomicIntegrationPluginInput = {
  contactPersonIds?: InputMaybe<Array<Scalars["String"]>>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type UpdateWorkflowConfigInput = {
  externalIntegrations: Array<WorkflowExternalIntegrationsUpdateInput>;
  selectData: WorkflowSelectDataInput;
  triggers: Array<WorkflowTriggerInput>;
};

export type UpdateWorkflowInput = {
  contactPersonIds?: InputMaybe<Array<Scalars["String"]>>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UserPermissions = {
  __typename?: "UserPermissions";
  access: Access;
  email: Scalars["String"];
  /** Is user Organization Admin from MCA Panel */
  isOrgAdminFromPanel: Scalars["Boolean"];
  lastLoginDate?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<Scalars["String"]>;
};

export type UsersPermissionsResponse = {
  __typename?: "UsersPermissionsResponse";
  count?: Maybe<Scalars["Int"]>;
  pages?: Maybe<Scalars["Int"]>;
  usersPermissions?: Maybe<Array<UserPermissions>>;
};

export type UtilisationResponse = {
  __typename?: "UtilisationResponse";
  benefit?: Maybe<Scalars["String"]>;
  utilization?: Maybe<Scalars["String"]>;
};

export type Vpc = {
  __typename?: "Vpc";
  nid: Scalars["String"];
  subnets?: Maybe<Array<Subnet>>;
  vpcId: Scalars["String"];
};

export type VpcSubnetsArgs = {
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
};

export type VpcsResponse = {
  __typename?: "VpcsResponse";
  count: Scalars["Int"];
  pages: Scalars["Int"];
  results?: Maybe<Array<Vpc>>;
};

/** Types */
export type Workflow = {
  __typename?: "Workflow";
  active: Scalars["Boolean"];
  contactPersons: Array<Person>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  externalIntegrations: Array<WorkflowExternalIntegration>;
  id: Scalars["ID"];
  lastRunDate: Scalars["String"];
  lastRunStatus: WorkflowLastRunStatus;
  name: Scalars["String"];
  selectData: WorkflowSelectData;
  triggers: Array<WorkflowTrigger>;
};

export type WorkflowAdditionalField = {
  __typename?: "WorkflowAdditionalField";
  key: Scalars["String"];
  value: Scalars["String"];
};

export type WorkflowAdditionalFieldInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};

export enum WorkflowDataType {
  AnomalyCosts = "ANOMALY_COSTS",
  Budgets = "BUDGETS",
  CloudCredentials = "CLOUD_CREDENTIALS",
  Costs = "COSTS",
  Kpi = "KPI",
  SavingSuggestions = "SAVING_SUGGESTIONS",
  UnallocatedResources = "UNALLOCATED_RESOURCES",
}

export type WorkflowDetailedResult = {
  __typename?: "WorkflowDetailedResult";
  kpiResult?: Maybe<WorkflowKpiResult>;
};

export type WorkflowEmailOptions = {
  __typename?: "WorkflowEmailOptions";
  mailTo?: Maybe<Array<Scalars["String"]>>;
  message: Scalars["String"];
  messageType: WorkflowEmailOptionsMessageType;
  sendToContactPersons?: Maybe<Scalars["Boolean"]>;
  subject: Scalars["String"];
};

export type WorkflowEmailOptionsInput = {
  mailTo?: InputMaybe<Array<Scalars["String"]>>;
  message: Scalars["String"];
  messageType: WorkflowEmailOptionsMessageType;
  sendToContactPersons?: InputMaybe<Scalars["Boolean"]>;
  subject: Scalars["String"];
};

export enum WorkflowEmailOptionsMessageType {
  AnomalyCosts = "ANOMALY_COSTS",
  Budgets = "BUDGETS",
  CloudCredentials = "CLOUD_CREDENTIALS",
  CostsIndividual = "COSTS_INDIVIDUAL",
  CostsTopCosts = "COSTS_TOP_COSTS",
  Custom = "CUSTOM",
  Kpi = "KPI",
  NewUnallocatedResources = "NEW_UNALLOCATED_RESOURCES",
  SavingSuggestions = "SAVING_SUGGESTIONS",
  SavingSuggestionsCombined = "SAVING_SUGGESTIONS_COMBINED",
  SavingSuggestionsIndividual = "SAVING_SUGGESTIONS_INDIVIDUAL",
  Undefined = "UNDEFINED",
}

export type WorkflowEvent = {
  __typename?: "WorkflowEvent";
  createdAt: Scalars["String"];
  customerId: Scalars["String"];
  dataType: WorkflowDataType;
  detailedResult?: Maybe<WorkflowDetailedResult>;
  endTime: Scalars["String"];
  errorCode?: Maybe<WorkflowEventErrorCode>;
  id: Scalars["String"];
  result: Scalars["String"];
  startTime: Scalars["String"];
  status: WorkflowEventStatus;
  trigger: WorkflowTrigger;
  workflowDeleted: Scalars["Boolean"];
  workflowDescription?: Maybe<Scalars["String"]>;
  workflowId: Scalars["String"];
  workflowName: Scalars["String"];
};

export enum WorkflowEventErrorCode {
  DataSelectionError = "DATA_SELECTION_ERROR",
  InternalError = "INTERNAL_ERROR",
}

export enum WorkflowEventInListSortingFields {
  Name = "NAME",
  StartTime = "START_TIME",
  Status = "STATUS",
}

export enum WorkflowEventStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
}

export type WorkflowExternalIntegration = {
  __typename?: "WorkflowExternalIntegration";
  emailOptions?: Maybe<WorkflowEmailOptions>;
  id: Scalars["ID"];
  serviceNowOptions?: Maybe<WorkflowServiceNowOptions>;
  slackOptions?: Maybe<WorkflowSlackOptions>;
  type: WorkflowExternalIntegrationType;
};

export enum WorkflowExternalIntegrationType {
  Email = "EMAIL",
  ServiceNow = "SERVICE_NOW",
  Slack = "SLACK",
}

export type WorkflowExternalIntegrationsInput = {
  emailOptions?: InputMaybe<WorkflowEmailOptionsInput>;
  serviceNowOptions?: InputMaybe<WorkflowServiceNowOptionsInput>;
  slackOptions?: InputMaybe<WorkflowSlackOptionsInput>;
  type: WorkflowExternalIntegrationType;
};

export type WorkflowExternalIntegrationsUpdateInput = {
  emailOptions?: InputMaybe<WorkflowEmailOptionsInput>;
  id?: InputMaybe<Scalars["ID"]>;
  serviceNowOptions?: InputMaybe<WorkflowServiceNowOptionsUpdateInput>;
  slackOptions?: InputMaybe<WorkflowSlackOptionsUpdateInput>;
  type: WorkflowExternalIntegrationType;
};

export enum WorkflowFrequency {
  Daily = "DAILY",
  Monthly = "MONTHLY",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export type WorkflowHistoryListOutput = {
  __typename?: "WorkflowHistoryListOutput";
  cursor?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<WorkflowEvent>>;
};

export type WorkflowKpiResult = {
  __typename?: "WorkflowKpiResult";
  businessContextColor?: Maybe<Scalars["String"]>;
  businessContextName?: Maybe<Scalars["String"]>;
  costAllocation?: Maybe<WorkflowKpiResultValue>;
  nonProdHours?: Maybe<WorkflowKpiResultValue>;
  overallWaste?: Maybe<WorkflowKpiResultValue>;
};

export type WorkflowKpiResultValue = {
  __typename?: "WorkflowKpiResultValue";
  percentage?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export enum WorkflowLastRunStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  None = "NONE",
}

export type WorkflowListItem = {
  __typename?: "WorkflowListItem";
  active: Scalars["Boolean"];
  contactPersons: Array<Person>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastRunDate: Scalars["String"];
  lastRunStatus: WorkflowLastRunStatus;
  name: Scalars["String"];
  triggers: Array<WorkflowTrigger>;
};

export type WorkflowOutput = {
  __typename?: "WorkflowOutput";
  userErrors?: Maybe<WorkflowUserError>;
  workflow?: Maybe<Workflow>;
};

export type WorkflowSchedulerOptions = {
  __typename?: "WorkflowSchedulerOptions";
  byWeekday?: Maybe<Array<Scalars["Int"]>>;
  endDate?: Maybe<Scalars["String"]>;
  frequency: WorkflowFrequency;
  interval?: Maybe<Scalars["Int"]>;
  startDate: Scalars["String"];
};

export type WorkflowSchedulerOptionsInput = {
  byWeekday?: InputMaybe<Array<Scalars["Int"]>>;
  endDate?: InputMaybe<Scalars["String"]>;
  frequency: WorkflowFrequency;
  interval?: InputMaybe<Scalars["Int"]>;
  startDate: Scalars["String"];
};

export type WorkflowSelectBudgetsOptionsInput = {
  applications?: InputMaybe<Array<Scalars["String"]>>;
  costGtBudget: Scalars["Boolean"];
  environments?: InputMaybe<Array<Scalars["String"]>>;
  forecastGtBudget: Scalars["Boolean"];
  orgUnitIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type WorkflowSelectData = {
  __typename?: "WorkflowSelectData";
  anomalyCostsOptions?: Maybe<WorkflowSelectDataAnomalyCostsOptions>;
  budgetsOptions?: Maybe<WorkflowSelectDataBudgetsOptions>;
  costsOptions?: Maybe<WorkflowSelectDataCostsOptions>;
  kpiOptions?: Maybe<WorkflowSelectDataKpiOptions>;
  savingSuggestionsOptions?: Maybe<WorkflowSelectDataSavingSuggestionsOptions>;
  type: WorkflowDataType;
};

export type WorkflowSelectDataAnomalyCostsOptions = {
  __typename?: "WorkflowSelectDataAnomalyCostsOptions";
  applicationIds: Array<Scalars["String"]>;
};

export type WorkflowSelectDataAnomalyCostsOptionsInput = {
  applicationIds: Array<Scalars["String"]>;
};

export type WorkflowSelectDataBudgetsOptions = {
  __typename?: "WorkflowSelectDataBudgetsOptions";
  applications?: Maybe<Array<Scalars["String"]>>;
  costGtBudget: Scalars["Boolean"];
  environments?: Maybe<Array<Scalars["String"]>>;
  forecastGtBudget: Scalars["Boolean"];
  orgUnitIds?: Maybe<Array<Scalars["String"]>>;
};

export type WorkflowSelectDataCostsOptions = {
  __typename?: "WorkflowSelectDataCostsOptions";
  applications?: Maybe<Array<Scalars["String"]>>;
  environments?: Maybe<Array<Scalars["String"]>>;
  orgUnitIds?: Maybe<Array<Scalars["String"]>>;
};

export type WorkflowSelectDataCostsOptionsInput = {
  applications?: InputMaybe<Array<Scalars["String"]>>;
  environments?: InputMaybe<Array<Scalars["String"]>>;
  orgUnitIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type WorkflowSelectDataInput = {
  anomalyCostsOptions?: InputMaybe<WorkflowSelectDataAnomalyCostsOptionsInput>;
  budgetsOptions?: InputMaybe<WorkflowSelectBudgetsOptionsInput>;
  costsOptions?: InputMaybe<WorkflowSelectDataCostsOptionsInput>;
  kpiOptions?: InputMaybe<WorkflowSelectDataKpiOptionsInput>;
  savingSuggestionsOptions?: InputMaybe<WorkflowSelectDataSavingSuggestionsOptionsInput>;
  type: WorkflowDataType;
};

export type WorkflowSelectDataKpiOptions = {
  __typename?: "WorkflowSelectDataKpiOptions";
  businessContextId: Scalars["String"];
};

export type WorkflowSelectDataKpiOptionsInput = {
  businessContextId: Scalars["String"];
};

export type WorkflowSelectDataSavingSuggestionsOptions = {
  __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
  applications?: Maybe<Array<Scalars["String"]>>;
  environments?: Maybe<Array<Scalars["String"]>>;
  orgUnitIds?: Maybe<Array<Scalars["String"]>>;
};

export type WorkflowSelectDataSavingSuggestionsOptionsInput = {
  applications?: InputMaybe<Array<Scalars["String"]>>;
  environments?: InputMaybe<Array<Scalars["String"]>>;
  orgUnitIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type WorkflowServiceNowCostsOptions = {
  __typename?: "WorkflowServiceNowCostsOptions";
  costsByCategories: Scalars["Boolean"];
  costsByProviders: Scalars["Boolean"];
  totalMonthlyCost: Scalars["Boolean"];
  totalMonthlyForecast: Scalars["Boolean"];
};

export type WorkflowServiceNowCostsOptionsInput = {
  costsByCategories: Scalars["Boolean"];
  costsByProviders: Scalars["Boolean"];
  totalMonthlyCost: Scalars["Boolean"];
  totalMonthlyForecast: Scalars["Boolean"];
};

export type WorkflowServiceNowKpiOptions = {
  __typename?: "WorkflowServiceNowKpiOptions";
  costAllocation: Scalars["Boolean"];
  nonProdHours: Scalars["Boolean"];
  overallWaste: Scalars["Boolean"];
};

export type WorkflowServiceNowKpiOptionsInput = {
  costAllocation: Scalars["Boolean"];
  nonProdHours: Scalars["Boolean"];
  overallWaste: Scalars["Boolean"];
};

export type WorkflowServiceNowOptions = {
  __typename?: "WorkflowServiceNowOptions";
  additionalFields?: Maybe<Array<WorkflowAdditionalField>>;
  costsOptions?: Maybe<WorkflowServiceNowCostsOptions>;
  description?: Maybe<Scalars["String"]>;
  kpiOptions?: Maybe<WorkflowServiceNowKpiOptions>;
  savingSuggestionsOptions?: Maybe<WorkflowServiceNowSavingSuggestionsOptions>;
  shortDescription: Scalars["String"];
  url: Scalars["String"];
  username: Scalars["String"];
};

export type WorkflowServiceNowOptionsInput = {
  additionalFields?: InputMaybe<Array<WorkflowAdditionalFieldInput>>;
  costsOptions?: InputMaybe<WorkflowServiceNowCostsOptionsInput>;
  description?: InputMaybe<Scalars["String"]>;
  kpiOptions?: InputMaybe<WorkflowServiceNowKpiOptionsInput>;
  password: Scalars["String"];
  savingSuggestionsOptions?: InputMaybe<WorkflowServiceNowSavingSuggestionsOptionsInput>;
  shortDescription: Scalars["String"];
  url: Scalars["String"];
  username: Scalars["String"];
};

export type WorkflowServiceNowOptionsUpdateInput = {
  additionalFields?: InputMaybe<Array<WorkflowAdditionalFieldInput>>;
  costsOptions?: InputMaybe<WorkflowServiceNowCostsOptionsInput>;
  description?: InputMaybe<Scalars["String"]>;
  kpiOptions?: InputMaybe<WorkflowServiceNowKpiOptionsInput>;
  password?: InputMaybe<Scalars["String"]>;
  savingSuggestionsOptions?: InputMaybe<WorkflowServiceNowSavingSuggestionsOptionsInput>;
  shortDescription: Scalars["String"];
  url: Scalars["String"];
  username: Scalars["String"];
};

export type WorkflowServiceNowSavingSuggestionsOptions = {
  __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
  monthlySavingsByCategories: Scalars["Boolean"];
  monthlySavingsByPolicies: Scalars["Boolean"];
  monthlySavingsByProviders: Scalars["Boolean"];
  totalMonthlySavings: Scalars["Boolean"];
};

export type WorkflowServiceNowSavingSuggestionsOptionsInput = {
  monthlySavingsByCategories: Scalars["Boolean"];
  monthlySavingsByPolicies: Scalars["Boolean"];
  monthlySavingsByProviders: Scalars["Boolean"];
  totalMonthlySavings: Scalars["Boolean"];
};

export type WorkflowSlackCostsOptions = {
  __typename?: "WorkflowSlackCostsOptions";
  costsByCategories: Scalars["Boolean"];
  costsByProviders: Scalars["Boolean"];
  totalMonthlyCost: Scalars["Boolean"];
  totalMonthlyForecast: Scalars["Boolean"];
};

export type WorkflowSlackCostsOptionsInput = {
  costsByCategories: Scalars["Boolean"];
  costsByProviders: Scalars["Boolean"];
  totalMonthlyCost: Scalars["Boolean"];
  totalMonthlyForecast: Scalars["Boolean"];
};

export type WorkflowSlackKpiOptions = {
  __typename?: "WorkflowSlackKpiOptions";
  costAllocation: Scalars["Boolean"];
  nonProdHours: Scalars["Boolean"];
  overallWaste: Scalars["Boolean"];
};

export type WorkflowSlackKpiOptionsInput = {
  costAllocation: Scalars["Boolean"];
  nonProdHours: Scalars["Boolean"];
  overallWaste: Scalars["Boolean"];
};

export type WorkflowSlackOptions = {
  __typename?: "WorkflowSlackOptions";
  channel: Scalars["String"];
  costsOptions?: Maybe<WorkflowSlackCostsOptions>;
  kpiOptions?: Maybe<WorkflowSlackKpiOptions>;
  message?: Maybe<Scalars["String"]>;
  savingSuggestionsOptions?: Maybe<WorkflowSlackSavingSuggestionsOptions>;
};

export type WorkflowSlackOptionsInput = {
  channel: Scalars["String"];
  costsOptions?: InputMaybe<WorkflowSlackCostsOptionsInput>;
  kpiOptions?: InputMaybe<WorkflowSlackKpiOptionsInput>;
  message?: InputMaybe<Scalars["String"]>;
  savingSuggestionsOptions?: InputMaybe<WorkflowSlackSavingSuggestionsOptionsInput>;
  token: Scalars["String"];
};

export type WorkflowSlackOptionsUpdateInput = {
  channel: Scalars["String"];
  costsOptions?: InputMaybe<WorkflowSlackCostsOptionsInput>;
  kpiOptions?: InputMaybe<WorkflowSlackKpiOptionsInput>;
  message?: InputMaybe<Scalars["String"]>;
  savingSuggestionsOptions?: InputMaybe<WorkflowSlackSavingSuggestionsOptionsInput>;
  token?: InputMaybe<Scalars["String"]>;
};

export type WorkflowSlackSavingSuggestionsOptions = {
  __typename?: "WorkflowSlackSavingSuggestionsOptions";
  monthlySavingsByCategories: Scalars["Boolean"];
  monthlySavingsByPolicies: Scalars["Boolean"];
  monthlySavingsByProviders: Scalars["Boolean"];
  totalMonthlySavings: Scalars["Boolean"];
};

export type WorkflowSlackSavingSuggestionsOptionsInput = {
  monthlySavingsByCategories: Scalars["Boolean"];
  monthlySavingsByPolicies: Scalars["Boolean"];
  monthlySavingsByProviders: Scalars["Boolean"];
  totalMonthlySavings: Scalars["Boolean"];
};

export enum WorkflowSortingFields {
  Active = "ACTIVE",
  LastRunDate = "LAST_RUN_DATE",
  LastRunStatus = "LAST_RUN_STATUS",
  Name = "NAME",
}

export enum WorkflowSortingOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type WorkflowTrigger = {
  __typename?: "WorkflowTrigger";
  schedulerOptions?: Maybe<WorkflowSchedulerOptions>;
  type: WorkflowTriggerType;
};

export type WorkflowTriggerInput = {
  schedulerOptions?: InputMaybe<WorkflowSchedulerOptionsInput>;
  type: WorkflowTriggerType;
};

export enum WorkflowTriggerType {
  Manual = "MANUAL",
  Scan = "SCAN",
  Scheduler = "SCHEDULER",
}

export type WorkflowUserError = {
  __typename?: "WorkflowUserError";
  code: WorkflowUserErrorCode;
  message: Scalars["String"];
  supportLink?: Maybe<Scalars["String"]>;
};

export enum WorkflowUserErrorCode {
  ActiveWorkflowLimitReached = "ACTIVE_WORKFLOW_LIMIT_REACHED",
  WorkflowMissingFutureEvent = "WORKFLOW_MISSING_FUTURE_EVENT",
}

export type EventsQueryVariables = Exact<{
  from: Scalars["AWSDateTime"];
  nid: Scalars["String"];
  to: Scalars["AWSDateTime"];
}>;

export type EventsQuery = {
  __typename?: "Query";
  events?: Array<{
    __typename?: "Event";
    id: string;
    title: string;
    type: string;
    content: string;
    createdAt: string;
  }> | null;
};

export type ExpandableTagQueryVariables = Exact<{
  ids: Array<Scalars["String"]> | Scalars["String"];
  isApp: Scalars["Boolean"];
  isEnv: Scalars["Boolean"];
  isCategory: Scalars["Boolean"];
  isPolicy: Scalars["Boolean"];
  isService: Scalars["Boolean"];
  isExternal: Scalars["Boolean"];
}>;

export type ExpandableTagQuery = {
  __typename?: "Query";
  applicationsByIds?: Array<{
    __typename?: "Application";
    name: string;
    id: string;
    nid: string;
  } | null> | null;
  environmentsByIds?: Array<{
    __typename?: "Environment";
    name: string;
    id: string;
    nid: string;
    application: { __typename?: "Application"; id: string; name: string; nid: string };
  }>;
  categoriesByIds?: Array<{ __typename?: "Category"; name: string; id: string }>;
  cloudWasteSummary?: {
    __typename?: "CloudWasteSummaryResponse";
    policies?: Array<{
      __typename?: "CloudWastePolicySummary";
      id?: CloudWasteSettingKey | null;
      title?: string | null;
    } | null> | null;
  } | null;
  externalIntegrations?: {
    __typename?: "ExternalIntegrationsResponse";
    integrations?: Array<{
      __typename?: "ExternalIntegrationConfig";
      id: string;
      name: string;
    } | null> | null;
  } | null;
  servicesByIds?: Array<{ __typename?: "Service"; id: string; name: string } | null> | null;
};

export type EstateResourceCountQueryVariables = Exact<{
  filter?: InputMaybe<EstateResourceFilterV2>;
}>;

export type EstateResourceCountQuery = {
  __typename?: "Query";
  estateResourceCountV2?: number | null;
};

export type GetAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccountsQuery = {
  __typename?: "Query";
  accounts?: Array<{
    __typename?: "Account";
    id: string;
    provider: Provider;
    providerType?: CloudProviderType | null;
    name: string;
    displayName?: string | null;
    nid: string;
    providerId: string;
    activated: boolean;
  } | null> | null;
};

export type GoogleCloudProjectsQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  query?: InputMaybe<Scalars["String"]>;
}>;

export type GoogleCloudProjectsQuery = {
  __typename?: "Query";
  gcpProjects?: {
    __typename?: "GcpProjectsResponse";
    count?: number | null;
    pages?: number | null;
    projects?: Array<string | null> | null;
  } | null;
};

export type GetVpcsSubnetsQueryVariables = Exact<{
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
}>;

export type GetVpcsSubnetsQuery = {
  __typename?: "Query";
  vpcs?: {
    __typename?: "VpcsResponse";
    pages: number;
    count: number;
    results?: Array<{
      __typename?: "Vpc";
      nid: string;
      vpcId: string;
      subnets?: Array<{ __typename?: "Subnet"; nid: string; subnetId: string }> | null;
    }> | null;
  } | null;
};

export type GetTagsByKeyQueryVariables = Exact<{
  key: Scalars["String"];
  limit: Scalars["Int"];
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetTagsByKeyQuery = {
  __typename?: "Query";
  searchTagKeys?: {
    __typename?: "SearchTagKeysResponse";
    keys: Array<string>;
    next?: string | null;
  } | null;
};

export type GetTagsByValueQueryVariables = Exact<{
  key: Scalars["String"];
  value: Scalars["String"];
  limit: Scalars["Int"];
  after?: InputMaybe<Scalars["String"]>;
}>;

export type GetTagsByValueQuery = {
  __typename?: "Query";
  searchTagValues?: {
    __typename?: "SearchTagValuesResponse";
    values: Array<string>;
    next?: string | null;
  } | null;
};

type KpiWidget_KpiCostAllocationCoverage_Fragment = {
  __typename?: "KpiCostAllocationCoverage";
  details: {
    __typename?: "KpiDetails";
    targets?: {
      __typename?: "KpiTargets";
      currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
    } | null;
    metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
  };
};

type KpiWidget_KpiNonProdHoursBusinessContext_Fragment = {
  __typename?: "KpiNonProdHoursBusinessContext";
  details: {
    __typename?: "KpiDetails";
    targets?: {
      __typename?: "KpiTargets";
      currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
    } | null;
    metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
  };
};

type KpiWidget_KpiOverallWasteBusinessContext_Fragment = {
  __typename?: "KpiOverallWasteBusinessContext";
  details: {
    __typename?: "KpiDetails";
    targets?: {
      __typename?: "KpiTargets";
      currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
    } | null;
    metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
  };
};

export type KpiWidgetFragment =
  | KpiWidget_KpiCostAllocationCoverage_Fragment
  | KpiWidget_KpiNonProdHoursBusinessContext_Fragment
  | KpiWidget_KpiOverallWasteBusinessContext_Fragment;

export type CustomerConfigQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerConfigQuery = {
  __typename?: "Query";
  customerConfig?: {
    __typename?: "CustomerConfig";
    id: string;
    riEnabled?: boolean | null;
    allowExperimental?: boolean | null;
    defaultCurrency?: string | null;
    riCoverageTarget?: number | null;
    enabledFeatures?: Array<string> | null;
    anomalyThreshold?: number | null;
  } | null;
};

export type AddAccountV2MutationVariables = Exact<{
  input: AddAccountV2;
}>;

export type AddAccountV2Mutation = {
  __typename?: "Mutation";
  addAccountV2?: { __typename?: "MutationResponse"; id?: string | null } | null;
};

export type UpdateAccountV2MutationVariables = Exact<{
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  ownerId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Provider>;
  providerId?: InputMaybe<Scalars["ID"]>;
  contactIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type UpdateAccountV2Mutation = { __typename?: "Mutation"; updateAccountV2?: boolean | null };

export type UpdateAwsAccountCredentialsMutationVariables = Exact<{
  id: Scalars["ID"];
  roles: Array<AwsRole> | AwsRole;
  additionalCredentials?: InputMaybe<AwsAdditionalCredentials>;
}>;

export type UpdateAwsAccountCredentialsMutation = {
  __typename?: "Mutation";
  updateAwsAccountCredentials?: boolean | null;
};

export type UpdateAzureAccountCredentialsMutationVariables = Exact<{
  id: Scalars["ID"];
  tenantId: Scalars["ID"];
  appId: Scalars["ID"];
  password: Scalars["String"];
}>;

export type UpdateAzureAccountCredentialsMutation = {
  __typename?: "Mutation";
  updateAzureAccountCredentials?: boolean | null;
};

export type UpdateGcpAccountCredentialsMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateGcpAccountCredentialsInput;
}>;

export type UpdateGcpAccountCredentialsMutation = {
  __typename?: "Mutation";
  updateGcpAccountCredentials?: boolean | null;
};

export type UpdateIbmAccountCredentialsMutationVariables = Exact<{
  id: Scalars["ID"];
  apikey: Scalars["ID"];
}>;

export type UpdateIbmAccountCredentialsMutation = {
  __typename?: "Mutation";
  updateIbmAccountCredentials?: boolean | null;
};

export type RemoveAccountV2MutationVariables = Exact<{
  nid: Scalars["ID"];
}>;

export type RemoveAccountV2Mutation = { __typename?: "Mutation"; removeAccountV2?: boolean | null };

export type OrgUnitActionDetailsFragment = {
  __typename?: "OrgUnitV2";
  id: string;
  name: string;
  description?: string | null;
  parentNid?: string | null;
  type?: {
    __typename?: "OrgUnitTypeV2";
    id: string;
    name: string;
    description?: string | null;
  } | null;
  contact?: { __typename?: "Person"; id: string; name: string; email: string } | null;
};

export type AddOrgUnitMutationVariables = Exact<{
  input: AddOrgUnitV2;
}>;

export type AddOrgUnitMutation = {
  __typename?: "Mutation";
  addOrgUnitV3: {
    __typename?: "OrgUnitV2";
    id: string;
    name: string;
    description?: string | null;
    parentNid?: string | null;
    type?: {
      __typename?: "OrgUnitTypeV2";
      id: string;
      name: string;
      description?: string | null;
    } | null;
    contact?: { __typename?: "Person"; id: string; name: string; email: string } | null;
  };
};

export type RemoveOrgUnitMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveOrgUnitMutation = { __typename?: "Mutation"; removeOrgUnitV2?: boolean | null };

export type UpdateOrgUnitMutationVariables = Exact<{
  nid: Scalars["ID"];
  input: UpdateOrgUnitV2;
}>;

export type UpdateOrgUnitMutation = {
  __typename?: "Mutation";
  updateOrgUnitV4: {
    __typename?: "UpdateOrgUnitV4Response";
    orgUnit?: {
      __typename?: "OrgUnitV2";
      id: string;
      name: string;
      description?: string | null;
      parentNid?: string | null;
      type?: {
        __typename?: "OrgUnitTypeV2";
        id: string;
        name: string;
        description?: string | null;
      } | null;
      contact?: { __typename?: "Person"; id: string; name: string; email: string } | null;
    } | null;
    userErrors?: {
      __typename?: "OrgUnitUserError";
      message: string;
      code: OrgUnitUserErrorCode;
    } | null;
  };
};

export type AddPersonMutationVariables = Exact<{
  name: Scalars["String"];
  email: Scalars["String"];
}>;

export type AddPersonMutation = {
  __typename?: "Mutation";
  addPersonV2: { __typename?: "Person"; id: string; name: string; email: string };
};

export type RemovePersonMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type RemovePersonMutation = { __typename?: "Mutation"; removePerson?: boolean | null };

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
}>;

export type UpdatePersonMutation = {
  __typename?: "Mutation";
  updatePersonV2: { __typename?: "Person"; id: string; name: string; email: string };
};

export type ResourceTypesQueryVariables = Exact<{
  provider?: InputMaybe<Array<Provider> | Provider>;
  categoryIDs?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type ResourceTypesQuery = { __typename?: "Query"; resourceTypes: Array<string> };

export type NotificationListQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars["String"]>;
  limit: Scalars["Int"];
}>;

export type NotificationListQuery = {
  __typename?: "Query";
  notifications?: {
    __typename?: "NotificationsResponse";
    cursor?: string | null;
    notifications?: Array<{
      __typename?: "Notification";
      id: string;
      type: NotificationType;
      title: string;
      details: string;
      targetView?: string | null;
      read: boolean;
      createdAt: string;
    }> | null;
  } | null;
};

export type MarkNotificationsAsReadMutationVariables = Exact<{
  ids: Array<Scalars["String"]> | Scalars["String"];
}>;

export type MarkNotificationsAsReadMutation = {
  __typename?: "Mutation";
  markNotificationsAsRead?: boolean | null;
};

export type TriggerRecalculationMutationVariables = Exact<{ [key: string]: never }>;

export type TriggerRecalculationMutation = {
  __typename?: "Mutation";
  triggerRecalculation?: boolean | null;
};

export type GetCustomerDirtinessQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerDirtinessQuery = {
  __typename?: "Query";
  customerDirtiness: { __typename?: "Dirtiness"; isDirty: boolean };
};

export type GetRecalculationStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecalculationStatusQuery = {
  __typename?: "Query";
  recalculations: Array<{ __typename?: "Recalculation"; status: RecalculationStatus } | null>;
};

export type AccumulatedCostValueFragment = {
  __typename?: "AccumulatedCostResponse";
  total: string;
  timePoints: Array<{ __typename?: "TimePoint"; date: string; value: string }>;
};

export type CustomerCorrectionTimepointsFragment = {
  __typename?: "CustomerCorrectionResponse";
  timePoints?: Array<{
    __typename?: "CustomerCorrectionTimePoint";
    date: string;
    value: string;
  } | null> | null;
};

export type AccumulatedCostQueryVariables = Exact<{
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type AccumulatedCostQuery = {
  __typename?: "Query";
  accumulatedCost: {
    __typename?: "AccumulatedCostResponse";
    total: string;
    timePoints: Array<{ __typename?: "TimePoint"; date: string; value: string }>;
  };
  accumulatedCostTable: {
    __typename?: "AccumulatedCostResponse";
    total: string;
    timePoints: Array<{ __typename?: "TimePoint"; date: string; value: string }>;
  };
  customerCorrectionCost: {
    __typename?: "CustomerCorrectionResponse";
    timePoints?: Array<{
      __typename?: "CustomerCorrectionTimePoint";
      date: string;
      value: string;
    } | null> | null;
  };
};

export type ApplicationsCostValueFragment = {
  __typename?: "CustomerApplicationsCostResponse";
  count?: number | null;
  pages?: number | null;
  timePoints: Array<{
    __typename?: "CustomerGroupTimePoint";
    date: string;
    value: string;
    totalCost: string;
    groups?: Array<{
      __typename?: "EnvGroup";
      id: string;
      name: string;
      value: string;
    } | null> | null;
  }>;
};

export type ApplicationsCostAnalysisQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type ApplicationsCostAnalysisQuery = {
  __typename?: "Query";
  customerApplicationsCost: {
    __typename?: "CustomerApplicationsCostResponse";
    count?: number | null;
    pages?: number | null;
    timePoints: Array<{
      __typename?: "CustomerGroupTimePoint";
      date: string;
      value: string;
      totalCost: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    }>;
  };
  customerApplicationsTableCost: {
    __typename?: "CustomerApplicationsCostResponse";
    count?: number | null;
    pages?: number | null;
    timePoints: Array<{
      __typename?: "CustomerGroupTimePoint";
      date: string;
      value: string;
      totalCost: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    }>;
  };
  customerCorrectionCost: {
    __typename?: "CustomerCorrectionResponse";
    timePoints?: Array<{
      __typename?: "CustomerCorrectionTimePoint";
      date: string;
      value: string;
    } | null> | null;
  };
};

export type ApplicationsCostPerCategoryValueFragment = {
  __typename?: "CustomerCategoriesCostResponse";
  count?: number | null;
  pages?: number | null;
  timePoints?: Array<{
    __typename?: "CustomerGroupTimePoint";
    date: string;
    value: string;
    totalCost: string;
    groups?: Array<{
      __typename?: "EnvGroup";
      id: string;
      name: string;
      value: string;
    } | null> | null;
  } | null> | null;
};

export type ApplicationsCostPerCategoryQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type ApplicationsCostPerCategoryQuery = {
  __typename?: "Query";
  customerCategoriesCost: {
    __typename?: "CustomerCategoriesCostResponse";
    count?: number | null;
    pages?: number | null;
    timePoints?: Array<{
      __typename?: "CustomerGroupTimePoint";
      date: string;
      value: string;
      totalCost: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
  customerCategoriesTableCost: {
    __typename?: "CustomerCategoriesCostResponse";
    count?: number | null;
    pages?: number | null;
    timePoints?: Array<{
      __typename?: "CustomerGroupTimePoint";
      date: string;
      value: string;
      totalCost: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
  customerCorrectionCost: {
    __typename?: "CustomerCorrectionResponse";
    timePoints?: Array<{
      __typename?: "CustomerCorrectionTimePoint";
      date: string;
      value: string;
    } | null> | null;
  };
};

export type ApplicationsCostPerServicesValueFragment = {
  __typename?: "CustomerServicesCostResponse";
  count?: number | null;
  pages?: number | null;
  timePoints?: Array<{
    __typename?: "CustomerGroupTimePoint";
    date: string;
    value: string;
    totalCost: string;
    groups?: Array<{
      __typename?: "EnvGroup";
      id: string;
      name: string;
      value: string;
    } | null> | null;
  } | null> | null;
};

export type ApplicationsCostPerServicesValueV2Fragment = {
  __typename?: "CustomerServicesCostResponseV2";
  count?: number | null;
  pages?: number | null;
  timePoints?: Array<{
    __typename?: "CustomerServiceGroupTimePoint";
    date: string;
    value: string;
    totalCost: string;
    groups?: Array<{
      __typename?: "CustomerServiceGroup";
      id: string;
      name: string;
      value: string;
      types?: Array<string | null> | null;
    } | null> | null;
  } | null> | null;
};

export type ApplicationsCostPerServiceQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type ApplicationsCostPerServiceQuery = {
  __typename?: "Query";
  customerServicesCost: {
    __typename?: "CustomerServicesCostResponse";
    count?: number | null;
    pages?: number | null;
    timePoints?: Array<{
      __typename?: "CustomerGroupTimePoint";
      date: string;
      value: string;
      totalCost: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
  customerServicesTableCost: {
    __typename?: "CustomerServicesCostResponseV2";
    count?: number | null;
    pages?: number | null;
    timePoints?: Array<{
      __typename?: "CustomerServiceGroupTimePoint";
      date: string;
      value: string;
      totalCost: string;
      groups?: Array<{
        __typename?: "CustomerServiceGroup";
        id: string;
        name: string;
        value: string;
        types?: Array<string | null> | null;
      } | null> | null;
    } | null> | null;
  };
  customerCorrectionCost: {
    __typename?: "CustomerCorrectionResponse";
    timePoints?: Array<{
      __typename?: "CustomerCorrectionTimePoint";
      date: string;
      value: string;
    } | null> | null;
  };
};

export type ApplicationFormDataQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type ApplicationFormDataQuery = {
  __typename?: "Query";
  application?: {
    __typename?: "Application";
    id: string;
    nid: string;
    owner?: { __typename?: "Person"; id: string; name: string } | null;
    environments?: Array<{
      __typename?: "Environment";
      id: string;
      nid: string;
      name: string;
      environmentType: EnvironmentType;
      description?: string | null;
      contacts?: Array<{ __typename?: "Person"; id: string } | null> | null;
    } | null> | null;
  } | null;
};

export type ApplicationsPaginatedQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  year: Scalars["String"];
}>;

export type ApplicationsPaginatedQuery = {
  __typename?: "Query";
  applicationsPaginated?: {
    __typename?: "ApplicationsPaginatedResponse";
    pages?: number | null;
    count?: number | null;
    results?: Array<{
      __typename?: "Application";
      name: string;
      id: string;
      resourceCount: number;
      hasAnomalies?: boolean | null;
      co2e?: string | null;
      budgetYearly: {
        __typename?: "BudgetYearly";
        id: string;
        budgetByMonth: Array<string>;
        yearlySum: string;
      };
      cloudWasteAggregate?: {
        __typename?: "CloudWasteAggregate";
        hasWaste?: boolean | null;
        cost?: string | null;
        count?: number | null;
        co2e?: string | null;
      } | null;
      cost?: {
        __typename?: "Cost";
        currentMonth: string;
        previousMonth: string;
        forecast?: string | null;
      } | null;
      environmentsV2?: { __typename?: "EnvironmentResponse"; count?: number | null } | null;
    }> | null;
  } | null;
};

export type ApplicationEnvironmentQueryVariables = Exact<{
  id: Scalars["String"];
  year: Scalars["String"];
  limit: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type ApplicationEnvironmentQuery = {
  __typename?: "Query";
  application?: {
    __typename?: "Application";
    id: string;
    nid: string;
    environmentsV2?: {
      __typename?: "EnvironmentResponse";
      count?: number | null;
      pages?: number | null;
      environments?: Array<{
        __typename?: "Environment";
        id: string;
        name: string;
        nid: string;
        co2e?: string | null;
        resourceCount: number;
        budgetYearly: {
          __typename?: "BudgetYearly";
          id: string;
          budgetByMonth: Array<string>;
          yearlySum: string;
        };
        cloudWasteAggregate?: {
          __typename?: "CloudWasteAggregate";
          hasWaste?: boolean | null;
          cost?: string | null;
          count?: number | null;
          co2e?: string | null;
        } | null;
        cost?: {
          __typename?: "Cost";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
        } | null;
        orgUnitsV2?: {
          __typename?: "OrgUnitsV2Response";
          orgUnits?: Array<{
            __typename?: "OrgUnitV2";
            id: string;
            nid: string;
            name: string;
            businessContext: {
              __typename?: "BusinessContext";
              id: string;
              color?: string | null;
              name: string;
            };
          }> | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type NidEventsQueryVariables = Exact<{
  to: Scalars["AWSDateTime"];
  from: Scalars["AWSDateTime"];
  nid: Scalars["String"];
}>;

export type NidEventsQuery = {
  __typename?: "Query";
  events?: Array<{
    __typename?: "Event";
    id: string;
    title: string;
    type: string;
    content: string;
    createdAt: string;
  }> | null;
};

export type ApplicationBaseDetailsFragment = {
  __typename?: "Application";
  id: string;
  name: string;
  nid: string;
  description?: string | null;
  displayName: string;
  contacts?: Array<{
    __typename?: "Person";
    id: string;
    name: string;
    email: string;
  } | null> | null;
  owner?: { __typename?: "Person"; id: string; name: string } | null;
};

export type EnvironmentBaseDetailsFragment = {
  __typename?: "Environment";
  id: string;
  name: string;
  nid: string;
  description?: string | null;
  displayName: string;
  environmentType: EnvironmentType;
  contacts?: Array<{
    __typename?: "Person";
    id: string;
    name: string;
    email: string;
  } | null> | null;
  owner?: { __typename?: "Person"; id: string; name: string } | null;
};

export type ApplicationQueryVariables = Exact<{
  id: Scalars["String"];
  year: Scalars["String"];
}>;

export type ApplicationQuery = {
  __typename?: "Query";
  application?: {
    __typename?: "Application";
    co2e?: string | null;
    id: string;
    name: string;
    nid: string;
    description?: string | null;
    displayName: string;
    budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
    cloudWasteAggregate?: {
      __typename?: "CloudWasteAggregate";
      hasWaste?: boolean | null;
      cost?: string | null;
      co2e?: string | null;
      count?: number | null;
    } | null;
    cost?: {
      __typename?: "Cost";
      currentMonth: string;
      currentMonthDaily?: Array<string | null> | null;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    environments?: Array<{
      __typename?: "Environment";
      co2e?: string | null;
      resourceCount: number;
      id: string;
      name: string;
      nid: string;
      description?: string | null;
      displayName: string;
      environmentType: EnvironmentType;
      budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
      cloudWasteAggregate?: {
        __typename?: "CloudWasteAggregate";
        hasWaste?: boolean | null;
        cost?: string | null;
        count?: number | null;
        co2e?: string | null;
      } | null;
      orgUnitsV2?: {
        __typename?: "OrgUnitsV2Response";
        orgUnits?: Array<{
          __typename?: "OrgUnitV2";
          id: string;
          nid: string;
          name: string;
          businessContext: {
            __typename?: "BusinessContext";
            id: string;
            color?: string | null;
            name: string;
          };
        }> | null;
      } | null;
      cost?: {
        __typename?: "Cost";
        currentMonth: string;
        previousMonth: string;
        forecast?: string | null;
      } | null;
      contacts?: Array<{
        __typename?: "Person";
        id: string;
        name: string;
        email: string;
      } | null> | null;
      owner?: { __typename?: "Person"; id: string; name: string } | null;
    } | null> | null;
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
    owner?: { __typename?: "Person"; id: string; name: string } | null;
  } | null;
};

export type AddApplicationMutationVariables = Exact<{
  name: Scalars["String"];
  ownerId: Scalars["ID"];
  description?: InputMaybe<Scalars["String"]>;
  contactIds?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type AddApplicationMutation = {
  __typename?: "Mutation";
  addApplicationV2?: { __typename?: "MutationResponse"; id?: string | null } | null;
};

export type UpdateApplicationMutationVariables = Exact<{
  nid: Scalars["ID"];
  input: UpdateApplicationV2;
}>;

export type UpdateApplicationMutation = {
  __typename?: "Mutation";
  updateApplicationV3: {
    __typename?: "Application";
    id: string;
    name: string;
    nid: string;
    description?: string | null;
    displayName: string;
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
    owner?: { __typename?: "Person"; id: string; name: string } | null;
  };
};

export type RemoveApplicationMutationVariables = Exact<{
  nid: Scalars["ID"];
}>;

export type RemoveApplicationMutation = {
  __typename?: "Mutation";
  removeApplicationV2?: boolean | null;
};

export type UpdateEnvironmentMutationVariables = Exact<{
  nid: Scalars["ID"];
  input: UpdateEnvironmentV2;
}>;

export type UpdateEnvironmentMutation = {
  __typename?: "Mutation";
  updateEnvironmentV3: {
    __typename?: "Environment";
    id: string;
    name: string;
    nid: string;
    description?: string | null;
    displayName: string;
    environmentType: EnvironmentType;
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
    owner?: { __typename?: "Person"; id: string; name: string } | null;
  };
};

export type AddEnvironmentMutationVariables = Exact<{
  input: AddEnvironmentV2;
}>;

export type AddEnvironmentMutation = {
  __typename?: "Mutation";
  addEnvironmentV3: {
    __typename?: "Environment";
    id: string;
    name: string;
    nid: string;
    description?: string | null;
    displayName: string;
    environmentType: EnvironmentType;
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
    owner?: { __typename?: "Person"; id: string; name: string } | null;
  };
};

export type GetDefaultApplicationQueryVariables = Exact<{ [key: string]: never }>;

export type GetDefaultApplicationQuery = {
  __typename?: "Query";
  defaultApplication?: {
    __typename?: "Application";
    nid: string;
    name: string;
    co2e?: string | null;
    id: string;
    cloudWasteAggregate?: {
      __typename?: "CloudWasteAggregate";
      hasWaste?: boolean | null;
      cost?: string | null;
      count?: number | null;
      co2e?: string | null;
    } | null;
    cost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    environments?: Array<{
      __typename?: "Environment";
      id: string;
      name: string;
      resourceCount: number;
    } | null> | null;
  } | null;
};

export type GetAnomaliesQueryVariables = Exact<{
  input: AnomaliesQueryInput;
}>;

export type GetAnomaliesQuery = {
  __typename?: "Query";
  anomalies: Array<{
    __typename?: "Anomaly";
    anomaly: boolean;
    date: string;
    cost: number;
    forecastedCost?: number | null;
    upperCostEstimate?: number | null;
    lowerCostEstimate?: number | null;
    anomalyCost?: number | null;
  }>;
};

export type UpdateAnomalyThresholdMutationVariables = Exact<{
  input: UpdateAnomalyThreshold;
}>;

export type UpdateAnomalyThresholdMutation = {
  __typename?: "Mutation";
  updateAnomalyThreshold?: boolean | null;
};

export type GetAnomalyDetailsQueryVariables = Exact<{
  date: Scalars["String"];
  applicationId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
}>;

export type GetAnomalyDetailsQuery = {
  __typename?: "Query";
  anomalyV2: {
    __typename?: "AnomalyDetailsResponse";
    count: number;
    resources: Array<{
      __typename?: "AnomalyService";
      type: string;
      cost?: number | null;
      anomaly: boolean;
      provider?: Provider | null;
      anomalyCost?: number | null;
      minDailyCost?: number | null;
      maxDailyCost?: number | null;
      subRows?: Array<{
        __typename?: "AnomalyServiceSubtype";
        subType: string;
        cost: number;
        subRows: Array<{
          __typename?: "AnomalyEstateRecord";
          name?: string | null;
          nid?: string | null;
          cost?: number | null;
        }>;
      }> | null;
    }>;
  };
};

export type GlobalCostAllocationQueryVariables = Exact<{
  startDate: Scalars["String"];
  endDate: Scalars["String"];
}>;

export type GlobalCostAllocationQuery = {
  __typename?: "Query";
  globalMetrics?: {
    __typename?: "GlobalMetricsResponse";
    costAllocation?: {
      __typename?: "GlobalCostAllocationResponse";
      timeSeries?: Array<{
        __typename?: "CostAllocationTimeSeries";
        date: string;
        value: string;
        isEstimated: boolean;
      }> | null;
    } | null;
  } | null;
};

export type AccumulatedCostFragment = {
  __typename?: "ApplicationAccumulatedCostResponse";
  total: string;
  timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
};

export type GetApplicationAccumulatedCostsQueryVariables = Exact<{
  applicationId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type GetApplicationAccumulatedCostsQuery = {
  __typename?: "Query";
  applicationAccumulatedCost: {
    __typename?: "ApplicationAccumulatedCostResponse";
    total: string;
    timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
  };
  applicationAccumulatedTableCost: {
    __typename?: "ApplicationAccumulatedCostResponse";
    total: string;
    timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
  };
};

export type TimePointFragment = {
  __typename?: "GroupTimePoint";
  date: string;
  value: string;
  groups?: Array<{
    __typename?: "EnvGroup";
    id: string;
    name: string;
    value: string;
  } | null> | null;
};

export type GetApplicationTopEnvironmentsCostsQueryVariables = Exact<{
  top: Scalars["Int"];
  applicationId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type GetApplicationTopEnvironmentsCostsQuery = {
  __typename?: "Query";
  applicationTopEnvironmentsCost: {
    __typename?: "ApplicationTopEnvironmentsCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
  applicationTopEnvironmentsTableCost: {
    __typename?: "ApplicationTopEnvironmentsCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type GetApplicationTopCategoriesCostsQueryVariables = Exact<{
  top: Scalars["Int"];
  applicationId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type GetApplicationTopCategoriesCostsQuery = {
  __typename?: "Query";
  applicationTopCategoriesCost: {
    __typename?: "ApplicationTopCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
  applicationTopCategoriesTableCost: {
    __typename?: "ApplicationTopCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type GetApplicationOtherCategoriesCostQueryVariables = Exact<{
  input: ApplicationOtherCategoriesCostInput;
}>;

export type GetApplicationOtherCategoriesCostQuery = {
  __typename?: "Query";
  applicationOtherCategoriesCost: {
    __typename?: "ApplicationOtherCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type BudgetFragment = {
  __typename?: "BudgetYearly";
  id: string;
  budgetByMonth: Array<string>;
  yearlySum: string;
};

export type ApplicationBudgetQueryVariables = Exact<{
  id: Scalars["String"];
  year: Scalars["String"];
  previousYear: Scalars["String"];
}>;

export type ApplicationBudgetQuery = {
  __typename?: "Query";
  application?: {
    __typename?: "Application";
    id: string;
    nid: string;
    environments?: Array<{
      __typename?: "Environment";
      id: string;
      nid: string;
      budgetYearly: {
        __typename?: "BudgetYearly";
        id: string;
        budgetByMonth: Array<string>;
        yearlySum: string;
      };
    } | null> | null;
    budgetYearly: {
      __typename?: "BudgetYearly";
      id: string;
      budgetByMonth: Array<string>;
      yearlySum: string;
    };
    spendYearly: { __typename?: "SpendYearly"; spends: Array<string> };
    forecastSpendYearly: { __typename?: "ForecastSpendYearly"; spends: Array<string> };
  } | null;
};

export type SetApplicationYearlyBudgetMutationVariables = Exact<{
  budget: BudgetYearlyInput;
  nid: Scalars["ID"];
}>;

export type SetApplicationYearlyBudgetMutation = {
  __typename?: "Mutation";
  setApplicationYearlyBudget: {
    __typename?: "BudgetYearly";
    id: string;
    budgetByMonth: Array<string>;
    yearlySum: string;
  };
};

export type ApplicationsPaginatedV2QueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  year: Scalars["String"];
}>;

export type ApplicationsPaginatedV2Query = {
  __typename?: "Query";
  applicationsPaginated?: {
    __typename?: "ApplicationsPaginatedResponse";
    pages?: number | null;
    count?: number | null;
    results?: Array<{
      __typename?: "Application";
      name: string;
      id: string;
      resourceCount: number;
      description?: string | null;
      owner?: { __typename?: "Person"; id: string; name: string; email: string } | null;
      contacts?: Array<{
        __typename?: "Person";
        id: string;
        name: string;
        email: string;
      } | null> | null;
      budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
      cloudWasteAggregate?: { __typename?: "CloudWasteAggregate"; cost?: string | null } | null;
      cost?: { __typename?: "Cost"; currentMonth: string; forecast?: string | null } | null;
      environmentsV2?: { __typename?: "EnvironmentResponse"; count?: number | null } | null;
      finOpsOptimisation: {
        __typename?: "FinOpsOptimisation";
        optimisationLevel: OptimisationLevel;
        contactPersonAdded: boolean;
        ownerAdded: boolean;
        budgetAdded: boolean;
        environmentsAttachedToOUs: boolean;
        environmentsTypesDefined: boolean;
        savingSuggestions: {
          __typename?: "SavingSuggestionsOptimisation";
          savingSuggestionsUtilised: boolean;
          savingSuggestionsValue: string;
        };
      };
    }> | null;
  } | null;
};

export type UnallocatedSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type UnallocatedSummaryQuery = {
  __typename?: "Query";
  defaultApplication?: {
    __typename?: "Application";
    name: string;
    id: string;
    cloudWasteAggregate?: {
      __typename?: "CloudWasteAggregate";
      hasWaste?: boolean | null;
      cost?: string | null;
    } | null;
    cost?: { __typename?: "Cost"; currentMonth: string; forecast?: string | null } | null;
  } | null;
  defaultEnvironment?: {
    __typename?: "Environment";
    nid: string;
    resourceCount: number;
    id: string;
  } | null;
};

export type ApplicationEnvironmentFragment = {
  __typename?: "Environment";
  name: string;
  nid: string;
  co2e?: string | null;
  resourceCount: number;
  budgetYearly: {
    __typename?: "BudgetYearly";
    id: string;
    budgetByMonth: Array<string>;
    yearlySum: string;
  };
  cloudWasteAggregate?: {
    __typename?: "CloudWasteAggregate";
    hasWaste?: boolean | null;
    cost?: string | null;
    count?: number | null;
    co2e?: string | null;
  } | null;
  cost?: {
    __typename?: "Cost";
    currentMonth: string;
    previousMonth: string;
    forecast?: string | null;
  } | null;
  orgUnitsV2?: {
    __typename?: "OrgUnitsV2Response";
    orgUnits?: Array<{
      __typename?: "OrgUnitV2";
      id: string;
      nid: string;
      name: string;
      businessContext: {
        __typename?: "BusinessContext";
        id: string;
        color?: string | null;
        name: string;
      };
    }> | null;
  } | null;
};

export type ApplicationEnvironmentsPaginatedV2QueryVariables = Exact<{
  id: Scalars["String"];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  year: Scalars["String"];
}>;

export type ApplicationEnvironmentsPaginatedV2Query = {
  __typename?: "Query";
  application?: {
    __typename?: "Application";
    id: string;
    environmentsV2?: {
      __typename?: "EnvironmentResponse";
      count?: number | null;
      pages?: number | null;
      environments?: Array<{
        __typename?: "Environment";
        id: string;
        nid: string;
        name: string;
        displayName: string;
        cloudWasteAggregate?: {
          __typename?: "CloudWasteAggregate";
          hasWaste?: boolean | null;
          cost?: string | null;
        } | null;
        cost?: { __typename?: "Cost"; currentMonth: string; forecast?: string | null } | null;
        budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
        orgUnitsV2?: {
          __typename?: "OrgUnitsV2Response";
          pages?: number | null;
          count?: number | null;
          orgUnits?: Array<{
            __typename?: "OrgUnitV2";
            name: string;
            id: string;
            parentNid?: string | null;
            businessContext: { __typename?: "BusinessContext"; id: string; color?: string | null };
          }> | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetMonthlyReportQueryVariables = Exact<{
  period: Scalars["String"];
  reportType: ReportType;
  businessContextId?: InputMaybe<Scalars["String"]>;
}>;

export type GetMonthlyReportQuery = {
  __typename?: "Query";
  monthlyReport?: {
    __typename?: "MonthlyReportResponse";
    status: ReportStatus;
    fileUrl?: string | null;
    error?: string | null;
    jobId: string;
    reportType: ReportType;
  } | null;
};

export type CustomerCreatedAtQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerCreatedAtQuery = {
  __typename?: "Query";
  organization?: { __typename?: "Organization"; id: string; createdAt: string } | null;
};

export type BusinessContextsMinimalQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type BusinessContextsMinimalQuery = {
  __typename?: "Query";
  businessContexts?: {
    __typename?: "BusinessContextResponse";
    pages?: number | null;
    count?: number | null;
    businessContexts?: Array<{ __typename?: "BusinessContext"; id: string; name: string }> | null;
  } | null;
};

export type OrgUnitTypesV2QueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type OrgUnitTypesV2Query = {
  __typename?: "Query";
  orgUnitTypesV2?: {
    __typename?: "OrgUnitTypesV2Response";
    pages?: number | null;
    count?: number | null;
    types?: Array<{
      __typename?: "OrgUnitTypeV2";
      id: string;
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type AddOrgUnitTypeMutationVariables = Exact<{
  input: AddOrgUnitTypeV2;
}>;

export type AddOrgUnitTypeMutation = {
  __typename?: "Mutation";
  addOrgUnitTypeV3: { __typename?: "OrgUnitTypeV2"; id: string };
};

export type RemoveOrgUnitTypeMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveOrgUnitTypeMutation = {
  __typename?: "Mutation";
  removeOrgUnitTypeV2?: boolean | null;
};

export type UpdateOrgUnitTypeMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateOrgUnitTypeV2;
}>;

export type UpdateOrgUnitTypeMutation = {
  __typename?: "Mutation";
  updateOrgUnitTypeV3: {
    __typename?: "OrgUnitTypeV2";
    id: string;
    name: string;
    description?: string | null;
  };
};

export type AddBusinessContextMutationVariables = Exact<{
  input: AddBusinessContext;
}>;

export type AddBusinessContextMutation = {
  __typename?: "Mutation";
  addBusinessContextV2: {
    __typename?: "BusinessContext";
    id: string;
    name: string;
    description?: string | null;
    color?: string | null;
    isPrimary?: boolean | null;
  };
};

export type BusinessContextsQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type BusinessContextsQuery = {
  __typename?: "Query";
  businessContexts?: {
    __typename?: "BusinessContextResponse";
    pages?: number | null;
    count?: number | null;
    businessContexts?: Array<{
      __typename?: "BusinessContext";
      id: string;
      name: string;
      color?: string | null;
      isPrimary?: boolean | null;
    }> | null;
  } | null;
};

export type BusinessContextQueryVariables = Exact<{
  id: Scalars["ID"];
  year: Scalars["String"];
}>;

export type BusinessContextQuery = {
  __typename?: "Query";
  businessContext?: {
    __typename?: "BusinessContext";
    id: string;
    name: string;
    description?: string | null;
    color?: string | null;
    isPrimary?: boolean | null;
    orgUnits?: {
      __typename?: "OrgUnitsV2NonPaginatedResponse";
      count?: number | null;
      orgUnits?: Array<{
        __typename?: "OrgUnitV2";
        id: string;
        nid: string;
        name: string;
        description?: string | null;
        parentNid?: string | null;
        environments?: { __typename?: "EnvironmentResponse"; count?: number | null } | null;
        businessContext: { __typename?: "BusinessContext"; id: string };
        budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
        cost?: {
          __typename?: "Cost";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
        } | null;
        type?: { __typename?: "OrgUnitTypeV2"; id: string; name: string } | null;
        contact?: { __typename?: "Person"; id: string; name: string } | null;
      }> | null;
    } | null;
  } | null;
};

export type GetOrgUnitTypesV2QueryVariables = Exact<{ [key: string]: never }>;

export type GetOrgUnitTypesV2Query = {
  __typename?: "Query";
  orgUnitTypesV2?: {
    __typename?: "OrgUnitTypesV2Response";
    types?: Array<{ __typename?: "OrgUnitTypeV2"; id: string; name: string }> | null;
  } | null;
};

export type SetPrimaryBusinessContextMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type SetPrimaryBusinessContextMutation = {
  __typename?: "Mutation";
  setPrimaryBusinessContext?: boolean | null;
};

export type AddEnvironmentsToOrgUnitMutationVariables = Exact<{
  input: UpdateOrgUnitEnvironmentsBodyInput;
}>;

export type AddEnvironmentsToOrgUnitMutation = {
  __typename?: "Mutation";
  addEnvironmentsToOrgUnit?: boolean | null;
};

export type GetOrgUnitWithChildrenQueryVariables = Exact<{
  id: Scalars["ID"];
  year: Scalars["String"];
}>;

export type GetOrgUnitWithChildrenQuery = {
  __typename?: "Query";
  orgUnitWithChildren?: {
    __typename?: "OrgUnitWithChildren";
    id: string;
    nid: string;
    name: string;
    description?: string | null;
    businessContext: { __typename?: "BusinessContext"; id: string; name: string };
    type?: { __typename?: "OrgUnitTypeV2"; id: string; name: string } | null;
    contact?: { __typename?: "Person"; id: string; name: string; email: string } | null;
    co2e?: {
      __typename?: "Co2e";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    cost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    cloudWasteAggregate?: {
      __typename?: "CloudWasteAggregate";
      co2e?: string | null;
      cost?: string | null;
      count?: number | null;
      hasWaste?: boolean | null;
    } | null;
    budgetYearly: {
      __typename?: "BudgetYearly";
      id: string;
      budgetByMonth: Array<string>;
      yearlySum: string;
    };
    children?: Array<{
      __typename?: "OrgUnitWithChildren";
      id: string;
      nid: string;
      name: string;
      parentNid?: string | null;
      businessContext: { __typename?: "BusinessContext"; id: string };
      cost?: {
        __typename?: "Cost";
        currentMonth: string;
        previousMonth: string;
        forecast?: string | null;
      } | null;
      budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
    }> | null;
  } | null;
};

export type OrgUnitApplicationsQueryVariables = Exact<{
  orgUnitId: Scalars["ID"];
  year: Scalars["String"];
  limit: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type OrgUnitApplicationsQuery = {
  __typename?: "Query";
  orgUnitApplications?: {
    __typename?: "OrgUnitApplicationsResponse";
    count?: number | null;
    pages?: number | null;
    results?: Array<{
      __typename?: "OrgUnitApplication";
      id: string;
      name: string;
      displayName: string;
      environments: Array<{
        __typename?: "OrgUnitApplicationEnvironment";
        id: string;
        name: string;
        displayName: string;
        resourceCount: number;
        budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
        cost?: {
          __typename?: "Cost";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
        } | null;
        cloudWasteAggregate?: {
          __typename?: "CloudWasteAggregate";
          co2e?: string | null;
          cost?: string | null;
          count?: number | null;
          hasWaste?: boolean | null;
        } | null;
      }>;
      cost?: {
        __typename?: "Cost";
        currentMonth: string;
        previousMonth: string;
        forecast?: string | null;
      } | null;
      budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
    }> | null;
  } | null;
};

export type GetAppsWithEnvironmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppsWithEnvironmentsQuery = {
  __typename?: "Query";
  applications?: Array<{
    __typename?: "Application";
    name: string;
    id: string;
    nid: string;
    environments?: Array<{
      __typename?: "Environment";
      id: string;
      nid: string;
      name: string;
      resourceCount: number;
      orgUnitsV2?: {
        __typename?: "OrgUnitsV2Response";
        orgUnits?: Array<{
          __typename?: "OrgUnitV2";
          id: string;
          nid: string;
          name: string;
          businessContext: { __typename?: "BusinessContext"; id: string; name: string };
        }> | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type OrgUnitBaseDetailsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrgUnitBaseDetailsQuery = {
  __typename?: "Query";
  orgUnitV2?: {
    __typename?: "OrgUnitV2";
    id: string;
    nid: string;
    name: string;
    description?: string | null;
    type?: { __typename?: "OrgUnitTypeV2"; id: string; name: string } | null;
    contact?: { __typename?: "Person"; id: string; name: string } | null;
  } | null;
};

export type GetOrgUnitTypeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOrgUnitTypeQuery = {
  __typename?: "Query";
  orgUnitV2?: {
    __typename?: "OrgUnitV2";
    id: string;
    type?: { __typename?: "OrgUnitTypeV2"; id: string; name: string } | null;
  } | null;
};

type OrgUnitKpiWidget_KpiNonProdHoursForOrgUnit_Fragment = {
  __typename?: "KpiNonProdHoursForOrgUnit";
  details: {
    __typename?: "KpiDetails";
    targets?: {
      __typename?: "KpiTargets";
      currentTarget?: {
        __typename?: "KpiTarget";
        id: string;
        name: string;
        goal: number;
        startDate: string;
        endDate: string;
        contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
      } | null;
    } | null;
    metrics?: {
      __typename?: "KpiMetric";
      latestTimePoint?: {
        __typename?: "EnhancedTimePoint";
        value: string;
        additionalFields?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
      } | null;
    } | null;
  };
};

type OrgUnitKpiWidget_KpiOverallWasteForOrgUnit_Fragment = {
  __typename?: "KpiOverallWasteForOrgUnit";
  details: {
    __typename?: "KpiDetails";
    targets?: {
      __typename?: "KpiTargets";
      currentTarget?: {
        __typename?: "KpiTarget";
        id: string;
        name: string;
        goal: number;
        startDate: string;
        endDate: string;
        contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
      } | null;
    } | null;
    metrics?: {
      __typename?: "KpiMetric";
      latestTimePoint?: {
        __typename?: "EnhancedTimePoint";
        value: string;
        additionalFields?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
      } | null;
    } | null;
  };
};

export type OrgUnitKpiWidgetFragment =
  | OrgUnitKpiWidget_KpiNonProdHoursForOrgUnit_Fragment
  | OrgUnitKpiWidget_KpiOverallWasteForOrgUnit_Fragment;

export type OrgUnitKpiDashboardQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type OrgUnitKpiDashboardQuery = {
  __typename?: "Query";
  orgUnitV2?: {
    __typename?: "OrgUnitV2";
    id: string;
    nid: string;
    kpis: {
      __typename?: "OrgUnitKpis";
      nonProdHours: {
        __typename?: "KpiNonProdHoursForOrgUnit";
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: {
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
              contactPerson?: {
                __typename?: "Person";
                name: string;
                id: string;
                email: string;
              } | null;
            } | null;
          } | null;
          metrics?: {
            __typename?: "KpiMetric";
            latestTimePoint?: {
              __typename?: "EnhancedTimePoint";
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            } | null;
          } | null;
        };
      };
      overallWaste: {
        __typename?: "KpiOverallWasteForOrgUnit";
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: {
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
              contactPerson?: {
                __typename?: "Person";
                name: string;
                id: string;
                email: string;
              } | null;
            } | null;
          } | null;
          metrics?: {
            __typename?: "KpiMetric";
            latestTimePoint?: {
              __typename?: "EnhancedTimePoint";
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            } | null;
          } | null;
        };
      };
    };
  } | null;
};

type OrgUnitKpiTimePoints_KpiNonProdHoursForOrgUnit_Fragment = {
  __typename?: "KpiNonProdHoursForOrgUnit";
  details: {
    __typename?: "KpiDetails";
    targets?: {
      __typename?: "KpiTargets";
      targets: Array<{
        __typename?: "KpiTarget";
        goal: number;
        name: string;
        id: string;
        startDate: string;
        endDate: string;
      }>;
    } | null;
    metrics?: {
      __typename?: "KpiMetric";
      values: Array<{
        __typename?: "EnhancedTimePoint";
        date: string;
        value: string;
        additionalFields?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
      }>;
    } | null;
  };
};

type OrgUnitKpiTimePoints_KpiOverallWasteForOrgUnit_Fragment = {
  __typename?: "KpiOverallWasteForOrgUnit";
  details: {
    __typename?: "KpiDetails";
    targets?: {
      __typename?: "KpiTargets";
      targets: Array<{
        __typename?: "KpiTarget";
        goal: number;
        name: string;
        id: string;
        startDate: string;
        endDate: string;
      }>;
    } | null;
    metrics?: {
      __typename?: "KpiMetric";
      values: Array<{
        __typename?: "EnhancedTimePoint";
        date: string;
        value: string;
        additionalFields?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
      }>;
    } | null;
  };
};

export type OrgUnitKpiTimePointsFragment =
  | OrgUnitKpiTimePoints_KpiNonProdHoursForOrgUnit_Fragment
  | OrgUnitKpiTimePoints_KpiOverallWasteForOrgUnit_Fragment;

export type OrgUnitKpiDataQueryVariables = Exact<{
  id: Scalars["ID"];
  period: KpiTimePeriod;
  fetchOverallWaste: Scalars["Boolean"];
  fetchNonProdHours: Scalars["Boolean"];
}>;

export type OrgUnitKpiDataQuery = {
  __typename?: "Query";
  orgUnitV2?: {
    __typename?: "OrgUnitV2";
    id: string;
    nid: string;
    kpis: {
      __typename?: "OrgUnitKpis";
      nonProdHours?: {
        __typename?: "KpiNonProdHoursForOrgUnit";
        worst: {
          __typename?: "KpiNonProdHoursOrgUnits";
          orgUnits?: Array<{
            __typename?: "KpiNonProdHoursOrgUnit";
            kpiValue: string;
            hours: string;
            orgUnit: {
              __typename?: "OrgUnitV2";
              id: string;
              nid: string;
              name: string;
              businessContext: { __typename?: "BusinessContext"; id: string };
            };
          }> | null;
        };
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            targets: Array<{
              __typename?: "KpiTarget";
              goal: number;
              name: string;
              id: string;
              startDate: string;
              endDate: string;
            }>;
          } | null;
          metrics?: {
            __typename?: "KpiMetric";
            values: Array<{
              __typename?: "EnhancedTimePoint";
              date: string;
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            }>;
          } | null;
        };
      };
      overallWaste?: {
        __typename?: "KpiOverallWasteForOrgUnit";
        worst: {
          __typename?: "KpiOverallWasteOrgUnits";
          orgUnits?: Array<{
            __typename?: "KpiOverallWasteOrgUnit";
            kpiValue: string;
            currentWaste: string;
            orgUnit: {
              __typename?: "OrgUnitV2";
              id: string;
              nid: string;
              name: string;
              businessContext: { __typename?: "BusinessContext"; id: string };
            };
          }> | null;
        };
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            targets: Array<{
              __typename?: "KpiTarget";
              goal: number;
              name: string;
              id: string;
              startDate: string;
              endDate: string;
            }>;
          } | null;
          metrics?: {
            __typename?: "KpiMetric";
            values: Array<{
              __typename?: "EnhancedTimePoint";
              date: string;
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            }>;
          } | null;
        };
      };
    };
  } | null;
};

export type OrgUnitBudgetQueryVariables = Exact<{
  nid: Scalars["ID"];
  year: Scalars["String"];
  previousYear: Scalars["String"];
}>;

export type OrgUnitBudgetQuery = {
  __typename?: "Query";
  orgUnitV2?: {
    __typename?: "OrgUnitV2";
    id: string;
    nid: string;
    budgetYearly: {
      __typename?: "BudgetYearly";
      id: string;
      budgetByMonth: Array<string>;
      yearlySum: string;
    };
    spendYearly: { __typename?: "SpendYearly"; spends: Array<string> };
    forecastSpendYearly: { __typename?: "ForecastSpendYearly"; spends: Array<string> };
  } | null;
};

export type OrgUnitChildrenBudgetQueryVariables = Exact<{
  nid: Scalars["ID"];
  year: Scalars["String"];
}>;

export type OrgUnitChildrenBudgetQuery = {
  __typename?: "Query";
  orgUnitWithChildren?: {
    __typename?: "OrgUnitWithChildren";
    id: string;
    nid: string;
    children?: Array<{
      __typename?: "OrgUnitWithChildren";
      id: string;
      budgetYearly: {
        __typename?: "BudgetYearly";
        id: string;
        budgetByMonth: Array<string>;
        yearlySum: string;
      };
    }> | null;
  } | null;
};

export type OrgUnitEnvironmentBudgetQueryVariables = Exact<{
  nid: Scalars["ID"];
  page: Scalars["Int"];
  year: Scalars["String"];
}>;

export type OrgUnitEnvironmentBudgetQuery = {
  __typename?: "Query";
  orgUnitWithChildren?: {
    __typename?: "OrgUnitWithChildren";
    id: string;
    nid: string;
    environments?: {
      __typename?: "EnvironmentResponse";
      pages?: number | null;
      environments?: Array<{
        __typename?: "Environment";
        id: string;
        nid: string;
        budgetYearly: {
          __typename?: "BudgetYearly";
          id: string;
          budgetByMonth: Array<string>;
          yearlySum: string;
        };
      }> | null;
    } | null;
  } | null;
};

export type OrganizationUnitAccumulatedCostFragment = {
  __typename?: "OrgUnitAccumulatedCostResponse";
  total: string;
  timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
};

export type OrgUnitAccumulatedCostQueryVariables = Exact<{
  orgUnitId: Scalars["ID"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type OrgUnitAccumulatedCostQuery = {
  __typename?: "Query";
  orgUnitAccumulatedCost: {
    __typename?: "OrgUnitAccumulatedCostResponse";
    total: string;
    timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
  };
  orgUnitAccumulatedCostTable: {
    __typename?: "OrgUnitAccumulatedCostResponse";
    total: string;
    timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
  };
};

export type SetOrgUnitYearlyBudgetMutationVariables = Exact<{
  budget: BudgetYearlyInput;
  nid: Scalars["ID"];
}>;

export type SetOrgUnitYearlyBudgetMutation = {
  __typename?: "Mutation";
  setOrgUnitBudget: {
    __typename?: "BudgetYearly";
    id: string;
    budgetByMonth: Array<string>;
    yearlySum: string;
  };
};

export type OrgUnitTopCategoriesCostsQueryVariables = Exact<{
  top: Scalars["Int"];
  orgUnitId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type OrgUnitTopCategoriesCostsQuery = {
  __typename?: "Query";
  orgUnitTopCategoriesCost: {
    __typename?: "OrgUnitTopCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
  orgUnitTopCategoriesTableCost: {
    __typename?: "OrgUnitTopCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type OrgUnitTopApplicationsCostsQueryVariables = Exact<{
  top: Scalars["Int"];
  orgUnitId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type OrgUnitTopApplicationsCostsQuery = {
  __typename?: "Query";
  orgUnitTopApplicationsCost: {
    __typename?: "OrgUnitTopApplicationsCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
  orgUnitTopApplicationsTableCost: {
    __typename?: "OrgUnitTopApplicationsCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type OrgUnitOtherCategoriesCostQueryVariables = Exact<{
  input: OrgUnitOtherCategoriesCostInput;
}>;

export type OrgUnitOtherCategoriesCostQuery = {
  __typename?: "Query";
  orgUnitOtherCategoriesCost: {
    __typename?: "OrgUnitOtherCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type UpdateBusinessContextMutationVariables = Exact<{
  id: Scalars["String"];
  input: UpdateBusinessContext;
}>;

export type UpdateBusinessContextMutation = {
  __typename?: "Mutation";
  updateBusinessContextV2: {
    __typename?: "BusinessContext";
    id: string;
    name: string;
    description?: string | null;
    color?: string | null;
  };
};

export type RemoveBusinessContextMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type RemoveBusinessContextMutation = {
  __typename?: "Mutation";
  removeBusinessContext?: boolean | null;
};

export type CloudAccountsQueryVariables = Exact<{
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CloudAccountsFilter>;
  order?: InputMaybe<CloudAccountsSort>;
  query?: InputMaybe<Scalars["String"]>;
}>;

export type CloudAccountsQuery = {
  __typename?: "Query";
  cloudAccounts?: {
    __typename?: "CloudAccountsResponse";
    count: number;
    pages: number;
    accounts?: Array<{
      __typename?: "Account";
      id: string;
      nid: string;
      provider: Provider;
      providerId: string;
      name: string;
      displayName?: string | null;
      description?: string | null;
      activated: boolean;
      metadata?: string | null;
      cloudStatusV2?: CloudAccountStatus | null;
      providerType?: CloudProviderType | null;
      chargeTypeCosts?: Array<{
        __typename?: "ChargeTypeCost";
        chargeType: string;
        cost: string;
      }> | null;
      contacts?: Array<{
        __typename?: "Person";
        id: string;
        name: string;
        email: string;
      } | null> | null;
      owner?: { __typename?: "Person"; id: string; name: string; email: string } | null;
    }> | null;
  } | null;
};

export type ChargeTypesQueryVariables = Exact<{
  provider: Scalars["String"];
}>;

export type ChargeTypesQuery = {
  __typename?: "Query";
  chargeTypes?: {
    __typename?: "ChargeTypesResponse";
    chargeTypes?: Array<{ __typename?: "ChargeType"; name: string }> | null;
  } | null;
};

export type CloudAccountsNidsQueryVariables = Exact<{
  filter?: InputMaybe<CloudAccountsFilter>;
}>;

export type CloudAccountsNidsQuery = {
  __typename?: "Query";
  cloudAccountNids?: {
    __typename?: "CloudAccountNidsResponse";
    count: number;
    accounts?: Array<{ __typename?: "CloudAccountNidItem"; nid: string }> | null;
  } | null;
};

export type AwsBillingDataQueryVariables = Exact<{ [key: string]: never }>;

export type AwsBillingDataQuery = {
  __typename?: "Query";
  awsBillingData: Array<{
    __typename?: "AWSBillingData";
    displayName?: string | null;
    accountId: string;
    nid?: string | null;
    isActive: boolean;
    isOnboarding: boolean;
    bucketId: string;
    region: string;
    reportName: string;
    reportPathPrefix: string;
  } | null>;
};

export type AzureBillingDataQueryVariables = Exact<{ [key: string]: never }>;

export type AzureBillingDataQuery = {
  __typename?: "Query";
  azureBillingData: Array<{
    __typename?: "AzureBillingData";
    isActive: boolean;
    enrollmentNumber: string;
    capacitorCustomerId: string;
    isOnboarding: boolean;
  } | null>;
};

export type GcpBillingDataQueryVariables = Exact<{ [key: string]: never }>;

export type GcpBillingDataQuery = {
  __typename?: "Query";
  gcpBillingData: Array<{
    __typename?: "GCPBillingData";
    isActive: boolean;
    organizationId: string;
    onboardedOrgId: string;
    projectId: string;
    isOnboarding: boolean;
    bigQueryDatasetName: string;
    bigQueryTableName: string;
    useDetailedBillingData: boolean;
  } | null>;
};

export type CloudAccountCostsByProviderQueryVariables = Exact<{ [key: string]: never }>;

export type CloudAccountCostsByProviderQuery = {
  __typename?: "Query";
  cloudAccountCostsByProvider?: {
    __typename?: "CloudAccountCostsByProviderResponse";
    costsByProvider?: Array<{
      __typename?: "CloudAccountCostByProvider";
      provider: string;
      cost: string;
      count: number;
      hasWarning: boolean;
    }> | null;
  } | null;
};

export type CloudAccountSavingsCostsQueryVariables = Exact<{
  cloudProviderId: Scalars["String"];
}>;

export type CloudAccountSavingsCostsQuery = {
  __typename?: "Query";
  cloudAccountSavingCosts: {
    __typename?: "CloudAccountSavingCosts";
    cost?: string | null;
    co2e?: string | null;
  };
};

export type CloudAccountCostsByChargeTypeQueryVariables = Exact<{
  filter?: InputMaybe<CloudAccountsFilter>;
  query?: InputMaybe<Scalars["String"]>;
}>;

export type CloudAccountCostsByChargeTypeQuery = {
  __typename?: "Query";
  cloudAccountCostsByChargeType?: {
    __typename?: "CloudAccountCostsByChargeTypeResponse";
    costsByChargeType?: Array<{
      __typename?: "CloudAccountCostByChargeType";
      chargeType: string;
      cost: string;
    }> | null;
  } | null;
};

export type AddGcpBillingConfigV2MutationVariables = Exact<{
  input: GcpBillingConfig;
}>;

export type AddGcpBillingConfigV2Mutation = {
  __typename?: "Mutation";
  addGCPBillingConfigV2: {
    __typename?: "GCPBillingData";
    onboardedOrgId: string;
    organizationId: string;
    projectId: string;
    isActive: boolean;
    isOnboarding: boolean;
    bigQueryDatasetName: string;
    bigQueryTableName: string;
    useDetailedBillingData: boolean;
  };
};

export type UpdateGcpBillingConfigV2MutationVariables = Exact<{
  input: UpdateGcpBillingConfig;
}>;

export type UpdateGcpBillingConfigV2Mutation = {
  __typename?: "Mutation";
  updateGcpBillingConfigV2: {
    __typename?: "GCPBillingData";
    onboardedOrgId: string;
    organizationId: string;
    projectId: string;
    isActive: boolean;
    isOnboarding: boolean;
    bigQueryDatasetName: string;
    bigQueryTableName: string;
    useDetailedBillingData: boolean;
  };
};

export type RemoveGcpBillingConfigV2MutationVariables = Exact<{
  onboardedOrgId: Scalars["String"];
}>;

export type RemoveGcpBillingConfigV2Mutation = {
  __typename?: "Mutation";
  removeGcpBillingConfigV2: { __typename?: "MutationResponse"; id?: string | null };
};

export type AddAwsBillingConfigMutationVariables = Exact<{
  input: AddAwsBillingConfig;
}>;

export type AddAwsBillingConfigMutation = {
  __typename?: "Mutation";
  addAWSBillingConfigV2: {
    __typename?: "AWSBillingData";
    nid?: string | null;
    accountId: string;
    displayName?: string | null;
    isActive: boolean;
    isOnboarding: boolean;
    bucketId: string;
    region: string;
    reportName: string;
    reportPathPrefix: string;
  };
};

export type RemoveAwsBillingConfigV2MutationVariables = Exact<{
  accountId: Scalars["ID"];
}>;

export type RemoveAwsBillingConfigV2Mutation = {
  __typename?: "Mutation";
  removeAwsBillingConfigV2: { __typename?: "MutationResponse"; id?: string | null };
};

export type AddAzureBillingConfigV2MutationVariables = Exact<{
  input: AddAzureBillingConfig;
}>;

export type AddAzureBillingConfigV2Mutation = {
  __typename?: "Mutation";
  addAzureBillingConfigV2: {
    __typename?: "AzureBillingData";
    capacitorCustomerId: string;
    enrollmentNumber: string;
    isActive: boolean;
    isOnboarding: boolean;
  };
};

export type UpdateAzureBillingConfigV2MutationVariables = Exact<{
  input: UpdateAzureBillingConfig;
}>;

export type UpdateAzureBillingConfigV2Mutation = {
  __typename?: "Mutation";
  updateAzureBillingConfigV2: {
    __typename?: "AzureBillingData";
    capacitorCustomerId: string;
    enrollmentNumber: string;
    isActive: boolean;
    isOnboarding: boolean;
  };
};

export type RemoveAzureBillingConfigV2MutationVariables = Exact<{
  capacitorCustomerId: Scalars["String"];
}>;

export type RemoveAzureBillingConfigV2Mutation = {
  __typename?: "Mutation";
  removeAzureBillingConfigV2: { __typename?: "MutationResponse"; id?: string | null };
};

export type UpdateAwsBillingConfigV2MutationVariables = Exact<{
  input: UpdateAwsBillingConfig;
}>;

export type UpdateAwsBillingConfigV2Mutation = {
  __typename?: "Mutation";
  updateAwsBillingConfigV2: {
    __typename?: "AWSBillingData";
    nid?: string | null;
    accountId: string;
    displayName?: string | null;
    isActive: boolean;
    isOnboarding: boolean;
    bucketId: string;
    region: string;
    reportName: string;
    reportPathPrefix: string;
  };
};

export type UpdateAccountV3MutationVariables = Exact<{
  nid: Scalars["ID"];
  input: UpdateAccountV3;
}>;

export type UpdateAccountV3Mutation = {
  __typename?: "Mutation";
  updateAccountV3?: {
    __typename?: "Account";
    id: string;
    nid: string;
    provider: Provider;
    providerId: string;
    name: string;
    displayName?: string | null;
    description?: string | null;
    activated: boolean;
    metadata?: string | null;
    status?: string | null;
    cloudStatusV2?: CloudAccountStatus | null;
    providerType?: CloudProviderType | null;
    chargeTypeCosts?: Array<{
      __typename?: "ChargeTypeCost";
      chargeType: string;
      cost: string;
    }> | null;
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
    owner?: { __typename?: "Person"; id: string; name: string; email: string } | null;
  } | null;
};

export type UpdateAzureAccountCredentialsBatchMutationVariables = Exact<{
  nids: Array<Scalars["ID"]> | Scalars["ID"];
  input: UpdateAzureAccountCredentialsInput;
}>;

export type UpdateAzureAccountCredentialsBatchMutation = {
  __typename?: "Mutation";
  updateAzureAccountCredentialsBatch?: boolean | null;
};

export type UpdateAwsAccountCredentialsBatchMutationVariables = Exact<{
  nids: Array<Scalars["ID"]> | Scalars["ID"];
  input: UpdateAwsAccountCredentialsInput;
}>;

export type UpdateAwsAccountCredentialsBatchMutation = {
  __typename?: "Mutation";
  updateAwsAccountCredentialsBatch?: boolean | null;
};

export type UpdateGcpAccountCredentialsBatchMutationVariables = Exact<{
  nids: Array<Scalars["ID"]> | Scalars["ID"];
  input: UpdateGcpAccountCredentialsInput;
}>;

export type UpdateGcpAccountCredentialsBatchMutation = {
  __typename?: "Mutation";
  updateGcpAccountCredentialsBatch?: boolean | null;
};

export type UpdateIbmAccountCredentialsBatchMutationVariables = Exact<{
  nids: Array<Scalars["ID"]> | Scalars["ID"];
  input: UpdateIbmAccountCredentialsInput;
}>;

export type UpdateIbmAccountCredentialsBatchMutation = {
  __typename?: "Mutation";
  updateIbmAccountCredentialsBatch?: boolean | null;
};

export type RemoveAccountsMutationVariables = Exact<{
  input: BatchRemoveAccountsInput;
}>;

export type RemoveAccountsMutation = {
  __typename?: "Mutation";
  removeAccounts?: {
    __typename?: "CloudAccountsResponse";
    count: number;
    pages: number;
    accounts?: Array<{
      __typename?: "Account";
      id: string;
      nid: string;
      provider: Provider;
      providerId: string;
      name: string;
      displayName?: string | null;
      description?: string | null;
      activated: boolean;
      metadata?: string | null;
      providerType?: CloudProviderType | null;
      chargeTypeCosts?: Array<{
        __typename?: "ChargeTypeCost";
        chargeType: string;
        cost: string;
      }> | null;
      contacts?: Array<{
        __typename?: "Person";
        id: string;
        name: string;
        email: string;
      } | null> | null;
      owner?: { __typename?: "Person"; id: string; name: string; email: string } | null;
    }> | null;
  } | null;
};

export type AddAccountV3MutationVariables = Exact<{
  input: AddAccountV3;
}>;

export type AddAccountV3Mutation = {
  __typename?: "Mutation";
  addAccountV3?: {
    __typename?: "Account";
    id: string;
    nid: string;
    provider: Provider;
    providerId: string;
    name: string;
    displayName?: string | null;
    description?: string | null;
    activated: boolean;
    metadata?: string | null;
    providerType?: CloudProviderType | null;
    chargeTypeCosts?: Array<{
      __typename?: "ChargeTypeCost";
      chargeType: string;
      cost: string;
    }> | null;
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
    owner?: { __typename?: "Person"; id: string; name: string; email: string } | null;
    cloudWasteAggregate?: {
      __typename?: "CloudWasteAggregate";
      hasWaste?: boolean | null;
      cost?: string | null;
      co2e?: string | null;
      count?: number | null;
    } | null;
  } | null;
};

export type ClusterResourceConsumptionQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type ClusterResourceConsumptionQuery = {
  __typename?: "Query";
  clusterResourceConsumption?: {
    __typename?: "ClusterResourceConsumptionResponse";
    accountId: string;
    memory?: Array<{
      __typename?: "ClusterConsumedResource";
      date?: string | null;
      used?: string | null;
    }> | null;
    cpu?: Array<{
      __typename?: "ClusterConsumedResource";
      date?: string | null;
      used?: string | null;
    }> | null;
    storageRw?: Array<{
      __typename?: "StorageReadWriteResource";
      date?: string | null;
      read?: string | null;
      write?: string | null;
    }> | null;
  } | null;
};

export type AccountV2QueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type AccountV2Query = {
  __typename?: "Query";
  accountV2?: {
    __typename?: "Account";
    id: string;
    name: string;
    displayName?: string | null;
    provider: Provider;
    status?: string | null;
    providerId: string;
    providerType?: CloudProviderType | null;
    description?: string | null;
    activated: boolean;
    metadata?: string | null;
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
    owner?: { __typename?: "Person"; id: string; name: string } | null;
  } | null;
};

export type AccountSpendsQueryVariables = Exact<{
  accountProviderId: Scalars["String"];
}>;

export type AccountSpendsQuery = {
  __typename?: "Query";
  accountSpends?: {
    __typename?: "AccountSpends";
    currentMonthForecastValue: number;
    currentMonthValue: number;
    previousMonthValue: number;
    currentMonthCo2e: number;
  } | null;
};

export type AccountsV2CountsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountsV2CountsQuery = {
  __typename?: "Query";
  AWS?: { __typename?: "AccountsResponse"; count?: number | null } | null;
  GCP?: { __typename?: "AccountsResponse"; count?: number | null } | null;
  AZURE?: { __typename?: "AccountsResponse"; count?: number | null } | null;
  VMWARE?: { __typename?: "AccountsResponse"; count?: number | null } | null;
  IBMCLOUD?: { __typename?: "AccountsResponse"; count?: number | null } | null;
  KUBERNETES?: { __typename?: "AccountsResponse"; count?: number | null } | null;
  OPENSHIFT?: { __typename?: "AccountsResponse"; count?: number | null } | null;
};

export type AccountsV2ByProviderQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  filter?: InputMaybe<AccountsFilter>;
}>;

export type AccountsV2ByProviderQuery = {
  __typename?: "Query";
  accountsV2?: {
    __typename?: "AccountsResponse";
    count?: number | null;
    accounts?: Array<{
      __typename?: "Account";
      id: string;
      provider: Provider;
      providerId: string;
      providerType?: CloudProviderType | null;
      activated: boolean;
      status?: string | null;
      description?: string | null;
      name: string;
      displayName?: string | null;
      owner?: { __typename?: "Person"; id: string; name: string } | null;
      contacts?: Array<{ __typename?: "Person"; id: string; name: string } | null> | null;
    }> | null;
  } | null;
};

export type DriftsQueryVariables = Exact<{
  input: EstateAllocationDriftsInput;
}>;

export type DriftsQuery = {
  __typename?: "Query";
  estateAllocationDrifts: {
    __typename?: "EstateAllocationDriftsResponse";
    count: number;
    pages: number;
    records?: Array<{
      __typename?: "EstateAllocationDriftRecord";
      name: string;
      provider: Provider;
      providerId: string;
      providerType?: string | null;
      categoryName: string;
      region: string;
      id: string;
      currentEnvironment?: {
        __typename?: "EstateAllocationDriftRecordEnvironment";
        name: string;
        nid: string;
        application: {
          __typename?: "EstateAllocationDriftRecordApplication";
          name: string;
          nid: string;
        };
      } | null;
      targetEnvironment?: {
        __typename?: "EstateAllocationDriftRecordEnvironment";
        name: string;
        nid: string;
        application: {
          __typename?: "EstateAllocationDriftRecordApplication";
          name: string;
          nid: string;
        };
      } | null;
    }> | null;
  };
};

export type UpdateDriftsMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  accept?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateDriftsMutation = {
  __typename?: "Mutation";
  updateDriftStatuses?: boolean | null;
};

export type ResolveDriftsMutationVariables = Exact<{
  input: ResolveDriftsInput;
}>;

export type ResolveDriftsMutation = {
  __typename?: "Mutation";
  resolveDrifts: {
    __typename?: "EstateAllocationDriftsResponse";
    count: number;
    pages: number;
    records?: Array<{
      __typename?: "EstateAllocationDriftRecord";
      name: string;
      provider: Provider;
      providerId: string;
      providerType?: string | null;
      categoryName: string;
      region: string;
      id: string;
      currentEnvironment?: {
        __typename?: "EstateAllocationDriftRecordEnvironment";
        name: string;
        nid: string;
        application: {
          __typename?: "EstateAllocationDriftRecordApplication";
          name: string;
          nid: string;
        };
      } | null;
      targetEnvironment?: {
        __typename?: "EstateAllocationDriftRecordEnvironment";
        name: string;
        nid: string;
        application: {
          __typename?: "EstateAllocationDriftRecordApplication";
          name: string;
          nid: string;
        };
      } | null;
    }> | null;
  };
};

export type ResolveAllDriftsMutationVariables = Exact<{
  input: ResolveAllDriftsInput;
}>;

export type ResolveAllDriftsMutation = {
  __typename?: "Mutation";
  resolveAllDrifts: { __typename?: "ResolveDriftsResponse"; success?: boolean | null };
};

export type DriftFragment = {
  __typename?: "EstateAllocationDriftRecord";
  name: string;
  provider: Provider;
  providerId: string;
  providerType?: string | null;
  categoryName: string;
  region: string;
  id: string;
  currentEnvironment?: {
    __typename?: "EstateAllocationDriftRecordEnvironment";
    name: string;
    nid: string;
    application: {
      __typename?: "EstateAllocationDriftRecordApplication";
      name: string;
      nid: string;
    };
  } | null;
  targetEnvironment?: {
    __typename?: "EstateAllocationDriftRecordEnvironment";
    name: string;
    nid: string;
    application: {
      __typename?: "EstateAllocationDriftRecordApplication";
      name: string;
      nid: string;
    };
  } | null;
};

export type DiscoveryRuleFieldsFragment = {
  __typename?: "DiscoveryRule";
  id: string;
  name: string;
  exclusive: boolean;
  ibmResourceGroup?: boolean | null;
  match: {
    __typename?: "DiscoveryRuleMatch";
    nid?: Array<string> | null;
    cloudAccount?: Array<string> | null;
    resourceGroup?: Array<string> | null;
    projectId?: Array<string> | null;
    type?: Array<string> | null;
    subtype?: Array<string> | null;
    subnetIds?: Array<string> | null;
    securityGroupIds?: Array<string> | null;
    tags?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
  };
};

export type GetDiscoveryRulesQueryVariables = Exact<{
  envId: Scalars["String"];
}>;

export type GetDiscoveryRulesQuery = {
  __typename?: "Query";
  discoveryRules?: Array<{
    __typename?: "DiscoveryRule";
    id: string;
    name: string;
    exclusive: boolean;
    ibmResourceGroup?: boolean | null;
    match: {
      __typename?: "DiscoveryRuleMatch";
      nid?: Array<string> | null;
      cloudAccount?: Array<string> | null;
      resourceGroup?: Array<string> | null;
      projectId?: Array<string> | null;
      type?: Array<string> | null;
      subtype?: Array<string> | null;
      subnetIds?: Array<string> | null;
      securityGroupIds?: Array<string> | null;
      tags?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
    };
  } | null> | null;
};

export type AddDiscoveryRuleMutationVariables = Exact<{
  input: AddDiscoveryRule;
}>;

export type AddDiscoveryRuleMutation = {
  __typename?: "Mutation";
  addDiscoveryRuleV2: {
    __typename?: "DiscoveryRule";
    id: string;
    name: string;
    exclusive: boolean;
    ibmResourceGroup?: boolean | null;
    match: {
      __typename?: "DiscoveryRuleMatch";
      nid?: Array<string> | null;
      cloudAccount?: Array<string> | null;
      resourceGroup?: Array<string> | null;
      projectId?: Array<string> | null;
      type?: Array<string> | null;
      subtype?: Array<string> | null;
      subnetIds?: Array<string> | null;
      securityGroupIds?: Array<string> | null;
      tags?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
    };
  };
};

export type RemoveDiscoveryRuleMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type RemoveDiscoveryRuleMutation = {
  __typename?: "Mutation";
  removeDiscoveryRule?: boolean | null;
};

export type UpdateDiscoveryRuleMutationVariables = Exact<{
  input: UpdateDiscoveryRule;
}>;

export type UpdateDiscoveryRuleMutation = {
  __typename?: "Mutation";
  updateDiscoveryRuleV2: {
    __typename?: "DiscoveryRule";
    id: string;
    name: string;
    exclusive: boolean;
    ibmResourceGroup?: boolean | null;
    match: {
      __typename?: "DiscoveryRuleMatch";
      nid?: Array<string> | null;
      cloudAccount?: Array<string> | null;
      resourceGroup?: Array<string> | null;
      projectId?: Array<string> | null;
      type?: Array<string> | null;
      subtype?: Array<string> | null;
      subnetIds?: Array<string> | null;
      securityGroupIds?: Array<string> | null;
      tags?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
    };
  };
};

export type EnvironmentQueryVariables = Exact<{
  id: Scalars["String"];
  year: Scalars["String"];
}>;

export type EnvironmentQuery = {
  __typename?: "Query";
  environment?: {
    __typename?: "Environment";
    id: string;
    nid: string;
    name: string;
    co2e?: string | null;
    environmentType: EnvironmentType;
    description?: string | null;
    budgetYearly: { __typename?: "BudgetYearly"; id: string; budgetByMonth: Array<string> };
    cloudWasteAggregate?: {
      __typename?: "CloudWasteAggregate";
      hasWaste?: boolean | null;
      cost?: string | null;
      co2e?: string | null;
      count?: number | null;
    } | null;
    cost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
      currentMonthDaily?: Array<string | null> | null;
    } | null;
    owner?: { __typename?: "Person"; id: string } | null;
    application: { __typename?: "Application"; id: string; nid: string; name: string };
    contacts?: Array<{
      __typename?: "Person";
      id: string;
      name: string;
      email: string;
    } | null> | null;
  } | null;
};

export type EnvironmentContactsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type EnvironmentContactsQuery = {
  __typename?: "Query";
  environment?: {
    __typename?: "Environment";
    id: string;
    name: string;
    description?: string | null;
    owner?: { __typename?: "Person"; id: string; name: string } | null;
    contacts?: Array<{ __typename?: "Person"; id: string; name: string } | null> | null;
  } | null;
};

export type SummaryKpiDetailsFragment = {
  __typename?: "KpiDetails";
  targets?: {
    __typename?: "KpiTargets";
    currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
  } | null;
  metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
};

export type EnvironmentKpiQueryVariables = Exact<{
  envId: Scalars["String"];
  timePeriod: KpiTimePeriod;
  bcId: Scalars["ID"];
  fetchOverallWaste: Scalars["Boolean"];
  fetchNonProdHours: Scalars["Boolean"];
}>;

export type EnvironmentKpiQuery = {
  __typename?: "Query";
  environment?: {
    __typename?: "Environment";
    id: string;
    nid: string;
    summaryKpis?: {
      __typename?: "EnvironmentKpis";
      nonProdHours: {
        __typename?: "KpiNonProdHoursForEnvironment";
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
      overallWaste: {
        __typename?: "KpiOverallWasteForEnvironment";
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
    } | null;
    kpis?: {
      __typename?: "EnvironmentKpis";
      nonProdHours?: {
        __typename?: "KpiNonProdHoursForEnvironment";
        details: {
          __typename?: "KpiDetails";
          metrics?: {
            __typename?: "KpiMetric";
            currentValue?: string | null;
            values: Array<{
              __typename?: "EnhancedTimePoint";
              date: string;
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            }>;
          } | null;
          targets?: {
            __typename?: "KpiTargets";
            targets: Array<{
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
            }>;
            currentTarget?: {
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
              contactPerson?: {
                __typename?: "Person";
                name: string;
                id: string;
                email: string;
              } | null;
            } | null;
          } | null;
        };
      };
      overallWaste?: {
        __typename?: "KpiOverallWasteForEnvironment";
        details: {
          __typename?: "KpiDetails";
          metrics?: {
            __typename?: "KpiMetric";
            currentValue?: string | null;
            values: Array<{
              __typename?: "EnhancedTimePoint";
              date: string;
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            }>;
          } | null;
          targets?: {
            __typename?: "KpiTargets";
            targets: Array<{
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
            }>;
            currentTarget?: {
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
              contactPerson?: {
                __typename?: "Person";
                name: string;
                id: string;
                email: string;
              } | null;
            } | null;
          } | null;
        };
      };
    } | null;
  } | null;
};

export type AddEnvToOrgUnitV2MutationVariables = Exact<{
  envId: Scalars["ID"];
  orgUnitId: Scalars["ID"];
}>;

export type AddEnvToOrgUnitV2Mutation = {
  __typename?: "Mutation";
  addEnvToOrgUnitV2?: boolean | null;
};

export type RemoveEnvFromOrgUnitV2MutationVariables = Exact<{
  envId: Scalars["ID"];
  orgUnitId: Scalars["ID"];
}>;

export type RemoveEnvFromOrgUnitV2Mutation = {
  __typename?: "Mutation";
  removeEnvFromOrgUnitV2?: boolean | null;
};

export type GetEnvironmentOrgUnitsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetEnvironmentOrgUnitsQuery = {
  __typename?: "Query";
  environment?: {
    __typename?: "Environment";
    id: string;
    nid: string;
    name: string;
    orgUnitsV2?: {
      __typename?: "OrgUnitsV2Response";
      orgUnits?: Array<{
        __typename?: "OrgUnitV2";
        id: string;
        nid: string;
        name: string;
        businessContext: {
          __typename?: "BusinessContext";
          id: string;
          color?: string | null;
          name: string;
          isPrimary?: boolean | null;
        };
      }> | null;
    } | null;
  } | null;
};

export type EnvironmentBudgetQueryVariables = Exact<{
  id: Scalars["String"];
  year: Scalars["String"];
  previousYear: Scalars["String"];
}>;

export type EnvironmentBudgetQuery = {
  __typename?: "Query";
  environment?: {
    __typename?: "Environment";
    id: string;
    nid: string;
    budgetYearly: {
      __typename?: "BudgetYearly";
      id: string;
      budgetByMonth: Array<string>;
      yearlySum: string;
    };
    spendYearly: { __typename?: "SpendYearly"; spends: Array<string> };
    forecastSpendYearly: { __typename?: "ForecastSpendYearly"; spends: Array<string> };
  } | null;
};

export type SetEnvironmentYearlyBudgetMutationVariables = Exact<{
  budget: BudgetYearlyInput;
  nid: Scalars["ID"];
}>;

export type SetEnvironmentYearlyBudgetMutation = {
  __typename?: "Mutation";
  setEnvironmentYearlyBudget: {
    __typename?: "BudgetYearly";
    id: string;
    budgetByMonth: Array<string>;
    yearlySum: string;
  };
};

export type RemoveEnvironmentMutationVariables = Exact<{
  nid: Scalars["ID"];
}>;

export type RemoveEnvironmentMutation = {
  __typename?: "Mutation";
  removeEnvironmentV2?: boolean | null;
};

export type EnvAccumulatedCostFragment = {
  __typename?: "EnvironmentAccumulatedCostResponse";
  total: string;
  timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
};

export type GetEnvironmentAccumulatedCostQueryVariables = Exact<{
  environmentId: Scalars["ID"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  tableGranularity?: InputMaybe<Granularity>;
}>;

export type GetEnvironmentAccumulatedCostQuery = {
  __typename?: "Query";
  environmentAccumulatedCost: {
    __typename?: "EnvironmentAccumulatedCostResponse";
    total: string;
    timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
  };
  environmentAccumulatedCostTable: {
    __typename?: "EnvironmentAccumulatedCostResponse";
    total: string;
    timePoints?: Array<{ __typename?: "TimePoint"; date: string; value: string } | null> | null;
  };
};

export type EnvironmentTopCategoriesCostQueryVariables = Exact<{
  top: Scalars["Int"];
  environmentId: Scalars["ID"];
  endDate: Scalars["String"];
  granularity?: InputMaybe<Granularity>;
  startDate: Scalars["String"];
}>;

export type EnvironmentTopCategoriesCostQuery = {
  __typename?: "Query";
  environmentTopCategoriesCost: {
    __typename?: "EnvironmentTopCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type EnvironmentOtherCategoriesCostQueryVariables = Exact<{
  input: EnvironmentOtherCategoriesCostInput;
}>;

export type EnvironmentOtherCategoriesCostQuery = {
  __typename?: "Query";
  environmentOtherCategoriesCost: {
    __typename?: "EnvironmentOtherCategoriesCostResponse";
    total: string;
    timePoints?: Array<{
      __typename?: "GroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "EnvGroup";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  };
};

export type CostResourceEnvironmentsQueryVariables = Exact<{
  id: Scalars["String"];
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
}>;

export type CostResourceEnvironmentsQuery = {
  __typename?: "Query";
  estateRecord?:
    | { __typename?: "CloudResource" }
    | {
        __typename?: "CostResource";
        id: string;
        environmentsPaginated: {
          __typename?: "EnvironmentAndSpendsPaginated";
          count?: number | null;
          pages?: number | null;
          environments?: Array<{
            __typename?: "EnvironmentAndSpends";
            cost?: {
              __typename?: "Cost";
              currentMonth: string;
              previousMonth: string;
              forecast?: string | null;
            } | null;
            environment?: {
              __typename?: "Environment";
              name: string;
              id: string;
              application: { __typename?: "Application"; name: string; id: string };
            } | null;
          }> | null;
        };
      }
    | null;
};

export type ResourceQueryVariables = Exact<{
  id: Scalars["String"];
  isLite: Scalars["Boolean"];
}>;

export type ResourceQuery = {
  __typename?: "Query";
  estateRecord?:
    | {
        __typename?: "CloudResource";
        id: string;
        provider: Provider;
        providerId: string;
        providerType?: string | null;
        name: string;
        region?: string | null;
        drifted?: boolean | null;
        cloudAccountId?: string | null;
        createdAt?: string | null;
        validThrough?: string | null;
        cloudWaste?: { __typename?: "CloudWaste"; isWaste: boolean; reason?: string | null } | null;
        pendingAssignment?: {
          __typename?: "Environment";
          id: string;
          name: string;
          application: { __typename?: "Application"; name: string; id: string };
        } | null;
        cost?: {
          __typename?: "Cost";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
          currentMonthLastSpendDate?: string | null;
          originalCost?: { __typename?: "OriginalCost"; currentMonth?: string | null } | null;
        } | null;
        co2e?: {
          __typename?: "Co2e";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
        } | null;
        costSavings?: Array<{
          __typename?: "CostSavings";
          problem: string;
          cost: string;
          co2e: string;
          cloudUrl?: string | null;
          action: string;
          annualCost: string;
          detectionId?: string | null;
          policyId: CloudWasteSettingKey;
          metadata?: {
            __typename?: "CostSavingsMetadata";
            currentInstanceType?: string | null;
            recommendedInstanceType?: string | null;
          } | null;
        }> | null;
        savingsAmount?: {
          __typename?: "SavingsAmount";
          amount: string;
          co2e: string;
          count: number;
        } | null;
        environment?: {
          __typename?: "Environment";
          name: string;
          id: string;
          application: { __typename?: "Application"; name: string; id: string };
        } | null;
        metadataAndTags?: {
          __typename?: "MetadataAndTags";
          tags?: Array<{ __typename?: "KeyValueSource"; key: string; value: string }> | null;
          metadata?: Array<{ __typename?: "KeyValueSource"; key: string; value: string }> | null;
        } | null;
        reservation?: { __typename?: "Reservation"; from: string; to: string } | null;
        links?: Array<{
          __typename?: "Link";
          sourceRecord:
            | {
                __typename?: "CloudResource";
                id: string;
                name: string;
                provider: Provider;
                providerId: string;
                cloudAccountId?: string | null;
                providerType?: string | null;
              }
            | { __typename?: "CostResource" };
          targetRecord:
            | {
                __typename?: "CloudResource";
                id: string;
                name: string;
                provider: Provider;
                providerId: string;
                cloudAccountId?: string | null;
                providerType?: string | null;
              }
            | { __typename?: "CostResource" };
        }> | null;
        enrichments?: Array<{
          __typename?: "Enrichment";
          integration: { __typename?: "Integration"; id: string; name: string };
          data?: Array<{ __typename?: "KeyValueSource"; key: string; value: string }> | null;
        }> | null;
      }
    | {
        __typename?: "CostResource";
        id: string;
        provider: Provider;
        providerType?: string | null;
        cost?: {
          __typename?: "Cost";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
        } | null;
        co2e?: {
          __typename?: "Co2e";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
        } | null;
        metadataAndTags?: {
          __typename?: "MetadataAndTags";
          tags?: Array<{ __typename?: "KeyValueSource"; key: string; value: string }> | null;
          metadata?: Array<{ __typename?: "KeyValueSource"; key: string; value: string }> | null;
        } | null;
      }
    | null;
};

export type ReservationDetailsQueryVariables = Exact<{
  reservationId: Scalars["String"];
}>;

export type ReservationDetailsQuery = {
  __typename?: "Query";
  reservationDetails?: {
    __typename?: "Reservation";
    from: string;
    to: string;
    instanceType?: string | null;
    purchaseOption?: string | null;
    paymentOption?: string | null;
    upfrontCost?: string | null;
    monthlyCost?: string | null;
    billingPlan?: string | null;
    skuName?: string | null;
    dailyUtilization?: {
      __typename?: "ReservationUtilization";
      utilization: string;
      benefit: string;
      forecast?: string | null;
      timeSeries?: Array<{
        __typename?: "TimeSeries";
        date: string;
        value: string;
        benefit: string;
      } | null> | null;
    } | null;
    monthlyUtilization?: {
      __typename?: "ReservationUtilization";
      utilization: string;
      benefit: string;
      forecast?: string | null;
      timeSeries?: Array<{
        __typename?: "TimeSeries";
        date: string;
        value: string;
        benefit: string;
      } | null> | null;
    } | null;
  } | null;
};

export type ListReservationCoveredInstancesQueryVariables = Exact<{
  reservationId: Scalars["String"];
  page: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type ListReservationCoveredInstancesQuery = {
  __typename?: "Query";
  listReservationCoveredInstances?: {
    __typename?: "ReservationCoveredInstancesPaginated";
    pages?: number | null;
    count?: number | null;
    instances?: Array<{
      __typename?: "ReservationCoveredInstance";
      id?: string | null;
      nid: string;
      provider?: Provider | null;
      type?: string | null;
      name?: string | null;
      account?: string | null;
      location?: string | null;
      benefit?: string | null;
      environment?: {
        __typename?: "Environment";
        id: string;
        nid: string;
        name: string;
        application: { __typename?: "Application"; id: string; nid: string; name: string };
      } | null;
    }> | null;
  } | null;
};

export type ResourceConsumptionQueryVariables = Exact<{
  id: Scalars["String"];
  type: KubernetesType;
}>;

export type ResourceConsumptionQuery = {
  __typename?: "Query";
  resourceConsumption?: {
    __typename?: "ResourceConsumptionResponse";
    cpu?: Array<{
      __typename?: "ConsumedResource";
      date?: string | null;
      used?: string | null;
      requested?: string | null;
    }> | null;
    memory?: Array<{
      __typename?: "ConsumedResource";
      date?: string | null;
      used?: string | null;
      requested?: string | null;
    }> | null;
    storageRw?: Array<{
      __typename?: "StorageReadWriteResource";
      date?: string | null;
      read?: string | null;
      write?: string | null;
    }> | null;
  } | null;
};

export type CreateLinkMutationVariables = Exact<{
  sourceNid: Scalars["String"];
  targetNid: Scalars["String"];
}>;

export type CreateLinkMutation = {
  __typename?: "Mutation";
  createLink?: {
    __typename?: "Link";
    effectiveAt: string;
    sourceRecord:
      | { __typename?: "CloudResource"; id: string; name: string }
      | { __typename?: "CostResource" };
    targetRecord:
      | { __typename?: "CloudResource"; id: string; name: string }
      | { __typename?: "CostResource" };
  } | null;
};

export type UpdateLinkMutationVariables = Exact<{
  oldLink: LinkInput;
  newLink: LinkInput;
}>;

export type UpdateLinkMutation = { __typename?: "Mutation"; updateLink?: boolean | null };

export type DeleteLinkMutationVariables = Exact<{
  sourceNid: Scalars["String"];
  targetNid: Scalars["String"];
}>;

export type DeleteLinkMutation = { __typename?: "Mutation"; deleteLink?: boolean | null };

export type LinkedRecordsMutationVariables = Exact<{
  unlink?: InputMaybe<Array<LinkInput> | LinkInput>;
  link?: InputMaybe<Array<LinkInput> | LinkInput>;
}>;

export type LinkedRecordsMutation = { __typename?: "Mutation"; linkedRecords?: boolean | null };

export type AddSyntheticEstateRecordMutationVariables = Exact<{
  input: AddSyntheticEstateRecord;
}>;

export type AddSyntheticEstateRecordMutation = {
  __typename?: "Mutation";
  addSyntheticEstateRecord?: { __typename?: "MutationResponse"; id?: string | null } | null;
};

export type UpdateCostSavingMutationVariables = Exact<{
  detectionId: Scalars["String"];
  state: RecommendationState;
  comment: Scalars["String"];
}>;

export type UpdateCostSavingMutation = {
  __typename?: "Mutation";
  updateCostSavings?: boolean | null;
};

export type ReservationUtilizationQueryVariables = Exact<{
  reservationId: Scalars["String"];
  timePeriod: ReservationUtilizationTimePeriod;
}>;

export type ReservationUtilizationQuery = {
  __typename?: "Query";
  reservationUtilization?: {
    __typename?: "ReservationUtilizationDetail";
    monthlyUtilization?: {
      __typename?: "ReservationUtilization";
      utilization: string;
      benefit: string;
      forecast?: string | null;
      timeSeries?: Array<{
        __typename?: "TimeSeries";
        date: string;
        value: string;
        benefit: string;
      } | null> | null;
    } | null;
  } | null;
};

export type SkuCostsQueryVariables = Exact<{
  nid: Scalars["String"];
  providers?: InputMaybe<Array<InputMaybe<Provider>> | InputMaybe<Provider>>;
}>;

export type SkuCostsQuery = {
  __typename?: "Query";
  skuCosts?: {
    __typename?: "SKUCostsResponse";
    currentMonth?: Array<{
      __typename?: "SKUCost";
      value?: string | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type ApplicationsPaginatedByRecordNidQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  year: Scalars["String"];
  estateRecordNid?: InputMaybe<Scalars["String"]>;
}>;

export type ApplicationsPaginatedByRecordNidQuery = {
  __typename?: "Query";
  applicationsPaginated?: {
    __typename?: "ApplicationsPaginatedResponse";
    pages?: number | null;
    count?: number | null;
    results?: Array<{
      __typename?: "Application";
      name: string;
      id: string;
      hasAnomalies?: boolean | null;
      co2e?: string | null;
      budgetYearly: {
        __typename?: "BudgetYearly";
        id: string;
        budgetByMonth: Array<string>;
        yearlySum: string;
      };
      cloudWasteAggregate?: {
        __typename?: "CloudWasteAggregate";
        hasWaste?: boolean | null;
        cost?: string | null;
        count?: number | null;
        co2e?: string | null;
      } | null;
      cost?: {
        __typename?: "Cost";
        currentMonth: string;
        previousMonth: string;
        forecast?: string | null;
        currentMonthDaily?: Array<string | null> | null;
      } | null;
      environments?: Array<{
        __typename?: "Environment";
        id: string;
        name: string;
        nid: string;
        co2e?: string | null;
        resourceCount: number;
        budgetYearly: {
          __typename?: "BudgetYearly";
          id: string;
          budgetByMonth: Array<string>;
          yearlySum: string;
        };
        cloudWasteAggregate?: {
          __typename?: "CloudWasteAggregate";
          hasWaste?: boolean | null;
          cost?: string | null;
          count?: number | null;
          co2e?: string | null;
        } | null;
        cost?: {
          __typename?: "Cost";
          currentMonth: string;
          previousMonth: string;
          forecast?: string | null;
        } | null;
        orgUnitsV2?: {
          __typename?: "OrgUnitsV2Response";
          orgUnits?: Array<{
            __typename?: "OrgUnitV2";
            id: string;
            nid: string;
            name: string;
            businessContext: {
              __typename?: "BusinessContext";
              id: string;
              color?: string | null;
              name: string;
            };
          }> | null;
        } | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type Ec2UtilizationQueryVariables = Exact<{
  nid: Scalars["String"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  granulation: Ec2MetricGranulation;
  volumeId: Scalars["String"];
}>;

export type Ec2UtilizationQuery = {
  __typename?: "Query";
  estateRecord?:
    | {
        __typename?: "CloudResource";
        id: string;
        ec2UsageMetrics?: {
          __typename?: "EC2UsageMetrics";
          processor?: {
            __typename?: "EC2ProcessorUtilization";
            utilization: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
          } | null;
          memory?: {
            __typename?: "EC2MemoryUtilization";
            utilization: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
          } | null;
          network?: {
            __typename?: "EC2NetworkUtilization";
            inBytes: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
            outBytes: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
          } | null;
          disk?: {
            __typename?: "EC2DiskUtilization";
            readBytes: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
            writeBytes: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
          } | null;
        } | null;
      }
    | { __typename?: "CostResource" }
    | null;
};

export type Ec2DiskUtilizationQueryVariables = Exact<{
  nid: Scalars["String"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  granulation: Ec2MetricGranulation;
  volumeId: Scalars["String"];
}>;

export type Ec2DiskUtilizationQuery = {
  __typename?: "Query";
  estateRecord?:
    | {
        __typename?: "CloudResource";
        id: string;
        ec2UsageMetrics?: {
          __typename?: "EC2UsageMetrics";
          disk?: {
            __typename?: "EC2DiskUtilization";
            readBytes: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
            writeBytes: Array<{
              __typename?: "TimePointWithUnit";
              date: string;
              value: string;
              unit: string;
            }>;
          } | null;
        } | null;
      }
    | { __typename?: "CostResource" }
    | null;
};

export type TimePointWithUnitFragment = {
  __typename?: "TimePointWithUnit";
  date: string;
  value: string;
  unit: string;
};

export type Ec2InstanceComparisonQueryVariables = Exact<{
  nid: Scalars["String"];
  type: Scalars["String"];
  usageOperation: Scalars["String"];
  region: Scalars["String"];
  tenancy?: InputMaybe<Ec2Tenancy>;
  instanceFamily: Ec2InstanceFamily;
  instancesFromSavingsSuggestions?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type Ec2InstanceComparisonQuery = {
  __typename?: "Query";
  estateRecord?:
    | {
        __typename?: "CloudResource";
        id: string;
        ec2InstanceComparator?: Array<{
          __typename?: "EC2Instance";
          instanceType: string;
          instanceFamily: string;
          memory: string;
          vCPU: number;
          storage: string;
          networkPerformance: string;
          region: string;
          priceHourly: string;
          priceDaily: string;
          priceMonthly: string;
          operatingSystem: string;
          isInstanceComparedTo: boolean;
          isInstanceFromSavingsSuggestions: boolean;
        }> | null;
      }
    | { __typename?: "CostResource" }
    | null;
};

export type ReservedInstancesRecommendationsQueryVariables = Exact<{
  resourceType: Scalars["String"];
  region: Scalars["String"];
  provider: Scalars["String"];
  platform: Scalars["String"];
  payments:
    | Array<ReservationsRecommendationPaymentOption>
    | ReservationsRecommendationPaymentOption;
  terms: Array<ReservationsRecommendationTerm> | ReservationsRecommendationTerm;
}>;

export type ReservedInstancesRecommendationsQuery = {
  __typename?: "Query";
  allReservationsRecommendations: Array<{
    __typename?: "AllReservationsRecommendationsResponse";
    payment: ReservationsRecommendationPaymentOption;
    term: ReservationsRecommendationTerm;
    data?: {
      __typename?: "ReservationsAndCommitmentsRecommendationsResponse";
      expectedUtilization?: string | null;
      instanceType?: string | null;
      monthlySavings?: string | null;
      monthlySavingsPercentage?: string | null;
      numberOfInstances?: number | null;
      recurringMonthlyCost?: string | null;
      upfrontCost?: string | null;
      yearlySavings?: string | null;
      startSavings?: string | null;
    } | null;
  } | null>;
};

export type CloudAccountStatusQueryVariables = Exact<{
  nid: Scalars["String"];
}>;

export type CloudAccountStatusQuery = {
  __typename?: "Query";
  estateRecord?:
    | {
        __typename?: "CloudResource";
        id: string;
        cloudAccount?: {
          __typename?: "Account";
          id: string;
          nid: string;
          activated: boolean;
          name: string;
          displayName?: string | null;
          status?: string | null;
        } | null;
      }
    | { __typename?: "CostResource" }
    | null;
};

export type ReservationBenefitsEstateRecordsQueryVariables = Exact<{
  instanceId: Scalars["String"];
  provider: Scalars["String"];
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
}>;

export type ReservationBenefitsEstateRecordsQuery = {
  __typename?: "Query";
  reservationsBenefitsEstateRecords?: {
    __typename?: "ReservationsPaginatedItem";
    reservations?: Array<{
      __typename?: "ReservationAndMetadataItem";
      from: string;
      to: string;
      paymentOption?: string | null;
      instanceType?: string | null;
      unitsCount?: number | null;
      expirationTimeInDays?: number | null;
      expirationTimeInYears?: string | null;
      offeringClass?: string | null;
      nid: string;
      description?: string | null;
      location?: string | null;
      yearlyUtilization?: {
        __typename?: "UtilisationResponse";
        benefit?: string | null;
        totalUtilisation?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type GetReservationUpfrontCostQueryVariables = Exact<{
  reservationId: Scalars["String"];
}>;

export type GetReservationUpfrontCostQuery = {
  __typename?: "Query";
  reservationDetails?: { __typename?: "Reservation"; upfrontCost?: string | null } | null;
};

export type ReservationUtilizationV2QueryVariables = Exact<{
  nid: Scalars["String"];
  start: Scalars["String"];
  end: Scalars["String"];
  granularity?: InputMaybe<Scalars["String"]>;
  reservationId?: InputMaybe<Scalars["String"]>;
}>;

export type ReservationUtilizationV2Query = {
  __typename?: "Query";
  reservationUtilizationV2?: {
    __typename?: "ReservationUtilizationDetail";
    reservationId: string;
    monthlyUtilization?: {
      __typename?: "ReservationUtilization";
      reservationIDs?: Array<string | null> | null;
      benefit: string;
      forecast?: string | null;
      utilization: string;
      timeSeries?: Array<{
        __typename?: "TimeSeries";
        date: string;
        value: string;
        benefit: string;
      } | null> | null;
    } | null;
  } | null;
};

export type HasReservedInstancesRecommendationsQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  filters?: InputMaybe<ReservationsAndCommitmentsFilters>;
}>;

export type HasReservedInstancesRecommendationsQuery = {
  __typename?: "Query";
  listReservationsAndCommitmentsCoverage?: {
    __typename?: "ReservationsAndCommitmentsCoveragePaginated";
    pages?: number | null;
    count?: number | null;
    groupedResources?: Array<{
      __typename?: "GroupedResources";
      resources?: Array<{
        __typename?: "ReservationCoveredAndCommitmentType";
        hasRecommendations?: boolean | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type AttachResourceMutationVariables = Exact<{
  environmentNid: Scalars["String"];
  resourceNid: Scalars["String"];
}>;

export type AttachResourceMutation = { __typename?: "Mutation"; attachResource?: boolean | null };

export type EstateV2ExportMutationVariables = Exact<{
  input: RequestEstateV2ExportInput;
}>;

export type EstateV2ExportMutation = {
  __typename?: "Mutation";
  requestEstateV2ExportAsync?: {
    __typename?: "RequestEstateV2ExportResponse";
    exportJobId: string;
  } | null;
};

export type EstateV3ExportMutationVariables = Exact<{
  input: RequestEstateV3ExportInput;
}>;

export type EstateV3ExportMutation = {
  __typename?: "Mutation";
  requestEstateV3ExportAsync?: {
    __typename?: "RequestEstateV3ExportResponse";
    exportJobId: string;
  } | null;
};

export type EstateGroupedExportMutationVariables = Exact<{
  input: RequestGroupedRecordsExportInput;
}>;

export type EstateGroupedExportMutation = {
  __typename?: "Mutation";
  requestGroupedRecordsExport?: {
    __typename?: "RequestGroupedRecordsExportResponse";
    exportJobId: string;
  } | null;
};

export type EstateDownloadQueryVariables = Exact<{
  exportJobId: Scalars["ID"];
}>;

export type EstateDownloadQuery = {
  __typename?: "Query";
  downloadExport?: { __typename?: "DownloadExportResponse"; fileUrl: string } | null;
};

export type GetAppsWithEnvsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppsWithEnvsQuery = {
  __typename?: "Query";
  applications?: Array<{
    __typename?: "Application";
    id: string;
    nid: string;
    name: string;
    environments?: Array<{
      __typename?: "Environment";
      id: string;
      nid: string;
      name: string;
    } | null> | null;
  } | null> | null;
};

export type GetAllAccountsWithDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAccountsWithDetailsQuery = {
  __typename?: "Query";
  accounts?: Array<{
    __typename?: "Account";
    provider: Provider;
    name: string;
    displayName?: string | null;
    id: string;
    providerId: string;
    activated: boolean;
    providerType?: CloudProviderType | null;
    status?: string | null;
  } | null> | null;
};

export type ServicesQueryVariables = Exact<{ [key: string]: never }>;

export type ServicesQuery = {
  __typename?: "Query";
  services: Array<{ __typename?: "Service"; id: string; name: string }>;
};

export type CloudWastePoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type CloudWastePoliciesQuery = {
  __typename?: "Query";
  cloudWasteSummary?: {
    __typename?: "CloudWasteSummaryResponse";
    policies?: Array<{
      __typename?: "CloudWastePolicySummary";
      id?: CloudWasteSettingKey | null;
      title?: string | null;
    } | null> | null;
  } | null;
};

export type CloudWastePoliciesCategorisedQueryVariables = Exact<{ [key: string]: never }>;

export type CloudWastePoliciesCategorisedQuery = {
  __typename?: "Query";
  cloudWastePoliciesCategorised: Array<{
    __typename?: "CloudWastePoliciesCategorised";
    category: CloudWasteCategoryKey;
    categoryName: string;
    policies: Array<{ __typename?: "CloudWastePolicy"; id: CloudWasteSettingKey; name: string }>;
  }>;
};

export type ExternalIntegrationsFilterQueryVariables = Exact<{ [key: string]: never }>;

export type ExternalIntegrationsFilterQuery = {
  __typename?: "Query";
  externalIntegrations?: {
    __typename?: "ExternalIntegrationsResponse";
    integrations?: Array<{
      __typename?: "ExternalIntegrationConfig";
      id: string;
      name: string;
    } | null> | null;
  } | null;
};

export type ListCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ListCategoriesQuery = {
  __typename?: "Query";
  listCategories: {
    __typename?: "ListCategoriesResponse";
    categories?: Array<{ __typename?: "Category"; id: string; name: string }> | null;
  };
};

export type SearchPresetsV2QueryVariables = Exact<{
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  nameQuery?: InputMaybe<Scalars["String"]>;
}>;

export type SearchPresetsV2Query = {
  __typename?: "Query";
  searchPresetsV2?: {
    __typename?: "SearchPresetsV2Response";
    count: number;
    pages: number;
    presets: Array<{
      __typename?: "SearchPresetV2";
      id: string;
      name: string;
      query?: string | null;
      createdAt: string;
      updatedAt: string;
      sort?: {
        __typename?: "SearchPresetSortV2";
        field: SearchV2SortField;
        order: SearchSortOrder;
      } | null;
      filter?: {
        __typename?: "SearchPresetFilterV2";
        provider?: Array<Provider> | null;
        providerExclude?: boolean | null;
        costFrom?: number | null;
        costTo?: number | null;
        costExclude?: boolean | null;
        unassigned?: boolean | null;
        unassignedExclude?: boolean | null;
        waste?: boolean | null;
        wastePolicy?: Array<string> | null;
        wastePolicyExclude?: boolean | null;
        co2eWaste?: boolean | null;
        category?: Array<string> | null;
        categoryExclude?: boolean | null;
        assignmentUpdatedFrom?: string | null;
        assignmentUpdatedTo?: string | null;
        assignmentUpdateExclude?: boolean | null;
        firstSeenAtFrom?: string | null;
        firstSeenAtTo?: string | null;
        firstSeenAtExclude?: boolean | null;
        lastSeenAtFrom?: string | null;
        lastSeenAtTo?: string | null;
        lastSeenAtExclude?: boolean | null;
        lifeSpanFrom?: number | null;
        lifeSpanTo?: number | null;
        lifeSpanExclude?: boolean | null;
        account?: Array<string> | null;
        accountExclude?: boolean | null;
        service?: Array<string> | null;
        serviceExclude?: boolean | null;
        application?: Array<string> | null;
        applicationExclude?: boolean | null;
        environment?: Array<string> | null;
        environmentExclude?: boolean | null;
        region?: Array<string> | null;
        regionExclude?: boolean | null;
        tagsExclude?: boolean | null;
        type?: Array<string> | null;
        typeExclude?: boolean | null;
        subtype?: Array<string> | null;
        subtypeExclude?: boolean | null;
        typeSubtype?: Array<string> | null;
        typeSubtypeExclude?: boolean | null;
        resourceGroup?: Array<string> | null;
        resourceGroupExclude?: boolean | null;
        projectId?: Array<string> | null;
        projectIdExclude?: boolean | null;
        externalIntegration?: Array<string> | null;
        externalIntegrationExclude?: boolean | null;
        enrichment?: boolean | null;
        enrichmentIntegrationId?: Array<string> | null;
        enrichmentIntegrationIdExclude?: boolean | null;
        metadataExclude?: boolean | null;
        providerId?: Array<string> | null;
        exceptProviderId?: Array<string> | null;
        onlyRelated?: boolean | null;
        securityGroup?: Array<string> | null;
        securityGroupExclude?: boolean | null;
        tags?: Array<{ __typename?: "KeyValues"; values: Array<string>; key: string }> | null;
        metadata?: Array<{ __typename?: "KeyValues"; values: Array<string>; key: string }> | null;
      } | null;
    }>;
  } | null;
};

export type AddSearchPresetV2MutationVariables = Exact<{
  input: AddSearchPresetV2Input;
}>;

export type AddSearchPresetV2Mutation = {
  __typename?: "Mutation";
  addSearchPresetV2?: {
    __typename?: "AddSearchPresetV2Response";
    preset: {
      __typename?: "SearchPresetV2";
      id: string;
      name: string;
      query?: string | null;
      createdAt: string;
      updatedAt: string;
      sort?: {
        __typename?: "SearchPresetSortV2";
        field: SearchV2SortField;
        order: SearchSortOrder;
      } | null;
      filter?: {
        __typename?: "SearchPresetFilterV2";
        provider?: Array<Provider> | null;
        providerExclude?: boolean | null;
        costFrom?: number | null;
        costTo?: number | null;
        costExclude?: boolean | null;
        unassigned?: boolean | null;
        unassignedExclude?: boolean | null;
        waste?: boolean | null;
        wastePolicy?: Array<string> | null;
        wastePolicyExclude?: boolean | null;
        co2eWaste?: boolean | null;
        category?: Array<string> | null;
        categoryExclude?: boolean | null;
        assignmentUpdatedFrom?: string | null;
        assignmentUpdatedTo?: string | null;
        assignmentUpdateExclude?: boolean | null;
        firstSeenAtFrom?: string | null;
        firstSeenAtTo?: string | null;
        firstSeenAtExclude?: boolean | null;
        lastSeenAtFrom?: string | null;
        lastSeenAtTo?: string | null;
        lastSeenAtExclude?: boolean | null;
        lifeSpanFrom?: number | null;
        lifeSpanTo?: number | null;
        lifeSpanExclude?: boolean | null;
        account?: Array<string> | null;
        accountExclude?: boolean | null;
        service?: Array<string> | null;
        serviceExclude?: boolean | null;
        application?: Array<string> | null;
        applicationExclude?: boolean | null;
        environment?: Array<string> | null;
        environmentExclude?: boolean | null;
        region?: Array<string> | null;
        regionExclude?: boolean | null;
        tagsExclude?: boolean | null;
        type?: Array<string> | null;
        typeExclude?: boolean | null;
        subtype?: Array<string> | null;
        subtypeExclude?: boolean | null;
        typeSubtype?: Array<string> | null;
        typeSubtypeExclude?: boolean | null;
        resourceGroup?: Array<string> | null;
        resourceGroupExclude?: boolean | null;
        projectId?: Array<string> | null;
        projectIdExclude?: boolean | null;
        externalIntegration?: Array<string> | null;
        externalIntegrationExclude?: boolean | null;
        enrichment?: boolean | null;
        enrichmentIntegrationId?: Array<string> | null;
        enrichmentIntegrationIdExclude?: boolean | null;
        metadataExclude?: boolean | null;
        providerId?: Array<string> | null;
        exceptProviderId?: Array<string> | null;
        onlyRelated?: boolean | null;
        securityGroup?: Array<string> | null;
        securityGroupExclude?: boolean | null;
        tags?: Array<{ __typename?: "KeyValues"; values: Array<string>; key: string }> | null;
        metadata?: Array<{ __typename?: "KeyValues"; values: Array<string>; key: string }> | null;
      } | null;
    };
  } | null;
};

export type RemoveSearchPresetV2MutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type RemoveSearchPresetV2Mutation = {
  __typename?: "Mutation";
  removeSearchPresetV2?: {
    __typename?: "RemoveSearchPresetV2Response";
    preset: { __typename?: "SearchPresetV2"; id: string };
  } | null;
};

export type ResourceCostSplitRulesQueryVariables = Exact<{
  limit: Scalars["Int"];
  nid: Scalars["String"];
}>;

export type ResourceCostSplitRulesQuery = {
  __typename?: "Query";
  resourceCostSplitRules: {
    __typename?: "ResourceCostSplitRulesResponse";
    count?: number | null;
    result: Array<{
      __typename?: "ResourceCostSplitRule";
      id?: string | null;
      resourceNid: string;
      provider: Provider;
      algorithm: CostMappingAlgorithm;
      settings: {
        __typename?: "CostSplitRuleSettings";
        allEnvironments?: boolean | null;
        environments?: Array<{
          __typename?: "EnvCostSplits";
          id?: string | null;
          splitValue?: string | null;
        }> | null;
      };
    }>;
  };
};

export type AddResourceCostSplitRuleMutationVariables = Exact<{
  input: ResourceCostSplitRuleInput;
}>;

export type AddResourceCostSplitRuleMutation = {
  __typename?: "Mutation";
  addResourceCostSplitRule: {
    __typename?: "ResourceCostSplitRule";
    id?: string | null;
    resourceNid: string;
    provider: Provider;
    algorithm: CostMappingAlgorithm;
    settings: {
      __typename?: "CostSplitRuleSettings";
      allEnvironments?: boolean | null;
      environments?: Array<{
        __typename?: "EnvCostSplits";
        id?: string | null;
        splitValue?: string | null;
      }> | null;
    };
  };
};

export type UpdateResourceCostSplitRuleMutationVariables = Exact<{
  input: ResourceCostSplitRuleUpdateInput;
}>;

export type UpdateResourceCostSplitRuleMutation = {
  __typename?: "Mutation";
  updateResourceCostSplitRule: {
    __typename?: "ResourceCostSplitRule";
    id?: string | null;
    resourceNid: string;
    provider: Provider;
    algorithm: CostMappingAlgorithm;
    settings: {
      __typename?: "CostSplitRuleSettings";
      allEnvironments?: boolean | null;
      environments?: Array<{
        __typename?: "EnvCostSplits";
        id?: string | null;
        splitValue?: string | null;
      }> | null;
    };
  };
};

export type DeleteResourceCostSplitRuleMutationVariables = Exact<{
  deleteResourceCostSplitRuleId: Scalars["ID"];
}>;

export type DeleteResourceCostSplitRuleMutation = {
  __typename?: "Mutation";
  removeResourceCostSplitRule?: boolean | null;
};

export type AttachResourceCostSplitRulesMutationVariables = Exact<{
  input: BulkResourceCostSplitRuleInput;
}>;

export type AttachResourceCostSplitRulesMutation = {
  __typename?: "Mutation";
  attachResourceCostSplitRules: {
    __typename?: "BulkResourceCostSplitRules";
    algorithm: CostMappingAlgorithm;
    resources: Array<{
      __typename?: "ResourcePropsType";
      ruleId: string;
      nid: string;
      provider: Provider;
    }>;
    settings: {
      __typename?: "CostSplitRuleSettings";
      allEnvironments?: boolean | null;
      environments?: Array<{
        __typename?: "EnvCostSplits";
        id?: string | null;
        splitValue?: string | null;
      }> | null;
    };
  };
};

export type SubmitSearchJobMutationVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  limit: Scalars["Int"];
  sort?: InputMaybe<SearchSort>;
  filter?: InputMaybe<EstateV2Filter>;
  query?: InputMaybe<Scalars["String"]>;
}>;

export type SubmitSearchJobMutation = {
  __typename?: "Mutation";
  submitSearchJob?: { __typename?: "SubmitSearchJobResponse"; jobId: string } | null;
};

export type SubmitSearchJobV2MutationVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  limit: Scalars["Int"];
  sort?: InputMaybe<SearchV2Sort>;
  filter?: InputMaybe<SearchV2Filter>;
  query?: InputMaybe<Scalars["String"]>;
}>;

export type SubmitSearchJobV2Mutation = {
  __typename?: "Mutation";
  submitSearchJobV2?: { __typename?: "SubmitSearchJobResponse"; jobId: string } | null;
};

export type SubmitOrgUnitsChartJobMutationVariables = Exact<{
  businessContextNid: Scalars["String"];
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  filter?: InputMaybe<SearchV2Filter>;
}>;

export type SubmitOrgUnitsChartJobMutation = {
  __typename?: "Mutation";
  submitOrganisationUnitsChartJob?: {
    __typename?: "SubmitSearchJobResponse";
    jobId: string;
  } | null;
};

export type SubmitTagsChartJobMutationVariables = Exact<{
  filter?: InputMaybe<SearchV2Filter>;
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  tagKey: Scalars["String"];
}>;

export type SubmitTagsChartJobMutation = {
  __typename?: "Mutation";
  submitTagsChartJob?: { __typename?: "SubmitSearchJobResponse"; jobId: string } | null;
};

export type SearchJobQueryVariables = Exact<{
  jobId: Scalars["ID"];
  isLite: Scalars["Boolean"];
}>;

export type SearchJobQuery = {
  __typename?: "Query";
  searchJob?: {
    __typename?: "SearchJobStatusResponse";
    status: SearchJobStatus;
    data?: {
      __typename?: "ResourceResponse";
      count?: number | null;
      pages?: number | null;
      cost?: {
        __typename?: "ResourcesCost";
        currentMonthTotal: string;
        monthlyForecast?: string | null;
      } | null;
      savingsAmount?: { __typename?: "ResourceSavingsAmount"; total?: string | null } | null;
      resources?: Array<
        | {
            __typename?: "CloudResource";
            id: string;
            provider: Provider;
            providerId: string;
            providerType?: string | null;
            name: string;
            category?: string | null;
            region?: string | null;
            cloudAccountId?: string | null;
            searchCost?: string | null;
            cost?: {
              __typename?: "Cost";
              currentMonth: string;
              forecast?: string | null;
              count?: number | null;
            } | null;
            environment?: {
              __typename?: "Environment";
              id: string;
              nid: string;
              name: string;
              application: { __typename?: "Application"; id: string; nid: string; name: string };
            } | null;
            cloudWaste?: {
              __typename?: "CloudWaste";
              isWaste: boolean;
              reason?: string | null;
            } | null;
            savingsAmount?: {
              __typename?: "SavingsAmount";
              amount: string;
              count: number;
              co2e: string;
            } | null;
            co2e?: { __typename?: "Co2e"; currentMonth: string } | null;
            assignments?: { __typename?: "Assignments"; appCount: number; envCount: number } | null;
          }
        | {
            __typename?: "CostResource";
            id: string;
            provider: Provider;
            providerType?: string | null;
            searchCost?: string | null;
            assignments?: { __typename?: "Assignments"; appCount: number; envCount: number } | null;
            cost?: { __typename?: "Cost"; currentMonth: string; forecast?: string | null } | null;
          }
      > | null;
    } | null;
  } | null;
};

export type SubmitChartJobMutationVariables = Exact<{
  filter?: InputMaybe<SearchV2Filter>;
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  chartType?: InputMaybe<ChartType>;
}>;

export type SubmitChartJobMutation = {
  __typename?: "Mutation";
  submitChartJobV2?: { __typename?: "SubmitSearchJobResponse"; jobId: string } | null;
};

export type ChartResponseFragment = {
  __typename?: "ChartJobStatusResponse";
  status: SearchJobStatus;
  data?: {
    __typename?: "ChartJobData";
    total: string;
    count: number;
    extraDetails?: Array<{
      __typename?: "ChartJobExtraDetails";
      name: string;
      value: string;
    } | null> | null;
    timePoints?: Array<{
      __typename?: "ChartGroupTimePoint";
      date: string;
      value: string;
      groups?: Array<{
        __typename?: "ChartGroupCost";
        id: string;
        name: string;
        value: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ChartJobQueryVariables = Exact<{
  jobId: Scalars["ID"];
  top?: InputMaybe<Scalars["Int"]>;
}>;

export type ChartJobQuery = {
  __typename?: "Query";
  chartJob?: {
    __typename?: "ChartJobStatusResponse";
    status: SearchJobStatus;
    data?: {
      __typename?: "ChartJobData";
      total: string;
      count: number;
      extraDetails?: Array<{
        __typename?: "ChartJobExtraDetails";
        name: string;
        value: string;
      } | null> | null;
      timePoints?: Array<{
        __typename?: "ChartGroupTimePoint";
        date: string;
        value: string;
        groups?: Array<{
          __typename?: "ChartGroupCost";
          id: string;
          name: string;
          value: string;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
  chartMonthlyJob?: {
    __typename?: "ChartJobStatusResponse";
    status: SearchJobStatus;
    data?: {
      __typename?: "ChartJobData";
      total: string;
      count: number;
      extraDetails?: Array<{
        __typename?: "ChartJobExtraDetails";
        name: string;
        value: string;
      } | null> | null;
      timePoints?: Array<{
        __typename?: "ChartGroupTimePoint";
        date: string;
        value: string;
        groups?: Array<{
          __typename?: "ChartGroupCost";
          id: string;
          name: string;
          value: string;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ChartJobTableQueryVariables = Exact<{
  jobId: Scalars["ID"];
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<ChartTableSort>;
}>;

export type ChartJobTableQuery = {
  __typename?: "Query";
  chartJobTable?: {
    __typename?: "ChartJobTableResponse";
    status: SearchJobStatus;
    data?: {
      __typename?: "ChartJobTableData";
      count: number;
      pages: number;
      results?: Array<{
        __typename?: "ChartJobTableTimePoint";
        date: string;
        total: string;
        groups?: Array<{
          __typename?: "ChartJobTableGroup";
          cost: string;
          key: string;
          name: string;
        }> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type CloudServicesQueryVariables = Exact<{ [key: string]: never }>;

export type CloudServicesQuery = {
  __typename?: "Query";
  services: Array<{ __typename?: "Service"; id: string; name: string }>;
};

export type TagKeysQueryVariables = Exact<{
  startDate: Scalars["String"];
  endDate: Scalars["String"];
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
}>;

export type TagKeysQuery = {
  __typename?: "Query";
  listTagKeys?: {
    __typename?: "ListUniqueTagKeysResponse";
    tagKeys?: Array<string | null> | null;
    count?: number | null;
    pages?: number | null;
  } | null;
};

export type ApplicationsPaginatedByApplicationNameQueryVariables = Exact<{
  limit: Scalars["Int"];
  page?: InputMaybe<Scalars["Int"]>;
  applicationName?: InputMaybe<Scalars["String"]>;
}>;

export type ApplicationsPaginatedByApplicationNameQuery = {
  __typename?: "Query";
  applicationsPaginated?: {
    __typename?: "ApplicationsPaginatedResponse";
    count?: number | null;
    pages?: number | null;
    results?: Array<{
      __typename?: "Application";
      id: string;
      nid: string;
      name: string;
      environments?: Array<{
        __typename?: "Environment";
        name: string;
        id: string;
        nid: string;
      } | null> | null;
    }> | null;
  } | null;
};

export type SearchPresetV2Fragment = {
  __typename?: "SearchPresetV2";
  id: string;
  name: string;
  query?: string | null;
  createdAt: string;
  updatedAt: string;
  sort?: {
    __typename?: "SearchPresetSortV2";
    field: SearchV2SortField;
    order: SearchSortOrder;
  } | null;
  filter?: {
    __typename?: "SearchPresetFilterV2";
    provider?: Array<Provider> | null;
    providerExclude?: boolean | null;
    costFrom?: number | null;
    costTo?: number | null;
    costExclude?: boolean | null;
    unassigned?: boolean | null;
    unassignedExclude?: boolean | null;
    waste?: boolean | null;
    wastePolicy?: Array<string> | null;
    wastePolicyExclude?: boolean | null;
    co2eWaste?: boolean | null;
    category?: Array<string> | null;
    categoryExclude?: boolean | null;
    assignmentUpdatedFrom?: string | null;
    assignmentUpdatedTo?: string | null;
    assignmentUpdateExclude?: boolean | null;
    firstSeenAtFrom?: string | null;
    firstSeenAtTo?: string | null;
    firstSeenAtExclude?: boolean | null;
    lastSeenAtFrom?: string | null;
    lastSeenAtTo?: string | null;
    lastSeenAtExclude?: boolean | null;
    lifeSpanFrom?: number | null;
    lifeSpanTo?: number | null;
    lifeSpanExclude?: boolean | null;
    account?: Array<string> | null;
    accountExclude?: boolean | null;
    service?: Array<string> | null;
    serviceExclude?: boolean | null;
    application?: Array<string> | null;
    applicationExclude?: boolean | null;
    environment?: Array<string> | null;
    environmentExclude?: boolean | null;
    region?: Array<string> | null;
    regionExclude?: boolean | null;
    tagsExclude?: boolean | null;
    type?: Array<string> | null;
    typeExclude?: boolean | null;
    subtype?: Array<string> | null;
    subtypeExclude?: boolean | null;
    typeSubtype?: Array<string> | null;
    typeSubtypeExclude?: boolean | null;
    resourceGroup?: Array<string> | null;
    resourceGroupExclude?: boolean | null;
    projectId?: Array<string> | null;
    projectIdExclude?: boolean | null;
    externalIntegration?: Array<string> | null;
    externalIntegrationExclude?: boolean | null;
    enrichment?: boolean | null;
    enrichmentIntegrationId?: Array<string> | null;
    enrichmentIntegrationIdExclude?: boolean | null;
    metadataExclude?: boolean | null;
    providerId?: Array<string> | null;
    exceptProviderId?: Array<string> | null;
    onlyRelated?: boolean | null;
    securityGroup?: Array<string> | null;
    securityGroupExclude?: boolean | null;
    tags?: Array<{ __typename?: "KeyValues"; values: Array<string>; key: string }> | null;
    metadata?: Array<{ __typename?: "KeyValues"; values: Array<string>; key: string }> | null;
  } | null;
};

export type ExternalIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type ExternalIntegrationsQuery = {
  __typename?: "Query";
  externalIntegrations?: {
    __typename?: "ExternalIntegrationsResponse";
    integrations?: Array<{
      __typename?: "ExternalIntegrationConfig";
      id: string;
      name: string;
      createdAt: string;
      lastSync?: string | null;
    } | null> | null;
  } | null;
};

export type AddExternalIntegrationMutationVariables = Exact<{
  integration: ExternalIntegration;
}>;

export type AddExternalIntegrationMutation = {
  __typename?: "Mutation";
  addExternalIntegration?: {
    __typename?: "ExternalIntegrationResponse";
    id: string;
    nid: string;
    secret: string;
  } | null;
};

export type RegenerateExternalIntegrationSecretMutationVariables = Exact<{
  integrationId: Scalars["String"];
}>;

export type RegenerateExternalIntegrationSecretMutation = {
  __typename?: "Mutation";
  regenerateExternalIntegrationSecret?: {
    __typename?: "ExternalIntegrationResponse";
    id: string;
    secret: string;
  } | null;
};

export type ExternalIntegrationDetailsQueryVariables = Exact<{
  integrationId: Scalars["String"];
}>;

export type ExternalIntegrationDetailsQuery = {
  __typename?: "Query";
  externalIntegrationDetails?: {
    __typename?: "ExternalIntegrationDetailsResponse";
    id: string;
    name?: string | null;
    description?: string | null;
    ownerId?: string | null;
    createdAt?: string | null;
    lastSync?: string | null;
  } | null;
};

export type AddSandboxMutationVariables = Exact<{
  input: AddSandbox;
}>;

export type AddSandboxMutation = {
  __typename?: "Mutation";
  addSandbox?: { __typename?: "MutationResponse"; id?: string | null } | null;
};

export type KpiTargetDetailsFragment = {
  __typename?: "KpiTarget";
  id: string;
  name: string;
  goal: number;
  startDate: string;
  endDate: string;
};

export type KpiTargetFieldsFragment = {
  __typename?: "KpiTarget";
  id: string;
  name: string;
  goal: number;
  startDate: string;
  endDate: string;
  contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
};

export type KpiDetailsFragment = {
  __typename?: "KpiDetails";
  metrics?: {
    __typename?: "KpiMetric";
    currentValue?: string | null;
    values: Array<{
      __typename?: "EnhancedTimePoint";
      date: string;
      value: string;
      additionalFields?: Array<{ __typename?: "KeyValue"; key: string; value: string }> | null;
    }>;
  } | null;
  targets?: {
    __typename?: "KpiTargets";
    targets: Array<{
      __typename?: "KpiTarget";
      id: string;
      name: string;
      goal: number;
      startDate: string;
      endDate: string;
    }>;
    currentTarget?: {
      __typename?: "KpiTarget";
      id: string;
      name: string;
      goal: number;
      startDate: string;
      endDate: string;
      contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
    } | null;
  } | null;
};

export type BusinessContextKpiQueryVariables = Exact<{
  bcId: Scalars["ID"];
  timePeriod: KpiTimePeriod;
  fetchCostAllocation: Scalars["Boolean"];
  fetchOverallWaste: Scalars["Boolean"];
  fetchNonProdHours: Scalars["Boolean"];
}>;

export type BusinessContextKpiQuery = {
  __typename?: "Query";
  businessContext?: {
    __typename?: "BusinessContext";
    id: string;
    costAllocation: { __typename?: "CostAllocation"; allocated: string };
    kpis: {
      __typename?: "BusinessContextKpis";
      costAllocationCoverage?: {
        __typename?: "KpiCostAllocationCoverage";
        details: {
          __typename?: "KpiDetails";
          metrics?: {
            __typename?: "KpiMetric";
            currentValue?: string | null;
            values: Array<{
              __typename?: "EnhancedTimePoint";
              date: string;
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            }>;
          } | null;
          targets?: {
            __typename?: "KpiTargets";
            targets: Array<{
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
            }>;
            currentTarget?: {
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
              contactPerson?: {
                __typename?: "Person";
                name: string;
                id: string;
                email: string;
              } | null;
            } | null;
          } | null;
        };
      };
      overallWaste?: {
        __typename?: "KpiOverallWasteBusinessContext";
        details: {
          __typename?: "KpiDetails";
          metrics?: {
            __typename?: "KpiMetric";
            currentValue?: string | null;
            values: Array<{
              __typename?: "EnhancedTimePoint";
              date: string;
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            }>;
          } | null;
          targets?: {
            __typename?: "KpiTargets";
            targets: Array<{
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
            }>;
            currentTarget?: {
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
              contactPerson?: {
                __typename?: "Person";
                name: string;
                id: string;
                email: string;
              } | null;
            } | null;
          } | null;
        };
        worst?: {
          __typename?: "KpiOverallWasteOrgUnits";
          orgUnits?: Array<{
            __typename?: "KpiOverallWasteOrgUnit";
            kpiValue: string;
            currentWaste: string;
            orgUnit: { __typename?: "OrgUnitV2"; id: string; nid: string; name: string };
          }> | null;
        } | null;
      };
      nonProdHours?: {
        __typename?: "KpiNonProdHoursBusinessContext";
        details: {
          __typename?: "KpiDetails";
          metrics?: {
            __typename?: "KpiMetric";
            currentValue?: string | null;
            values: Array<{
              __typename?: "EnhancedTimePoint";
              date: string;
              value: string;
              additionalFields?: Array<{
                __typename?: "KeyValue";
                key: string;
                value: string;
              }> | null;
            }>;
          } | null;
          targets?: {
            __typename?: "KpiTargets";
            targets: Array<{
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
            }>;
            currentTarget?: {
              __typename?: "KpiTarget";
              id: string;
              name: string;
              goal: number;
              startDate: string;
              endDate: string;
              contactPerson?: {
                __typename?: "Person";
                name: string;
                id: string;
                email: string;
              } | null;
            } | null;
          } | null;
        };
        worst?: {
          __typename?: "KpiNonProdHoursOrgUnits";
          orgUnits?: Array<{
            __typename?: "KpiNonProdHoursOrgUnit";
            kpiValue: string;
            hours: string;
            orgUnit: { __typename?: "OrgUnitV2"; id: string; nid: string; name: string };
          }> | null;
        } | null;
      };
    };
  } | null;
};

export type BusinessContextTargetsQueryVariables = Exact<{
  bcId: Scalars["ID"];
  selection: KpiTargetSelection;
  fetchCostAllocation: Scalars["Boolean"];
  fetchOverallWaste: Scalars["Boolean"];
  fetchNonProdHours: Scalars["Boolean"];
}>;

export type BusinessContextTargetsQuery = {
  __typename?: "Query";
  businessContext?: {
    __typename?: "BusinessContext";
    id: string;
    kpis: {
      __typename?: "BusinessContextKpis";
      costAllocationCoverage?: {
        __typename?: "KpiCostAllocationCoverage";
        targets?: Array<{
          __typename?: "KpiTarget";
          id: string;
          name: string;
          goal: number;
          startDate: string;
          endDate: string;
          contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
        }> | null;
      };
      overallWaste?: {
        __typename?: "KpiOverallWasteBusinessContext";
        targets?: Array<{
          __typename?: "KpiTarget";
          id: string;
          name: string;
          goal: number;
          startDate: string;
          endDate: string;
          contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
        }> | null;
      };
      nonProdHours?: {
        __typename?: "KpiNonProdHoursBusinessContext";
        targets?: Array<{
          __typename?: "KpiTarget";
          id: string;
          name: string;
          goal: number;
          startDate: string;
          endDate: string;
          contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
        }> | null;
      };
    };
  } | null;
};

export type ArchiveKpiTargetMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ArchiveKpiTargetMutation = {
  __typename?: "Mutation";
  archiveKPITarget: {
    __typename?: "KpiTarget";
    id: string;
    name: string;
    goal: number;
    startDate: string;
    endDate: string;
    contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
  };
};

export type DashboardBusinessContextKpiWidgetsQueryVariables = Exact<{
  bcId: Scalars["ID"];
}>;

export type DashboardBusinessContextKpiWidgetsQuery = {
  __typename?: "Query";
  businessContext?: {
    __typename?: "BusinessContext";
    id: string;
    name: string;
    costAllocation: { __typename?: "CostAllocation"; allocated: string };
    kpis: {
      __typename?: "BusinessContextKpis";
      costAllocationCoverage: {
        __typename?: "KpiCostAllocationCoverage";
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
      nonProdHours: {
        __typename?: "KpiNonProdHoursBusinessContext";
        totalHours: string;
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
      overallWaste: {
        __typename?: "KpiOverallWasteBusinessContext";
        totalWaste: string;
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
    };
  } | null;
};

export type AddKpiTargetMutationVariables = Exact<{
  input: AddKpiTarget;
}>;

export type AddKpiTargetMutation = {
  __typename?: "Mutation";
  addKPITarget: {
    __typename?: "KpiTarget";
    id: string;
    name: string;
    goal: number;
    startDate: string;
    endDate: string;
    contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
  };
};

export type UpdateKpiTargetMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateKpiTarget;
}>;

export type UpdateKpiTargetMutation = {
  __typename?: "Mutation";
  updateKPITarget: {
    __typename?: "KpiTarget";
    id: string;
    name: string;
    goal: number;
    startDate: string;
    endDate: string;
    contactPerson?: { __typename?: "Person"; name: string; id: string; email: string } | null;
  };
};

export type BusinessContextsSimpleQueryVariables = Exact<{ [key: string]: never }>;

export type BusinessContextsSimpleQuery = {
  __typename?: "Query";
  businessContexts?: {
    __typename?: "BusinessContextResponse";
    businessContexts?: Array<{
      __typename?: "BusinessContext";
      id: string;
      name: string;
      color?: string | null;
    }> | null;
  } | null;
};

export type TeamLeaderDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type TeamLeaderDashboardQuery = {
  __typename?: "Query";
  applications?: Array<{
    __typename?: "Application";
    id: string;
    nid: string;
    name: string;
    displayName: string;
    cost?: {
      __typename?: "Cost";
      currentMonth: string;
      currentMonthDaily?: Array<string | null> | null;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    environments?: Array<{
      __typename?: "Environment";
      id: string;
      resourceCount: number;
    } | null> | null;
  } | null> | null;
};

export type CloudWasteSummaryCategorisedQueryVariables = Exact<{ [key: string]: never }>;

export type CloudWasteSummaryCategorisedQuery = {
  __typename?: "Query";
  cloudWasteSummaryCategorised?: {
    __typename?: "CloudWasteSummaryCategorisedResponse";
    totalCost?: string | null;
    totalCostYearly?: string | null;
    categories?: Array<{
      __typename?: "CloudWastePolicySummaryCategorised";
      category?: CloudWasteCategoryKey | null;
      cost?: string | null;
      costYearly?: string | null;
      count?: number | null;
      policies?: Array<CloudWasteSettingKey | null> | null;
    } | null> | null;
  } | null;
};

export type GetPrimaryBcQueryVariables = Exact<{ [key: string]: never }>;

export type GetPrimaryBcQuery = {
  __typename?: "Query";
  primaryBusinessContext?: { __typename?: "BusinessContext"; id: string; name: string } | null;
};

export type DashboardKpiWidgetsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardKpiWidgetsQuery = {
  __typename?: "Query";
  primaryBusinessContext?: {
    __typename?: "BusinessContext";
    id: string;
    name: string;
    costAllocation: { __typename?: "CostAllocation"; allocated: string };
    kpis: {
      __typename?: "BusinessContextKpis";
      costAllocationCoverage: {
        __typename?: "KpiCostAllocationCoverage";
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
      nonProdHours: {
        __typename?: "KpiNonProdHoursBusinessContext";
        totalHours: string;
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
      overallWaste: {
        __typename?: "KpiOverallWasteBusinessContext";
        totalWaste: string;
        details: {
          __typename?: "KpiDetails";
          targets?: {
            __typename?: "KpiTargets";
            currentTarget?: { __typename?: "KpiTarget"; id: string; goal: number } | null;
          } | null;
          metrics?: { __typename?: "KpiMetric"; currentValue?: string | null } | null;
        };
      };
    };
  } | null;
};

export type ReservationsAndCommitmentsSavingsQueryVariables = Exact<{
  paymentOption: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
}>;

export type ReservationsAndCommitmentsSavingsQuery = {
  __typename?: "Query";
  reservationsAndCommitmentsSavings?: {
    __typename?: "ReservationsAndCommitmentsSavingsData";
    totalMonthlySavings?: string | null;
    totalYearlySavings?: string | null;
  } | null;
};

export type AzureReservationsSavingsQueryVariables = Exact<{
  paymentOption: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
  serviceType: ReservationsRecommendationServiceType;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
}>;

export type AzureReservationsSavingsQuery = {
  __typename?: "Query";
  Compute: { __typename?: "ReservationsRecommendationsSummary"; monthlySavings: string };
  Database: { __typename?: "ReservationsRecommendationsSummary"; monthlySavings: string };
  Storage: { __typename?: "ReservationsRecommendationsSummary"; monthlySavings: string };
  Analytics: { __typename?: "ReservationsRecommendationsSummary"; monthlySavings: string };
};

export type EstateRecordsAccountStatusQueryVariables = Exact<{ [key: string]: never }>;

export type EstateRecordsAccountStatusQuery = {
  __typename?: "Query";
  accounts?: Array<{ __typename?: "Account"; id: string; activated: boolean } | null> | null;
};

export type ApplicationsCountQueryVariables = Exact<{ [key: string]: never }>;

export type ApplicationsCountQuery = {
  __typename?: "Query";
  applicationsPaginated?: {
    __typename?: "ApplicationsPaginatedResponse";
    count?: number | null;
  } | null;
};

export type DriftedResourcesCountQueryVariables = Exact<{ [key: string]: never }>;

export type DriftedResourcesCountQuery = {
  __typename?: "Query";
  driftedResources?: { __typename?: "DriftedResourcesResponse"; count: number } | null;
};

export type OrganizationCostQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationCostQuery = {
  __typename?: "Query";
  organization?: {
    __typename?: "Organization";
    id: string;
    cost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      previousMonthDayToDay?: string | null;
      forecast?: string | null;
    } | null;
  } | null;
};

export type BaseCostFragment = {
  __typename?: "Cost";
  currentMonth: string;
  previousMonth: string;
  forecast?: string | null;
};

export type GlobalCostByProviderQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalCostByProviderQuery = {
  __typename?: "Query";
  organization?: {
    __typename?: "Organization";
    id: string;
    awsCost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    azureCost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    gcpCost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    ibmCloudCost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    kubernetesCost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
    openShiftCost?: {
      __typename?: "Cost";
      currentMonth: string;
      previousMonth: string;
      forecast?: string | null;
    } | null;
  } | null;
};

export type SpendsFragment = {
  __typename?: "DashboardApplicationCost";
  nid: string;
  name: string;
  currentMonth: string;
  previousMonth: string;
  forecast: string;
};

export type TopSpendingApplicationsQueryVariables = Exact<{
  limit: Scalars["Int"];
}>;

export type TopSpendingApplicationsQuery = {
  __typename?: "Query";
  topSpendingApplications: {
    __typename?: "TopSpendingApplicationsResponse";
    topSpending?: Array<{
      __typename?: "DashboardApplicationCost";
      nid: string;
      name: string;
      currentMonth: string;
      previousMonth: string;
      forecast: string;
    }> | null;
    defaultApplication?: {
      __typename?: "DashboardApplicationCost";
      nid: string;
      name: string;
      currentMonth: string;
      previousMonth: string;
      forecast: string;
    } | null;
    other: {
      __typename?: "DashboardApplicationCost";
      nid: string;
      name: string;
      currentMonth: string;
      previousMonth: string;
      forecast: string;
    };
    total: { __typename?: "DashboardApplicationCostTotal"; currentMonth: string };
  };
};

export type IntegrationPluginsQueryVariables = Exact<{
  filter?: InputMaybe<IntegrationPluginsInput>;
}>;

export type IntegrationPluginsQuery = {
  __typename?: "Query";
  integrationPlugins: Array<{
    __typename?: "IntegrationPlugin";
    id: string;
    name: string;
    description?: string | null;
    lastSyncTime: string;
    lastSyncStatus?: IntegrationPluginSyncStatus | null;
    integrationType: IntegrationPluginType;
    createdAt: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string }>;
  }>;
};

export type TurbonomicIntegrationPluginQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TurbonomicIntegrationPluginQuery = {
  __typename?: "Query";
  turbonomicIntegrationPlugin?: {
    __typename?: "TurbonomicIntegrationPlugin";
    id: string;
    name: string;
    description?: string | null;
    lastSyncTime: string;
    lastSyncStatus?: IntegrationPluginSyncStatus | null;
    integrationType: IntegrationPluginType;
    createdAt: string;
    url: string;
    username: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string }>;
  } | null;
};

export type RemoveIntegrationPluginMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveIntegrationPluginMutation = {
  __typename?: "Mutation";
  removeIntegrationPlugin?: boolean | null;
};

export type AddTurbonomicIntegrationPluginV2MutationVariables = Exact<{
  input: AddTurbonomicIntegrationPluginInput;
}>;

export type AddTurbonomicIntegrationPluginV2Mutation = {
  __typename?: "Mutation";
  addTurbonomicIntegrationPluginV2?: {
    __typename?: "IntegrationPlugin";
    id: string;
    name: string;
    description?: string | null;
    lastSyncTime: string;
    lastSyncStatus?: IntegrationPluginSyncStatus | null;
    integrationType: IntegrationPluginType;
    createdAt: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string }>;
  } | null;
};

export type TurbonomicConnectionStatusQueryVariables = Exact<{
  connection: TurbonomicConnectionInput;
}>;

export type TurbonomicConnectionStatusQuery = {
  __typename?: "Query";
  turbonomicConnectionStatus: ConnectionStatus;
};

export type TurbonomicPluginConnectionStatusQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TurbonomicPluginConnectionStatusQuery = {
  __typename?: "Query";
  turbonomicPluginConnectionStatus: ConnectionStatus;
};

export type UpdateTurbonomicIntegrationPluginMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateTurbonomicIntegrationPluginInput;
}>;

export type UpdateTurbonomicIntegrationPluginMutation = {
  __typename?: "Mutation";
  updateTurbonomicIntegrationPlugin?: {
    __typename?: "IntegrationPlugin";
    id: string;
    name: string;
    description?: string | null;
    integrationType: IntegrationPluginType;
    createdAt: string;
    lastSyncStatus?: IntegrationPluginSyncStatus | null;
    lastSyncTime: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string }>;
  } | null;
};

export type PeopleQueryVariables = Exact<{ [key: string]: never }>;

export type PeopleQuery = {
  __typename?: "Query";
  people?: Array<{ __typename?: "Person"; id: string; name: string; email: string } | null> | null;
};

export type PersonFieldsFragment = {
  __typename?: "Person";
  id: string;
  name: string;
  email: string;
};

export type ReservationsRecommendationsSummaryQueryVariables = Exact<{
  paymentOption: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
  serviceType: ReservationsRecommendationServiceType;
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  filters?: InputMaybe<ReservationsAndCommitmentsFilters>;
  provider?: InputMaybe<ReservationsProvider>;
}>;

export type ReservationsRecommendationsSummaryQuery = {
  __typename?: "Query";
  reservationsRecommendationsSummary: {
    __typename?: "ReservationsRecommendationsSummary";
    monthlySavings: string;
    recurringMonthlyCost: string;
    upfrontCost: string;
    yearlySavings: string;
    savingsRate: string;
    count?: number | null;
    savingsPlansCommitments?: Array<{
      __typename?: "SavingsPlansCommitment";
      account?: string | null;
      savingsPerHour?: string | null;
      onDemandCostPerHour?: string | null;
      savingsPlansHourlyCommitmentToPurchase?: string | null;
    } | null> | null;
  };
};

export type ListReservationsAndCommitmentsCoverageQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
  paymentOption?: InputMaybe<ReservationsRecommendationPaymentOption>;
  term?: InputMaybe<ReservationsRecommendationTerm>;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  filters?: InputMaybe<ReservationsAndCommitmentsFilters>;
  provider?: InputMaybe<ReservationsProvider>;
}>;

export type ListReservationsAndCommitmentsCoverageQuery = {
  __typename?: "Query";
  listReservationsAndCommitmentsCoverage?: {
    __typename?: "ReservationsAndCommitmentsCoveragePaginated";
    pages?: number | null;
    count?: number | null;
    groupedResources?: Array<{
      __typename?: "GroupedResources";
      platform?: string | null;
      resources?: Array<{
        __typename?: "ReservationCoveredAndCommitmentType";
        resource?: string | null;
        resourceTypesCount?: number | null;
        platform?: string | null;
        region?: string | null;
        provider?: string | null;
        averageCoverage?: string | null;
        totalCoveredHours?: string | null;
        totalOnDemandHours?: string | null;
        totalOnDemandCost?: string | null;
        coverageSavedCost?: string | null;
        measurementUnit?: string | null;
        hasCoverageResourceList?: boolean | null;
        offeringType?: string | null;
        hasRecommendations?: boolean | null;
        potentialSavings?: string | null;
        onDemandCostPercent?: string | null;
        riCostPercent?: string | null;
        spCostPercent?: string | null;
        groupByParameter?: string | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type ListReservationsAndCommitmentsCoverageDetailsQueryVariables = Exact<{
  region: Scalars["String"];
  platform: Scalars["String"];
  resourceType: Scalars["String"];
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  provider?: InputMaybe<ReservationsProvider>;
}>;

export type ListReservationsAndCommitmentsCoverageDetailsQuery = {
  __typename?: "Query";
  listReservationsAndCommitmentsCoverageDetails?: {
    __typename?: "ListCommitmentsCoverageDetailsResponse";
    resources?: Array<{
      __typename?: "CommitmentsCoverageDetailsResponse";
      resource?: string | null;
      resourceTypesCount?: number | null;
      platform?: string | null;
      potentialSavings?: string | null;
      region?: string | null;
      provider?: string | null;
      averageCoverage?: string | null;
      totalCoveredHours?: string | null;
      totalOnDemandHours?: string | null;
      totalOnDemandCost?: string | null;
      coverageSavedCost?: string | null;
      measurementUnit?: string | null;
      onDemandCostPercent?: string | null;
      riCostPercent?: string | null;
      spCostPercent?: string | null;
    }> | null;
  } | null;
};

export type ReservationsFiltersQueryVariables = Exact<{
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod;
  provider: ReservationsProvider;
}>;

export type ReservationsFiltersQuery = {
  __typename?: "Query";
  reservationsFilters?: {
    __typename?: "ReservationsFiltersResponse";
    regionsList?: Array<string | null> | null;
    resourcesList?: Array<string | null> | null;
    platformsList?: Array<string | null> | null;
  } | null;
};

export type ReservationsAndCommitmentUsageCoverageQueryVariables = Exact<{
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  filters?: InputMaybe<ReservationsAndCommitmentsFilters>;
  provider?: InputMaybe<ReservationsProvider>;
}>;

export type ReservationsAndCommitmentUsageCoverageQuery = {
  __typename?: "Query";
  reservationsAndCommitmentUsageCoverage?: {
    __typename?: "ReservationsAndCommitmentsUsageChartCoverage";
    averageCoverage?: string | null;
    riAverageCoverage?: string | null;
    spAverageCoverage?: string | null;
    totalOnDemandCost?: string | null;
    savedCost?: string | null;
    timeSeries?: Array<{
      __typename?: "ReservationUsageChartTimeSeries";
      date?: string | null;
      riCost?: string | null;
      spCost?: string | null;
      onDemandCost?: string | null;
      totalCost?: string | null;
    } | null> | null;
  } | null;
};

export type GetReservationsAndCommitmentsRecommendationsQueryVariables = Exact<{
  resourceType: Scalars["String"];
  region: Scalars["String"];
  platform: Scalars["String"];
  provider: Scalars["String"];
  paymentOption: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
  offeringType: ReservationsAndCommitmentsCoverageOfferingType;
  serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
  lookbackPeriod?: InputMaybe<ReservationsAndCommitmentsLookbackPeriod>;
}>;

export type GetReservationsAndCommitmentsRecommendationsQuery = {
  __typename?: "Query";
  getReservationsAndCommitmentsRecommendations?: {
    __typename?: "ReservationsAndCommitmentsRecommendationsResponse";
    expectedUtilization?: string | null;
    instanceType?: string | null;
    monthlySavings?: string | null;
    monthlySavingsPercentage?: string | null;
    numberOfInstances?: number | null;
    recurringMonthlyCost?: string | null;
    upfrontCost?: string | null;
    yearlySavings?: string | null;
    startSavings?: string | null;
    breakEvenPointXAxis?: string | null;
    breakEvenPointYAxis?: string | null;
    timeSeries?: Array<{
      __typename?: "RecommendationTimeSeriesResponse";
      label?: string | null;
      onDemandCost?: string | null;
      riCost?: string | null;
    }> | null;
  } | null;
};

export type ReservationsAndMetadataQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  offeringType?: InputMaybe<ReservationsAndCommitmentsCoverageOfferingType>;
  serviceUsageType?: InputMaybe<ReservationsAndCommitmentsCoverageServiceUsageType>;
  onlyCount?: InputMaybe<Scalars["Boolean"]>;
  filters?: InputMaybe<ReservationAndMetadataFilters>;
  provider?: InputMaybe<ReservationsProvider>;
}>;

export type ReservationsAndMetadataQuery = {
  __typename?: "Query";
  reservationsAndMetadata?: {
    __typename?: "ReservationsPaginatedItem";
    count?: number | null;
    pages?: number | null;
    filters?: {
      __typename?: "ActiveReservationsFilters";
      platforms?: Array<string> | null;
      resourceTypes?: Array<string> | null;
    } | null;
    reservations?: Array<{
      __typename?: "ReservationAndMetadataItem";
      reservationId: string;
      from: string;
      to: string;
      paymentOption?: string | null;
      instanceType?: string | null;
      productDescription?: string | null;
      region?: string | null;
      unitsCount?: number | null;
      expirationTimeInDays?: number | null;
      expirationTimeInYears?: string | null;
      offeringClass?: string | null;
      commitment?: string | null;
      savingsPlanType?: string | null;
      ec2InstanceFamily?: string | null;
      billingPlan?: string | null;
      originalQuantity?: number | null;
      skuName?: string | null;
      yearlyUtilization?: {
        __typename?: "UtilisationResponse";
        utilization?: string | null;
        benefit?: string | null;
      } | null;
    }> | null;
  } | null;
};

export type ReservationsActiveTabsQueryVariables = Exact<{
  paymentOption: ReservationsRecommendationPaymentOption;
  term: ReservationsRecommendationTerm;
  lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod;
  provider: Scalars["String"];
}>;

export type ReservationsActiveTabsQuery = {
  __typename?: "Query";
  reservationsActiveTabs?: {
    __typename?: "ReservationsActiveServicesResponse";
    tabs?: Array<{
      __typename?: "ReservationCommitmentsActiveServices";
      isActive: boolean;
      serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType;
    }> | null;
  } | null;
};

export type CloudWasteSettingsCategorisedQueryVariables = Exact<{ [key: string]: never }>;

export type CloudWasteSettingsCategorisedQuery = {
  __typename?: "Query";
  cloudWasteSettingsCategorised: Array<{
    __typename?: "CloudWasteSettingCategorised";
    category: CloudWasteCategoryKey;
    settings: Array<{
      __typename?: "CloudWasteSetting";
      id: CloudWasteSettingKey;
      name: string;
      description: string;
      status: CloudWasteSettingStatus;
      parameters: Array<{
        __typename?: "CloudWasteSettingParameterInt";
        id: CloudWasteSettingParameterId;
        description: string;
        value: number;
        min: number;
        max: number;
      }>;
    }>;
  }>;
};

export type UpdateCloudWasteSettingMutationVariables = Exact<{
  input: UpdateCloudWasteSetting;
}>;

export type UpdateCloudWasteSettingMutation = {
  __typename?: "Mutation";
  updateCloudWasteSetting?: boolean | null;
};

export type GlobalTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalTagsQuery = {
  __typename?: "Query";
  globalTagKeys?: {
    __typename?: "GlobalTagKeys";
    application: Array<string | null>;
    environment: Array<string | null>;
  } | null;
};

export type UpdateGlobalKeysMutationVariables = Exact<{
  application?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  environment?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type UpdateGlobalKeysMutation = {
  __typename?: "Mutation";
  updateGlobalTagKeys?: boolean | null;
};

export type CostMappingsQueryVariables = Exact<{ [key: string]: never }>;

export type CostMappingsQuery = {
  __typename?: "Query";
  costMappings?: Array<{
    __typename?: "CostCategoryMapping";
    id?: string | null;
    type?: string | null;
    provider?: Provider | null;
    algorithm?: CostMappingAlgorithm | null;
    settings?: {
      __typename?: "CostSettings";
      allEnvironments?: boolean | null;
      environments?: Array<{
        __typename?: "EnvCostShare";
        id: string;
        splitValue?: string | null;
      }> | null;
    } | null;
  } | null> | null;
};

export type UpdateCostMappingRuleAlgorithmMutationVariables = Exact<{
  id: Scalars["String"];
  algorithm?: InputMaybe<CostMappingAlgorithm>;
  settings?: InputMaybe<CostSettingsInput>;
}>;

export type UpdateCostMappingRuleAlgorithmMutation = {
  __typename?: "Mutation";
  updateCostCategoryMapping?: boolean | null;
};

export type DefaultApplicationQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultApplicationQuery = {
  __typename?: "Query";
  applications?: Array<{
    __typename?: "Application";
    name: string;
    id: string;
    nid: string;
    environments?: Array<{
      __typename?: "Environment";
      name: string;
      id: string;
      nid: string;
      resourceCount: number;
    } | null> | null;
  } | null> | null;
};

export type GetUsersPermissionsQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  sortByValue?: InputMaybe<SortByValue>;
  sortByOrder?: InputMaybe<SortByOrder>;
  searchByValue?: InputMaybe<Scalars["String"]>;
}>;

export type GetUsersPermissionsQuery = {
  __typename?: "Query";
  usersPermissions?: {
    __typename?: "UsersPermissionsResponse";
    pages?: number | null;
    count?: number | null;
    usersPermissions?: Array<{
      __typename?: "UserPermissions";
      lastLoginDate?: string | null;
      email: string;
      isOrgAdminFromPanel: boolean;
      access: {
        __typename?: "Access";
        role: Role;
        applications?: Array<string> | null;
        applicationsWithNames?: Array<{
          __typename?: "ApplicationName";
          nid: string;
          name?: string | null;
        }> | null;
      };
    }> | null;
  } | null;
};

export type RemoveUserPermissionsMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RemoveUserPermissionsMutation = {
  __typename?: "Mutation";
  removeUserPermissions?: boolean | null;
};

export type SetUserPermissionsMutationVariables = Exact<{
  email: Scalars["String"];
  input: AccessInput;
}>;

export type SetUserPermissionsMutation = {
  __typename?: "Mutation";
  setUserPermissions?: boolean | null;
};

export type UpdateUserLastLoginDateMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type UpdateUserLastLoginDateMutation = {
  __typename?: "Mutation";
  updateUserLastLoginDate?: {
    __typename?: "UserPermissions";
    lastLoginDate?: string | null;
    updatedBy?: string | null;
  } | null;
};

export type GetApiKeysPermissionsQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
  sortByValue?: InputMaybe<SortByValue>;
  sortByOrder?: InputMaybe<SortByOrder>;
  searchByValue?: InputMaybe<Scalars["String"]>;
}>;

export type GetApiKeysPermissionsQuery = {
  __typename?: "Query";
  accessKeysPermissions?: {
    __typename?: "AccessKeysPermissionsResponse";
    count?: number | null;
    pages?: number | null;
    keyPermissions?: Array<{
      __typename?: "AccessKeyPermissions";
      expirationDate?: string | null;
      createdAt?: string | null;
      isExpired?: boolean | null;
      id: string;
      description?: string | null;
      access: {
        __typename?: "Access";
        role: Role;
        applications?: Array<string> | null;
        applicationsWithNames?: Array<{
          __typename?: "ApplicationName";
          nid: string;
          name?: string | null;
        }> | null;
      };
    }> | null;
  } | null;
};

export type RemoveApiKeyPermissionsMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveApiKeyPermissionsMutation = {
  __typename?: "Mutation";
  removeAccessKey?: boolean | null;
};

export type CreateApiKeyPermissionsMutationVariables = Exact<{
  input: CreateAccessInput;
}>;

export type CreateApiKeyPermissionsMutation = {
  __typename?: "Mutation";
  createAccessKey: { __typename?: "AccessKeyPermissionsWithSecret"; id: string; secret: string };
};

export type UpdateApiKeyPermissionsMutationVariables = Exact<{
  id: Scalars["ID"];
  input: AccessInput;
}>;

export type UpdateApiKeyPermissionsMutation = {
  __typename?: "Mutation";
  updateAccessKeyPermissions?: boolean | null;
};

export type ApplicationsPaginatedListQueryVariables = Exact<{
  limit: Scalars["Int"];
  page: Scalars["Int"];
}>;

export type ApplicationsPaginatedListQuery = {
  __typename?: "Query";
  applicationsPaginated?: {
    __typename?: "ApplicationsPaginatedResponse";
    pages?: number | null;
    count?: number | null;
    results?: Array<{
      __typename?: "Application";
      id: string;
      name: string;
      description?: string | null;
      nid: string;
    }> | null;
  } | null;
};

export type RegenerateAccessKeySecretMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RegenerateAccessKeySecretMutation = {
  __typename?: "Mutation";
  regenerateAccessKeySecret?: {
    __typename?: "AccessKeyPermissionsWithSecret";
    id: string;
    secret: string;
  } | null;
};

export type UserPermissionsQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type UserPermissionsQuery = {
  __typename?: "Query";
  userPermissions?: {
    __typename?: "UserPermissions";
    email: string;
    lastLoginDate?: string | null;
    access: { __typename?: "Access"; role: Role };
  } | null;
};

export type ThresholdsQueryVariables = Exact<{
  sorting?: InputMaybe<ThresholdSortInput>;
}>;

export type ThresholdsQuery = {
  __typename?: "Query";
  thresholds: Array<{
    __typename?: "Threshold";
    id: string;
    name: string;
    accounts: Array<string>;
    services: Array<string>;
    providers: Array<string>;
    userRecipients: Array<string>;
    externalRecipients: Array<string>;
    thresholdAmount: number;
    granularity: ThresholdGranularity;
    createdDate: string;
  }>;
};

export type RemoveThresholdMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveThresholdMutation = {
  __typename?: "Mutation";
  removeThreshold: { __typename?: "MutationResponse"; id?: string | null };
};

export type AddThresholdMutationVariables = Exact<{
  input: ThresholdAddInput;
}>;

export type AddThresholdMutation = {
  __typename?: "Mutation";
  addThreshold: {
    __typename?: "Threshold";
    id: string;
    name: string;
    thresholdAmount: number;
    granularity: ThresholdGranularity;
    userRecipients: Array<string>;
    externalRecipients: Array<string>;
    providers: Array<string>;
    accounts: Array<string>;
    services: Array<string>;
  };
};

export type UpdateThresholdMutationVariables = Exact<{
  id: Scalars["ID"];
  input: ThresholdUpdateInput;
}>;

export type UpdateThresholdMutation = {
  __typename?: "Mutation";
  updateThreshold: {
    __typename?: "Threshold";
    id: string;
    name: string;
    thresholdAmount: number;
    granularity: ThresholdGranularity;
    userRecipients: Array<string>;
    externalRecipients: Array<string>;
    providers: Array<string>;
    accounts: Array<string>;
    services: Array<string>;
  };
};

export type AddWorkflowMutationVariables = Exact<{
  input: AddWorkflowInput;
}>;

export type AddWorkflowMutation = {
  __typename?: "Mutation";
  addWorkflowV2?: {
    __typename?: "WorkflowOutput";
    workflow?: {
      __typename?: "Workflow";
      id: string;
      active: boolean;
      description?: string | null;
      createdAt: string;
      name: string;
      lastRunStatus: WorkflowLastRunStatus;
      lastRunDate: string;
      contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
      selectData: {
        __typename?: "WorkflowSelectData";
        type: WorkflowDataType;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
          applications?: Array<string> | null;
          environments?: Array<string> | null;
          orgUnitIds?: Array<string> | null;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowSelectDataKpiOptions";
          businessContextId: string;
        } | null;
        costsOptions?: {
          __typename?: "WorkflowSelectDataCostsOptions";
          applications?: Array<string> | null;
          environments?: Array<string> | null;
          orgUnitIds?: Array<string> | null;
        } | null;
        budgetsOptions?: {
          __typename?: "WorkflowSelectDataBudgetsOptions";
          forecastGtBudget: boolean;
          costGtBudget: boolean;
          applications?: Array<string> | null;
          environments?: Array<string> | null;
          orgUnitIds?: Array<string> | null;
        } | null;
        anomalyCostsOptions?: {
          __typename?: "WorkflowSelectDataAnomalyCostsOptions";
          applicationIds: Array<string>;
        } | null;
      };
      externalIntegrations: Array<{
        __typename?: "WorkflowExternalIntegration";
        id: string;
        type: WorkflowExternalIntegrationType;
        serviceNowOptions?: {
          __typename?: "WorkflowServiceNowOptions";
          description?: string | null;
          shortDescription: string;
          url: string;
          username: string;
          additionalFields?: Array<{
            __typename?: "WorkflowAdditionalField";
            key: string;
            value: string;
          }> | null;
          costsOptions?: {
            __typename?: "WorkflowServiceNowCostsOptions";
            totalMonthlyCost: boolean;
            totalMonthlyForecast: boolean;
            costsByProviders: boolean;
            costsByCategories: boolean;
          } | null;
          kpiOptions?: {
            __typename?: "WorkflowServiceNowKpiOptions";
            costAllocation: boolean;
            overallWaste: boolean;
            nonProdHours: boolean;
          } | null;
          savingSuggestionsOptions?: {
            __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
            totalMonthlySavings: boolean;
            monthlySavingsByPolicies: boolean;
            monthlySavingsByProviders: boolean;
            monthlySavingsByCategories: boolean;
          } | null;
        } | null;
        emailOptions?: {
          __typename?: "WorkflowEmailOptions";
          sendToContactPersons?: boolean | null;
          mailTo?: Array<string> | null;
          subject: string;
          message: string;
          messageType: WorkflowEmailOptionsMessageType;
        } | null;
        slackOptions?: {
          __typename?: "WorkflowSlackOptions";
          channel: string;
          message?: string | null;
          costsOptions?: {
            __typename?: "WorkflowSlackCostsOptions";
            totalMonthlyCost: boolean;
            totalMonthlyForecast: boolean;
            costsByProviders: boolean;
            costsByCategories: boolean;
          } | null;
          kpiOptions?: {
            __typename?: "WorkflowSlackKpiOptions";
            costAllocation: boolean;
            overallWaste: boolean;
            nonProdHours: boolean;
          } | null;
          savingSuggestionsOptions?: {
            __typename?: "WorkflowSlackSavingSuggestionsOptions";
            totalMonthlySavings: boolean;
            monthlySavingsByProviders: boolean;
            monthlySavingsByCategories: boolean;
            monthlySavingsByPolicies: boolean;
          } | null;
        } | null;
      }>;
      triggers: Array<{
        __typename?: "WorkflowTrigger";
        type: WorkflowTriggerType;
        schedulerOptions?: {
          __typename?: "WorkflowSchedulerOptions";
          byWeekday?: Array<number> | null;
          endDate?: string | null;
          frequency: WorkflowFrequency;
          interval?: number | null;
          startDate: string;
        } | null;
      }>;
    } | null;
    userErrors?: {
      __typename?: "WorkflowUserError";
      message: string;
      code: WorkflowUserErrorCode;
    } | null;
  } | null;
};

export type GetWorkflowListQueryVariables = Exact<{
  field?: InputMaybe<WorkflowSortingFields>;
  order?: InputMaybe<WorkflowSortingOrder>;
}>;

export type GetWorkflowListQuery = {
  __typename?: "Query";
  workflows: Array<{
    __typename?: "WorkflowListItem";
    lastRunDate: string;
    lastRunStatus: WorkflowLastRunStatus;
    id: string;
    active: boolean;
    description?: string | null;
    name: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
    triggers: Array<{
      __typename?: "WorkflowTrigger";
      type: WorkflowTriggerType;
      schedulerOptions?: {
        __typename?: "WorkflowSchedulerOptions";
        byWeekday?: Array<number> | null;
        endDate?: string | null;
        frequency: WorkflowFrequency;
        interval?: number | null;
        startDate: string;
      } | null;
    }>;
  }>;
};

export type WorkflowFieldsFragment = {
  __typename?: "Workflow";
  id: string;
  active: boolean;
  description?: string | null;
  createdAt: string;
  name: string;
  lastRunStatus: WorkflowLastRunStatus;
  lastRunDate: string;
  contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
  selectData: {
    __typename?: "WorkflowSelectData";
    type: WorkflowDataType;
    savingSuggestionsOptions?: {
      __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
      applications?: Array<string> | null;
      environments?: Array<string> | null;
      orgUnitIds?: Array<string> | null;
    } | null;
    kpiOptions?: { __typename?: "WorkflowSelectDataKpiOptions"; businessContextId: string } | null;
    costsOptions?: {
      __typename?: "WorkflowSelectDataCostsOptions";
      applications?: Array<string> | null;
      environments?: Array<string> | null;
      orgUnitIds?: Array<string> | null;
    } | null;
    budgetsOptions?: {
      __typename?: "WorkflowSelectDataBudgetsOptions";
      forecastGtBudget: boolean;
      costGtBudget: boolean;
      applications?: Array<string> | null;
      environments?: Array<string> | null;
      orgUnitIds?: Array<string> | null;
    } | null;
    anomalyCostsOptions?: {
      __typename?: "WorkflowSelectDataAnomalyCostsOptions";
      applicationIds: Array<string>;
    } | null;
  };
  externalIntegrations: Array<{
    __typename?: "WorkflowExternalIntegration";
    id: string;
    type: WorkflowExternalIntegrationType;
    serviceNowOptions?: {
      __typename?: "WorkflowServiceNowOptions";
      description?: string | null;
      shortDescription: string;
      url: string;
      username: string;
      additionalFields?: Array<{
        __typename?: "WorkflowAdditionalField";
        key: string;
        value: string;
      }> | null;
      costsOptions?: {
        __typename?: "WorkflowServiceNowCostsOptions";
        totalMonthlyCost: boolean;
        totalMonthlyForecast: boolean;
        costsByProviders: boolean;
        costsByCategories: boolean;
      } | null;
      kpiOptions?: {
        __typename?: "WorkflowServiceNowKpiOptions";
        costAllocation: boolean;
        overallWaste: boolean;
        nonProdHours: boolean;
      } | null;
      savingSuggestionsOptions?: {
        __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
        totalMonthlySavings: boolean;
        monthlySavingsByPolicies: boolean;
        monthlySavingsByProviders: boolean;
        monthlySavingsByCategories: boolean;
      } | null;
    } | null;
    emailOptions?: {
      __typename?: "WorkflowEmailOptions";
      sendToContactPersons?: boolean | null;
      mailTo?: Array<string> | null;
      subject: string;
      message: string;
      messageType: WorkflowEmailOptionsMessageType;
    } | null;
    slackOptions?: {
      __typename?: "WorkflowSlackOptions";
      channel: string;
      message?: string | null;
      costsOptions?: {
        __typename?: "WorkflowSlackCostsOptions";
        totalMonthlyCost: boolean;
        totalMonthlyForecast: boolean;
        costsByProviders: boolean;
        costsByCategories: boolean;
      } | null;
      kpiOptions?: {
        __typename?: "WorkflowSlackKpiOptions";
        costAllocation: boolean;
        overallWaste: boolean;
        nonProdHours: boolean;
      } | null;
      savingSuggestionsOptions?: {
        __typename?: "WorkflowSlackSavingSuggestionsOptions";
        totalMonthlySavings: boolean;
        monthlySavingsByProviders: boolean;
        monthlySavingsByCategories: boolean;
        monthlySavingsByPolicies: boolean;
      } | null;
    } | null;
  }>;
  triggers: Array<{
    __typename?: "WorkflowTrigger";
    type: WorkflowTriggerType;
    schedulerOptions?: {
      __typename?: "WorkflowSchedulerOptions";
      byWeekday?: Array<number> | null;
      endDate?: string | null;
      frequency: WorkflowFrequency;
      interval?: number | null;
      startDate: string;
    } | null;
  }>;
};

export type GetWorkflowQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetWorkflowQuery = {
  __typename?: "Query";
  workflow?: {
    __typename?: "Workflow";
    id: string;
    active: boolean;
    description?: string | null;
    createdAt: string;
    name: string;
    lastRunStatus: WorkflowLastRunStatus;
    lastRunDate: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
    selectData: {
      __typename?: "WorkflowSelectData";
      type: WorkflowDataType;
      savingSuggestionsOptions?: {
        __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      kpiOptions?: {
        __typename?: "WorkflowSelectDataKpiOptions";
        businessContextId: string;
      } | null;
      costsOptions?: {
        __typename?: "WorkflowSelectDataCostsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      budgetsOptions?: {
        __typename?: "WorkflowSelectDataBudgetsOptions";
        forecastGtBudget: boolean;
        costGtBudget: boolean;
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      anomalyCostsOptions?: {
        __typename?: "WorkflowSelectDataAnomalyCostsOptions";
        applicationIds: Array<string>;
      } | null;
    };
    externalIntegrations: Array<{
      __typename?: "WorkflowExternalIntegration";
      id: string;
      type: WorkflowExternalIntegrationType;
      serviceNowOptions?: {
        __typename?: "WorkflowServiceNowOptions";
        description?: string | null;
        shortDescription: string;
        url: string;
        username: string;
        additionalFields?: Array<{
          __typename?: "WorkflowAdditionalField";
          key: string;
          value: string;
        }> | null;
        costsOptions?: {
          __typename?: "WorkflowServiceNowCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowServiceNowKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByPolicies: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
        } | null;
      } | null;
      emailOptions?: {
        __typename?: "WorkflowEmailOptions";
        sendToContactPersons?: boolean | null;
        mailTo?: Array<string> | null;
        subject: string;
        message: string;
        messageType: WorkflowEmailOptionsMessageType;
      } | null;
      slackOptions?: {
        __typename?: "WorkflowSlackOptions";
        channel: string;
        message?: string | null;
        costsOptions?: {
          __typename?: "WorkflowSlackCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowSlackKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowSlackSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
          monthlySavingsByPolicies: boolean;
        } | null;
      } | null;
    }>;
    triggers: Array<{
      __typename?: "WorkflowTrigger";
      type: WorkflowTriggerType;
      schedulerOptions?: {
        __typename?: "WorkflowSchedulerOptions";
        byWeekday?: Array<number> | null;
        endDate?: string | null;
        frequency: WorkflowFrequency;
        interval?: number | null;
        startDate: string;
      } | null;
    }>;
  } | null;
};

export type ExecuteWorkflowMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExecuteWorkflowMutation = { __typename?: "Mutation"; executeWorkflow?: boolean | null };

export type ActivateWorkflowMutationVariables = Exact<{
  id: Scalars["ID"];
  active: Scalars["Boolean"];
}>;

export type ActivateWorkflowMutation = {
  __typename?: "Mutation";
  activateWorkflowV2?: {
    __typename?: "WorkflowOutput";
    workflow?: {
      __typename?: "Workflow";
      id: string;
      active: boolean;
      description?: string | null;
      createdAt: string;
      name: string;
      lastRunStatus: WorkflowLastRunStatus;
      lastRunDate: string;
      contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
      selectData: {
        __typename?: "WorkflowSelectData";
        type: WorkflowDataType;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
          applications?: Array<string> | null;
          environments?: Array<string> | null;
          orgUnitIds?: Array<string> | null;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowSelectDataKpiOptions";
          businessContextId: string;
        } | null;
        costsOptions?: {
          __typename?: "WorkflowSelectDataCostsOptions";
          applications?: Array<string> | null;
          environments?: Array<string> | null;
          orgUnitIds?: Array<string> | null;
        } | null;
        budgetsOptions?: {
          __typename?: "WorkflowSelectDataBudgetsOptions";
          forecastGtBudget: boolean;
          costGtBudget: boolean;
          applications?: Array<string> | null;
          environments?: Array<string> | null;
          orgUnitIds?: Array<string> | null;
        } | null;
        anomalyCostsOptions?: {
          __typename?: "WorkflowSelectDataAnomalyCostsOptions";
          applicationIds: Array<string>;
        } | null;
      };
      externalIntegrations: Array<{
        __typename?: "WorkflowExternalIntegration";
        id: string;
        type: WorkflowExternalIntegrationType;
        serviceNowOptions?: {
          __typename?: "WorkflowServiceNowOptions";
          description?: string | null;
          shortDescription: string;
          url: string;
          username: string;
          additionalFields?: Array<{
            __typename?: "WorkflowAdditionalField";
            key: string;
            value: string;
          }> | null;
          costsOptions?: {
            __typename?: "WorkflowServiceNowCostsOptions";
            totalMonthlyCost: boolean;
            totalMonthlyForecast: boolean;
            costsByProviders: boolean;
            costsByCategories: boolean;
          } | null;
          kpiOptions?: {
            __typename?: "WorkflowServiceNowKpiOptions";
            costAllocation: boolean;
            overallWaste: boolean;
            nonProdHours: boolean;
          } | null;
          savingSuggestionsOptions?: {
            __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
            totalMonthlySavings: boolean;
            monthlySavingsByPolicies: boolean;
            monthlySavingsByProviders: boolean;
            monthlySavingsByCategories: boolean;
          } | null;
        } | null;
        emailOptions?: {
          __typename?: "WorkflowEmailOptions";
          sendToContactPersons?: boolean | null;
          mailTo?: Array<string> | null;
          subject: string;
          message: string;
          messageType: WorkflowEmailOptionsMessageType;
        } | null;
        slackOptions?: {
          __typename?: "WorkflowSlackOptions";
          channel: string;
          message?: string | null;
          costsOptions?: {
            __typename?: "WorkflowSlackCostsOptions";
            totalMonthlyCost: boolean;
            totalMonthlyForecast: boolean;
            costsByProviders: boolean;
            costsByCategories: boolean;
          } | null;
          kpiOptions?: {
            __typename?: "WorkflowSlackKpiOptions";
            costAllocation: boolean;
            overallWaste: boolean;
            nonProdHours: boolean;
          } | null;
          savingSuggestionsOptions?: {
            __typename?: "WorkflowSlackSavingSuggestionsOptions";
            totalMonthlySavings: boolean;
            monthlySavingsByProviders: boolean;
            monthlySavingsByCategories: boolean;
            monthlySavingsByPolicies: boolean;
          } | null;
        } | null;
      }>;
      triggers: Array<{
        __typename?: "WorkflowTrigger";
        type: WorkflowTriggerType;
        schedulerOptions?: {
          __typename?: "WorkflowSchedulerOptions";
          byWeekday?: Array<number> | null;
          endDate?: string | null;
          frequency: WorkflowFrequency;
          interval?: number | null;
          startDate: string;
        } | null;
      }>;
    } | null;
    userErrors?: {
      __typename?: "WorkflowUserError";
      message: string;
      code: WorkflowUserErrorCode;
      supportLink?: string | null;
    } | null;
  } | null;
};

export type UpdateWorkflowDetailMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateWorkflowInput;
}>;

export type UpdateWorkflowDetailMutation = {
  __typename?: "Mutation";
  updateWorkflowDetail?: {
    __typename?: "Workflow";
    id: string;
    active: boolean;
    description?: string | null;
    createdAt: string;
    name: string;
    lastRunStatus: WorkflowLastRunStatus;
    lastRunDate: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
    selectData: {
      __typename?: "WorkflowSelectData";
      type: WorkflowDataType;
      savingSuggestionsOptions?: {
        __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      kpiOptions?: {
        __typename?: "WorkflowSelectDataKpiOptions";
        businessContextId: string;
      } | null;
      costsOptions?: {
        __typename?: "WorkflowSelectDataCostsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      budgetsOptions?: {
        __typename?: "WorkflowSelectDataBudgetsOptions";
        forecastGtBudget: boolean;
        costGtBudget: boolean;
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      anomalyCostsOptions?: {
        __typename?: "WorkflowSelectDataAnomalyCostsOptions";
        applicationIds: Array<string>;
      } | null;
    };
    externalIntegrations: Array<{
      __typename?: "WorkflowExternalIntegration";
      id: string;
      type: WorkflowExternalIntegrationType;
      serviceNowOptions?: {
        __typename?: "WorkflowServiceNowOptions";
        description?: string | null;
        shortDescription: string;
        url: string;
        username: string;
        additionalFields?: Array<{
          __typename?: "WorkflowAdditionalField";
          key: string;
          value: string;
        }> | null;
        costsOptions?: {
          __typename?: "WorkflowServiceNowCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowServiceNowKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByPolicies: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
        } | null;
      } | null;
      emailOptions?: {
        __typename?: "WorkflowEmailOptions";
        sendToContactPersons?: boolean | null;
        mailTo?: Array<string> | null;
        subject: string;
        message: string;
        messageType: WorkflowEmailOptionsMessageType;
      } | null;
      slackOptions?: {
        __typename?: "WorkflowSlackOptions";
        channel: string;
        message?: string | null;
        costsOptions?: {
          __typename?: "WorkflowSlackCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowSlackKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowSlackSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
          monthlySavingsByPolicies: boolean;
        } | null;
      } | null;
    }>;
    triggers: Array<{
      __typename?: "WorkflowTrigger";
      type: WorkflowTriggerType;
      schedulerOptions?: {
        __typename?: "WorkflowSchedulerOptions";
        byWeekday?: Array<number> | null;
        endDate?: string | null;
        frequency: WorkflowFrequency;
        interval?: number | null;
        startDate: string;
      } | null;
    }>;
  } | null;
};

export type UpdateWorkflowConfigMutationVariables = Exact<{
  id: Scalars["ID"];
  input: UpdateWorkflowConfigInput;
}>;

export type UpdateWorkflowConfigMutation = {
  __typename?: "Mutation";
  updateWorkflowConfig?: {
    __typename?: "Workflow";
    id: string;
    active: boolean;
    description?: string | null;
    createdAt: string;
    name: string;
    lastRunStatus: WorkflowLastRunStatus;
    lastRunDate: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
    selectData: {
      __typename?: "WorkflowSelectData";
      type: WorkflowDataType;
      savingSuggestionsOptions?: {
        __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      kpiOptions?: {
        __typename?: "WorkflowSelectDataKpiOptions";
        businessContextId: string;
      } | null;
      costsOptions?: {
        __typename?: "WorkflowSelectDataCostsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      budgetsOptions?: {
        __typename?: "WorkflowSelectDataBudgetsOptions";
        forecastGtBudget: boolean;
        costGtBudget: boolean;
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      anomalyCostsOptions?: {
        __typename?: "WorkflowSelectDataAnomalyCostsOptions";
        applicationIds: Array<string>;
      } | null;
    };
    externalIntegrations: Array<{
      __typename?: "WorkflowExternalIntegration";
      id: string;
      type: WorkflowExternalIntegrationType;
      serviceNowOptions?: {
        __typename?: "WorkflowServiceNowOptions";
        description?: string | null;
        shortDescription: string;
        url: string;
        username: string;
        additionalFields?: Array<{
          __typename?: "WorkflowAdditionalField";
          key: string;
          value: string;
        }> | null;
        costsOptions?: {
          __typename?: "WorkflowServiceNowCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowServiceNowKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByPolicies: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
        } | null;
      } | null;
      emailOptions?: {
        __typename?: "WorkflowEmailOptions";
        sendToContactPersons?: boolean | null;
        mailTo?: Array<string> | null;
        subject: string;
        message: string;
        messageType: WorkflowEmailOptionsMessageType;
      } | null;
      slackOptions?: {
        __typename?: "WorkflowSlackOptions";
        channel: string;
        message?: string | null;
        costsOptions?: {
          __typename?: "WorkflowSlackCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowSlackKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowSlackSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
          monthlySavingsByPolicies: boolean;
        } | null;
      } | null;
    }>;
    triggers: Array<{
      __typename?: "WorkflowTrigger";
      type: WorkflowTriggerType;
      schedulerOptions?: {
        __typename?: "WorkflowSchedulerOptions";
        byWeekday?: Array<number> | null;
        endDate?: string | null;
        frequency: WorkflowFrequency;
        interval?: number | null;
        startDate: string;
      } | null;
    }>;
  } | null;
};

export type RemoveWorkflowMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RemoveWorkflowMutation = { __typename?: "Mutation"; removeWorkflow?: boolean | null };

export type CloneWorkflowMutationVariables = Exact<{
  id: Scalars["ID"];
  input: CloneWorkflowInput;
}>;

export type CloneWorkflowMutation = {
  __typename?: "Mutation";
  cloneWorkflow?: {
    __typename?: "Workflow";
    id: string;
    active: boolean;
    description?: string | null;
    createdAt: string;
    name: string;
    lastRunStatus: WorkflowLastRunStatus;
    lastRunDate: string;
    contactPersons: Array<{ __typename?: "Person"; id: string; name: string; email: string }>;
    selectData: {
      __typename?: "WorkflowSelectData";
      type: WorkflowDataType;
      savingSuggestionsOptions?: {
        __typename?: "WorkflowSelectDataSavingSuggestionsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      kpiOptions?: {
        __typename?: "WorkflowSelectDataKpiOptions";
        businessContextId: string;
      } | null;
      costsOptions?: {
        __typename?: "WorkflowSelectDataCostsOptions";
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      budgetsOptions?: {
        __typename?: "WorkflowSelectDataBudgetsOptions";
        forecastGtBudget: boolean;
        costGtBudget: boolean;
        applications?: Array<string> | null;
        environments?: Array<string> | null;
        orgUnitIds?: Array<string> | null;
      } | null;
      anomalyCostsOptions?: {
        __typename?: "WorkflowSelectDataAnomalyCostsOptions";
        applicationIds: Array<string>;
      } | null;
    };
    externalIntegrations: Array<{
      __typename?: "WorkflowExternalIntegration";
      id: string;
      type: WorkflowExternalIntegrationType;
      serviceNowOptions?: {
        __typename?: "WorkflowServiceNowOptions";
        description?: string | null;
        shortDescription: string;
        url: string;
        username: string;
        additionalFields?: Array<{
          __typename?: "WorkflowAdditionalField";
          key: string;
          value: string;
        }> | null;
        costsOptions?: {
          __typename?: "WorkflowServiceNowCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowServiceNowKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowServiceNowSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByPolicies: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
        } | null;
      } | null;
      emailOptions?: {
        __typename?: "WorkflowEmailOptions";
        sendToContactPersons?: boolean | null;
        mailTo?: Array<string> | null;
        subject: string;
        message: string;
        messageType: WorkflowEmailOptionsMessageType;
      } | null;
      slackOptions?: {
        __typename?: "WorkflowSlackOptions";
        channel: string;
        message?: string | null;
        costsOptions?: {
          __typename?: "WorkflowSlackCostsOptions";
          totalMonthlyCost: boolean;
          totalMonthlyForecast: boolean;
          costsByProviders: boolean;
          costsByCategories: boolean;
        } | null;
        kpiOptions?: {
          __typename?: "WorkflowSlackKpiOptions";
          costAllocation: boolean;
          overallWaste: boolean;
          nonProdHours: boolean;
        } | null;
        savingSuggestionsOptions?: {
          __typename?: "WorkflowSlackSavingSuggestionsOptions";
          totalMonthlySavings: boolean;
          monthlySavingsByProviders: boolean;
          monthlySavingsByCategories: boolean;
          monthlySavingsByPolicies: boolean;
        } | null;
      } | null;
    }>;
    triggers: Array<{
      __typename?: "WorkflowTrigger";
      type: WorkflowTriggerType;
      schedulerOptions?: {
        __typename?: "WorkflowSchedulerOptions";
        byWeekday?: Array<number> | null;
        endDate?: string | null;
        frequency: WorkflowFrequency;
        interval?: number | null;
        startDate: string;
      } | null;
    }>;
  } | null;
};

export type GetWorkflowHistoryQueryVariables = Exact<{
  limit: Scalars["Int"];
  workflowId?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<WorkflowEventStatus>;
}>;

export type GetWorkflowHistoryQuery = {
  __typename?: "Query";
  workflowHistoryEvents?: {
    __typename?: "WorkflowHistoryListOutput";
    cursor?: string | null;
    events?: Array<{
      __typename?: "WorkflowEvent";
      id: string;
      status: WorkflowEventStatus;
      workflowId: string;
      workflowName: string;
      workflowDescription?: string | null;
      startTime: string;
      endTime: string;
      result: string;
      workflowDeleted: boolean;
      trigger: {
        __typename?: "WorkflowTrigger";
        type: WorkflowTriggerType;
        schedulerOptions?: {
          __typename?: "WorkflowSchedulerOptions";
          byWeekday?: Array<number> | null;
          endDate?: string | null;
          frequency: WorkflowFrequency;
          interval?: number | null;
          startDate: string;
        } | null;
      };
    }> | null;
  } | null;
};

export type HistoryEventsQueryVariables = Exact<{
  limit: Scalars["Int"];
  cursor?: InputMaybe<Scalars["String"]>;
  field?: InputMaybe<WorkflowEventInListSortingFields>;
  order?: InputMaybe<WorkflowSortingOrder>;
}>;

export type HistoryEventsQuery = {
  __typename?: "Query";
  historyEvents?: {
    __typename?: "WorkflowHistoryListOutput";
    cursor?: string | null;
    events?: Array<{
      __typename?: "WorkflowEvent";
      id: string;
      status: WorkflowEventStatus;
      workflowId: string;
      workflowName: string;
      workflowDescription?: string | null;
      startTime: string;
      endTime: string;
      result: string;
      workflowDeleted: boolean;
      trigger: {
        __typename?: "WorkflowTrigger";
        type: WorkflowTriggerType;
        schedulerOptions?: {
          __typename?: "WorkflowSchedulerOptions";
          byWeekday?: Array<number> | null;
          endDate?: string | null;
          frequency: WorkflowFrequency;
          interval?: number | null;
          startDate: string;
        } | null;
      };
    }> | null;
  } | null;
};

export type GetWorkflowHistoryEventQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetWorkflowHistoryEventQuery = {
  __typename?: "Query";
  workflowHistoryEvent?: {
    __typename?: "WorkflowEvent";
    id: string;
    startTime: string;
    endTime: string;
    status: WorkflowEventStatus;
    workflowName: string;
    dataType: WorkflowDataType;
    result: string;
    errorCode?: WorkflowEventErrorCode | null;
    trigger: {
      __typename?: "WorkflowTrigger";
      type: WorkflowTriggerType;
      schedulerOptions?: {
        __typename?: "WorkflowSchedulerOptions";
        byWeekday?: Array<number> | null;
        endDate?: string | null;
        frequency: WorkflowFrequency;
        interval?: number | null;
        startDate: string;
      } | null;
    };
    detailedResult?: {
      __typename?: "WorkflowDetailedResult";
      kpiResult?: {
        __typename?: "WorkflowKpiResult";
        businessContextName?: string | null;
        businessContextColor?: string | null;
        costAllocation?: {
          __typename?: "WorkflowKpiResultValue";
          value?: string | null;
          percentage?: string | null;
          target?: string | null;
        } | null;
        overallWaste?: {
          __typename?: "WorkflowKpiResultValue";
          value?: string | null;
          percentage?: string | null;
          target?: string | null;
        } | null;
        nonProdHours?: {
          __typename?: "WorkflowKpiResultValue";
          value?: string | null;
          percentage?: string | null;
          target?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetBusinessContextsQueryVariables = Exact<{
  year: Scalars["String"];
}>;

export type GetBusinessContextsQuery = {
  __typename?: "Query";
  businessContexts?: {
    __typename?: "BusinessContextResponse";
    businessContexts?: Array<{
      __typename?: "BusinessContext";
      id: string;
      name: string;
      color?: string | null;
      isPrimary?: boolean | null;
      orgUnits?: {
        __typename?: "OrgUnitsV2NonPaginatedResponse";
        orgUnits?: Array<{
          __typename?: "OrgUnitV2";
          id: string;
          nid: string;
          parentNid?: string | null;
          name: string;
          budgetYearly: { __typename?: "BudgetYearly"; id: string; yearlySum: string };
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type ApplicationsSearchWithBudgetsQueryVariables = Exact<{
  limit: Scalars["Int"];
  year: Scalars["String"];
  page?: InputMaybe<Scalars["Int"]>;
  applicationName?: InputMaybe<Scalars["String"]>;
}>;

export type ApplicationsSearchWithBudgetsQuery = {
  __typename?: "Query";
  applicationsPaginated?: {
    __typename?: "ApplicationsPaginatedResponse";
    count?: number | null;
    pages?: number | null;
    results?: Array<{
      __typename?: "Application";
      id: string;
      nid: string;
      name: string;
      environments?: Array<{
        __typename?: "Environment";
        name: string;
        id: string;
        nid: string;
        budgetYearly: { __typename?: "BudgetYearly"; id: string; yearlySum: string };
      } | null> | null;
      budgetYearly: { __typename?: "BudgetYearly"; id: string; yearlySum: string };
    }> | null;
  } | null;
};

export type AllAppAndEnvIdsQueryVariables = Exact<{
  applicationName?: InputMaybe<Scalars["String"]>;
}>;

export type AllAppAndEnvIdsQuery = {
  __typename?: "Query";
  selectAllAppAndEnvIds?: {
    __typename?: "AppAndEnvIds";
    applicationNids: Array<string | null>;
    environmentNids: Array<string | null>;
  } | null;
};

export const KpiWidgetFragmentDoc = gql`
  fragment KpiWidget on BusinessContextKpi {
    details(timePeriod: ONE_MONTH) {
      targets {
        currentTarget {
          id
          goal
        }
      }
      metrics {
        currentValue
      }
    }
  }
`;
export const OrgUnitActionDetailsFragmentDoc = gql`
  fragment OrgUnitActionDetails on OrgUnitV2 {
    id
    name
    description
    parentNid
    type {
      id
      name
      description
    }
    contact {
      id
      name
      email
    }
  }
`;
export const AccumulatedCostValueFragmentDoc = gql`
  fragment AccumulatedCostValue on AccumulatedCostResponse {
    total
    timePoints {
      date
      value
    }
  }
`;
export const CustomerCorrectionTimepointsFragmentDoc = gql`
  fragment CustomerCorrectionTimepoints on CustomerCorrectionResponse {
    timePoints {
      date
      value
    }
  }
`;
export const ApplicationsCostValueFragmentDoc = gql`
  fragment ApplicationsCostValue on CustomerApplicationsCostResponse {
    count
    pages
    timePoints {
      date
      value
      totalCost
      groups {
        id
        name
        value
      }
    }
  }
`;
export const ApplicationsCostPerCategoryValueFragmentDoc = gql`
  fragment ApplicationsCostPerCategoryValue on CustomerCategoriesCostResponse {
    count
    pages
    timePoints {
      date
      value
      totalCost
      groups {
        id
        name
        value
      }
    }
  }
`;
export const ApplicationsCostPerServicesValueFragmentDoc = gql`
  fragment ApplicationsCostPerServicesValue on CustomerServicesCostResponse {
    count
    pages
    timePoints {
      date
      value
      totalCost
      groups {
        id
        name
        value
      }
    }
  }
`;
export const ApplicationsCostPerServicesValueV2FragmentDoc = gql`
  fragment ApplicationsCostPerServicesValueV2 on CustomerServicesCostResponseV2 {
    count
    pages
    timePoints {
      date
      value
      totalCost
      groups {
        id
        name
        value
        types
      }
    }
  }
`;
export const ApplicationBaseDetailsFragmentDoc = gql`
  fragment ApplicationBaseDetails on Application {
    id
    name
    nid
    description
    displayName
    contacts {
      id
      name
      email
    }
    owner {
      id
      name
    }
  }
`;
export const EnvironmentBaseDetailsFragmentDoc = gql`
  fragment EnvironmentBaseDetails on Environment {
    id
    name
    nid
    description
    displayName
    contacts {
      id
      name
      email
    }
    owner {
      id
      name
    }
    environmentType
  }
`;
export const AccumulatedCostFragmentDoc = gql`
  fragment AccumulatedCost on ApplicationAccumulatedCostResponse {
    total
    timePoints {
      date
      value
    }
  }
`;
export const TimePointFragmentDoc = gql`
  fragment TimePoint on GroupTimePoint {
    date
    value
    groups {
      id
      name
      value
    }
  }
`;
export const BudgetFragmentDoc = gql`
  fragment Budget on BudgetYearly {
    id
    budgetByMonth
    yearlySum
  }
`;
export const ApplicationEnvironmentFragmentDoc = gql`
  fragment ApplicationEnvironment on Environment {
    name
    nid
    budgetYearly(year: $year) {
      id
      budgetByMonth
      yearlySum
    }
    cloudWasteAggregate {
      hasWaste
      cost
      count
      co2e
    }
    co2e(timePeriod: CURRENTMONTH)
    cost {
      currentMonth
      previousMonth
      forecast
    }
    resourceCount
    orgUnitsV2(limit: 50, page: 0) {
      orgUnits {
        id
        nid
        name
        businessContext {
          id
          color
          name
        }
      }
    }
  }
`;
export const KpiTargetDetailsFragmentDoc = gql`
  fragment KpiTargetDetails on KpiTarget {
    id
    name
    goal
    startDate
    endDate
  }
`;
export const KpiTargetFieldsFragmentDoc = gql`
  fragment KpiTargetFields on KpiTarget {
    ...KpiTargetDetails
    contactPerson {
      name
      id
      email
    }
  }
  ${KpiTargetDetailsFragmentDoc}
`;
export const OrgUnitKpiWidgetFragmentDoc = gql`
  fragment OrgUnitKpiWidget on OrgUnitKpi {
    details(timePeriod: ONE_MONTH) {
      targets {
        currentTarget {
          ...KpiTargetFields
        }
      }
      metrics {
        latestTimePoint {
          value
          additionalFields {
            key
            value
          }
        }
      }
    }
  }
  ${KpiTargetFieldsFragmentDoc}
`;
export const OrgUnitKpiTimePointsFragmentDoc = gql`
  fragment OrgUnitKpiTimePoints on OrgUnitKpi {
    details(timePeriod: $period) {
      targets {
        targets {
          goal
          name
          id
          startDate
          endDate
        }
      }
      metrics {
        values {
          date
          value
          additionalFields {
            key
            value
          }
        }
      }
    }
  }
`;
export const OrganizationUnitAccumulatedCostFragmentDoc = gql`
  fragment OrganizationUnitAccumulatedCost on OrgUnitAccumulatedCostResponse {
    total
    timePoints {
      date
      value
    }
  }
`;
export const DriftFragmentDoc = gql`
  fragment Drift on EstateAllocationDriftRecord {
    id: nid
    name
    provider
    providerId
    providerType
    categoryName
    region
    currentEnvironment {
      name
      nid
      application {
        name
        nid
      }
    }
    targetEnvironment {
      name
      nid
      application {
        name
        nid
      }
    }
  }
`;
export const DiscoveryRuleFieldsFragmentDoc = gql`
  fragment discoveryRuleFields on DiscoveryRule {
    id
    name
    match {
      nid
      cloudAccount
      resourceGroup
      projectId
      type
      subtype
      subnetIds
      tags {
        key
        value
      }
      securityGroupIds
    }
    exclusive
    ibmResourceGroup
  }
`;
export const SummaryKpiDetailsFragmentDoc = gql`
  fragment SummaryKpiDetails on KpiDetails {
    targets {
      currentTarget {
        id
        goal
      }
    }
    metrics {
      currentValue
    }
  }
`;
export const EnvAccumulatedCostFragmentDoc = gql`
  fragment EnvAccumulatedCost on EnvironmentAccumulatedCostResponse {
    total
    timePoints {
      date
      value
    }
  }
`;
export const TimePointWithUnitFragmentDoc = gql`
  fragment TimePointWithUnit on TimePointWithUnit {
    date
    value
    unit
  }
`;
export const ChartResponseFragmentDoc = gql`
  fragment ChartResponse on ChartJobStatusResponse {
    status
    data {
      total
      count
      extraDetails {
        name
        value
      }
      timePoints {
        date
        value
        groups {
          id
          name
          value
        }
      }
    }
  }
`;
export const SearchPresetV2FragmentDoc = gql`
  fragment SearchPresetV2 on SearchPresetV2 {
    id
    name
    query
    sort {
      field
      order
    }
    filter {
      provider
      providerExclude
      costFrom
      costTo
      costExclude
      unassigned
      unassignedExclude
      waste
      wastePolicy
      wastePolicyExclude
      co2eWaste
      category
      categoryExclude
      assignmentUpdatedFrom
      assignmentUpdatedTo
      assignmentUpdateExclude
      firstSeenAtFrom
      firstSeenAtTo
      firstSeenAtExclude
      lastSeenAtFrom
      lastSeenAtTo
      lastSeenAtExclude
      lifeSpanFrom
      lifeSpanTo
      lifeSpanExclude
      account
      accountExclude
      service
      serviceExclude
      application
      applicationExclude
      environment
      environmentExclude
      region
      regionExclude
      tags {
        values
        key
      }
      tagsExclude
      type
      typeExclude
      subtype
      subtypeExclude
      typeSubtype
      typeSubtypeExclude
      resourceGroup
      resourceGroupExclude
      projectId
      projectIdExclude
      externalIntegration
      externalIntegrationExclude
      enrichment
      enrichmentIntegrationId
      enrichmentIntegrationIdExclude
      metadata {
        values
        key
      }
      metadataExclude
      providerId
      exceptProviderId
      onlyRelated
      securityGroup
      securityGroupExclude
    }
    createdAt
    updatedAt
  }
`;
export const KpiDetailsFragmentDoc = gql`
  fragment KpiDetails on KpiDetails {
    metrics {
      values {
        date
        value
        additionalFields {
          key
          value
        }
      }
      currentValue
    }
    targets {
      targets {
        ...KpiTargetDetails
      }
      currentTarget {
        ...KpiTargetFields
      }
    }
  }
  ${KpiTargetDetailsFragmentDoc}
  ${KpiTargetFieldsFragmentDoc}
`;
export const BaseCostFragmentDoc = gql`
  fragment BaseCost on Cost {
    currentMonth
    previousMonth
    forecast
  }
`;
export const SpendsFragmentDoc = gql`
  fragment Spends on DashboardApplicationCost {
    nid
    name
    currentMonth
    previousMonth
    forecast
  }
`;
export const PersonFieldsFragmentDoc = gql`
  fragment PersonFields on Person {
    id
    name
    email
  }
`;
export const WorkflowFieldsFragmentDoc = gql`
  fragment workflowFields on Workflow {
    id
    contactPersons {
      id
      name
      email
    }
    selectData {
      type
      savingSuggestionsOptions {
        applications
        environments
        orgUnitIds
      }
      kpiOptions {
        businessContextId
      }
      costsOptions {
        applications
        environments
        orgUnitIds
      }
      budgetsOptions {
        forecastGtBudget
        costGtBudget
        applications
        environments
        orgUnitIds
      }
      anomalyCostsOptions {
        applicationIds
      }
    }
    active
    description
    createdAt
    name
    externalIntegrations {
      id
      type
      serviceNowOptions {
        description
        shortDescription
        url
        username
        additionalFields {
          key
          value
        }
        costsOptions {
          totalMonthlyCost
          totalMonthlyForecast
          costsByProviders
          costsByCategories
        }
        kpiOptions {
          costAllocation
          overallWaste
          nonProdHours
        }
        savingSuggestionsOptions {
          totalMonthlySavings
          monthlySavingsByPolicies
          monthlySavingsByProviders
          monthlySavingsByCategories
        }
      }
      emailOptions {
        sendToContactPersons
        mailTo
        subject
        message
        messageType
      }
      slackOptions {
        channel
        message
        costsOptions {
          totalMonthlyCost
          totalMonthlyForecast
          costsByProviders
          costsByCategories
        }
        kpiOptions {
          costAllocation
          overallWaste
          nonProdHours
        }
        savingSuggestionsOptions {
          totalMonthlySavings
          monthlySavingsByProviders
          monthlySavingsByCategories
          monthlySavingsByPolicies
        }
      }
    }
    triggers {
      schedulerOptions {
        byWeekday
        endDate
        frequency
        interval
        startDate
      }
      type
    }
    lastRunStatus
    lastRunDate
  }
`;
export const EventsDocument = gql`
  query Events($from: AWSDateTime!, $nid: String!, $to: AWSDateTime!) {
    events(from: $from, nid: $nid, to: $to) {
      id
      title
      type
      content
      createdAt
    }
  }
`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      nid: // value for 'nid'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const ExpandableTagDocument = gql`
  query ExpandableTag(
    $ids: [String!]!
    $isApp: Boolean!
    $isEnv: Boolean!
    $isCategory: Boolean!
    $isPolicy: Boolean!
    $isService: Boolean!
    $isExternal: Boolean!
  ) {
    applicationsByIds(ids: $ids) @include(if: $isApp) {
      name
      id
      nid
    }
    environmentsByIds(ids: $ids) @include(if: $isEnv) {
      name
      id
      nid
      application {
        id
        name
        nid
      }
    }
    categoriesByIds(ids: $ids) @include(if: $isCategory) {
      name
      id
    }
    cloudWasteSummary @include(if: $isPolicy) {
      policies {
        id
        title
      }
    }
    externalIntegrations @include(if: $isExternal) {
      integrations {
        id
        name
      }
    }
    servicesByIds(ids: $ids) @include(if: $isService) {
      id
      name
    }
  }
`;

/**
 * __useExpandableTagQuery__
 *
 * To run a query within a React component, call `useExpandableTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpandableTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpandableTagQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      isApp: // value for 'isApp'
 *      isEnv: // value for 'isEnv'
 *      isCategory: // value for 'isCategory'
 *      isPolicy: // value for 'isPolicy'
 *      isService: // value for 'isService'
 *      isExternal: // value for 'isExternal'
 *   },
 * });
 */
export function useExpandableTagQuery(
  baseOptions: Apollo.QueryHookOptions<ExpandableTagQuery, ExpandableTagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExpandableTagQuery, ExpandableTagQueryVariables>(
    ExpandableTagDocument,
    options
  );
}
export function useExpandableTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExpandableTagQuery, ExpandableTagQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExpandableTagQuery, ExpandableTagQueryVariables>(
    ExpandableTagDocument,
    options
  );
}
export type ExpandableTagQueryHookResult = ReturnType<typeof useExpandableTagQuery>;
export type ExpandableTagLazyQueryHookResult = ReturnType<typeof useExpandableTagLazyQuery>;
export type ExpandableTagQueryResult = Apollo.QueryResult<
  ExpandableTagQuery,
  ExpandableTagQueryVariables
>;
export const EstateResourceCountDocument = gql`
  query EstateResourceCount($filter: EstateResourceFilterV2) {
    estateResourceCountV2(filter: $filter)
  }
`;

/**
 * __useEstateResourceCountQuery__
 *
 * To run a query within a React component, call `useEstateResourceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateResourceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateResourceCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEstateResourceCountQuery(
  baseOptions?: Apollo.QueryHookOptions<EstateResourceCountQuery, EstateResourceCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstateResourceCountQuery, EstateResourceCountQueryVariables>(
    EstateResourceCountDocument,
    options
  );
}
export function useEstateResourceCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstateResourceCountQuery,
    EstateResourceCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstateResourceCountQuery, EstateResourceCountQueryVariables>(
    EstateResourceCountDocument,
    options
  );
}
export type EstateResourceCountQueryHookResult = ReturnType<typeof useEstateResourceCountQuery>;
export type EstateResourceCountLazyQueryHookResult = ReturnType<
  typeof useEstateResourceCountLazyQuery
>;
export type EstateResourceCountQueryResult = Apollo.QueryResult<
  EstateResourceCountQuery,
  EstateResourceCountQueryVariables
>;
export const GetAccountsDocument = gql`
  query GetAccounts {
    accounts {
      id
      provider
      providerType
      name
      displayName
      nid
      providerId
      activated
    }
  }
`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
}
export function useGetAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(
    GetAccountsDocument,
    options
  );
}
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<
  GetAccountsQuery,
  GetAccountsQueryVariables
>;
export const GoogleCloudProjectsDocument = gql`
  query GoogleCloudProjects($limit: Int!, $page: Int!, $query: String) {
    gcpProjects(limit: $limit, page: $page, query: $query) {
      count
      pages
      projects
    }
  }
`;

/**
 * __useGoogleCloudProjectsQuery__
 *
 * To run a query within a React component, call `useGoogleCloudProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleCloudProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleCloudProjectsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGoogleCloudProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>(
    GoogleCloudProjectsDocument,
    options
  );
}
export function useGoogleCloudProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoogleCloudProjectsQuery,
    GoogleCloudProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoogleCloudProjectsQuery, GoogleCloudProjectsQueryVariables>(
    GoogleCloudProjectsDocument,
    options
  );
}
export type GoogleCloudProjectsQueryHookResult = ReturnType<typeof useGoogleCloudProjectsQuery>;
export type GoogleCloudProjectsLazyQueryHookResult = ReturnType<
  typeof useGoogleCloudProjectsLazyQuery
>;
export type GoogleCloudProjectsQueryResult = Apollo.QueryResult<
  GoogleCloudProjectsQuery,
  GoogleCloudProjectsQueryVariables
>;
export const GetVpcsSubnetsDocument = gql`
  query GetVpcsSubnets($limit: Int!, $page: Int, $query: String) {
    vpcs(limit: $limit, page: $page, query: $query) {
      pages
      count
      results {
        nid
        vpcId
        subnets(limit: $limit) {
          nid
          subnetId
        }
      }
    }
  }
`;

/**
 * __useGetVpcsSubnetsQuery__
 *
 * To run a query within a React component, call `useGetVpcsSubnetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVpcsSubnetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVpcsSubnetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetVpcsSubnetsQuery(
  baseOptions: Apollo.QueryHookOptions<GetVpcsSubnetsQuery, GetVpcsSubnetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVpcsSubnetsQuery, GetVpcsSubnetsQueryVariables>(
    GetVpcsSubnetsDocument,
    options
  );
}
export function useGetVpcsSubnetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetVpcsSubnetsQuery, GetVpcsSubnetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVpcsSubnetsQuery, GetVpcsSubnetsQueryVariables>(
    GetVpcsSubnetsDocument,
    options
  );
}
export type GetVpcsSubnetsQueryHookResult = ReturnType<typeof useGetVpcsSubnetsQuery>;
export type GetVpcsSubnetsLazyQueryHookResult = ReturnType<typeof useGetVpcsSubnetsLazyQuery>;
export type GetVpcsSubnetsQueryResult = Apollo.QueryResult<
  GetVpcsSubnetsQuery,
  GetVpcsSubnetsQueryVariables
>;
export const GetTagsByKeyDocument = gql`
  query GetTagsByKey($key: String!, $limit: Int!, $after: String) {
    searchTagKeys(keyPrefix: $key, limit: $limit, after: $after) {
      keys
      next
    }
  }
`;

/**
 * __useGetTagsByKeyQuery__
 *
 * To run a query within a React component, call `useGetTagsByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByKeyQuery({
 *   variables: {
 *      key: // value for 'key'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTagsByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>(
    GetTagsByKeyDocument,
    options
  );
}
export function useGetTagsByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsByKeyQuery, GetTagsByKeyQueryVariables>(
    GetTagsByKeyDocument,
    options
  );
}
export type GetTagsByKeyQueryHookResult = ReturnType<typeof useGetTagsByKeyQuery>;
export type GetTagsByKeyLazyQueryHookResult = ReturnType<typeof useGetTagsByKeyLazyQuery>;
export type GetTagsByKeyQueryResult = Apollo.QueryResult<
  GetTagsByKeyQuery,
  GetTagsByKeyQueryVariables
>;
export const GetTagsByValueDocument = gql`
  query GetTagsByValue($key: String!, $value: String!, $limit: Int!, $after: String) {
    searchTagValues(key: $key, valuePrefix: $value, limit: $limit, after: $after) {
      values
      next
    }
  }
`;

/**
 * __useGetTagsByValueQuery__
 *
 * To run a query within a React component, call `useGetTagsByValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsByValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsByValueQuery({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTagsByValueQuery(
  baseOptions: Apollo.QueryHookOptions<GetTagsByValueQuery, GetTagsByValueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsByValueQuery, GetTagsByValueQueryVariables>(
    GetTagsByValueDocument,
    options
  );
}
export function useGetTagsByValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTagsByValueQuery, GetTagsByValueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsByValueQuery, GetTagsByValueQueryVariables>(
    GetTagsByValueDocument,
    options
  );
}
export type GetTagsByValueQueryHookResult = ReturnType<typeof useGetTagsByValueQuery>;
export type GetTagsByValueLazyQueryHookResult = ReturnType<typeof useGetTagsByValueLazyQuery>;
export type GetTagsByValueQueryResult = Apollo.QueryResult<
  GetTagsByValueQuery,
  GetTagsByValueQueryVariables
>;
export const CustomerConfigDocument = gql`
  query CustomerConfig {
    customerConfig {
      id
      riEnabled
      allowExperimental
      defaultCurrency
      riCoverageTarget
      enabledFeatures
      anomalyThreshold
    }
  }
`;

/**
 * __useCustomerConfigQuery__
 *
 * To run a query within a React component, call `useCustomerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerConfigQuery, CustomerConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerConfigQuery, CustomerConfigQueryVariables>(
    CustomerConfigDocument,
    options
  );
}
export function useCustomerConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerConfigQuery, CustomerConfigQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerConfigQuery, CustomerConfigQueryVariables>(
    CustomerConfigDocument,
    options
  );
}
export type CustomerConfigQueryHookResult = ReturnType<typeof useCustomerConfigQuery>;
export type CustomerConfigLazyQueryHookResult = ReturnType<typeof useCustomerConfigLazyQuery>;
export type CustomerConfigQueryResult = Apollo.QueryResult<
  CustomerConfigQuery,
  CustomerConfigQueryVariables
>;
export const AddAccountV2Document = gql`
  mutation AddAccountV2($input: AddAccountV2!) {
    addAccountV2(input: $input) {
      id
    }
  }
`;
export type AddAccountV2MutationFn = Apollo.MutationFunction<
  AddAccountV2Mutation,
  AddAccountV2MutationVariables
>;

/**
 * __useAddAccountV2Mutation__
 *
 * To run a mutation, you first call `useAddAccountV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountV2Mutation, { data, loading, error }] = useAddAccountV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAccountV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<AddAccountV2Mutation, AddAccountV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAccountV2Mutation, AddAccountV2MutationVariables>(
    AddAccountV2Document,
    options
  );
}
export type AddAccountV2MutationHookResult = ReturnType<typeof useAddAccountV2Mutation>;
export type AddAccountV2MutationResult = Apollo.MutationResult<AddAccountV2Mutation>;
export type AddAccountV2MutationOptions = Apollo.BaseMutationOptions<
  AddAccountV2Mutation,
  AddAccountV2MutationVariables
>;
export const UpdateAccountV2Document = gql`
  mutation updateAccountV2(
    $id: ID!
    $name: String
    $displayName: String
    $ownerId: ID
    $status: String
    $description: String
    $provider: Provider
    $providerId: ID
    $contactIds: [ID!]
  ) {
    updateAccountV2(
      nid: $id
      input: {
        contactIds: $contactIds
        name: $name
        ownerId: $ownerId
        displayName: $displayName
        status: $status
        description: $description
        provider: $provider
        providerId: $providerId
      }
    )
  }
`;
export type UpdateAccountV2MutationFn = Apollo.MutationFunction<
  UpdateAccountV2Mutation,
  UpdateAccountV2MutationVariables
>;

/**
 * __useUpdateAccountV2Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountV2Mutation, { data, loading, error }] = useUpdateAccountV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      ownerId: // value for 'ownerId'
 *      status: // value for 'status'
 *      description: // value for 'description'
 *      provider: // value for 'provider'
 *      providerId: // value for 'providerId'
 *      contactIds: // value for 'contactIds'
 *   },
 * });
 */
export function useUpdateAccountV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountV2Mutation,
    UpdateAccountV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountV2Mutation, UpdateAccountV2MutationVariables>(
    UpdateAccountV2Document,
    options
  );
}
export type UpdateAccountV2MutationHookResult = ReturnType<typeof useUpdateAccountV2Mutation>;
export type UpdateAccountV2MutationResult = Apollo.MutationResult<UpdateAccountV2Mutation>;
export type UpdateAccountV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountV2Mutation,
  UpdateAccountV2MutationVariables
>;
export const UpdateAwsAccountCredentialsDocument = gql`
  mutation UpdateAwsAccountCredentials(
    $id: ID!
    $roles: [AwsRole!]!
    $additionalCredentials: AwsAdditionalCredentials
  ) {
    updateAwsAccountCredentials(
      nid: $id
      input: { roles: $roles, additionalCredentials: $additionalCredentials }
    )
  }
`;
export type UpdateAwsAccountCredentialsMutationFn = Apollo.MutationFunction<
  UpdateAwsAccountCredentialsMutation,
  UpdateAwsAccountCredentialsMutationVariables
>;

/**
 * __useUpdateAwsAccountCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateAwsAccountCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAwsAccountCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAwsAccountCredentialsMutation, { data, loading, error }] = useUpdateAwsAccountCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roles: // value for 'roles'
 *      additionalCredentials: // value for 'additionalCredentials'
 *   },
 * });
 */
export function useUpdateAwsAccountCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAwsAccountCredentialsMutation,
    UpdateAwsAccountCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAwsAccountCredentialsMutation,
    UpdateAwsAccountCredentialsMutationVariables
  >(UpdateAwsAccountCredentialsDocument, options);
}
export type UpdateAwsAccountCredentialsMutationHookResult = ReturnType<
  typeof useUpdateAwsAccountCredentialsMutation
>;
export type UpdateAwsAccountCredentialsMutationResult =
  Apollo.MutationResult<UpdateAwsAccountCredentialsMutation>;
export type UpdateAwsAccountCredentialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAwsAccountCredentialsMutation,
  UpdateAwsAccountCredentialsMutationVariables
>;
export const UpdateAzureAccountCredentialsDocument = gql`
  mutation UpdateAzureAccountCredentials(
    $id: ID!
    $tenantId: ID!
    $appId: ID!
    $password: String!
  ) {
    updateAzureAccountCredentials(
      nid: $id
      input: { tenantId: $tenantId, appId: $appId, password: $password }
    )
  }
`;
export type UpdateAzureAccountCredentialsMutationFn = Apollo.MutationFunction<
  UpdateAzureAccountCredentialsMutation,
  UpdateAzureAccountCredentialsMutationVariables
>;

/**
 * __useUpdateAzureAccountCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateAzureAccountCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAzureAccountCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAzureAccountCredentialsMutation, { data, loading, error }] = useUpdateAzureAccountCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tenantId: // value for 'tenantId'
 *      appId: // value for 'appId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateAzureAccountCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAzureAccountCredentialsMutation,
    UpdateAzureAccountCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAzureAccountCredentialsMutation,
    UpdateAzureAccountCredentialsMutationVariables
  >(UpdateAzureAccountCredentialsDocument, options);
}
export type UpdateAzureAccountCredentialsMutationHookResult = ReturnType<
  typeof useUpdateAzureAccountCredentialsMutation
>;
export type UpdateAzureAccountCredentialsMutationResult =
  Apollo.MutationResult<UpdateAzureAccountCredentialsMutation>;
export type UpdateAzureAccountCredentialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAzureAccountCredentialsMutation,
  UpdateAzureAccountCredentialsMutationVariables
>;
export const UpdateGcpAccountCredentialsDocument = gql`
  mutation UpdateGcpAccountCredentials($id: ID!, $input: UpdateGcpAccountCredentialsInput!) {
    updateGcpAccountCredentials(nid: $id, input: $input)
  }
`;
export type UpdateGcpAccountCredentialsMutationFn = Apollo.MutationFunction<
  UpdateGcpAccountCredentialsMutation,
  UpdateGcpAccountCredentialsMutationVariables
>;

/**
 * __useUpdateGcpAccountCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateGcpAccountCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGcpAccountCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGcpAccountCredentialsMutation, { data, loading, error }] = useUpdateGcpAccountCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGcpAccountCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGcpAccountCredentialsMutation,
    UpdateGcpAccountCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGcpAccountCredentialsMutation,
    UpdateGcpAccountCredentialsMutationVariables
  >(UpdateGcpAccountCredentialsDocument, options);
}
export type UpdateGcpAccountCredentialsMutationHookResult = ReturnType<
  typeof useUpdateGcpAccountCredentialsMutation
>;
export type UpdateGcpAccountCredentialsMutationResult =
  Apollo.MutationResult<UpdateGcpAccountCredentialsMutation>;
export type UpdateGcpAccountCredentialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateGcpAccountCredentialsMutation,
  UpdateGcpAccountCredentialsMutationVariables
>;
export const UpdateIbmAccountCredentialsDocument = gql`
  mutation UpdateIbmAccountCredentials($id: ID!, $apikey: ID!) {
    updateIbmAccountCredentials(nid: $id, input: { apikey: $apikey })
  }
`;
export type UpdateIbmAccountCredentialsMutationFn = Apollo.MutationFunction<
  UpdateIbmAccountCredentialsMutation,
  UpdateIbmAccountCredentialsMutationVariables
>;

/**
 * __useUpdateIbmAccountCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateIbmAccountCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIbmAccountCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIbmAccountCredentialsMutation, { data, loading, error }] = useUpdateIbmAccountCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      apikey: // value for 'apikey'
 *   },
 * });
 */
export function useUpdateIbmAccountCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIbmAccountCredentialsMutation,
    UpdateIbmAccountCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIbmAccountCredentialsMutation,
    UpdateIbmAccountCredentialsMutationVariables
  >(UpdateIbmAccountCredentialsDocument, options);
}
export type UpdateIbmAccountCredentialsMutationHookResult = ReturnType<
  typeof useUpdateIbmAccountCredentialsMutation
>;
export type UpdateIbmAccountCredentialsMutationResult =
  Apollo.MutationResult<UpdateIbmAccountCredentialsMutation>;
export type UpdateIbmAccountCredentialsMutationOptions = Apollo.BaseMutationOptions<
  UpdateIbmAccountCredentialsMutation,
  UpdateIbmAccountCredentialsMutationVariables
>;
export const RemoveAccountV2Document = gql`
  mutation removeAccountV2($nid: ID!) {
    removeAccountV2(nid: $nid)
  }
`;
export type RemoveAccountV2MutationFn = Apollo.MutationFunction<
  RemoveAccountV2Mutation,
  RemoveAccountV2MutationVariables
>;

/**
 * __useRemoveAccountV2Mutation__
 *
 * To run a mutation, you first call `useRemoveAccountV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountV2Mutation, { data, loading, error }] = useRemoveAccountV2Mutation({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useRemoveAccountV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAccountV2Mutation,
    RemoveAccountV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAccountV2Mutation, RemoveAccountV2MutationVariables>(
    RemoveAccountV2Document,
    options
  );
}
export type RemoveAccountV2MutationHookResult = ReturnType<typeof useRemoveAccountV2Mutation>;
export type RemoveAccountV2MutationResult = Apollo.MutationResult<RemoveAccountV2Mutation>;
export type RemoveAccountV2MutationOptions = Apollo.BaseMutationOptions<
  RemoveAccountV2Mutation,
  RemoveAccountV2MutationVariables
>;
export const AddOrgUnitDocument = gql`
  mutation addOrgUnit($input: AddOrgUnitV2!) {
    addOrgUnitV3(input: $input) {
      ...OrgUnitActionDetails
    }
  }
  ${OrgUnitActionDetailsFragmentDoc}
`;
export type AddOrgUnitMutationFn = Apollo.MutationFunction<
  AddOrgUnitMutation,
  AddOrgUnitMutationVariables
>;

/**
 * __useAddOrgUnitMutation__
 *
 * To run a mutation, you first call `useAddOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrgUnitMutation, { data, loading, error }] = useAddOrgUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrgUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOrgUnitMutation, AddOrgUnitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrgUnitMutation, AddOrgUnitMutationVariables>(
    AddOrgUnitDocument,
    options
  );
}
export type AddOrgUnitMutationHookResult = ReturnType<typeof useAddOrgUnitMutation>;
export type AddOrgUnitMutationResult = Apollo.MutationResult<AddOrgUnitMutation>;
export type AddOrgUnitMutationOptions = Apollo.BaseMutationOptions<
  AddOrgUnitMutation,
  AddOrgUnitMutationVariables
>;
export const RemoveOrgUnitDocument = gql`
  mutation removeOrgUnit($id: ID!) {
    removeOrgUnitV2(nid: $id)
  }
`;
export type RemoveOrgUnitMutationFn = Apollo.MutationFunction<
  RemoveOrgUnitMutation,
  RemoveOrgUnitMutationVariables
>;

/**
 * __useRemoveOrgUnitMutation__
 *
 * To run a mutation, you first call `useRemoveOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgUnitMutation, { data, loading, error }] = useRemoveOrgUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrgUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveOrgUnitMutation, RemoveOrgUnitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveOrgUnitMutation, RemoveOrgUnitMutationVariables>(
    RemoveOrgUnitDocument,
    options
  );
}
export type RemoveOrgUnitMutationHookResult = ReturnType<typeof useRemoveOrgUnitMutation>;
export type RemoveOrgUnitMutationResult = Apollo.MutationResult<RemoveOrgUnitMutation>;
export type RemoveOrgUnitMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrgUnitMutation,
  RemoveOrgUnitMutationVariables
>;
export const UpdateOrgUnitDocument = gql`
  mutation updateOrgUnit($nid: ID!, $input: UpdateOrgUnitV2!) {
    updateOrgUnitV4(nid: $nid, input: $input) {
      orgUnit {
        ...OrgUnitActionDetails
      }
      userErrors {
        message
        code
      }
    }
  }
  ${OrgUnitActionDetailsFragmentDoc}
`;
export type UpdateOrgUnitMutationFn = Apollo.MutationFunction<
  UpdateOrgUnitMutation,
  UpdateOrgUnitMutationVariables
>;

/**
 * __useUpdateOrgUnitMutation__
 *
 * To run a mutation, you first call `useUpdateOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgUnitMutation, { data, loading, error }] = useUpdateOrgUnitMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrgUnitMutation, UpdateOrgUnitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgUnitMutation, UpdateOrgUnitMutationVariables>(
    UpdateOrgUnitDocument,
    options
  );
}
export type UpdateOrgUnitMutationHookResult = ReturnType<typeof useUpdateOrgUnitMutation>;
export type UpdateOrgUnitMutationResult = Apollo.MutationResult<UpdateOrgUnitMutation>;
export type UpdateOrgUnitMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgUnitMutation,
  UpdateOrgUnitMutationVariables
>;
export const AddPersonDocument = gql`
  mutation addPerson($name: String!, $email: String!) {
    addPersonV2(input: { name: $name, email: $email }) {
      ...PersonFields
    }
  }
  ${PersonFieldsFragmentDoc}
`;
export type AddPersonMutationFn = Apollo.MutationFunction<
  AddPersonMutation,
  AddPersonMutationVariables
>;

/**
 * __useAddPersonMutation__
 *
 * To run a mutation, you first call `useAddPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonMutation, { data, loading, error }] = useAddPersonMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPersonMutation, AddPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPersonMutation, AddPersonMutationVariables>(
    AddPersonDocument,
    options
  );
}
export type AddPersonMutationHookResult = ReturnType<typeof useAddPersonMutation>;
export type AddPersonMutationResult = Apollo.MutationResult<AddPersonMutation>;
export type AddPersonMutationOptions = Apollo.BaseMutationOptions<
  AddPersonMutation,
  AddPersonMutationVariables
>;
export const RemovePersonDocument = gql`
  mutation removePerson($id: String!) {
    removePerson(id: $id)
  }
`;
export type RemovePersonMutationFn = Apollo.MutationFunction<
  RemovePersonMutation,
  RemovePersonMutationVariables
>;

/**
 * __useRemovePersonMutation__
 *
 * To run a mutation, you first call `useRemovePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePersonMutation, { data, loading, error }] = useRemovePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<RemovePersonMutation, RemovePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePersonMutation, RemovePersonMutationVariables>(
    RemovePersonDocument,
    options
  );
}
export type RemovePersonMutationHookResult = ReturnType<typeof useRemovePersonMutation>;
export type RemovePersonMutationResult = Apollo.MutationResult<RemovePersonMutation>;
export type RemovePersonMutationOptions = Apollo.BaseMutationOptions<
  RemovePersonMutation,
  RemovePersonMutationVariables
>;
export const UpdatePersonDocument = gql`
  mutation updatePerson($id: String!, $name: String, $email: String) {
    updatePersonV2(input: { id: $id, name: $name, email: $email }) {
      ...PersonFields
    }
  }
  ${PersonFieldsFragmentDoc}
`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(
    UpdatePersonDocument,
    options
  );
}
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;
export const ResourceTypesDocument = gql`
  query ResourceTypes($provider: [Provider!], $categoryIDs: [ID!]) {
    resourceTypes(provider: $provider, categoryIDs: $categoryIDs)
  }
`;

/**
 * __useResourceTypesQuery__
 *
 * To run a query within a React component, call `useResourceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceTypesQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *      categoryIDs: // value for 'categoryIDs'
 *   },
 * });
 */
export function useResourceTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<ResourceTypesQuery, ResourceTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceTypesQuery, ResourceTypesQueryVariables>(
    ResourceTypesDocument,
    options
  );
}
export function useResourceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResourceTypesQuery, ResourceTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceTypesQuery, ResourceTypesQueryVariables>(
    ResourceTypesDocument,
    options
  );
}
export type ResourceTypesQueryHookResult = ReturnType<typeof useResourceTypesQuery>;
export type ResourceTypesLazyQueryHookResult = ReturnType<typeof useResourceTypesLazyQuery>;
export type ResourceTypesQueryResult = Apollo.QueryResult<
  ResourceTypesQuery,
  ResourceTypesQueryVariables
>;
export const NotificationListDocument = gql`
  query NotificationList($cursor: String, $limit: Int!) {
    notifications(limit: $limit, cursor: $cursor) {
      notifications {
        id
        type
        title
        details
        targetView
        read
        createdAt
      }
      cursor
    }
  }
`;

/**
 * __useNotificationListQuery__
 *
 * To run a query within a React component, call `useNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNotificationListQuery(
  baseOptions: Apollo.QueryHookOptions<NotificationListQuery, NotificationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationListQuery, NotificationListQueryVariables>(
    NotificationListDocument,
    options
  );
}
export function useNotificationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationListQuery, NotificationListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationListQuery, NotificationListQueryVariables>(
    NotificationListDocument,
    options
  );
}
export type NotificationListQueryHookResult = ReturnType<typeof useNotificationListQuery>;
export type NotificationListLazyQueryHookResult = ReturnType<typeof useNotificationListLazyQuery>;
export type NotificationListQueryResult = Apollo.QueryResult<
  NotificationListQuery,
  NotificationListQueryVariables
>;
export const MarkNotificationsAsReadDocument = gql`
  mutation markNotificationsAsRead($ids: [String!]!) {
    markNotificationsAsRead(ids: $ids)
  }
`;
export type MarkNotificationsAsReadMutationFn = Apollo.MutationFunction<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;

/**
 * __useMarkNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsReadMutation, { data, loading, error }] = useMarkNotificationsAsReadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkNotificationsAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationsAsReadMutation,
    MarkNotificationsAsReadMutationVariables
  >(MarkNotificationsAsReadDocument, options);
}
export type MarkNotificationsAsReadMutationHookResult = ReturnType<
  typeof useMarkNotificationsAsReadMutation
>;
export type MarkNotificationsAsReadMutationResult =
  Apollo.MutationResult<MarkNotificationsAsReadMutation>;
export type MarkNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;
export const TriggerRecalculationDocument = gql`
  mutation TriggerRecalculation {
    triggerRecalculation
  }
`;
export type TriggerRecalculationMutationFn = Apollo.MutationFunction<
  TriggerRecalculationMutation,
  TriggerRecalculationMutationVariables
>;

/**
 * __useTriggerRecalculationMutation__
 *
 * To run a mutation, you first call `useTriggerRecalculationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerRecalculationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerRecalculationMutation, { data, loading, error }] = useTriggerRecalculationMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerRecalculationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerRecalculationMutation,
    TriggerRecalculationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TriggerRecalculationMutation, TriggerRecalculationMutationVariables>(
    TriggerRecalculationDocument,
    options
  );
}
export type TriggerRecalculationMutationHookResult = ReturnType<
  typeof useTriggerRecalculationMutation
>;
export type TriggerRecalculationMutationResult =
  Apollo.MutationResult<TriggerRecalculationMutation>;
export type TriggerRecalculationMutationOptions = Apollo.BaseMutationOptions<
  TriggerRecalculationMutation,
  TriggerRecalculationMutationVariables
>;
export const GetCustomerDirtinessDocument = gql`
  query GetCustomerDirtiness {
    customerDirtiness {
      isDirty
    }
  }
`;

/**
 * __useGetCustomerDirtinessQuery__
 *
 * To run a query within a React component, call `useGetCustomerDirtinessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDirtinessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDirtinessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerDirtinessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomerDirtinessQuery,
    GetCustomerDirtinessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerDirtinessQuery, GetCustomerDirtinessQueryVariables>(
    GetCustomerDirtinessDocument,
    options
  );
}
export function useGetCustomerDirtinessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomerDirtinessQuery,
    GetCustomerDirtinessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerDirtinessQuery, GetCustomerDirtinessQueryVariables>(
    GetCustomerDirtinessDocument,
    options
  );
}
export type GetCustomerDirtinessQueryHookResult = ReturnType<typeof useGetCustomerDirtinessQuery>;
export type GetCustomerDirtinessLazyQueryHookResult = ReturnType<
  typeof useGetCustomerDirtinessLazyQuery
>;
export type GetCustomerDirtinessQueryResult = Apollo.QueryResult<
  GetCustomerDirtinessQuery,
  GetCustomerDirtinessQueryVariables
>;
export const GetRecalculationStatusDocument = gql`
  query GetRecalculationStatus {
    recalculations {
      status
    }
  }
`;

/**
 * __useGetRecalculationStatusQuery__
 *
 * To run a query within a React component, call `useGetRecalculationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecalculationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecalculationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecalculationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecalculationStatusQuery,
    GetRecalculationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecalculationStatusQuery, GetRecalculationStatusQueryVariables>(
    GetRecalculationStatusDocument,
    options
  );
}
export function useGetRecalculationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecalculationStatusQuery,
    GetRecalculationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecalculationStatusQuery, GetRecalculationStatusQueryVariables>(
    GetRecalculationStatusDocument,
    options
  );
}
export type GetRecalculationStatusQueryHookResult = ReturnType<
  typeof useGetRecalculationStatusQuery
>;
export type GetRecalculationStatusLazyQueryHookResult = ReturnType<
  typeof useGetRecalculationStatusLazyQuery
>;
export type GetRecalculationStatusQueryResult = Apollo.QueryResult<
  GetRecalculationStatusQuery,
  GetRecalculationStatusQueryVariables
>;
export const AccumulatedCostDocument = gql`
  query AccumulatedCost(
    $startDate: String!
    $endDate: String!
    $granularity: Granularity
    $tableGranularity: Granularity
  ) {
    accumulatedCost(
      input: { startDate: $startDate, endDate: $endDate, granularity: $granularity }
    ) {
      ...AccumulatedCostValue
    }
    accumulatedCostTable: accumulatedCost(
      input: { startDate: $startDate, endDate: $endDate, granularity: $tableGranularity }
    ) {
      ...AccumulatedCostValue
    }
    customerCorrectionCost(
      input: { startDate: $startDate, endDate: $endDate, granularity: $granularity }
    ) {
      ...CustomerCorrectionTimepoints
    }
  }
  ${AccumulatedCostValueFragmentDoc}
  ${CustomerCorrectionTimepointsFragmentDoc}
`;

/**
 * __useAccumulatedCostQuery__
 *
 * To run a query within a React component, call `useAccumulatedCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccumulatedCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccumulatedCostQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useAccumulatedCostQuery(
  baseOptions: Apollo.QueryHookOptions<AccumulatedCostQuery, AccumulatedCostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccumulatedCostQuery, AccumulatedCostQueryVariables>(
    AccumulatedCostDocument,
    options
  );
}
export function useAccumulatedCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccumulatedCostQuery, AccumulatedCostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccumulatedCostQuery, AccumulatedCostQueryVariables>(
    AccumulatedCostDocument,
    options
  );
}
export type AccumulatedCostQueryHookResult = ReturnType<typeof useAccumulatedCostQuery>;
export type AccumulatedCostLazyQueryHookResult = ReturnType<typeof useAccumulatedCostLazyQuery>;
export type AccumulatedCostQueryResult = Apollo.QueryResult<
  AccumulatedCostQuery,
  AccumulatedCostQueryVariables
>;
export const ApplicationsCostAnalysisDocument = gql`
  query ApplicationsCostAnalysis(
    $limit: Int!
    $page: Int!
    $startDate: String!
    $endDate: String!
    $granularity: Granularity
    $tableGranularity: Granularity
  ) {
    customerApplicationsCost(
      input: {
        limit: $limit
        page: $page
        startDate: $startDate
        endDate: $endDate
        granularity: $granularity
      }
    ) {
      ...ApplicationsCostValue
    }
    customerApplicationsTableCost: customerApplicationsCost(
      input: {
        limit: $limit
        page: $page
        startDate: $startDate
        endDate: $endDate
        granularity: $tableGranularity
      }
    ) {
      ...ApplicationsCostValue
    }
    customerCorrectionCost(
      input: { startDate: $startDate, endDate: $endDate, granularity: $granularity }
    ) {
      ...CustomerCorrectionTimepoints
    }
  }
  ${ApplicationsCostValueFragmentDoc}
  ${CustomerCorrectionTimepointsFragmentDoc}
`;

/**
 * __useApplicationsCostAnalysisQuery__
 *
 * To run a query within a React component, call `useApplicationsCostAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsCostAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsCostAnalysisQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useApplicationsCostAnalysisQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsCostAnalysisQuery,
    ApplicationsCostAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsCostAnalysisQuery, ApplicationsCostAnalysisQueryVariables>(
    ApplicationsCostAnalysisDocument,
    options
  );
}
export function useApplicationsCostAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsCostAnalysisQuery,
    ApplicationsCostAnalysisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationsCostAnalysisQuery, ApplicationsCostAnalysisQueryVariables>(
    ApplicationsCostAnalysisDocument,
    options
  );
}
export type ApplicationsCostAnalysisQueryHookResult = ReturnType<
  typeof useApplicationsCostAnalysisQuery
>;
export type ApplicationsCostAnalysisLazyQueryHookResult = ReturnType<
  typeof useApplicationsCostAnalysisLazyQuery
>;
export type ApplicationsCostAnalysisQueryResult = Apollo.QueryResult<
  ApplicationsCostAnalysisQuery,
  ApplicationsCostAnalysisQueryVariables
>;
export const ApplicationsCostPerCategoryDocument = gql`
  query ApplicationsCostPerCategory(
    $limit: Int!
    $page: Int!
    $startDate: String!
    $endDate: String!
    $granularity: Granularity
    $tableGranularity: Granularity
  ) {
    customerCategoriesCost(
      input: {
        limit: $limit
        page: $page
        startDate: $startDate
        endDate: $endDate
        granularity: $granularity
      }
    ) {
      ...ApplicationsCostPerCategoryValue
    }
    customerCategoriesTableCost: customerCategoriesCost(
      input: {
        limit: $limit
        page: $page
        startDate: $startDate
        endDate: $endDate
        granularity: $tableGranularity
      }
    ) {
      ...ApplicationsCostPerCategoryValue
    }
    customerCorrectionCost(
      input: { startDate: $startDate, endDate: $endDate, granularity: $granularity }
    ) {
      ...CustomerCorrectionTimepoints
    }
  }
  ${ApplicationsCostPerCategoryValueFragmentDoc}
  ${CustomerCorrectionTimepointsFragmentDoc}
`;

/**
 * __useApplicationsCostPerCategoryQuery__
 *
 * To run a query within a React component, call `useApplicationsCostPerCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsCostPerCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsCostPerCategoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useApplicationsCostPerCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsCostPerCategoryQuery,
    ApplicationsCostPerCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationsCostPerCategoryQuery,
    ApplicationsCostPerCategoryQueryVariables
  >(ApplicationsCostPerCategoryDocument, options);
}
export function useApplicationsCostPerCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsCostPerCategoryQuery,
    ApplicationsCostPerCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationsCostPerCategoryQuery,
    ApplicationsCostPerCategoryQueryVariables
  >(ApplicationsCostPerCategoryDocument, options);
}
export type ApplicationsCostPerCategoryQueryHookResult = ReturnType<
  typeof useApplicationsCostPerCategoryQuery
>;
export type ApplicationsCostPerCategoryLazyQueryHookResult = ReturnType<
  typeof useApplicationsCostPerCategoryLazyQuery
>;
export type ApplicationsCostPerCategoryQueryResult = Apollo.QueryResult<
  ApplicationsCostPerCategoryQuery,
  ApplicationsCostPerCategoryQueryVariables
>;
export const ApplicationsCostPerServiceDocument = gql`
  query ApplicationsCostPerService(
    $limit: Int!
    $page: Int!
    $startDate: String!
    $endDate: String!
    $granularity: Granularity
    $tableGranularity: Granularity
  ) {
    customerServicesCost(
      input: {
        limit: $limit
        page: $page
        startDate: $startDate
        endDate: $endDate
        granularity: $granularity
      }
    ) {
      ...ApplicationsCostPerServicesValue
    }
    customerServicesTableCost: customerServicesCostV2(
      input: {
        limit: $limit
        page: $page
        startDate: $startDate
        endDate: $endDate
        granularity: $tableGranularity
      }
    ) {
      ...ApplicationsCostPerServicesValueV2
    }
    customerCorrectionCost(
      input: { startDate: $startDate, endDate: $endDate, granularity: $granularity }
    ) {
      ...CustomerCorrectionTimepoints
    }
  }
  ${ApplicationsCostPerServicesValueFragmentDoc}
  ${ApplicationsCostPerServicesValueV2FragmentDoc}
  ${CustomerCorrectionTimepointsFragmentDoc}
`;

/**
 * __useApplicationsCostPerServiceQuery__
 *
 * To run a query within a React component, call `useApplicationsCostPerServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsCostPerServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsCostPerServiceQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useApplicationsCostPerServiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsCostPerServiceQuery,
    ApplicationsCostPerServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsCostPerServiceQuery, ApplicationsCostPerServiceQueryVariables>(
    ApplicationsCostPerServiceDocument,
    options
  );
}
export function useApplicationsCostPerServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsCostPerServiceQuery,
    ApplicationsCostPerServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationsCostPerServiceQuery,
    ApplicationsCostPerServiceQueryVariables
  >(ApplicationsCostPerServiceDocument, options);
}
export type ApplicationsCostPerServiceQueryHookResult = ReturnType<
  typeof useApplicationsCostPerServiceQuery
>;
export type ApplicationsCostPerServiceLazyQueryHookResult = ReturnType<
  typeof useApplicationsCostPerServiceLazyQuery
>;
export type ApplicationsCostPerServiceQueryResult = Apollo.QueryResult<
  ApplicationsCostPerServiceQuery,
  ApplicationsCostPerServiceQueryVariables
>;
export const ApplicationFormDataDocument = gql`
  query ApplicationFormData($id: String!) {
    application(id: $id) {
      id
      nid
      owner {
        id
        name
      }
      environments {
        id
        nid
        name
        environmentType
        description
        contacts {
          id
        }
      }
    }
  }
`;

/**
 * __useApplicationFormDataQuery__
 *
 * To run a query within a React component, call `useApplicationFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationFormDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationFormDataQuery(
  baseOptions: Apollo.QueryHookOptions<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>(
    ApplicationFormDataDocument,
    options
  );
}
export function useApplicationFormDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationFormDataQuery,
    ApplicationFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationFormDataQuery, ApplicationFormDataQueryVariables>(
    ApplicationFormDataDocument,
    options
  );
}
export type ApplicationFormDataQueryHookResult = ReturnType<typeof useApplicationFormDataQuery>;
export type ApplicationFormDataLazyQueryHookResult = ReturnType<
  typeof useApplicationFormDataLazyQuery
>;
export type ApplicationFormDataQueryResult = Apollo.QueryResult<
  ApplicationFormDataQuery,
  ApplicationFormDataQueryVariables
>;
export const ApplicationsPaginatedDocument = gql`
  query ApplicationsPaginated($limit: Int!, $page: Int!, $year: String!) {
    applicationsPaginated(limit: $limit, page: $page) {
      pages
      count
      results {
        name
        id
        resourceCount
        hasAnomalies
        budgetYearly(year: $year) {
          id
          budgetByMonth
          yearlySum
        }
        cloudWasteAggregate {
          hasWaste
          cost
          count
          co2e
        }
        co2e(timePeriod: CURRENTMONTH)
        cost {
          currentMonth
          previousMonth
          forecast
        }
        environmentsV2(page: 1, limit: 1) {
          count
        }
      }
    }
  }
`;

/**
 * __useApplicationsPaginatedQuery__
 *
 * To run a query within a React component, call `useApplicationsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsPaginatedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useApplicationsPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsPaginatedQuery,
    ApplicationsPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsPaginatedQuery, ApplicationsPaginatedQueryVariables>(
    ApplicationsPaginatedDocument,
    options
  );
}
export function useApplicationsPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsPaginatedQuery,
    ApplicationsPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationsPaginatedQuery, ApplicationsPaginatedQueryVariables>(
    ApplicationsPaginatedDocument,
    options
  );
}
export type ApplicationsPaginatedQueryHookResult = ReturnType<typeof useApplicationsPaginatedQuery>;
export type ApplicationsPaginatedLazyQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedLazyQuery
>;
export type ApplicationsPaginatedQueryResult = Apollo.QueryResult<
  ApplicationsPaginatedQuery,
  ApplicationsPaginatedQueryVariables
>;
export const ApplicationEnvironmentDocument = gql`
  query ApplicationEnvironment($id: String!, $year: String!, $limit: Int!, $page: Int!) {
    application(id: $id) {
      id
      nid
      environmentsV2(limit: $limit, page: $page) {
        count
        pages
        environments {
          id
          ...ApplicationEnvironment
        }
      }
    }
  }
  ${ApplicationEnvironmentFragmentDoc}
`;

/**
 * __useApplicationEnvironmentQuery__
 *
 * To run a query within a React component, call `useApplicationEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationEnvironmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useApplicationEnvironmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationEnvironmentQuery,
    ApplicationEnvironmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationEnvironmentQuery, ApplicationEnvironmentQueryVariables>(
    ApplicationEnvironmentDocument,
    options
  );
}
export function useApplicationEnvironmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationEnvironmentQuery,
    ApplicationEnvironmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationEnvironmentQuery, ApplicationEnvironmentQueryVariables>(
    ApplicationEnvironmentDocument,
    options
  );
}
export type ApplicationEnvironmentQueryHookResult = ReturnType<
  typeof useApplicationEnvironmentQuery
>;
export type ApplicationEnvironmentLazyQueryHookResult = ReturnType<
  typeof useApplicationEnvironmentLazyQuery
>;
export type ApplicationEnvironmentQueryResult = Apollo.QueryResult<
  ApplicationEnvironmentQuery,
  ApplicationEnvironmentQueryVariables
>;
export const NidEventsDocument = gql`
  query NidEvents($to: AWSDateTime!, $from: AWSDateTime!, $nid: String!) {
    events(to: $to, from: $from, nid: $nid) {
      id
      title
      type
      content
      createdAt
    }
  }
`;

/**
 * __useNidEventsQuery__
 *
 * To run a query within a React component, call `useNidEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNidEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNidEventsQuery({
 *   variables: {
 *      to: // value for 'to'
 *      from: // value for 'from'
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useNidEventsQuery(
  baseOptions: Apollo.QueryHookOptions<NidEventsQuery, NidEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NidEventsQuery, NidEventsQueryVariables>(NidEventsDocument, options);
}
export function useNidEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NidEventsQuery, NidEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NidEventsQuery, NidEventsQueryVariables>(NidEventsDocument, options);
}
export type NidEventsQueryHookResult = ReturnType<typeof useNidEventsQuery>;
export type NidEventsLazyQueryHookResult = ReturnType<typeof useNidEventsLazyQuery>;
export type NidEventsQueryResult = Apollo.QueryResult<NidEventsQuery, NidEventsQueryVariables>;
export const ApplicationDocument = gql`
  query Application($id: String!, $year: String!) {
    application(id: $id) {
      ...ApplicationBaseDetails
      budgetYearly(year: $year) {
        id
        budgetByMonth
      }
      cloudWasteAggregate {
        hasWaste
        cost
        co2e
        count
      }
      cost {
        currentMonth
        currentMonthDaily
        previousMonth
        forecast
      }
      co2e(timePeriod: CURRENTMONTH)
      environments {
        ...EnvironmentBaseDetails
        budgetYearly(year: $year) {
          id
          budgetByMonth
        }
        cloudWasteAggregate {
          hasWaste
          cost
          count
          co2e
        }
        co2e(timePeriod: CURRENTMONTH)
        orgUnitsV2(limit: 50, page: 0) {
          orgUnits {
            id
            nid
            name
            businessContext {
              id
              color
              name
            }
          }
        }
        resourceCount
        cost {
          currentMonth
          previousMonth
          forecast
        }
      }
    }
  }
  ${ApplicationBaseDetailsFragmentDoc}
  ${EnvironmentBaseDetailsFragmentDoc}
`;

/**
 * __useApplicationQuery__
 *
 * To run a query within a React component, call `useApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<ApplicationQuery, ApplicationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, options);
}
export function useApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationQuery, ApplicationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options
  );
}
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>;
export type ApplicationLazyQueryHookResult = ReturnType<typeof useApplicationLazyQuery>;
export type ApplicationQueryResult = Apollo.QueryResult<
  ApplicationQuery,
  ApplicationQueryVariables
>;
export const AddApplicationDocument = gql`
  mutation addApplication($name: String!, $ownerId: ID!, $description: String, $contactIds: [ID!]) {
    addApplicationV2(
      input: {
        name: $name
        displayName: $name
        ownerId: $ownerId
        description: $description
        contactIds: $contactIds
      }
    ) {
      id
    }
  }
`;
export type AddApplicationMutationFn = Apollo.MutationFunction<
  AddApplicationMutation,
  AddApplicationMutationVariables
>;

/**
 * __useAddApplicationMutation__
 *
 * To run a mutation, you first call `useAddApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicationMutation, { data, loading, error }] = useAddApplicationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ownerId: // value for 'ownerId'
 *      description: // value for 'description'
 *      contactIds: // value for 'contactIds'
 *   },
 * });
 */
export function useAddApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<AddApplicationMutation, AddApplicationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddApplicationMutation, AddApplicationMutationVariables>(
    AddApplicationDocument,
    options
  );
}
export type AddApplicationMutationHookResult = ReturnType<typeof useAddApplicationMutation>;
export type AddApplicationMutationResult = Apollo.MutationResult<AddApplicationMutation>;
export type AddApplicationMutationOptions = Apollo.BaseMutationOptions<
  AddApplicationMutation,
  AddApplicationMutationVariables
>;
export const UpdateApplicationDocument = gql`
  mutation updateApplication($nid: ID!, $input: UpdateApplicationV2!) {
    updateApplicationV3(nid: $nid, input: $input) {
      ...ApplicationBaseDetails
    }
  }
  ${ApplicationBaseDetailsFragmentDoc}
`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;

/**
 * __useUpdateApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationMutation, { data, loading, error }] = useUpdateApplicationMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationMutation,
    UpdateApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(
    UpdateApplicationDocument,
    options
  );
}
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>;
export type UpdateApplicationMutationOptions = Apollo.BaseMutationOptions<
  UpdateApplicationMutation,
  UpdateApplicationMutationVariables
>;
export const RemoveApplicationDocument = gql`
  mutation removeApplication($nid: ID!) {
    removeApplicationV2(nid: $nid)
  }
`;
export type RemoveApplicationMutationFn = Apollo.MutationFunction<
  RemoveApplicationMutation,
  RemoveApplicationMutationVariables
>;

/**
 * __useRemoveApplicationMutation__
 *
 * To run a mutation, you first call `useRemoveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApplicationMutation, { data, loading, error }] = useRemoveApplicationMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useRemoveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveApplicationMutation,
    RemoveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveApplicationMutation, RemoveApplicationMutationVariables>(
    RemoveApplicationDocument,
    options
  );
}
export type RemoveApplicationMutationHookResult = ReturnType<typeof useRemoveApplicationMutation>;
export type RemoveApplicationMutationResult = Apollo.MutationResult<RemoveApplicationMutation>;
export type RemoveApplicationMutationOptions = Apollo.BaseMutationOptions<
  RemoveApplicationMutation,
  RemoveApplicationMutationVariables
>;
export const UpdateEnvironmentDocument = gql`
  mutation updateEnvironment($nid: ID!, $input: UpdateEnvironmentV2!) {
    updateEnvironmentV3(nid: $nid, input: $input) {
      ...EnvironmentBaseDetails
    }
  }
  ${EnvironmentBaseDetailsFragmentDoc}
`;
export type UpdateEnvironmentMutationFn = Apollo.MutationFunction<
  UpdateEnvironmentMutation,
  UpdateEnvironmentMutationVariables
>;

/**
 * __useUpdateEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentMutation, { data, loading, error }] = useUpdateEnvironmentMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEnvironmentMutation,
    UpdateEnvironmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>(
    UpdateEnvironmentDocument,
    options
  );
}
export type UpdateEnvironmentMutationHookResult = ReturnType<typeof useUpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationResult = Apollo.MutationResult<UpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateEnvironmentMutation,
  UpdateEnvironmentMutationVariables
>;
export const AddEnvironmentDocument = gql`
  mutation AddEnvironment($input: AddEnvironmentV2!) {
    addEnvironmentV3(input: $input) {
      ...EnvironmentBaseDetails
    }
  }
  ${EnvironmentBaseDetailsFragmentDoc}
`;
export type AddEnvironmentMutationFn = Apollo.MutationFunction<
  AddEnvironmentMutation,
  AddEnvironmentMutationVariables
>;

/**
 * __useAddEnvironmentMutation__
 *
 * To run a mutation, you first call `useAddEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEnvironmentMutation, { data, loading, error }] = useAddEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddEnvironmentMutation, AddEnvironmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddEnvironmentMutation, AddEnvironmentMutationVariables>(
    AddEnvironmentDocument,
    options
  );
}
export type AddEnvironmentMutationHookResult = ReturnType<typeof useAddEnvironmentMutation>;
export type AddEnvironmentMutationResult = Apollo.MutationResult<AddEnvironmentMutation>;
export type AddEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  AddEnvironmentMutation,
  AddEnvironmentMutationVariables
>;
export const GetDefaultApplicationDocument = gql`
  query getDefaultApplication {
    defaultApplication {
      id: nid
      nid
      name
      cloudWasteAggregate {
        hasWaste
        cost
        count
        co2e
      }
      co2e(timePeriod: CURRENTMONTH)
      cost {
        currentMonth
        previousMonth
        forecast
      }
      environments {
        id
        name
        resourceCount
      }
    }
  }
`;

/**
 * __useGetDefaultApplicationQuery__
 *
 * To run a query within a React component, call `useGetDefaultApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDefaultApplicationQuery,
    GetDefaultApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDefaultApplicationQuery, GetDefaultApplicationQueryVariables>(
    GetDefaultApplicationDocument,
    options
  );
}
export function useGetDefaultApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultApplicationQuery,
    GetDefaultApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDefaultApplicationQuery, GetDefaultApplicationQueryVariables>(
    GetDefaultApplicationDocument,
    options
  );
}
export type GetDefaultApplicationQueryHookResult = ReturnType<typeof useGetDefaultApplicationQuery>;
export type GetDefaultApplicationLazyQueryHookResult = ReturnType<
  typeof useGetDefaultApplicationLazyQuery
>;
export type GetDefaultApplicationQueryResult = Apollo.QueryResult<
  GetDefaultApplicationQuery,
  GetDefaultApplicationQueryVariables
>;
export const GetAnomaliesDocument = gql`
  query getAnomalies($input: AnomaliesQueryInput!) {
    anomalies(input: $input) {
      anomaly
      date
      cost
      forecastedCost
      upperCostEstimate
      lowerCostEstimate
      anomalyCost
    }
  }
`;

/**
 * __useGetAnomaliesQuery__
 *
 * To run a query within a React component, call `useGetAnomaliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomaliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomaliesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAnomaliesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnomaliesQuery, GetAnomaliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnomaliesQuery, GetAnomaliesQueryVariables>(
    GetAnomaliesDocument,
    options
  );
}
export function useGetAnomaliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnomaliesQuery, GetAnomaliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnomaliesQuery, GetAnomaliesQueryVariables>(
    GetAnomaliesDocument,
    options
  );
}
export type GetAnomaliesQueryHookResult = ReturnType<typeof useGetAnomaliesQuery>;
export type GetAnomaliesLazyQueryHookResult = ReturnType<typeof useGetAnomaliesLazyQuery>;
export type GetAnomaliesQueryResult = Apollo.QueryResult<
  GetAnomaliesQuery,
  GetAnomaliesQueryVariables
>;
export const UpdateAnomalyThresholdDocument = gql`
  mutation updateAnomalyThreshold($input: UpdateAnomalyThreshold!) {
    updateAnomalyThreshold(input: $input)
  }
`;
export type UpdateAnomalyThresholdMutationFn = Apollo.MutationFunction<
  UpdateAnomalyThresholdMutation,
  UpdateAnomalyThresholdMutationVariables
>;

/**
 * __useUpdateAnomalyThresholdMutation__
 *
 * To run a mutation, you first call `useUpdateAnomalyThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnomalyThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnomalyThresholdMutation, { data, loading, error }] = useUpdateAnomalyThresholdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnomalyThresholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnomalyThresholdMutation,
    UpdateAnomalyThresholdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnomalyThresholdMutation,
    UpdateAnomalyThresholdMutationVariables
  >(UpdateAnomalyThresholdDocument, options);
}
export type UpdateAnomalyThresholdMutationHookResult = ReturnType<
  typeof useUpdateAnomalyThresholdMutation
>;
export type UpdateAnomalyThresholdMutationResult =
  Apollo.MutationResult<UpdateAnomalyThresholdMutation>;
export type UpdateAnomalyThresholdMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnomalyThresholdMutation,
  UpdateAnomalyThresholdMutationVariables
>;
export const GetAnomalyDetailsDocument = gql`
  query getAnomalyDetails($date: String!, $applicationId: String!, $limit: Int, $page: Int) {
    anomalyV2(date: $date, applicationId: $applicationId, limit: $limit, page: $page) {
      count
      resources {
        type
        cost
        anomaly
        provider
        anomalyCost
        minDailyCost
        maxDailyCost
        subRows(applicationId: $applicationId, date: $date) {
          subType
          cost
          subRows {
            name
            nid
            cost
          }
        }
      }
    }
  }
`;

/**
 * __useGetAnomalyDetailsQuery__
 *
 * To run a query within a React component, call `useGetAnomalyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnomalyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnomalyDetailsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      applicationId: // value for 'applicationId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetAnomalyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnomalyDetailsQuery, GetAnomalyDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnomalyDetailsQuery, GetAnomalyDetailsQueryVariables>(
    GetAnomalyDetailsDocument,
    options
  );
}
export function useGetAnomalyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnomalyDetailsQuery, GetAnomalyDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnomalyDetailsQuery, GetAnomalyDetailsQueryVariables>(
    GetAnomalyDetailsDocument,
    options
  );
}
export type GetAnomalyDetailsQueryHookResult = ReturnType<typeof useGetAnomalyDetailsQuery>;
export type GetAnomalyDetailsLazyQueryHookResult = ReturnType<typeof useGetAnomalyDetailsLazyQuery>;
export type GetAnomalyDetailsQueryResult = Apollo.QueryResult<
  GetAnomalyDetailsQuery,
  GetAnomalyDetailsQueryVariables
>;
export const GlobalCostAllocationDocument = gql`
  query GlobalCostAllocation($startDate: String!, $endDate: String!) {
    globalMetrics {
      costAllocation(startDate: $startDate, endDate: $endDate) {
        timeSeries {
          date
          value
          isEstimated
        }
      }
    }
  }
`;

/**
 * __useGlobalCostAllocationQuery__
 *
 * To run a query within a React component, call `useGlobalCostAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalCostAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalCostAllocationQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGlobalCostAllocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GlobalCostAllocationQuery,
    GlobalCostAllocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalCostAllocationQuery, GlobalCostAllocationQueryVariables>(
    GlobalCostAllocationDocument,
    options
  );
}
export function useGlobalCostAllocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalCostAllocationQuery,
    GlobalCostAllocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalCostAllocationQuery, GlobalCostAllocationQueryVariables>(
    GlobalCostAllocationDocument,
    options
  );
}
export type GlobalCostAllocationQueryHookResult = ReturnType<typeof useGlobalCostAllocationQuery>;
export type GlobalCostAllocationLazyQueryHookResult = ReturnType<
  typeof useGlobalCostAllocationLazyQuery
>;
export type GlobalCostAllocationQueryResult = Apollo.QueryResult<
  GlobalCostAllocationQuery,
  GlobalCostAllocationQueryVariables
>;
export const GetApplicationAccumulatedCostsDocument = gql`
  query getApplicationAccumulatedCosts(
    $applicationId: ID!
    $endDate: String!
    $granularity: Granularity
    $startDate: String!
    $tableGranularity: Granularity
  ) {
    applicationAccumulatedCost(
      input: {
        applicationId: $applicationId
        endDate: $endDate
        granularity: $granularity
        startDate: $startDate
      }
    ) {
      ...AccumulatedCost
    }
    applicationAccumulatedTableCost: applicationAccumulatedCost(
      input: {
        applicationId: $applicationId
        endDate: $endDate
        granularity: $tableGranularity
        startDate: $startDate
      }
    ) {
      ...AccumulatedCost
    }
  }
  ${AccumulatedCostFragmentDoc}
`;

/**
 * __useGetApplicationAccumulatedCostsQuery__
 *
 * To run a query within a React component, call `useGetApplicationAccumulatedCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationAccumulatedCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationAccumulatedCostsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useGetApplicationAccumulatedCostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationAccumulatedCostsQuery,
    GetApplicationAccumulatedCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationAccumulatedCostsQuery,
    GetApplicationAccumulatedCostsQueryVariables
  >(GetApplicationAccumulatedCostsDocument, options);
}
export function useGetApplicationAccumulatedCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationAccumulatedCostsQuery,
    GetApplicationAccumulatedCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationAccumulatedCostsQuery,
    GetApplicationAccumulatedCostsQueryVariables
  >(GetApplicationAccumulatedCostsDocument, options);
}
export type GetApplicationAccumulatedCostsQueryHookResult = ReturnType<
  typeof useGetApplicationAccumulatedCostsQuery
>;
export type GetApplicationAccumulatedCostsLazyQueryHookResult = ReturnType<
  typeof useGetApplicationAccumulatedCostsLazyQuery
>;
export type GetApplicationAccumulatedCostsQueryResult = Apollo.QueryResult<
  GetApplicationAccumulatedCostsQuery,
  GetApplicationAccumulatedCostsQueryVariables
>;
export const GetApplicationTopEnvironmentsCostsDocument = gql`
  query getApplicationTopEnvironmentsCosts(
    $top: Int!
    $applicationId: ID!
    $endDate: String!
    $granularity: Granularity
    $startDate: String!
    $tableGranularity: Granularity
  ) {
    applicationTopEnvironmentsCost(
      input: {
        top: $top
        applicationId: $applicationId
        endDate: $endDate
        granularity: $granularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
    applicationTopEnvironmentsTableCost: applicationTopEnvironmentsCost(
      input: {
        top: $top
        applicationId: $applicationId
        endDate: $endDate
        granularity: $tableGranularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
  }
  ${TimePointFragmentDoc}
`;

/**
 * __useGetApplicationTopEnvironmentsCostsQuery__
 *
 * To run a query within a React component, call `useGetApplicationTopEnvironmentsCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationTopEnvironmentsCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationTopEnvironmentsCostsQuery({
 *   variables: {
 *      top: // value for 'top'
 *      applicationId: // value for 'applicationId'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useGetApplicationTopEnvironmentsCostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationTopEnvironmentsCostsQuery,
    GetApplicationTopEnvironmentsCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationTopEnvironmentsCostsQuery,
    GetApplicationTopEnvironmentsCostsQueryVariables
  >(GetApplicationTopEnvironmentsCostsDocument, options);
}
export function useGetApplicationTopEnvironmentsCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationTopEnvironmentsCostsQuery,
    GetApplicationTopEnvironmentsCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationTopEnvironmentsCostsQuery,
    GetApplicationTopEnvironmentsCostsQueryVariables
  >(GetApplicationTopEnvironmentsCostsDocument, options);
}
export type GetApplicationTopEnvironmentsCostsQueryHookResult = ReturnType<
  typeof useGetApplicationTopEnvironmentsCostsQuery
>;
export type GetApplicationTopEnvironmentsCostsLazyQueryHookResult = ReturnType<
  typeof useGetApplicationTopEnvironmentsCostsLazyQuery
>;
export type GetApplicationTopEnvironmentsCostsQueryResult = Apollo.QueryResult<
  GetApplicationTopEnvironmentsCostsQuery,
  GetApplicationTopEnvironmentsCostsQueryVariables
>;
export const GetApplicationTopCategoriesCostsDocument = gql`
  query getApplicationTopCategoriesCosts(
    $top: Int!
    $applicationId: ID!
    $endDate: String!
    $granularity: Granularity
    $startDate: String!
    $tableGranularity: Granularity
  ) {
    applicationTopCategoriesCost(
      input: {
        top: $top
        applicationId: $applicationId
        endDate: $endDate
        granularity: $granularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
    applicationTopCategoriesTableCost: applicationTopCategoriesCost(
      input: {
        top: $top
        applicationId: $applicationId
        endDate: $endDate
        granularity: $tableGranularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
  }
  ${TimePointFragmentDoc}
`;

/**
 * __useGetApplicationTopCategoriesCostsQuery__
 *
 * To run a query within a React component, call `useGetApplicationTopCategoriesCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationTopCategoriesCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationTopCategoriesCostsQuery({
 *   variables: {
 *      top: // value for 'top'
 *      applicationId: // value for 'applicationId'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useGetApplicationTopCategoriesCostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationTopCategoriesCostsQuery,
    GetApplicationTopCategoriesCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationTopCategoriesCostsQuery,
    GetApplicationTopCategoriesCostsQueryVariables
  >(GetApplicationTopCategoriesCostsDocument, options);
}
export function useGetApplicationTopCategoriesCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationTopCategoriesCostsQuery,
    GetApplicationTopCategoriesCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationTopCategoriesCostsQuery,
    GetApplicationTopCategoriesCostsQueryVariables
  >(GetApplicationTopCategoriesCostsDocument, options);
}
export type GetApplicationTopCategoriesCostsQueryHookResult = ReturnType<
  typeof useGetApplicationTopCategoriesCostsQuery
>;
export type GetApplicationTopCategoriesCostsLazyQueryHookResult = ReturnType<
  typeof useGetApplicationTopCategoriesCostsLazyQuery
>;
export type GetApplicationTopCategoriesCostsQueryResult = Apollo.QueryResult<
  GetApplicationTopCategoriesCostsQuery,
  GetApplicationTopCategoriesCostsQueryVariables
>;
export const GetApplicationOtherCategoriesCostDocument = gql`
  query getApplicationOtherCategoriesCost($input: ApplicationOtherCategoriesCostInput!) {
    applicationOtherCategoriesCost(input: $input) {
      total
      timePoints {
        date
        value
        groups {
          id
          name
          value
        }
      }
    }
  }
`;

/**
 * __useGetApplicationOtherCategoriesCostQuery__
 *
 * To run a query within a React component, call `useGetApplicationOtherCategoriesCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationOtherCategoriesCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationOtherCategoriesCostQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetApplicationOtherCategoriesCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApplicationOtherCategoriesCostQuery,
    GetApplicationOtherCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationOtherCategoriesCostQuery,
    GetApplicationOtherCategoriesCostQueryVariables
  >(GetApplicationOtherCategoriesCostDocument, options);
}
export function useGetApplicationOtherCategoriesCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationOtherCategoriesCostQuery,
    GetApplicationOtherCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationOtherCategoriesCostQuery,
    GetApplicationOtherCategoriesCostQueryVariables
  >(GetApplicationOtherCategoriesCostDocument, options);
}
export type GetApplicationOtherCategoriesCostQueryHookResult = ReturnType<
  typeof useGetApplicationOtherCategoriesCostQuery
>;
export type GetApplicationOtherCategoriesCostLazyQueryHookResult = ReturnType<
  typeof useGetApplicationOtherCategoriesCostLazyQuery
>;
export type GetApplicationOtherCategoriesCostQueryResult = Apollo.QueryResult<
  GetApplicationOtherCategoriesCostQuery,
  GetApplicationOtherCategoriesCostQueryVariables
>;
export const ApplicationBudgetDocument = gql`
  query ApplicationBudget($id: String!, $year: String!, $previousYear: String!) {
    application(id: $id) {
      id
      nid
      environments {
        id
        nid
        budgetYearly(year: $year) {
          ...Budget
        }
      }
      budgetYearly(year: $year) {
        ...Budget
      }
      spendYearly(year: $previousYear) {
        spends
      }
      forecastSpendYearly(year: $previousYear) {
        spends
      }
    }
  }
  ${BudgetFragmentDoc}
`;

/**
 * __useApplicationBudgetQuery__
 *
 * To run a query within a React component, call `useApplicationBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationBudgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *      previousYear: // value for 'previousYear'
 *   },
 * });
 */
export function useApplicationBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<ApplicationBudgetQuery, ApplicationBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationBudgetQuery, ApplicationBudgetQueryVariables>(
    ApplicationBudgetDocument,
    options
  );
}
export function useApplicationBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationBudgetQuery, ApplicationBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationBudgetQuery, ApplicationBudgetQueryVariables>(
    ApplicationBudgetDocument,
    options
  );
}
export type ApplicationBudgetQueryHookResult = ReturnType<typeof useApplicationBudgetQuery>;
export type ApplicationBudgetLazyQueryHookResult = ReturnType<typeof useApplicationBudgetLazyQuery>;
export type ApplicationBudgetQueryResult = Apollo.QueryResult<
  ApplicationBudgetQuery,
  ApplicationBudgetQueryVariables
>;
export const SetApplicationYearlyBudgetDocument = gql`
  mutation setApplicationYearlyBudget($budget: BudgetYearlyInput!, $nid: ID!) {
    setApplicationYearlyBudget(budget: $budget, nid: $nid) {
      ...Budget
    }
  }
  ${BudgetFragmentDoc}
`;
export type SetApplicationYearlyBudgetMutationFn = Apollo.MutationFunction<
  SetApplicationYearlyBudgetMutation,
  SetApplicationYearlyBudgetMutationVariables
>;

/**
 * __useSetApplicationYearlyBudgetMutation__
 *
 * To run a mutation, you first call `useSetApplicationYearlyBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApplicationYearlyBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApplicationYearlyBudgetMutation, { data, loading, error }] = useSetApplicationYearlyBudgetMutation({
 *   variables: {
 *      budget: // value for 'budget'
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useSetApplicationYearlyBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetApplicationYearlyBudgetMutation,
    SetApplicationYearlyBudgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetApplicationYearlyBudgetMutation,
    SetApplicationYearlyBudgetMutationVariables
  >(SetApplicationYearlyBudgetDocument, options);
}
export type SetApplicationYearlyBudgetMutationHookResult = ReturnType<
  typeof useSetApplicationYearlyBudgetMutation
>;
export type SetApplicationYearlyBudgetMutationResult =
  Apollo.MutationResult<SetApplicationYearlyBudgetMutation>;
export type SetApplicationYearlyBudgetMutationOptions = Apollo.BaseMutationOptions<
  SetApplicationYearlyBudgetMutation,
  SetApplicationYearlyBudgetMutationVariables
>;
export const ApplicationsPaginatedV2Document = gql`
  query ApplicationsPaginatedV2($limit: Int!, $page: Int!, $year: String!) {
    applicationsPaginated(limit: $limit, page: $page) {
      pages
      count
      results {
        name
        id
        resourceCount
        description
        owner {
          id
          name
          email
        }
        contacts {
          id
          name
          email
        }
        budgetYearly(year: $year) {
          id
          budgetByMonth
        }
        cloudWasteAggregate {
          cost
        }
        cost {
          currentMonth
          forecast
        }
        environmentsV2(page: 1, limit: 1) {
          count
        }
        finOpsOptimisation {
          optimisationLevel
          contactPersonAdded
          ownerAdded
          budgetAdded
          environmentsAttachedToOUs
          environmentsTypesDefined
          savingSuggestions {
            savingSuggestionsUtilised
            savingSuggestionsValue
          }
        }
      }
    }
  }
`;

/**
 * __useApplicationsPaginatedV2Query__
 *
 * To run a query within a React component, call `useApplicationsPaginatedV2Query` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsPaginatedV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsPaginatedV2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useApplicationsPaginatedV2Query(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsPaginatedV2Query,
    ApplicationsPaginatedV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsPaginatedV2Query, ApplicationsPaginatedV2QueryVariables>(
    ApplicationsPaginatedV2Document,
    options
  );
}
export function useApplicationsPaginatedV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsPaginatedV2Query,
    ApplicationsPaginatedV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationsPaginatedV2Query, ApplicationsPaginatedV2QueryVariables>(
    ApplicationsPaginatedV2Document,
    options
  );
}
export type ApplicationsPaginatedV2QueryHookResult = ReturnType<
  typeof useApplicationsPaginatedV2Query
>;
export type ApplicationsPaginatedV2LazyQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedV2LazyQuery
>;
export type ApplicationsPaginatedV2QueryResult = Apollo.QueryResult<
  ApplicationsPaginatedV2Query,
  ApplicationsPaginatedV2QueryVariables
>;
export const UnallocatedSummaryDocument = gql`
  query UnallocatedSummary {
    defaultApplication {
      id: nid
      name
      cloudWasteAggregate {
        hasWaste
        cost
      }
      cost {
        currentMonth
        forecast
      }
    }
    defaultEnvironment {
      id: nid
      nid
      resourceCount
    }
  }
`;

/**
 * __useUnallocatedSummaryQuery__
 *
 * To run a query within a React component, call `useUnallocatedSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnallocatedSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnallocatedSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnallocatedSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<UnallocatedSummaryQuery, UnallocatedSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnallocatedSummaryQuery, UnallocatedSummaryQueryVariables>(
    UnallocatedSummaryDocument,
    options
  );
}
export function useUnallocatedSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnallocatedSummaryQuery,
    UnallocatedSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnallocatedSummaryQuery, UnallocatedSummaryQueryVariables>(
    UnallocatedSummaryDocument,
    options
  );
}
export type UnallocatedSummaryQueryHookResult = ReturnType<typeof useUnallocatedSummaryQuery>;
export type UnallocatedSummaryLazyQueryHookResult = ReturnType<
  typeof useUnallocatedSummaryLazyQuery
>;
export type UnallocatedSummaryQueryResult = Apollo.QueryResult<
  UnallocatedSummaryQuery,
  UnallocatedSummaryQueryVariables
>;
export const ApplicationEnvironmentsPaginatedV2Document = gql`
  query ApplicationEnvironmentsPaginatedV2(
    $id: String!
    $limit: Int!
    $page: Int!
    $year: String!
  ) {
    application(id: $id) {
      id
      environmentsV2(page: $page, limit: $limit) {
        count
        pages
        environments {
          id
          nid
          name
          displayName
          cloudWasteAggregate {
            hasWaste
            cost
          }
          cost {
            currentMonth
            forecast
          }
          budgetYearly(year: $year) {
            id
            budgetByMonth
          }
          orgUnitsV2(page: 0, limit: 10) {
            pages
            count
            orgUnits {
              name
              id
              parentNid
              businessContext {
                id
                color
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useApplicationEnvironmentsPaginatedV2Query__
 *
 * To run a query within a React component, call `useApplicationEnvironmentsPaginatedV2Query` and pass it any options that fit your needs.
 * When your component renders, `useApplicationEnvironmentsPaginatedV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationEnvironmentsPaginatedV2Query({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useApplicationEnvironmentsPaginatedV2Query(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationEnvironmentsPaginatedV2Query,
    ApplicationEnvironmentsPaginatedV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationEnvironmentsPaginatedV2Query,
    ApplicationEnvironmentsPaginatedV2QueryVariables
  >(ApplicationEnvironmentsPaginatedV2Document, options);
}
export function useApplicationEnvironmentsPaginatedV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationEnvironmentsPaginatedV2Query,
    ApplicationEnvironmentsPaginatedV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationEnvironmentsPaginatedV2Query,
    ApplicationEnvironmentsPaginatedV2QueryVariables
  >(ApplicationEnvironmentsPaginatedV2Document, options);
}
export type ApplicationEnvironmentsPaginatedV2QueryHookResult = ReturnType<
  typeof useApplicationEnvironmentsPaginatedV2Query
>;
export type ApplicationEnvironmentsPaginatedV2LazyQueryHookResult = ReturnType<
  typeof useApplicationEnvironmentsPaginatedV2LazyQuery
>;
export type ApplicationEnvironmentsPaginatedV2QueryResult = Apollo.QueryResult<
  ApplicationEnvironmentsPaginatedV2Query,
  ApplicationEnvironmentsPaginatedV2QueryVariables
>;
export const GetMonthlyReportDocument = gql`
  query GetMonthlyReport($period: String!, $reportType: ReportType!, $businessContextId: String) {
    monthlyReport(
      input: { period: $period, reportType: $reportType, businessContextId: $businessContextId }
    ) {
      status
      fileUrl
      error
      jobId
      reportType
    }
  }
`;

/**
 * __useGetMonthlyReportQuery__
 *
 * To run a query within a React component, call `useGetMonthlyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyReportQuery({
 *   variables: {
 *      period: // value for 'period'
 *      reportType: // value for 'reportType'
 *      businessContextId: // value for 'businessContextId'
 *   },
 * });
 */
export function useGetMonthlyReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>(
    GetMonthlyReportDocument,
    options
  );
}
export function useGetMonthlyReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMonthlyReportQuery, GetMonthlyReportQueryVariables>(
    GetMonthlyReportDocument,
    options
  );
}
export type GetMonthlyReportQueryHookResult = ReturnType<typeof useGetMonthlyReportQuery>;
export type GetMonthlyReportLazyQueryHookResult = ReturnType<typeof useGetMonthlyReportLazyQuery>;
export type GetMonthlyReportQueryResult = Apollo.QueryResult<
  GetMonthlyReportQuery,
  GetMonthlyReportQueryVariables
>;
export const CustomerCreatedAtDocument = gql`
  query customerCreatedAt {
    organization {
      id
      createdAt
    }
  }
`;

/**
 * __useCustomerCreatedAtQuery__
 *
 * To run a query within a React component, call `useCustomerCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCreatedAtQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerCreatedAtQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>(
    CustomerCreatedAtDocument,
    options
  );
}
export function useCustomerCreatedAtLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerCreatedAtQuery, CustomerCreatedAtQueryVariables>(
    CustomerCreatedAtDocument,
    options
  );
}
export type CustomerCreatedAtQueryHookResult = ReturnType<typeof useCustomerCreatedAtQuery>;
export type CustomerCreatedAtLazyQueryHookResult = ReturnType<typeof useCustomerCreatedAtLazyQuery>;
export type CustomerCreatedAtQueryResult = Apollo.QueryResult<
  CustomerCreatedAtQuery,
  CustomerCreatedAtQueryVariables
>;
export const BusinessContextsMinimalDocument = gql`
  query BusinessContextsMinimal($limit: Int!, $page: Int!) {
    businessContexts(limit: $limit, page: $page) {
      businessContexts {
        id
        name
      }
      pages
      count
    }
  }
`;

/**
 * __useBusinessContextsMinimalQuery__
 *
 * To run a query within a React component, call `useBusinessContextsMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextsMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextsMinimalQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBusinessContextsMinimalQuery(
  baseOptions: Apollo.QueryHookOptions<
    BusinessContextsMinimalQuery,
    BusinessContextsMinimalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessContextsMinimalQuery, BusinessContextsMinimalQueryVariables>(
    BusinessContextsMinimalDocument,
    options
  );
}
export function useBusinessContextsMinimalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessContextsMinimalQuery,
    BusinessContextsMinimalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessContextsMinimalQuery, BusinessContextsMinimalQueryVariables>(
    BusinessContextsMinimalDocument,
    options
  );
}
export type BusinessContextsMinimalQueryHookResult = ReturnType<
  typeof useBusinessContextsMinimalQuery
>;
export type BusinessContextsMinimalLazyQueryHookResult = ReturnType<
  typeof useBusinessContextsMinimalLazyQuery
>;
export type BusinessContextsMinimalQueryResult = Apollo.QueryResult<
  BusinessContextsMinimalQuery,
  BusinessContextsMinimalQueryVariables
>;
export const OrgUnitTypesV2Document = gql`
  query orgUnitTypesV2($limit: Int!, $page: Int!) {
    orgUnitTypesV2(limit: $limit, page: $page) {
      types {
        id
        name
        description
      }
      pages
      count
    }
  }
`;

/**
 * __useOrgUnitTypesV2Query__
 *
 * To run a query within a React component, call `useOrgUnitTypesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitTypesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitTypesV2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrgUnitTypesV2Query(
  baseOptions: Apollo.QueryHookOptions<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>(
    OrgUnitTypesV2Document,
    options
  );
}
export function useOrgUnitTypesV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitTypesV2Query, OrgUnitTypesV2QueryVariables>(
    OrgUnitTypesV2Document,
    options
  );
}
export type OrgUnitTypesV2QueryHookResult = ReturnType<typeof useOrgUnitTypesV2Query>;
export type OrgUnitTypesV2LazyQueryHookResult = ReturnType<typeof useOrgUnitTypesV2LazyQuery>;
export type OrgUnitTypesV2QueryResult = Apollo.QueryResult<
  OrgUnitTypesV2Query,
  OrgUnitTypesV2QueryVariables
>;
export const AddOrgUnitTypeDocument = gql`
  mutation addOrgUnitType($input: AddOrgUnitTypeV2!) {
    addOrgUnitTypeV3(input: $input) {
      id
    }
  }
`;
export type AddOrgUnitTypeMutationFn = Apollo.MutationFunction<
  AddOrgUnitTypeMutation,
  AddOrgUnitTypeMutationVariables
>;

/**
 * __useAddOrgUnitTypeMutation__
 *
 * To run a mutation, you first call `useAddOrgUnitTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrgUnitTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrgUnitTypeMutation, { data, loading, error }] = useAddOrgUnitTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrgUnitTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOrgUnitTypeMutation, AddOrgUnitTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrgUnitTypeMutation, AddOrgUnitTypeMutationVariables>(
    AddOrgUnitTypeDocument,
    options
  );
}
export type AddOrgUnitTypeMutationHookResult = ReturnType<typeof useAddOrgUnitTypeMutation>;
export type AddOrgUnitTypeMutationResult = Apollo.MutationResult<AddOrgUnitTypeMutation>;
export type AddOrgUnitTypeMutationOptions = Apollo.BaseMutationOptions<
  AddOrgUnitTypeMutation,
  AddOrgUnitTypeMutationVariables
>;
export const RemoveOrgUnitTypeDocument = gql`
  mutation removeOrgUnitType($id: ID!) {
    removeOrgUnitTypeV2(id: $id)
  }
`;
export type RemoveOrgUnitTypeMutationFn = Apollo.MutationFunction<
  RemoveOrgUnitTypeMutation,
  RemoveOrgUnitTypeMutationVariables
>;

/**
 * __useRemoveOrgUnitTypeMutation__
 *
 * To run a mutation, you first call `useRemoveOrgUnitTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrgUnitTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrgUnitTypeMutation, { data, loading, error }] = useRemoveOrgUnitTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrgUnitTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrgUnitTypeMutation,
    RemoveOrgUnitTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveOrgUnitTypeMutation, RemoveOrgUnitTypeMutationVariables>(
    RemoveOrgUnitTypeDocument,
    options
  );
}
export type RemoveOrgUnitTypeMutationHookResult = ReturnType<typeof useRemoveOrgUnitTypeMutation>;
export type RemoveOrgUnitTypeMutationResult = Apollo.MutationResult<RemoveOrgUnitTypeMutation>;
export type RemoveOrgUnitTypeMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrgUnitTypeMutation,
  RemoveOrgUnitTypeMutationVariables
>;
export const UpdateOrgUnitTypeDocument = gql`
  mutation updateOrgUnitType($id: ID!, $input: UpdateOrgUnitTypeV2!) {
    updateOrgUnitTypeV3(id: $id, input: $input) {
      id
      name
      description
    }
  }
`;
export type UpdateOrgUnitTypeMutationFn = Apollo.MutationFunction<
  UpdateOrgUnitTypeMutation,
  UpdateOrgUnitTypeMutationVariables
>;

/**
 * __useUpdateOrgUnitTypeMutation__
 *
 * To run a mutation, you first call `useUpdateOrgUnitTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgUnitTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgUnitTypeMutation, { data, loading, error }] = useUpdateOrgUnitTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgUnitTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgUnitTypeMutation,
    UpdateOrgUnitTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgUnitTypeMutation, UpdateOrgUnitTypeMutationVariables>(
    UpdateOrgUnitTypeDocument,
    options
  );
}
export type UpdateOrgUnitTypeMutationHookResult = ReturnType<typeof useUpdateOrgUnitTypeMutation>;
export type UpdateOrgUnitTypeMutationResult = Apollo.MutationResult<UpdateOrgUnitTypeMutation>;
export type UpdateOrgUnitTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgUnitTypeMutation,
  UpdateOrgUnitTypeMutationVariables
>;
export const AddBusinessContextDocument = gql`
  mutation AddBusinessContext($input: AddBusinessContext!) {
    addBusinessContextV2(input: $input) {
      id
      name
      description
      color
      isPrimary
    }
  }
`;
export type AddBusinessContextMutationFn = Apollo.MutationFunction<
  AddBusinessContextMutation,
  AddBusinessContextMutationVariables
>;

/**
 * __useAddBusinessContextMutation__
 *
 * To run a mutation, you first call `useAddBusinessContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBusinessContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBusinessContextMutation, { data, loading, error }] = useAddBusinessContextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBusinessContextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBusinessContextMutation,
    AddBusinessContextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBusinessContextMutation, AddBusinessContextMutationVariables>(
    AddBusinessContextDocument,
    options
  );
}
export type AddBusinessContextMutationHookResult = ReturnType<typeof useAddBusinessContextMutation>;
export type AddBusinessContextMutationResult = Apollo.MutationResult<AddBusinessContextMutation>;
export type AddBusinessContextMutationOptions = Apollo.BaseMutationOptions<
  AddBusinessContextMutation,
  AddBusinessContextMutationVariables
>;
export const BusinessContextsDocument = gql`
  query BusinessContexts($limit: Int!, $page: Int!) {
    businessContexts(limit: $limit, page: $page) {
      businessContexts {
        id
        name
        color
        isPrimary
      }
      pages
      count
    }
  }
`;

/**
 * __useBusinessContextsQuery__
 *
 * To run a query within a React component, call `useBusinessContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useBusinessContextsQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessContextsQuery, BusinessContextsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessContextsQuery, BusinessContextsQueryVariables>(
    BusinessContextsDocument,
    options
  );
}
export function useBusinessContextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessContextsQuery, BusinessContextsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessContextsQuery, BusinessContextsQueryVariables>(
    BusinessContextsDocument,
    options
  );
}
export type BusinessContextsQueryHookResult = ReturnType<typeof useBusinessContextsQuery>;
export type BusinessContextsLazyQueryHookResult = ReturnType<typeof useBusinessContextsLazyQuery>;
export type BusinessContextsQueryResult = Apollo.QueryResult<
  BusinessContextsQuery,
  BusinessContextsQueryVariables
>;
export const BusinessContextDocument = gql`
  query BusinessContext($id: ID!, $year: String!) {
    businessContext(id: $id) {
      id
      name
      description
      color
      isPrimary
      orgUnits {
        orgUnits {
          id
          nid
          name
          description
          parentNid
          environments(limit: 1, page: 0) {
            count
          }
          businessContext {
            id
          }
          budgetYearly(year: $year) {
            id
            budgetByMonth
          }
          cost {
            currentMonth
            previousMonth
            forecast
          }
          type {
            id
            name
          }
          contact {
            id
            name
          }
        }
        count
      }
    }
  }
`;

/**
 * __useBusinessContextQuery__
 *
 * To run a query within a React component, call `useBusinessContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useBusinessContextQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessContextQuery, BusinessContextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessContextQuery, BusinessContextQueryVariables>(
    BusinessContextDocument,
    options
  );
}
export function useBusinessContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BusinessContextQuery, BusinessContextQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessContextQuery, BusinessContextQueryVariables>(
    BusinessContextDocument,
    options
  );
}
export type BusinessContextQueryHookResult = ReturnType<typeof useBusinessContextQuery>;
export type BusinessContextLazyQueryHookResult = ReturnType<typeof useBusinessContextLazyQuery>;
export type BusinessContextQueryResult = Apollo.QueryResult<
  BusinessContextQuery,
  BusinessContextQueryVariables
>;
export const GetOrgUnitTypesV2Document = gql`
  query GetOrgUnitTypesV2 {
    orgUnitTypesV2(limit: 100, page: 0) {
      types {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOrgUnitTypesV2Query__
 *
 * To run a query within a React component, call `useGetOrgUnitTypesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnitTypesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnitTypesV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetOrgUnitTypesV2Query(
  baseOptions?: Apollo.QueryHookOptions<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>(
    GetOrgUnitTypesV2Document,
    options
  );
}
export function useGetOrgUnitTypesV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrgUnitTypesV2Query, GetOrgUnitTypesV2QueryVariables>(
    GetOrgUnitTypesV2Document,
    options
  );
}
export type GetOrgUnitTypesV2QueryHookResult = ReturnType<typeof useGetOrgUnitTypesV2Query>;
export type GetOrgUnitTypesV2LazyQueryHookResult = ReturnType<typeof useGetOrgUnitTypesV2LazyQuery>;
export type GetOrgUnitTypesV2QueryResult = Apollo.QueryResult<
  GetOrgUnitTypesV2Query,
  GetOrgUnitTypesV2QueryVariables
>;
export const SetPrimaryBusinessContextDocument = gql`
  mutation setPrimaryBusinessContext($id: String!) {
    setPrimaryBusinessContext(id: $id)
  }
`;
export type SetPrimaryBusinessContextMutationFn = Apollo.MutationFunction<
  SetPrimaryBusinessContextMutation,
  SetPrimaryBusinessContextMutationVariables
>;

/**
 * __useSetPrimaryBusinessContextMutation__
 *
 * To run a mutation, you first call `useSetPrimaryBusinessContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryBusinessContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryBusinessContextMutation, { data, loading, error }] = useSetPrimaryBusinessContextMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetPrimaryBusinessContextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPrimaryBusinessContextMutation,
    SetPrimaryBusinessContextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPrimaryBusinessContextMutation,
    SetPrimaryBusinessContextMutationVariables
  >(SetPrimaryBusinessContextDocument, options);
}
export type SetPrimaryBusinessContextMutationHookResult = ReturnType<
  typeof useSetPrimaryBusinessContextMutation
>;
export type SetPrimaryBusinessContextMutationResult =
  Apollo.MutationResult<SetPrimaryBusinessContextMutation>;
export type SetPrimaryBusinessContextMutationOptions = Apollo.BaseMutationOptions<
  SetPrimaryBusinessContextMutation,
  SetPrimaryBusinessContextMutationVariables
>;
export const AddEnvironmentsToOrgUnitDocument = gql`
  mutation AddEnvironmentsToOrgUnit($input: UpdateOrgUnitEnvironmentsBodyInput!) {
    addEnvironmentsToOrgUnit(input: $input)
  }
`;
export type AddEnvironmentsToOrgUnitMutationFn = Apollo.MutationFunction<
  AddEnvironmentsToOrgUnitMutation,
  AddEnvironmentsToOrgUnitMutationVariables
>;

/**
 * __useAddEnvironmentsToOrgUnitMutation__
 *
 * To run a mutation, you first call `useAddEnvironmentsToOrgUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEnvironmentsToOrgUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEnvironmentsToOrgUnitMutation, { data, loading, error }] = useAddEnvironmentsToOrgUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEnvironmentsToOrgUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEnvironmentsToOrgUnitMutation,
    AddEnvironmentsToOrgUnitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEnvironmentsToOrgUnitMutation,
    AddEnvironmentsToOrgUnitMutationVariables
  >(AddEnvironmentsToOrgUnitDocument, options);
}
export type AddEnvironmentsToOrgUnitMutationHookResult = ReturnType<
  typeof useAddEnvironmentsToOrgUnitMutation
>;
export type AddEnvironmentsToOrgUnitMutationResult =
  Apollo.MutationResult<AddEnvironmentsToOrgUnitMutation>;
export type AddEnvironmentsToOrgUnitMutationOptions = Apollo.BaseMutationOptions<
  AddEnvironmentsToOrgUnitMutation,
  AddEnvironmentsToOrgUnitMutationVariables
>;
export const GetOrgUnitWithChildrenDocument = gql`
  query GetOrgUnitWithChildren($id: ID!, $year: String!) {
    orgUnitWithChildren(nid: $id) {
      id
      nid
      name
      description
      businessContext {
        id
        name
      }
      type {
        id
        name
      }
      contact {
        id
        name
        email
      }
      co2e {
        currentMonth
        previousMonth
        forecast
      }
      cost {
        currentMonth
        previousMonth
        forecast
      }
      cloudWasteAggregate {
        co2e
        cost
        count
        hasWaste
      }
      budgetYearly(year: $year) {
        ...Budget
      }
      children {
        id
        nid
        name
        parentNid
        businessContext {
          id
        }
        cost {
          currentMonth
          previousMonth
          forecast
        }
        budgetYearly(year: $year) {
          id
          budgetByMonth
        }
      }
    }
  }
  ${BudgetFragmentDoc}
`;

/**
 * __useGetOrgUnitWithChildrenQuery__
 *
 * To run a query within a React component, call `useGetOrgUnitWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnitWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnitWithChildrenQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetOrgUnitWithChildrenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrgUnitWithChildrenQuery,
    GetOrgUnitWithChildrenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgUnitWithChildrenQuery, GetOrgUnitWithChildrenQueryVariables>(
    GetOrgUnitWithChildrenDocument,
    options
  );
}
export function useGetOrgUnitWithChildrenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrgUnitWithChildrenQuery,
    GetOrgUnitWithChildrenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrgUnitWithChildrenQuery, GetOrgUnitWithChildrenQueryVariables>(
    GetOrgUnitWithChildrenDocument,
    options
  );
}
export type GetOrgUnitWithChildrenQueryHookResult = ReturnType<
  typeof useGetOrgUnitWithChildrenQuery
>;
export type GetOrgUnitWithChildrenLazyQueryHookResult = ReturnType<
  typeof useGetOrgUnitWithChildrenLazyQuery
>;
export type GetOrgUnitWithChildrenQueryResult = Apollo.QueryResult<
  GetOrgUnitWithChildrenQuery,
  GetOrgUnitWithChildrenQueryVariables
>;
export const OrgUnitApplicationsDocument = gql`
  query OrgUnitApplications($orgUnitId: ID!, $year: String!, $limit: Int!, $page: Int!) {
    orgUnitApplications(orgUnitId: $orgUnitId, limit: $limit, page: $page) {
      results {
        id
        name
        displayName
        environments {
          id
          name
          displayName
          resourceCount
          budgetYearly(year: $year) {
            id
            budgetByMonth
          }
          cost {
            currentMonth
            previousMonth
            forecast
          }
          cloudWasteAggregate {
            co2e
            cost
            count
            hasWaste
          }
        }
        cost {
          currentMonth
          previousMonth
          forecast
        }
        budgetYearly(year: $year) {
          id
          budgetByMonth
        }
      }
      count
      pages
    }
  }
`;

/**
 * __useOrgUnitApplicationsQuery__
 *
 * To run a query within a React component, call `useOrgUnitApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitApplicationsQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *      year: // value for 'year'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useOrgUnitApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<OrgUnitApplicationsQuery, OrgUnitApplicationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitApplicationsQuery, OrgUnitApplicationsQueryVariables>(
    OrgUnitApplicationsDocument,
    options
  );
}
export function useOrgUnitApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitApplicationsQuery,
    OrgUnitApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitApplicationsQuery, OrgUnitApplicationsQueryVariables>(
    OrgUnitApplicationsDocument,
    options
  );
}
export type OrgUnitApplicationsQueryHookResult = ReturnType<typeof useOrgUnitApplicationsQuery>;
export type OrgUnitApplicationsLazyQueryHookResult = ReturnType<
  typeof useOrgUnitApplicationsLazyQuery
>;
export type OrgUnitApplicationsQueryResult = Apollo.QueryResult<
  OrgUnitApplicationsQuery,
  OrgUnitApplicationsQueryVariables
>;
export const GetAppsWithEnvironmentsDocument = gql`
  query getAppsWithEnvironments {
    applications {
      name
      id
      nid
      environments {
        id
        nid
        name
        resourceCount
        orgUnitsV2(limit: 100, page: 0) {
          orgUnits {
            id
            nid
            name
            businessContext {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetAppsWithEnvironmentsQuery__
 *
 * To run a query within a React component, call `useGetAppsWithEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsWithEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsWithEnvironmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppsWithEnvironmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppsWithEnvironmentsQuery,
    GetAppsWithEnvironmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppsWithEnvironmentsQuery, GetAppsWithEnvironmentsQueryVariables>(
    GetAppsWithEnvironmentsDocument,
    options
  );
}
export function useGetAppsWithEnvironmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppsWithEnvironmentsQuery,
    GetAppsWithEnvironmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppsWithEnvironmentsQuery, GetAppsWithEnvironmentsQueryVariables>(
    GetAppsWithEnvironmentsDocument,
    options
  );
}
export type GetAppsWithEnvironmentsQueryHookResult = ReturnType<
  typeof useGetAppsWithEnvironmentsQuery
>;
export type GetAppsWithEnvironmentsLazyQueryHookResult = ReturnType<
  typeof useGetAppsWithEnvironmentsLazyQuery
>;
export type GetAppsWithEnvironmentsQueryResult = Apollo.QueryResult<
  GetAppsWithEnvironmentsQuery,
  GetAppsWithEnvironmentsQueryVariables
>;
export const OrgUnitBaseDetailsDocument = gql`
  query OrgUnitBaseDetails($id: ID!) {
    orgUnitV2(nid: $id) {
      id
      nid
      name
      description
      type {
        id
        name
      }
      contact {
        id
        name
      }
    }
  }
`;

/**
 * __useOrgUnitBaseDetailsQuery__
 *
 * To run a query within a React component, call `useOrgUnitBaseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitBaseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitBaseDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgUnitBaseDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>(
    OrgUnitBaseDetailsDocument,
    options
  );
}
export function useOrgUnitBaseDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitBaseDetailsQuery,
    OrgUnitBaseDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitBaseDetailsQuery, OrgUnitBaseDetailsQueryVariables>(
    OrgUnitBaseDetailsDocument,
    options
  );
}
export type OrgUnitBaseDetailsQueryHookResult = ReturnType<typeof useOrgUnitBaseDetailsQuery>;
export type OrgUnitBaseDetailsLazyQueryHookResult = ReturnType<
  typeof useOrgUnitBaseDetailsLazyQuery
>;
export type OrgUnitBaseDetailsQueryResult = Apollo.QueryResult<
  OrgUnitBaseDetailsQuery,
  OrgUnitBaseDetailsQueryVariables
>;
export const GetOrgUnitTypeDocument = gql`
  query GetOrgUnitType($id: ID!) {
    orgUnitV2(nid: $id) {
      id
      type {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOrgUnitTypeQuery__
 *
 * To run a query within a React component, call `useGetOrgUnitTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgUnitTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgUnitTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrgUnitTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>(
    GetOrgUnitTypeDocument,
    options
  );
}
export function useGetOrgUnitTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrgUnitTypeQuery, GetOrgUnitTypeQueryVariables>(
    GetOrgUnitTypeDocument,
    options
  );
}
export type GetOrgUnitTypeQueryHookResult = ReturnType<typeof useGetOrgUnitTypeQuery>;
export type GetOrgUnitTypeLazyQueryHookResult = ReturnType<typeof useGetOrgUnitTypeLazyQuery>;
export type GetOrgUnitTypeQueryResult = Apollo.QueryResult<
  GetOrgUnitTypeQuery,
  GetOrgUnitTypeQueryVariables
>;
export const OrgUnitKpiDashboardDocument = gql`
  query OrgUnitKpiDashboard($id: ID!) {
    orgUnitV2(nid: $id) {
      id
      nid
      kpis {
        nonProdHours {
          ...OrgUnitKpiWidget
        }
        overallWaste {
          ...OrgUnitKpiWidget
        }
      }
    }
  }
  ${OrgUnitKpiWidgetFragmentDoc}
`;

/**
 * __useOrgUnitKpiDashboardQuery__
 *
 * To run a query within a React component, call `useOrgUnitKpiDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitKpiDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitKpiDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrgUnitKpiDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<OrgUnitKpiDashboardQuery, OrgUnitKpiDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitKpiDashboardQuery, OrgUnitKpiDashboardQueryVariables>(
    OrgUnitKpiDashboardDocument,
    options
  );
}
export function useOrgUnitKpiDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitKpiDashboardQuery,
    OrgUnitKpiDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitKpiDashboardQuery, OrgUnitKpiDashboardQueryVariables>(
    OrgUnitKpiDashboardDocument,
    options
  );
}
export type OrgUnitKpiDashboardQueryHookResult = ReturnType<typeof useOrgUnitKpiDashboardQuery>;
export type OrgUnitKpiDashboardLazyQueryHookResult = ReturnType<
  typeof useOrgUnitKpiDashboardLazyQuery
>;
export type OrgUnitKpiDashboardQueryResult = Apollo.QueryResult<
  OrgUnitKpiDashboardQuery,
  OrgUnitKpiDashboardQueryVariables
>;
export const OrgUnitKpiDataDocument = gql`
  query OrgUnitKpiData(
    $id: ID!
    $period: KpiTimePeriod!
    $fetchOverallWaste: Boolean!
    $fetchNonProdHours: Boolean!
  ) {
    orgUnitV2(nid: $id) {
      id
      nid
      kpis {
        nonProdHours @include(if: $fetchNonProdHours) {
          ...OrgUnitKpiTimePoints
          worst {
            orgUnits {
              orgUnit {
                id
                nid
                name
                businessContext {
                  id
                }
              }
              kpiValue
              hours
            }
          }
        }
        overallWaste @include(if: $fetchOverallWaste) {
          ...OrgUnitKpiTimePoints
          worst {
            orgUnits {
              orgUnit {
                id
                nid
                name
                businessContext {
                  id
                }
              }
              kpiValue
              currentWaste
            }
          }
        }
      }
    }
  }
  ${OrgUnitKpiTimePointsFragmentDoc}
`;

/**
 * __useOrgUnitKpiDataQuery__
 *
 * To run a query within a React component, call `useOrgUnitKpiDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitKpiDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitKpiDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      period: // value for 'period'
 *      fetchOverallWaste: // value for 'fetchOverallWaste'
 *      fetchNonProdHours: // value for 'fetchNonProdHours'
 *   },
 * });
 */
export function useOrgUnitKpiDataQuery(
  baseOptions: Apollo.QueryHookOptions<OrgUnitKpiDataQuery, OrgUnitKpiDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitKpiDataQuery, OrgUnitKpiDataQueryVariables>(
    OrgUnitKpiDataDocument,
    options
  );
}
export function useOrgUnitKpiDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrgUnitKpiDataQuery, OrgUnitKpiDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitKpiDataQuery, OrgUnitKpiDataQueryVariables>(
    OrgUnitKpiDataDocument,
    options
  );
}
export type OrgUnitKpiDataQueryHookResult = ReturnType<typeof useOrgUnitKpiDataQuery>;
export type OrgUnitKpiDataLazyQueryHookResult = ReturnType<typeof useOrgUnitKpiDataLazyQuery>;
export type OrgUnitKpiDataQueryResult = Apollo.QueryResult<
  OrgUnitKpiDataQuery,
  OrgUnitKpiDataQueryVariables
>;
export const OrgUnitBudgetDocument = gql`
  query orgUnitBudget($nid: ID!, $year: String!, $previousYear: String!) {
    orgUnitV2(nid: $nid) {
      id
      nid
      budgetYearly(year: $year) {
        ...Budget
      }
      spendYearly(year: $previousYear) {
        spends
      }
      forecastSpendYearly(year: $previousYear) {
        spends
      }
    }
  }
  ${BudgetFragmentDoc}
`;

/**
 * __useOrgUnitBudgetQuery__
 *
 * To run a query within a React component, call `useOrgUnitBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitBudgetQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *      year: // value for 'year'
 *      previousYear: // value for 'previousYear'
 *   },
 * });
 */
export function useOrgUnitBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<OrgUnitBudgetQuery, OrgUnitBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitBudgetQuery, OrgUnitBudgetQueryVariables>(
    OrgUnitBudgetDocument,
    options
  );
}
export function useOrgUnitBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrgUnitBudgetQuery, OrgUnitBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitBudgetQuery, OrgUnitBudgetQueryVariables>(
    OrgUnitBudgetDocument,
    options
  );
}
export type OrgUnitBudgetQueryHookResult = ReturnType<typeof useOrgUnitBudgetQuery>;
export type OrgUnitBudgetLazyQueryHookResult = ReturnType<typeof useOrgUnitBudgetLazyQuery>;
export type OrgUnitBudgetQueryResult = Apollo.QueryResult<
  OrgUnitBudgetQuery,
  OrgUnitBudgetQueryVariables
>;
export const OrgUnitChildrenBudgetDocument = gql`
  query OrgUnitChildrenBudget($nid: ID!, $year: String!) {
    orgUnitWithChildren(nid: $nid) {
      id
      nid
      children {
        id
        budgetYearly(year: $year) {
          ...Budget
        }
      }
    }
  }
  ${BudgetFragmentDoc}
`;

/**
 * __useOrgUnitChildrenBudgetQuery__
 *
 * To run a query within a React component, call `useOrgUnitChildrenBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitChildrenBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitChildrenBudgetQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useOrgUnitChildrenBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgUnitChildrenBudgetQuery,
    OrgUnitChildrenBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitChildrenBudgetQuery, OrgUnitChildrenBudgetQueryVariables>(
    OrgUnitChildrenBudgetDocument,
    options
  );
}
export function useOrgUnitChildrenBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitChildrenBudgetQuery,
    OrgUnitChildrenBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitChildrenBudgetQuery, OrgUnitChildrenBudgetQueryVariables>(
    OrgUnitChildrenBudgetDocument,
    options
  );
}
export type OrgUnitChildrenBudgetQueryHookResult = ReturnType<typeof useOrgUnitChildrenBudgetQuery>;
export type OrgUnitChildrenBudgetLazyQueryHookResult = ReturnType<
  typeof useOrgUnitChildrenBudgetLazyQuery
>;
export type OrgUnitChildrenBudgetQueryResult = Apollo.QueryResult<
  OrgUnitChildrenBudgetQuery,
  OrgUnitChildrenBudgetQueryVariables
>;
export const OrgUnitEnvironmentBudgetDocument = gql`
  query OrgUnitEnvironmentBudget($nid: ID!, $page: Int!, $year: String!) {
    orgUnitWithChildren(nid: $nid) {
      id
      nid
      environments(limit: 100, page: $page) {
        pages
        environments {
          id
          nid
          budgetYearly(year: $year) {
            ...Budget
          }
        }
      }
    }
  }
  ${BudgetFragmentDoc}
`;

/**
 * __useOrgUnitEnvironmentBudgetQuery__
 *
 * To run a query within a React component, call `useOrgUnitEnvironmentBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitEnvironmentBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitEnvironmentBudgetQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *      page: // value for 'page'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useOrgUnitEnvironmentBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgUnitEnvironmentBudgetQuery,
    OrgUnitEnvironmentBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitEnvironmentBudgetQuery, OrgUnitEnvironmentBudgetQueryVariables>(
    OrgUnitEnvironmentBudgetDocument,
    options
  );
}
export function useOrgUnitEnvironmentBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitEnvironmentBudgetQuery,
    OrgUnitEnvironmentBudgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitEnvironmentBudgetQuery, OrgUnitEnvironmentBudgetQueryVariables>(
    OrgUnitEnvironmentBudgetDocument,
    options
  );
}
export type OrgUnitEnvironmentBudgetQueryHookResult = ReturnType<
  typeof useOrgUnitEnvironmentBudgetQuery
>;
export type OrgUnitEnvironmentBudgetLazyQueryHookResult = ReturnType<
  typeof useOrgUnitEnvironmentBudgetLazyQuery
>;
export type OrgUnitEnvironmentBudgetQueryResult = Apollo.QueryResult<
  OrgUnitEnvironmentBudgetQuery,
  OrgUnitEnvironmentBudgetQueryVariables
>;
export const OrgUnitAccumulatedCostDocument = gql`
  query OrgUnitAccumulatedCost(
    $orgUnitId: ID!
    $granularity: Granularity
    $startDate: String!
    $endDate: String!
    $tableGranularity: Granularity
  ) {
    orgUnitAccumulatedCost(
      input: {
        orgUnitId: $orgUnitId
        granularity: $granularity
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...OrganizationUnitAccumulatedCost
    }
    orgUnitAccumulatedCostTable: orgUnitAccumulatedCost(
      input: {
        orgUnitId: $orgUnitId
        granularity: $tableGranularity
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...OrganizationUnitAccumulatedCost
    }
  }
  ${OrganizationUnitAccumulatedCostFragmentDoc}
`;

/**
 * __useOrgUnitAccumulatedCostQuery__
 *
 * To run a query within a React component, call `useOrgUnitAccumulatedCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitAccumulatedCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitAccumulatedCostQuery({
 *   variables: {
 *      orgUnitId: // value for 'orgUnitId'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useOrgUnitAccumulatedCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgUnitAccumulatedCostQuery,
    OrgUnitAccumulatedCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitAccumulatedCostQuery, OrgUnitAccumulatedCostQueryVariables>(
    OrgUnitAccumulatedCostDocument,
    options
  );
}
export function useOrgUnitAccumulatedCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitAccumulatedCostQuery,
    OrgUnitAccumulatedCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrgUnitAccumulatedCostQuery, OrgUnitAccumulatedCostQueryVariables>(
    OrgUnitAccumulatedCostDocument,
    options
  );
}
export type OrgUnitAccumulatedCostQueryHookResult = ReturnType<
  typeof useOrgUnitAccumulatedCostQuery
>;
export type OrgUnitAccumulatedCostLazyQueryHookResult = ReturnType<
  typeof useOrgUnitAccumulatedCostLazyQuery
>;
export type OrgUnitAccumulatedCostQueryResult = Apollo.QueryResult<
  OrgUnitAccumulatedCostQuery,
  OrgUnitAccumulatedCostQueryVariables
>;
export const SetOrgUnitYearlyBudgetDocument = gql`
  mutation setOrgUnitYearlyBudget($budget: BudgetYearlyInput!, $nid: ID!) {
    setOrgUnitBudget(budget: $budget, nid: $nid) {
      ...Budget
    }
  }
  ${BudgetFragmentDoc}
`;
export type SetOrgUnitYearlyBudgetMutationFn = Apollo.MutationFunction<
  SetOrgUnitYearlyBudgetMutation,
  SetOrgUnitYearlyBudgetMutationVariables
>;

/**
 * __useSetOrgUnitYearlyBudgetMutation__
 *
 * To run a mutation, you first call `useSetOrgUnitYearlyBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrgUnitYearlyBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrgUnitYearlyBudgetMutation, { data, loading, error }] = useSetOrgUnitYearlyBudgetMutation({
 *   variables: {
 *      budget: // value for 'budget'
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useSetOrgUnitYearlyBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetOrgUnitYearlyBudgetMutation,
    SetOrgUnitYearlyBudgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetOrgUnitYearlyBudgetMutation,
    SetOrgUnitYearlyBudgetMutationVariables
  >(SetOrgUnitYearlyBudgetDocument, options);
}
export type SetOrgUnitYearlyBudgetMutationHookResult = ReturnType<
  typeof useSetOrgUnitYearlyBudgetMutation
>;
export type SetOrgUnitYearlyBudgetMutationResult =
  Apollo.MutationResult<SetOrgUnitYearlyBudgetMutation>;
export type SetOrgUnitYearlyBudgetMutationOptions = Apollo.BaseMutationOptions<
  SetOrgUnitYearlyBudgetMutation,
  SetOrgUnitYearlyBudgetMutationVariables
>;
export const OrgUnitTopCategoriesCostsDocument = gql`
  query OrgUnitTopCategoriesCosts(
    $top: Int!
    $orgUnitId: ID!
    $endDate: String!
    $granularity: Granularity
    $startDate: String!
    $tableGranularity: Granularity
  ) {
    orgUnitTopCategoriesCost(
      input: {
        top: $top
        orgUnitId: $orgUnitId
        endDate: $endDate
        granularity: $granularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
    orgUnitTopCategoriesTableCost: orgUnitTopCategoriesCost(
      input: {
        top: $top
        orgUnitId: $orgUnitId
        endDate: $endDate
        granularity: $tableGranularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
  }
  ${TimePointFragmentDoc}
`;

/**
 * __useOrgUnitTopCategoriesCostsQuery__
 *
 * To run a query within a React component, call `useOrgUnitTopCategoriesCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitTopCategoriesCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitTopCategoriesCostsQuery({
 *   variables: {
 *      top: // value for 'top'
 *      orgUnitId: // value for 'orgUnitId'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useOrgUnitTopCategoriesCostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgUnitTopCategoriesCostsQuery,
    OrgUnitTopCategoriesCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitTopCategoriesCostsQuery, OrgUnitTopCategoriesCostsQueryVariables>(
    OrgUnitTopCategoriesCostsDocument,
    options
  );
}
export function useOrgUnitTopCategoriesCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitTopCategoriesCostsQuery,
    OrgUnitTopCategoriesCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgUnitTopCategoriesCostsQuery,
    OrgUnitTopCategoriesCostsQueryVariables
  >(OrgUnitTopCategoriesCostsDocument, options);
}
export type OrgUnitTopCategoriesCostsQueryHookResult = ReturnType<
  typeof useOrgUnitTopCategoriesCostsQuery
>;
export type OrgUnitTopCategoriesCostsLazyQueryHookResult = ReturnType<
  typeof useOrgUnitTopCategoriesCostsLazyQuery
>;
export type OrgUnitTopCategoriesCostsQueryResult = Apollo.QueryResult<
  OrgUnitTopCategoriesCostsQuery,
  OrgUnitTopCategoriesCostsQueryVariables
>;
export const OrgUnitTopApplicationsCostsDocument = gql`
  query OrgUnitTopApplicationsCosts(
    $top: Int!
    $orgUnitId: ID!
    $endDate: String!
    $granularity: Granularity
    $startDate: String!
    $tableGranularity: Granularity
  ) {
    orgUnitTopApplicationsCost(
      input: {
        top: $top
        orgUnitId: $orgUnitId
        endDate: $endDate
        granularity: $granularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
    orgUnitTopApplicationsTableCost: orgUnitTopApplicationsCost(
      input: {
        top: $top
        orgUnitId: $orgUnitId
        endDate: $endDate
        granularity: $tableGranularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
  }
  ${TimePointFragmentDoc}
`;

/**
 * __useOrgUnitTopApplicationsCostsQuery__
 *
 * To run a query within a React component, call `useOrgUnitTopApplicationsCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitTopApplicationsCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitTopApplicationsCostsQuery({
 *   variables: {
 *      top: // value for 'top'
 *      orgUnitId: // value for 'orgUnitId'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useOrgUnitTopApplicationsCostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgUnitTopApplicationsCostsQuery,
    OrgUnitTopApplicationsCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrgUnitTopApplicationsCostsQuery,
    OrgUnitTopApplicationsCostsQueryVariables
  >(OrgUnitTopApplicationsCostsDocument, options);
}
export function useOrgUnitTopApplicationsCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitTopApplicationsCostsQuery,
    OrgUnitTopApplicationsCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgUnitTopApplicationsCostsQuery,
    OrgUnitTopApplicationsCostsQueryVariables
  >(OrgUnitTopApplicationsCostsDocument, options);
}
export type OrgUnitTopApplicationsCostsQueryHookResult = ReturnType<
  typeof useOrgUnitTopApplicationsCostsQuery
>;
export type OrgUnitTopApplicationsCostsLazyQueryHookResult = ReturnType<
  typeof useOrgUnitTopApplicationsCostsLazyQuery
>;
export type OrgUnitTopApplicationsCostsQueryResult = Apollo.QueryResult<
  OrgUnitTopApplicationsCostsQuery,
  OrgUnitTopApplicationsCostsQueryVariables
>;
export const OrgUnitOtherCategoriesCostDocument = gql`
  query OrgUnitOtherCategoriesCost($input: OrgUnitOtherCategoriesCostInput!) {
    orgUnitOtherCategoriesCost(input: $input) {
      total
      timePoints {
        date
        value
        groups {
          id
          name
          value
        }
      }
    }
  }
`;

/**
 * __useOrgUnitOtherCategoriesCostQuery__
 *
 * To run a query within a React component, call `useOrgUnitOtherCategoriesCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgUnitOtherCategoriesCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgUnitOtherCategoriesCostQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgUnitOtherCategoriesCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrgUnitOtherCategoriesCostQuery,
    OrgUnitOtherCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrgUnitOtherCategoriesCostQuery, OrgUnitOtherCategoriesCostQueryVariables>(
    OrgUnitOtherCategoriesCostDocument,
    options
  );
}
export function useOrgUnitOtherCategoriesCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgUnitOtherCategoriesCostQuery,
    OrgUnitOtherCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrgUnitOtherCategoriesCostQuery,
    OrgUnitOtherCategoriesCostQueryVariables
  >(OrgUnitOtherCategoriesCostDocument, options);
}
export type OrgUnitOtherCategoriesCostQueryHookResult = ReturnType<
  typeof useOrgUnitOtherCategoriesCostQuery
>;
export type OrgUnitOtherCategoriesCostLazyQueryHookResult = ReturnType<
  typeof useOrgUnitOtherCategoriesCostLazyQuery
>;
export type OrgUnitOtherCategoriesCostQueryResult = Apollo.QueryResult<
  OrgUnitOtherCategoriesCostQuery,
  OrgUnitOtherCategoriesCostQueryVariables
>;
export const UpdateBusinessContextDocument = gql`
  mutation UpdateBusinessContext($id: String!, $input: UpdateBusinessContext!) {
    updateBusinessContextV2(id: $id, input: $input) {
      id
      name
      description
      color
    }
  }
`;
export type UpdateBusinessContextMutationFn = Apollo.MutationFunction<
  UpdateBusinessContextMutation,
  UpdateBusinessContextMutationVariables
>;

/**
 * __useUpdateBusinessContextMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessContextMutation, { data, loading, error }] = useUpdateBusinessContextMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessContextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessContextMutation,
    UpdateBusinessContextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBusinessContextMutation, UpdateBusinessContextMutationVariables>(
    UpdateBusinessContextDocument,
    options
  );
}
export type UpdateBusinessContextMutationHookResult = ReturnType<
  typeof useUpdateBusinessContextMutation
>;
export type UpdateBusinessContextMutationResult =
  Apollo.MutationResult<UpdateBusinessContextMutation>;
export type UpdateBusinessContextMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessContextMutation,
  UpdateBusinessContextMutationVariables
>;
export const RemoveBusinessContextDocument = gql`
  mutation RemoveBusinessContext($id: String!) {
    removeBusinessContext(id: $id)
  }
`;
export type RemoveBusinessContextMutationFn = Apollo.MutationFunction<
  RemoveBusinessContextMutation,
  RemoveBusinessContextMutationVariables
>;

/**
 * __useRemoveBusinessContextMutation__
 *
 * To run a mutation, you first call `useRemoveBusinessContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBusinessContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBusinessContextMutation, { data, loading, error }] = useRemoveBusinessContextMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveBusinessContextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBusinessContextMutation,
    RemoveBusinessContextMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveBusinessContextMutation, RemoveBusinessContextMutationVariables>(
    RemoveBusinessContextDocument,
    options
  );
}
export type RemoveBusinessContextMutationHookResult = ReturnType<
  typeof useRemoveBusinessContextMutation
>;
export type RemoveBusinessContextMutationResult =
  Apollo.MutationResult<RemoveBusinessContextMutation>;
export type RemoveBusinessContextMutationOptions = Apollo.BaseMutationOptions<
  RemoveBusinessContextMutation,
  RemoveBusinessContextMutationVariables
>;
export const CloudAccountsDocument = gql`
  query CloudAccounts(
    $limit: Int!
    $page: Int
    $filter: CloudAccountsFilter
    $order: CloudAccountsSort
    $query: String
  ) {
    cloudAccounts(limit: $limit, page: $page, filter: $filter, order: $order, query: $query) {
      accounts {
        id
        nid
        provider
        providerId
        name
        displayName
        description
        activated
        metadata
        cloudStatusV2
        providerType
        chargeTypeCosts {
          chargeType
          cost
        }
        contacts {
          id
          name
          email
        }
        owner {
          id
          name
          email
        }
      }
      count
      pages
    }
  }
`;

/**
 * __useCloudAccountsQuery__
 *
 * To run a query within a React component, call `useCloudAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudAccountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCloudAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<CloudAccountsQuery, CloudAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CloudAccountsQuery, CloudAccountsQueryVariables>(
    CloudAccountsDocument,
    options
  );
}
export function useCloudAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CloudAccountsQuery, CloudAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CloudAccountsQuery, CloudAccountsQueryVariables>(
    CloudAccountsDocument,
    options
  );
}
export type CloudAccountsQueryHookResult = ReturnType<typeof useCloudAccountsQuery>;
export type CloudAccountsLazyQueryHookResult = ReturnType<typeof useCloudAccountsLazyQuery>;
export type CloudAccountsQueryResult = Apollo.QueryResult<
  CloudAccountsQuery,
  CloudAccountsQueryVariables
>;
export const ChargeTypesDocument = gql`
  query ChargeTypes($provider: String!) {
    chargeTypes(provider: $provider) {
      chargeTypes {
        name
      }
    }
  }
`;

/**
 * __useChargeTypesQuery__
 *
 * To run a query within a React component, call `useChargeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeTypesQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useChargeTypesQuery(
  baseOptions: Apollo.QueryHookOptions<ChargeTypesQuery, ChargeTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChargeTypesQuery, ChargeTypesQueryVariables>(ChargeTypesDocument, options);
}
export function useChargeTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargeTypesQuery, ChargeTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChargeTypesQuery, ChargeTypesQueryVariables>(
    ChargeTypesDocument,
    options
  );
}
export type ChargeTypesQueryHookResult = ReturnType<typeof useChargeTypesQuery>;
export type ChargeTypesLazyQueryHookResult = ReturnType<typeof useChargeTypesLazyQuery>;
export type ChargeTypesQueryResult = Apollo.QueryResult<
  ChargeTypesQuery,
  ChargeTypesQueryVariables
>;
export const CloudAccountsNidsDocument = gql`
  query CloudAccountsNids($filter: CloudAccountsFilter) {
    cloudAccountNids(filter: $filter) {
      accounts {
        nid
      }
      count
    }
  }
`;

/**
 * __useCloudAccountsNidsQuery__
 *
 * To run a query within a React component, call `useCloudAccountsNidsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudAccountsNidsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudAccountsNidsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCloudAccountsNidsQuery(
  baseOptions?: Apollo.QueryHookOptions<CloudAccountsNidsQuery, CloudAccountsNidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CloudAccountsNidsQuery, CloudAccountsNidsQueryVariables>(
    CloudAccountsNidsDocument,
    options
  );
}
export function useCloudAccountsNidsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CloudAccountsNidsQuery, CloudAccountsNidsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CloudAccountsNidsQuery, CloudAccountsNidsQueryVariables>(
    CloudAccountsNidsDocument,
    options
  );
}
export type CloudAccountsNidsQueryHookResult = ReturnType<typeof useCloudAccountsNidsQuery>;
export type CloudAccountsNidsLazyQueryHookResult = ReturnType<typeof useCloudAccountsNidsLazyQuery>;
export type CloudAccountsNidsQueryResult = Apollo.QueryResult<
  CloudAccountsNidsQuery,
  CloudAccountsNidsQueryVariables
>;
export const AwsBillingDataDocument = gql`
  query AwsBillingData {
    awsBillingData {
      displayName
      accountId
      nid
      isActive
      isOnboarding
      bucketId
      region
      reportName
      reportPathPrefix
    }
  }
`;

/**
 * __useAwsBillingDataQuery__
 *
 * To run a query within a React component, call `useAwsBillingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAwsBillingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAwsBillingDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAwsBillingDataQuery(
  baseOptions?: Apollo.QueryHookOptions<AwsBillingDataQuery, AwsBillingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AwsBillingDataQuery, AwsBillingDataQueryVariables>(
    AwsBillingDataDocument,
    options
  );
}
export function useAwsBillingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AwsBillingDataQuery, AwsBillingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AwsBillingDataQuery, AwsBillingDataQueryVariables>(
    AwsBillingDataDocument,
    options
  );
}
export type AwsBillingDataQueryHookResult = ReturnType<typeof useAwsBillingDataQuery>;
export type AwsBillingDataLazyQueryHookResult = ReturnType<typeof useAwsBillingDataLazyQuery>;
export type AwsBillingDataQueryResult = Apollo.QueryResult<
  AwsBillingDataQuery,
  AwsBillingDataQueryVariables
>;
export const AzureBillingDataDocument = gql`
  query AzureBillingData {
    azureBillingData {
      isActive
      enrollmentNumber
      capacitorCustomerId
      isOnboarding
    }
  }
`;

/**
 * __useAzureBillingDataQuery__
 *
 * To run a query within a React component, call `useAzureBillingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAzureBillingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAzureBillingDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAzureBillingDataQuery(
  baseOptions?: Apollo.QueryHookOptions<AzureBillingDataQuery, AzureBillingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AzureBillingDataQuery, AzureBillingDataQueryVariables>(
    AzureBillingDataDocument,
    options
  );
}
export function useAzureBillingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AzureBillingDataQuery, AzureBillingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AzureBillingDataQuery, AzureBillingDataQueryVariables>(
    AzureBillingDataDocument,
    options
  );
}
export type AzureBillingDataQueryHookResult = ReturnType<typeof useAzureBillingDataQuery>;
export type AzureBillingDataLazyQueryHookResult = ReturnType<typeof useAzureBillingDataLazyQuery>;
export type AzureBillingDataQueryResult = Apollo.QueryResult<
  AzureBillingDataQuery,
  AzureBillingDataQueryVariables
>;
export const GcpBillingDataDocument = gql`
  query GcpBillingData {
    gcpBillingData {
      isActive
      organizationId
      onboardedOrgId
      projectId
      isOnboarding
      bigQueryDatasetName
      bigQueryTableName
      useDetailedBillingData
    }
  }
`;

/**
 * __useGcpBillingDataQuery__
 *
 * To run a query within a React component, call `useGcpBillingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGcpBillingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGcpBillingDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGcpBillingDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GcpBillingDataQuery, GcpBillingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GcpBillingDataQuery, GcpBillingDataQueryVariables>(
    GcpBillingDataDocument,
    options
  );
}
export function useGcpBillingDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GcpBillingDataQuery, GcpBillingDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GcpBillingDataQuery, GcpBillingDataQueryVariables>(
    GcpBillingDataDocument,
    options
  );
}
export type GcpBillingDataQueryHookResult = ReturnType<typeof useGcpBillingDataQuery>;
export type GcpBillingDataLazyQueryHookResult = ReturnType<typeof useGcpBillingDataLazyQuery>;
export type GcpBillingDataQueryResult = Apollo.QueryResult<
  GcpBillingDataQuery,
  GcpBillingDataQueryVariables
>;
export const CloudAccountCostsByProviderDocument = gql`
  query CloudAccountCostsByProvider {
    cloudAccountCostsByProvider {
      costsByProvider {
        provider
        cost
        count
        hasWarning
      }
    }
  }
`;

/**
 * __useCloudAccountCostsByProviderQuery__
 *
 * To run a query within a React component, call `useCloudAccountCostsByProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudAccountCostsByProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudAccountCostsByProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudAccountCostsByProviderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CloudAccountCostsByProviderQuery,
    CloudAccountCostsByProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CloudAccountCostsByProviderQuery,
    CloudAccountCostsByProviderQueryVariables
  >(CloudAccountCostsByProviderDocument, options);
}
export function useCloudAccountCostsByProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudAccountCostsByProviderQuery,
    CloudAccountCostsByProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CloudAccountCostsByProviderQuery,
    CloudAccountCostsByProviderQueryVariables
  >(CloudAccountCostsByProviderDocument, options);
}
export type CloudAccountCostsByProviderQueryHookResult = ReturnType<
  typeof useCloudAccountCostsByProviderQuery
>;
export type CloudAccountCostsByProviderLazyQueryHookResult = ReturnType<
  typeof useCloudAccountCostsByProviderLazyQuery
>;
export type CloudAccountCostsByProviderQueryResult = Apollo.QueryResult<
  CloudAccountCostsByProviderQuery,
  CloudAccountCostsByProviderQueryVariables
>;
export const CloudAccountSavingsCostsDocument = gql`
  query CloudAccountSavingsCosts($cloudProviderId: String!) {
    cloudAccountSavingCosts(cloudProviderId: $cloudProviderId) {
      cost
      co2e
    }
  }
`;

/**
 * __useCloudAccountSavingsCostsQuery__
 *
 * To run a query within a React component, call `useCloudAccountSavingsCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudAccountSavingsCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudAccountSavingsCostsQuery({
 *   variables: {
 *      cloudProviderId: // value for 'cloudProviderId'
 *   },
 * });
 */
export function useCloudAccountSavingsCostsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CloudAccountSavingsCostsQuery,
    CloudAccountSavingsCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CloudAccountSavingsCostsQuery, CloudAccountSavingsCostsQueryVariables>(
    CloudAccountSavingsCostsDocument,
    options
  );
}
export function useCloudAccountSavingsCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudAccountSavingsCostsQuery,
    CloudAccountSavingsCostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CloudAccountSavingsCostsQuery, CloudAccountSavingsCostsQueryVariables>(
    CloudAccountSavingsCostsDocument,
    options
  );
}
export type CloudAccountSavingsCostsQueryHookResult = ReturnType<
  typeof useCloudAccountSavingsCostsQuery
>;
export type CloudAccountSavingsCostsLazyQueryHookResult = ReturnType<
  typeof useCloudAccountSavingsCostsLazyQuery
>;
export type CloudAccountSavingsCostsQueryResult = Apollo.QueryResult<
  CloudAccountSavingsCostsQuery,
  CloudAccountSavingsCostsQueryVariables
>;
export const CloudAccountCostsByChargeTypeDocument = gql`
  query CloudAccountCostsByChargeType($filter: CloudAccountsFilter, $query: String) {
    cloudAccountCostsByChargeType(filter: $filter, query: $query) {
      costsByChargeType {
        chargeType
        cost
      }
    }
  }
`;

/**
 * __useCloudAccountCostsByChargeTypeQuery__
 *
 * To run a query within a React component, call `useCloudAccountCostsByChargeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudAccountCostsByChargeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudAccountCostsByChargeTypeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCloudAccountCostsByChargeTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CloudAccountCostsByChargeTypeQuery,
    CloudAccountCostsByChargeTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CloudAccountCostsByChargeTypeQuery,
    CloudAccountCostsByChargeTypeQueryVariables
  >(CloudAccountCostsByChargeTypeDocument, options);
}
export function useCloudAccountCostsByChargeTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudAccountCostsByChargeTypeQuery,
    CloudAccountCostsByChargeTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CloudAccountCostsByChargeTypeQuery,
    CloudAccountCostsByChargeTypeQueryVariables
  >(CloudAccountCostsByChargeTypeDocument, options);
}
export type CloudAccountCostsByChargeTypeQueryHookResult = ReturnType<
  typeof useCloudAccountCostsByChargeTypeQuery
>;
export type CloudAccountCostsByChargeTypeLazyQueryHookResult = ReturnType<
  typeof useCloudAccountCostsByChargeTypeLazyQuery
>;
export type CloudAccountCostsByChargeTypeQueryResult = Apollo.QueryResult<
  CloudAccountCostsByChargeTypeQuery,
  CloudAccountCostsByChargeTypeQueryVariables
>;
export const AddGcpBillingConfigV2Document = gql`
  mutation AddGCPBillingConfigV2($input: GcpBillingConfig!) {
    addGCPBillingConfigV2(input: $input) {
      onboardedOrgId
      organizationId
      projectId
      isActive
      isOnboarding
      bigQueryDatasetName
      bigQueryTableName
      useDetailedBillingData
    }
  }
`;
export type AddGcpBillingConfigV2MutationFn = Apollo.MutationFunction<
  AddGcpBillingConfigV2Mutation,
  AddGcpBillingConfigV2MutationVariables
>;

/**
 * __useAddGcpBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useAddGcpBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGcpBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGcpBillingConfigV2Mutation, { data, loading, error }] = useAddGcpBillingConfigV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGcpBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGcpBillingConfigV2Mutation,
    AddGcpBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddGcpBillingConfigV2Mutation, AddGcpBillingConfigV2MutationVariables>(
    AddGcpBillingConfigV2Document,
    options
  );
}
export type AddGcpBillingConfigV2MutationHookResult = ReturnType<
  typeof useAddGcpBillingConfigV2Mutation
>;
export type AddGcpBillingConfigV2MutationResult =
  Apollo.MutationResult<AddGcpBillingConfigV2Mutation>;
export type AddGcpBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  AddGcpBillingConfigV2Mutation,
  AddGcpBillingConfigV2MutationVariables
>;
export const UpdateGcpBillingConfigV2Document = gql`
  mutation UpdateGcpBillingConfigV2($input: UpdateGCPBillingConfig!) {
    updateGcpBillingConfigV2(input: $input) {
      onboardedOrgId
      organizationId
      projectId
      isActive
      isOnboarding
      bigQueryDatasetName
      bigQueryTableName
      useDetailedBillingData
    }
  }
`;
export type UpdateGcpBillingConfigV2MutationFn = Apollo.MutationFunction<
  UpdateGcpBillingConfigV2Mutation,
  UpdateGcpBillingConfigV2MutationVariables
>;

/**
 * __useUpdateGcpBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useUpdateGcpBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGcpBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGcpBillingConfigV2Mutation, { data, loading, error }] = useUpdateGcpBillingConfigV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGcpBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGcpBillingConfigV2Mutation,
    UpdateGcpBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGcpBillingConfigV2Mutation,
    UpdateGcpBillingConfigV2MutationVariables
  >(UpdateGcpBillingConfigV2Document, options);
}
export type UpdateGcpBillingConfigV2MutationHookResult = ReturnType<
  typeof useUpdateGcpBillingConfigV2Mutation
>;
export type UpdateGcpBillingConfigV2MutationResult =
  Apollo.MutationResult<UpdateGcpBillingConfigV2Mutation>;
export type UpdateGcpBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateGcpBillingConfigV2Mutation,
  UpdateGcpBillingConfigV2MutationVariables
>;
export const RemoveGcpBillingConfigV2Document = gql`
  mutation RemoveGcpBillingConfigV2($onboardedOrgId: String!) {
    removeGcpBillingConfigV2(onboardedOrgId: $onboardedOrgId) {
      id
    }
  }
`;
export type RemoveGcpBillingConfigV2MutationFn = Apollo.MutationFunction<
  RemoveGcpBillingConfigV2Mutation,
  RemoveGcpBillingConfigV2MutationVariables
>;

/**
 * __useRemoveGcpBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useRemoveGcpBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGcpBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGcpBillingConfigV2Mutation, { data, loading, error }] = useRemoveGcpBillingConfigV2Mutation({
 *   variables: {
 *      onboardedOrgId: // value for 'onboardedOrgId'
 *   },
 * });
 */
export function useRemoveGcpBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGcpBillingConfigV2Mutation,
    RemoveGcpBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGcpBillingConfigV2Mutation,
    RemoveGcpBillingConfigV2MutationVariables
  >(RemoveGcpBillingConfigV2Document, options);
}
export type RemoveGcpBillingConfigV2MutationHookResult = ReturnType<
  typeof useRemoveGcpBillingConfigV2Mutation
>;
export type RemoveGcpBillingConfigV2MutationResult =
  Apollo.MutationResult<RemoveGcpBillingConfigV2Mutation>;
export type RemoveGcpBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  RemoveGcpBillingConfigV2Mutation,
  RemoveGcpBillingConfigV2MutationVariables
>;
export const AddAwsBillingConfigDocument = gql`
  mutation AddAwsBillingConfig($input: AddAwsBillingConfig!) {
    addAWSBillingConfigV2(input: $input) {
      nid
      accountId
      displayName
      isActive
      isOnboarding
      bucketId
      region
      reportName
      reportPathPrefix
    }
  }
`;
export type AddAwsBillingConfigMutationFn = Apollo.MutationFunction<
  AddAwsBillingConfigMutation,
  AddAwsBillingConfigMutationVariables
>;

/**
 * __useAddAwsBillingConfigMutation__
 *
 * To run a mutation, you first call `useAddAwsBillingConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAwsBillingConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAwsBillingConfigMutation, { data, loading, error }] = useAddAwsBillingConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAwsBillingConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAwsBillingConfigMutation,
    AddAwsBillingConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAwsBillingConfigMutation, AddAwsBillingConfigMutationVariables>(
    AddAwsBillingConfigDocument,
    options
  );
}
export type AddAwsBillingConfigMutationHookResult = ReturnType<
  typeof useAddAwsBillingConfigMutation
>;
export type AddAwsBillingConfigMutationResult = Apollo.MutationResult<AddAwsBillingConfigMutation>;
export type AddAwsBillingConfigMutationOptions = Apollo.BaseMutationOptions<
  AddAwsBillingConfigMutation,
  AddAwsBillingConfigMutationVariables
>;
export const RemoveAwsBillingConfigV2Document = gql`
  mutation RemoveAwsBillingConfigV2($accountId: ID!) {
    removeAwsBillingConfigV2(accountId: $accountId) {
      id
    }
  }
`;
export type RemoveAwsBillingConfigV2MutationFn = Apollo.MutationFunction<
  RemoveAwsBillingConfigV2Mutation,
  RemoveAwsBillingConfigV2MutationVariables
>;

/**
 * __useRemoveAwsBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useRemoveAwsBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAwsBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAwsBillingConfigV2Mutation, { data, loading, error }] = useRemoveAwsBillingConfigV2Mutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRemoveAwsBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAwsBillingConfigV2Mutation,
    RemoveAwsBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAwsBillingConfigV2Mutation,
    RemoveAwsBillingConfigV2MutationVariables
  >(RemoveAwsBillingConfigV2Document, options);
}
export type RemoveAwsBillingConfigV2MutationHookResult = ReturnType<
  typeof useRemoveAwsBillingConfigV2Mutation
>;
export type RemoveAwsBillingConfigV2MutationResult =
  Apollo.MutationResult<RemoveAwsBillingConfigV2Mutation>;
export type RemoveAwsBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  RemoveAwsBillingConfigV2Mutation,
  RemoveAwsBillingConfigV2MutationVariables
>;
export const AddAzureBillingConfigV2Document = gql`
  mutation AddAzureBillingConfigV2($input: AddAzureBillingConfig!) {
    addAzureBillingConfigV2(input: $input) {
      capacitorCustomerId
      enrollmentNumber
      isActive
      isOnboarding
    }
  }
`;
export type AddAzureBillingConfigV2MutationFn = Apollo.MutationFunction<
  AddAzureBillingConfigV2Mutation,
  AddAzureBillingConfigV2MutationVariables
>;

/**
 * __useAddAzureBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useAddAzureBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAzureBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAzureBillingConfigV2Mutation, { data, loading, error }] = useAddAzureBillingConfigV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAzureBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAzureBillingConfigV2Mutation,
    AddAzureBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAzureBillingConfigV2Mutation,
    AddAzureBillingConfigV2MutationVariables
  >(AddAzureBillingConfigV2Document, options);
}
export type AddAzureBillingConfigV2MutationHookResult = ReturnType<
  typeof useAddAzureBillingConfigV2Mutation
>;
export type AddAzureBillingConfigV2MutationResult =
  Apollo.MutationResult<AddAzureBillingConfigV2Mutation>;
export type AddAzureBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  AddAzureBillingConfigV2Mutation,
  AddAzureBillingConfigV2MutationVariables
>;
export const UpdateAzureBillingConfigV2Document = gql`
  mutation UpdateAzureBillingConfigV2($input: UpdateAzureBillingConfig!) {
    updateAzureBillingConfigV2(input: $input) {
      capacitorCustomerId
      enrollmentNumber
      isActive
      isOnboarding
    }
  }
`;
export type UpdateAzureBillingConfigV2MutationFn = Apollo.MutationFunction<
  UpdateAzureBillingConfigV2Mutation,
  UpdateAzureBillingConfigV2MutationVariables
>;

/**
 * __useUpdateAzureBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useUpdateAzureBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAzureBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAzureBillingConfigV2Mutation, { data, loading, error }] = useUpdateAzureBillingConfigV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAzureBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAzureBillingConfigV2Mutation,
    UpdateAzureBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAzureBillingConfigV2Mutation,
    UpdateAzureBillingConfigV2MutationVariables
  >(UpdateAzureBillingConfigV2Document, options);
}
export type UpdateAzureBillingConfigV2MutationHookResult = ReturnType<
  typeof useUpdateAzureBillingConfigV2Mutation
>;
export type UpdateAzureBillingConfigV2MutationResult =
  Apollo.MutationResult<UpdateAzureBillingConfigV2Mutation>;
export type UpdateAzureBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateAzureBillingConfigV2Mutation,
  UpdateAzureBillingConfigV2MutationVariables
>;
export const RemoveAzureBillingConfigV2Document = gql`
  mutation RemoveAzureBillingConfigV2($capacitorCustomerId: String!) {
    removeAzureBillingConfigV2(capacitorCustomerId: $capacitorCustomerId) {
      id
    }
  }
`;
export type RemoveAzureBillingConfigV2MutationFn = Apollo.MutationFunction<
  RemoveAzureBillingConfigV2Mutation,
  RemoveAzureBillingConfigV2MutationVariables
>;

/**
 * __useRemoveAzureBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useRemoveAzureBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAzureBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAzureBillingConfigV2Mutation, { data, loading, error }] = useRemoveAzureBillingConfigV2Mutation({
 *   variables: {
 *      capacitorCustomerId: // value for 'capacitorCustomerId'
 *   },
 * });
 */
export function useRemoveAzureBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAzureBillingConfigV2Mutation,
    RemoveAzureBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAzureBillingConfigV2Mutation,
    RemoveAzureBillingConfigV2MutationVariables
  >(RemoveAzureBillingConfigV2Document, options);
}
export type RemoveAzureBillingConfigV2MutationHookResult = ReturnType<
  typeof useRemoveAzureBillingConfigV2Mutation
>;
export type RemoveAzureBillingConfigV2MutationResult =
  Apollo.MutationResult<RemoveAzureBillingConfigV2Mutation>;
export type RemoveAzureBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  RemoveAzureBillingConfigV2Mutation,
  RemoveAzureBillingConfigV2MutationVariables
>;
export const UpdateAwsBillingConfigV2Document = gql`
  mutation UpdateAwsBillingConfigV2($input: UpdateAwsBillingConfig!) {
    updateAwsBillingConfigV2(input: $input) {
      nid
      accountId
      displayName
      isActive
      isOnboarding
      bucketId
      region
      reportName
      reportPathPrefix
    }
  }
`;
export type UpdateAwsBillingConfigV2MutationFn = Apollo.MutationFunction<
  UpdateAwsBillingConfigV2Mutation,
  UpdateAwsBillingConfigV2MutationVariables
>;

/**
 * __useUpdateAwsBillingConfigV2Mutation__
 *
 * To run a mutation, you first call `useUpdateAwsBillingConfigV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAwsBillingConfigV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAwsBillingConfigV2Mutation, { data, loading, error }] = useUpdateAwsBillingConfigV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAwsBillingConfigV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAwsBillingConfigV2Mutation,
    UpdateAwsBillingConfigV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAwsBillingConfigV2Mutation,
    UpdateAwsBillingConfigV2MutationVariables
  >(UpdateAwsBillingConfigV2Document, options);
}
export type UpdateAwsBillingConfigV2MutationHookResult = ReturnType<
  typeof useUpdateAwsBillingConfigV2Mutation
>;
export type UpdateAwsBillingConfigV2MutationResult =
  Apollo.MutationResult<UpdateAwsBillingConfigV2Mutation>;
export type UpdateAwsBillingConfigV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateAwsBillingConfigV2Mutation,
  UpdateAwsBillingConfigV2MutationVariables
>;
export const UpdateAccountV3Document = gql`
  mutation UpdateAccountV3($nid: ID!, $input: UpdateAccountV3!) {
    updateAccountV3(nid: $nid, input: $input) {
      id
      nid
      provider
      chargeTypeCosts {
        chargeType
        cost
      }
      providerId
      name
      displayName
      description
      activated
      metadata
      status
      cloudStatusV2
      providerType
      contacts {
        id
        name
        email
      }
      owner {
        id
        name
        email
      }
    }
  }
`;
export type UpdateAccountV3MutationFn = Apollo.MutationFunction<
  UpdateAccountV3Mutation,
  UpdateAccountV3MutationVariables
>;

/**
 * __useUpdateAccountV3Mutation__
 *
 * To run a mutation, you first call `useUpdateAccountV3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountV3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountV3Mutation, { data, loading, error }] = useUpdateAccountV3Mutation({
 *   variables: {
 *      nid: // value for 'nid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountV3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountV3Mutation,
    UpdateAccountV3MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountV3Mutation, UpdateAccountV3MutationVariables>(
    UpdateAccountV3Document,
    options
  );
}
export type UpdateAccountV3MutationHookResult = ReturnType<typeof useUpdateAccountV3Mutation>;
export type UpdateAccountV3MutationResult = Apollo.MutationResult<UpdateAccountV3Mutation>;
export type UpdateAccountV3MutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountV3Mutation,
  UpdateAccountV3MutationVariables
>;
export const UpdateAzureAccountCredentialsBatchDocument = gql`
  mutation UpdateAzureAccountCredentialsBatch(
    $nids: [ID!]!
    $input: UpdateAzureAccountCredentialsInput!
  ) {
    updateAzureAccountCredentialsBatch(input: $input, nids: $nids)
  }
`;
export type UpdateAzureAccountCredentialsBatchMutationFn = Apollo.MutationFunction<
  UpdateAzureAccountCredentialsBatchMutation,
  UpdateAzureAccountCredentialsBatchMutationVariables
>;

/**
 * __useUpdateAzureAccountCredentialsBatchMutation__
 *
 * To run a mutation, you first call `useUpdateAzureAccountCredentialsBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAzureAccountCredentialsBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAzureAccountCredentialsBatchMutation, { data, loading, error }] = useUpdateAzureAccountCredentialsBatchMutation({
 *   variables: {
 *      nids: // value for 'nids'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAzureAccountCredentialsBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAzureAccountCredentialsBatchMutation,
    UpdateAzureAccountCredentialsBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAzureAccountCredentialsBatchMutation,
    UpdateAzureAccountCredentialsBatchMutationVariables
  >(UpdateAzureAccountCredentialsBatchDocument, options);
}
export type UpdateAzureAccountCredentialsBatchMutationHookResult = ReturnType<
  typeof useUpdateAzureAccountCredentialsBatchMutation
>;
export type UpdateAzureAccountCredentialsBatchMutationResult =
  Apollo.MutationResult<UpdateAzureAccountCredentialsBatchMutation>;
export type UpdateAzureAccountCredentialsBatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateAzureAccountCredentialsBatchMutation,
  UpdateAzureAccountCredentialsBatchMutationVariables
>;
export const UpdateAwsAccountCredentialsBatchDocument = gql`
  mutation UpdateAwsAccountCredentialsBatch(
    $nids: [ID!]!
    $input: UpdateAwsAccountCredentialsInput!
  ) {
    updateAwsAccountCredentialsBatch(input: $input, nids: $nids)
  }
`;
export type UpdateAwsAccountCredentialsBatchMutationFn = Apollo.MutationFunction<
  UpdateAwsAccountCredentialsBatchMutation,
  UpdateAwsAccountCredentialsBatchMutationVariables
>;

/**
 * __useUpdateAwsAccountCredentialsBatchMutation__
 *
 * To run a mutation, you first call `useUpdateAwsAccountCredentialsBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAwsAccountCredentialsBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAwsAccountCredentialsBatchMutation, { data, loading, error }] = useUpdateAwsAccountCredentialsBatchMutation({
 *   variables: {
 *      nids: // value for 'nids'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAwsAccountCredentialsBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAwsAccountCredentialsBatchMutation,
    UpdateAwsAccountCredentialsBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAwsAccountCredentialsBatchMutation,
    UpdateAwsAccountCredentialsBatchMutationVariables
  >(UpdateAwsAccountCredentialsBatchDocument, options);
}
export type UpdateAwsAccountCredentialsBatchMutationHookResult = ReturnType<
  typeof useUpdateAwsAccountCredentialsBatchMutation
>;
export type UpdateAwsAccountCredentialsBatchMutationResult =
  Apollo.MutationResult<UpdateAwsAccountCredentialsBatchMutation>;
export type UpdateAwsAccountCredentialsBatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateAwsAccountCredentialsBatchMutation,
  UpdateAwsAccountCredentialsBatchMutationVariables
>;
export const UpdateGcpAccountCredentialsBatchDocument = gql`
  mutation UpdateGcpAccountCredentialsBatch(
    $nids: [ID!]!
    $input: UpdateGcpAccountCredentialsInput!
  ) {
    updateGcpAccountCredentialsBatch(input: $input, nids: $nids)
  }
`;
export type UpdateGcpAccountCredentialsBatchMutationFn = Apollo.MutationFunction<
  UpdateGcpAccountCredentialsBatchMutation,
  UpdateGcpAccountCredentialsBatchMutationVariables
>;

/**
 * __useUpdateGcpAccountCredentialsBatchMutation__
 *
 * To run a mutation, you first call `useUpdateGcpAccountCredentialsBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGcpAccountCredentialsBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGcpAccountCredentialsBatchMutation, { data, loading, error }] = useUpdateGcpAccountCredentialsBatchMutation({
 *   variables: {
 *      nids: // value for 'nids'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGcpAccountCredentialsBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGcpAccountCredentialsBatchMutation,
    UpdateGcpAccountCredentialsBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGcpAccountCredentialsBatchMutation,
    UpdateGcpAccountCredentialsBatchMutationVariables
  >(UpdateGcpAccountCredentialsBatchDocument, options);
}
export type UpdateGcpAccountCredentialsBatchMutationHookResult = ReturnType<
  typeof useUpdateGcpAccountCredentialsBatchMutation
>;
export type UpdateGcpAccountCredentialsBatchMutationResult =
  Apollo.MutationResult<UpdateGcpAccountCredentialsBatchMutation>;
export type UpdateGcpAccountCredentialsBatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateGcpAccountCredentialsBatchMutation,
  UpdateGcpAccountCredentialsBatchMutationVariables
>;
export const UpdateIbmAccountCredentialsBatchDocument = gql`
  mutation UpdateIbmAccountCredentialsBatch(
    $nids: [ID!]!
    $input: UpdateIbmAccountCredentialsInput!
  ) {
    updateIbmAccountCredentialsBatch(input: $input, nids: $nids)
  }
`;
export type UpdateIbmAccountCredentialsBatchMutationFn = Apollo.MutationFunction<
  UpdateIbmAccountCredentialsBatchMutation,
  UpdateIbmAccountCredentialsBatchMutationVariables
>;

/**
 * __useUpdateIbmAccountCredentialsBatchMutation__
 *
 * To run a mutation, you first call `useUpdateIbmAccountCredentialsBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIbmAccountCredentialsBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIbmAccountCredentialsBatchMutation, { data, loading, error }] = useUpdateIbmAccountCredentialsBatchMutation({
 *   variables: {
 *      nids: // value for 'nids'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIbmAccountCredentialsBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIbmAccountCredentialsBatchMutation,
    UpdateIbmAccountCredentialsBatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIbmAccountCredentialsBatchMutation,
    UpdateIbmAccountCredentialsBatchMutationVariables
  >(UpdateIbmAccountCredentialsBatchDocument, options);
}
export type UpdateIbmAccountCredentialsBatchMutationHookResult = ReturnType<
  typeof useUpdateIbmAccountCredentialsBatchMutation
>;
export type UpdateIbmAccountCredentialsBatchMutationResult =
  Apollo.MutationResult<UpdateIbmAccountCredentialsBatchMutation>;
export type UpdateIbmAccountCredentialsBatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateIbmAccountCredentialsBatchMutation,
  UpdateIbmAccountCredentialsBatchMutationVariables
>;
export const RemoveAccountsDocument = gql`
  mutation RemoveAccounts($input: BatchRemoveAccountsInput!) {
    removeAccounts(input: $input) {
      accounts {
        id
        nid
        provider
        providerId
        name
        displayName
        description
        activated
        metadata
        providerType
        chargeTypeCosts {
          chargeType
          cost
        }
        contacts {
          id
          name
          email
        }
        owner {
          id
          name
          email
        }
      }
      count
      pages
    }
  }
`;
export type RemoveAccountsMutationFn = Apollo.MutationFunction<
  RemoveAccountsMutation,
  RemoveAccountsMutationVariables
>;

/**
 * __useRemoveAccountsMutation__
 *
 * To run a mutation, you first call `useRemoveAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountsMutation, { data, loading, error }] = useRemoveAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveAccountsMutation, RemoveAccountsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAccountsMutation, RemoveAccountsMutationVariables>(
    RemoveAccountsDocument,
    options
  );
}
export type RemoveAccountsMutationHookResult = ReturnType<typeof useRemoveAccountsMutation>;
export type RemoveAccountsMutationResult = Apollo.MutationResult<RemoveAccountsMutation>;
export type RemoveAccountsMutationOptions = Apollo.BaseMutationOptions<
  RemoveAccountsMutation,
  RemoveAccountsMutationVariables
>;
export const AddAccountV3Document = gql`
  mutation AddAccountV3($input: AddAccountV3!) {
    addAccountV3(input: $input) {
      id
      nid
      provider
      chargeTypeCosts {
        chargeType
        cost
      }
      providerId
      name
      displayName
      description
      activated
      metadata
      providerType
      contacts {
        id
        name
        email
      }
      owner {
        id
        name
        email
      }
      cloudWasteAggregate {
        hasWaste
        cost
        co2e
        count
      }
    }
  }
`;
export type AddAccountV3MutationFn = Apollo.MutationFunction<
  AddAccountV3Mutation,
  AddAccountV3MutationVariables
>;

/**
 * __useAddAccountV3Mutation__
 *
 * To run a mutation, you first call `useAddAccountV3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountV3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountV3Mutation, { data, loading, error }] = useAddAccountV3Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAccountV3Mutation(
  baseOptions?: Apollo.MutationHookOptions<AddAccountV3Mutation, AddAccountV3MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAccountV3Mutation, AddAccountV3MutationVariables>(
    AddAccountV3Document,
    options
  );
}
export type AddAccountV3MutationHookResult = ReturnType<typeof useAddAccountV3Mutation>;
export type AddAccountV3MutationResult = Apollo.MutationResult<AddAccountV3Mutation>;
export type AddAccountV3MutationOptions = Apollo.BaseMutationOptions<
  AddAccountV3Mutation,
  AddAccountV3MutationVariables
>;
export const ClusterResourceConsumptionDocument = gql`
  query ClusterResourceConsumption($id: String!) {
    clusterResourceConsumption(accountId: $id) {
      memory {
        date
        used
      }
      cpu {
        date
        used
      }
      storageRw {
        date
        read
        write
      }
      accountId
    }
  }
`;

/**
 * __useClusterResourceConsumptionQuery__
 *
 * To run a query within a React component, call `useClusterResourceConsumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useClusterResourceConsumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClusterResourceConsumptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClusterResourceConsumptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClusterResourceConsumptionQuery,
    ClusterResourceConsumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClusterResourceConsumptionQuery, ClusterResourceConsumptionQueryVariables>(
    ClusterResourceConsumptionDocument,
    options
  );
}
export function useClusterResourceConsumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClusterResourceConsumptionQuery,
    ClusterResourceConsumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClusterResourceConsumptionQuery,
    ClusterResourceConsumptionQueryVariables
  >(ClusterResourceConsumptionDocument, options);
}
export type ClusterResourceConsumptionQueryHookResult = ReturnType<
  typeof useClusterResourceConsumptionQuery
>;
export type ClusterResourceConsumptionLazyQueryHookResult = ReturnType<
  typeof useClusterResourceConsumptionLazyQuery
>;
export type ClusterResourceConsumptionQueryResult = Apollo.QueryResult<
  ClusterResourceConsumptionQuery,
  ClusterResourceConsumptionQueryVariables
>;
export const AccountV2Document = gql`
  query AccountV2($id: String!) {
    accountV2(id: $id) {
      id
      contacts {
        id
        name
        email
      }
      name
      displayName
      provider
      status
      providerId
      providerType
      description
      owner {
        id
        name
      }
      activated
      metadata
    }
  }
`;

/**
 * __useAccountV2Query__
 *
 * To run a query within a React component, call `useAccountV2Query` and pass it any options that fit your needs.
 * When your component renders, `useAccountV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountV2Query({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountV2Query(
  baseOptions: Apollo.QueryHookOptions<AccountV2Query, AccountV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountV2Query, AccountV2QueryVariables>(AccountV2Document, options);
}
export function useAccountV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountV2Query, AccountV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountV2Query, AccountV2QueryVariables>(AccountV2Document, options);
}
export type AccountV2QueryHookResult = ReturnType<typeof useAccountV2Query>;
export type AccountV2LazyQueryHookResult = ReturnType<typeof useAccountV2LazyQuery>;
export type AccountV2QueryResult = Apollo.QueryResult<AccountV2Query, AccountV2QueryVariables>;
export const AccountSpendsDocument = gql`
  query AccountSpends($accountProviderId: String!) {
    accountSpends(accountProviderId: $accountProviderId) {
      currentMonthForecastValue
      currentMonthValue
      previousMonthValue
      currentMonthCo2e
    }
  }
`;

/**
 * __useAccountSpendsQuery__
 *
 * To run a query within a React component, call `useAccountSpendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSpendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSpendsQuery({
 *   variables: {
 *      accountProviderId: // value for 'accountProviderId'
 *   },
 * });
 */
export function useAccountSpendsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountSpendsQuery, AccountSpendsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountSpendsQuery, AccountSpendsQueryVariables>(
    AccountSpendsDocument,
    options
  );
}
export function useAccountSpendsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountSpendsQuery, AccountSpendsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountSpendsQuery, AccountSpendsQueryVariables>(
    AccountSpendsDocument,
    options
  );
}
export type AccountSpendsQueryHookResult = ReturnType<typeof useAccountSpendsQuery>;
export type AccountSpendsLazyQueryHookResult = ReturnType<typeof useAccountSpendsLazyQuery>;
export type AccountSpendsQueryResult = Apollo.QueryResult<
  AccountSpendsQuery,
  AccountSpendsQueryVariables
>;
export const AccountsV2CountsDocument = gql`
  query AccountsV2Counts {
    AWS: accountsV2(limit: 1, filter: { providers: [AWS] }) {
      count
    }
    GCP: accountsV2(limit: 1, filter: { providers: [GCP] }) {
      count
    }
    AZURE: accountsV2(limit: 1, filter: { providers: [AZURE] }) {
      count
    }
    VMWARE: accountsV2(limit: 1, filter: { providers: [HYBRID, VMWARE], providerTypes: [VMWARE] }) {
      count
    }
    IBMCLOUD: accountsV2(limit: 1, filter: { providers: [IBMCLOUD] }) {
      count
    }
    KUBERNETES: accountsV2(
      limit: 1
      filter: { providers: [HYBRID, KUBERNETES], providerTypes: [KUBERNETES] }
    ) {
      count
    }
    OPENSHIFT: accountsV2(
      limit: 1
      filter: { providers: [HYBRID, OPENSHIFT], providerTypes: [OPENSHIFT] }
    ) {
      count
    }
  }
`;

/**
 * __useAccountsV2CountsQuery__
 *
 * To run a query within a React component, call `useAccountsV2CountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsV2CountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsV2CountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsV2CountsQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountsV2CountsQuery, AccountsV2CountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountsV2CountsQuery, AccountsV2CountsQueryVariables>(
    AccountsV2CountsDocument,
    options
  );
}
export function useAccountsV2CountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountsV2CountsQuery, AccountsV2CountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountsV2CountsQuery, AccountsV2CountsQueryVariables>(
    AccountsV2CountsDocument,
    options
  );
}
export type AccountsV2CountsQueryHookResult = ReturnType<typeof useAccountsV2CountsQuery>;
export type AccountsV2CountsLazyQueryHookResult = ReturnType<typeof useAccountsV2CountsLazyQuery>;
export type AccountsV2CountsQueryResult = Apollo.QueryResult<
  AccountsV2CountsQuery,
  AccountsV2CountsQueryVariables
>;
export const AccountsV2ByProviderDocument = gql`
  query AccountsV2ByProvider($limit: Int!, $page: Int!, $filter: AccountsFilter) {
    accountsV2(limit: $limit, page: $page, filter: $filter) {
      accounts {
        id
        provider
        providerId
        providerType
        activated
        status
        description
        name
        displayName
        owner {
          id
          name
        }
        contacts {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useAccountsV2ByProviderQuery__
 *
 * To run a query within a React component, call `useAccountsV2ByProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsV2ByProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsV2ByProviderQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAccountsV2ByProviderQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountsV2ByProviderQuery,
    AccountsV2ByProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountsV2ByProviderQuery, AccountsV2ByProviderQueryVariables>(
    AccountsV2ByProviderDocument,
    options
  );
}
export function useAccountsV2ByProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountsV2ByProviderQuery,
    AccountsV2ByProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountsV2ByProviderQuery, AccountsV2ByProviderQueryVariables>(
    AccountsV2ByProviderDocument,
    options
  );
}
export type AccountsV2ByProviderQueryHookResult = ReturnType<typeof useAccountsV2ByProviderQuery>;
export type AccountsV2ByProviderLazyQueryHookResult = ReturnType<
  typeof useAccountsV2ByProviderLazyQuery
>;
export type AccountsV2ByProviderQueryResult = Apollo.QueryResult<
  AccountsV2ByProviderQuery,
  AccountsV2ByProviderQueryVariables
>;
export const DriftsDocument = gql`
  query Drifts($input: EstateAllocationDriftsInput!) {
    estateAllocationDrifts(input: $input) {
      count
      pages
      records {
        ...Drift
      }
    }
  }
  ${DriftFragmentDoc}
`;

/**
 * __useDriftsQuery__
 *
 * To run a query within a React component, call `useDriftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriftsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDriftsQuery(
  baseOptions: Apollo.QueryHookOptions<DriftsQuery, DriftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriftsQuery, DriftsQueryVariables>(DriftsDocument, options);
}
export function useDriftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DriftsQuery, DriftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriftsQuery, DriftsQueryVariables>(DriftsDocument, options);
}
export type DriftsQueryHookResult = ReturnType<typeof useDriftsQuery>;
export type DriftsLazyQueryHookResult = ReturnType<typeof useDriftsLazyQuery>;
export type DriftsQueryResult = Apollo.QueryResult<DriftsQuery, DriftsQueryVariables>;
export const UpdateDriftsDocument = gql`
  mutation UpdateDrifts($ids: [String], $accept: Boolean) {
    updateDriftStatuses(ids: $ids, accept: $accept)
  }
`;
export type UpdateDriftsMutationFn = Apollo.MutationFunction<
  UpdateDriftsMutation,
  UpdateDriftsMutationVariables
>;

/**
 * __useUpdateDriftsMutation__
 *
 * To run a mutation, you first call `useUpdateDriftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriftsMutation, { data, loading, error }] = useUpdateDriftsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      accept: // value for 'accept'
 *   },
 * });
 */
export function useUpdateDriftsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDriftsMutation, UpdateDriftsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDriftsMutation, UpdateDriftsMutationVariables>(
    UpdateDriftsDocument,
    options
  );
}
export type UpdateDriftsMutationHookResult = ReturnType<typeof useUpdateDriftsMutation>;
export type UpdateDriftsMutationResult = Apollo.MutationResult<UpdateDriftsMutation>;
export type UpdateDriftsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriftsMutation,
  UpdateDriftsMutationVariables
>;
export const ResolveDriftsDocument = gql`
  mutation ResolveDrifts($input: ResolveDriftsInput!) {
    resolveDrifts(input: $input) {
      count
      pages
      records {
        ...Drift
      }
    }
  }
  ${DriftFragmentDoc}
`;
export type ResolveDriftsMutationFn = Apollo.MutationFunction<
  ResolveDriftsMutation,
  ResolveDriftsMutationVariables
>;

/**
 * __useResolveDriftsMutation__
 *
 * To run a mutation, you first call `useResolveDriftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveDriftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveDriftsMutation, { data, loading, error }] = useResolveDriftsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveDriftsMutation(
  baseOptions?: Apollo.MutationHookOptions<ResolveDriftsMutation, ResolveDriftsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResolveDriftsMutation, ResolveDriftsMutationVariables>(
    ResolveDriftsDocument,
    options
  );
}
export type ResolveDriftsMutationHookResult = ReturnType<typeof useResolveDriftsMutation>;
export type ResolveDriftsMutationResult = Apollo.MutationResult<ResolveDriftsMutation>;
export type ResolveDriftsMutationOptions = Apollo.BaseMutationOptions<
  ResolveDriftsMutation,
  ResolveDriftsMutationVariables
>;
export const ResolveAllDriftsDocument = gql`
  mutation ResolveAllDrifts($input: ResolveAllDriftsInput!) {
    resolveAllDrifts(input: $input) {
      success
    }
  }
`;
export type ResolveAllDriftsMutationFn = Apollo.MutationFunction<
  ResolveAllDriftsMutation,
  ResolveAllDriftsMutationVariables
>;

/**
 * __useResolveAllDriftsMutation__
 *
 * To run a mutation, you first call `useResolveAllDriftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveAllDriftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveAllDriftsMutation, { data, loading, error }] = useResolveAllDriftsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveAllDriftsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveAllDriftsMutation,
    ResolveAllDriftsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResolveAllDriftsMutation, ResolveAllDriftsMutationVariables>(
    ResolveAllDriftsDocument,
    options
  );
}
export type ResolveAllDriftsMutationHookResult = ReturnType<typeof useResolveAllDriftsMutation>;
export type ResolveAllDriftsMutationResult = Apollo.MutationResult<ResolveAllDriftsMutation>;
export type ResolveAllDriftsMutationOptions = Apollo.BaseMutationOptions<
  ResolveAllDriftsMutation,
  ResolveAllDriftsMutationVariables
>;
export const GetDiscoveryRulesDocument = gql`
  query GetDiscoveryRules($envId: String!) {
    discoveryRules(environmentId: $envId) {
      ...discoveryRuleFields
    }
  }
  ${DiscoveryRuleFieldsFragmentDoc}
`;

/**
 * __useGetDiscoveryRulesQuery__
 *
 * To run a query within a React component, call `useGetDiscoveryRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoveryRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoveryRulesQuery({
 *   variables: {
 *      envId: // value for 'envId'
 *   },
 * });
 */
export function useGetDiscoveryRulesQuery(
  baseOptions: Apollo.QueryHookOptions<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>(
    GetDiscoveryRulesDocument,
    options
  );
}
export function useGetDiscoveryRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDiscoveryRulesQuery, GetDiscoveryRulesQueryVariables>(
    GetDiscoveryRulesDocument,
    options
  );
}
export type GetDiscoveryRulesQueryHookResult = ReturnType<typeof useGetDiscoveryRulesQuery>;
export type GetDiscoveryRulesLazyQueryHookResult = ReturnType<typeof useGetDiscoveryRulesLazyQuery>;
export type GetDiscoveryRulesQueryResult = Apollo.QueryResult<
  GetDiscoveryRulesQuery,
  GetDiscoveryRulesQueryVariables
>;
export const AddDiscoveryRuleDocument = gql`
  mutation AddDiscoveryRule($input: AddDiscoveryRule!) {
    addDiscoveryRuleV2(input: $input) {
      ...discoveryRuleFields
    }
  }
  ${DiscoveryRuleFieldsFragmentDoc}
`;
export type AddDiscoveryRuleMutationFn = Apollo.MutationFunction<
  AddDiscoveryRuleMutation,
  AddDiscoveryRuleMutationVariables
>;

/**
 * __useAddDiscoveryRuleMutation__
 *
 * To run a mutation, you first call `useAddDiscoveryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiscoveryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiscoveryRuleMutation, { data, loading, error }] = useAddDiscoveryRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDiscoveryRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDiscoveryRuleMutation,
    AddDiscoveryRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDiscoveryRuleMutation, AddDiscoveryRuleMutationVariables>(
    AddDiscoveryRuleDocument,
    options
  );
}
export type AddDiscoveryRuleMutationHookResult = ReturnType<typeof useAddDiscoveryRuleMutation>;
export type AddDiscoveryRuleMutationResult = Apollo.MutationResult<AddDiscoveryRuleMutation>;
export type AddDiscoveryRuleMutationOptions = Apollo.BaseMutationOptions<
  AddDiscoveryRuleMutation,
  AddDiscoveryRuleMutationVariables
>;
export const RemoveDiscoveryRuleDocument = gql`
  mutation RemoveDiscoveryRule($id: String!) {
    removeDiscoveryRule(id: $id)
  }
`;
export type RemoveDiscoveryRuleMutationFn = Apollo.MutationFunction<
  RemoveDiscoveryRuleMutation,
  RemoveDiscoveryRuleMutationVariables
>;

/**
 * __useRemoveDiscoveryRuleMutation__
 *
 * To run a mutation, you first call `useRemoveDiscoveryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDiscoveryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDiscoveryRuleMutation, { data, loading, error }] = useRemoveDiscoveryRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDiscoveryRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDiscoveryRuleMutation,
    RemoveDiscoveryRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDiscoveryRuleMutation, RemoveDiscoveryRuleMutationVariables>(
    RemoveDiscoveryRuleDocument,
    options
  );
}
export type RemoveDiscoveryRuleMutationHookResult = ReturnType<
  typeof useRemoveDiscoveryRuleMutation
>;
export type RemoveDiscoveryRuleMutationResult = Apollo.MutationResult<RemoveDiscoveryRuleMutation>;
export type RemoveDiscoveryRuleMutationOptions = Apollo.BaseMutationOptions<
  RemoveDiscoveryRuleMutation,
  RemoveDiscoveryRuleMutationVariables
>;
export const UpdateDiscoveryRuleDocument = gql`
  mutation UpdateDiscoveryRule($input: UpdateDiscoveryRule!) {
    updateDiscoveryRuleV2(input: $input) {
      ...discoveryRuleFields
    }
  }
  ${DiscoveryRuleFieldsFragmentDoc}
`;
export type UpdateDiscoveryRuleMutationFn = Apollo.MutationFunction<
  UpdateDiscoveryRuleMutation,
  UpdateDiscoveryRuleMutationVariables
>;

/**
 * __useUpdateDiscoveryRuleMutation__
 *
 * To run a mutation, you first call `useUpdateDiscoveryRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscoveryRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscoveryRuleMutation, { data, loading, error }] = useUpdateDiscoveryRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDiscoveryRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDiscoveryRuleMutation,
    UpdateDiscoveryRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDiscoveryRuleMutation, UpdateDiscoveryRuleMutationVariables>(
    UpdateDiscoveryRuleDocument,
    options
  );
}
export type UpdateDiscoveryRuleMutationHookResult = ReturnType<
  typeof useUpdateDiscoveryRuleMutation
>;
export type UpdateDiscoveryRuleMutationResult = Apollo.MutationResult<UpdateDiscoveryRuleMutation>;
export type UpdateDiscoveryRuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateDiscoveryRuleMutation,
  UpdateDiscoveryRuleMutationVariables
>;
export const EnvironmentDocument = gql`
  query Environment($id: String!, $year: String!) {
    environment(id: $id) {
      id
      nid
      name
      budgetYearly(year: $year) {
        id
        budgetByMonth
      }
      cloudWasteAggregate {
        hasWaste
        cost
        co2e
        count
      }
      cost {
        currentMonth
        previousMonth
        forecast
        currentMonthDaily
      }
      co2e(timePeriod: CURRENTMONTH)
      owner {
        id
      }
      environmentType
      application {
        id
        nid
        name
      }
      contacts {
        id
        name
        email
      }
      description
    }
  }
`;

/**
 * __useEnvironmentQuery__
 *
 * To run a query within a React component, call `useEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useEnvironmentQuery(
  baseOptions: Apollo.QueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnvironmentQuery, EnvironmentQueryVariables>(EnvironmentDocument, options);
}
export function useEnvironmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentQuery, EnvironmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnvironmentQuery, EnvironmentQueryVariables>(
    EnvironmentDocument,
    options
  );
}
export type EnvironmentQueryHookResult = ReturnType<typeof useEnvironmentQuery>;
export type EnvironmentLazyQueryHookResult = ReturnType<typeof useEnvironmentLazyQuery>;
export type EnvironmentQueryResult = Apollo.QueryResult<
  EnvironmentQuery,
  EnvironmentQueryVariables
>;
export const EnvironmentContactsDocument = gql`
  query EnvironmentContacts($id: String!) {
    environment(id: $id) {
      id
      name
      owner {
        id
        name
      }
      description
      contacts {
        id
        name
      }
    }
  }
`;

/**
 * __useEnvironmentContactsQuery__
 *
 * To run a query within a React component, call `useEnvironmentContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnvironmentContactsQuery(
  baseOptions: Apollo.QueryHookOptions<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>(
    EnvironmentContactsDocument,
    options
  );
}
export function useEnvironmentContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnvironmentContactsQuery,
    EnvironmentContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnvironmentContactsQuery, EnvironmentContactsQueryVariables>(
    EnvironmentContactsDocument,
    options
  );
}
export type EnvironmentContactsQueryHookResult = ReturnType<typeof useEnvironmentContactsQuery>;
export type EnvironmentContactsLazyQueryHookResult = ReturnType<
  typeof useEnvironmentContactsLazyQuery
>;
export type EnvironmentContactsQueryResult = Apollo.QueryResult<
  EnvironmentContactsQuery,
  EnvironmentContactsQueryVariables
>;
export const EnvironmentKpiDocument = gql`
  query EnvironmentKPI(
    $envId: String!
    $timePeriod: KpiTimePeriod!
    $bcId: ID!
    $fetchOverallWaste: Boolean!
    $fetchNonProdHours: Boolean!
  ) {
    environment(id: $envId) {
      id
      nid
      summaryKpis: kpis(bcId: $bcId) {
        nonProdHours {
          details(timePeriod: $timePeriod) {
            ...SummaryKpiDetails
          }
        }
        overallWaste {
          details(timePeriod: $timePeriod) {
            ...SummaryKpiDetails
          }
        }
      }
      kpis(bcId: $bcId) {
        nonProdHours @include(if: $fetchNonProdHours) {
          details(timePeriod: $timePeriod) {
            ...KpiDetails
          }
        }
        overallWaste @include(if: $fetchOverallWaste) {
          details(timePeriod: $timePeriod) {
            ...KpiDetails
          }
        }
      }
    }
  }
  ${SummaryKpiDetailsFragmentDoc}
  ${KpiDetailsFragmentDoc}
`;

/**
 * __useEnvironmentKpiQuery__
 *
 * To run a query within a React component, call `useEnvironmentKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentKpiQuery({
 *   variables: {
 *      envId: // value for 'envId'
 *      timePeriod: // value for 'timePeriod'
 *      bcId: // value for 'bcId'
 *      fetchOverallWaste: // value for 'fetchOverallWaste'
 *      fetchNonProdHours: // value for 'fetchNonProdHours'
 *   },
 * });
 */
export function useEnvironmentKpiQuery(
  baseOptions: Apollo.QueryHookOptions<EnvironmentKpiQuery, EnvironmentKpiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnvironmentKpiQuery, EnvironmentKpiQueryVariables>(
    EnvironmentKpiDocument,
    options
  );
}
export function useEnvironmentKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentKpiQuery, EnvironmentKpiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnvironmentKpiQuery, EnvironmentKpiQueryVariables>(
    EnvironmentKpiDocument,
    options
  );
}
export type EnvironmentKpiQueryHookResult = ReturnType<typeof useEnvironmentKpiQuery>;
export type EnvironmentKpiLazyQueryHookResult = ReturnType<typeof useEnvironmentKpiLazyQuery>;
export type EnvironmentKpiQueryResult = Apollo.QueryResult<
  EnvironmentKpiQuery,
  EnvironmentKpiQueryVariables
>;
export const AddEnvToOrgUnitV2Document = gql`
  mutation AddEnvToOrgUnitV2($envId: ID!, $orgUnitId: ID!) {
    addEnvToOrgUnitV2(envId: $envId, orgUnitId: $orgUnitId)
  }
`;
export type AddEnvToOrgUnitV2MutationFn = Apollo.MutationFunction<
  AddEnvToOrgUnitV2Mutation,
  AddEnvToOrgUnitV2MutationVariables
>;

/**
 * __useAddEnvToOrgUnitV2Mutation__
 *
 * To run a mutation, you first call `useAddEnvToOrgUnitV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEnvToOrgUnitV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEnvToOrgUnitV2Mutation, { data, loading, error }] = useAddEnvToOrgUnitV2Mutation({
 *   variables: {
 *      envId: // value for 'envId'
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useAddEnvToOrgUnitV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEnvToOrgUnitV2Mutation,
    AddEnvToOrgUnitV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddEnvToOrgUnitV2Mutation, AddEnvToOrgUnitV2MutationVariables>(
    AddEnvToOrgUnitV2Document,
    options
  );
}
export type AddEnvToOrgUnitV2MutationHookResult = ReturnType<typeof useAddEnvToOrgUnitV2Mutation>;
export type AddEnvToOrgUnitV2MutationResult = Apollo.MutationResult<AddEnvToOrgUnitV2Mutation>;
export type AddEnvToOrgUnitV2MutationOptions = Apollo.BaseMutationOptions<
  AddEnvToOrgUnitV2Mutation,
  AddEnvToOrgUnitV2MutationVariables
>;
export const RemoveEnvFromOrgUnitV2Document = gql`
  mutation RemoveEnvFromOrgUnitV2($envId: ID!, $orgUnitId: ID!) {
    removeEnvFromOrgUnitV2(envId: $envId, orgUnitId: $orgUnitId)
  }
`;
export type RemoveEnvFromOrgUnitV2MutationFn = Apollo.MutationFunction<
  RemoveEnvFromOrgUnitV2Mutation,
  RemoveEnvFromOrgUnitV2MutationVariables
>;

/**
 * __useRemoveEnvFromOrgUnitV2Mutation__
 *
 * To run a mutation, you first call `useRemoveEnvFromOrgUnitV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnvFromOrgUnitV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnvFromOrgUnitV2Mutation, { data, loading, error }] = useRemoveEnvFromOrgUnitV2Mutation({
 *   variables: {
 *      envId: // value for 'envId'
 *      orgUnitId: // value for 'orgUnitId'
 *   },
 * });
 */
export function useRemoveEnvFromOrgUnitV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEnvFromOrgUnitV2Mutation,
    RemoveEnvFromOrgUnitV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveEnvFromOrgUnitV2Mutation,
    RemoveEnvFromOrgUnitV2MutationVariables
  >(RemoveEnvFromOrgUnitV2Document, options);
}
export type RemoveEnvFromOrgUnitV2MutationHookResult = ReturnType<
  typeof useRemoveEnvFromOrgUnitV2Mutation
>;
export type RemoveEnvFromOrgUnitV2MutationResult =
  Apollo.MutationResult<RemoveEnvFromOrgUnitV2Mutation>;
export type RemoveEnvFromOrgUnitV2MutationOptions = Apollo.BaseMutationOptions<
  RemoveEnvFromOrgUnitV2Mutation,
  RemoveEnvFromOrgUnitV2MutationVariables
>;
export const GetEnvironmentOrgUnitsDocument = gql`
  query GetEnvironmentOrgUnits($id: String!) {
    environment(id: $id) {
      id
      nid
      name
      orgUnitsV2(limit: 50, page: 0) {
        orgUnits {
          id
          nid
          name
          businessContext {
            id
            color
            name
            isPrimary
          }
        }
      }
    }
  }
`;

/**
 * __useGetEnvironmentOrgUnitsQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentOrgUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentOrgUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentOrgUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnvironmentOrgUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnvironmentOrgUnitsQuery,
    GetEnvironmentOrgUnitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnvironmentOrgUnitsQuery, GetEnvironmentOrgUnitsQueryVariables>(
    GetEnvironmentOrgUnitsDocument,
    options
  );
}
export function useGetEnvironmentOrgUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnvironmentOrgUnitsQuery,
    GetEnvironmentOrgUnitsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnvironmentOrgUnitsQuery, GetEnvironmentOrgUnitsQueryVariables>(
    GetEnvironmentOrgUnitsDocument,
    options
  );
}
export type GetEnvironmentOrgUnitsQueryHookResult = ReturnType<
  typeof useGetEnvironmentOrgUnitsQuery
>;
export type GetEnvironmentOrgUnitsLazyQueryHookResult = ReturnType<
  typeof useGetEnvironmentOrgUnitsLazyQuery
>;
export type GetEnvironmentOrgUnitsQueryResult = Apollo.QueryResult<
  GetEnvironmentOrgUnitsQuery,
  GetEnvironmentOrgUnitsQueryVariables
>;
export const EnvironmentBudgetDocument = gql`
  query environmentBudget($id: String!, $year: String!, $previousYear: String!) {
    environment(id: $id) {
      id
      nid
      budgetYearly(year: $year) {
        ...Budget
      }
      spendYearly(year: $previousYear) {
        spends
      }
      forecastSpendYearly(year: $previousYear) {
        spends
      }
    }
  }
  ${BudgetFragmentDoc}
`;

/**
 * __useEnvironmentBudgetQuery__
 *
 * To run a query within a React component, call `useEnvironmentBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentBudgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      year: // value for 'year'
 *      previousYear: // value for 'previousYear'
 *   },
 * });
 */
export function useEnvironmentBudgetQuery(
  baseOptions: Apollo.QueryHookOptions<EnvironmentBudgetQuery, EnvironmentBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnvironmentBudgetQuery, EnvironmentBudgetQueryVariables>(
    EnvironmentBudgetDocument,
    options
  );
}
export function useEnvironmentBudgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentBudgetQuery, EnvironmentBudgetQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnvironmentBudgetQuery, EnvironmentBudgetQueryVariables>(
    EnvironmentBudgetDocument,
    options
  );
}
export type EnvironmentBudgetQueryHookResult = ReturnType<typeof useEnvironmentBudgetQuery>;
export type EnvironmentBudgetLazyQueryHookResult = ReturnType<typeof useEnvironmentBudgetLazyQuery>;
export type EnvironmentBudgetQueryResult = Apollo.QueryResult<
  EnvironmentBudgetQuery,
  EnvironmentBudgetQueryVariables
>;
export const SetEnvironmentYearlyBudgetDocument = gql`
  mutation setEnvironmentYearlyBudget($budget: BudgetYearlyInput!, $nid: ID!) {
    setEnvironmentYearlyBudget(budget: $budget, nid: $nid) {
      ...Budget
    }
  }
  ${BudgetFragmentDoc}
`;
export type SetEnvironmentYearlyBudgetMutationFn = Apollo.MutationFunction<
  SetEnvironmentYearlyBudgetMutation,
  SetEnvironmentYearlyBudgetMutationVariables
>;

/**
 * __useSetEnvironmentYearlyBudgetMutation__
 *
 * To run a mutation, you first call `useSetEnvironmentYearlyBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnvironmentYearlyBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnvironmentYearlyBudgetMutation, { data, loading, error }] = useSetEnvironmentYearlyBudgetMutation({
 *   variables: {
 *      budget: // value for 'budget'
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useSetEnvironmentYearlyBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEnvironmentYearlyBudgetMutation,
    SetEnvironmentYearlyBudgetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEnvironmentYearlyBudgetMutation,
    SetEnvironmentYearlyBudgetMutationVariables
  >(SetEnvironmentYearlyBudgetDocument, options);
}
export type SetEnvironmentYearlyBudgetMutationHookResult = ReturnType<
  typeof useSetEnvironmentYearlyBudgetMutation
>;
export type SetEnvironmentYearlyBudgetMutationResult =
  Apollo.MutationResult<SetEnvironmentYearlyBudgetMutation>;
export type SetEnvironmentYearlyBudgetMutationOptions = Apollo.BaseMutationOptions<
  SetEnvironmentYearlyBudgetMutation,
  SetEnvironmentYearlyBudgetMutationVariables
>;
export const RemoveEnvironmentDocument = gql`
  mutation removeEnvironment($nid: ID!) {
    removeEnvironmentV2(nid: $nid)
  }
`;
export type RemoveEnvironmentMutationFn = Apollo.MutationFunction<
  RemoveEnvironmentMutation,
  RemoveEnvironmentMutationVariables
>;

/**
 * __useRemoveEnvironmentMutation__
 *
 * To run a mutation, you first call `useRemoveEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnvironmentMutation, { data, loading, error }] = useRemoveEnvironmentMutation({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useRemoveEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEnvironmentMutation,
    RemoveEnvironmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveEnvironmentMutation, RemoveEnvironmentMutationVariables>(
    RemoveEnvironmentDocument,
    options
  );
}
export type RemoveEnvironmentMutationHookResult = ReturnType<typeof useRemoveEnvironmentMutation>;
export type RemoveEnvironmentMutationResult = Apollo.MutationResult<RemoveEnvironmentMutation>;
export type RemoveEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveEnvironmentMutation,
  RemoveEnvironmentMutationVariables
>;
export const GetEnvironmentAccumulatedCostDocument = gql`
  query getEnvironmentAccumulatedCost(
    $environmentId: ID!
    $granularity: Granularity
    $startDate: String!
    $endDate: String!
    $tableGranularity: Granularity
  ) {
    environmentAccumulatedCost(
      input: {
        environmentId: $environmentId
        granularity: $granularity
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...EnvAccumulatedCost
    }
    environmentAccumulatedCostTable: environmentAccumulatedCost(
      input: {
        environmentId: $environmentId
        granularity: $tableGranularity
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      ...EnvAccumulatedCost
    }
  }
  ${EnvAccumulatedCostFragmentDoc}
`;

/**
 * __useGetEnvironmentAccumulatedCostQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentAccumulatedCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentAccumulatedCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentAccumulatedCostQuery({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tableGranularity: // value for 'tableGranularity'
 *   },
 * });
 */
export function useGetEnvironmentAccumulatedCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEnvironmentAccumulatedCostQuery,
    GetEnvironmentAccumulatedCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEnvironmentAccumulatedCostQuery,
    GetEnvironmentAccumulatedCostQueryVariables
  >(GetEnvironmentAccumulatedCostDocument, options);
}
export function useGetEnvironmentAccumulatedCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEnvironmentAccumulatedCostQuery,
    GetEnvironmentAccumulatedCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEnvironmentAccumulatedCostQuery,
    GetEnvironmentAccumulatedCostQueryVariables
  >(GetEnvironmentAccumulatedCostDocument, options);
}
export type GetEnvironmentAccumulatedCostQueryHookResult = ReturnType<
  typeof useGetEnvironmentAccumulatedCostQuery
>;
export type GetEnvironmentAccumulatedCostLazyQueryHookResult = ReturnType<
  typeof useGetEnvironmentAccumulatedCostLazyQuery
>;
export type GetEnvironmentAccumulatedCostQueryResult = Apollo.QueryResult<
  GetEnvironmentAccumulatedCostQuery,
  GetEnvironmentAccumulatedCostQueryVariables
>;
export const EnvironmentTopCategoriesCostDocument = gql`
  query EnvironmentTopCategoriesCost(
    $top: Int!
    $environmentId: ID!
    $endDate: String!
    $granularity: Granularity
    $startDate: String!
  ) {
    environmentTopCategoriesCost(
      input: {
        top: $top
        environmentId: $environmentId
        endDate: $endDate
        granularity: $granularity
        startDate: $startDate
      }
    ) {
      total
      timePoints {
        ...TimePoint
      }
    }
  }
  ${TimePointFragmentDoc}
`;

/**
 * __useEnvironmentTopCategoriesCostQuery__
 *
 * To run a query within a React component, call `useEnvironmentTopCategoriesCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentTopCategoriesCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentTopCategoriesCostQuery({
 *   variables: {
 *      top: // value for 'top'
 *      environmentId: // value for 'environmentId'
 *      endDate: // value for 'endDate'
 *      granularity: // value for 'granularity'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useEnvironmentTopCategoriesCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    EnvironmentTopCategoriesCostQuery,
    EnvironmentTopCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EnvironmentTopCategoriesCostQuery,
    EnvironmentTopCategoriesCostQueryVariables
  >(EnvironmentTopCategoriesCostDocument, options);
}
export function useEnvironmentTopCategoriesCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnvironmentTopCategoriesCostQuery,
    EnvironmentTopCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnvironmentTopCategoriesCostQuery,
    EnvironmentTopCategoriesCostQueryVariables
  >(EnvironmentTopCategoriesCostDocument, options);
}
export type EnvironmentTopCategoriesCostQueryHookResult = ReturnType<
  typeof useEnvironmentTopCategoriesCostQuery
>;
export type EnvironmentTopCategoriesCostLazyQueryHookResult = ReturnType<
  typeof useEnvironmentTopCategoriesCostLazyQuery
>;
export type EnvironmentTopCategoriesCostQueryResult = Apollo.QueryResult<
  EnvironmentTopCategoriesCostQuery,
  EnvironmentTopCategoriesCostQueryVariables
>;
export const EnvironmentOtherCategoriesCostDocument = gql`
  query EnvironmentOtherCategoriesCost($input: EnvironmentOtherCategoriesCostInput!) {
    environmentOtherCategoriesCost(input: $input) {
      total
      timePoints {
        date
        value
        groups {
          id
          name
          value
        }
      }
    }
  }
`;

/**
 * __useEnvironmentOtherCategoriesCostQuery__
 *
 * To run a query within a React component, call `useEnvironmentOtherCategoriesCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentOtherCategoriesCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentOtherCategoriesCostQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnvironmentOtherCategoriesCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    EnvironmentOtherCategoriesCostQuery,
    EnvironmentOtherCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EnvironmentOtherCategoriesCostQuery,
    EnvironmentOtherCategoriesCostQueryVariables
  >(EnvironmentOtherCategoriesCostDocument, options);
}
export function useEnvironmentOtherCategoriesCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnvironmentOtherCategoriesCostQuery,
    EnvironmentOtherCategoriesCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnvironmentOtherCategoriesCostQuery,
    EnvironmentOtherCategoriesCostQueryVariables
  >(EnvironmentOtherCategoriesCostDocument, options);
}
export type EnvironmentOtherCategoriesCostQueryHookResult = ReturnType<
  typeof useEnvironmentOtherCategoriesCostQuery
>;
export type EnvironmentOtherCategoriesCostLazyQueryHookResult = ReturnType<
  typeof useEnvironmentOtherCategoriesCostLazyQuery
>;
export type EnvironmentOtherCategoriesCostQueryResult = Apollo.QueryResult<
  EnvironmentOtherCategoriesCostQuery,
  EnvironmentOtherCategoriesCostQueryVariables
>;
export const CostResourceEnvironmentsDocument = gql`
  query CostResourceEnvironments($id: String!, $limit: Int!, $page: Int) {
    estateRecord(nid: $id) {
      ... on CostResource {
        id
        environmentsPaginated(limit: $limit, page: $page) {
          environments {
            cost {
              currentMonth
              previousMonth
              forecast
            }
            environment {
              name
              id
              application {
                name
                id
              }
            }
          }
          count
          pages
        }
      }
    }
  }
`;

/**
 * __useCostResourceEnvironmentsQuery__
 *
 * To run a query within a React component, call `useCostResourceEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostResourceEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostResourceEnvironmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCostResourceEnvironmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CostResourceEnvironmentsQuery,
    CostResourceEnvironmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostResourceEnvironmentsQuery, CostResourceEnvironmentsQueryVariables>(
    CostResourceEnvironmentsDocument,
    options
  );
}
export function useCostResourceEnvironmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CostResourceEnvironmentsQuery,
    CostResourceEnvironmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostResourceEnvironmentsQuery, CostResourceEnvironmentsQueryVariables>(
    CostResourceEnvironmentsDocument,
    options
  );
}
export type CostResourceEnvironmentsQueryHookResult = ReturnType<
  typeof useCostResourceEnvironmentsQuery
>;
export type CostResourceEnvironmentsLazyQueryHookResult = ReturnType<
  typeof useCostResourceEnvironmentsLazyQuery
>;
export type CostResourceEnvironmentsQueryResult = Apollo.QueryResult<
  CostResourceEnvironmentsQuery,
  CostResourceEnvironmentsQueryVariables
>;
export const ResourceDocument = gql`
  query Resource($id: String!, $isLite: Boolean!) {
    estateRecord(nid: $id) {
      ... on CloudResource {
        id
        provider
        providerId
        providerType
        name
        region
        drifted @skip(if: $isLite)
        cloudAccountId
        createdAt
        validThrough
        cloudWaste @skip(if: $isLite) {
          isWaste
          reason
        }
        pendingAssignment @skip(if: $isLite) {
          id
          name
          application {
            name
            id
          }
        }
        cost {
          currentMonth
          previousMonth
          forecast
          originalCost {
            currentMonth
          }
          currentMonthLastSpendDate
        }
        co2e {
          currentMonth
          previousMonth
          forecast
        }
        costSavings @skip(if: $isLite) {
          problem
          cost
          co2e
          cloudUrl
          action
          annualCost
          detectionId
          policyId
          metadata {
            currentInstanceType
            recommendedInstanceType
          }
        }
        savingsAmount @skip(if: $isLite) {
          amount
          co2e
          count
        }
        environment @skip(if: $isLite) {
          name
          id
          application {
            name
            id
          }
        }
        metadataAndTags {
          tags {
            key
            value
          }
          metadata {
            key
            value
          }
        }
        reservation @skip(if: $isLite) {
          from
          to
        }
        links {
          sourceRecord {
            ... on CloudResource {
              id
              name
              provider
              providerId
              cloudAccountId
              providerType
            }
          }
          targetRecord {
            ... on CloudResource {
              id
              name
              provider
              providerId
              cloudAccountId
              providerType
            }
          }
        }
        enrichments @skip(if: $isLite) {
          integration {
            id
            name
          }
          data {
            key
            value
          }
        }
      }
      ... on CostResource {
        id
        provider
        providerType
        cost {
          currentMonth
          previousMonth
          forecast
        }
        co2e {
          currentMonth
          previousMonth
          forecast
        }
        metadataAndTags {
          tags {
            key
            value
          }
          metadata {
            key
            value
          }
        }
      }
    }
  }
`;

/**
 * __useResourceQuery__
 *
 * To run a query within a React component, call `useResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isLite: // value for 'isLite'
 *   },
 * });
 */
export function useResourceQuery(
  baseOptions: Apollo.QueryHookOptions<ResourceQuery, ResourceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, options);
}
export function useResourceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ResourceQuery, ResourceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceQuery, ResourceQueryVariables>(ResourceDocument, options);
}
export type ResourceQueryHookResult = ReturnType<typeof useResourceQuery>;
export type ResourceLazyQueryHookResult = ReturnType<typeof useResourceLazyQuery>;
export type ResourceQueryResult = Apollo.QueryResult<ResourceQuery, ResourceQueryVariables>;
export const ReservationDetailsDocument = gql`
  query ReservationDetails($reservationId: String!) {
    reservationDetails(reservationId: $reservationId) {
      dailyUtilization {
        utilization
        benefit
        forecast
        timeSeries {
          date
          value
          benefit
        }
      }
      monthlyUtilization {
        utilization
        benefit
        forecast
        timeSeries {
          date
          value
          benefit
        }
      }
      from
      to
      instanceType
      purchaseOption
      paymentOption
      upfrontCost
      monthlyCost
      billingPlan
      skuName
    }
  }
`;

/**
 * __useReservationDetailsQuery__
 *
 * To run a query within a React component, call `useReservationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationDetailsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useReservationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ReservationDetailsQuery, ReservationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationDetailsQuery, ReservationDetailsQueryVariables>(
    ReservationDetailsDocument,
    options
  );
}
export function useReservationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationDetailsQuery,
    ReservationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationDetailsQuery, ReservationDetailsQueryVariables>(
    ReservationDetailsDocument,
    options
  );
}
export type ReservationDetailsQueryHookResult = ReturnType<typeof useReservationDetailsQuery>;
export type ReservationDetailsLazyQueryHookResult = ReturnType<
  typeof useReservationDetailsLazyQuery
>;
export type ReservationDetailsQueryResult = Apollo.QueryResult<
  ReservationDetailsQuery,
  ReservationDetailsQueryVariables
>;
export const ListReservationCoveredInstancesDocument = gql`
  query ListReservationCoveredInstances($reservationId: String!, $page: Int!, $limit: Int!) {
    listReservationCoveredInstances(reservationId: $reservationId, page: $page, limit: $limit) {
      pages
      count
      instances {
        id
        nid
        provider
        type
        name
        account
        location
        environment {
          id
          nid
          name
          application {
            id
            nid
            name
          }
        }
        benefit
      }
    }
  }
`;

/**
 * __useListReservationCoveredInstancesQuery__
 *
 * To run a query within a React component, call `useListReservationCoveredInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReservationCoveredInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReservationCoveredInstancesQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListReservationCoveredInstancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListReservationCoveredInstancesQuery,
    ListReservationCoveredInstancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListReservationCoveredInstancesQuery,
    ListReservationCoveredInstancesQueryVariables
  >(ListReservationCoveredInstancesDocument, options);
}
export function useListReservationCoveredInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListReservationCoveredInstancesQuery,
    ListReservationCoveredInstancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListReservationCoveredInstancesQuery,
    ListReservationCoveredInstancesQueryVariables
  >(ListReservationCoveredInstancesDocument, options);
}
export type ListReservationCoveredInstancesQueryHookResult = ReturnType<
  typeof useListReservationCoveredInstancesQuery
>;
export type ListReservationCoveredInstancesLazyQueryHookResult = ReturnType<
  typeof useListReservationCoveredInstancesLazyQuery
>;
export type ListReservationCoveredInstancesQueryResult = Apollo.QueryResult<
  ListReservationCoveredInstancesQuery,
  ListReservationCoveredInstancesQueryVariables
>;
export const ResourceConsumptionDocument = gql`
  query ResourceConsumption($id: String!, $type: KubernetesType!) {
    resourceConsumption(nid: $id, type: $type) {
      cpu {
        date
        used
        requested
      }
      memory {
        date
        used
        requested
      }
      storageRw {
        date
        read
        write
      }
    }
  }
`;

/**
 * __useResourceConsumptionQuery__
 *
 * To run a query within a React component, call `useResourceConsumptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceConsumptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceConsumptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useResourceConsumptionQuery(
  baseOptions: Apollo.QueryHookOptions<ResourceConsumptionQuery, ResourceConsumptionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceConsumptionQuery, ResourceConsumptionQueryVariables>(
    ResourceConsumptionDocument,
    options
  );
}
export function useResourceConsumptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceConsumptionQuery,
    ResourceConsumptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceConsumptionQuery, ResourceConsumptionQueryVariables>(
    ResourceConsumptionDocument,
    options
  );
}
export type ResourceConsumptionQueryHookResult = ReturnType<typeof useResourceConsumptionQuery>;
export type ResourceConsumptionLazyQueryHookResult = ReturnType<
  typeof useResourceConsumptionLazyQuery
>;
export type ResourceConsumptionQueryResult = Apollo.QueryResult<
  ResourceConsumptionQuery,
  ResourceConsumptionQueryVariables
>;
export const CreateLinkDocument = gql`
  mutation CreateLink($sourceNid: String!, $targetNid: String!) {
    createLink(link: { sourceNid: $sourceNid, targetNid: $targetNid }) {
      sourceRecord {
        ... on CloudResource {
          id
          name
        }
      }
      targetRecord {
        ... on CloudResource {
          id
          name
        }
      }
      effectiveAt
    }
  }
`;
export type CreateLinkMutationFn = Apollo.MutationFunction<
  CreateLinkMutation,
  CreateLinkMutationVariables
>;

/**
 * __useCreateLinkMutation__
 *
 * To run a mutation, you first call `useCreateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkMutation, { data, loading, error }] = useCreateLinkMutation({
 *   variables: {
 *      sourceNid: // value for 'sourceNid'
 *      targetNid: // value for 'targetNid'
 *   },
 * });
 */
export function useCreateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLinkMutation, CreateLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLinkMutation, CreateLinkMutationVariables>(
    CreateLinkDocument,
    options
  );
}
export type CreateLinkMutationHookResult = ReturnType<typeof useCreateLinkMutation>;
export type CreateLinkMutationResult = Apollo.MutationResult<CreateLinkMutation>;
export type CreateLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateLinkMutation,
  CreateLinkMutationVariables
>;
export const UpdateLinkDocument = gql`
  mutation UpdateLink($oldLink: LinkInput!, $newLink: LinkInput!) {
    updateLink(oldLink: $oldLink, newLink: $newLink)
  }
`;
export type UpdateLinkMutationFn = Apollo.MutationFunction<
  UpdateLinkMutation,
  UpdateLinkMutationVariables
>;

/**
 * __useUpdateLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkMutation, { data, loading, error }] = useUpdateLinkMutation({
 *   variables: {
 *      oldLink: // value for 'oldLink'
 *      newLink: // value for 'newLink'
 *   },
 * });
 */
export function useUpdateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLinkMutation, UpdateLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLinkMutation, UpdateLinkMutationVariables>(
    UpdateLinkDocument,
    options
  );
}
export type UpdateLinkMutationHookResult = ReturnType<typeof useUpdateLinkMutation>;
export type UpdateLinkMutationResult = Apollo.MutationResult<UpdateLinkMutation>;
export type UpdateLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateLinkMutation,
  UpdateLinkMutationVariables
>;
export const DeleteLinkDocument = gql`
  mutation DeleteLink($sourceNid: String!, $targetNid: String!) {
    deleteLink(link: { sourceNid: $sourceNid, targetNid: $targetNid })
  }
`;
export type DeleteLinkMutationFn = Apollo.MutationFunction<
  DeleteLinkMutation,
  DeleteLinkMutationVariables
>;

/**
 * __useDeleteLinkMutation__
 *
 * To run a mutation, you first call `useDeleteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkMutation, { data, loading, error }] = useDeleteLinkMutation({
 *   variables: {
 *      sourceNid: // value for 'sourceNid'
 *      targetNid: // value for 'targetNid'
 *   },
 * });
 */
export function useDeleteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLinkMutation, DeleteLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLinkMutation, DeleteLinkMutationVariables>(
    DeleteLinkDocument,
    options
  );
}
export type DeleteLinkMutationHookResult = ReturnType<typeof useDeleteLinkMutation>;
export type DeleteLinkMutationResult = Apollo.MutationResult<DeleteLinkMutation>;
export type DeleteLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteLinkMutation,
  DeleteLinkMutationVariables
>;
export const LinkedRecordsDocument = gql`
  mutation linkedRecords($unlink: [LinkInput!], $link: [LinkInput!]) {
    linkedRecords(unlink: $unlink, link: $link)
  }
`;
export type LinkedRecordsMutationFn = Apollo.MutationFunction<
  LinkedRecordsMutation,
  LinkedRecordsMutationVariables
>;

/**
 * __useLinkedRecordsMutation__
 *
 * To run a mutation, you first call `useLinkedRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkedRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkedRecordsMutation, { data, loading, error }] = useLinkedRecordsMutation({
 *   variables: {
 *      unlink: // value for 'unlink'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useLinkedRecordsMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkedRecordsMutation, LinkedRecordsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkedRecordsMutation, LinkedRecordsMutationVariables>(
    LinkedRecordsDocument,
    options
  );
}
export type LinkedRecordsMutationHookResult = ReturnType<typeof useLinkedRecordsMutation>;
export type LinkedRecordsMutationResult = Apollo.MutationResult<LinkedRecordsMutation>;
export type LinkedRecordsMutationOptions = Apollo.BaseMutationOptions<
  LinkedRecordsMutation,
  LinkedRecordsMutationVariables
>;
export const AddSyntheticEstateRecordDocument = gql`
  mutation addSyntheticEstateRecord($input: AddSyntheticEstateRecord!) {
    addSyntheticEstateRecord(input: $input) {
      id
    }
  }
`;
export type AddSyntheticEstateRecordMutationFn = Apollo.MutationFunction<
  AddSyntheticEstateRecordMutation,
  AddSyntheticEstateRecordMutationVariables
>;

/**
 * __useAddSyntheticEstateRecordMutation__
 *
 * To run a mutation, you first call `useAddSyntheticEstateRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSyntheticEstateRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSyntheticEstateRecordMutation, { data, loading, error }] = useAddSyntheticEstateRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSyntheticEstateRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSyntheticEstateRecordMutation,
    AddSyntheticEstateRecordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSyntheticEstateRecordMutation,
    AddSyntheticEstateRecordMutationVariables
  >(AddSyntheticEstateRecordDocument, options);
}
export type AddSyntheticEstateRecordMutationHookResult = ReturnType<
  typeof useAddSyntheticEstateRecordMutation
>;
export type AddSyntheticEstateRecordMutationResult =
  Apollo.MutationResult<AddSyntheticEstateRecordMutation>;
export type AddSyntheticEstateRecordMutationOptions = Apollo.BaseMutationOptions<
  AddSyntheticEstateRecordMutation,
  AddSyntheticEstateRecordMutationVariables
>;
export const UpdateCostSavingDocument = gql`
  mutation updateCostSaving(
    $detectionId: String!
    $state: RecommendationState!
    $comment: String!
  ) {
    updateCostSavings(input: { detectionId: $detectionId, state: $state, comment: $comment })
  }
`;
export type UpdateCostSavingMutationFn = Apollo.MutationFunction<
  UpdateCostSavingMutation,
  UpdateCostSavingMutationVariables
>;

/**
 * __useUpdateCostSavingMutation__
 *
 * To run a mutation, you first call `useUpdateCostSavingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostSavingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostSavingMutation, { data, loading, error }] = useUpdateCostSavingMutation({
 *   variables: {
 *      detectionId: // value for 'detectionId'
 *      state: // value for 'state'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateCostSavingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostSavingMutation,
    UpdateCostSavingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCostSavingMutation, UpdateCostSavingMutationVariables>(
    UpdateCostSavingDocument,
    options
  );
}
export type UpdateCostSavingMutationHookResult = ReturnType<typeof useUpdateCostSavingMutation>;
export type UpdateCostSavingMutationResult = Apollo.MutationResult<UpdateCostSavingMutation>;
export type UpdateCostSavingMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostSavingMutation,
  UpdateCostSavingMutationVariables
>;
export const ReservationUtilizationDocument = gql`
  query ReservationUtilization(
    $reservationId: String!
    $timePeriod: ReservationUtilizationTimePeriod!
  ) {
    reservationUtilization(reservationId: $reservationId, timePeriod: $timePeriod) {
      monthlyUtilization {
        utilization
        benefit
        forecast
        timeSeries {
          date
          value
          benefit
        }
      }
    }
  }
`;

/**
 * __useReservationUtilizationQuery__
 *
 * To run a query within a React component, call `useReservationUtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationUtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationUtilizationQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      timePeriod: // value for 'timePeriod'
 *   },
 * });
 */
export function useReservationUtilizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationUtilizationQuery,
    ReservationUtilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationUtilizationQuery, ReservationUtilizationQueryVariables>(
    ReservationUtilizationDocument,
    options
  );
}
export function useReservationUtilizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationUtilizationQuery,
    ReservationUtilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationUtilizationQuery, ReservationUtilizationQueryVariables>(
    ReservationUtilizationDocument,
    options
  );
}
export type ReservationUtilizationQueryHookResult = ReturnType<
  typeof useReservationUtilizationQuery
>;
export type ReservationUtilizationLazyQueryHookResult = ReturnType<
  typeof useReservationUtilizationLazyQuery
>;
export type ReservationUtilizationQueryResult = Apollo.QueryResult<
  ReservationUtilizationQuery,
  ReservationUtilizationQueryVariables
>;
export const SkuCostsDocument = gql`
  query SkuCosts($nid: String!, $providers: [Provider]) {
    skuCosts(nid: $nid, providers: $providers) {
      currentMonth {
        value
        name
      }
    }
  }
`;

/**
 * __useSkuCostsQuery__
 *
 * To run a query within a React component, call `useSkuCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuCostsQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *      providers: // value for 'providers'
 *   },
 * });
 */
export function useSkuCostsQuery(
  baseOptions: Apollo.QueryHookOptions<SkuCostsQuery, SkuCostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SkuCostsQuery, SkuCostsQueryVariables>(SkuCostsDocument, options);
}
export function useSkuCostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SkuCostsQuery, SkuCostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SkuCostsQuery, SkuCostsQueryVariables>(SkuCostsDocument, options);
}
export type SkuCostsQueryHookResult = ReturnType<typeof useSkuCostsQuery>;
export type SkuCostsLazyQueryHookResult = ReturnType<typeof useSkuCostsLazyQuery>;
export type SkuCostsQueryResult = Apollo.QueryResult<SkuCostsQuery, SkuCostsQueryVariables>;
export const ApplicationsPaginatedByRecordNidDocument = gql`
  query ApplicationsPaginatedByRecordNid(
    $limit: Int!
    $page: Int!
    $year: String!
    $estateRecordNid: String
  ) {
    applicationsPaginated(limit: $limit, page: $page, estateRecordNid: $estateRecordNid) {
      pages
      count
      results {
        name
        id
        hasAnomalies
        budgetYearly(year: $year) {
          id
          budgetByMonth
          yearlySum
        }
        cloudWasteAggregate {
          hasWaste
          cost
          count
          co2e
        }
        co2e(timePeriod: CURRENTMONTH)
        cost {
          currentMonth
          previousMonth
          forecast
          currentMonthDaily
        }
        environments {
          id
          ...ApplicationEnvironment
        }
      }
    }
  }
  ${ApplicationEnvironmentFragmentDoc}
`;

/**
 * __useApplicationsPaginatedByRecordNidQuery__
 *
 * To run a query within a React component, call `useApplicationsPaginatedByRecordNidQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsPaginatedByRecordNidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsPaginatedByRecordNidQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      year: // value for 'year'
 *      estateRecordNid: // value for 'estateRecordNid'
 *   },
 * });
 */
export function useApplicationsPaginatedByRecordNidQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsPaginatedByRecordNidQuery,
    ApplicationsPaginatedByRecordNidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationsPaginatedByRecordNidQuery,
    ApplicationsPaginatedByRecordNidQueryVariables
  >(ApplicationsPaginatedByRecordNidDocument, options);
}
export function useApplicationsPaginatedByRecordNidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsPaginatedByRecordNidQuery,
    ApplicationsPaginatedByRecordNidQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationsPaginatedByRecordNidQuery,
    ApplicationsPaginatedByRecordNidQueryVariables
  >(ApplicationsPaginatedByRecordNidDocument, options);
}
export type ApplicationsPaginatedByRecordNidQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedByRecordNidQuery
>;
export type ApplicationsPaginatedByRecordNidLazyQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedByRecordNidLazyQuery
>;
export type ApplicationsPaginatedByRecordNidQueryResult = Apollo.QueryResult<
  ApplicationsPaginatedByRecordNidQuery,
  ApplicationsPaginatedByRecordNidQueryVariables
>;
export const Ec2UtilizationDocument = gql`
  query Ec2Utilization(
    $nid: String!
    $startDate: String!
    $endDate: String!
    $granulation: EC2MetricGranulation!
    $volumeId: String!
  ) {
    estateRecord(nid: $nid) {
      ... on CloudResource {
        id
        ec2UsageMetrics(
          startDate: $startDate
          endDate: $endDate
          granulation: $granulation
          volumeId: $volumeId
        ) {
          processor {
            utilization {
              ...TimePointWithUnit
            }
          }
          memory {
            utilization {
              ...TimePointWithUnit
            }
          }
          network {
            inBytes {
              ...TimePointWithUnit
            }
            outBytes {
              ...TimePointWithUnit
            }
          }
          disk {
            readBytes {
              ...TimePointWithUnit
            }
            writeBytes {
              ...TimePointWithUnit
            }
          }
        }
      }
    }
  }
  ${TimePointWithUnitFragmentDoc}
`;

/**
 * __useEc2UtilizationQuery__
 *
 * To run a query within a React component, call `useEc2UtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEc2UtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEc2UtilizationQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      granulation: // value for 'granulation'
 *      volumeId: // value for 'volumeId'
 *   },
 * });
 */
export function useEc2UtilizationQuery(
  baseOptions: Apollo.QueryHookOptions<Ec2UtilizationQuery, Ec2UtilizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Ec2UtilizationQuery, Ec2UtilizationQueryVariables>(
    Ec2UtilizationDocument,
    options
  );
}
export function useEc2UtilizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Ec2UtilizationQuery, Ec2UtilizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Ec2UtilizationQuery, Ec2UtilizationQueryVariables>(
    Ec2UtilizationDocument,
    options
  );
}
export type Ec2UtilizationQueryHookResult = ReturnType<typeof useEc2UtilizationQuery>;
export type Ec2UtilizationLazyQueryHookResult = ReturnType<typeof useEc2UtilizationLazyQuery>;
export type Ec2UtilizationQueryResult = Apollo.QueryResult<
  Ec2UtilizationQuery,
  Ec2UtilizationQueryVariables
>;
export const Ec2DiskUtilizationDocument = gql`
  query Ec2DiskUtilization(
    $nid: String!
    $startDate: String!
    $endDate: String!
    $granulation: EC2MetricGranulation!
    $volumeId: String!
  ) {
    estateRecord(nid: $nid) {
      ... on CloudResource {
        id
        ec2UsageMetrics(
          startDate: $startDate
          endDate: $endDate
          granulation: $granulation
          volumeId: $volumeId
        ) {
          disk {
            readBytes {
              ...TimePointWithUnit
            }
            writeBytes {
              ...TimePointWithUnit
            }
          }
        }
      }
    }
  }
  ${TimePointWithUnitFragmentDoc}
`;

/**
 * __useEc2DiskUtilizationQuery__
 *
 * To run a query within a React component, call `useEc2DiskUtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEc2DiskUtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEc2DiskUtilizationQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      granulation: // value for 'granulation'
 *      volumeId: // value for 'volumeId'
 *   },
 * });
 */
export function useEc2DiskUtilizationQuery(
  baseOptions: Apollo.QueryHookOptions<Ec2DiskUtilizationQuery, Ec2DiskUtilizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Ec2DiskUtilizationQuery, Ec2DiskUtilizationQueryVariables>(
    Ec2DiskUtilizationDocument,
    options
  );
}
export function useEc2DiskUtilizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Ec2DiskUtilizationQuery,
    Ec2DiskUtilizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Ec2DiskUtilizationQuery, Ec2DiskUtilizationQueryVariables>(
    Ec2DiskUtilizationDocument,
    options
  );
}
export type Ec2DiskUtilizationQueryHookResult = ReturnType<typeof useEc2DiskUtilizationQuery>;
export type Ec2DiskUtilizationLazyQueryHookResult = ReturnType<
  typeof useEc2DiskUtilizationLazyQuery
>;
export type Ec2DiskUtilizationQueryResult = Apollo.QueryResult<
  Ec2DiskUtilizationQuery,
  Ec2DiskUtilizationQueryVariables
>;
export const Ec2InstanceComparisonDocument = gql`
  query Ec2InstanceComparison(
    $nid: String!
    $type: String!
    $usageOperation: String!
    $region: String!
    $tenancy: EC2Tenancy
    $instanceFamily: EC2InstanceFamily!
    $instancesFromSavingsSuggestions: [String!]
  ) {
    estateRecord(nid: $nid) {
      ... on CloudResource {
        id
        ec2InstanceComparator(
          type: $type
          usageOperation: $usageOperation
          region: $region
          tenancy: $tenancy
          instanceFamily: $instanceFamily
          instancesFromSavingsSuggestions: $instancesFromSavingsSuggestions
        ) {
          instanceType
          instanceFamily
          memory
          vCPU
          storage
          networkPerformance
          region
          priceHourly
          priceDaily
          priceMonthly
          operatingSystem
          isInstanceComparedTo
          isInstanceFromSavingsSuggestions
        }
      }
    }
  }
`;

/**
 * __useEc2InstanceComparisonQuery__
 *
 * To run a query within a React component, call `useEc2InstanceComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useEc2InstanceComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEc2InstanceComparisonQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *      type: // value for 'type'
 *      usageOperation: // value for 'usageOperation'
 *      region: // value for 'region'
 *      tenancy: // value for 'tenancy'
 *      instanceFamily: // value for 'instanceFamily'
 *      instancesFromSavingsSuggestions: // value for 'instancesFromSavingsSuggestions'
 *   },
 * });
 */
export function useEc2InstanceComparisonQuery(
  baseOptions: Apollo.QueryHookOptions<
    Ec2InstanceComparisonQuery,
    Ec2InstanceComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Ec2InstanceComparisonQuery, Ec2InstanceComparisonQueryVariables>(
    Ec2InstanceComparisonDocument,
    options
  );
}
export function useEc2InstanceComparisonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Ec2InstanceComparisonQuery,
    Ec2InstanceComparisonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Ec2InstanceComparisonQuery, Ec2InstanceComparisonQueryVariables>(
    Ec2InstanceComparisonDocument,
    options
  );
}
export type Ec2InstanceComparisonQueryHookResult = ReturnType<typeof useEc2InstanceComparisonQuery>;
export type Ec2InstanceComparisonLazyQueryHookResult = ReturnType<
  typeof useEc2InstanceComparisonLazyQuery
>;
export type Ec2InstanceComparisonQueryResult = Apollo.QueryResult<
  Ec2InstanceComparisonQuery,
  Ec2InstanceComparisonQueryVariables
>;
export const ReservedInstancesRecommendationsDocument = gql`
  query ReservedInstancesRecommendations(
    $resourceType: String!
    $region: String!
    $provider: String!
    $platform: String!
    $payments: [ReservationsRecommendationPaymentOption!]!
    $terms: [ReservationsRecommendationTerm!]!
  ) {
    allReservationsRecommendations(
      resourceType: $resourceType
      region: $region
      provider: $provider
      offeringType: RESERVED_INSTANCES
      serviceUsageType: COMPUTE
      payments: $payments
      terms: $terms
      platform: $platform
    ) {
      payment
      term
      data {
        expectedUtilization
        instanceType
        monthlySavings
        monthlySavingsPercentage
        numberOfInstances
        recurringMonthlyCost
        upfrontCost
        yearlySavings
        startSavings
      }
    }
  }
`;

/**
 * __useReservedInstancesRecommendationsQuery__
 *
 * To run a query within a React component, call `useReservedInstancesRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservedInstancesRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservedInstancesRecommendationsQuery({
 *   variables: {
 *      resourceType: // value for 'resourceType'
 *      region: // value for 'region'
 *      provider: // value for 'provider'
 *      platform: // value for 'platform'
 *      payments: // value for 'payments'
 *      terms: // value for 'terms'
 *   },
 * });
 */
export function useReservedInstancesRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservedInstancesRecommendationsQuery,
    ReservedInstancesRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReservedInstancesRecommendationsQuery,
    ReservedInstancesRecommendationsQueryVariables
  >(ReservedInstancesRecommendationsDocument, options);
}
export function useReservedInstancesRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservedInstancesRecommendationsQuery,
    ReservedInstancesRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReservedInstancesRecommendationsQuery,
    ReservedInstancesRecommendationsQueryVariables
  >(ReservedInstancesRecommendationsDocument, options);
}
export type ReservedInstancesRecommendationsQueryHookResult = ReturnType<
  typeof useReservedInstancesRecommendationsQuery
>;
export type ReservedInstancesRecommendationsLazyQueryHookResult = ReturnType<
  typeof useReservedInstancesRecommendationsLazyQuery
>;
export type ReservedInstancesRecommendationsQueryResult = Apollo.QueryResult<
  ReservedInstancesRecommendationsQuery,
  ReservedInstancesRecommendationsQueryVariables
>;
export const CloudAccountStatusDocument = gql`
  query CloudAccountStatus($nid: String!) {
    estateRecord(nid: $nid) {
      ... on CloudResource {
        id
        cloudAccount {
          id
          nid
          activated
          name
          displayName
          status
        }
      }
    }
  }
`;

/**
 * __useCloudAccountStatusQuery__
 *
 * To run a query within a React component, call `useCloudAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudAccountStatusQuery({
 *   variables: {
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useCloudAccountStatusQuery(
  baseOptions: Apollo.QueryHookOptions<CloudAccountStatusQuery, CloudAccountStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CloudAccountStatusQuery, CloudAccountStatusQueryVariables>(
    CloudAccountStatusDocument,
    options
  );
}
export function useCloudAccountStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudAccountStatusQuery,
    CloudAccountStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CloudAccountStatusQuery, CloudAccountStatusQueryVariables>(
    CloudAccountStatusDocument,
    options
  );
}
export type CloudAccountStatusQueryHookResult = ReturnType<typeof useCloudAccountStatusQuery>;
export type CloudAccountStatusLazyQueryHookResult = ReturnType<
  typeof useCloudAccountStatusLazyQuery
>;
export type CloudAccountStatusQueryResult = Apollo.QueryResult<
  CloudAccountStatusQuery,
  CloudAccountStatusQueryVariables
>;
export const ReservationBenefitsEstateRecordsDocument = gql`
  query ReservationBenefitsEstateRecords(
    $instanceId: String!
    $provider: String!
    $offeringType: ReservationsAndCommitmentsCoverageOfferingType
  ) {
    reservationsBenefitsEstateRecords(
      instanceId: $instanceId
      provider: $provider
      offeringType: $offeringType
    ) {
      reservations {
        nid: reservationId
        from
        to
        paymentOption
        instanceType
        description: productDescription
        location: region
        unitsCount
        expirationTimeInDays
        expirationTimeInYears
        offeringClass
        yearlyUtilization {
          totalUtilisation: utilization
          benefit
        }
      }
    }
  }
`;

/**
 * __useReservationBenefitsEstateRecordsQuery__
 *
 * To run a query within a React component, call `useReservationBenefitsEstateRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationBenefitsEstateRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationBenefitsEstateRecordsQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      provider: // value for 'provider'
 *      offeringType: // value for 'offeringType'
 *   },
 * });
 */
export function useReservationBenefitsEstateRecordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationBenefitsEstateRecordsQuery,
    ReservationBenefitsEstateRecordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReservationBenefitsEstateRecordsQuery,
    ReservationBenefitsEstateRecordsQueryVariables
  >(ReservationBenefitsEstateRecordsDocument, options);
}
export function useReservationBenefitsEstateRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationBenefitsEstateRecordsQuery,
    ReservationBenefitsEstateRecordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReservationBenefitsEstateRecordsQuery,
    ReservationBenefitsEstateRecordsQueryVariables
  >(ReservationBenefitsEstateRecordsDocument, options);
}
export type ReservationBenefitsEstateRecordsQueryHookResult = ReturnType<
  typeof useReservationBenefitsEstateRecordsQuery
>;
export type ReservationBenefitsEstateRecordsLazyQueryHookResult = ReturnType<
  typeof useReservationBenefitsEstateRecordsLazyQuery
>;
export type ReservationBenefitsEstateRecordsQueryResult = Apollo.QueryResult<
  ReservationBenefitsEstateRecordsQuery,
  ReservationBenefitsEstateRecordsQueryVariables
>;
export const GetReservationUpfrontCostDocument = gql`
  query GetReservationUpfrontCost($reservationId: String!) {
    reservationDetails(reservationId: $reservationId) {
      upfrontCost
    }
  }
`;

/**
 * __useGetReservationUpfrontCostQuery__
 *
 * To run a query within a React component, call `useGetReservationUpfrontCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationUpfrontCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationUpfrontCostQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationUpfrontCostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReservationUpfrontCostQuery,
    GetReservationUpfrontCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReservationUpfrontCostQuery, GetReservationUpfrontCostQueryVariables>(
    GetReservationUpfrontCostDocument,
    options
  );
}
export function useGetReservationUpfrontCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReservationUpfrontCostQuery,
    GetReservationUpfrontCostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReservationUpfrontCostQuery,
    GetReservationUpfrontCostQueryVariables
  >(GetReservationUpfrontCostDocument, options);
}
export type GetReservationUpfrontCostQueryHookResult = ReturnType<
  typeof useGetReservationUpfrontCostQuery
>;
export type GetReservationUpfrontCostLazyQueryHookResult = ReturnType<
  typeof useGetReservationUpfrontCostLazyQuery
>;
export type GetReservationUpfrontCostQueryResult = Apollo.QueryResult<
  GetReservationUpfrontCostQuery,
  GetReservationUpfrontCostQueryVariables
>;
export const ReservationUtilizationV2Document = gql`
  query ReservationUtilizationV2(
    $nid: String!
    $start: String!
    $end: String!
    $granularity: String
    $reservationId: String
  ) {
    reservationUtilizationV2(
      nid: $nid
      start: $start
      end: $end
      granularity: $granularity
      reservationId: $reservationId
    ) {
      reservationId
      monthlyUtilization {
        reservationIDs
        benefit
        forecast
        utilization
        timeSeries {
          date
          value
          benefit
        }
      }
    }
  }
`;

/**
 * __useReservationUtilizationV2Query__
 *
 * To run a query within a React component, call `useReservationUtilizationV2Query` and pass it any options that fit your needs.
 * When your component renders, `useReservationUtilizationV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationUtilizationV2Query({
 *   variables: {
 *      nid: // value for 'nid'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      granularity: // value for 'granularity'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useReservationUtilizationV2Query(
  baseOptions: Apollo.QueryHookOptions<
    ReservationUtilizationV2Query,
    ReservationUtilizationV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationUtilizationV2Query, ReservationUtilizationV2QueryVariables>(
    ReservationUtilizationV2Document,
    options
  );
}
export function useReservationUtilizationV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationUtilizationV2Query,
    ReservationUtilizationV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationUtilizationV2Query, ReservationUtilizationV2QueryVariables>(
    ReservationUtilizationV2Document,
    options
  );
}
export type ReservationUtilizationV2QueryHookResult = ReturnType<
  typeof useReservationUtilizationV2Query
>;
export type ReservationUtilizationV2LazyQueryHookResult = ReturnType<
  typeof useReservationUtilizationV2LazyQuery
>;
export type ReservationUtilizationV2QueryResult = Apollo.QueryResult<
  ReservationUtilizationV2Query,
  ReservationUtilizationV2QueryVariables
>;
export const HasReservedInstancesRecommendationsDocument = gql`
  query hasReservedInstancesRecommendations(
    $limit: Int!
    $page: Int!
    $filters: ReservationsAndCommitmentsFilters
  ) {
    listReservationsAndCommitmentsCoverage(
      limit: $limit
      page: $page
      offeringType: ALL
      serviceUsageType: COMPUTE
      paymentOption: PARTIAL_UPFRONT
      term: ONE_YEAR
      lookbackPeriod: THIRTY_DAYS
      isGrouped: true
      filters: $filters
    ) {
      pages
      count
      groupedResources {
        resources {
          hasRecommendations
        }
      }
    }
  }
`;

/**
 * __useHasReservedInstancesRecommendationsQuery__
 *
 * To run a query within a React component, call `useHasReservedInstancesRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasReservedInstancesRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasReservedInstancesRecommendationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useHasReservedInstancesRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HasReservedInstancesRecommendationsQuery,
    HasReservedInstancesRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HasReservedInstancesRecommendationsQuery,
    HasReservedInstancesRecommendationsQueryVariables
  >(HasReservedInstancesRecommendationsDocument, options);
}
export function useHasReservedInstancesRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasReservedInstancesRecommendationsQuery,
    HasReservedInstancesRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HasReservedInstancesRecommendationsQuery,
    HasReservedInstancesRecommendationsQueryVariables
  >(HasReservedInstancesRecommendationsDocument, options);
}
export type HasReservedInstancesRecommendationsQueryHookResult = ReturnType<
  typeof useHasReservedInstancesRecommendationsQuery
>;
export type HasReservedInstancesRecommendationsLazyQueryHookResult = ReturnType<
  typeof useHasReservedInstancesRecommendationsLazyQuery
>;
export type HasReservedInstancesRecommendationsQueryResult = Apollo.QueryResult<
  HasReservedInstancesRecommendationsQuery,
  HasReservedInstancesRecommendationsQueryVariables
>;
export const AttachResourceDocument = gql`
  mutation AttachResource($environmentNid: String!, $resourceNid: String!) {
    attachResource(environmentNid: $environmentNid, resourceNid: $resourceNid)
  }
`;
export type AttachResourceMutationFn = Apollo.MutationFunction<
  AttachResourceMutation,
  AttachResourceMutationVariables
>;

/**
 * __useAttachResourceMutation__
 *
 * To run a mutation, you first call `useAttachResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachResourceMutation, { data, loading, error }] = useAttachResourceMutation({
 *   variables: {
 *      environmentNid: // value for 'environmentNid'
 *      resourceNid: // value for 'resourceNid'
 *   },
 * });
 */
export function useAttachResourceMutation(
  baseOptions?: Apollo.MutationHookOptions<AttachResourceMutation, AttachResourceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttachResourceMutation, AttachResourceMutationVariables>(
    AttachResourceDocument,
    options
  );
}
export type AttachResourceMutationHookResult = ReturnType<typeof useAttachResourceMutation>;
export type AttachResourceMutationResult = Apollo.MutationResult<AttachResourceMutation>;
export type AttachResourceMutationOptions = Apollo.BaseMutationOptions<
  AttachResourceMutation,
  AttachResourceMutationVariables
>;
export const EstateV2ExportDocument = gql`
  mutation EstateV2Export($input: RequestEstateV2ExportInput!) {
    requestEstateV2ExportAsync(input: $input) {
      exportJobId
    }
  }
`;
export type EstateV2ExportMutationFn = Apollo.MutationFunction<
  EstateV2ExportMutation,
  EstateV2ExportMutationVariables
>;

/**
 * __useEstateV2ExportMutation__
 *
 * To run a mutation, you first call `useEstateV2ExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstateV2ExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estateV2ExportMutation, { data, loading, error }] = useEstateV2ExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstateV2ExportMutation(
  baseOptions?: Apollo.MutationHookOptions<EstateV2ExportMutation, EstateV2ExportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstateV2ExportMutation, EstateV2ExportMutationVariables>(
    EstateV2ExportDocument,
    options
  );
}
export type EstateV2ExportMutationHookResult = ReturnType<typeof useEstateV2ExportMutation>;
export type EstateV2ExportMutationResult = Apollo.MutationResult<EstateV2ExportMutation>;
export type EstateV2ExportMutationOptions = Apollo.BaseMutationOptions<
  EstateV2ExportMutation,
  EstateV2ExportMutationVariables
>;
export const EstateV3ExportDocument = gql`
  mutation EstateV3Export($input: RequestEstateV3ExportInput!) {
    requestEstateV3ExportAsync(input: $input) {
      exportJobId
    }
  }
`;
export type EstateV3ExportMutationFn = Apollo.MutationFunction<
  EstateV3ExportMutation,
  EstateV3ExportMutationVariables
>;

/**
 * __useEstateV3ExportMutation__
 *
 * To run a mutation, you first call `useEstateV3ExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstateV3ExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estateV3ExportMutation, { data, loading, error }] = useEstateV3ExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstateV3ExportMutation(
  baseOptions?: Apollo.MutationHookOptions<EstateV3ExportMutation, EstateV3ExportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstateV3ExportMutation, EstateV3ExportMutationVariables>(
    EstateV3ExportDocument,
    options
  );
}
export type EstateV3ExportMutationHookResult = ReturnType<typeof useEstateV3ExportMutation>;
export type EstateV3ExportMutationResult = Apollo.MutationResult<EstateV3ExportMutation>;
export type EstateV3ExportMutationOptions = Apollo.BaseMutationOptions<
  EstateV3ExportMutation,
  EstateV3ExportMutationVariables
>;
export const EstateGroupedExportDocument = gql`
  mutation EstateGroupedExport($input: RequestGroupedRecordsExportInput!) {
    requestGroupedRecordsExport(input: $input) {
      exportJobId
    }
  }
`;
export type EstateGroupedExportMutationFn = Apollo.MutationFunction<
  EstateGroupedExportMutation,
  EstateGroupedExportMutationVariables
>;

/**
 * __useEstateGroupedExportMutation__
 *
 * To run a mutation, you first call `useEstateGroupedExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstateGroupedExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estateGroupedExportMutation, { data, loading, error }] = useEstateGroupedExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstateGroupedExportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstateGroupedExportMutation,
    EstateGroupedExportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstateGroupedExportMutation, EstateGroupedExportMutationVariables>(
    EstateGroupedExportDocument,
    options
  );
}
export type EstateGroupedExportMutationHookResult = ReturnType<
  typeof useEstateGroupedExportMutation
>;
export type EstateGroupedExportMutationResult = Apollo.MutationResult<EstateGroupedExportMutation>;
export type EstateGroupedExportMutationOptions = Apollo.BaseMutationOptions<
  EstateGroupedExportMutation,
  EstateGroupedExportMutationVariables
>;
export const EstateDownloadDocument = gql`
  query EstateDownload($exportJobId: ID!) {
    downloadExport(exportJobId: $exportJobId) {
      fileUrl
    }
  }
`;

/**
 * __useEstateDownloadQuery__
 *
 * To run a query within a React component, call `useEstateDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateDownloadQuery({
 *   variables: {
 *      exportJobId: // value for 'exportJobId'
 *   },
 * });
 */
export function useEstateDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<EstateDownloadQuery, EstateDownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstateDownloadQuery, EstateDownloadQueryVariables>(
    EstateDownloadDocument,
    options
  );
}
export function useEstateDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstateDownloadQuery, EstateDownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstateDownloadQuery, EstateDownloadQueryVariables>(
    EstateDownloadDocument,
    options
  );
}
export type EstateDownloadQueryHookResult = ReturnType<typeof useEstateDownloadQuery>;
export type EstateDownloadLazyQueryHookResult = ReturnType<typeof useEstateDownloadLazyQuery>;
export type EstateDownloadQueryResult = Apollo.QueryResult<
  EstateDownloadQuery,
  EstateDownloadQueryVariables
>;
export const GetAppsWithEnvsDocument = gql`
  query getAppsWithEnvs {
    applications {
      id
      nid
      name
      environments {
        id
        nid
        name
      }
    }
  }
`;

/**
 * __useGetAppsWithEnvsQuery__
 *
 * To run a query within a React component, call `useGetAppsWithEnvsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppsWithEnvsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppsWithEnvsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppsWithEnvsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>(
    GetAppsWithEnvsDocument,
    options
  );
}
export function useGetAppsWithEnvsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppsWithEnvsQuery, GetAppsWithEnvsQueryVariables>(
    GetAppsWithEnvsDocument,
    options
  );
}
export type GetAppsWithEnvsQueryHookResult = ReturnType<typeof useGetAppsWithEnvsQuery>;
export type GetAppsWithEnvsLazyQueryHookResult = ReturnType<typeof useGetAppsWithEnvsLazyQuery>;
export type GetAppsWithEnvsQueryResult = Apollo.QueryResult<
  GetAppsWithEnvsQuery,
  GetAppsWithEnvsQueryVariables
>;
export const GetAllAccountsWithDetailsDocument = gql`
  query GetAllAccountsWithDetails {
    accounts {
      provider
      name
      displayName
      id
      providerId
      activated
      providerType
      status
    }
  }
`;

/**
 * __useGetAllAccountsWithDetailsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountsWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountsWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountsWithDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountsWithDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllAccountsWithDetailsQuery,
    GetAllAccountsWithDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAccountsWithDetailsQuery, GetAllAccountsWithDetailsQueryVariables>(
    GetAllAccountsWithDetailsDocument,
    options
  );
}
export function useGetAllAccountsWithDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAccountsWithDetailsQuery,
    GetAllAccountsWithDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllAccountsWithDetailsQuery,
    GetAllAccountsWithDetailsQueryVariables
  >(GetAllAccountsWithDetailsDocument, options);
}
export type GetAllAccountsWithDetailsQueryHookResult = ReturnType<
  typeof useGetAllAccountsWithDetailsQuery
>;
export type GetAllAccountsWithDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAllAccountsWithDetailsLazyQuery
>;
export type GetAllAccountsWithDetailsQueryResult = Apollo.QueryResult<
  GetAllAccountsWithDetailsQuery,
  GetAllAccountsWithDetailsQueryVariables
>;
export const ServicesDocument = gql`
  query Services {
    services {
      id
      name
    }
  }
`;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
}
export function useServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
}
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const CloudWastePoliciesDocument = gql`
  query CloudWastePolicies {
    cloudWasteSummary {
      policies {
        id
        title
      }
    }
  }
`;

/**
 * __useCloudWastePoliciesQuery__
 *
 * To run a query within a React component, call `useCloudWastePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudWastePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudWastePoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudWastePoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>(
    CloudWastePoliciesDocument,
    options
  );
}
export function useCloudWastePoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudWastePoliciesQuery,
    CloudWastePoliciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CloudWastePoliciesQuery, CloudWastePoliciesQueryVariables>(
    CloudWastePoliciesDocument,
    options
  );
}
export type CloudWastePoliciesQueryHookResult = ReturnType<typeof useCloudWastePoliciesQuery>;
export type CloudWastePoliciesLazyQueryHookResult = ReturnType<
  typeof useCloudWastePoliciesLazyQuery
>;
export type CloudWastePoliciesQueryResult = Apollo.QueryResult<
  CloudWastePoliciesQuery,
  CloudWastePoliciesQueryVariables
>;
export const CloudWastePoliciesCategorisedDocument = gql`
  query CloudWastePoliciesCategorised {
    cloudWastePoliciesCategorised {
      category
      categoryName
      policies {
        id
        name
      }
    }
  }
`;

/**
 * __useCloudWastePoliciesCategorisedQuery__
 *
 * To run a query within a React component, call `useCloudWastePoliciesCategorisedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudWastePoliciesCategorisedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudWastePoliciesCategorisedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudWastePoliciesCategorisedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CloudWastePoliciesCategorisedQuery,
    CloudWastePoliciesCategorisedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CloudWastePoliciesCategorisedQuery,
    CloudWastePoliciesCategorisedQueryVariables
  >(CloudWastePoliciesCategorisedDocument, options);
}
export function useCloudWastePoliciesCategorisedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudWastePoliciesCategorisedQuery,
    CloudWastePoliciesCategorisedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CloudWastePoliciesCategorisedQuery,
    CloudWastePoliciesCategorisedQueryVariables
  >(CloudWastePoliciesCategorisedDocument, options);
}
export type CloudWastePoliciesCategorisedQueryHookResult = ReturnType<
  typeof useCloudWastePoliciesCategorisedQuery
>;
export type CloudWastePoliciesCategorisedLazyQueryHookResult = ReturnType<
  typeof useCloudWastePoliciesCategorisedLazyQuery
>;
export type CloudWastePoliciesCategorisedQueryResult = Apollo.QueryResult<
  CloudWastePoliciesCategorisedQuery,
  CloudWastePoliciesCategorisedQueryVariables
>;
export const ExternalIntegrationsFilterDocument = gql`
  query externalIntegrationsFilter {
    externalIntegrations {
      integrations {
        id
        name
      }
    }
  }
`;

/**
 * __useExternalIntegrationsFilterQuery__
 *
 * To run a query within a React component, call `useExternalIntegrationsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalIntegrationsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalIntegrationsFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useExternalIntegrationsFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExternalIntegrationsFilterQuery,
    ExternalIntegrationsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalIntegrationsFilterQuery, ExternalIntegrationsFilterQueryVariables>(
    ExternalIntegrationsFilterDocument,
    options
  );
}
export function useExternalIntegrationsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalIntegrationsFilterQuery,
    ExternalIntegrationsFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalIntegrationsFilterQuery,
    ExternalIntegrationsFilterQueryVariables
  >(ExternalIntegrationsFilterDocument, options);
}
export type ExternalIntegrationsFilterQueryHookResult = ReturnType<
  typeof useExternalIntegrationsFilterQuery
>;
export type ExternalIntegrationsFilterLazyQueryHookResult = ReturnType<
  typeof useExternalIntegrationsFilterLazyQuery
>;
export type ExternalIntegrationsFilterQueryResult = Apollo.QueryResult<
  ExternalIntegrationsFilterQuery,
  ExternalIntegrationsFilterQueryVariables
>;
export const ListCategoriesDocument = gql`
  query listCategories {
    listCategories {
      categories {
        id
        name
      }
    }
  }
`;

/**
 * __useListCategoriesQuery__
 *
 * To run a query within a React component, call `useListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCategoriesQuery, ListCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(
    ListCategoriesDocument,
    options
  );
}
export function useListCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCategoriesQuery, ListCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(
    ListCategoriesDocument,
    options
  );
}
export type ListCategoriesQueryHookResult = ReturnType<typeof useListCategoriesQuery>;
export type ListCategoriesLazyQueryHookResult = ReturnType<typeof useListCategoriesLazyQuery>;
export type ListCategoriesQueryResult = Apollo.QueryResult<
  ListCategoriesQuery,
  ListCategoriesQueryVariables
>;
export const SearchPresetsV2Document = gql`
  query SearchPresetsV2($limit: Int!, $page: Int, $nameQuery: String) {
    searchPresetsV2(page: $page, limit: $limit, nameQuery: $nameQuery) {
      count
      pages
      presets {
        ...SearchPresetV2
      }
    }
  }
  ${SearchPresetV2FragmentDoc}
`;

/**
 * __useSearchPresetsV2Query__
 *
 * To run a query within a React component, call `useSearchPresetsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchPresetsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPresetsV2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      nameQuery: // value for 'nameQuery'
 *   },
 * });
 */
export function useSearchPresetsV2Query(
  baseOptions: Apollo.QueryHookOptions<SearchPresetsV2Query, SearchPresetsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPresetsV2Query, SearchPresetsV2QueryVariables>(
    SearchPresetsV2Document,
    options
  );
}
export function useSearchPresetsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPresetsV2Query, SearchPresetsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPresetsV2Query, SearchPresetsV2QueryVariables>(
    SearchPresetsV2Document,
    options
  );
}
export type SearchPresetsV2QueryHookResult = ReturnType<typeof useSearchPresetsV2Query>;
export type SearchPresetsV2LazyQueryHookResult = ReturnType<typeof useSearchPresetsV2LazyQuery>;
export type SearchPresetsV2QueryResult = Apollo.QueryResult<
  SearchPresetsV2Query,
  SearchPresetsV2QueryVariables
>;
export const AddSearchPresetV2Document = gql`
  mutation AddSearchPresetV2($input: AddSearchPresetV2Input!) {
    addSearchPresetV2(input: $input) {
      preset {
        ...SearchPresetV2
      }
    }
  }
  ${SearchPresetV2FragmentDoc}
`;
export type AddSearchPresetV2MutationFn = Apollo.MutationFunction<
  AddSearchPresetV2Mutation,
  AddSearchPresetV2MutationVariables
>;

/**
 * __useAddSearchPresetV2Mutation__
 *
 * To run a mutation, you first call `useAddSearchPresetV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSearchPresetV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSearchPresetV2Mutation, { data, loading, error }] = useAddSearchPresetV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSearchPresetV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSearchPresetV2Mutation,
    AddSearchPresetV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSearchPresetV2Mutation, AddSearchPresetV2MutationVariables>(
    AddSearchPresetV2Document,
    options
  );
}
export type AddSearchPresetV2MutationHookResult = ReturnType<typeof useAddSearchPresetV2Mutation>;
export type AddSearchPresetV2MutationResult = Apollo.MutationResult<AddSearchPresetV2Mutation>;
export type AddSearchPresetV2MutationOptions = Apollo.BaseMutationOptions<
  AddSearchPresetV2Mutation,
  AddSearchPresetV2MutationVariables
>;
export const RemoveSearchPresetV2Document = gql`
  mutation RemoveSearchPresetV2($id: String!) {
    removeSearchPresetV2(id: $id) {
      preset {
        id
      }
    }
  }
`;
export type RemoveSearchPresetV2MutationFn = Apollo.MutationFunction<
  RemoveSearchPresetV2Mutation,
  RemoveSearchPresetV2MutationVariables
>;

/**
 * __useRemoveSearchPresetV2Mutation__
 *
 * To run a mutation, you first call `useRemoveSearchPresetV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSearchPresetV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSearchPresetV2Mutation, { data, loading, error }] = useRemoveSearchPresetV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSearchPresetV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSearchPresetV2Mutation,
    RemoveSearchPresetV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveSearchPresetV2Mutation, RemoveSearchPresetV2MutationVariables>(
    RemoveSearchPresetV2Document,
    options
  );
}
export type RemoveSearchPresetV2MutationHookResult = ReturnType<
  typeof useRemoveSearchPresetV2Mutation
>;
export type RemoveSearchPresetV2MutationResult =
  Apollo.MutationResult<RemoveSearchPresetV2Mutation>;
export type RemoveSearchPresetV2MutationOptions = Apollo.BaseMutationOptions<
  RemoveSearchPresetV2Mutation,
  RemoveSearchPresetV2MutationVariables
>;
export const ResourceCostSplitRulesDocument = gql`
  query ResourceCostSplitRules($limit: Int!, $nid: String!) {
    resourceCostSplitRules(limit: $limit, nid: $nid) {
      result {
        id
        resourceNid
        provider
        algorithm
        settings {
          allEnvironments
          environments {
            id
            splitValue
          }
        }
      }
      count
    }
  }
`;

/**
 * __useResourceCostSplitRulesQuery__
 *
 * To run a query within a React component, call `useResourceCostSplitRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceCostSplitRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceCostSplitRulesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nid: // value for 'nid'
 *   },
 * });
 */
export function useResourceCostSplitRulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceCostSplitRulesQuery,
    ResourceCostSplitRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ResourceCostSplitRulesQuery, ResourceCostSplitRulesQueryVariables>(
    ResourceCostSplitRulesDocument,
    options
  );
}
export function useResourceCostSplitRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceCostSplitRulesQuery,
    ResourceCostSplitRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ResourceCostSplitRulesQuery, ResourceCostSplitRulesQueryVariables>(
    ResourceCostSplitRulesDocument,
    options
  );
}
export type ResourceCostSplitRulesQueryHookResult = ReturnType<
  typeof useResourceCostSplitRulesQuery
>;
export type ResourceCostSplitRulesLazyQueryHookResult = ReturnType<
  typeof useResourceCostSplitRulesLazyQuery
>;
export type ResourceCostSplitRulesQueryResult = Apollo.QueryResult<
  ResourceCostSplitRulesQuery,
  ResourceCostSplitRulesQueryVariables
>;
export const AddResourceCostSplitRuleDocument = gql`
  mutation AddResourceCostSplitRule($input: ResourceCostSplitRuleInput!) {
    addResourceCostSplitRule(input: $input) {
      id
      resourceNid
      provider
      algorithm
      settings {
        allEnvironments
        environments {
          id
          splitValue
        }
      }
    }
  }
`;
export type AddResourceCostSplitRuleMutationFn = Apollo.MutationFunction<
  AddResourceCostSplitRuleMutation,
  AddResourceCostSplitRuleMutationVariables
>;

/**
 * __useAddResourceCostSplitRuleMutation__
 *
 * To run a mutation, you first call `useAddResourceCostSplitRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResourceCostSplitRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResourceCostSplitRuleMutation, { data, loading, error }] = useAddResourceCostSplitRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddResourceCostSplitRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddResourceCostSplitRuleMutation,
    AddResourceCostSplitRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddResourceCostSplitRuleMutation,
    AddResourceCostSplitRuleMutationVariables
  >(AddResourceCostSplitRuleDocument, options);
}
export type AddResourceCostSplitRuleMutationHookResult = ReturnType<
  typeof useAddResourceCostSplitRuleMutation
>;
export type AddResourceCostSplitRuleMutationResult =
  Apollo.MutationResult<AddResourceCostSplitRuleMutation>;
export type AddResourceCostSplitRuleMutationOptions = Apollo.BaseMutationOptions<
  AddResourceCostSplitRuleMutation,
  AddResourceCostSplitRuleMutationVariables
>;
export const UpdateResourceCostSplitRuleDocument = gql`
  mutation UpdateResourceCostSplitRule($input: ResourceCostSplitRuleUpdateInput!) {
    updateResourceCostSplitRule(input: $input) {
      id
      resourceNid
      provider
      algorithm
      settings {
        allEnvironments
        environments {
          id
          splitValue
        }
      }
    }
  }
`;
export type UpdateResourceCostSplitRuleMutationFn = Apollo.MutationFunction<
  UpdateResourceCostSplitRuleMutation,
  UpdateResourceCostSplitRuleMutationVariables
>;

/**
 * __useUpdateResourceCostSplitRuleMutation__
 *
 * To run a mutation, you first call `useUpdateResourceCostSplitRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceCostSplitRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceCostSplitRuleMutation, { data, loading, error }] = useUpdateResourceCostSplitRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceCostSplitRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateResourceCostSplitRuleMutation,
    UpdateResourceCostSplitRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateResourceCostSplitRuleMutation,
    UpdateResourceCostSplitRuleMutationVariables
  >(UpdateResourceCostSplitRuleDocument, options);
}
export type UpdateResourceCostSplitRuleMutationHookResult = ReturnType<
  typeof useUpdateResourceCostSplitRuleMutation
>;
export type UpdateResourceCostSplitRuleMutationResult =
  Apollo.MutationResult<UpdateResourceCostSplitRuleMutation>;
export type UpdateResourceCostSplitRuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateResourceCostSplitRuleMutation,
  UpdateResourceCostSplitRuleMutationVariables
>;
export const DeleteResourceCostSplitRuleDocument = gql`
  mutation DeleteResourceCostSplitRule($deleteResourceCostSplitRuleId: ID!) {
    removeResourceCostSplitRule(id: $deleteResourceCostSplitRuleId)
  }
`;
export type DeleteResourceCostSplitRuleMutationFn = Apollo.MutationFunction<
  DeleteResourceCostSplitRuleMutation,
  DeleteResourceCostSplitRuleMutationVariables
>;

/**
 * __useDeleteResourceCostSplitRuleMutation__
 *
 * To run a mutation, you first call `useDeleteResourceCostSplitRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceCostSplitRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceCostSplitRuleMutation, { data, loading, error }] = useDeleteResourceCostSplitRuleMutation({
 *   variables: {
 *      deleteResourceCostSplitRuleId: // value for 'deleteResourceCostSplitRuleId'
 *   },
 * });
 */
export function useDeleteResourceCostSplitRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteResourceCostSplitRuleMutation,
    DeleteResourceCostSplitRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteResourceCostSplitRuleMutation,
    DeleteResourceCostSplitRuleMutationVariables
  >(DeleteResourceCostSplitRuleDocument, options);
}
export type DeleteResourceCostSplitRuleMutationHookResult = ReturnType<
  typeof useDeleteResourceCostSplitRuleMutation
>;
export type DeleteResourceCostSplitRuleMutationResult =
  Apollo.MutationResult<DeleteResourceCostSplitRuleMutation>;
export type DeleteResourceCostSplitRuleMutationOptions = Apollo.BaseMutationOptions<
  DeleteResourceCostSplitRuleMutation,
  DeleteResourceCostSplitRuleMutationVariables
>;
export const AttachResourceCostSplitRulesDocument = gql`
  mutation AttachResourceCostSplitRules($input: BulkResourceCostSplitRuleInput!) {
    attachResourceCostSplitRules(input: $input) {
      algorithm
      resources {
        ruleId
        nid
        provider
      }
      settings {
        allEnvironments
        environments {
          id
          splitValue
        }
      }
    }
  }
`;
export type AttachResourceCostSplitRulesMutationFn = Apollo.MutationFunction<
  AttachResourceCostSplitRulesMutation,
  AttachResourceCostSplitRulesMutationVariables
>;

/**
 * __useAttachResourceCostSplitRulesMutation__
 *
 * To run a mutation, you first call `useAttachResourceCostSplitRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachResourceCostSplitRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachResourceCostSplitRulesMutation, { data, loading, error }] = useAttachResourceCostSplitRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachResourceCostSplitRulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachResourceCostSplitRulesMutation,
    AttachResourceCostSplitRulesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttachResourceCostSplitRulesMutation,
    AttachResourceCostSplitRulesMutationVariables
  >(AttachResourceCostSplitRulesDocument, options);
}
export type AttachResourceCostSplitRulesMutationHookResult = ReturnType<
  typeof useAttachResourceCostSplitRulesMutation
>;
export type AttachResourceCostSplitRulesMutationResult =
  Apollo.MutationResult<AttachResourceCostSplitRulesMutation>;
export type AttachResourceCostSplitRulesMutationOptions = Apollo.BaseMutationOptions<
  AttachResourceCostSplitRulesMutation,
  AttachResourceCostSplitRulesMutationVariables
>;
export const SubmitSearchJobDocument = gql`
  mutation SubmitSearchJob(
    $page: Int
    $limit: Int!
    $sort: SearchSort
    $filter: EstateV2Filter
    $query: String
  ) {
    submitSearchJob(page: $page, limit: $limit, sort: $sort, filter: $filter, query: $query) {
      jobId
    }
  }
`;
export type SubmitSearchJobMutationFn = Apollo.MutationFunction<
  SubmitSearchJobMutation,
  SubmitSearchJobMutationVariables
>;

/**
 * __useSubmitSearchJobMutation__
 *
 * To run a mutation, you first call `useSubmitSearchJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSearchJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSearchJobMutation, { data, loading, error }] = useSubmitSearchJobMutation({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSubmitSearchJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitSearchJobMutation,
    SubmitSearchJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitSearchJobMutation, SubmitSearchJobMutationVariables>(
    SubmitSearchJobDocument,
    options
  );
}
export type SubmitSearchJobMutationHookResult = ReturnType<typeof useSubmitSearchJobMutation>;
export type SubmitSearchJobMutationResult = Apollo.MutationResult<SubmitSearchJobMutation>;
export type SubmitSearchJobMutationOptions = Apollo.BaseMutationOptions<
  SubmitSearchJobMutation,
  SubmitSearchJobMutationVariables
>;
export const SubmitSearchJobV2Document = gql`
  mutation SubmitSearchJobV2(
    $page: Int
    $limit: Int!
    $sort: SearchV2Sort
    $filter: SearchV2Filter
    $query: String
  ) {
    submitSearchJobV2(page: $page, limit: $limit, sort: $sort, filter: $filter, query: $query) {
      jobId
    }
  }
`;
export type SubmitSearchJobV2MutationFn = Apollo.MutationFunction<
  SubmitSearchJobV2Mutation,
  SubmitSearchJobV2MutationVariables
>;

/**
 * __useSubmitSearchJobV2Mutation__
 *
 * To run a mutation, you first call `useSubmitSearchJobV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSearchJobV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSearchJobV2Mutation, { data, loading, error }] = useSubmitSearchJobV2Mutation({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSubmitSearchJobV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitSearchJobV2Mutation,
    SubmitSearchJobV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitSearchJobV2Mutation, SubmitSearchJobV2MutationVariables>(
    SubmitSearchJobV2Document,
    options
  );
}
export type SubmitSearchJobV2MutationHookResult = ReturnType<typeof useSubmitSearchJobV2Mutation>;
export type SubmitSearchJobV2MutationResult = Apollo.MutationResult<SubmitSearchJobV2Mutation>;
export type SubmitSearchJobV2MutationOptions = Apollo.BaseMutationOptions<
  SubmitSearchJobV2Mutation,
  SubmitSearchJobV2MutationVariables
>;
export const SubmitOrgUnitsChartJobDocument = gql`
  mutation SubmitOrgUnitsChartJob(
    $businessContextNid: String!
    $startDate: String!
    $endDate: String!
    $filter: SearchV2Filter
  ) {
    submitOrganisationUnitsChartJob(
      businessContextNID: $businessContextNid
      startDate: $startDate
      endDate: $endDate
      filter: $filter
    ) {
      jobId
    }
  }
`;
export type SubmitOrgUnitsChartJobMutationFn = Apollo.MutationFunction<
  SubmitOrgUnitsChartJobMutation,
  SubmitOrgUnitsChartJobMutationVariables
>;

/**
 * __useSubmitOrgUnitsChartJobMutation__
 *
 * To run a mutation, you first call `useSubmitOrgUnitsChartJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOrgUnitsChartJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOrgUnitsChartJobMutation, { data, loading, error }] = useSubmitOrgUnitsChartJobMutation({
 *   variables: {
 *      businessContextNid: // value for 'businessContextNid'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubmitOrgUnitsChartJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitOrgUnitsChartJobMutation,
    SubmitOrgUnitsChartJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitOrgUnitsChartJobMutation,
    SubmitOrgUnitsChartJobMutationVariables
  >(SubmitOrgUnitsChartJobDocument, options);
}
export type SubmitOrgUnitsChartJobMutationHookResult = ReturnType<
  typeof useSubmitOrgUnitsChartJobMutation
>;
export type SubmitOrgUnitsChartJobMutationResult =
  Apollo.MutationResult<SubmitOrgUnitsChartJobMutation>;
export type SubmitOrgUnitsChartJobMutationOptions = Apollo.BaseMutationOptions<
  SubmitOrgUnitsChartJobMutation,
  SubmitOrgUnitsChartJobMutationVariables
>;
export const SubmitTagsChartJobDocument = gql`
  mutation SubmitTagsChartJob(
    $filter: SearchV2Filter
    $startDate: String!
    $endDate: String!
    $tagKey: String!
  ) {
    submitTagsChartJob(filter: $filter, startDate: $startDate, endDate: $endDate, tagKey: $tagKey) {
      jobId
    }
  }
`;
export type SubmitTagsChartJobMutationFn = Apollo.MutationFunction<
  SubmitTagsChartJobMutation,
  SubmitTagsChartJobMutationVariables
>;

/**
 * __useSubmitTagsChartJobMutation__
 *
 * To run a mutation, you first call `useSubmitTagsChartJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTagsChartJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTagsChartJobMutation, { data, loading, error }] = useSubmitTagsChartJobMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      tagKey: // value for 'tagKey'
 *   },
 * });
 */
export function useSubmitTagsChartJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitTagsChartJobMutation,
    SubmitTagsChartJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitTagsChartJobMutation, SubmitTagsChartJobMutationVariables>(
    SubmitTagsChartJobDocument,
    options
  );
}
export type SubmitTagsChartJobMutationHookResult = ReturnType<typeof useSubmitTagsChartJobMutation>;
export type SubmitTagsChartJobMutationResult = Apollo.MutationResult<SubmitTagsChartJobMutation>;
export type SubmitTagsChartJobMutationOptions = Apollo.BaseMutationOptions<
  SubmitTagsChartJobMutation,
  SubmitTagsChartJobMutationVariables
>;
export const SearchJobDocument = gql`
  query SearchJob($jobId: ID!, $isLite: Boolean!) {
    searchJob(jobId: $jobId) {
      status
      data {
        cost {
          currentMonthTotal
          monthlyForecast
        }
        savingsAmount {
          total
        }
        count
        pages
        resources {
          ... on CloudResource {
            id
            provider
            providerId
            providerType
            name
            category
            region
            cloudAccountId
            cost {
              currentMonth
              forecast
              count
            }
            searchCost
            environment @skip(if: $isLite) {
              id
              nid
              name
              application {
                id
                nid
                name
              }
            }
            cloudWaste @skip(if: $isLite) {
              isWaste
              reason
            }
            savingsAmount @skip(if: $isLite) {
              amount
              count
              co2e
            }
            co2e @skip(if: $isLite) {
              currentMonth
            }
            assignments @skip(if: $isLite) {
              appCount
              envCount
            }
          }
          ... on CostResource {
            id
            provider
            providerType
            assignments @skip(if: $isLite) {
              appCount
              envCount
            }
            cost {
              currentMonth
              forecast
            }
            searchCost
          }
        }
      }
    }
  }
`;

/**
 * __useSearchJobQuery__
 *
 * To run a query within a React component, call `useSearchJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      isLite: // value for 'isLite'
 *   },
 * });
 */
export function useSearchJobQuery(
  baseOptions: Apollo.QueryHookOptions<SearchJobQuery, SearchJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchJobQuery, SearchJobQueryVariables>(SearchJobDocument, options);
}
export function useSearchJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchJobQuery, SearchJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchJobQuery, SearchJobQueryVariables>(SearchJobDocument, options);
}
export type SearchJobQueryHookResult = ReturnType<typeof useSearchJobQuery>;
export type SearchJobLazyQueryHookResult = ReturnType<typeof useSearchJobLazyQuery>;
export type SearchJobQueryResult = Apollo.QueryResult<SearchJobQuery, SearchJobQueryVariables>;
export const SubmitChartJobDocument = gql`
  mutation SubmitChartJob(
    $filter: SearchV2Filter
    $startDate: String!
    $endDate: String!
    $chartType: ChartType
  ) {
    submitChartJobV2(
      filter: $filter
      startDate: $startDate
      endDate: $endDate
      chartType: $chartType
    ) {
      jobId
    }
  }
`;
export type SubmitChartJobMutationFn = Apollo.MutationFunction<
  SubmitChartJobMutation,
  SubmitChartJobMutationVariables
>;

/**
 * __useSubmitChartJobMutation__
 *
 * To run a mutation, you first call `useSubmitChartJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChartJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitChartJobMutation, { data, loading, error }] = useSubmitChartJobMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      chartType: // value for 'chartType'
 *   },
 * });
 */
export function useSubmitChartJobMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitChartJobMutation, SubmitChartJobMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitChartJobMutation, SubmitChartJobMutationVariables>(
    SubmitChartJobDocument,
    options
  );
}
export type SubmitChartJobMutationHookResult = ReturnType<typeof useSubmitChartJobMutation>;
export type SubmitChartJobMutationResult = Apollo.MutationResult<SubmitChartJobMutation>;
export type SubmitChartJobMutationOptions = Apollo.BaseMutationOptions<
  SubmitChartJobMutation,
  SubmitChartJobMutationVariables
>;
export const ChartJobDocument = gql`
  query ChartJob($jobId: ID!, $top: Int) {
    chartJob(jobId: $jobId, granularity: DAILY, top: $top) {
      ...ChartResponse
    }
    chartMonthlyJob: chartJob(jobId: $jobId, granularity: MONTHLY, top: $top) {
      ...ChartResponse
    }
  }
  ${ChartResponseFragmentDoc}
`;

/**
 * __useChartJobQuery__
 *
 * To run a query within a React component, call `useChartJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      top: // value for 'top'
 *   },
 * });
 */
export function useChartJobQuery(
  baseOptions: Apollo.QueryHookOptions<ChartJobQuery, ChartJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChartJobQuery, ChartJobQueryVariables>(ChartJobDocument, options);
}
export function useChartJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChartJobQuery, ChartJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChartJobQuery, ChartJobQueryVariables>(ChartJobDocument, options);
}
export type ChartJobQueryHookResult = ReturnType<typeof useChartJobQuery>;
export type ChartJobLazyQueryHookResult = ReturnType<typeof useChartJobLazyQuery>;
export type ChartJobQueryResult = Apollo.QueryResult<ChartJobQuery, ChartJobQueryVariables>;
export const ChartJobTableDocument = gql`
  query ChartJobTable(
    $jobId: ID!
    $limit: Int!
    $page: Int
    $query: String
    $order: ChartTableSort
  ) {
    chartJobTable(jobId: $jobId, limit: $limit, page: $page, query: $query, order: $order) {
      status
      data {
        count
        pages
        results {
          date
          total
          groups {
            cost
            key
            name
          }
        }
      }
    }
  }
`;

/**
 * __useChartJobTableQuery__
 *
 * To run a query within a React component, call `useChartJobTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartJobTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartJobTableQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      query: // value for 'query'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useChartJobTableQuery(
  baseOptions: Apollo.QueryHookOptions<ChartJobTableQuery, ChartJobTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChartJobTableQuery, ChartJobTableQueryVariables>(
    ChartJobTableDocument,
    options
  );
}
export function useChartJobTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChartJobTableQuery, ChartJobTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChartJobTableQuery, ChartJobTableQueryVariables>(
    ChartJobTableDocument,
    options
  );
}
export type ChartJobTableQueryHookResult = ReturnType<typeof useChartJobTableQuery>;
export type ChartJobTableLazyQueryHookResult = ReturnType<typeof useChartJobTableLazyQuery>;
export type ChartJobTableQueryResult = Apollo.QueryResult<
  ChartJobTableQuery,
  ChartJobTableQueryVariables
>;
export const CloudServicesDocument = gql`
  query CloudServices {
    services {
      id
      name
    }
  }
`;

/**
 * __useCloudServicesQuery__
 *
 * To run a query within a React component, call `useCloudServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<CloudServicesQuery, CloudServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CloudServicesQuery, CloudServicesQueryVariables>(
    CloudServicesDocument,
    options
  );
}
export function useCloudServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CloudServicesQuery, CloudServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CloudServicesQuery, CloudServicesQueryVariables>(
    CloudServicesDocument,
    options
  );
}
export type CloudServicesQueryHookResult = ReturnType<typeof useCloudServicesQuery>;
export type CloudServicesLazyQueryHookResult = ReturnType<typeof useCloudServicesLazyQuery>;
export type CloudServicesQueryResult = Apollo.QueryResult<
  CloudServicesQuery,
  CloudServicesQueryVariables
>;
export const TagKeysDocument = gql`
  query TagKeys($startDate: String!, $endDate: String!, $limit: Int!, $page: Int, $query: String) {
    listTagKeys(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      page: $page
      query: $query
    ) {
      tagKeys
      count
      pages
    }
  }
`;

/**
 * __useTagKeysQuery__
 *
 * To run a query within a React component, call `useTagKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagKeysQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTagKeysQuery(
  baseOptions: Apollo.QueryHookOptions<TagKeysQuery, TagKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TagKeysQuery, TagKeysQueryVariables>(TagKeysDocument, options);
}
export function useTagKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TagKeysQuery, TagKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TagKeysQuery, TagKeysQueryVariables>(TagKeysDocument, options);
}
export type TagKeysQueryHookResult = ReturnType<typeof useTagKeysQuery>;
export type TagKeysLazyQueryHookResult = ReturnType<typeof useTagKeysLazyQuery>;
export type TagKeysQueryResult = Apollo.QueryResult<TagKeysQuery, TagKeysQueryVariables>;
export const ApplicationsPaginatedByApplicationNameDocument = gql`
  query ApplicationsPaginatedByApplicationName($limit: Int!, $page: Int, $applicationName: String) {
    applicationsPaginated(limit: $limit, page: $page, applicationName: $applicationName) {
      count
      pages
      results {
        id
        nid
        name
        environments {
          name
          id
          nid
        }
      }
    }
  }
`;

/**
 * __useApplicationsPaginatedByApplicationNameQuery__
 *
 * To run a query within a React component, call `useApplicationsPaginatedByApplicationNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsPaginatedByApplicationNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsPaginatedByApplicationNameQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      applicationName: // value for 'applicationName'
 *   },
 * });
 */
export function useApplicationsPaginatedByApplicationNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsPaginatedByApplicationNameQuery,
    ApplicationsPaginatedByApplicationNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationsPaginatedByApplicationNameQuery,
    ApplicationsPaginatedByApplicationNameQueryVariables
  >(ApplicationsPaginatedByApplicationNameDocument, options);
}
export function useApplicationsPaginatedByApplicationNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsPaginatedByApplicationNameQuery,
    ApplicationsPaginatedByApplicationNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationsPaginatedByApplicationNameQuery,
    ApplicationsPaginatedByApplicationNameQueryVariables
  >(ApplicationsPaginatedByApplicationNameDocument, options);
}
export type ApplicationsPaginatedByApplicationNameQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedByApplicationNameQuery
>;
export type ApplicationsPaginatedByApplicationNameLazyQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedByApplicationNameLazyQuery
>;
export type ApplicationsPaginatedByApplicationNameQueryResult = Apollo.QueryResult<
  ApplicationsPaginatedByApplicationNameQuery,
  ApplicationsPaginatedByApplicationNameQueryVariables
>;
export const ExternalIntegrationsDocument = gql`
  query externalIntegrations {
    externalIntegrations {
      integrations {
        id
        name
        createdAt
        lastSync
      }
    }
  }
`;

/**
 * __useExternalIntegrationsQuery__
 *
 * To run a query within a React component, call `useExternalIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExternalIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExternalIntegrationsQuery,
    ExternalIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalIntegrationsQuery, ExternalIntegrationsQueryVariables>(
    ExternalIntegrationsDocument,
    options
  );
}
export function useExternalIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalIntegrationsQuery,
    ExternalIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalIntegrationsQuery, ExternalIntegrationsQueryVariables>(
    ExternalIntegrationsDocument,
    options
  );
}
export type ExternalIntegrationsQueryHookResult = ReturnType<typeof useExternalIntegrationsQuery>;
export type ExternalIntegrationsLazyQueryHookResult = ReturnType<
  typeof useExternalIntegrationsLazyQuery
>;
export type ExternalIntegrationsQueryResult = Apollo.QueryResult<
  ExternalIntegrationsQuery,
  ExternalIntegrationsQueryVariables
>;
export const AddExternalIntegrationDocument = gql`
  mutation AddExternalIntegration($integration: ExternalIntegration!) {
    addExternalIntegration(integration: $integration) {
      id
      nid
      secret
    }
  }
`;
export type AddExternalIntegrationMutationFn = Apollo.MutationFunction<
  AddExternalIntegrationMutation,
  AddExternalIntegrationMutationVariables
>;

/**
 * __useAddExternalIntegrationMutation__
 *
 * To run a mutation, you first call `useAddExternalIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExternalIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExternalIntegrationMutation, { data, loading, error }] = useAddExternalIntegrationMutation({
 *   variables: {
 *      integration: // value for 'integration'
 *   },
 * });
 */
export function useAddExternalIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddExternalIntegrationMutation,
    AddExternalIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddExternalIntegrationMutation,
    AddExternalIntegrationMutationVariables
  >(AddExternalIntegrationDocument, options);
}
export type AddExternalIntegrationMutationHookResult = ReturnType<
  typeof useAddExternalIntegrationMutation
>;
export type AddExternalIntegrationMutationResult =
  Apollo.MutationResult<AddExternalIntegrationMutation>;
export type AddExternalIntegrationMutationOptions = Apollo.BaseMutationOptions<
  AddExternalIntegrationMutation,
  AddExternalIntegrationMutationVariables
>;
export const RegenerateExternalIntegrationSecretDocument = gql`
  mutation RegenerateExternalIntegrationSecret($integrationId: String!) {
    regenerateExternalIntegrationSecret(integrationId: $integrationId) {
      id
      secret
    }
  }
`;
export type RegenerateExternalIntegrationSecretMutationFn = Apollo.MutationFunction<
  RegenerateExternalIntegrationSecretMutation,
  RegenerateExternalIntegrationSecretMutationVariables
>;

/**
 * __useRegenerateExternalIntegrationSecretMutation__
 *
 * To run a mutation, you first call `useRegenerateExternalIntegrationSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateExternalIntegrationSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateExternalIntegrationSecretMutation, { data, loading, error }] = useRegenerateExternalIntegrationSecretMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useRegenerateExternalIntegrationSecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateExternalIntegrationSecretMutation,
    RegenerateExternalIntegrationSecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegenerateExternalIntegrationSecretMutation,
    RegenerateExternalIntegrationSecretMutationVariables
  >(RegenerateExternalIntegrationSecretDocument, options);
}
export type RegenerateExternalIntegrationSecretMutationHookResult = ReturnType<
  typeof useRegenerateExternalIntegrationSecretMutation
>;
export type RegenerateExternalIntegrationSecretMutationResult =
  Apollo.MutationResult<RegenerateExternalIntegrationSecretMutation>;
export type RegenerateExternalIntegrationSecretMutationOptions = Apollo.BaseMutationOptions<
  RegenerateExternalIntegrationSecretMutation,
  RegenerateExternalIntegrationSecretMutationVariables
>;
export const ExternalIntegrationDetailsDocument = gql`
  query externalIntegrationDetails($integrationId: String!) {
    externalIntegrationDetails(integrationId: $integrationId) {
      id
      name
      description
      ownerId
      createdAt
      lastSync
    }
  }
`;

/**
 * __useExternalIntegrationDetailsQuery__
 *
 * To run a query within a React component, call `useExternalIntegrationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalIntegrationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalIntegrationDetailsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useExternalIntegrationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExternalIntegrationDetailsQuery,
    ExternalIntegrationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalIntegrationDetailsQuery, ExternalIntegrationDetailsQueryVariables>(
    ExternalIntegrationDetailsDocument,
    options
  );
}
export function useExternalIntegrationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExternalIntegrationDetailsQuery,
    ExternalIntegrationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ExternalIntegrationDetailsQuery,
    ExternalIntegrationDetailsQueryVariables
  >(ExternalIntegrationDetailsDocument, options);
}
export type ExternalIntegrationDetailsQueryHookResult = ReturnType<
  typeof useExternalIntegrationDetailsQuery
>;
export type ExternalIntegrationDetailsLazyQueryHookResult = ReturnType<
  typeof useExternalIntegrationDetailsLazyQuery
>;
export type ExternalIntegrationDetailsQueryResult = Apollo.QueryResult<
  ExternalIntegrationDetailsQuery,
  ExternalIntegrationDetailsQueryVariables
>;
export const AddSandboxDocument = gql`
  mutation AddSandbox($input: AddSandbox!) {
    addSandbox(input: $input) {
      id
    }
  }
`;
export type AddSandboxMutationFn = Apollo.MutationFunction<
  AddSandboxMutation,
  AddSandboxMutationVariables
>;

/**
 * __useAddSandboxMutation__
 *
 * To run a mutation, you first call `useAddSandboxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSandboxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSandboxMutation, { data, loading, error }] = useAddSandboxMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSandboxMutation(
  baseOptions?: Apollo.MutationHookOptions<AddSandboxMutation, AddSandboxMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddSandboxMutation, AddSandboxMutationVariables>(
    AddSandboxDocument,
    options
  );
}
export type AddSandboxMutationHookResult = ReturnType<typeof useAddSandboxMutation>;
export type AddSandboxMutationResult = Apollo.MutationResult<AddSandboxMutation>;
export type AddSandboxMutationOptions = Apollo.BaseMutationOptions<
  AddSandboxMutation,
  AddSandboxMutationVariables
>;
export const BusinessContextKpiDocument = gql`
  query BusinessContextKpi(
    $bcId: ID!
    $timePeriod: KpiTimePeriod!
    $fetchCostAllocation: Boolean!
    $fetchOverallWaste: Boolean!
    $fetchNonProdHours: Boolean!
  ) {
    businessContext(id: $bcId) {
      id
      costAllocation {
        allocated
      }
      kpis {
        costAllocationCoverage @include(if: $fetchCostAllocation) {
          details(timePeriod: $timePeriod) {
            ...KpiDetails
          }
        }
        overallWaste @include(if: $fetchOverallWaste) {
          details(timePeriod: $timePeriod) {
            ...KpiDetails
          }
          worst {
            orgUnits {
              orgUnit {
                id
                nid
                name
              }
              kpiValue
              currentWaste
            }
          }
        }
        nonProdHours @include(if: $fetchNonProdHours) {
          details(timePeriod: $timePeriod) {
            ...KpiDetails
          }
          worst {
            orgUnits {
              orgUnit {
                id
                nid
                name
              }
              kpiValue
              hours
            }
          }
        }
      }
    }
  }
  ${KpiDetailsFragmentDoc}
`;

/**
 * __useBusinessContextKpiQuery__
 *
 * To run a query within a React component, call `useBusinessContextKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextKpiQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      timePeriod: // value for 'timePeriod'
 *      fetchCostAllocation: // value for 'fetchCostAllocation'
 *      fetchOverallWaste: // value for 'fetchOverallWaste'
 *      fetchNonProdHours: // value for 'fetchNonProdHours'
 *   },
 * });
 */
export function useBusinessContextKpiQuery(
  baseOptions: Apollo.QueryHookOptions<BusinessContextKpiQuery, BusinessContextKpiQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessContextKpiQuery, BusinessContextKpiQueryVariables>(
    BusinessContextKpiDocument,
    options
  );
}
export function useBusinessContextKpiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessContextKpiQuery,
    BusinessContextKpiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessContextKpiQuery, BusinessContextKpiQueryVariables>(
    BusinessContextKpiDocument,
    options
  );
}
export type BusinessContextKpiQueryHookResult = ReturnType<typeof useBusinessContextKpiQuery>;
export type BusinessContextKpiLazyQueryHookResult = ReturnType<
  typeof useBusinessContextKpiLazyQuery
>;
export type BusinessContextKpiQueryResult = Apollo.QueryResult<
  BusinessContextKpiQuery,
  BusinessContextKpiQueryVariables
>;
export const BusinessContextTargetsDocument = gql`
  query BusinessContextTargets(
    $bcId: ID!
    $selection: KpiTargetSelection!
    $fetchCostAllocation: Boolean!
    $fetchOverallWaste: Boolean!
    $fetchNonProdHours: Boolean!
  ) {
    businessContext(id: $bcId) {
      id
      kpis {
        costAllocationCoverage @include(if: $fetchCostAllocation) {
          targets(selection: $selection) {
            ...KpiTargetFields
          }
        }
        overallWaste @include(if: $fetchOverallWaste) {
          targets(selection: $selection) {
            ...KpiTargetFields
          }
        }
        nonProdHours @include(if: $fetchNonProdHours) {
          targets(selection: $selection) {
            ...KpiTargetFields
          }
        }
      }
    }
  }
  ${KpiTargetFieldsFragmentDoc}
`;

/**
 * __useBusinessContextTargetsQuery__
 *
 * To run a query within a React component, call `useBusinessContextTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextTargetsQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      selection: // value for 'selection'
 *      fetchCostAllocation: // value for 'fetchCostAllocation'
 *      fetchOverallWaste: // value for 'fetchOverallWaste'
 *      fetchNonProdHours: // value for 'fetchNonProdHours'
 *   },
 * });
 */
export function useBusinessContextTargetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BusinessContextTargetsQuery,
    BusinessContextTargetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessContextTargetsQuery, BusinessContextTargetsQueryVariables>(
    BusinessContextTargetsDocument,
    options
  );
}
export function useBusinessContextTargetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessContextTargetsQuery,
    BusinessContextTargetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessContextTargetsQuery, BusinessContextTargetsQueryVariables>(
    BusinessContextTargetsDocument,
    options
  );
}
export type BusinessContextTargetsQueryHookResult = ReturnType<
  typeof useBusinessContextTargetsQuery
>;
export type BusinessContextTargetsLazyQueryHookResult = ReturnType<
  typeof useBusinessContextTargetsLazyQuery
>;
export type BusinessContextTargetsQueryResult = Apollo.QueryResult<
  BusinessContextTargetsQuery,
  BusinessContextTargetsQueryVariables
>;
export const ArchiveKpiTargetDocument = gql`
  mutation ArchiveKpiTarget($id: ID!) {
    archiveKPITarget(id: $id) {
      ...KpiTargetFields
    }
  }
  ${KpiTargetFieldsFragmentDoc}
`;
export type ArchiveKpiTargetMutationFn = Apollo.MutationFunction<
  ArchiveKpiTargetMutation,
  ArchiveKpiTargetMutationVariables
>;

/**
 * __useArchiveKpiTargetMutation__
 *
 * To run a mutation, you first call `useArchiveKpiTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveKpiTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveKpiTargetMutation, { data, loading, error }] = useArchiveKpiTargetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveKpiTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveKpiTargetMutation,
    ArchiveKpiTargetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ArchiveKpiTargetMutation, ArchiveKpiTargetMutationVariables>(
    ArchiveKpiTargetDocument,
    options
  );
}
export type ArchiveKpiTargetMutationHookResult = ReturnType<typeof useArchiveKpiTargetMutation>;
export type ArchiveKpiTargetMutationResult = Apollo.MutationResult<ArchiveKpiTargetMutation>;
export type ArchiveKpiTargetMutationOptions = Apollo.BaseMutationOptions<
  ArchiveKpiTargetMutation,
  ArchiveKpiTargetMutationVariables
>;
export const DashboardBusinessContextKpiWidgetsDocument = gql`
  query DashboardBusinessContextKpiWidgets($bcId: ID!) {
    businessContext(id: $bcId) {
      id
      name
      costAllocation {
        allocated
      }
      kpis {
        costAllocationCoverage {
          ...KpiWidget
        }
        nonProdHours {
          ...KpiWidget
          totalHours
        }
        overallWaste {
          ...KpiWidget
          totalWaste
        }
      }
    }
  }
  ${KpiWidgetFragmentDoc}
`;

/**
 * __useDashboardBusinessContextKpiWidgetsQuery__
 *
 * To run a query within a React component, call `useDashboardBusinessContextKpiWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardBusinessContextKpiWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardBusinessContextKpiWidgetsQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *   },
 * });
 */
export function useDashboardBusinessContextKpiWidgetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DashboardBusinessContextKpiWidgetsQuery,
    DashboardBusinessContextKpiWidgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardBusinessContextKpiWidgetsQuery,
    DashboardBusinessContextKpiWidgetsQueryVariables
  >(DashboardBusinessContextKpiWidgetsDocument, options);
}
export function useDashboardBusinessContextKpiWidgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardBusinessContextKpiWidgetsQuery,
    DashboardBusinessContextKpiWidgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardBusinessContextKpiWidgetsQuery,
    DashboardBusinessContextKpiWidgetsQueryVariables
  >(DashboardBusinessContextKpiWidgetsDocument, options);
}
export type DashboardBusinessContextKpiWidgetsQueryHookResult = ReturnType<
  typeof useDashboardBusinessContextKpiWidgetsQuery
>;
export type DashboardBusinessContextKpiWidgetsLazyQueryHookResult = ReturnType<
  typeof useDashboardBusinessContextKpiWidgetsLazyQuery
>;
export type DashboardBusinessContextKpiWidgetsQueryResult = Apollo.QueryResult<
  DashboardBusinessContextKpiWidgetsQuery,
  DashboardBusinessContextKpiWidgetsQueryVariables
>;
export const AddKpiTargetDocument = gql`
  mutation AddKpiTarget($input: AddKpiTarget!) {
    addKPITarget(input: $input) {
      ...KpiTargetFields
    }
  }
  ${KpiTargetFieldsFragmentDoc}
`;
export type AddKpiTargetMutationFn = Apollo.MutationFunction<
  AddKpiTargetMutation,
  AddKpiTargetMutationVariables
>;

/**
 * __useAddKpiTargetMutation__
 *
 * To run a mutation, you first call `useAddKpiTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKpiTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKpiTargetMutation, { data, loading, error }] = useAddKpiTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddKpiTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<AddKpiTargetMutation, AddKpiTargetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddKpiTargetMutation, AddKpiTargetMutationVariables>(
    AddKpiTargetDocument,
    options
  );
}
export type AddKpiTargetMutationHookResult = ReturnType<typeof useAddKpiTargetMutation>;
export type AddKpiTargetMutationResult = Apollo.MutationResult<AddKpiTargetMutation>;
export type AddKpiTargetMutationOptions = Apollo.BaseMutationOptions<
  AddKpiTargetMutation,
  AddKpiTargetMutationVariables
>;
export const UpdateKpiTargetDocument = gql`
  mutation UpdateKpiTarget($id: ID!, $input: UpdateKpiTarget!) {
    updateKPITarget(id: $id, input: $input) {
      ...KpiTargetFields
    }
  }
  ${KpiTargetFieldsFragmentDoc}
`;
export type UpdateKpiTargetMutationFn = Apollo.MutationFunction<
  UpdateKpiTargetMutation,
  UpdateKpiTargetMutationVariables
>;

/**
 * __useUpdateKpiTargetMutation__
 *
 * To run a mutation, you first call `useUpdateKpiTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKpiTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKpiTargetMutation, { data, loading, error }] = useUpdateKpiTargetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKpiTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateKpiTargetMutation,
    UpdateKpiTargetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateKpiTargetMutation, UpdateKpiTargetMutationVariables>(
    UpdateKpiTargetDocument,
    options
  );
}
export type UpdateKpiTargetMutationHookResult = ReturnType<typeof useUpdateKpiTargetMutation>;
export type UpdateKpiTargetMutationResult = Apollo.MutationResult<UpdateKpiTargetMutation>;
export type UpdateKpiTargetMutationOptions = Apollo.BaseMutationOptions<
  UpdateKpiTargetMutation,
  UpdateKpiTargetMutationVariables
>;
export const BusinessContextsSimpleDocument = gql`
  query BusinessContextsSimple {
    businessContexts(limit: 100, page: 0) {
      businessContexts {
        id
        name
        color
      }
    }
  }
`;

/**
 * __useBusinessContextsSimpleQuery__
 *
 * To run a query within a React component, call `useBusinessContextsSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessContextsSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessContextsSimpleQuery({
 *   variables: {
 *   },
 * });
 */
export function useBusinessContextsSimpleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BusinessContextsSimpleQuery,
    BusinessContextsSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BusinessContextsSimpleQuery, BusinessContextsSimpleQueryVariables>(
    BusinessContextsSimpleDocument,
    options
  );
}
export function useBusinessContextsSimpleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessContextsSimpleQuery,
    BusinessContextsSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BusinessContextsSimpleQuery, BusinessContextsSimpleQueryVariables>(
    BusinessContextsSimpleDocument,
    options
  );
}
export type BusinessContextsSimpleQueryHookResult = ReturnType<
  typeof useBusinessContextsSimpleQuery
>;
export type BusinessContextsSimpleLazyQueryHookResult = ReturnType<
  typeof useBusinessContextsSimpleLazyQuery
>;
export type BusinessContextsSimpleQueryResult = Apollo.QueryResult<
  BusinessContextsSimpleQuery,
  BusinessContextsSimpleQueryVariables
>;
export const TeamLeaderDashboardDocument = gql`
  query TeamLeaderDashboard {
    applications {
      id
      nid
      name
      displayName
      cost {
        currentMonth
        currentMonthDaily
        previousMonth
        forecast
      }
      environments {
        id
        resourceCount
      }
    }
  }
`;

/**
 * __useTeamLeaderDashboardQuery__
 *
 * To run a query within a React component, call `useTeamLeaderDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamLeaderDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamLeaderDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamLeaderDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>(
    TeamLeaderDashboardDocument,
    options
  );
}
export function useTeamLeaderDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TeamLeaderDashboardQuery,
    TeamLeaderDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TeamLeaderDashboardQuery, TeamLeaderDashboardQueryVariables>(
    TeamLeaderDashboardDocument,
    options
  );
}
export type TeamLeaderDashboardQueryHookResult = ReturnType<typeof useTeamLeaderDashboardQuery>;
export type TeamLeaderDashboardLazyQueryHookResult = ReturnType<
  typeof useTeamLeaderDashboardLazyQuery
>;
export type TeamLeaderDashboardQueryResult = Apollo.QueryResult<
  TeamLeaderDashboardQuery,
  TeamLeaderDashboardQueryVariables
>;
export const CloudWasteSummaryCategorisedDocument = gql`
  query CloudWasteSummaryCategorised {
    cloudWasteSummaryCategorised {
      categories {
        category
        cost
        costYearly
        count
        policies
      }
      totalCost
      totalCostYearly
    }
  }
`;

/**
 * __useCloudWasteSummaryCategorisedQuery__
 *
 * To run a query within a React component, call `useCloudWasteSummaryCategorisedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudWasteSummaryCategorisedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudWasteSummaryCategorisedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudWasteSummaryCategorisedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CloudWasteSummaryCategorisedQuery,
    CloudWasteSummaryCategorisedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CloudWasteSummaryCategorisedQuery,
    CloudWasteSummaryCategorisedQueryVariables
  >(CloudWasteSummaryCategorisedDocument, options);
}
export function useCloudWasteSummaryCategorisedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudWasteSummaryCategorisedQuery,
    CloudWasteSummaryCategorisedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CloudWasteSummaryCategorisedQuery,
    CloudWasteSummaryCategorisedQueryVariables
  >(CloudWasteSummaryCategorisedDocument, options);
}
export type CloudWasteSummaryCategorisedQueryHookResult = ReturnType<
  typeof useCloudWasteSummaryCategorisedQuery
>;
export type CloudWasteSummaryCategorisedLazyQueryHookResult = ReturnType<
  typeof useCloudWasteSummaryCategorisedLazyQuery
>;
export type CloudWasteSummaryCategorisedQueryResult = Apollo.QueryResult<
  CloudWasteSummaryCategorisedQuery,
  CloudWasteSummaryCategorisedQueryVariables
>;
export const GetPrimaryBcDocument = gql`
  query GetPrimaryBC {
    primaryBusinessContext {
      id
      name
    }
  }
`;

/**
 * __useGetPrimaryBcQuery__
 *
 * To run a query within a React component, call `useGetPrimaryBcQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrimaryBcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrimaryBcQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrimaryBcQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPrimaryBcQuery, GetPrimaryBcQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrimaryBcQuery, GetPrimaryBcQueryVariables>(
    GetPrimaryBcDocument,
    options
  );
}
export function useGetPrimaryBcLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrimaryBcQuery, GetPrimaryBcQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrimaryBcQuery, GetPrimaryBcQueryVariables>(
    GetPrimaryBcDocument,
    options
  );
}
export type GetPrimaryBcQueryHookResult = ReturnType<typeof useGetPrimaryBcQuery>;
export type GetPrimaryBcLazyQueryHookResult = ReturnType<typeof useGetPrimaryBcLazyQuery>;
export type GetPrimaryBcQueryResult = Apollo.QueryResult<
  GetPrimaryBcQuery,
  GetPrimaryBcQueryVariables
>;
export const DashboardKpiWidgetsDocument = gql`
  query DashboardKpiWidgets {
    primaryBusinessContext {
      id
      name
      costAllocation {
        allocated
      }
      kpis {
        costAllocationCoverage {
          ...KpiWidget
        }
        nonProdHours {
          ...KpiWidget
          totalHours
        }
        overallWaste {
          ...KpiWidget
          totalWaste
        }
      }
    }
  }
  ${KpiWidgetFragmentDoc}
`;

/**
 * __useDashboardKpiWidgetsQuery__
 *
 * To run a query within a React component, call `useDashboardKpiWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardKpiWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardKpiWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardKpiWidgetsQuery(
  baseOptions?: Apollo.QueryHookOptions<DashboardKpiWidgetsQuery, DashboardKpiWidgetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardKpiWidgetsQuery, DashboardKpiWidgetsQueryVariables>(
    DashboardKpiWidgetsDocument,
    options
  );
}
export function useDashboardKpiWidgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardKpiWidgetsQuery,
    DashboardKpiWidgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardKpiWidgetsQuery, DashboardKpiWidgetsQueryVariables>(
    DashboardKpiWidgetsDocument,
    options
  );
}
export type DashboardKpiWidgetsQueryHookResult = ReturnType<typeof useDashboardKpiWidgetsQuery>;
export type DashboardKpiWidgetsLazyQueryHookResult = ReturnType<
  typeof useDashboardKpiWidgetsLazyQuery
>;
export type DashboardKpiWidgetsQueryResult = Apollo.QueryResult<
  DashboardKpiWidgetsQuery,
  DashboardKpiWidgetsQueryVariables
>;
export const ReservationsAndCommitmentsSavingsDocument = gql`
  query reservationsAndCommitmentsSavings(
    $paymentOption: ReservationsRecommendationPaymentOption!
    $term: ReservationsRecommendationTerm!
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
  ) {
    reservationsAndCommitmentsSavings(
      paymentOption: $paymentOption
      term: $term
      lookbackPeriod: $lookbackPeriod
    ) {
      totalMonthlySavings
      totalYearlySavings
    }
  }
`;

/**
 * __useReservationsAndCommitmentsSavingsQuery__
 *
 * To run a query within a React component, call `useReservationsAndCommitmentsSavingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsAndCommitmentsSavingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsAndCommitmentsSavingsQuery({
 *   variables: {
 *      paymentOption: // value for 'paymentOption'
 *      term: // value for 'term'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *   },
 * });
 */
export function useReservationsAndCommitmentsSavingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationsAndCommitmentsSavingsQuery,
    ReservationsAndCommitmentsSavingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReservationsAndCommitmentsSavingsQuery,
    ReservationsAndCommitmentsSavingsQueryVariables
  >(ReservationsAndCommitmentsSavingsDocument, options);
}
export function useReservationsAndCommitmentsSavingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationsAndCommitmentsSavingsQuery,
    ReservationsAndCommitmentsSavingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReservationsAndCommitmentsSavingsQuery,
    ReservationsAndCommitmentsSavingsQueryVariables
  >(ReservationsAndCommitmentsSavingsDocument, options);
}
export type ReservationsAndCommitmentsSavingsQueryHookResult = ReturnType<
  typeof useReservationsAndCommitmentsSavingsQuery
>;
export type ReservationsAndCommitmentsSavingsLazyQueryHookResult = ReturnType<
  typeof useReservationsAndCommitmentsSavingsLazyQuery
>;
export type ReservationsAndCommitmentsSavingsQueryResult = Apollo.QueryResult<
  ReservationsAndCommitmentsSavingsQuery,
  ReservationsAndCommitmentsSavingsQueryVariables
>;
export const AzureReservationsSavingsDocument = gql`
  query AzureReservationsSavings(
    $paymentOption: ReservationsRecommendationPaymentOption!
    $term: ReservationsRecommendationTerm!
    $serviceType: ReservationsRecommendationServiceType!
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
  ) {
    Compute: reservationsRecommendationsSummary(
      paymentOption: $paymentOption
      term: $term
      serviceType: $serviceType
      serviceUsageType: COMPUTE
      lookbackPeriod: $lookbackPeriod
      provider: AZURE
    ) {
      monthlySavings
    }
    Database: reservationsRecommendationsSummary(
      paymentOption: $paymentOption
      term: $term
      serviceType: $serviceType
      serviceUsageType: DATABASE
      lookbackPeriod: $lookbackPeriod
      provider: AZURE
    ) {
      monthlySavings
    }
    Storage: reservationsRecommendationsSummary(
      paymentOption: $paymentOption
      term: $term
      serviceType: $serviceType
      serviceUsageType: STORAGE
      lookbackPeriod: $lookbackPeriod
      provider: AZURE
    ) {
      monthlySavings
    }
    Analytics: reservationsRecommendationsSummary(
      paymentOption: $paymentOption
      term: $term
      serviceType: $serviceType
      serviceUsageType: ANALYTICS
      lookbackPeriod: $lookbackPeriod
      provider: AZURE
    ) {
      monthlySavings
    }
  }
`;

/**
 * __useAzureReservationsSavingsQuery__
 *
 * To run a query within a React component, call `useAzureReservationsSavingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAzureReservationsSavingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAzureReservationsSavingsQuery({
 *   variables: {
 *      paymentOption: // value for 'paymentOption'
 *      term: // value for 'term'
 *      serviceType: // value for 'serviceType'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *   },
 * });
 */
export function useAzureReservationsSavingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AzureReservationsSavingsQuery,
    AzureReservationsSavingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AzureReservationsSavingsQuery, AzureReservationsSavingsQueryVariables>(
    AzureReservationsSavingsDocument,
    options
  );
}
export function useAzureReservationsSavingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AzureReservationsSavingsQuery,
    AzureReservationsSavingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AzureReservationsSavingsQuery, AzureReservationsSavingsQueryVariables>(
    AzureReservationsSavingsDocument,
    options
  );
}
export type AzureReservationsSavingsQueryHookResult = ReturnType<
  typeof useAzureReservationsSavingsQuery
>;
export type AzureReservationsSavingsLazyQueryHookResult = ReturnType<
  typeof useAzureReservationsSavingsLazyQuery
>;
export type AzureReservationsSavingsQueryResult = Apollo.QueryResult<
  AzureReservationsSavingsQuery,
  AzureReservationsSavingsQueryVariables
>;
export const EstateRecordsAccountStatusDocument = gql`
  query EstateRecordsAccountStatus {
    accounts {
      id
      activated
    }
  }
`;

/**
 * __useEstateRecordsAccountStatusQuery__
 *
 * To run a query within a React component, call `useEstateRecordsAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateRecordsAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateRecordsAccountStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstateRecordsAccountStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EstateRecordsAccountStatusQuery,
    EstateRecordsAccountStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstateRecordsAccountStatusQuery, EstateRecordsAccountStatusQueryVariables>(
    EstateRecordsAccountStatusDocument,
    options
  );
}
export function useEstateRecordsAccountStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstateRecordsAccountStatusQuery,
    EstateRecordsAccountStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstateRecordsAccountStatusQuery,
    EstateRecordsAccountStatusQueryVariables
  >(EstateRecordsAccountStatusDocument, options);
}
export type EstateRecordsAccountStatusQueryHookResult = ReturnType<
  typeof useEstateRecordsAccountStatusQuery
>;
export type EstateRecordsAccountStatusLazyQueryHookResult = ReturnType<
  typeof useEstateRecordsAccountStatusLazyQuery
>;
export type EstateRecordsAccountStatusQueryResult = Apollo.QueryResult<
  EstateRecordsAccountStatusQuery,
  EstateRecordsAccountStatusQueryVariables
>;
export const ApplicationsCountDocument = gql`
  query ApplicationsCount {
    applicationsPaginated(limit: 1, page: 0) {
      count
    }
  }
`;

/**
 * __useApplicationsCountQuery__
 *
 * To run a query within a React component, call `useApplicationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<ApplicationsCountQuery, ApplicationsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsCountQuery, ApplicationsCountQueryVariables>(
    ApplicationsCountDocument,
    options
  );
}
export function useApplicationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsCountQuery, ApplicationsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApplicationsCountQuery, ApplicationsCountQueryVariables>(
    ApplicationsCountDocument,
    options
  );
}
export type ApplicationsCountQueryHookResult = ReturnType<typeof useApplicationsCountQuery>;
export type ApplicationsCountLazyQueryHookResult = ReturnType<typeof useApplicationsCountLazyQuery>;
export type ApplicationsCountQueryResult = Apollo.QueryResult<
  ApplicationsCountQuery,
  ApplicationsCountQueryVariables
>;
export const DriftedResourcesCountDocument = gql`
  query DriftedResourcesCount {
    driftedResources(limit: 1, page: 0) {
      count
    }
  }
`;

/**
 * __useDriftedResourcesCountQuery__
 *
 * To run a query within a React component, call `useDriftedResourcesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriftedResourcesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriftedResourcesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriftedResourcesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DriftedResourcesCountQuery,
    DriftedResourcesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DriftedResourcesCountQuery, DriftedResourcesCountQueryVariables>(
    DriftedResourcesCountDocument,
    options
  );
}
export function useDriftedResourcesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DriftedResourcesCountQuery,
    DriftedResourcesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DriftedResourcesCountQuery, DriftedResourcesCountQueryVariables>(
    DriftedResourcesCountDocument,
    options
  );
}
export type DriftedResourcesCountQueryHookResult = ReturnType<typeof useDriftedResourcesCountQuery>;
export type DriftedResourcesCountLazyQueryHookResult = ReturnType<
  typeof useDriftedResourcesCountLazyQuery
>;
export type DriftedResourcesCountQueryResult = Apollo.QueryResult<
  DriftedResourcesCountQuery,
  DriftedResourcesCountQueryVariables
>;
export const OrganizationCostDocument = gql`
  query OrganizationCost {
    organization {
      id
      cost {
        currentMonth
        previousMonth
        previousMonthDayToDay
        forecast
      }
    }
  }
`;

/**
 * __useOrganizationCostQuery__
 *
 * To run a query within a React component, call `useOrganizationCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCostQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationCostQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationCostQuery, OrganizationCostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationCostQuery, OrganizationCostQueryVariables>(
    OrganizationCostDocument,
    options
  );
}
export function useOrganizationCostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationCostQuery, OrganizationCostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationCostQuery, OrganizationCostQueryVariables>(
    OrganizationCostDocument,
    options
  );
}
export type OrganizationCostQueryHookResult = ReturnType<typeof useOrganizationCostQuery>;
export type OrganizationCostLazyQueryHookResult = ReturnType<typeof useOrganizationCostLazyQuery>;
export type OrganizationCostQueryResult = Apollo.QueryResult<
  OrganizationCostQuery,
  OrganizationCostQueryVariables
>;
export const GlobalCostByProviderDocument = gql`
  query GlobalCostByProvider {
    organization {
      id
      awsCost: cost(providers: [AWS]) {
        ...BaseCost
      }
      azureCost: cost(providers: [AZURE]) {
        ...BaseCost
      }
      gcpCost: cost(providers: [GCP]) {
        ...BaseCost
      }
      ibmCloudCost: cost(providers: [IBMCLOUD]) {
        ...BaseCost
      }
      kubernetesCost: cost(providers: [KUBERNETES]) {
        ...BaseCost
      }
      openShiftCost: cost(providers: [OPENSHIFT]) {
        ...BaseCost
      }
    }
  }
  ${BaseCostFragmentDoc}
`;

/**
 * __useGlobalCostByProviderQuery__
 *
 * To run a query within a React component, call `useGlobalCostByProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalCostByProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalCostByProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalCostByProviderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GlobalCostByProviderQuery,
    GlobalCostByProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalCostByProviderQuery, GlobalCostByProviderQueryVariables>(
    GlobalCostByProviderDocument,
    options
  );
}
export function useGlobalCostByProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalCostByProviderQuery,
    GlobalCostByProviderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalCostByProviderQuery, GlobalCostByProviderQueryVariables>(
    GlobalCostByProviderDocument,
    options
  );
}
export type GlobalCostByProviderQueryHookResult = ReturnType<typeof useGlobalCostByProviderQuery>;
export type GlobalCostByProviderLazyQueryHookResult = ReturnType<
  typeof useGlobalCostByProviderLazyQuery
>;
export type GlobalCostByProviderQueryResult = Apollo.QueryResult<
  GlobalCostByProviderQuery,
  GlobalCostByProviderQueryVariables
>;
export const TopSpendingApplicationsDocument = gql`
  query TopSpendingApplications($limit: Int!) {
    topSpendingApplications(limit: $limit) {
      topSpending {
        ...Spends
      }
      defaultApplication {
        ...Spends
      }
      other {
        ...Spends
      }
      total {
        currentMonth
      }
    }
  }
  ${SpendsFragmentDoc}
`;

/**
 * __useTopSpendingApplicationsQuery__
 *
 * To run a query within a React component, call `useTopSpendingApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopSpendingApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopSpendingApplicationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTopSpendingApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopSpendingApplicationsQuery,
    TopSpendingApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopSpendingApplicationsQuery, TopSpendingApplicationsQueryVariables>(
    TopSpendingApplicationsDocument,
    options
  );
}
export function useTopSpendingApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopSpendingApplicationsQuery,
    TopSpendingApplicationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopSpendingApplicationsQuery, TopSpendingApplicationsQueryVariables>(
    TopSpendingApplicationsDocument,
    options
  );
}
export type TopSpendingApplicationsQueryHookResult = ReturnType<
  typeof useTopSpendingApplicationsQuery
>;
export type TopSpendingApplicationsLazyQueryHookResult = ReturnType<
  typeof useTopSpendingApplicationsLazyQuery
>;
export type TopSpendingApplicationsQueryResult = Apollo.QueryResult<
  TopSpendingApplicationsQuery,
  TopSpendingApplicationsQueryVariables
>;
export const IntegrationPluginsDocument = gql`
  query integrationPlugins($filter: IntegrationPluginsInput) {
    integrationPlugins(filter: $filter) {
      id
      name
      description
      lastSyncTime
      lastSyncStatus
      contactPersons {
        id
        name
      }
      integrationType
      createdAt
    }
  }
`;

/**
 * __useIntegrationPluginsQuery__
 *
 * To run a query within a React component, call `useIntegrationPluginsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationPluginsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationPluginsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIntegrationPluginsQuery(
  baseOptions?: Apollo.QueryHookOptions<IntegrationPluginsQuery, IntegrationPluginsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IntegrationPluginsQuery, IntegrationPluginsQueryVariables>(
    IntegrationPluginsDocument,
    options
  );
}
export function useIntegrationPluginsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationPluginsQuery,
    IntegrationPluginsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IntegrationPluginsQuery, IntegrationPluginsQueryVariables>(
    IntegrationPluginsDocument,
    options
  );
}
export type IntegrationPluginsQueryHookResult = ReturnType<typeof useIntegrationPluginsQuery>;
export type IntegrationPluginsLazyQueryHookResult = ReturnType<
  typeof useIntegrationPluginsLazyQuery
>;
export type IntegrationPluginsQueryResult = Apollo.QueryResult<
  IntegrationPluginsQuery,
  IntegrationPluginsQueryVariables
>;
export const TurbonomicIntegrationPluginDocument = gql`
  query turbonomicIntegrationPlugin($id: ID!) {
    turbonomicIntegrationPlugin(id: $id) {
      id
      name
      description
      lastSyncTime
      lastSyncStatus
      contactPersons {
        id
        name
      }
      integrationType
      createdAt
      url
      username
    }
  }
`;

/**
 * __useTurbonomicIntegrationPluginQuery__
 *
 * To run a query within a React component, call `useTurbonomicIntegrationPluginQuery` and pass it any options that fit your needs.
 * When your component renders, `useTurbonomicIntegrationPluginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTurbonomicIntegrationPluginQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTurbonomicIntegrationPluginQuery(
  baseOptions: Apollo.QueryHookOptions<
    TurbonomicIntegrationPluginQuery,
    TurbonomicIntegrationPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TurbonomicIntegrationPluginQuery,
    TurbonomicIntegrationPluginQueryVariables
  >(TurbonomicIntegrationPluginDocument, options);
}
export function useTurbonomicIntegrationPluginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TurbonomicIntegrationPluginQuery,
    TurbonomicIntegrationPluginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TurbonomicIntegrationPluginQuery,
    TurbonomicIntegrationPluginQueryVariables
  >(TurbonomicIntegrationPluginDocument, options);
}
export type TurbonomicIntegrationPluginQueryHookResult = ReturnType<
  typeof useTurbonomicIntegrationPluginQuery
>;
export type TurbonomicIntegrationPluginLazyQueryHookResult = ReturnType<
  typeof useTurbonomicIntegrationPluginLazyQuery
>;
export type TurbonomicIntegrationPluginQueryResult = Apollo.QueryResult<
  TurbonomicIntegrationPluginQuery,
  TurbonomicIntegrationPluginQueryVariables
>;
export const RemoveIntegrationPluginDocument = gql`
  mutation RemoveIntegrationPlugin($id: ID!) {
    removeIntegrationPlugin(id: $id)
  }
`;
export type RemoveIntegrationPluginMutationFn = Apollo.MutationFunction<
  RemoveIntegrationPluginMutation,
  RemoveIntegrationPluginMutationVariables
>;

/**
 * __useRemoveIntegrationPluginMutation__
 *
 * To run a mutation, you first call `useRemoveIntegrationPluginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIntegrationPluginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIntegrationPluginMutation, { data, loading, error }] = useRemoveIntegrationPluginMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveIntegrationPluginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveIntegrationPluginMutation,
    RemoveIntegrationPluginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveIntegrationPluginMutation,
    RemoveIntegrationPluginMutationVariables
  >(RemoveIntegrationPluginDocument, options);
}
export type RemoveIntegrationPluginMutationHookResult = ReturnType<
  typeof useRemoveIntegrationPluginMutation
>;
export type RemoveIntegrationPluginMutationResult =
  Apollo.MutationResult<RemoveIntegrationPluginMutation>;
export type RemoveIntegrationPluginMutationOptions = Apollo.BaseMutationOptions<
  RemoveIntegrationPluginMutation,
  RemoveIntegrationPluginMutationVariables
>;
export const AddTurbonomicIntegrationPluginV2Document = gql`
  mutation addTurbonomicIntegrationPluginV2($input: AddTurbonomicIntegrationPluginInput!) {
    addTurbonomicIntegrationPluginV2(input: $input) {
      id
      name
      description
      lastSyncTime
      lastSyncStatus
      contactPersons {
        id
        name
      }
      integrationType
      createdAt
    }
  }
`;
export type AddTurbonomicIntegrationPluginV2MutationFn = Apollo.MutationFunction<
  AddTurbonomicIntegrationPluginV2Mutation,
  AddTurbonomicIntegrationPluginV2MutationVariables
>;

/**
 * __useAddTurbonomicIntegrationPluginV2Mutation__
 *
 * To run a mutation, you first call `useAddTurbonomicIntegrationPluginV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTurbonomicIntegrationPluginV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTurbonomicIntegrationPluginV2Mutation, { data, loading, error }] = useAddTurbonomicIntegrationPluginV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTurbonomicIntegrationPluginV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTurbonomicIntegrationPluginV2Mutation,
    AddTurbonomicIntegrationPluginV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTurbonomicIntegrationPluginV2Mutation,
    AddTurbonomicIntegrationPluginV2MutationVariables
  >(AddTurbonomicIntegrationPluginV2Document, options);
}
export type AddTurbonomicIntegrationPluginV2MutationHookResult = ReturnType<
  typeof useAddTurbonomicIntegrationPluginV2Mutation
>;
export type AddTurbonomicIntegrationPluginV2MutationResult =
  Apollo.MutationResult<AddTurbonomicIntegrationPluginV2Mutation>;
export type AddTurbonomicIntegrationPluginV2MutationOptions = Apollo.BaseMutationOptions<
  AddTurbonomicIntegrationPluginV2Mutation,
  AddTurbonomicIntegrationPluginV2MutationVariables
>;
export const TurbonomicConnectionStatusDocument = gql`
  query TurbonomicConnectionStatus($connection: TurbonomicConnectionInput!) {
    turbonomicConnectionStatus(connection: $connection)
  }
`;

/**
 * __useTurbonomicConnectionStatusQuery__
 *
 * To run a query within a React component, call `useTurbonomicConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTurbonomicConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTurbonomicConnectionStatusQuery({
 *   variables: {
 *      connection: // value for 'connection'
 *   },
 * });
 */
export function useTurbonomicConnectionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    TurbonomicConnectionStatusQuery,
    TurbonomicConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TurbonomicConnectionStatusQuery, TurbonomicConnectionStatusQueryVariables>(
    TurbonomicConnectionStatusDocument,
    options
  );
}
export function useTurbonomicConnectionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TurbonomicConnectionStatusQuery,
    TurbonomicConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TurbonomicConnectionStatusQuery,
    TurbonomicConnectionStatusQueryVariables
  >(TurbonomicConnectionStatusDocument, options);
}
export type TurbonomicConnectionStatusQueryHookResult = ReturnType<
  typeof useTurbonomicConnectionStatusQuery
>;
export type TurbonomicConnectionStatusLazyQueryHookResult = ReturnType<
  typeof useTurbonomicConnectionStatusLazyQuery
>;
export type TurbonomicConnectionStatusQueryResult = Apollo.QueryResult<
  TurbonomicConnectionStatusQuery,
  TurbonomicConnectionStatusQueryVariables
>;
export const TurbonomicPluginConnectionStatusDocument = gql`
  query turbonomicPluginConnectionStatus($id: ID!) {
    turbonomicPluginConnectionStatus(id: $id)
  }
`;

/**
 * __useTurbonomicPluginConnectionStatusQuery__
 *
 * To run a query within a React component, call `useTurbonomicPluginConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTurbonomicPluginConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTurbonomicPluginConnectionStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTurbonomicPluginConnectionStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    TurbonomicPluginConnectionStatusQuery,
    TurbonomicPluginConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TurbonomicPluginConnectionStatusQuery,
    TurbonomicPluginConnectionStatusQueryVariables
  >(TurbonomicPluginConnectionStatusDocument, options);
}
export function useTurbonomicPluginConnectionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TurbonomicPluginConnectionStatusQuery,
    TurbonomicPluginConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TurbonomicPluginConnectionStatusQuery,
    TurbonomicPluginConnectionStatusQueryVariables
  >(TurbonomicPluginConnectionStatusDocument, options);
}
export type TurbonomicPluginConnectionStatusQueryHookResult = ReturnType<
  typeof useTurbonomicPluginConnectionStatusQuery
>;
export type TurbonomicPluginConnectionStatusLazyQueryHookResult = ReturnType<
  typeof useTurbonomicPluginConnectionStatusLazyQuery
>;
export type TurbonomicPluginConnectionStatusQueryResult = Apollo.QueryResult<
  TurbonomicPluginConnectionStatusQuery,
  TurbonomicPluginConnectionStatusQueryVariables
>;
export const UpdateTurbonomicIntegrationPluginDocument = gql`
  mutation updateTurbonomicIntegrationPlugin(
    $id: ID!
    $input: UpdateTurbonomicIntegrationPluginInput!
  ) {
    updateTurbonomicIntegrationPlugin(id: $id, input: $input) {
      id
      name
      description
      integrationType
      createdAt
      contactPersons {
        id
        name
      }
      lastSyncStatus
      lastSyncTime
    }
  }
`;
export type UpdateTurbonomicIntegrationPluginMutationFn = Apollo.MutationFunction<
  UpdateTurbonomicIntegrationPluginMutation,
  UpdateTurbonomicIntegrationPluginMutationVariables
>;

/**
 * __useUpdateTurbonomicIntegrationPluginMutation__
 *
 * To run a mutation, you first call `useUpdateTurbonomicIntegrationPluginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTurbonomicIntegrationPluginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTurbonomicIntegrationPluginMutation, { data, loading, error }] = useUpdateTurbonomicIntegrationPluginMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTurbonomicIntegrationPluginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTurbonomicIntegrationPluginMutation,
    UpdateTurbonomicIntegrationPluginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTurbonomicIntegrationPluginMutation,
    UpdateTurbonomicIntegrationPluginMutationVariables
  >(UpdateTurbonomicIntegrationPluginDocument, options);
}
export type UpdateTurbonomicIntegrationPluginMutationHookResult = ReturnType<
  typeof useUpdateTurbonomicIntegrationPluginMutation
>;
export type UpdateTurbonomicIntegrationPluginMutationResult =
  Apollo.MutationResult<UpdateTurbonomicIntegrationPluginMutation>;
export type UpdateTurbonomicIntegrationPluginMutationOptions = Apollo.BaseMutationOptions<
  UpdateTurbonomicIntegrationPluginMutation,
  UpdateTurbonomicIntegrationPluginMutationVariables
>;
export const PeopleDocument = gql`
  query People {
    people {
      ...PersonFields
    }
  }
  ${PersonFieldsFragmentDoc}
`;

/**
 * __usePeopleQuery__
 *
 * To run a query within a React component, call `usePeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<PeopleQuery, PeopleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, options);
}
export function usePeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PeopleQuery, PeopleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PeopleQuery, PeopleQueryVariables>(PeopleDocument, options);
}
export type PeopleQueryHookResult = ReturnType<typeof usePeopleQuery>;
export type PeopleLazyQueryHookResult = ReturnType<typeof usePeopleLazyQuery>;
export type PeopleQueryResult = Apollo.QueryResult<PeopleQuery, PeopleQueryVariables>;
export const ReservationsRecommendationsSummaryDocument = gql`
  query reservationsRecommendationsSummary(
    $paymentOption: ReservationsRecommendationPaymentOption!
    $term: ReservationsRecommendationTerm!
    $serviceType: ReservationsRecommendationServiceType!
    $offeringType: ReservationsAndCommitmentsCoverageOfferingType
    $serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
    $filters: ReservationsAndCommitmentsFilters
    $provider: ReservationsProvider
  ) {
    reservationsRecommendationsSummary(
      paymentOption: $paymentOption
      term: $term
      serviceType: $serviceType
      offeringType: $offeringType
      serviceUsageType: $serviceUsageType
      lookbackPeriod: $lookbackPeriod
      filters: $filters
      provider: $provider
    ) {
      monthlySavings
      recurringMonthlyCost
      upfrontCost
      yearlySavings
      savingsRate
      savingsPlansCommitments {
        account
        savingsPerHour
        onDemandCostPerHour
        savingsPlansHourlyCommitmentToPurchase
      }
      count
    }
  }
`;

/**
 * __useReservationsRecommendationsSummaryQuery__
 *
 * To run a query within a React component, call `useReservationsRecommendationsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsRecommendationsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsRecommendationsSummaryQuery({
 *   variables: {
 *      paymentOption: // value for 'paymentOption'
 *      term: // value for 'term'
 *      serviceType: // value for 'serviceType'
 *      offeringType: // value for 'offeringType'
 *      serviceUsageType: // value for 'serviceUsageType'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *      filters: // value for 'filters'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useReservationsRecommendationsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationsRecommendationsSummaryQuery,
    ReservationsRecommendationsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReservationsRecommendationsSummaryQuery,
    ReservationsRecommendationsSummaryQueryVariables
  >(ReservationsRecommendationsSummaryDocument, options);
}
export function useReservationsRecommendationsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationsRecommendationsSummaryQuery,
    ReservationsRecommendationsSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReservationsRecommendationsSummaryQuery,
    ReservationsRecommendationsSummaryQueryVariables
  >(ReservationsRecommendationsSummaryDocument, options);
}
export type ReservationsRecommendationsSummaryQueryHookResult = ReturnType<
  typeof useReservationsRecommendationsSummaryQuery
>;
export type ReservationsRecommendationsSummaryLazyQueryHookResult = ReturnType<
  typeof useReservationsRecommendationsSummaryLazyQuery
>;
export type ReservationsRecommendationsSummaryQueryResult = Apollo.QueryResult<
  ReservationsRecommendationsSummaryQuery,
  ReservationsRecommendationsSummaryQueryVariables
>;
export const ListReservationsAndCommitmentsCoverageDocument = gql`
  query listReservationsAndCommitmentsCoverage(
    $limit: Int!
    $page: Int!
    $offeringType: ReservationsAndCommitmentsCoverageOfferingType
    $serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
    $paymentOption: ReservationsRecommendationPaymentOption
    $term: ReservationsRecommendationTerm
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
    $filters: ReservationsAndCommitmentsFilters
    $provider: ReservationsProvider
  ) {
    listReservationsAndCommitmentsCoverage(
      limit: $limit
      page: $page
      offeringType: $offeringType
      serviceUsageType: $serviceUsageType
      paymentOption: $paymentOption
      term: $term
      lookbackPeriod: $lookbackPeriod
      filters: $filters
      provider: $provider
    ) {
      pages
      count
      groupedResources {
        platform
        resources {
          resource
          resourceTypesCount
          platform
          region
          provider
          averageCoverage
          totalCoveredHours
          totalOnDemandHours
          totalOnDemandCost
          coverageSavedCost
          measurementUnit
          hasCoverageResourceList
          offeringType
          hasRecommendations
          potentialSavings
          onDemandCostPercent
          riCostPercent
          spCostPercent
          groupByParameter
        }
      }
    }
  }
`;

/**
 * __useListReservationsAndCommitmentsCoverageQuery__
 *
 * To run a query within a React component, call `useListReservationsAndCommitmentsCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReservationsAndCommitmentsCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReservationsAndCommitmentsCoverageQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      offeringType: // value for 'offeringType'
 *      serviceUsageType: // value for 'serviceUsageType'
 *      paymentOption: // value for 'paymentOption'
 *      term: // value for 'term'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *      filters: // value for 'filters'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useListReservationsAndCommitmentsCoverageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListReservationsAndCommitmentsCoverageQuery,
    ListReservationsAndCommitmentsCoverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListReservationsAndCommitmentsCoverageQuery,
    ListReservationsAndCommitmentsCoverageQueryVariables
  >(ListReservationsAndCommitmentsCoverageDocument, options);
}
export function useListReservationsAndCommitmentsCoverageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListReservationsAndCommitmentsCoverageQuery,
    ListReservationsAndCommitmentsCoverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListReservationsAndCommitmentsCoverageQuery,
    ListReservationsAndCommitmentsCoverageQueryVariables
  >(ListReservationsAndCommitmentsCoverageDocument, options);
}
export type ListReservationsAndCommitmentsCoverageQueryHookResult = ReturnType<
  typeof useListReservationsAndCommitmentsCoverageQuery
>;
export type ListReservationsAndCommitmentsCoverageLazyQueryHookResult = ReturnType<
  typeof useListReservationsAndCommitmentsCoverageLazyQuery
>;
export type ListReservationsAndCommitmentsCoverageQueryResult = Apollo.QueryResult<
  ListReservationsAndCommitmentsCoverageQuery,
  ListReservationsAndCommitmentsCoverageQueryVariables
>;
export const ListReservationsAndCommitmentsCoverageDetailsDocument = gql`
  query listReservationsAndCommitmentsCoverageDetails(
    $region: String!
    $platform: String!
    $resourceType: String!
    $offeringType: ReservationsAndCommitmentsCoverageOfferingType
    $serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
    $provider: ReservationsProvider
  ) {
    listReservationsAndCommitmentsCoverageDetails(
      region: $region
      platform: $platform
      resourceType: $resourceType
      offeringType: $offeringType
      serviceUsageType: $serviceUsageType
      lookbackPeriod: $lookbackPeriod
      provider: $provider
    ) {
      resources {
        resource
        resourceTypesCount
        platform
        potentialSavings
        region
        provider
        averageCoverage
        totalCoveredHours
        totalOnDemandHours
        totalOnDemandCost
        coverageSavedCost
        measurementUnit
        onDemandCostPercent
        riCostPercent
        spCostPercent
      }
    }
  }
`;

/**
 * __useListReservationsAndCommitmentsCoverageDetailsQuery__
 *
 * To run a query within a React component, call `useListReservationsAndCommitmentsCoverageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReservationsAndCommitmentsCoverageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReservationsAndCommitmentsCoverageDetailsQuery({
 *   variables: {
 *      region: // value for 'region'
 *      platform: // value for 'platform'
 *      resourceType: // value for 'resourceType'
 *      offeringType: // value for 'offeringType'
 *      serviceUsageType: // value for 'serviceUsageType'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useListReservationsAndCommitmentsCoverageDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListReservationsAndCommitmentsCoverageDetailsQuery,
    ListReservationsAndCommitmentsCoverageDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListReservationsAndCommitmentsCoverageDetailsQuery,
    ListReservationsAndCommitmentsCoverageDetailsQueryVariables
  >(ListReservationsAndCommitmentsCoverageDetailsDocument, options);
}
export function useListReservationsAndCommitmentsCoverageDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListReservationsAndCommitmentsCoverageDetailsQuery,
    ListReservationsAndCommitmentsCoverageDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListReservationsAndCommitmentsCoverageDetailsQuery,
    ListReservationsAndCommitmentsCoverageDetailsQueryVariables
  >(ListReservationsAndCommitmentsCoverageDetailsDocument, options);
}
export type ListReservationsAndCommitmentsCoverageDetailsQueryHookResult = ReturnType<
  typeof useListReservationsAndCommitmentsCoverageDetailsQuery
>;
export type ListReservationsAndCommitmentsCoverageDetailsLazyQueryHookResult = ReturnType<
  typeof useListReservationsAndCommitmentsCoverageDetailsLazyQuery
>;
export type ListReservationsAndCommitmentsCoverageDetailsQueryResult = Apollo.QueryResult<
  ListReservationsAndCommitmentsCoverageDetailsQuery,
  ListReservationsAndCommitmentsCoverageDetailsQueryVariables
>;
export const ReservationsFiltersDocument = gql`
  query reservationsFilters(
    $offeringType: ReservationsAndCommitmentsCoverageOfferingType!
    $serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType!
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod!
    $provider: ReservationsProvider!
  ) {
    reservationsFilters(
      offeringType: $offeringType
      serviceUsageType: $serviceUsageType
      lookbackPeriod: $lookbackPeriod
      provider: $provider
    ) {
      regionsList
      resourcesList
      platformsList
    }
  }
`;

/**
 * __useReservationsFiltersQuery__
 *
 * To run a query within a React component, call `useReservationsFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsFiltersQuery({
 *   variables: {
 *      offeringType: // value for 'offeringType'
 *      serviceUsageType: // value for 'serviceUsageType'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useReservationsFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<ReservationsFiltersQuery, ReservationsFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationsFiltersQuery, ReservationsFiltersQueryVariables>(
    ReservationsFiltersDocument,
    options
  );
}
export function useReservationsFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationsFiltersQuery,
    ReservationsFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationsFiltersQuery, ReservationsFiltersQueryVariables>(
    ReservationsFiltersDocument,
    options
  );
}
export type ReservationsFiltersQueryHookResult = ReturnType<typeof useReservationsFiltersQuery>;
export type ReservationsFiltersLazyQueryHookResult = ReturnType<
  typeof useReservationsFiltersLazyQuery
>;
export type ReservationsFiltersQueryResult = Apollo.QueryResult<
  ReservationsFiltersQuery,
  ReservationsFiltersQueryVariables
>;
export const ReservationsAndCommitmentUsageCoverageDocument = gql`
  query reservationsAndCommitmentUsageCoverage(
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
    $serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType!
    $filters: ReservationsAndCommitmentsFilters
    $provider: ReservationsProvider
  ) {
    reservationsAndCommitmentUsageCoverage(
      lookbackPeriod: $lookbackPeriod
      serviceUsageType: $serviceUsageType
      filters: $filters
      provider: $provider
    ) {
      averageCoverage
      riAverageCoverage
      spAverageCoverage
      totalOnDemandCost
      savedCost
      timeSeries {
        date
        riCost
        spCost
        onDemandCost
        totalCost
      }
    }
  }
`;

/**
 * __useReservationsAndCommitmentUsageCoverageQuery__
 *
 * To run a query within a React component, call `useReservationsAndCommitmentUsageCoverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsAndCommitmentUsageCoverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsAndCommitmentUsageCoverageQuery({
 *   variables: {
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *      serviceUsageType: // value for 'serviceUsageType'
 *      filters: // value for 'filters'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useReservationsAndCommitmentUsageCoverageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationsAndCommitmentUsageCoverageQuery,
    ReservationsAndCommitmentUsageCoverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReservationsAndCommitmentUsageCoverageQuery,
    ReservationsAndCommitmentUsageCoverageQueryVariables
  >(ReservationsAndCommitmentUsageCoverageDocument, options);
}
export function useReservationsAndCommitmentUsageCoverageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationsAndCommitmentUsageCoverageQuery,
    ReservationsAndCommitmentUsageCoverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReservationsAndCommitmentUsageCoverageQuery,
    ReservationsAndCommitmentUsageCoverageQueryVariables
  >(ReservationsAndCommitmentUsageCoverageDocument, options);
}
export type ReservationsAndCommitmentUsageCoverageQueryHookResult = ReturnType<
  typeof useReservationsAndCommitmentUsageCoverageQuery
>;
export type ReservationsAndCommitmentUsageCoverageLazyQueryHookResult = ReturnType<
  typeof useReservationsAndCommitmentUsageCoverageLazyQuery
>;
export type ReservationsAndCommitmentUsageCoverageQueryResult = Apollo.QueryResult<
  ReservationsAndCommitmentUsageCoverageQuery,
  ReservationsAndCommitmentUsageCoverageQueryVariables
>;
export const GetReservationsAndCommitmentsRecommendationsDocument = gql`
  query getReservationsAndCommitmentsRecommendations(
    $resourceType: String!
    $region: String!
    $platform: String!
    $provider: String!
    $paymentOption: ReservationsRecommendationPaymentOption!
    $term: ReservationsRecommendationTerm!
    $offeringType: ReservationsAndCommitmentsCoverageOfferingType!
    $serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType!
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod
  ) {
    getReservationsAndCommitmentsRecommendations(
      resourceType: $resourceType
      region: $region
      platform: $platform
      provider: $provider
      paymentOption: $paymentOption
      term: $term
      offeringType: $offeringType
      serviceUsageType: $serviceUsageType
      lookbackPeriod: $lookbackPeriod
    ) {
      expectedUtilization
      instanceType
      monthlySavings
      monthlySavingsPercentage
      numberOfInstances
      recurringMonthlyCost
      upfrontCost
      yearlySavings
      startSavings
      breakEvenPointXAxis
      breakEvenPointYAxis
      timeSeries {
        label
        onDemandCost
        riCost
      }
    }
  }
`;

/**
 * __useGetReservationsAndCommitmentsRecommendationsQuery__
 *
 * To run a query within a React component, call `useGetReservationsAndCommitmentsRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsAndCommitmentsRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsAndCommitmentsRecommendationsQuery({
 *   variables: {
 *      resourceType: // value for 'resourceType'
 *      region: // value for 'region'
 *      platform: // value for 'platform'
 *      provider: // value for 'provider'
 *      paymentOption: // value for 'paymentOption'
 *      term: // value for 'term'
 *      offeringType: // value for 'offeringType'
 *      serviceUsageType: // value for 'serviceUsageType'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *   },
 * });
 */
export function useGetReservationsAndCommitmentsRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReservationsAndCommitmentsRecommendationsQuery,
    GetReservationsAndCommitmentsRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetReservationsAndCommitmentsRecommendationsQuery,
    GetReservationsAndCommitmentsRecommendationsQueryVariables
  >(GetReservationsAndCommitmentsRecommendationsDocument, options);
}
export function useGetReservationsAndCommitmentsRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReservationsAndCommitmentsRecommendationsQuery,
    GetReservationsAndCommitmentsRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetReservationsAndCommitmentsRecommendationsQuery,
    GetReservationsAndCommitmentsRecommendationsQueryVariables
  >(GetReservationsAndCommitmentsRecommendationsDocument, options);
}
export type GetReservationsAndCommitmentsRecommendationsQueryHookResult = ReturnType<
  typeof useGetReservationsAndCommitmentsRecommendationsQuery
>;
export type GetReservationsAndCommitmentsRecommendationsLazyQueryHookResult = ReturnType<
  typeof useGetReservationsAndCommitmentsRecommendationsLazyQuery
>;
export type GetReservationsAndCommitmentsRecommendationsQueryResult = Apollo.QueryResult<
  GetReservationsAndCommitmentsRecommendationsQuery,
  GetReservationsAndCommitmentsRecommendationsQueryVariables
>;
export const ReservationsAndMetadataDocument = gql`
  query reservationsAndMetadata(
    $limit: Int
    $page: Int
    $offeringType: ReservationsAndCommitmentsCoverageOfferingType
    $serviceUsageType: ReservationsAndCommitmentsCoverageServiceUsageType
    $onlyCount: Boolean
    $filters: ReservationAndMetadataFilters
    $provider: ReservationsProvider
  ) {
    reservationsAndMetadata(
      limit: $limit
      page: $page
      offeringType: $offeringType
      serviceUsageType: $serviceUsageType
      onlyCount: $onlyCount
      filters: $filters
      provider: $provider
    ) {
      count
      pages
      filters {
        platforms
        resourceTypes
      }
      reservations {
        reservationId
        from
        to
        paymentOption
        instanceType
        productDescription
        region
        unitsCount
        expirationTimeInDays
        expirationTimeInYears
        offeringClass
        commitment
        savingsPlanType
        ec2InstanceFamily
        billingPlan
        originalQuantity
        skuName
        yearlyUtilization {
          utilization
          benefit
        }
      }
    }
  }
`;

/**
 * __useReservationsAndMetadataQuery__
 *
 * To run a query within a React component, call `useReservationsAndMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsAndMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsAndMetadataQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      offeringType: // value for 'offeringType'
 *      serviceUsageType: // value for 'serviceUsageType'
 *      onlyCount: // value for 'onlyCount'
 *      filters: // value for 'filters'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useReservationsAndMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReservationsAndMetadataQuery,
    ReservationsAndMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationsAndMetadataQuery, ReservationsAndMetadataQueryVariables>(
    ReservationsAndMetadataDocument,
    options
  );
}
export function useReservationsAndMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationsAndMetadataQuery,
    ReservationsAndMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationsAndMetadataQuery, ReservationsAndMetadataQueryVariables>(
    ReservationsAndMetadataDocument,
    options
  );
}
export type ReservationsAndMetadataQueryHookResult = ReturnType<
  typeof useReservationsAndMetadataQuery
>;
export type ReservationsAndMetadataLazyQueryHookResult = ReturnType<
  typeof useReservationsAndMetadataLazyQuery
>;
export type ReservationsAndMetadataQueryResult = Apollo.QueryResult<
  ReservationsAndMetadataQuery,
  ReservationsAndMetadataQueryVariables
>;
export const ReservationsActiveTabsDocument = gql`
  query reservationsActiveTabs(
    $paymentOption: ReservationsRecommendationPaymentOption!
    $term: ReservationsRecommendationTerm!
    $lookbackPeriod: ReservationsAndCommitmentsLookbackPeriod!
    $provider: String!
  ) {
    reservationsActiveTabs(
      paymentOption: $paymentOption
      term: $term
      lookbackPeriod: $lookbackPeriod
      provider: $provider
    ) {
      tabs {
        isActive
        serviceUsageType
      }
    }
  }
`;

/**
 * __useReservationsActiveTabsQuery__
 *
 * To run a query within a React component, call `useReservationsActiveTabsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsActiveTabsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsActiveTabsQuery({
 *   variables: {
 *      paymentOption: // value for 'paymentOption'
 *      term: // value for 'term'
 *      lookbackPeriod: // value for 'lookbackPeriod'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useReservationsActiveTabsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationsActiveTabsQuery,
    ReservationsActiveTabsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationsActiveTabsQuery, ReservationsActiveTabsQueryVariables>(
    ReservationsActiveTabsDocument,
    options
  );
}
export function useReservationsActiveTabsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationsActiveTabsQuery,
    ReservationsActiveTabsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationsActiveTabsQuery, ReservationsActiveTabsQueryVariables>(
    ReservationsActiveTabsDocument,
    options
  );
}
export type ReservationsActiveTabsQueryHookResult = ReturnType<
  typeof useReservationsActiveTabsQuery
>;
export type ReservationsActiveTabsLazyQueryHookResult = ReturnType<
  typeof useReservationsActiveTabsLazyQuery
>;
export type ReservationsActiveTabsQueryResult = Apollo.QueryResult<
  ReservationsActiveTabsQuery,
  ReservationsActiveTabsQueryVariables
>;
export const CloudWasteSettingsCategorisedDocument = gql`
  query CloudWasteSettingsCategorised {
    cloudWasteSettingsCategorised {
      category
      settings {
        id
        name
        description
        status
        parameters {
          ... on CloudWasteSettingParameterInt {
            id
            description
            value
            min
            max
          }
        }
      }
    }
  }
`;

/**
 * __useCloudWasteSettingsCategorisedQuery__
 *
 * To run a query within a React component, call `useCloudWasteSettingsCategorisedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudWasteSettingsCategorisedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudWasteSettingsCategorisedQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudWasteSettingsCategorisedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CloudWasteSettingsCategorisedQuery,
    CloudWasteSettingsCategorisedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CloudWasteSettingsCategorisedQuery,
    CloudWasteSettingsCategorisedQueryVariables
  >(CloudWasteSettingsCategorisedDocument, options);
}
export function useCloudWasteSettingsCategorisedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CloudWasteSettingsCategorisedQuery,
    CloudWasteSettingsCategorisedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CloudWasteSettingsCategorisedQuery,
    CloudWasteSettingsCategorisedQueryVariables
  >(CloudWasteSettingsCategorisedDocument, options);
}
export type CloudWasteSettingsCategorisedQueryHookResult = ReturnType<
  typeof useCloudWasteSettingsCategorisedQuery
>;
export type CloudWasteSettingsCategorisedLazyQueryHookResult = ReturnType<
  typeof useCloudWasteSettingsCategorisedLazyQuery
>;
export type CloudWasteSettingsCategorisedQueryResult = Apollo.QueryResult<
  CloudWasteSettingsCategorisedQuery,
  CloudWasteSettingsCategorisedQueryVariables
>;
export const UpdateCloudWasteSettingDocument = gql`
  mutation UpdateCloudWasteSetting($input: UpdateCloudWasteSetting!) {
    updateCloudWasteSetting(input: $input)
  }
`;
export type UpdateCloudWasteSettingMutationFn = Apollo.MutationFunction<
  UpdateCloudWasteSettingMutation,
  UpdateCloudWasteSettingMutationVariables
>;

/**
 * __useUpdateCloudWasteSettingMutation__
 *
 * To run a mutation, you first call `useUpdateCloudWasteSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCloudWasteSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCloudWasteSettingMutation, { data, loading, error }] = useUpdateCloudWasteSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCloudWasteSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCloudWasteSettingMutation,
    UpdateCloudWasteSettingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCloudWasteSettingMutation,
    UpdateCloudWasteSettingMutationVariables
  >(UpdateCloudWasteSettingDocument, options);
}
export type UpdateCloudWasteSettingMutationHookResult = ReturnType<
  typeof useUpdateCloudWasteSettingMutation
>;
export type UpdateCloudWasteSettingMutationResult =
  Apollo.MutationResult<UpdateCloudWasteSettingMutation>;
export type UpdateCloudWasteSettingMutationOptions = Apollo.BaseMutationOptions<
  UpdateCloudWasteSettingMutation,
  UpdateCloudWasteSettingMutationVariables
>;
export const GlobalTagsDocument = gql`
  query GlobalTags {
    globalTagKeys {
      application
      environment
    }
  }
`;

/**
 * __useGlobalTagsQuery__
 *
 * To run a query within a React component, call `useGlobalTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GlobalTagsQuery, GlobalTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalTagsQuery, GlobalTagsQueryVariables>(GlobalTagsDocument, options);
}
export function useGlobalTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GlobalTagsQuery, GlobalTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalTagsQuery, GlobalTagsQueryVariables>(
    GlobalTagsDocument,
    options
  );
}
export type GlobalTagsQueryHookResult = ReturnType<typeof useGlobalTagsQuery>;
export type GlobalTagsLazyQueryHookResult = ReturnType<typeof useGlobalTagsLazyQuery>;
export type GlobalTagsQueryResult = Apollo.QueryResult<GlobalTagsQuery, GlobalTagsQueryVariables>;
export const UpdateGlobalKeysDocument = gql`
  mutation UpdateGlobalKeys($application: [String], $environment: [String]) {
    updateGlobalTagKeys(input: { application: $application, environment: $environment })
  }
`;
export type UpdateGlobalKeysMutationFn = Apollo.MutationFunction<
  UpdateGlobalKeysMutation,
  UpdateGlobalKeysMutationVariables
>;

/**
 * __useUpdateGlobalKeysMutation__
 *
 * To run a mutation, you first call `useUpdateGlobalKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlobalKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlobalKeysMutation, { data, loading, error }] = useUpdateGlobalKeysMutation({
 *   variables: {
 *      application: // value for 'application'
 *      environment: // value for 'environment'
 *   },
 * });
 */
export function useUpdateGlobalKeysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGlobalKeysMutation,
    UpdateGlobalKeysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGlobalKeysMutation, UpdateGlobalKeysMutationVariables>(
    UpdateGlobalKeysDocument,
    options
  );
}
export type UpdateGlobalKeysMutationHookResult = ReturnType<typeof useUpdateGlobalKeysMutation>;
export type UpdateGlobalKeysMutationResult = Apollo.MutationResult<UpdateGlobalKeysMutation>;
export type UpdateGlobalKeysMutationOptions = Apollo.BaseMutationOptions<
  UpdateGlobalKeysMutation,
  UpdateGlobalKeysMutationVariables
>;
export const CostMappingsDocument = gql`
  query costMappings {
    costMappings {
      id
      type
      provider
      algorithm
      settings {
        allEnvironments
        environments {
          id
          splitValue
        }
      }
    }
  }
`;

/**
 * __useCostMappingsQuery__
 *
 * To run a query within a React component, call `useCostMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCostMappingsQuery(
  baseOptions?: Apollo.QueryHookOptions<CostMappingsQuery, CostMappingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CostMappingsQuery, CostMappingsQueryVariables>(
    CostMappingsDocument,
    options
  );
}
export function useCostMappingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostMappingsQuery, CostMappingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CostMappingsQuery, CostMappingsQueryVariables>(
    CostMappingsDocument,
    options
  );
}
export type CostMappingsQueryHookResult = ReturnType<typeof useCostMappingsQuery>;
export type CostMappingsLazyQueryHookResult = ReturnType<typeof useCostMappingsLazyQuery>;
export type CostMappingsQueryResult = Apollo.QueryResult<
  CostMappingsQuery,
  CostMappingsQueryVariables
>;
export const UpdateCostMappingRuleAlgorithmDocument = gql`
  mutation updateCostMappingRuleAlgorithm(
    $id: String!
    $algorithm: CostMappingAlgorithm
    $settings: CostSettingsInput
  ) {
    updateCostCategoryMapping(input: { id: $id, algorithm: $algorithm, settings: $settings })
  }
`;
export type UpdateCostMappingRuleAlgorithmMutationFn = Apollo.MutationFunction<
  UpdateCostMappingRuleAlgorithmMutation,
  UpdateCostMappingRuleAlgorithmMutationVariables
>;

/**
 * __useUpdateCostMappingRuleAlgorithmMutation__
 *
 * To run a mutation, you first call `useUpdateCostMappingRuleAlgorithmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostMappingRuleAlgorithmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostMappingRuleAlgorithmMutation, { data, loading, error }] = useUpdateCostMappingRuleAlgorithmMutation({
 *   variables: {
 *      id: // value for 'id'
 *      algorithm: // value for 'algorithm'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateCostMappingRuleAlgorithmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostMappingRuleAlgorithmMutation,
    UpdateCostMappingRuleAlgorithmMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCostMappingRuleAlgorithmMutation,
    UpdateCostMappingRuleAlgorithmMutationVariables
  >(UpdateCostMappingRuleAlgorithmDocument, options);
}
export type UpdateCostMappingRuleAlgorithmMutationHookResult = ReturnType<
  typeof useUpdateCostMappingRuleAlgorithmMutation
>;
export type UpdateCostMappingRuleAlgorithmMutationResult =
  Apollo.MutationResult<UpdateCostMappingRuleAlgorithmMutation>;
export type UpdateCostMappingRuleAlgorithmMutationOptions = Apollo.BaseMutationOptions<
  UpdateCostMappingRuleAlgorithmMutation,
  UpdateCostMappingRuleAlgorithmMutationVariables
>;
export const DefaultApplicationDocument = gql`
  query defaultApplication {
    applications {
      name
      id
      nid
      environments {
        name
        id
        nid
        resourceCount
      }
    }
  }
`;

/**
 * __useDefaultApplicationQuery__
 *
 * To run a query within a React component, call `useDefaultApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<DefaultApplicationQuery, DefaultApplicationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DefaultApplicationQuery, DefaultApplicationQueryVariables>(
    DefaultApplicationDocument,
    options
  );
}
export function useDefaultApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DefaultApplicationQuery,
    DefaultApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DefaultApplicationQuery, DefaultApplicationQueryVariables>(
    DefaultApplicationDocument,
    options
  );
}
export type DefaultApplicationQueryHookResult = ReturnType<typeof useDefaultApplicationQuery>;
export type DefaultApplicationLazyQueryHookResult = ReturnType<
  typeof useDefaultApplicationLazyQuery
>;
export type DefaultApplicationQueryResult = Apollo.QueryResult<
  DefaultApplicationQuery,
  DefaultApplicationQueryVariables
>;
export const GetUsersPermissionsDocument = gql`
  query getUsersPermissions(
    $limit: Int!
    $page: Int!
    $sortByValue: SortByValue
    $sortByOrder: SortByOrder
    $searchByValue: String
  ) {
    usersPermissions(
      limit: $limit
      page: $page
      sortByValue: $sortByValue
      sortByOrder: $sortByOrder
      searchByValue: $searchByValue
    ) {
      usersPermissions {
        lastLoginDate
        email
        isOrgAdminFromPanel
        access {
          role
          applications
          applicationsWithNames {
            nid
            name
          }
        }
      }
      pages
      count
    }
  }
`;

/**
 * __useGetUsersPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUsersPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPermissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sortByValue: // value for 'sortByValue'
 *      sortByOrder: // value for 'sortByOrder'
 *      searchByValue: // value for 'searchByValue'
 *   },
 * });
 */
export function useGetUsersPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>(
    GetUsersPermissionsDocument,
    options
  );
}
export function useGetUsersPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersPermissionsQuery,
    GetUsersPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersPermissionsQuery, GetUsersPermissionsQueryVariables>(
    GetUsersPermissionsDocument,
    options
  );
}
export type GetUsersPermissionsQueryHookResult = ReturnType<typeof useGetUsersPermissionsQuery>;
export type GetUsersPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetUsersPermissionsLazyQuery
>;
export type GetUsersPermissionsQueryResult = Apollo.QueryResult<
  GetUsersPermissionsQuery,
  GetUsersPermissionsQueryVariables
>;
export const RemoveUserPermissionsDocument = gql`
  mutation removeUserPermissions($email: String!) {
    removeUserPermissions(email: $email)
  }
`;
export type RemoveUserPermissionsMutationFn = Apollo.MutationFunction<
  RemoveUserPermissionsMutation,
  RemoveUserPermissionsMutationVariables
>;

/**
 * __useRemoveUserPermissionsMutation__
 *
 * To run a mutation, you first call `useRemoveUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserPermissionsMutation, { data, loading, error }] = useRemoveUserPermissionsMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserPermissionsMutation,
    RemoveUserPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserPermissionsMutation, RemoveUserPermissionsMutationVariables>(
    RemoveUserPermissionsDocument,
    options
  );
}
export type RemoveUserPermissionsMutationHookResult = ReturnType<
  typeof useRemoveUserPermissionsMutation
>;
export type RemoveUserPermissionsMutationResult =
  Apollo.MutationResult<RemoveUserPermissionsMutation>;
export type RemoveUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserPermissionsMutation,
  RemoveUserPermissionsMutationVariables
>;
export const SetUserPermissionsDocument = gql`
  mutation setUserPermissions($email: String!, $input: AccessInput!) {
    setUserPermissions(email: $email, input: $input)
  }
`;
export type SetUserPermissionsMutationFn = Apollo.MutationFunction<
  SetUserPermissionsMutation,
  SetUserPermissionsMutationVariables
>;

/**
 * __useSetUserPermissionsMutation__
 *
 * To run a mutation, you first call `useSetUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPermissionsMutation, { data, loading, error }] = useSetUserPermissionsMutation({
 *   variables: {
 *      email: // value for 'email'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserPermissionsMutation,
    SetUserPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>(
    SetUserPermissionsDocument,
    options
  );
}
export type SetUserPermissionsMutationHookResult = ReturnType<typeof useSetUserPermissionsMutation>;
export type SetUserPermissionsMutationResult = Apollo.MutationResult<SetUserPermissionsMutation>;
export type SetUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  SetUserPermissionsMutation,
  SetUserPermissionsMutationVariables
>;
export const UpdateUserLastLoginDateDocument = gql`
  mutation UpdateUserLastLoginDate($email: String!) {
    updateUserLastLoginDate(email: $email) {
      lastLoginDate
      updatedBy
    }
  }
`;
export type UpdateUserLastLoginDateMutationFn = Apollo.MutationFunction<
  UpdateUserLastLoginDateMutation,
  UpdateUserLastLoginDateMutationVariables
>;

/**
 * __useUpdateUserLastLoginDateMutation__
 *
 * To run a mutation, you first call `useUpdateUserLastLoginDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLastLoginDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLastLoginDateMutation, { data, loading, error }] = useUpdateUserLastLoginDateMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateUserLastLoginDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserLastLoginDateMutation,
    UpdateUserLastLoginDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserLastLoginDateMutation,
    UpdateUserLastLoginDateMutationVariables
  >(UpdateUserLastLoginDateDocument, options);
}
export type UpdateUserLastLoginDateMutationHookResult = ReturnType<
  typeof useUpdateUserLastLoginDateMutation
>;
export type UpdateUserLastLoginDateMutationResult =
  Apollo.MutationResult<UpdateUserLastLoginDateMutation>;
export type UpdateUserLastLoginDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserLastLoginDateMutation,
  UpdateUserLastLoginDateMutationVariables
>;
export const GetApiKeysPermissionsDocument = gql`
  query getAPIKeysPermissions(
    $limit: Int!
    $page: Int!
    $sortByValue: SortByValue
    $sortByOrder: SortByOrder
    $searchByValue: String
  ) {
    accessKeysPermissions(
      limit: $limit
      page: $page
      sortByValue: $sortByValue
      sortByOrder: $sortByOrder
      searchByValue: $searchByValue
    ) {
      count
      pages
      keyPermissions {
        expirationDate
        createdAt
        isExpired
        id
        access {
          role
          applications
          applicationsWithNames {
            nid
            name
          }
        }
        description
      }
    }
  }
`;

/**
 * __useGetApiKeysPermissionsQuery__
 *
 * To run a query within a React component, call `useGetApiKeysPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeysPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeysPermissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sortByValue: // value for 'sortByValue'
 *      sortByOrder: // value for 'sortByOrder'
 *      searchByValue: // value for 'searchByValue'
 *   },
 * });
 */
export function useGetApiKeysPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApiKeysPermissionsQuery,
    GetApiKeysPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiKeysPermissionsQuery, GetApiKeysPermissionsQueryVariables>(
    GetApiKeysPermissionsDocument,
    options
  );
}
export function useGetApiKeysPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiKeysPermissionsQuery,
    GetApiKeysPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiKeysPermissionsQuery, GetApiKeysPermissionsQueryVariables>(
    GetApiKeysPermissionsDocument,
    options
  );
}
export type GetApiKeysPermissionsQueryHookResult = ReturnType<typeof useGetApiKeysPermissionsQuery>;
export type GetApiKeysPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetApiKeysPermissionsLazyQuery
>;
export type GetApiKeysPermissionsQueryResult = Apollo.QueryResult<
  GetApiKeysPermissionsQuery,
  GetApiKeysPermissionsQueryVariables
>;
export const RemoveApiKeyPermissionsDocument = gql`
  mutation removeAPIKeyPermissions($id: ID!) {
    removeAccessKey(id: $id)
  }
`;
export type RemoveApiKeyPermissionsMutationFn = Apollo.MutationFunction<
  RemoveApiKeyPermissionsMutation,
  RemoveApiKeyPermissionsMutationVariables
>;

/**
 * __useRemoveApiKeyPermissionsMutation__
 *
 * To run a mutation, you first call `useRemoveApiKeyPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveApiKeyPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeApiKeyPermissionsMutation, { data, loading, error }] = useRemoveApiKeyPermissionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveApiKeyPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveApiKeyPermissionsMutation,
    RemoveApiKeyPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveApiKeyPermissionsMutation,
    RemoveApiKeyPermissionsMutationVariables
  >(RemoveApiKeyPermissionsDocument, options);
}
export type RemoveApiKeyPermissionsMutationHookResult = ReturnType<
  typeof useRemoveApiKeyPermissionsMutation
>;
export type RemoveApiKeyPermissionsMutationResult =
  Apollo.MutationResult<RemoveApiKeyPermissionsMutation>;
export type RemoveApiKeyPermissionsMutationOptions = Apollo.BaseMutationOptions<
  RemoveApiKeyPermissionsMutation,
  RemoveApiKeyPermissionsMutationVariables
>;
export const CreateApiKeyPermissionsDocument = gql`
  mutation createAPIKeyPermissions($input: CreateAccessInput!) {
    createAccessKey(input: $input) {
      id
      secret
    }
  }
`;
export type CreateApiKeyPermissionsMutationFn = Apollo.MutationFunction<
  CreateApiKeyPermissionsMutation,
  CreateApiKeyPermissionsMutationVariables
>;

/**
 * __useCreateApiKeyPermissionsMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyPermissionsMutation, { data, loading, error }] = useCreateApiKeyPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApiKeyPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApiKeyPermissionsMutation,
    CreateApiKeyPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApiKeyPermissionsMutation,
    CreateApiKeyPermissionsMutationVariables
  >(CreateApiKeyPermissionsDocument, options);
}
export type CreateApiKeyPermissionsMutationHookResult = ReturnType<
  typeof useCreateApiKeyPermissionsMutation
>;
export type CreateApiKeyPermissionsMutationResult =
  Apollo.MutationResult<CreateApiKeyPermissionsMutation>;
export type CreateApiKeyPermissionsMutationOptions = Apollo.BaseMutationOptions<
  CreateApiKeyPermissionsMutation,
  CreateApiKeyPermissionsMutationVariables
>;
export const UpdateApiKeyPermissionsDocument = gql`
  mutation updateAPIKeyPermissions($id: ID!, $input: AccessInput!) {
    updateAccessKeyPermissions(id: $id, input: $input)
  }
`;
export type UpdateApiKeyPermissionsMutationFn = Apollo.MutationFunction<
  UpdateApiKeyPermissionsMutation,
  UpdateApiKeyPermissionsMutationVariables
>;

/**
 * __useUpdateApiKeyPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateApiKeyPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiKeyPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiKeyPermissionsMutation, { data, loading, error }] = useUpdateApiKeyPermissionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiKeyPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApiKeyPermissionsMutation,
    UpdateApiKeyPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApiKeyPermissionsMutation,
    UpdateApiKeyPermissionsMutationVariables
  >(UpdateApiKeyPermissionsDocument, options);
}
export type UpdateApiKeyPermissionsMutationHookResult = ReturnType<
  typeof useUpdateApiKeyPermissionsMutation
>;
export type UpdateApiKeyPermissionsMutationResult =
  Apollo.MutationResult<UpdateApiKeyPermissionsMutation>;
export type UpdateApiKeyPermissionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateApiKeyPermissionsMutation,
  UpdateApiKeyPermissionsMutationVariables
>;
export const ApplicationsPaginatedListDocument = gql`
  query ApplicationsPaginatedList($limit: Int!, $page: Int!) {
    applicationsPaginated(limit: $limit, page: $page) {
      pages
      count
      results {
        id
        name
        description
        nid
      }
    }
  }
`;

/**
 * __useApplicationsPaginatedListQuery__
 *
 * To run a query within a React component, call `useApplicationsPaginatedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsPaginatedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsPaginatedListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useApplicationsPaginatedListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsPaginatedListQuery,
    ApplicationsPaginatedListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApplicationsPaginatedListQuery, ApplicationsPaginatedListQueryVariables>(
    ApplicationsPaginatedListDocument,
    options
  );
}
export function useApplicationsPaginatedListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsPaginatedListQuery,
    ApplicationsPaginatedListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationsPaginatedListQuery,
    ApplicationsPaginatedListQueryVariables
  >(ApplicationsPaginatedListDocument, options);
}
export type ApplicationsPaginatedListQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedListQuery
>;
export type ApplicationsPaginatedListLazyQueryHookResult = ReturnType<
  typeof useApplicationsPaginatedListLazyQuery
>;
export type ApplicationsPaginatedListQueryResult = Apollo.QueryResult<
  ApplicationsPaginatedListQuery,
  ApplicationsPaginatedListQueryVariables
>;
export const RegenerateAccessKeySecretDocument = gql`
  mutation regenerateAccessKeySecret($id: ID!) {
    regenerateAccessKeySecret(id: $id) {
      id
      secret
    }
  }
`;
export type RegenerateAccessKeySecretMutationFn = Apollo.MutationFunction<
  RegenerateAccessKeySecretMutation,
  RegenerateAccessKeySecretMutationVariables
>;

/**
 * __useRegenerateAccessKeySecretMutation__
 *
 * To run a mutation, you first call `useRegenerateAccessKeySecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateAccessKeySecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateAccessKeySecretMutation, { data, loading, error }] = useRegenerateAccessKeySecretMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegenerateAccessKeySecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateAccessKeySecretMutation,
    RegenerateAccessKeySecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegenerateAccessKeySecretMutation,
    RegenerateAccessKeySecretMutationVariables
  >(RegenerateAccessKeySecretDocument, options);
}
export type RegenerateAccessKeySecretMutationHookResult = ReturnType<
  typeof useRegenerateAccessKeySecretMutation
>;
export type RegenerateAccessKeySecretMutationResult =
  Apollo.MutationResult<RegenerateAccessKeySecretMutation>;
export type RegenerateAccessKeySecretMutationOptions = Apollo.BaseMutationOptions<
  RegenerateAccessKeySecretMutation,
  RegenerateAccessKeySecretMutationVariables
>;
export const UserPermissionsDocument = gql`
  query UserPermissions($email: String!) {
    userPermissions(email: $email) {
      email
      access {
        role
      }
      lastLoginDate
    }
  }
`;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    options
  );
}
export function useUserPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionsQuery, UserPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    options
  );
}
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>;
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>;
export type UserPermissionsQueryResult = Apollo.QueryResult<
  UserPermissionsQuery,
  UserPermissionsQueryVariables
>;
export const ThresholdsDocument = gql`
  query Thresholds($sorting: ThresholdSortInput) {
    thresholds(sorting: $sorting) {
      id
      name
      accounts
      services
      providers
      userRecipients
      externalRecipients
      thresholdAmount
      granularity
      createdDate
    }
  }
`;

/**
 * __useThresholdsQuery__
 *
 * To run a query within a React component, call `useThresholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useThresholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThresholdsQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useThresholdsQuery(
  baseOptions?: Apollo.QueryHookOptions<ThresholdsQuery, ThresholdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThresholdsQuery, ThresholdsQueryVariables>(ThresholdsDocument, options);
}
export function useThresholdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ThresholdsQuery, ThresholdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThresholdsQuery, ThresholdsQueryVariables>(
    ThresholdsDocument,
    options
  );
}
export type ThresholdsQueryHookResult = ReturnType<typeof useThresholdsQuery>;
export type ThresholdsLazyQueryHookResult = ReturnType<typeof useThresholdsLazyQuery>;
export type ThresholdsQueryResult = Apollo.QueryResult<ThresholdsQuery, ThresholdsQueryVariables>;
export const RemoveThresholdDocument = gql`
  mutation RemoveThreshold($id: ID!) {
    removeThreshold(id: $id) {
      id
    }
  }
`;
export type RemoveThresholdMutationFn = Apollo.MutationFunction<
  RemoveThresholdMutation,
  RemoveThresholdMutationVariables
>;

/**
 * __useRemoveThresholdMutation__
 *
 * To run a mutation, you first call `useRemoveThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeThresholdMutation, { data, loading, error }] = useRemoveThresholdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveThresholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveThresholdMutation,
    RemoveThresholdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveThresholdMutation, RemoveThresholdMutationVariables>(
    RemoveThresholdDocument,
    options
  );
}
export type RemoveThresholdMutationHookResult = ReturnType<typeof useRemoveThresholdMutation>;
export type RemoveThresholdMutationResult = Apollo.MutationResult<RemoveThresholdMutation>;
export type RemoveThresholdMutationOptions = Apollo.BaseMutationOptions<
  RemoveThresholdMutation,
  RemoveThresholdMutationVariables
>;
export const AddThresholdDocument = gql`
  mutation AddThreshold($input: ThresholdAddInput!) {
    addThreshold(input: $input) {
      id
      name
      thresholdAmount
      granularity
      userRecipients
      externalRecipients
      providers
      accounts
      services
    }
  }
`;
export type AddThresholdMutationFn = Apollo.MutationFunction<
  AddThresholdMutation,
  AddThresholdMutationVariables
>;

/**
 * __useAddThresholdMutation__
 *
 * To run a mutation, you first call `useAddThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addThresholdMutation, { data, loading, error }] = useAddThresholdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddThresholdMutation(
  baseOptions?: Apollo.MutationHookOptions<AddThresholdMutation, AddThresholdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddThresholdMutation, AddThresholdMutationVariables>(
    AddThresholdDocument,
    options
  );
}
export type AddThresholdMutationHookResult = ReturnType<typeof useAddThresholdMutation>;
export type AddThresholdMutationResult = Apollo.MutationResult<AddThresholdMutation>;
export type AddThresholdMutationOptions = Apollo.BaseMutationOptions<
  AddThresholdMutation,
  AddThresholdMutationVariables
>;
export const UpdateThresholdDocument = gql`
  mutation UpdateThreshold($id: ID!, $input: ThresholdUpdateInput!) {
    updateThreshold(id: $id, input: $input) {
      id
      name
      thresholdAmount
      granularity
      userRecipients
      externalRecipients
      providers
      accounts
      services
    }
  }
`;
export type UpdateThresholdMutationFn = Apollo.MutationFunction<
  UpdateThresholdMutation,
  UpdateThresholdMutationVariables
>;

/**
 * __useUpdateThresholdMutation__
 *
 * To run a mutation, you first call `useUpdateThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThresholdMutation, { data, loading, error }] = useUpdateThresholdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThresholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThresholdMutation,
    UpdateThresholdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateThresholdMutation, UpdateThresholdMutationVariables>(
    UpdateThresholdDocument,
    options
  );
}
export type UpdateThresholdMutationHookResult = ReturnType<typeof useUpdateThresholdMutation>;
export type UpdateThresholdMutationResult = Apollo.MutationResult<UpdateThresholdMutation>;
export type UpdateThresholdMutationOptions = Apollo.BaseMutationOptions<
  UpdateThresholdMutation,
  UpdateThresholdMutationVariables
>;
export const AddWorkflowDocument = gql`
  mutation addWorkflow($input: AddWorkflowInput!) {
    addWorkflowV2(input: $input) {
      workflow {
        ...workflowFields
      }
      userErrors {
        message
        code
      }
    }
  }
  ${WorkflowFieldsFragmentDoc}
`;
export type AddWorkflowMutationFn = Apollo.MutationFunction<
  AddWorkflowMutation,
  AddWorkflowMutationVariables
>;

/**
 * __useAddWorkflowMutation__
 *
 * To run a mutation, you first call `useAddWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkflowMutation, { data, loading, error }] = useAddWorkflowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<AddWorkflowMutation, AddWorkflowMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddWorkflowMutation, AddWorkflowMutationVariables>(
    AddWorkflowDocument,
    options
  );
}
export type AddWorkflowMutationHookResult = ReturnType<typeof useAddWorkflowMutation>;
export type AddWorkflowMutationResult = Apollo.MutationResult<AddWorkflowMutation>;
export type AddWorkflowMutationOptions = Apollo.BaseMutationOptions<
  AddWorkflowMutation,
  AddWorkflowMutationVariables
>;
export const GetWorkflowListDocument = gql`
  query getWorkflowList($field: WorkflowSortingFields, $order: WorkflowSortingOrder) {
    workflows(field: $field, order: $order) {
      lastRunDate
      lastRunStatus
      id
      active
      contactPersons {
        id
        name
        email
      }
      description
      name
      triggers {
        type
        schedulerOptions {
          byWeekday
          endDate
          frequency
          interval
          startDate
        }
      }
    }
  }
`;

/**
 * __useGetWorkflowListQuery__
 *
 * To run a query within a React component, call `useGetWorkflowListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowListQuery({
 *   variables: {
 *      field: // value for 'field'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWorkflowListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWorkflowListQuery, GetWorkflowListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkflowListQuery, GetWorkflowListQueryVariables>(
    GetWorkflowListDocument,
    options
  );
}
export function useGetWorkflowListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkflowListQuery, GetWorkflowListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkflowListQuery, GetWorkflowListQueryVariables>(
    GetWorkflowListDocument,
    options
  );
}
export type GetWorkflowListQueryHookResult = ReturnType<typeof useGetWorkflowListQuery>;
export type GetWorkflowListLazyQueryHookResult = ReturnType<typeof useGetWorkflowListLazyQuery>;
export type GetWorkflowListQueryResult = Apollo.QueryResult<
  GetWorkflowListQuery,
  GetWorkflowListQueryVariables
>;
export const GetWorkflowDocument = gql`
  query getWorkflow($id: ID!) {
    workflow(id: $id) {
      ...workflowFields
    }
  }
  ${WorkflowFieldsFragmentDoc}
`;

/**
 * __useGetWorkflowQuery__
 *
 * To run a query within a React component, call `useGetWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkflowQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkflowQuery, GetWorkflowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(GetWorkflowDocument, options);
}
export function useGetWorkflowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkflowQuery, GetWorkflowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkflowQuery, GetWorkflowQueryVariables>(
    GetWorkflowDocument,
    options
  );
}
export type GetWorkflowQueryHookResult = ReturnType<typeof useGetWorkflowQuery>;
export type GetWorkflowLazyQueryHookResult = ReturnType<typeof useGetWorkflowLazyQuery>;
export type GetWorkflowQueryResult = Apollo.QueryResult<
  GetWorkflowQuery,
  GetWorkflowQueryVariables
>;
export const ExecuteWorkflowDocument = gql`
  mutation executeWorkflow($id: ID!) {
    executeWorkflow(id: $id)
  }
`;
export type ExecuteWorkflowMutationFn = Apollo.MutationFunction<
  ExecuteWorkflowMutation,
  ExecuteWorkflowMutationVariables
>;

/**
 * __useExecuteWorkflowMutation__
 *
 * To run a mutation, you first call `useExecuteWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeWorkflowMutation, { data, loading, error }] = useExecuteWorkflowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExecuteWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExecuteWorkflowMutation,
    ExecuteWorkflowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExecuteWorkflowMutation, ExecuteWorkflowMutationVariables>(
    ExecuteWorkflowDocument,
    options
  );
}
export type ExecuteWorkflowMutationHookResult = ReturnType<typeof useExecuteWorkflowMutation>;
export type ExecuteWorkflowMutationResult = Apollo.MutationResult<ExecuteWorkflowMutation>;
export type ExecuteWorkflowMutationOptions = Apollo.BaseMutationOptions<
  ExecuteWorkflowMutation,
  ExecuteWorkflowMutationVariables
>;
export const ActivateWorkflowDocument = gql`
  mutation activateWorkflow($id: ID!, $active: Boolean!) {
    activateWorkflowV2(id: $id, active: $active) {
      workflow {
        ...workflowFields
      }
      userErrors {
        message
        code
        supportLink
      }
    }
  }
  ${WorkflowFieldsFragmentDoc}
`;
export type ActivateWorkflowMutationFn = Apollo.MutationFunction<
  ActivateWorkflowMutation,
  ActivateWorkflowMutationVariables
>;

/**
 * __useActivateWorkflowMutation__
 *
 * To run a mutation, you first call `useActivateWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateWorkflowMutation, { data, loading, error }] = useActivateWorkflowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useActivateWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateWorkflowMutation,
    ActivateWorkflowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateWorkflowMutation, ActivateWorkflowMutationVariables>(
    ActivateWorkflowDocument,
    options
  );
}
export type ActivateWorkflowMutationHookResult = ReturnType<typeof useActivateWorkflowMutation>;
export type ActivateWorkflowMutationResult = Apollo.MutationResult<ActivateWorkflowMutation>;
export type ActivateWorkflowMutationOptions = Apollo.BaseMutationOptions<
  ActivateWorkflowMutation,
  ActivateWorkflowMutationVariables
>;
export const UpdateWorkflowDetailDocument = gql`
  mutation updateWorkflowDetail($id: ID!, $input: UpdateWorkflowInput!) {
    updateWorkflowDetail(id: $id, input: $input) {
      ...workflowFields
    }
  }
  ${WorkflowFieldsFragmentDoc}
`;
export type UpdateWorkflowDetailMutationFn = Apollo.MutationFunction<
  UpdateWorkflowDetailMutation,
  UpdateWorkflowDetailMutationVariables
>;

/**
 * __useUpdateWorkflowDetailMutation__
 *
 * To run a mutation, you first call `useUpdateWorkflowDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkflowDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkflowDetailMutation, { data, loading, error }] = useUpdateWorkflowDetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkflowDetailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkflowDetailMutation,
    UpdateWorkflowDetailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkflowDetailMutation, UpdateWorkflowDetailMutationVariables>(
    UpdateWorkflowDetailDocument,
    options
  );
}
export type UpdateWorkflowDetailMutationHookResult = ReturnType<
  typeof useUpdateWorkflowDetailMutation
>;
export type UpdateWorkflowDetailMutationResult =
  Apollo.MutationResult<UpdateWorkflowDetailMutation>;
export type UpdateWorkflowDetailMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkflowDetailMutation,
  UpdateWorkflowDetailMutationVariables
>;
export const UpdateWorkflowConfigDocument = gql`
  mutation updateWorkflowConfig($id: ID!, $input: UpdateWorkflowConfigInput!) {
    updateWorkflowConfig(id: $id, input: $input) {
      ...workflowFields
    }
  }
  ${WorkflowFieldsFragmentDoc}
`;
export type UpdateWorkflowConfigMutationFn = Apollo.MutationFunction<
  UpdateWorkflowConfigMutation,
  UpdateWorkflowConfigMutationVariables
>;

/**
 * __useUpdateWorkflowConfigMutation__
 *
 * To run a mutation, you first call `useUpdateWorkflowConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkflowConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkflowConfigMutation, { data, loading, error }] = useUpdateWorkflowConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkflowConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkflowConfigMutation,
    UpdateWorkflowConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWorkflowConfigMutation, UpdateWorkflowConfigMutationVariables>(
    UpdateWorkflowConfigDocument,
    options
  );
}
export type UpdateWorkflowConfigMutationHookResult = ReturnType<
  typeof useUpdateWorkflowConfigMutation
>;
export type UpdateWorkflowConfigMutationResult =
  Apollo.MutationResult<UpdateWorkflowConfigMutation>;
export type UpdateWorkflowConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkflowConfigMutation,
  UpdateWorkflowConfigMutationVariables
>;
export const RemoveWorkflowDocument = gql`
  mutation RemoveWorkflow($id: ID!) {
    removeWorkflow(id: $id)
  }
`;
export type RemoveWorkflowMutationFn = Apollo.MutationFunction<
  RemoveWorkflowMutation,
  RemoveWorkflowMutationVariables
>;

/**
 * __useRemoveWorkflowMutation__
 *
 * To run a mutation, you first call `useRemoveWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkflowMutation, { data, loading, error }] = useRemoveWorkflowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveWorkflowMutation, RemoveWorkflowMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveWorkflowMutation, RemoveWorkflowMutationVariables>(
    RemoveWorkflowDocument,
    options
  );
}
export type RemoveWorkflowMutationHookResult = ReturnType<typeof useRemoveWorkflowMutation>;
export type RemoveWorkflowMutationResult = Apollo.MutationResult<RemoveWorkflowMutation>;
export type RemoveWorkflowMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkflowMutation,
  RemoveWorkflowMutationVariables
>;
export const CloneWorkflowDocument = gql`
  mutation cloneWorkflow($id: ID!, $input: CloneWorkflowInput!) {
    cloneWorkflow(id: $id, input: $input) {
      ...workflowFields
    }
  }
  ${WorkflowFieldsFragmentDoc}
`;
export type CloneWorkflowMutationFn = Apollo.MutationFunction<
  CloneWorkflowMutation,
  CloneWorkflowMutationVariables
>;

/**
 * __useCloneWorkflowMutation__
 *
 * To run a mutation, you first call `useCloneWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneWorkflowMutation, { data, loading, error }] = useCloneWorkflowMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneWorkflowMutation(
  baseOptions?: Apollo.MutationHookOptions<CloneWorkflowMutation, CloneWorkflowMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloneWorkflowMutation, CloneWorkflowMutationVariables>(
    CloneWorkflowDocument,
    options
  );
}
export type CloneWorkflowMutationHookResult = ReturnType<typeof useCloneWorkflowMutation>;
export type CloneWorkflowMutationResult = Apollo.MutationResult<CloneWorkflowMutation>;
export type CloneWorkflowMutationOptions = Apollo.BaseMutationOptions<
  CloneWorkflowMutation,
  CloneWorkflowMutationVariables
>;
export const GetWorkflowHistoryDocument = gql`
  query getWorkflowHistory(
    $limit: Int!
    $workflowId: String
    $cursor: String
    $status: WorkflowEventStatus
  ) {
    workflowHistoryEvents(
      limit: $limit
      workflowId: $workflowId
      cursor: $cursor
      status: $status
    ) {
      cursor
      events {
        id
        status
        workflowId
        workflowName
        workflowDescription
        startTime
        endTime
        result
        workflowDeleted
        trigger {
          type
          schedulerOptions {
            byWeekday
            endDate
            frequency
            interval
            startDate
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkflowHistoryQuery__
 *
 * To run a query within a React component, call `useGetWorkflowHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowHistoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      workflowId: // value for 'workflowId'
 *      cursor: // value for 'cursor'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetWorkflowHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkflowHistoryQuery, GetWorkflowHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkflowHistoryQuery, GetWorkflowHistoryQueryVariables>(
    GetWorkflowHistoryDocument,
    options
  );
}
export function useGetWorkflowHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkflowHistoryQuery,
    GetWorkflowHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkflowHistoryQuery, GetWorkflowHistoryQueryVariables>(
    GetWorkflowHistoryDocument,
    options
  );
}
export type GetWorkflowHistoryQueryHookResult = ReturnType<typeof useGetWorkflowHistoryQuery>;
export type GetWorkflowHistoryLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowHistoryLazyQuery
>;
export type GetWorkflowHistoryQueryResult = Apollo.QueryResult<
  GetWorkflowHistoryQuery,
  GetWorkflowHistoryQueryVariables
>;
export const HistoryEventsDocument = gql`
  query historyEvents(
    $limit: Int!
    $cursor: String
    $field: WorkflowEventInListSortingFields
    $order: WorkflowSortingOrder
  ) {
    historyEvents(limit: $limit, cursor: $cursor, field: $field, order: $order) {
      cursor
      events {
        id
        status
        workflowId
        workflowName
        workflowDescription
        startTime
        endTime
        result
        workflowDeleted
        trigger {
          type
          schedulerOptions {
            byWeekday
            endDate
            frequency
            interval
            startDate
          }
        }
      }
    }
  }
`;

/**
 * __useHistoryEventsQuery__
 *
 * To run a query within a React component, call `useHistoryEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryEventsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      field: // value for 'field'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHistoryEventsQuery(
  baseOptions: Apollo.QueryHookOptions<HistoryEventsQuery, HistoryEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoryEventsQuery, HistoryEventsQueryVariables>(
    HistoryEventsDocument,
    options
  );
}
export function useHistoryEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HistoryEventsQuery, HistoryEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HistoryEventsQuery, HistoryEventsQueryVariables>(
    HistoryEventsDocument,
    options
  );
}
export type HistoryEventsQueryHookResult = ReturnType<typeof useHistoryEventsQuery>;
export type HistoryEventsLazyQueryHookResult = ReturnType<typeof useHistoryEventsLazyQuery>;
export type HistoryEventsQueryResult = Apollo.QueryResult<
  HistoryEventsQuery,
  HistoryEventsQueryVariables
>;
export const GetWorkflowHistoryEventDocument = gql`
  query getWorkflowHistoryEvent($id: ID!) {
    workflowHistoryEvent(id: $id) {
      id
      startTime
      endTime
      status
      workflowName
      trigger {
        type
        schedulerOptions {
          byWeekday
          endDate
          frequency
          interval
          startDate
        }
      }
      dataType
      result
      detailedResult {
        kpiResult {
          businessContextName
          businessContextColor
          costAllocation {
            value
            percentage
            target
          }
          overallWaste {
            value
            percentage
            target
          }
          nonProdHours {
            value
            percentage
            target
          }
        }
      }
      errorCode
    }
  }
`;

/**
 * __useGetWorkflowHistoryEventQuery__
 *
 * To run a query within a React component, call `useGetWorkflowHistoryEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkflowHistoryEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkflowHistoryEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkflowHistoryEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkflowHistoryEventQuery,
    GetWorkflowHistoryEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkflowHistoryEventQuery, GetWorkflowHistoryEventQueryVariables>(
    GetWorkflowHistoryEventDocument,
    options
  );
}
export function useGetWorkflowHistoryEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkflowHistoryEventQuery,
    GetWorkflowHistoryEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkflowHistoryEventQuery, GetWorkflowHistoryEventQueryVariables>(
    GetWorkflowHistoryEventDocument,
    options
  );
}
export type GetWorkflowHistoryEventQueryHookResult = ReturnType<
  typeof useGetWorkflowHistoryEventQuery
>;
export type GetWorkflowHistoryEventLazyQueryHookResult = ReturnType<
  typeof useGetWorkflowHistoryEventLazyQuery
>;
export type GetWorkflowHistoryEventQueryResult = Apollo.QueryResult<
  GetWorkflowHistoryEventQuery,
  GetWorkflowHistoryEventQueryVariables
>;
export const GetBusinessContextsDocument = gql`
  query GetBusinessContexts($year: String!) {
    businessContexts(limit: 100, page: 0) {
      businessContexts {
        id
        name
        color
        isPrimary
        orgUnits {
          orgUnits {
            id
            nid
            parentNid
            name
            budgetYearly(year: $year) {
              id
              yearlySum
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetBusinessContextsQuery__
 *
 * To run a query within a React component, call `useGetBusinessContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessContextsQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetBusinessContextsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBusinessContextsQuery, GetBusinessContextsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessContextsQuery, GetBusinessContextsQueryVariables>(
    GetBusinessContextsDocument,
    options
  );
}
export function useGetBusinessContextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessContextsQuery,
    GetBusinessContextsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBusinessContextsQuery, GetBusinessContextsQueryVariables>(
    GetBusinessContextsDocument,
    options
  );
}
export type GetBusinessContextsQueryHookResult = ReturnType<typeof useGetBusinessContextsQuery>;
export type GetBusinessContextsLazyQueryHookResult = ReturnType<
  typeof useGetBusinessContextsLazyQuery
>;
export type GetBusinessContextsQueryResult = Apollo.QueryResult<
  GetBusinessContextsQuery,
  GetBusinessContextsQueryVariables
>;
export const ApplicationsSearchWithBudgetsDocument = gql`
  query ApplicationsSearchWithBudgets(
    $limit: Int!
    $year: String!
    $page: Int
    $applicationName: String
  ) {
    applicationsPaginated(limit: $limit, page: $page, applicationName: $applicationName) {
      count
      pages
      results {
        id
        nid
        name
        environments {
          name
          id
          nid
          budgetYearly(year: $year) {
            id
            yearlySum
          }
        }
        budgetYearly(year: $year) {
          id
          yearlySum
        }
      }
    }
  }
`;

/**
 * __useApplicationsSearchWithBudgetsQuery__
 *
 * To run a query within a React component, call `useApplicationsSearchWithBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsSearchWithBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsSearchWithBudgetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      year: // value for 'year'
 *      page: // value for 'page'
 *      applicationName: // value for 'applicationName'
 *   },
 * });
 */
export function useApplicationsSearchWithBudgetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApplicationsSearchWithBudgetsQuery,
    ApplicationsSearchWithBudgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApplicationsSearchWithBudgetsQuery,
    ApplicationsSearchWithBudgetsQueryVariables
  >(ApplicationsSearchWithBudgetsDocument, options);
}
export function useApplicationsSearchWithBudgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationsSearchWithBudgetsQuery,
    ApplicationsSearchWithBudgetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApplicationsSearchWithBudgetsQuery,
    ApplicationsSearchWithBudgetsQueryVariables
  >(ApplicationsSearchWithBudgetsDocument, options);
}
export type ApplicationsSearchWithBudgetsQueryHookResult = ReturnType<
  typeof useApplicationsSearchWithBudgetsQuery
>;
export type ApplicationsSearchWithBudgetsLazyQueryHookResult = ReturnType<
  typeof useApplicationsSearchWithBudgetsLazyQuery
>;
export type ApplicationsSearchWithBudgetsQueryResult = Apollo.QueryResult<
  ApplicationsSearchWithBudgetsQuery,
  ApplicationsSearchWithBudgetsQueryVariables
>;
export const AllAppAndEnvIdsDocument = gql`
  query AllAppAndEnvIds($applicationName: String) {
    selectAllAppAndEnvIds(applicationName: $applicationName) {
      applicationNids
      environmentNids
    }
  }
`;

/**
 * __useAllAppAndEnvIdsQuery__
 *
 * To run a query within a React component, call `useAllAppAndEnvIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAppAndEnvIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAppAndEnvIdsQuery({
 *   variables: {
 *      applicationName: // value for 'applicationName'
 *   },
 * });
 */
export function useAllAppAndEnvIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllAppAndEnvIdsQuery, AllAppAndEnvIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllAppAndEnvIdsQuery, AllAppAndEnvIdsQueryVariables>(
    AllAppAndEnvIdsDocument,
    options
  );
}
export function useAllAppAndEnvIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAppAndEnvIdsQuery, AllAppAndEnvIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllAppAndEnvIdsQuery, AllAppAndEnvIdsQueryVariables>(
    AllAppAndEnvIdsDocument,
    options
  );
}
export type AllAppAndEnvIdsQueryHookResult = ReturnType<typeof useAllAppAndEnvIdsQuery>;
export type AllAppAndEnvIdsLazyQueryHookResult = ReturnType<typeof useAllAppAndEnvIdsLazyQuery>;
export type AllAppAndEnvIdsQueryResult = Apollo.QueryResult<
  AllAppAndEnvIdsQuery,
  AllAppAndEnvIdsQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BusinessContextKpi: [
      "KpiCostAllocationCoverage",
      "KpiNonProdHoursBusinessContext",
      "KpiOverallWasteBusinessContext",
    ],
    CloudWasteSettingParameter: ["CloudWasteSettingParameterInt"],
    EnvironmentKpi: ["KpiNonProdHoursForEnvironment", "KpiOverallWasteForEnvironment"],
    OrgUnitKpi: ["KpiNonProdHoursForOrgUnit", "KpiOverallWasteForOrgUnit"],
    Resource: ["CloudResource", "CostResource"],
    SearchResult: [
      "Account",
      "Application",
      "CloudResource",
      "CostResource",
      "Environment",
      "OrgUnitV2",
    ],
  },
};
export default result;
