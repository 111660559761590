/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import {
  Provider,
  useGetReservationUpfrontCostQuery,
} from "~/generated/graphql";
import { ResourceLifecycleDates } from "~/tools";
import { Metadata, mapMetadata } from "../../components";
import { EstateRecord } from "../../Ec2Instance/types";
import { useResourceLifeSpanDates } from "../../hooks/useResouceLifeSpanDates";
import { Costs } from "../../types";
import { ProviderType } from "../constants";
import { MappedMetadata } from "../types";
import { getCloudIconAndColor } from "../utils";

type Props = {
  estateRecord: EstateRecord;
  costs: Costs;
  metadata: Metadata[];
  resourceLifecycleDates?: ResourceLifecycleDates;
  nid: string;
};

export function useReservationInstanceDetails({
  estateRecord,
  costs,
  metadata,
  resourceLifecycleDates,
  nid,
}: Props) {
  const mapped = mapMetadata<MappedMetadata>(metadata);

  const { data } = useGetReservationUpfrontCostQuery({
    variables: {
      reservationId: nid,
    },
  });

  const parsedUpfrontCost = Number(data?.reservationDetails?.upfrontCost);
  const upfrontCost = isNaN(parsedUpfrontCost) ? 0 : parsedUpfrontCost;

  const lifeSpanDates = useResourceLifeSpanDates({
    lastUpdateDate: mapped.lastUpdateDate ?? "",
    resourceLifecycleDates,
  });

  const resultWithMetadata = {
    reservationDetails: toReservationDetails(mapped, lifeSpanDates),
  };

  const provisionState = resultWithMetadata.reservationDetails.provisionState;
  const cloudIconStatus = getCloudIconAndColor(provisionState);
  const { instanceLabel } = getInstanceLabel(
    estateRecord.providerType ?? "",
    nid,
    metadata
  );

  return {
    resultWithMetadata,
    headerData: {
      name: instanceLabel ?? "",
      providerId: estateRecord.providerId ?? "",
      providerType: estateRecord.providerType ?? Provider.Azure,
      account: estateRecord.cloudAccountId ?? "",
      costs,
      cloudIcon: cloudIconStatus.cloudIcon,
      color: cloudIconStatus.color,
      provider: estateRecord.provider ?? Provider.Azure,
      upfrontCost,
    },
  };
}

function toReservationDetails(
  mapped: Partial<MappedMetadata>,
  lifeSpanDates: LifeSpanDates
) {
  const {
    sku,
    displayName = "",
    offeringClass = "",
    offeringType = "",
    instanceCount,
    instanceType = "",
    DBInstanceClass,
    DBInstanceCount,
    paymentOption = "",
    productDescription = "",
    provisioningState,
    term = "",
    cacheNodeCount,
    cacheNodeType,
    commitment,
    createdDateTime,
    benefitStartTime,
    savingPlanUsageType = "",
    savingsPlanType = "",
    start,
    end,
    expiryDate,
    originalQuantity = "",
    billingPlan,
  } = mapped;
  const { firstSeenDate, lastSeenDate } = lifeSpanDates;

  return {
    skuName: sku?.name,
    displayName,
    quantity:
      originalQuantity ?? instanceCount ?? DBInstanceCount ?? cacheNodeCount,
    createdDate: createdDateTime,
    expirationDate: expiryDate ?? end,
    reservationStartDate: benefitStartTime ?? start,
    firstSeen: firstSeenDate,
    lastSeen: lastSeenDate,
    billingPlan: billingPlan ?? offeringType,
    provisionState: provisioningState,
    term,
    instanceType: instanceType ?? DBInstanceClass ?? cacheNodeType,
    savingsPlanType,
    savingsPlanUsageType: savingPlanUsageType,
    commitment,
    paymentOption,
    offeringClass,
    operatingSystem: productDescription,
  };
}

function getInstanceLabel(
  providerType: string,
  nid: string,
  metadata: Metadata[]
) {
  const mappedData = mapMetadata<MappedMetadata>(metadata);
  const savingsPlanUsageType = mappedData.savingPlanUsageType;
  const instanceFamily = savingsPlanUsageType?.split(".")[0];

  const ec2SavingsPlanType = providerType.includes(
    ProviderType.EC2_SAVINGS_PLANS
  )
    ? `${mappedData.savingsPlanType}(${instanceFamily})`
    : mappedData.savingsPlanType;

  let instanceLabel: string | undefined = "";

  if (providerType.includes(ProviderType.MICROSOFT_CAPACITY_RESERVATIONS)) {
    instanceLabel = mappedData.sku?.name;
  } else if (providerType.includes(ProviderType.RESERVED_INSTANCES)) {
    instanceLabel = mappedData.instanceType;
  } else if (providerType.includes(ProviderType.SAVINGS_PLANS)) {
    instanceLabel = ec2SavingsPlanType;
  } else {
    instanceLabel = nid;
  }
  return {
    instanceLabel,
  };
}

type LifeSpanDates = {
  firstSeenDate: string;
  lastSeenDate: string;
};
