/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

export const enum ProviderType {
  MICROSOFT_CAPACITY_RESERVATIONS = "microsoft.capacity/reservations",
  RESERVED_INSTANCES = "reserved-instances",
  SAVINGS_PLANS = "savingsplans",
  EC2_SAVINGS_PLANS = "savingsplans/ec2instance",
}

export const enum AWSHeaderLabels {
  ID = "AWS ID",
  TYPE = "AWS Type",
}

export const enum AzureHeaderLabels {
  ID = "Azure ID",
  TYPE = "Azure Type",
}

export const defaultRangeLabel = "Date Range";
export const defaultSelectButtonWidth = "3rem";

export const enum Granularity {
  DAILY = "daily",
  MONTHLY = "monthly",
}
