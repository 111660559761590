/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dayjs from "dayjs";
import { ReservationUtilizationTimePeriod } from "~/generated/graphql";
import { dateFormat } from "~/constants/dateFormat";
import { generateNumericArray } from "~/tools";
import { DataItem } from "../types";

export const generateMonthlyChartTicks = (data: DataItem[]) =>
  data.map((dt, index, array) => {
    if (array.length > 50) {
      return index % 3 === 0
        ? dayjs(dt.date).format(dateFormat.dayMonthShort)
        : "";
    }

    return data.length > 0
      ? dayjs(dt.date).format(dateFormat.dayMonthShort)
      : dayjs(dt.date).format(dateFormat.monthYearShort);
  });

export const generateOneMonthTicks = (data: DataItem[], barsNumber: number) => {
  const startDayOfMonth = dayjs(data[0]?.date);
  const dates = generateNumericArray(barsNumber);
  return dates.map((dayNumber) =>
    startDayOfMonth.add(dayNumber, "day").format(dateFormat.dayMonthShort)
  );
};

export const generateThreeMonthsTicks = (
  data: DataItem[],
  period: ReservationUtilizationTimePeriod
) =>
  data.map((dt, index) => {
    if (period === ReservationUtilizationTimePeriod.ThreeMonths) {
      return index % 7 === 0
        ? dayjs(dt.date).format(dateFormat.dayMonthShort)
        : "";
    }

    return dayjs(dt.date).format(dateFormat.monthYearShort);
  });
