/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { UtilizationChart } from "~/components";
import { isNotNil } from "~/tools";
import { useReservationUtilizationData } from "./hooks/useReservationUtilizationData";
import { ReservationType } from "./types";
import { UtilizationHeader } from "./UtilizationHeader";

type Props = {
  nid: string;
};

export function UtilizationTab({ nid }: Props) {
  const {
    getUtilization,
    setSelectedReservationId,
    onChangeRange,
    onChangePeriod,
    period,
    range,
    loading,
    benefit,
    forecast,
    reservationIds,
    chartData,
    utilizationValue,
    selectedReservationId,
  } = useReservationUtilizationData({
    nid,
  });

  const onChangeReservationId = (newValue: ReservationType | null) => {
    setSelectedReservationId(newValue);
  };

  useEffect(() => {
    getUtilization();
  }, [getUtilization, selectedReservationId]);

  return (
    <>
      <UtilizationHeader
        period={period}
        range={range}
        reservationIds={reservationIds}
        selectedReservationId={selectedReservationId}
        onChangeRange={onChangeRange}
        onChangePeriod={onChangePeriod}
        onChangeReservationId={onChangeReservationId}
      />
      <UtilizationChart
        loading={loading}
        chartData={chartData.filter(isNotNil)}
        benefit={benefit}
        forecast={forecast}
        utilizationValue={utilizationValue}
      />
    </>
  );
}
