/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import { DateRange, Granularity } from "~/components";
import { EstatePeriod } from "~/constants";
import { useQueryState } from "~/hooks";
import { useBillingPeriod } from "~/services/customers";
import { isNotNil } from "~/tools";
import { EstateChartsQueryState, EstateGroupBy } from "../types";
import {
  adjustRangeByPeriod,
  areDatesInPeriod,
  getEndDate,
  getRange,
  getStartDate,
  isGroupByValueChanged,
  isPeriodValueChanged,
  isDateValueChanged,
} from "./helpers";

type EstateState = {
  estatePeriod: EstatePeriod;
  groupBy: EstateGroupBy;
  periodStart: string;
  periodEnd: string;
  granularity?: Granularity;
  groupByTagKey?: string;
  groupByBusinessContext?: string;
  page?: number;
};

export function useEstateChartParameters() {
  const { state, updateQueryState } = useQueryState<EstateChartsQueryState>();
  const { isPreviousBillingPeriod } = useBillingPeriod();

  const [search, setSearch] = useState(state.query ?? "");
  const [range, setRange] = useState<DateRange | undefined>(
    getRange(state, isPreviousBillingPeriod)
  );
  const granularity = state.granularity ?? Granularity.MONTHS;

  const [parameters, setParameters] = useState<EstateState>({
    estatePeriod: state.estatePeriod ?? EstatePeriod.DEFAULT_MONTH,
    periodStart: getStartDate(
      state.estatePeriod ?? EstatePeriod.DEFAULT_MONTH,
      isPreviousBillingPeriod,
      range
    ),
    periodEnd: getEndDate(
      state.estatePeriod ?? EstatePeriod.DEFAULT_MONTH,
      isPreviousBillingPeriod,
      range
    ),
    granularity,
    groupBy: state.groupBy ?? EstateGroupBy.NONE,
  });

  const onApply = () => {
    updateQueryState(parameters);
  };

  const handleGroupByChange = (
    groupBy: EstateGroupBy,
    groupByTagKey?: string,
    groupByBusinessContext?: string
  ) => {
    setParameters((prevValue) => ({
      ...prevValue,
      groupBy,
      groupByTagKey,
      groupByBusinessContext,
    }));
  };

  const handleGranularityChange = (granularitySelection: Granularity) => {
    updateQueryState({
      granularity: granularitySelection,
    });
    setParameters((prevParameters) => ({
      ...prevParameters,
      granularity: granularitySelection,
    }));
  };

  const clearRange = () => {
    setRange(undefined);
  };

  // used when billing period is changed
  const handlePeriodChange = (
    selectedPeriod: EstatePeriod,
    selectedRange?: DateRange
  ) => {
    if (selectedRange) {
      setRange(selectedRange);
    }

    const periodStart = getStartDate(
      selectedPeriod,
      isPreviousBillingPeriod,
      selectedRange
    );

    const periodEnd = getEndDate(
      selectedPeriod,
      isPreviousBillingPeriod,
      selectedRange
    );

    updateQueryState({
      periodStart,
      periodEnd,
      estatePeriod: selectedPeriod,
    });

    setParameters((prevParameters) => ({
      ...prevParameters,
      periodStart,
      periodEnd,
      estatePeriod: selectedPeriod,
      page: 0,
    }));
  };

  const handlePeriodChangeOnClick = (
    selectedPeriod: EstatePeriod,
    selectedRange?: DateRange
  ) => {
    if (selectedRange) {
      setRange(selectedRange);
    }

    setParameters((prevParameters) => ({
      ...prevParameters,
      periodStart: getStartDate(
        selectedPeriod,
        isPreviousBillingPeriod,
        selectedRange
      ),
      periodEnd: getEndDate(
        selectedPeriod,
        isPreviousBillingPeriod,
        selectedRange
      ),
      estatePeriod: selectedPeriod,
      page: 0,
    }));
  };

  const handleRangeSelection = () => {
    if (areDatesInPeriod(isPreviousBillingPeriod, state)) {
      handlePeriodChange(
        EstatePeriod.RANGE,
        adjustRangeByPeriod(isPreviousBillingPeriod, state)
      );
    } else {
      handlePeriodChange(EstatePeriod.DEFAULT_MONTH);
      setRange(undefined);
    }
  };

  const setFiltersToDefault = () => {
    setRange(undefined);

    const defaultParameters = {
      periodStart: getStartDate(
        EstatePeriod.DEFAULT_MONTH,
        isPreviousBillingPeriod
      ),
      periodEnd: getEndDate(
        EstatePeriod.DEFAULT_MONTH,
        isPreviousBillingPeriod
      ),
      estatePeriod: EstatePeriod.DEFAULT_MONTH,
      granularity: Granularity.MONTHS,
      groupBy: EstateGroupBy.NONE,
      groupByTagKey: undefined,
      groupByBusinessContext: undefined,
    };

    setParameters((preValue) => ({
      ...preValue,
      ...defaultParameters,
    }));

    updateQueryState({
      ...defaultParameters,
    });
  };

  const areParametersChanged = () => {
    const isPeriodStartChanged = isDateValueChanged(
      state.periodStart,
      getStartDate(
        state.estatePeriod ?? EstatePeriod.DEFAULT_MONTH,
        isPreviousBillingPeriod
      ),
      parameters.periodStart
    );

    const isPeriodEndChanged = isDateValueChanged(
      state.periodEnd,
      getEndDate(
        state.estatePeriod ?? EstatePeriod.DEFAULT_MONTH,
        isPreviousBillingPeriod
      ),
      parameters.periodEnd
    );

    const isPeriodChanged = isPeriodValueChanged(
      state.estatePeriod,
      parameters.estatePeriod
    );
    const isGroupByChanged = isGroupByValueChanged(
      state.groupBy,
      parameters.groupBy
    );

    const isTagKeyChanged =
      isNotNil(state.groupByTagKey) &&
      state.groupByTagKey !== parameters.groupByTagKey;

    const isBusinessContextChanges =
      isNotNil(state.groupByBusinessContext) &&
      state.groupByBusinessContext !== parameters.groupByBusinessContext;

    return (
      isPeriodChanged ||
      isGroupByChanged ||
      isTagKeyChanged ||
      isBusinessContextChanges ||
      isPeriodStartChanged ||
      isPeriodEndChanged
    );
  };

  const isApplyEnabled = areParametersChanged();

  useEffect(() => {
    if (parameters.estatePeriod === EstatePeriod.RANGE) {
      handleRangeSelection();
    } else {
      handlePeriodChange(parameters.estatePeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousBillingPeriod]);

  const handleClearFilters = () => {
    clearRange();
    setFiltersToDefault();
  };

  return {
    period: parameters.estatePeriod,
    range,
    periodStart: parameters.periodStart,
    periodEnd: parameters.periodEnd,
    granularity: parameters.granularity ?? granularity,
    isApplyEnabled,
    search,
    selectedChart: parameters.groupBy,
    onApply,
    handleGranularityChange,
    handlePeriodChangeOnClick,
    handleGroupByChange,
    setSearch,
    clearRange,
    setFiltersToDefault,
    handleClearFilters,
  };
}
