/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { CollapsibleBox } from "~/components";
import { Item } from "../../components";
import { InstanceGridWrapper } from "../styles";
import { InstanceDetails } from "./InstanceDetails";

type Props = {
  instanceType: string;
  operatingSystem: string;
  location: string;
  unitsCount: string;
  purchaseOption: string;
  offeringClass: string;
  startDate: string;
  expirationDate: string;
  term: string;
  firstSeen: string | undefined;
  lastSeen: string | undefined;
};

export function AwsReservedInstanceDetails({
  instanceType,
  purchaseOption,
  operatingSystem,
  unitsCount,
  location,
  startDate,
  term,
  offeringClass,
  expirationDate,
  firstSeen,
  lastSeen,
}: Props) {
  return (
    <CollapsibleBox title="Instance Details">
      <InstanceGridWrapper>
        <Item label="Instance Type" value={instanceType} />
        <Item label="Operating System" value={operatingSystem} />
        <Item label="Location" value={location} />
        <Item label="Units Count" value={unitsCount} />
        <Item label="Offering Class" value={offeringClass} />
        <InstanceDetails
          paymentOption={purchaseOption}
          startDate={startDate}
          expirationDate={expirationDate}
          firstSeen={firstSeen}
          lastSeen={lastSeen}
          term={term}
        />
      </InstanceGridWrapper>
    </CollapsibleBox>
  );
}
