/**
 * Copyright 2022-2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */
import { Dispatch } from "react";
import dayjs from "dayjs";
import { Col, Hidden } from "react-awesome-styled-grid";
import { When } from "react-if";
import {
  theme,
  Text,
  Popover,
  Button,
  Navigation,
  SVGIcon,
  FlexContainer,
} from "@nordcloud/gnui";
import { dateFormat } from "~/constants";
import { ROUTES } from "~/routing/routes";
import { ACTIONS, Can } from "~/services/auth";
import { isNotNil } from "~/tools";
import { Co2eSavings } from "../Co2e";
import { WasteIndicator } from "../WasteIndicator";
import { ColumnWrapper, StyledText } from "./styles";
import { ApplicationDesc } from "./Types";

type Props = {
  application: ApplicationDesc;
  showAppCo2eSavings?: boolean;
  getAppHandler?: (options: {
    variables: { id: string; year: string };
  }) => void;
  editHandler?: Dispatch<boolean>;
  setIsModalConfirmOpen?: Dispatch<boolean>;
};

export function ApplicationItemTotals({
  application,
  showAppCo2eSavings,
  editHandler,
  getAppHandler,
  setIsModalConfirmOpen,
}: Props) {
  const showPopover =
    isNotNil(editHandler) &&
    isNotNil(getAppHandler) &&
    isNotNil(setIsModalConfirmOpen);

  return (
    <>
      <Col sm={3} md={4} lg={5}>
        <FlexContainer alignItems="center" justifyContent="flex-end">
          <ColumnWrapper>
            <StyledText
              isTitle
              nowrap
              size="sm"
              tag="div"
              mb={theme.spacing.spacing02}
            >
              Monthly Costs
            </StyledText>
            <StyledText
              weight="medium"
              tag="div"
              ml={theme.spacing.spacing02}
              data-testid="application-monthly-cost"
            >
              {application.cost.currentMonth}
            </StyledText>
          </ColumnWrapper>
          <Hidden xs sm>
            <ColumnWrapper>
              <StyledText
                isTitle
                size="sm"
                tag="div"
                mb={theme.spacing.spacing02}
              >
                Monthly Forecast
              </StyledText>
              <FlexContainer justifyContent="flex-end" alignItems="center">
                <When condition={application?.cloudWasteAggregate?.hasWaste}>
                  <When condition={showAppCo2eSavings}>
                    <Co2eSavings
                      iconOnly
                      co2eSavings={parseFloat(
                        application?.cloudWasteAggregate?.co2e ?? "0"
                      )}
                    />
                  </When>
                  <WasteIndicator
                    size="sm"
                    reason="Application has Savings Suggestions"
                    amount={{
                      amount: application?.cloudWasteAggregate?.cost ?? "",
                      uri: `${ROUTES.estateRecords.index}?application=${application.id}&page=0&waste=true`,
                    }}
                  />
                </When>
                <StyledText
                  tag="div"
                  ml={theme.spacing.spacing02}
                  weight="medium"
                >
                  {application.cost.forecast}
                </StyledText>
              </FlexContainer>
            </ColumnWrapper>
            <ColumnWrapper>
              <StyledText
                isTitle
                nowrap
                size="sm"
                tag="div"
                mb={theme.spacing.spacing02}
              >
                Monthly Budget
              </StyledText>
              <StyledText
                tag="div"
                ml={theme.spacing.spacing02}
                weight="medium"
              >
                {application.budget}
              </StyledText>
            </ColumnWrapper>
          </Hidden>
          <When condition={showPopover}>
            <ColumnWrapper hideBorder width="2rem">
              <Popover
                alignRight
                trigger={
                  <Button
                    severity="low"
                    icon="menu"
                    size="md"
                    title="popover-menu-button"
                  />
                }
              >
                <Navigation.Container popoverMenu>
                  <Navigation.Item
                    css={{ cursor: "pointer" }}
                    onClick={() => {
                      getAppHandler?.({
                        variables: {
                          id: application.id,
                          year: dayjs().format(dateFormat.year),
                        },
                      });
                      editHandler?.(true);
                    }}
                  >
                    <SVGIcon name="edit" />
                    <Text tag="span">Edit</Text>
                  </Navigation.Item>
                  <Can action={ACTIONS.removeApplication}>
                    <Navigation.Item
                      css={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsModalConfirmOpen?.(true);
                      }}
                    >
                      <SVGIcon name="trash" />
                      <Text tag="span">Delete</Text>
                    </Navigation.Item>
                  </Can>
                </Navigation.Container>
              </Popover>
            </ColumnWrapper>
          </When>
        </FlexContainer>
      </Col>
    </>
  );
}
